import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import ModelLicense from '../../models/Licenses';
import { useStatusRequest } from '../../contexts/request';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';
import { useAuthState } from '../../contexts/auth';

interface IProps extends Interfaces.IAnalytics {
  status: Types.TLicenseStatus;
  isTutorial?: boolean;
}

interface IUseLicensesByStatus {
  isLoadingRequest: {
    fetch: boolean;
    create: boolean;
    assign: boolean;
    editStatus: boolean;
    deleteLicenseAvailable: boolean;
  };
  dataLicenses: {
    listInfoStatus: Interfaces.ILicensesTypeItem[];
    listParsed: Interfaces.IParseLicensesByStatus[] | [];
  };
  isErrorAssign: boolean;
  nextRequest: Interfaces.INextRequest;
  refetchLicenses: () => Promise<void>;
  getMoreLicenses: () => Promise<void>;
  sendAssignMultiple: (body: Interfaces.IBodyAssignMultipleLicenses) => void;
  updateListLicenses: (list: Interfaces.ILicensesTypeItem[]) => void;
  sendEditLicenseStatus(data: Interfaces.IBodyLicenseEditStatus): Promise<void>;
  deleteLicenseToBeAvailable(idLicense: string): Promise<void>;
}

interface IState {
  query: Interfaces.IQueryLicensesByStatus;
  listLicensesByStatus: IUseLicensesByStatus['dataLicenses']['listInfoStatus'];
  parsedListLicenses: IUseLicensesByStatus['dataLicenses']['listParsed'];
}

const DEFAULT_LOADING = {
  fetch: false,
  create: false,
  assign: false,
  editLocation: false,
  editStatus: false,
  deleteLicenseAvailable: false
};

const { DEFAULT_VALUE_NEXT_REQUEST } = Constants;

const LoggerInstance = ReporterLogger.getInstance();

// TODO: PARSED DATA LICENSES FOR COORDS

export const useLicensesByStatus = ({
  status,
  analytics,
  isTutorial
}: IProps): IUseLicensesByStatus => {
  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });

  const { t: translations } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();
  const user = useAuthState();
  const DEFAULT_QUERY: IState['query'] = !isTutorial
    ? { status, limit: 25 }
    : { status };

  const [isLoadingRequest, setIsLoadingRequest] =
    useState<IUseLicensesByStatus['isLoadingRequest']>(DEFAULT_LOADING);
  const [listLicensesByStatus, setListLicensesByStatus] = useState<
    IState['listLicensesByStatus']
  >([]);
  const [parsedListLicenses, setParsedListLicenses] = useState<
    IState['parsedListLicenses']
  >([]);
  const [nextRequest, setNextRequest] = useState<
    IUseLicensesByStatus['nextRequest']
  >(DEFAULT_VALUE_NEXT_REQUEST);
  const [query, setQuery] = useState<IState['query']>(DEFAULT_QUERY);
  const [isErrorAssign, setIsErrorAssign] = useState<boolean>(false);

  const setLicensesAndNext = (response: any, isRefetch: boolean): void => {
    const { licenses } = response;
    let newListLicenses = licenses;
    let newNextRequest = DEFAULT_VALUE_NEXT_REQUEST;
    if (nextRequest.isLoadMore && !isRefetch) {
      newListLicenses = [...listLicensesByStatus, ...licenses];
    }
    if (response?.lastItem) {
      newNextRequest = {
        isLoadMore: !!response.licenses.length,
        lastItem: response.lastItem
      };
    }
    setListLicensesByStatus(newListLicenses);
    setNextRequest(newNextRequest);
  };

  const fetchLicensesByStatus = async (isRefetch = false): Promise<void> => {
    if (!isLoadingRequest.fetch) {
      setIsLoadingRequest({ ...isLoadingRequest, fetch: true });
    }
    try {
      const response = await BackendIoT.getLicensesByStatus(query);
      if (response?.licenses.length) {
        setLicensesAndNext(response, isRefetch);
      } else {
        setParsedListLicenses([]);
        setListLicensesByStatus([]);
      }
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: translations('actions.requests.fetch.licenses'),
        message: translations('error.requests.licenses.fetch')
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed to get license by status - useLicensesByStatus - "fetchLicensesByStatus"',
        error
      );
    } finally {
      setIsLoadingRequest({ ...isLoadingRequest, fetch: false });
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
  }, []);

  useEffect(() => {
    if (user.id) {
      fetchLicensesByStatus();
    }
  }, [query, user]);

  useEffect(() => {
    if (status !== query.status) {
      setQuery({ ...DEFAULT_QUERY, status });
      setListLicensesByStatus([]);
      setParsedListLicenses([]);
    }
  }, [status]);

  useEffect(() => {
    if (listLicensesByStatus.length) {
      const parseList = Object.values(listLicensesByStatus).map((license) =>
        new ModelLicense().init(license).parseToViewTable()
      );
      setParsedListLicenses(parseList);
    }
  }, [listLicensesByStatus]);

  const refetchLicenses = async (): Promise<void> => {
    if (query.lastItem) {
      setQuery(DEFAULT_QUERY);
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
    } else {
      await fetchLicensesByStatus(true);
    }
  };

  const sendEditLicenseStatus = async (
    data: Interfaces.IBodyLicenseEditStatus
  ): Promise<void> => {
    if (!isLoadingRequest.editStatus) {
      setIsLoadingRequest({ ...isLoadingRequest, editStatus: true });
    }
    try {
      await BackendIoT.updateLicenseStatus(data);
      messageResponseRef.current = {
        type: 'success',
        action: translations('actions.requests.edit.licenses'),
        message: translations('success.requests.licenses.edit', {
          id: data.id
        })
      };
    } catch (error: any) {
      messageResponseRef.current = {
        type: 'error',
        action: translations('actions.requests.edit.licenses'),
        message: translations('error.requests.licenses.edit', {
          id: data.id
        })
      };
      LoggerInstance.error(
        'Failed to edit license status - useLicensesByStatus - "fetchLicensesByStatus"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoadingRequest({ ...isLoadingRequest, editStatus: false });
      if (!isLoadingRequest.deleteLicenseAvailable) {
        refetchLicenses();
      }
    }
  };

  const deleteLicenseToBeAvailable = async (
    idLicense: string
  ): Promise<void> => {
    if (!isLoadingRequest.deleteLicenseAvailable) {
      setIsLoadingRequest({
        ...isLoadingRequest,
        deleteLicenseAvailable: true
      });
    }
    try {
      await BackendIoT.deleteLicenseThings(idLicense);
      messageResponseRef.current = {
        type: 'success',
        action: translations('actions.requests.edit.licenses'),
        message: translations('success.requests.licenses.edit', {
          id: idLicense
        })
      };
    } catch (error: any) {
      if (error?.status === 400) {
        sendEditLicenseStatus({ id: idLicense, status: 'available' });
      } else {
        messageResponseRef.current = {
          type: 'error',
          action: translations('actions.requests.edit.licenses'),
          message: translations('error.requests.licenses.edit', {
            id: idLicense
          })
        };
      }
      LoggerInstance.error(
        'Failed to delete license to be available - useLicensesByStatus - "deleteLicenseToBeAvailable"',
        error
      );
    } finally {
      if (messageResponseRef.current.message?.length) {
        UpdateMessageResponse(messageResponseRef.current);
      }
      setIsLoadingRequest({
        ...isLoadingRequest,
        deleteLicenseAvailable: false
      });
      if (!isLoadingRequest.editStatus) {
        refetchLicenses();
      }
    }
  };

  const updateListLicenses = (list: Interfaces.ILicensesTypeItem[]): void => {
    setListLicensesByStatus(list);
  };

  const getMoreLicenses = async (): Promise<void> => {
    const paramsQuery = {
      ...query,
      lastItem: JSON.stringify(nextRequest.lastItem)
    };
    setQuery(paramsQuery);
  };

  const sendAssignMultiple = async (
    body: Interfaces.IBodyAssignMultipleLicenses
  ): Promise<void> => {
    setIsErrorAssign(false);
    try {
      setIsLoadingRequest({ ...isLoadingRequest, assign: true });
      await BackendIoT.assignMultipleLicense(body);
      messageResponseRef.current = {
        type: 'success',
        action: translations('actions.requests.assign.licenses'),
        message: translations('success.requests.licenses.assign')
      };
    } catch (error) {
      setIsErrorAssign(true);
      messageResponseRef.current = {
        type: 'error',
        action: translations('actions.requests.assign.licenses'),
        message: translations('error.requests.licenses.fetch')
      };
      LoggerInstance.error(
        'Failed to assign multiple licenses - useLicensesByStatus - "sendAssignMultiple"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoadingRequest({ ...isLoadingRequest, assign: false });
    }
  };

  return {
    nextRequest,
    isErrorAssign,
    isLoadingRequest,
    getMoreLicenses,
    refetchLicenses,
    updateListLicenses,
    sendEditLicenseStatus,
    sendAssignMultiple,
    deleteLicenseToBeAvailable,
    dataLicenses: {
      listInfoStatus: listLicensesByStatus,
      listParsed: parsedListLicenses
    }
  };
};

export default useLicensesByStatus;
