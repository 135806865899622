import React, { useCallback, useEffect, useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import HeaderLayer from './Header';
import Container from './Container';
import Empty from '../Empty';
import * as Types from '../../types';
import * as Interfaces from '../../interfaces';

import './Layer.scss';

export interface PropsComponentLayer {
  loading: boolean;
  loadMore?: boolean;
  data: any[];
  section: Types.TLayerTypes;
  fieldToSearch?: string;
  onSetSearchData?: (searchValue: Interfaces.ISearchValue) => void;
  onEdit?: (element?: any) => void;
  onCreate?: () => void | null;
  onDelete?: () => void;
  onDeleteCommand?: (
    command: Interfaces.ICommandScheduledDeleteElement
  ) => void;
  onRefresh?: () => Promise<void> | null;
  onSearchTrigger?: (search: any) => void | null;
  refetchLoadMore?: () => void;
  statistic?: () => JSX.Element | null;
  setModalParam?: (e: SyntheticEvent<HTMLElement>, data: any) => void;
}

interface IState {
  searchItem: string;
  searching: {
    isFound: boolean;
    isInitial: boolean;
  };
}

const DEFAULT_SEARCHING_VALUE = {
  isFound: true,
  isInitial: true
};

const VIEW_IN_LIST = [
  'FEEDACK',
  'GROUPS-THINGS',
  'GROUPS-USERS',
  'GROUPS-FEEDACK'
];

const Layer = ({
  data,
  section,
  loading,
  loadMore,
  onCreate,
  onDelete,
  onDeleteCommand,
  onEdit,
  onRefresh,
  onSearchTrigger,
  onSetSearchData,
  statistic,
  setModalParam,
  refetchLoadMore
}: PropsComponentLayer): JSX.Element => {
  const { t } = useTranslation('common', { keyPrefix: 'messages.search' });
  const {
    isMobileDevice,
    screen: { isMobile }
  } = useBreakpoint();

  const initialView = isMobileDevice || isMobile ? 'CARDS' : 'TABLE';
  const [displayView, setDisplayView] =
    useState<Types.TViewRecords>(initialView);
  const [filterData, setFilterData] = useState<PropsComponentLayer['data']>([]);
  const [searchItem, setSearchItem] = useState<IState['searchItem']>('');
  const [searching, setSearching] = useState<IState['searching']>(
    DEFAULT_SEARCHING_VALUE
  );

  useEffect(() => {
    if (!loading) {
      setFilterData(data);
    } else {
      setSearching(DEFAULT_SEARCHING_VALUE);
    }
  }, [loading, data]);

  useEffect(() => {
    if (isMobile) {
      setDisplayView(VIEW_IN_LIST.includes(section) ? 'LIST' : 'CARDS');
    } else {
      setDisplayView('TABLE');
    }
  }, [isMobile]);

  const countSubgroups = (name: string): number => {
    let count = 0;
    data.forEach((element) => {
      if (element.group) {
        const groupId = element.group;
        const arrayGroups = groupId.split(':');
        if (arrayGroups.includes(name)) {
          const position = arrayGroups.indexOf(name) + 1;
          const itemAfter = arrayGroups.length - position;
          if (itemAfter === 1) {
            count += 1;
          }
        }
      }
    });
    return count;
  };

  const dataContainer =
    section !== 'GROUPS'
      ? filterData
      : filterData
          .map((element) => {
            const nameWithoutSpaces = element.groupName
              ? element.groupName.replace(/ /g, '')
              : '';
            return {
              ...element,
              subgroups: countSubgroups(nameWithoutSpaces)
            };
          })
          .filter((dataInfo) => dataInfo.groupName);

  const handlerOnSearchData = useCallback(
    (e: SyntheticEvent, list: any, searchValue: Interfaces.ISearchValue) => {
      e.preventDefault();
      setSearchItem(searchValue.value);
      setFilterData(list);
      setSearching({
        isFound: !!list.length,
        isInitial: false
      });
      if (onSetSearchData) {
        // This validation is for when the data is dynamic, for example updating the connection status
        onSetSearchData(searchValue);
      }
    },
    [data]
  );

  const { isFound, isInitial } = searching;

  return (
    <>
      <HeaderLayer
        data={data}
        section={section}
        loading={loading}
        isFound={isFound}
        totalItems={filterData.length}
        statistic={statistic}
        onCreate={onCreate}
        onRefresh={onRefresh}
        onSearch={handlerOnSearchData}
        onSearchTrigger={onSearchTrigger}
      />
      {!isFound && !isInitial ? (
        <Empty
          iconName='search'
          texts={{
            title: t('title', { results: searchItem }),
            subtitle: t('subtitle')
          }}
        />
      ) : (
        <Container
          isSearch={!!searchItem.length}
          loadMore={loadMore}
          loading={loading}
          refetchLoadMore={refetchLoadMore}
          view={displayView}
          section={section}
          data={dataContainer}
          onDelete={onDelete}
          onDeleteCommand={onDeleteCommand}
          onEdit={onEdit}
          setModalParam={setModalParam}
        />
      )}
    </>
  );
};

Layer.defaultProps = {
  refetchLoadMore: () => null,
  fieldToSearch: 'name',
  loadMore: false,
  onCreate: null,
  onSearchTrigger: null,
  onRefresh: null,
  onEdit: () => null,
  onDelete: () => null,
  onDeleteCommand: () => null,
  setModalParam: () => null,
  statistic: null,
  onSetSearchData: () => null
};

export default Layer;
