import React, { SyntheticEvent } from 'react';
import { Button, Header, Label, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { GoogleMapProps } from '@react-google-maps/api';
import { useMaps } from '../../../hooks/useMaps';
import Map from '../../Map';
import { DEFAULT_COORDS } from '../../../config/constants';

import '../../../scss/layout/Modal.scss';
import './ModalCoords.scss';

const OPTIONS_MAP = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  draggable: false,
  zoomControl: true
};

export interface PropsComponentCoords {
  isOpen: boolean;
  coords?: GoogleMapProps['center'];
  address?: string;
  onCancel(e?: SyntheticEvent): void;
}

const Coords = ({
  isOpen,
  coords,
  address,
  onCancel
}: PropsComponentCoords): JSX.Element => {
  const { t } = useTranslation();

  const { onLoad } = useMaps(coords);

  const propsMap = {
    coords: coords ?? DEFAULT_COORDS,
    onLoadMap: onLoad,
    optionsMap: OPTIONS_MAP,
    withMarker: { position: coords || DEFAULT_COORDS }
  };
  return (
    <Modal
      open={isOpen}
      size='small'
      className='wrapper-modal wrapper-modal-coords'
      data-testid='modal-coords-component'
    >
      <Modal.Header>
        <Header icon='map marker alternate' content={t('maps.title')} />
      </Modal.Header>
      <Modal.Content className='wrapper-map'>
        <div style={{ height: '324px' }}>
          <Map {...propsMap} />
        </div>
        {address && !!address.length && (
          <Label attached='bottom' className='label-address'>
            {address}
          </Label>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onCancel} content={t('actions.close')} />
      </Modal.Actions>
    </Modal>
  );
};

Coords.defaultProps = {
  coords: undefined,
  address: ''
};

export default Coords;
