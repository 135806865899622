import { COMMANDS_EVENTS, LOGS_EVENTS } from '../config/constants';
import * as Interfaces from '../interfaces';
import * as Types from '../types';

const UNKNOWN_EVENT_NAME = 'feedAck.events.unknown';

class Feed {
  private eventId: string | null;

  private thingId: string | null;

  private createdAt: Date | null;

  private eventAction: Types.TCommands | null;

  private entity: Types.TEntity | null;

  private logType: Types.TNameLogsEvent | null;

  private eventData: any;

  private eventType: Types.TCommands | null;

  private payload: any;

  constructor() {
    this.createdAt = null;
    this.eventAction = null;
    this.eventId = null;
    this.logType = null;
    this.thingId = null;
    this.entity = null;
    this.eventAction = null;
    this.eventType = null;
    this.payload = null;
  }

  init(feed: Interfaces.IFeedItems) {
    this.createdAt = feed.createdAt;
    this.eventAction = feed.eventAction ?? null;
    this.eventId = feed.eventId || feed.logId;
    this.logType = feed?.logType ?? null;
    this.thingId = feed?.thingId || '';
    this.entity = feed?.entity;
    this.eventData = feed?.eventData || null;
    this.eventType = feed?.eventType ?? null;
    this.payload = feed?.payload || null;
    return this;
  }

  parseToKeysSubscription(): Interfaces.ISubscriptionFeed {
    return {
      id: this.eventId ?? 'error.notAvailable',
      createdAt: this.createdAt ?? '',
      name: this.thingId ?? 'error.notAvailable',
      event: this.getNameTranslate(),
      description: this.getDescriptionTranslate(),
      eventData: this.eventData || null,
      payload: this.payload || null,
      typeEvent: this.logType || this.eventAction || this.eventType
    };
  }

  getNameTranslate(): string {
    if (!this.entity) {
      return UNKNOWN_EVENT_NAME;
    }

    if (this.entity === 'ACKNOWLEDGEMENT') {
      const commandOrEventName = this.eventAction || this.eventType;
      return Feed.getNameEventForAcknowledgement(
        commandOrEventName,
        this.eventData
      );
    }

    if (this.entity === 'LOG') {
      return Feed.getNameEventForLogs(this.logType);
    }

    return UNKNOWN_EVENT_NAME;
  }

  static getNameEventForAcknowledgement(
    command: Types.TCommands | null,
    data: any
  ): string {
    if (!command) {
      return UNKNOWN_EVENT_NAME;
    }

    if (command === 'test-alert' && !data) {
      return COMMANDS_EVENTS['silent-notification'].name;
    }

    const commandName = COMMANDS_EVENTS[command];
    return commandName ? commandName.name : 'error.unknownCommand';
  }

  static getNameEventForLogs(status: Types.TNameLogsEvent | null): string {
    if (!status) {
      return UNKNOWN_EVENT_NAME;
    }

    const logName = LOGS_EVENTS[status] || LOGS_EVENTS.UNKNOWN;
    return logName.name;
  }

  getDescriptionTranslate(): string {
    if (!this.entity) {
      return '';
    }

    if (this.entity === 'ACKNOWLEDGEMENT') {
      const commandOrEventName = this.eventAction || this.eventType;
      return Feed.getDescriptionForAcknowledgement(
        commandOrEventName,
        this.eventData
      );
    }

    if (this.entity === 'LOG') {
      return Feed.getDescriptionForLogs(this.logType);
    }

    return '';
  }

  static getDescriptionForAcknowledgement(
    command: Types.TCommands | null,
    data: any
  ): string {
    if (!command) {
      return '';
    }

    if (command === 'test-alert' && !data) {
      return COMMANDS_EVENTS['silent-notification'].description;
    }

    const commandDescription = COMMANDS_EVENTS[command];
    return commandDescription
      ? commandDescription.description
      : 'error.unknownCommand';
  }

  static getDescriptionForLogs(status: Types.TNameLogsEvent | null): string {
    if (!status) {
      return '';
    }

    const logName = LOGS_EVENTS[status] || LOGS_EVENTS.UNKNOWN;
    return logName.description;
  }
}

export default Feed;
