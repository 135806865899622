import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './resources/en';
import es from './resources/es';
import names from '../names';
import { DEFAULT_USER_SETTINGS } from '../constants';
import * as Interfaces from '../../interfaces';

import 'moment/locale/es';

let initialConfig = DEFAULT_USER_SETTINGS;

const settingsStore = localStorage.getItem(names.storageKeys.userSettings);
if (settingsStore) {
  const parseSettingsStore = JSON.parse(
    settingsStore
  ) as Interfaces.ISettingsStorage;
  initialConfig = parseSettingsStore;
}

moment.locale(initialConfig.language);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      es
    },
    interpolation: {
      escapeValue: false,
      format(value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize')
          return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      }
    },
    lng: initialConfig.language,
    fallbackLng: 'en',
    defaultNS: 'common'
  });

i18n.on('languageChanged', (lng: 'es' | 'en') => {
  moment.locale(lng);
});

export default i18n;
