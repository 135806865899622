import React, {
  FC,
  useState,
  SyntheticEvent,
  useCallback,
  useEffect
} from 'react';
import {
  ModalContent,
  ModalActions,
  Button,
  Header,
  Modal,
  Form,
  InputProps
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { LIMIT_CHARACTERS_NAME_THING } from '../../../config/constants';

export interface EditNameThingProps {
  isOpen: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  thingName: string;
  onClose: () => void;
  onSave: (newThingName: string) => void;
}

interface IError {
  state: boolean;
  message: string;
}

const EditThingAlias: FC<EditNameThingProps> = ({
  isOpen,
  isDisabled,
  isLoading,
  thingName,
  onClose,
  onSave
}: EditNameThingProps): JSX.Element => {
  const { t } = useTranslation();

  const DEFAULT_ERROR = {
    state: false,
    message: t('error.modals.labels.name')
  };

  const [isError, setIsError] = useState<IError>(DEFAULT_ERROR);
  const [newThingName, setNewThingName] = useState<string>(thingName);

  useEffect(() => {
    if (isOpen) {
      setNewThingName(thingName);
      setIsError(DEFAULT_ERROR);
    }
  }, [isOpen]);

  useEffect(() => {
    if (thingName) {
      setNewThingName(thingName);
    }
  }, [thingName]);

  const handleOnChangeThingName = useCallback(
    (e: SyntheticEvent, { value }: InputProps) => {
      e.preventDefault();
      setNewThingName(value);
      setIsError({ ...isError, state: false });
    },
    []
  );

  const handleOnCancel = (): void => {
    onClose();
  };

  const handleOnSave = (): void => {
    if (!newThingName) {
      setIsError({ ...isError, state: true });
      return;
    }

    if (newThingName.length > LIMIT_CHARACTERS_NAME_THING) {
      setIsError({
        state: true,
        message: t('error.modals.labels.nameLong')
      });
      return;
    }

    onSave(newThingName);
  };

  return (
    <Modal open={isOpen} size='tiny'>
      <Header icon='edit' content={t('modals.titles.edit.thing')} />
      <ModalContent>
        <Form autoComplete='off'>
          <Form.Input
            data-testid='thing-name-input'
            type='text'
            disabled={isLoading || isDisabled}
            onChange={handleOnChangeThingName}
            error={isError.state && isError.message}
            value={newThingName}
          />
        </Form>
      </ModalContent>
      <ModalActions>
        <Button
          negative
          onClick={handleOnCancel}
          content={t('actions.cancel')}
        />
        <Button
          positive
          onClick={handleOnSave}
          content={t('actions.save')}
          disabled={isLoading}
          loading={isLoading}
        />
      </ModalActions>
    </Modal>
  );
};

export default EditThingAlias;
