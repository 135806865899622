import React from 'react';
import {
  Button,
  SemanticCOLORS,
  SemanticICONS,
  SemanticSIZES
} from 'semantic-ui-react';

import './ButtonFloat.scss';

export interface PropsComponentButtonFloat {
  styled?: string;
  isHidden: boolean;
  onClick: () => void;
  color?: SemanticCOLORS;
  icon?: SemanticICONS;
  size?: SemanticSIZES;
}

const ButtonFloat = (props: PropsComponentButtonFloat): JSX.Element => {
  const { isHidden, styled, ...propsButton } = props;
  if (!isHidden) {
    return (
      <Button
        circular
        {...propsButton}
        data-testid='button-float-component'
        className={`wrapper-buttonFloat ${styled}`}
      />
    );
  }
  return <></>;
};

ButtonFloat.defaultProps = {
  styled: '',
  color: 'orange',
  icon: 'plus',
  size: 'massive'
};

export default ButtonFloat;
