import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackendIoT from '../controllers/Backend/IoT';
import ReporterLogger from '../controllers/ReporterLogger';
import { useStatusRequest } from '../contexts/request';
import * as Interfaces from '../interfaces';
import * as Types from '../types';

interface PropsUseConnectionStatus {
  query: {
    customerId?: string;
    receptor?: Types.TReceptorTypes;
  };
  createIntervalRequest?: boolean;
  isInitialRequest?: boolean;
}
const LoggerInstance = ReporterLogger.getInstance();

const MILLISECONDS_PER_REQUEST = 1000 * 60 * 5;

let isFirstTimeToSendLoggerErrorForThingsConnected = true;
let isQueueRequest = false;

const useConnectionStatus = ({
  query,
  createIntervalRequest = false,
  isInitialRequest = false
}: PropsUseConnectionStatus) => {
  const refIntervalIdForRequestThingsConnected =
    useRef<ReturnType<typeof setInterval>>();
  const { t: translation } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
  const [thingsConnected, setThingsConnected] = useState<
    Interfaces.IRequestThingsConnected['connectedThings']
  >([]);

  const catchErrorRequestThingsConnected = (error: Error): void => {
    LoggerInstance.error(
      'Failed to get things connected - useConnectionStatus - fetchThingsConnected',
      error
    );

    UpdateMessageResponse({
      type: 'error',
      message: translation('error.requests.monitor.statusConnection'),
      delay: 10000
    });
  };

  const fetchThingsConnected = async () => {
    setIsLoadingRequest(true);
    const queryThings = query || {};
    if (!isQueueRequest) {
      isQueueRequest = true;
      try {
        const { connectedThings } = await BackendIoT.getThingsConnected(
          queryThings
        );
        setThingsConnected(connectedThings);
      } catch (error) {
        if (isFirstTimeToSendLoggerErrorForThingsConnected) {
          isFirstTimeToSendLoggerErrorForThingsConnected = false;
          catchErrorRequestThingsConnected(error);
        }
      } finally {
        setIsLoadingRequest(false);
        isQueueRequest = false;
      }
    }
  };

  const checkIfThingIsConnected = (
    thingId: string
  ): Types.TConnectionStatus => {
    return thingsConnected.includes(thingId) ? 'connected' : 'disconnected';
  };

  const createIntervalRequestThingsConnected = () => {
    if (!refIntervalIdForRequestThingsConnected.current) {
      fetchThingsConnected();
    }

    refIntervalIdForRequestThingsConnected.current = setInterval(() => {
      fetchThingsConnected();
    }, MILLISECONDS_PER_REQUEST);
  };

  const clearIntervalRequestThingsConnected = () => {
    if (refIntervalIdForRequestThingsConnected.current) {
      clearInterval(refIntervalIdForRequestThingsConnected.current);
    }
  };

  useEffect(() => {
    if (isInitialRequest) {
      if (createIntervalRequest) {
        createIntervalRequestThingsConnected();
      } else {
        fetchThingsConnected();
      }
    }
    return () => {
      clearIntervalRequestThingsConnected();
    };
  }, [isInitialRequest]);

  useEffect(() => {
    if (query.receptor !== 'desk') {
      fetchThingsConnected();
    }
  }, [query.receptor]);

  return {
    isLoadingRequestThingsConnected: isLoadingRequest,
    thingsConnected,
    checkIfThingIsConnected
  };
};

export default useConnectionStatus;
