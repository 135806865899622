import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Header,
  Icon,
  SemanticCOLORS,
  SemanticICONS,
  Grid
} from 'semantic-ui-react';
import { TStatusDamageReport } from '../../types';
import * as Interfaces from '../../interfaces';

import './DamageStatus.scss';

interface PropsDamageStatus {
  damage: Interfaces.IDamageStatus;
  type: 'icon' | 'detail';
}

interface IIconAndLabel {
  name: string;
  nameIcon: SemanticICONS;
  colorIcon: SemanticCOLORS;
  title: string;
  description: string;
}

type TStatusElement = {
  [key in TStatusDamageReport]: IIconAndLabel;
};

const DAMAGE_STATUS_ELEMENTS: TStatusElement = {
  none: {
    name: 'none',
    colorIcon: 'green',
    nameIcon: 'thumbs up',
    title: 'damageReport.status.none.title',
    description: 'damageReport.status.none.description'
  },
  people: {
    name: 'people',
    nameIcon: 'user circle',
    colorIcon: 'black',
    title: 'damageReport.status.people.title',
    description: 'damageReport.status.people.description'
  },
  inventory: {
    name: 'inventory',
    nameIcon: 'shopping basket',
    colorIcon: 'black',
    title: 'damageReport.status.inventory.title',
    description: 'damageReport.status.inventory.description'
  },
  property: {
    name: 'home',
    nameIcon: 'home',
    colorIcon: 'black',
    title: 'damageReport.status.property.title',
    description: 'damageReport.status.property.description'
  },
  other: {
    name: 'other',
    nameIcon: 'comment alternate',
    colorIcon: 'black',
    title: 'damageReport.status.other.title',
    description: 'damageReport.status.other.description'
  }
};

export const DamageStatus = ({ damage, type }: PropsDamageStatus) => {
  const { t: translation } = useTranslation();

  const isAllOk =
    !damage.people && !damage.inventory && !damage.property && !damage.other;

  const renderStatus = ({
    nameIcon,
    colorIcon,
    title,
    description,
    name
  }: IIconAndLabel): JSX.Element => {
    return type === 'icon' ? (
      <Icon name={nameIcon} color={colorIcon} size='large' />
    ) : (
      <Grid.Column computer={8} tablet={8} mobile={16}>
        <Header
          as='h4'
          style={{ display: name !== 'other' ? 'flex' : 'none' }}
          className='detail-status'
          icon={nameIcon}
          content={translation(title as any)}
          subheader={translation(description as any)}
        />
      </Grid.Column>
    );
  };

  return (
    <Grid className='wrapper-damage-status'>
      <Grid.Row>
        {isAllOk && renderStatus(DAMAGE_STATUS_ELEMENTS.none)}
        {damage.people && renderStatus(DAMAGE_STATUS_ELEMENTS.people)}
        {damage.inventory && renderStatus(DAMAGE_STATUS_ELEMENTS.inventory)}
        {damage.property && renderStatus(DAMAGE_STATUS_ELEMENTS.property)}
        {damage.other && renderStatus(DAMAGE_STATUS_ELEMENTS.other)}
      </Grid.Row>
    </Grid>
  );
};

export default DamageStatus;
