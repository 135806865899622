import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavState } from '../../contexts/navigation';
import { useStatusRequest } from '../../contexts/request';
import Analytics from '../../controllers/Analytics';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

interface IProps extends Interfaces.IAnalytics {
  idLicense?: string;
  isTutorial?: boolean;
}

interface IUseLicenses {
  isLoadingRequest: {
    assign: boolean;
    fetch: boolean;
  };
  dataLicense: {
    listInfoDetail: Interfaces.ILicenseDetail;
  };
  sendAssign: (id: string, body: Interfaces.IBodyAssignLicense) => void;
  updateListLicense: (
    key: 'authorizations' | 'things',
    list: Interfaces.IThing[] | Interfaces.IAuthorization[]
  ) => void;
  refetchLicenseDetail: () => void;
  errorAssign: Interfaces.IErrorAssign;
}

interface IState {
  isLoading: IUseLicenses['isLoadingRequest'];
  listLicenseDetail: IUseLicenses['dataLicense']['listInfoDetail'];
  errorAssign: Interfaces.IErrorAssign;
}

const DEFAULT_LICENSE_DETAIL = {
  detail: null,
  things: [],
  authorizations: []
};

const DEFAULT_ERROR: Interfaces.IErrorAssign = {
  isError: false
};

const LoggerInstance = ReporterLogger.getInstance();

export const useLicenseDetail = ({
  idLicense,
  isTutorial,
  analytics
}: IProps): IUseLicenses => {
  const { t } = useTranslation();
  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });
  const { UpdateMessageResponse } = useStatusRequest();
  const { setIsTabOpen } = useNavState();

  const [listLicenseDetail, setListLicenseDetail] = useState<
    IState['listLicenseDetail']
  >(DEFAULT_LICENSE_DETAIL);
  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    fetch: true,
    assign: false
  });
  const [errorAssign, setIsErrorAssign] =
    useState<IState['errorAssign']>(DEFAULT_ERROR);

  const fetchLicense = async (): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }
    try {
      const { license } = await BackendIoT.getLicense(idLicense ?? '');
      const list: IState['listLicenseDetail'] = {
        detail: null,
        things: [],
        authorizations: []
      };

      if (license.id) {
        list.detail = license;
      }

      if (license.authorizations) {
        list.authorizations = license.authorizations;
      }

      if (license.things) {
        list.things = license.things;
      }

      setListLicenseDetail(list);
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        message: t('error.requests.licenses.fetch')
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed to get license detail - useLicenseDetail - "fetchLicense"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  useEffect(() => {
    setIsTabOpen(true);
    setIsErrorAssign(DEFAULT_ERROR);
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
  }, []);

  useEffect(() => {
    if (idLicense) {
      fetchLicense();
    }
  }, [idLicense]);

  const sendAssign = async (
    id: Types.TParamsLicenses['id'],
    body: Interfaces.IBodyAssignLicense
  ): Promise<void> => {
    try {
      setIsLoading({ ...isLoading, assign: true });
      setIsErrorAssign(DEFAULT_ERROR);
      await BackendIoT.assignLicense(id, body);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.assign.licenses'),
        message: t('success.requests.licenses.assign')
      };
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.assign.licenses'),
        message: t('error.requests.licenses.fetch')
      };
      LoggerInstance.error(
        'Failed to assign license - useLicenseDetail - "sendAssign"',
        error
      );
      setIsErrorAssign({
        status: error.status ?? 0,
        isError: true
      });
    } finally {
      if (!isTutorial) {
        UpdateMessageResponse(messageResponseRef.current);
      }
      setIsLoading({ ...isLoading, assign: false });
    }
  };

  const updateListLicense = (
    key: 'authorizations' | 'things',
    list: Interfaces.IThing[] | Interfaces.IAuthorization[]
  ): void => {
    setListLicenseDetail({
      [key]: list,
      ...listLicenseDetail
    });
  };

  const refetchLicenseDetail = async (): Promise<void> => {
    await fetchLicense();
  };

  return {
    isLoadingRequest: isLoading,
    sendAssign,
    updateListLicense,
    dataLicense: {
      listInfoDetail: listLicenseDetail
    },
    refetchLicenseDetail,
    errorAssign
  };
};

export default useLicenseDetail;
