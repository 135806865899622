import React, { SyntheticEvent } from 'react';
import { Header, Grid, Image, Segment, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../contexts/auth';
import { useSettings } from '../../contexts/settings';
import { useNavState } from '../../contexts/navigation';
import useAuthClient from '../../hooks/useAuthClient';
import { useThings } from '../../hooks/things/useThings';
import CommandsThings from '../../components/CommandsThings';
import Layout from '../../components/Layout';
import { THINGS_TYPES_LIST } from '../../config/constants';
import { getTotalThings } from '../../helpers';
import { IAnalyticsViewPage, IPropsRoutes } from '../../interfaces';
import names from '../../config/names';

import '../../scss/layout/Things.scss';

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.things.main,
  title: 'Things Main'
};

const Things = ({ isNavbar }: IPropsRoutes): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateSubsection } = useNavState();
  const { theme } = useSettings();
  const { customer, adminLevel } = useAuthState();
  const {
    isLoading,
    dataThings: { totalReceptors }
  } = useThings({ analytics: ANALYTICS_PAGE_VIEW });
  const { validateThingCount } = useAuthClient();

  const thingsTotal = validateThingCount(totalReceptors);

  const handlerClickRedirect = (e: SyntheticEvent, path: string): void => {
    e.preventDefault();
    updateSubsection(path);
    navigate(path);
  };

  const renderThings = (): JSX.Element => (
    <Grid padded stackable relaxed columns={4}>
      {Object.values(THINGS_TYPES_LIST).map(
        (thing, index) =>
          !thing.disabled && (
            <Grid.Row
              key={`receptor-${index.toString()}`}
              columns={2}
              as={Segment}
              className='container-things'
              onClick={(e: SyntheticEvent) =>
                handlerClickRedirect(e, THINGS_TYPES_LIST[thing.type].path)
              }
            >
              <Grid.Column
                key={thing.text}
                width={4}
                style={{ paddingRight: 0 }}
                className='image-column'
              >
                <Image
                  className={`image-${thing.type}`}
                  src={thing.image}
                  style={{ height: '100%', margin: 'auto' }}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <Header
                  as='h1'
                  inverted={theme !== 'light'}
                  className='header-thing'
                >
                  {t(THINGS_TYPES_LIST[thing.type].text as any)}
                  <Header.Subheader>
                    <Icon name='database' />
                    {isLoading ? (
                      <Icon loading name='spinner' />
                    ) : (
                      t('things.devices' as any, {
                        count: getTotalThings({
                          type: thing.type as string,
                          things: thingsTotal
                        })
                      })
                    )}
                  </Header.Subheader>
                </Header>
                <span className='explanation-thing'>
                  {t(thing.explanation as any)}
                </span>
                <Image
                  size='tiny'
                  src={thing.image}
                  className='exception-image'
                  style={{ display: 'none' }}
                />
              </Grid.Column>
            </Grid.Row>
          )
      )}
    </Grid>
  );

  const hasPermissionToSendCommands = adminLevel !== 'user';
  return (
    <Layout title={t('sections.things')} showNavbar={isNavbar}>
      <Segment basic className='wrapper-things'>
        {hasPermissionToSendCommands && (
          <CommandsThings customerId={customer} />
        )}
        <div className='container-things-list'>{renderThings()}</div>
      </Segment>
    </Layout>
  );
};

export default Things;
