import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthDispatch } from '../contexts/auth';
import { useClientState } from '../contexts/client';
import { useStatusRequest } from '../contexts/request';
import { useSettings } from '../contexts/settings';
import useClients from './useClients';
import Analytics from '../controllers/Analytics';
import BackendIoT from '../controllers/Backend/IoT';
import ReporterLogger from '../controllers/ReporterLogger';
import AuthClient from '../models/AuthClient';
import { validateEmail, saveActiveChangelogInLocalStorage } from '../helpers';
import names from '../config/names';
import * as Constants from '../config/constants';
import * as Types from '../types';
import * as Interfaces from '../interfaces';

interface IUseLogin {
  isSend: {
    code: boolean;
    password: boolean;
  };
  isLoading: boolean;
  isChangelog: boolean;
  fields: Interfaces.IFieldsLogin;
  fieldsShowPass: { [key: string]: boolean };
  view: Types.EViews;
  messageRequest: {
    type: 'error' | 'success' | 'warning' | 'none';
    content: string;
  };
  validations: {
    isViewLogin: boolean;
    isDisabledLogin: boolean;
    isViewDefaultPassword: boolean;
    isViewPasswordRequirements: boolean;
  };
  changeViewLink: () => void;
  managerStateView: () => void;
  resetMessageRequest: () => void;
  showFieldsPass: (fieldName: string) => void;
  showErrorMessage: (type: 'mail' | 'password') => void;
  updateValueFields: (fieldName: string, value: string) => void;
  updateUserInfo: () => void;
}

interface IState {
  view: IUseLogin['view'];
  fields: IUseLogin['fields'];
  isSend: IUseLogin['isSend'];
  isLoading: IUseLogin['isLoading'];
  message: IUseLogin['messageRequest'];
  isChangelog: IUseLogin['isChangelog'];
  fieldsPasswords: IUseLogin['fieldsShowPass'];
}

interface IParamsView {
  [key: string]: Types.EViews;
}

const {
  FIELDS_VALIDATION,
  NAVIGATION_OPTIONS,
  INITIAL_PATH_ADMIN_LEVEL,
  EXPIRED_CODE,
  ERRORS_COGNITO_LOGIN,
  ERRORS_BACKEND_LOGIN,
  DEFAULT_VALUES_FIELDS,
  DEFAULT_VALUES_FIELDS_PASS,
  PARAMS_URL_REDIRECT
} = Constants;

const { REQUEST_CODE, LOGIN, FORGOT_PASSWORD, CHANGE_DEFAULT_PASSWORD } =
  Types.EViews;

const DEFAULT_VIEW_LOGIN = LOGIN;

const DEFAULT_IS_SEND = {
  code: false,
  password: false
};

const paramsView: IParamsView = {
  login: LOGIN,
  forgotPassword: FORGOT_PASSWORD,
  changeDefaultPassword: CHANGE_DEFAULT_PASSWORD,
  newPassword: REQUEST_CODE
};

const AuthClientInstance = AuthClient.getInstance();
const LoggerInstance = ReporterLogger.getInstance();

export const useLogin = ({
  analytics
}: Interfaces.IAnalytics = {}): IUseLogin => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search: searchParamsUrl } = useLocation();
  const client = useClientState();
  const { unselectClient } = useClients();
  const dispatch = useAuthDispatch();
  const { isOpenReleaseNotes } = useSettings();

  const { activeModalInfo } = useStatusRequest();

  const [isChangelog, setIsChangelog] = useState<IState['isChangelog']>(false);
  const [isLoading, setIsLoading] = useState<IState['isLoading']>(false);
  const [isSend, setIsSend] = useState<IState['isSend']>(DEFAULT_IS_SEND);
  const [messageRequest, setMessageRequest] = useState<IState['message']>({
    content: '',
    type: 'none'
  });
  const [view, setView] = useState<IState['view']>(DEFAULT_VIEW_LOGIN);
  const [fields, setFields] = useState<IState['fields']>(DEFAULT_VALUES_FIELDS);
  const [fieldsShowPass, setFieldsShowPass] = useState<
    IState['fieldsPasswords']
  >(DEFAULT_VALUES_FIELDS_PASS);

  useEffect(() => {
    const checkIsLogged = async (): Promise<void> => {
      const hasActiveSession = await AuthClient.isLoggedIn();
      if (hasActiveSession) {
        AuthClient.logout();
      }
    };

    const checkIsSelectClient = (): void => {
      if (client.selected) {
        unselectClient();
      }
    };

    checkIsLogged();
    checkIsSelectClient();

    const {
      session: { key, value }
    } = PARAMS_URL_REDIRECT;
    const urlParams = new URLSearchParams(searchParamsUrl);

    if (urlParams.get(key) === value) {
      activeModalInfo(t('login.expiration'));
    }

    if (urlParams.has('view')) {
      const temporal: Types.EViews =
        paramsView[urlParams.get('view') as string] || LOGIN;
      setView(temporal);
    }

    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
  }, []);

  useEffect(() => {
    if (isOpenReleaseNotes) {
      setIsChangelog(true);
    }
  }, [isOpenReleaseNotes]);

  const storeDefaultInformation = (
    response: Interfaces.IRequestUserIoT
  ): void => {
    localStorage.setItem(names.storageKeys.userInfo, JSON.stringify(response));

    localStorage.setItem(
      names.storageKeys.navigation,
      JSON.stringify(NAVIGATION_OPTIONS.DEFAULT)
    );
  };

  const errorsLoginCatch = (error: any): void => {
    const urlRecoverPassword = `${window.location.origin}${names.paths.login}?view=forgotPassword`;

    const msgWarningExpiredCode = EXPIRED_CODE[error?.message];

    const msgError =
      ERRORS_COGNITO_LOGIN[error?.message || error?.name || error?.code] ||
      error?.message ||
      '';

    const msgErrorCustom = msgError.length
      ? msgError
      : ERRORS_BACKEND_LOGIN[error?.statusCode || error?.status] || '';

    const contentWarning = msgWarningExpiredCode || msgErrorCustom;

    const typeMessage = msgWarningExpiredCode ? 'warning' : 'error';

    const content = contentWarning.length
      ? t(contentWarning, { url: urlRecoverPassword })
      : t('error.unknown');
    setMessageRequest({ type: typeMessage, content });
  };

  const updateValueFields = (fieldName: string, value: string): void => {
    setFields({ ...fields, [fieldName]: value });
    setMessageRequest({ type: 'none', content: '' });
  };

  const resetMessageRequest = (): void => {
    setMessageRequest({ type: 'none', content: '' });
    setIsSend(DEFAULT_IS_SEND);
  };

  const storeUserInfo = async (isUpdate = false): Promise<void> => {
    try {
      dispatch({ type: Types.EActionsLoginReducer.request });
      const response = await BackendIoT.getUser();
      let route = names.paths.notFound;
      if (Object.keys(response.user).length) {
        const {
          user: { id, adminLevel, email, name, customer, customerLicensee }
        } = response;
        storeDefaultInformation(response.user);
        dispatch({
          type: Types.EActionsLoginReducer.login,
          payload: response.user
        });
        LoggerInstance.setUser(id, email);
        LoggerInstance.setCustomInformationIoT({
          user: {
            email,
            id
          },
          company: {
            id: customer,
            name,
            licensee: customerLicensee
          }
        });
        route = INITIAL_PATH_ADMIN_LEVEL[adminLevel as Types.TUserTypes];
      }
      navigate(!isUpdate ? route : names.paths.users);
    } catch (error) {
      dispatch({
        type: Types.EActionsLoginReducer.error,
        error: (error as Error).message
      });
      errorsLoginCatch(error);
      LoggerInstance.error(
        'Failed get the user - useLogin - "storeUserInfo"',
        error
      );
    } finally {
      setTimeout(() => resetMessageRequest(), 3500);
    }
  };

  const validateIsNewUser = async (email: string): Promise<boolean> => {
    let isNewUser = false;
    try {
      setIsLoading(true);
      const response = await BackendIoT.validateIsNewUser(email);
      if (response.isNewUser) {
        isNewUser = response.isNewUser;
      }
    } catch (error) {
      errorsLoginCatch(error);
      const isNotFoundEmail = error?.status === 404;
      if (!isNotFoundEmail) {
        LoggerInstance.error(
          'Failed validate is new user - useLogin - "validateIsNewUser"',
          error
        );
      }
    } finally {
      setIsLoading(false);
    }
    return isNewUser;
  };

  // TEMPORARY FUNCTION TO KNOW IF YOU ARE A NEW USER AND SHOW YOU THE TUTORIAL
  // ONLY NEW USERS ENTERING FOR THE FIRST TIME ARE THOSE WHO HAVE A TEMPORARY PASSWORD
  // TODO: REMOVE WHEN YOU HAVE THE INFORMATION IF YOU HAVE ALREADY SEEN THE TUTORIAL

  const saveNewUserLogin = async (): Promise<void> => {
    dispatch({
      type: Types.EActionsLoginReducer.newUser,
      isNewUser: true
    });
  };

  const sendAuthenticated = async (): Promise<void> => {
    const { email, pass } = fields;
    const emailTrimmed = email.trim();
    // TODO: REMOVE VALIDATIONS WHEN THE PROBLEM IS FIXED IN STAGING
    try {
      setIsLoading(true);
      const isDefault = await AuthClientInstance.isDefaultPassword(
        emailTrimmed,
        pass
      );
      if (!isDefault) {
        await AuthClientInstance.login(emailTrimmed, pass);
        if (isChangelog) {
          saveActiveChangelogInLocalStorage();
        }
        await storeUserInfo();
      } else {
        saveNewUserLogin();
        setView(CHANGE_DEFAULT_PASSWORD);
      }
    } catch (error) {
      errorsLoginCatch(error);
      const isErrorAuthorized =
        typeof error === 'object' &&
        Object.keys(error).includes('name') &&
        error?.name === 'NotAuthorizedException';

      if (!isErrorAuthorized) {
        LoggerInstance.error(
          'Failed send the authenticated - useLogin - "sendAuthenticated"',
          error
        );
      }
    } finally {
      setIsLoading(false);
      setTimeout(() => resetMessageRequest(), 3500);
    }
  };

  const sendChangeDefaultPassword = async (): Promise<void> => {
    const { changeNewPass } = fields;
    try {
      setIsLoading(true);
      await AuthClientInstance.completeNewPassword(changeNewPass);
      await storeUserInfo();
    } catch (error) {
      errorsLoginCatch(error);
      LoggerInstance.error(
        'Failed send change default password - useLogin - "sendChangeDefaultPassword"',
        error
      );
    } finally {
      setIsLoading(false);
      setTimeout(() => resetMessageRequest(), 3500);
    }
  };

  const sendForgotPassword = async (): Promise<void> => {
    const { forgotEmail } = fields;
    const forgotEmailTrimmed = forgotEmail.trim();
    try {
      setIsLoading(true);
      await AuthClient.sendForgotPassword(forgotEmailTrimmed);
      setIsSend({ ...isSend, code: true });

      // NOTE: Validation is because when you redirect here with search params the url is not updated just by changing the view
      const urlParams = new URLSearchParams(searchParamsUrl);
      if (urlParams.get('view')) {
        navigate(`${names.paths.login}?view=newPassword`);
      }
      setView(REQUEST_CODE);
    } catch (error) {
      errorsLoginCatch(error);
      LoggerInstance.error(
        'Failed send change forgot password - useLogin - "sendForgotPassword"',
        error
      );
    } finally {
      setIsLoading(false);
      setTimeout(() => resetMessageRequest(), 3500);
    }
  };

  const resendTemporaryPassword = async (): Promise<void> => {
    const { forgotEmail } = fields;
    const forgotEmailTrimmed = forgotEmail.trim();
    try {
      setIsLoading(true);
      await BackendIoT.resendTemporaryPassword(forgotEmailTrimmed);
      setIsSend({ ...isSend, password: true });
      setView(LOGIN);
    } catch (error) {
      errorsLoginCatch(error);
      LoggerInstance.error(
        'Failed send temporary password - useLogin - "resendTemporaryPassword"',
        error
      );
    } finally {
      setIsLoading(false);
      setTimeout(() => resetMessageRequest(), 4500);
    }
  };

  const sendForgotCodePassword = async (): Promise<void> => {
    const { forgotCode, forgotEmail, forgotNewPass } = fields;
    try {
      setIsLoading(true);
      const forgotEmailTrimmed = forgotEmail.trim();
      await AuthClient.sendForgotCodePassword(
        forgotEmailTrimmed,
        forgotCode,
        forgotNewPass
      );
      setView(LOGIN);
      setFields(DEFAULT_VALUES_FIELDS);
      setFieldsShowPass(DEFAULT_VALUES_FIELDS_PASS);
      setMessageRequest({
        type: 'success',
        content: t('success.login.resetPass')
      });
    } catch (error) {
      errorsLoginCatch(error);
      LoggerInstance.error(
        'Failed send change forgot code password - useLogin - "sendForgotCodePassword"',
        error
      );
    } finally {
      setIsLoading(false);
      setTimeout(() => resetMessageRequest(), 3500);
    }
  };

  const changeViewLink = (): void => {
    const isViewForPassword = [REQUEST_CODE, LOGIN].includes(view);
    setView(isViewForPassword ? FORGOT_PASSWORD : LOGIN);
    setFields(DEFAULT_VALUES_FIELDS);
    setFieldsShowPass(DEFAULT_VALUES_FIELDS_PASS);
    resetMessageRequest();
  };

  const showFieldsPass = (fieldName: string): void => {
    setFieldsShowPass({
      ...fieldsShowPass,
      [fieldName]: !fieldsShowPass[fieldName]
    });
  };

  const showErrorMessage = (type: 'mail' | 'password'): void => {
    setMessageRequest({
      type: 'error',
      content:
        type === 'mail'
          ? t('error.login.email')
          : t('error.login.passwordMatch')
    });
  };

  const validatorDefaultPassword = async (): Promise<void> => {
    const { changeNewPass, changeConfirmNewPass } = fields;
    if (changeNewPass !== changeConfirmNewPass) {
      showErrorMessage('password');
    } else {
      await sendChangeDefaultPassword();
    }
  };

  const validatorLogin = async (): Promise<void> => {
    const { email } = fields;
    const emailTrimmed = email.trim();
    if (!validateEmail(emailTrimmed)) {
      showErrorMessage('mail');
    } else {
      await sendAuthenticated();
    }
  };

  const validatorForgotPassword = async (): Promise<void> => {
    const { forgotEmail } = fields;
    const forgotEmailTrimmed = forgotEmail.trim();
    if (!validateEmail(forgotEmailTrimmed)) {
      showErrorMessage('mail');
    } else {
      const isNewUser = await validateIsNewUser(forgotEmailTrimmed);
      if (isNewUser) {
        await resendTemporaryPassword();
      } else {
        await sendForgotPassword();
      }
    }
  };

  const validatorForgotCodePassword = async (): Promise<void> => {
    const { forgotNewPass, forgotConfirmNewPass } = fields;
    if (forgotNewPass !== forgotConfirmNewPass) {
      showErrorMessage('password');
    } else {
      await sendForgotCodePassword();
    }
  };

  const managerStateView = (): Promise<void> => {
    switch (view) {
      case CHANGE_DEFAULT_PASSWORD:
        return validatorDefaultPassword();
      case FORGOT_PASSWORD:
        return validatorForgotPassword();
      case REQUEST_CODE:
        return validatorForgotCodePassword();
      default:
        return validatorLogin();
    }
  };

  const isDisabledLogin = Object.values(FIELDS_VALIDATION[view]).some(
    (key) => !fields[key]
  );
  const isViewDefaultPassword: boolean = CHANGE_DEFAULT_PASSWORD === view;
  const isViewPasswordRequirements: boolean = [LOGIN, FORGOT_PASSWORD].includes(
    view
  );
  const isViewLogin = LOGIN === view;

  const updateUserInfo = async (): Promise<void> => {
    await storeUserInfo(true);
  };

  return {
    view,
    fields,
    isSend,
    isLoading,
    isChangelog,
    fieldsShowPass,
    showFieldsPass,
    messageRequest,
    changeViewLink,
    managerStateView,
    updateValueFields,
    resetMessageRequest,
    updateUserInfo,
    showErrorMessage,
    validations: {
      isDisabledLogin,
      isViewLogin,
      isViewDefaultPassword,
      isViewPasswordRequirements
    }
  };
};

export default useLogin;
