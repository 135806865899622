import React, { SyntheticEvent } from 'react';
import Markdown from 'markdown-to-jsx';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Header,
  Icon,
  Segment,
  SemanticCOLORS
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../contexts/auth';
import { useNavState } from '../../contexts/navigation';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useGeneralRequests } from '../../hooks/useGeneralRequests';
import {
  formatDate,
  parseKeysTypes,
  parseStatus,
  getCounterThing,
  addressLicensesFormant,
  validateLocationZero,
  parseParamsThingDetail
  // formatDateValidUntil
} from '../../helpers';
import { FORMAT_DATE_CREATED, GROUP_PERMISSIONS } from '../../config/constants';
import DamageStatus from '../DamageStatus';
import DropdownActions from '../DropdownActions';
import names from '../../config/names';
import { IAddressLicense, IkeyText, IRedirect } from '../../interfaces';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

import '../../scss/layout/Cards.scss';

export interface PropsComponentViewCards {
  renderType: Types.TLayerTypes;
  data: any[];
  loadMore?: boolean;
  isSearch?: boolean;
  onClickCard?: (
    e: SyntheticEvent<HTMLElement>,
    data: any,
    permissions?: string
  ) => void;
  onDeleteCommand?: (
    command: Interfaces.ICommandScheduledDeleteElement
  ) => void;
  onDelete?: () => void;
  onEdit?: () => void;
  refetchLoadMore?: () => void;
  setModalParam?: ((e: SyntheticEvent<HTMLElement>, data: any) => void) | null;
}

const EPV: IkeyText = {
  xs: 1,
  sm: 2,
  md: 3
};

const ViewCards = ({
  renderType,
  data,
  loadMore,
  isSearch,
  onClickCard,
  onEdit,
  onDelete,
  onDeleteCommand,
  refetchLoadMore,
  setModalParam
}: PropsComponentViewCards): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { adminLevel, email: userEmail } = useAuthState();
  const { breakpoint } = useBreakpoint();
  const { groupPermission } = useNavState();

  const { resendTemporaryPassword } = useGeneralRequests();
  const { status } =
    useParams<Types.TParamsLicenses>() as Types.TParamsLicenses;
  const ITEMS_PER_VIEW = renderType === 'CLIENTS' ? 1 : EPV[breakpoint] || 4;

  const renderDropdownActions = ({
    id,
    name,
    email,
    isNewUser
  }: any): JSX.Element => {
    if (!setModalParam) {
      return <></>;
    }
    const validateEmail = renderType !== 'USERS' ? true : email !== userEmail;
    return (
      <div className='card-header-right'>
        <Dropdown
          as={Button}
          className='card-button-dropdown'
          circular
          icon='ellipsis vertical'
        >
          <Dropdown.Menu>
            {renderType === 'USERS' && isNewUser && (
              <Dropdown.Item
                icon='unlock'
                text={t('actions.resendTemporaryPassword')}
                onClick={() => {
                  resendTemporaryPassword({ id: email, name });
                }}
              />
            )}
            {onDelete && validateEmail && (
              <Dropdown.Item
                icon='trash'
                text={t('actions.delete')}
                onClick={(e: SyntheticEvent<HTMLElement>) => {
                  setModalParam(e, { id, name });
                  onDelete();
                }}
              />
            )}
            {onEdit && renderType === 'USERS' && (
              <Dropdown.Item
                icon='pencil'
                text={t('actions.edit')}
                onClick={(e: SyntheticEvent<HTMLElement>) => {
                  setModalParam(e, { id, name, email });
                  onEdit();
                }}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const renderStatus = (
    sharedWithEmail?: string,
    color?: SemanticCOLORS
  ): JSX.Element => {
    const shared = sharedWithEmail && status === 'available';
    return (
      <Segment basic className='statusLicense'>
        <Icon color={shared ? `grey` : color} name='circle' size='small' />
        {t(`licenses.state.${shared ? 'shared' : status}` as any)}
      </Segment>
    );
  };

  const handlerClickRedirect = (
    e: SyntheticEvent,
    { name, id, type: typePath, groupId }: IRedirect
  ) => {
    e.preventDefault();
    if (id) {
      const path = {
        pathname: names.paths.things.detail(typePath),
        search: parseParamsThingDetail(id, name, groupId)
      };

      navigate(path, { state: { name, id, groupId } });
    }
  };

  const renderLocation = (
    location: string,
    address: IAddressLicense
  ): JSX.Element => {
    const islocationHaveZero = validateLocationZero(location);
    const textLocation = !islocationHaveZero
      ? addressLicensesFormant(address)
      : t('error.noDataYet');
    const dataLocation: Interfaces.ICoordsAddress = {
      coords: location,
      address: textLocation
    };

    return (
      <Button
        className='button-location'
        onClick={(e) => {
          if (setModalParam) {
            setModalParam(e, dataLocation);
          }
        }}
        disabled={!location || islocationHaveZero}
      >
        {location ? textLocation : t('error.notAvailable')}
      </Button>
    );
  };

  const renderGroups = (): JSX.Element[] => {
    return data.map(
      ({ group, createdAt, groupName, subgroups, permissions }, index) => (
        <Card
          link
          key={`group-card-${index + 1}`}
          className='card-group'
          onClick={(e: SyntheticEvent<HTMLElement>) => {
            if (onClickCard) {
              onClickCard(
                e,
                { id: group, name: groupName },
                permissions || ['admin']
              );
            }
          }}
        >
          <Card.Content>
            <Card.Header className='card-header'>
              <div className='card-header-left'>
                <Checkbox className='card-check' style={{ display: 'none' }} />
                <span className='card-name'>{groupName}</span>
              </div>
              {adminLevel !== 'user' &&
                renderDropdownActions({ id: group, name: groupName })}
            </Card.Header>
            <Card.Meta>
              <span>{formatDate(createdAt, FORMAT_DATE_CREATED)}</span>
            </Card.Meta>
            <Card.Description>
              {permissions && (
                <Header as='h5' className='permission'>
                  <Icon
                    size='small'
                    name={GROUP_PERMISSIONS[permissions].icon}
                  />
                  <Header.Content>
                    {t(GROUP_PERMISSIONS[permissions].text)}
                  </Header.Content>
                </Header>
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className='card-icon-info'>
              <Header
                as='h6'
                icon='folder outline'
                content={t('viewType.cards.groups', { count: subgroups })}
              />
            </div>
          </Card.Content>
        </Card>
      )
    );
  };

  const renderSubgroups = (): JSX.Element[] => {
    return data.map(
      (
        { id, name, thingCount, userCount, subGroupCount, createdAt },
        index
      ) => (
        <Card
          link
          key={`group-card-${index + 1}`}
          className='card-group'
          onClick={(e: SyntheticEvent<HTMLElement>) => {
            if (onClickCard) {
              onClickCard(e, { id, name });
            }
          }}
        >
          <Card.Content>
            <Card.Header className='card-header'>
              {name}
              <span>{formatDate(createdAt, FORMAT_DATE_CREATED)}</span>
            </Card.Header>
            <Card.Description>
              <span>
                {`${t('tableList.header.devices')}: ${getCounterThing(
                  thingCount
                )}`}
              </span>
              <span>{`${t('tableList.header.users')}: ${userCount}`}</span>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            {`${t('tableList.header.subgroups')}: ${subGroupCount}`}
          </Card.Content>
        </Card>
      )
    );
  };

  const renderUsers = (): JSX.Element[] => {
    return data.map(
      (
        {
          name,
          email,
          color,
          meta,
          permissions,
          id,
          createdBySystem,
          isNewUser
        },
        index
      ) => (
        <Card key={`user-card-${index + 1}`} className='card-group' raised>
          <Card.Content>
            <Card.Header className='header-divider card-header'>
              <div className='card-header-left'>
                <span className='card-name'>{name}</span>
              </div>
              {!createdBySystem &&
                adminLevel !== 'user' &&
                renderDropdownActions({ id, name, email, isNewUser })}
            </Card.Header>
            <Card.Meta className='card-meta'>
              <span>
                <Icon color={color} name='circle' size='small' />
                {t(meta)}
              </span>
              <span className='collapse-texts'>{email}</span>
            </Card.Meta>
          </Card.Content>
          <Card.Content extra className='card-extra'>
            <Header as='h4' icon='user' content={t(permissions)} />
          </Card.Content>
        </Card>
      )
    );
  };

  const renderLicenses = (): JSX.Element[] => {
    return data.map((element, index) => {
      const { id, name, type, sharedWithEmail, location, address } = element;
      const licenseColor = Constants.LICENSES_TYPES_LIST[status]
        ?.color as SemanticCOLORS;
      return (
        <Card
          key={`licenses-card-${index + 1}`}
          className='card-license'
          raised
        >
          <Card.Header className='card-header-link licenses-header'>
            <Link
              className='link-name-licenses'
              to={
                status
                  ? names.paths.licenses.detail(status, id)
                  : names.paths.dashboard
              }
            >
              <span className='card-name-link'>{name}</span>
            </Link>
            <span>
              <Segment basic className='statusLicense'>
                <Link
                  className='link-status-licenses'
                  to={
                    status
                      ? names.paths.licenses.detail(status, id)
                      : names.paths.dashboard
                  }
                >
                  {renderStatus(sharedWithEmail, licenseColor)}
                </Link>
                {adminLevel === 'SkyAlertLicensee' && (
                  <DropdownActions
                    type={renderType}
                    onEdit={onEdit}
                    element={element}
                  />
                )}
              </Segment>
            </span>
          </Card.Header>
          <Link
            className='link-card'
            to={
              status
                ? names.paths.licenses.detail(status, id)
                : names.paths.dashboard
            }
          >
            <Card.Content>
              <Card.Meta className='card-meta'>
                <span className='link' style={{ marginBottom: '1em' }}>
                  {id}
                </span>
                {/* <span>{dateUntil}</span> */}
              </Card.Meta>

              <span>{renderLocation(location, address)}</span>
            </Card.Content>
          </Link>
          <Card.Content extra className='card-extra'>
            <Header as='h4' icon='computer' content={type} />
          </Card.Content>
        </Card>
      );
    });
  };

  const renderThings = (): JSX.Element[] => {
    return data.map((thingElement, index) => {
      const {
        type: receptor,
        name,
        id: uuid,
        commandGroup = '',
        connectionStatus,
        location,
        address,
        license
      }: Interfaces.IThingsTypeItem = thingElement;

      const { text: connection, color: iconColor } =
        parseStatus(connectionStatus);

      const parseLocation = Array.isArray(location)
        ? location.join(', ').toString()
        : '';

      return (
        <Card key={`thing-card-${index + 1}`} className='card-thing' raised>
          <Card.Header className='card-header-link'>
            {/* <EditNameThing
              nameThing={name}
              uuid={uuid}
              receptor={receptor}
              commandGroup={commandGroup}
              handlerClickRedirect={handlerClickRedirect}
              onEdit={onEdit}
              hasPermission={adminLevel !== 'user'}
            /> */}
            <Link
              className='link-name'
              to={{
                pathname: names.paths.things.detail(receptor),
                search: parseParamsThingDetail(uuid, name, commandGroup)
              }}
              onClick={(e) =>
                handlerClickRedirect(e, {
                  name,
                  type: receptor,
                  id: uuid,
                  groupId: commandGroup
                } as Interfaces.IRedirect)
              }
            >
              {name}
            </Link>
          </Card.Header>
          <Link
            className='link-card'
            to={{
              pathname: names.paths.things.detail(receptor),
              search: parseParamsThingDetail(uuid, name, commandGroup)
            }}
            onClick={(e) =>
              handlerClickRedirect(e, {
                name,
                type: receptor,
                id: uuid,
                groupId: commandGroup
              } as IRedirect)
            }
          >
            <Card.Content>
              <Card.Meta className='card-meta'>
                <span className='link'>{uuid}</span>
                <strong>{license?.id || t('error.notAvailable')}</strong>
                {/* <span> {formatDateValidUntil(license?.validUntil || '')}</span> */}
                <span>{parseKeysTypes('RECEPTOR', receptor)}</span>
              </Card.Meta>
            </Card.Content>
          </Link>
          <div className='container-card-data'>
            {renderLocation(parseLocation, address)}
          </div>
          <Card.Content extra className='card-extra'>
            <div className='card-icon-info'>
              <Icon name='circle' color={iconColor} size='small' />
              {connection}
            </div>
          </Card.Content>
        </Card>
      );
    });
  };

  const renderControlThings = (): JSX.Element[] => {
    const renderCardHeader = ({
      id,
      actionName,
      sendingTimeFormat,
      disabledCancelled
    }: Interfaces.IParseCommandSchedule): JSX.Element => {
      return (
        <div className='card-header-command'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>{id}</p>
            <strong>{actionName}</strong>
          </div>
          <Button
            circular
            icon='trash alternate'
            disabled={groupPermission !== 'admin' || disabledCancelled}
            onClick={() =>
              onDeleteCommand &&
              onDeleteCommand({ id, actionName, sendingTimeFormat })
            }
          />
        </div>
      );
    };

    return data.map((command, index) => (
      <Card
        key={`command-schedule-${index + 1}`}
        className='card-command'
        header={renderCardHeader(command)}
        meta={t('control.schedule.card.date', {
          date: command.sendingTimeFormat
        })}
        description={command.status}
      />
    ));
  };

  const renderMonitorThings = (): JSX.Element[] => {
    return data.map((element: Interfaces.IParseMonitorThings, index) => {
      const {
        name,
        type: thingType,
        connectionStatus,
        customer,
        customerName
      } = element;
      const { text: connectionText, color: iconColor } =
        parseStatus(connectionStatus);
      return (
        <Card key={`monitor-card-${index + 1}`} className='card-monitor' raised>
          <Card.Header className='card-header-link'>{name}</Card.Header>
          <Card.Content className='card-content'>
            {renderType === 'MONITOR-THINGS-ALL' && (
              <span>
                <Markdown>
                  {t('monitor.card.thingType', {
                    thingType: parseKeysTypes('RECEPTOR', thingType)
                  })}
                </Markdown>
              </span>
            )}
            <span>
              <Markdown>
                {t('monitor.card.idCommercial', { customer })}
              </Markdown>
            </span>
            <span className=''>
              <Markdown>{t('monitor.card.client')}</Markdown>
              {!customerName.length ? (
                <span className='not-available'>{t('error.notAvailable')}</span>
              ) : (
                <>{customerName}</>
              )}
            </span>
            <Button
              className='button-more-info'
              onClick={(e) => {
                if (setModalParam) {
                  setModalParam(e, element);
                }
              }}
            >
              {t('actions.detail')}
            </Button>
          </Card.Content>
          <Card.Content extra className='card-extra'>
            <div className='card-icon-info'>
              <Icon name='circle' color={iconColor} size='small' />
              {connectionText}
            </div>
          </Card.Content>
        </Card>
      );
    });
  };

  const renderDamageReport = (): JSX.Element[] => {
    return data.map(
      ({
        name,
        uuid,
        earthquakeDate,
        reportDate,
        damage,
        location,
        address,
        comments
      }: Interfaces.IParseDamageReport) => {
        const dataInfo: Interfaces.IMoreInfoReport = {
          thingName: name,
          earthquakeDate,
          reportDate,
          damage,
          comments
        };

        return (
          <Card
            key={`damage-report-card-${uuid}`}
            className='card-damage-report'
            raised
          >
            <Card.Header className='card-header'>
              <span className='name'>{name}</span>
              <span>{uuid}</span>
            </Card.Header>
            <Card.Content className='card-content'>
              <Header
                as='h4'
                content={t('damageReport.modal.dateReport', {
                  date: reportDate
                })}
              />
              <Card.Meta className='card-meta'>
                {renderLocation(location, address)}
              </Card.Meta>
              <DamageStatus damage={damage} type='icon' />
              <Button
                className='button-more-info'
                onClick={(e) => {
                  if (setModalParam) {
                    setModalParam(e, dataInfo);
                  }
                }}
              >
                {t('damageReport.moreInfo')}
              </Button>
            </Card.Content>
            <Card.Content extra className='card-extra'>
              <Header
                as='h4'
                content={t('damageReport.modal.dateEarthquake', {
                  date: earthquakeDate
                })}
              />
            </Card.Content>
          </Card>
        );
      }
    );
  };

  const renderCards = (): JSX.Element[] | JSX.Element => {
    switch (renderType) {
      case 'GROUPS':
        return renderGroups();
      case 'SUBGROUPS':
        return renderSubgroups();
      case 'USERS':
        return renderUsers();
      case 'LICENSES':
        return renderLicenses();
      case 'THINGS':
        return renderThings();
      case 'CONTROL-THINGS':
        return renderControlThings();
      case 'MONITOR-THINGS-ALL':
        return renderMonitorThings();
      case 'MONITOR-THINGS':
        return renderMonitorThings();
      case 'DAMAGE-REPORT':
        return renderDamageReport();
      default:
        return <Card />;
    }
  };

  return (
    <Segment basic className='wrapper-view'>
      <Card.Group itemsPerRow={ITEMS_PER_VIEW} className='wrapper-cards'>
        {renderCards()}
      </Card.Group>
      {loadMore && !isSearch && (
        <Button
          content={t('actions.loadMore')}
          onClick={refetchLoadMore}
          className='button-loadMore load-more'
        />
      )}
    </Segment>
  );
};

ViewCards.defaultProps = {
  loadMore: false,
  isSearch: false,
  onClickCard: () => null,
  onDelete: () => null,
  onEdit: () => null,
  onDeleteCommand: () => null,
  refetchLoadMore: () => null,
  setModalParam: null
};

export default ViewCards;
