import React, { useState, useEffect } from 'react';
import { Header, Icon, Placeholder, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  GoogleMap,
  GoogleMapProps,
  useJsApiLoader,
  Marker,
  MarkerProps
} from '@react-google-maps/api';
import ReporterLogger from '../../controllers/ReporterLogger';
import { useSettings } from '../../contexts/settings';
import Empty from '../Empty';
import ThemeDark from './theme/dark';
import ThemeLight from './theme/light';

export interface PropsComponentMap {
  children?: React.ReactNode;
  coords: GoogleMapProps['center'];
  onLoadMap?: (map: google.maps.Map) => void;
  optionsMap?: GoogleMapProps['options'];
  withMarker?: MarkerProps;
}

const styles = {
  map: {
    height: '100%',
    width: '100%'
  }
};

const LoggerInstance = ReporterLogger.getInstance();

export const Map = ({
  coords,
  children,
  optionsMap,
  withMarker,
  onLoadMap,
  ...props
}: PropsComponentMap): JSX.Element => {
  const { t } = useTranslation('common', { keyPrefix: 'maps' });
  const { theme } = useSettings();

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'script-loader',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? ''
  });

  const [location, setLocation] = useState<GoogleMapProps['center']>(undefined);

  useEffect(() => {
    if (coords?.lat && coords?.lng) {
      setLocation(coords);
    }
  }, [coords]);

  const renderLoading = (): JSX.Element => (
    <Placeholder>
      <Placeholder.Image style={{ height: '100%', maxWidth: '100%' }} />
    </Placeholder>
  );

  const renderMap = (): JSX.Element => {
    if (!coords?.lat && !coords?.lng) {
      return (
        <Segment className='map-empty-placeholder' placeholder>
          <Header icon disabled>
            <Icon name='map' />
            {t('notAvailable')}
          </Header>
        </Segment>
      );
    }

    const styleMap = theme !== 'light' ? ThemeDark : ThemeLight;
    return (
      <GoogleMap
        {...props}
        mapContainerStyle={styles.map}
        center={location}
        onLoad={onLoadMap}
        options={{ ...optionsMap, styles: styleMap }}
      >
        {children}
        {withMarker ? <Marker {...withMarker} /> : <></>}
      </GoogleMap>
    );
  };

  if (loadError) {
    LoggerInstance.error('Failed GoogleMap render - Map');
    return <Empty iconName='map signs' texts={{ title: t('error') }} />;
  }

  return isLoaded ? renderMap() : renderLoading();
};

Map.defaultProps = {
  onLoadMap: () => null,
  children: <div />,
  optionsMap: {},
  withMarker: undefined
};

export default React.memo(Map);
