import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownProps, Grid, Icon } from 'semantic-ui-react';
import * as Constants from '../../config/constants';
import * as Types from '../../types';

export interface PropsComponentLanguage {
  select: Types.TLanguage;
  onChangeLanguage: (e: SyntheticEvent, value: DropdownProps) => void;
}

const Language = ({ select, onChangeLanguage }: PropsComponentLanguage) => {
  const { t } = useTranslation();

  const TRANSLATE_OPTIONS_LANGUAGE = Object.values(
    Constants.OPTIONS_LANGUAGE
  ).map((item) => ({ ...item, text: t(item.text as any) }));

  return (
    <Grid.Row data-testid='language-component'>
      <Grid.Column width={4} textAlign='left'>
        <Icon name='language' style={{ marginRight: 8 }} />
        <span style={{ textAlign: 'left' }}>{t('settings.language')}</span>
      </Grid.Column>
      <Grid.Column width={12}>
        <Dropdown
          selection
          options={TRANSLATE_OPTIONS_LANGUAGE}
          onChange={onChangeLanguage}
          value={select}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default Language;
