import React, { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../../controllers/Analytics';
import { useNavState } from '../../../contexts/navigation';
import { SkeletonThingsCount } from '../../../components/Skeletons';
import Empty from '../../../components/Empty';
import { THINGS_TYPES_LIST } from '../../../config/constants';
import { getTotalThings, receptorType } from '../../../helpers';
import * as Constants from '../../../config/constants';
import * as Interfaces from '../../../interfaces';

import './ThingsCount.scss';

export interface PropsComponentLicensesStatus {
  isLoading: boolean;
  totalThings: Interfaces.IThingsTotal;
}

const ThingsCount = ({
  isLoading,
  totalThings
}: PropsComponentLicensesStatus): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateSubsection } = useNavState();

  const handlerClickRedirect = (e: SyntheticEvent, path: string): void => {
    e.preventDefault();
    updateSubsection(path);
    navigate(path);

    Analytics.sendEventCustom({
      category: 'Dashboard',
      action: 'Redirect',
      label: 'Things'
    });
  };

  const renderState = (): JSX.Element => {
    if (isLoading) {
      return <SkeletonThingsCount />;
    }

    const noThings =
      !totalThings.desk && !totalThings.epicenter && !totalThings.gadget;
    if (noThings) {
      const { texts, icon } = Constants.PROPS_EMPTY_LAYER_CONTAINER.THINGS;
      return (
        <Empty
          iconName={icon}
          texts={{
            title: t(texts.title as any),
            subtitle: t(texts.subtitle as any)
          }}
        />
      );
    }

    return (
      <>
        {Object.values(THINGS_TYPES_LIST).map(
          (thing, index) =>
            !thing.disabled && (
              <Segment
                key={`receptor-${index.toString()}`}
                onClick={(e: SyntheticEvent) =>
                  handlerClickRedirect(e, THINGS_TYPES_LIST[thing.type].path)
                }
                className={`container-things ${receptorType(`${thing.type}`)}`}
              >
                <Header as='h3' className='header-thing'>
                  {t(THINGS_TYPES_LIST[thing.type].text as any)}
                  <span>
                    {t('things.devices' as any, {
                      count: getTotalThings({
                        type: thing.type as string,
                        things: totalThings
                      })
                    })}
                  </span>
                </Header>
              </Segment>
            )
        )}
      </>
    );
  };
  return (
    <Segment className='wrapper-things-count'>
      <Header
        as='h2'
        content={t('sections.things')}
        className='title-section'
      />
      {renderState()}
    </Segment>
  );
};

export default ThingsCount;
