import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStatusRequest } from '../../contexts/request';
import Analytics from '../../controllers/Analytics';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import { countTotalThingsConnections } from '../../helpers';
import {
  TOTAL_CONNECT_TRACKING,
  DEFAULT_VALUE_NEXT_REQUEST
} from '../../config/constants';
import * as Interfaces from '../../interfaces';

interface IProps extends Interfaces.IAnalytics {
  groupId: string;
}

interface IUseGroupsThings {
  isLoadingRequest: {
    fetch: boolean;
    create: boolean;
  };
  dataGroups: {
    listInfoThings: Interfaces.IGroupThingItems[];
    totalConnections: Interfaces.ITotalDevices;
    thingsPrevSelected: string[];
  };
  sendCreate: (name: Interfaces.IBodyGroupThingCreate) => Promise<void>;
  refetchGroupThings: () => Promise<void>;
  getMoreGroupsThings: () => Promise<void>;
  nextRequest: Interfaces.INextRequest;
}

interface IState {
  query: Interfaces.IQuery;
  isLoading: IUseGroupsThings['isLoadingRequest'];
  listGroupsThings: IUseGroupsThings['dataGroups']['listInfoThings'];
  listSelectedThings: IUseGroupsThings['dataGroups']['thingsPrevSelected'];
  totalThingsConnection: IUseGroupsThings['dataGroups']['totalConnections'];
}

const DEFAULT_QUERY: IState['query'] = { limit: 25 };

const LoggerInstance = ReporterLogger.getInstance();

export const useGroupsThings = ({
  groupId,
  analytics
}: IProps): IUseGroupsThings => {
  const { t } = useTranslation();
  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });
  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    fetch: true,
    create: false
  });
  const [listGroupsThings, setListGroupsThings] = useState<
    IState['listGroupsThings']
  >([]);
  const [listSelectedThings, setListSelectedThings] = useState<
    IState['listSelectedThings']
  >([]);
  const [totalThingsConnections, setTotalThingsConnections] = useState<
    IState['totalThingsConnection']
  >(TOTAL_CONNECT_TRACKING);
  const [nextRequest, setNextRequest] = useState<
    IUseGroupsThings['nextRequest']
  >(DEFAULT_VALUE_NEXT_REQUEST);
  const [query, setQuery] = useState<IState['query']>(DEFAULT_QUERY);

  const fetchGroupThings = async (isRequest = false): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }
    try {
      const response = await BackendIoT.getThingsInGroup(groupId, query);
      let newNextRequest = DEFAULT_VALUE_NEXT_REQUEST;
      let newListGroupThings: Interfaces.IGroupThingItems[] = [];
      let newTotalConnected: Interfaces.ITotalTracking = {
        registered: 0,
        disconnected: 0
      };
      let newListSelectedThings: string[] = [];
      const { things, lastItem } = response;
      if (things.length) {
        newListGroupThings =
          nextRequest.isLoadMore && !isRequest
            ? [...listGroupsThings, ...things]
            : things;
        newTotalConnected = countTotalThingsConnections(newListGroupThings);
        newListSelectedThings = newListGroupThings.map((item) => item.thing);

        if (lastItem) {
          newNextRequest = {
            isLoadMore: !!newListGroupThings.length,
            lastItem
          };
        }
      }

      setListGroupsThings(newListGroupThings);
      setTotalThingsConnections(newTotalConnected);
      setListSelectedThings(newListSelectedThings);
      setNextRequest(newNextRequest);
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.group.fetchThings')
      });
      LoggerInstance.error(
        'Failed to get things in group - useGroupsThings - "fetchGroupThings"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
  }, []);

  useEffect(() => {
    fetchGroupThings();
  }, [query]);

  const refetchGroupThings = async (): Promise<void> => {
    if (query.lastItem) {
      setQuery(DEFAULT_QUERY);
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
    } else {
      await fetchGroupThings(true);
    }
  };

  const sendCreate = async (
    bodyModal: Interfaces.IBodyGroupThingCreate
  ): Promise<void> => {
    try {
      setIsLoading({ ...isLoading, create: true });
      if (!bodyModal.thingIds.length) {
        throw String('not send list required');
      }

      await BackendIoT.createGroupThing(groupId, bodyModal);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.add.thing'),
        message: t('success.requests.groups.createThing')
      };
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.add.thing'),
        message: t('error.requests.groups.createThing')
      };
      LoggerInstance.error(
        'Failed to add thing to group - useGroupsThings - "sendCreate"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, create: false });
    }

    refetchGroupThings();
  };

  const getMoreGroupsThings = async (): Promise<void> => {
    const paramsQuery = {
      ...query,
      lastItem: JSON.stringify(nextRequest.lastItem)
    };
    setQuery(paramsQuery);
  };

  return {
    isLoadingRequest: isLoading,
    nextRequest,
    sendCreate,
    getMoreGroupsThings,
    refetchGroupThings,
    dataGroups: {
      listInfoThings: listGroupsThings,
      totalConnections: totalThingsConnections,
      thingsPrevSelected: listSelectedThings
    }
  };
};

export default useGroupsThings;
