import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Header,
  InputProps,
  Message,
  Modal,
  Segment
} from 'semantic-ui-react';
import { useAuthState } from '../../../contexts/auth';
import BackendRedSkyAlert from '../../../controllers/Backend/RedSkyAlert';
import ReporterLogger from '../../../controllers/ReporterLogger';
import MapLicenses from '../../Map/MapLicenses';
import Empty from '../../Empty';
import { parseCoordsToAddress } from '../../../helpers/geocoding';
import * as Constants from '../../../config/constants';
import * as Interfaces from '../../../interfaces';

import '../../../scss/layout/Modal.scss';

const DEFAULT_CENTER: Interfaces.ICoords = {
  lat: 0,
  lng: 0
};

export interface PropsComponentModalLocationThing {
  location: Interfaces.ICoords | null;
  address: Interfaces.IAddressLicense | null;
  isOpen: boolean;
  isLoading: boolean;
  onAccept(data: Interfaces.ILocationThing): void;
  onCancel(): void;
}

type IErrorLocation = { [key: string]: boolean | string };

const DEFAULT_ERROR_MESSAGE: IErrorLocation = {
  location: false,
  coverage: false,
  coordLat: '',
  coordLng: ''
};

const DEFAULT_NEW_DATA: Interfaces.IDataLocationThing = {
  id: '',
  county: '',
  state: '',
  zipCode: '',
  location: DEFAULT_CENTER
};

const LoggerInstance = ReporterLogger.getInstance();

const ModalEditLocationThing = ({
  location,
  address,
  isOpen,
  isLoading,
  onCancel,
  onAccept
}: PropsComponentModalLocationThing): JSX.Element => {
  const { t: translations } = useTranslation();
  const { isLicensee } = useAuthState();

  const [isError, setIsError] = useState<IErrorLocation>(DEFAULT_ERROR_MESSAGE);
  const [isLoadingZipCode, setIsLoadingZipCode] = useState<boolean>(false);
  const [coords, setCoords] = useState<Interfaces.ICoords>(DEFAULT_CENTER);
  const [coordsInput, setCoordsInput] =
    useState<Interfaces.ICoords>(DEFAULT_CENTER);

  const checkIsCoordsCorrect = (coordsCheck: Interfaces.ICoords): boolean => {
    return [coordsCheck.lat, coordsCheck.lng].every((value) => value !== 0);
  };

  const resetStates = (): void => {
    setCoords(DEFAULT_CENTER);
    setCoordsInput(DEFAULT_CENTER);
    setIsError(DEFAULT_ERROR_MESSAGE);
  };

  useEffect(() => {
    if (isOpen) {
      resetStates();
      if (location) {
        const parsedLocation = location as Interfaces.ICoords;
        if (parsedLocation.lat && parsedLocation.lng) {
          setCoordsInput(parsedLocation);
          setCoords(parsedLocation);
        }
      }
    }
  }, [isOpen]);

  const validateIsCoordsCorrect = (
    coordsCheck: Interfaces.ICoords
  ): boolean => {
    let isCorrect = true;
    const requiredFieldError = translations(
      'modals.labels.thingLocation.errorField'
    );
    const invalidZeroError = translations(
      'modals.labels.thingLocation.errorZeroCoord'
    );

    if (!coordsCheck.lat) {
      setIsError({
        ...isError,
        coordLat: coordsCheck.lat === 0 ? invalidZeroError : requiredFieldError
      });
      isCorrect = false;
    }

    if (!coordsCheck.lng) {
      setIsError({
        ...isError,
        coordLng: coordsCheck.lng === 0 ? invalidZeroError : requiredFieldError
      });
      isCorrect = false;
    }

    return isCorrect;
  };

  const handleOnNewCoords = (newCoords: Interfaces.ICoords): void => {
    setCoords(newCoords);
    setCoordsInput(newCoords);
    setIsError({ coordLat: '', coordLng: '', location: false });
  };

  const handleOnchangeInputCoords = (
    e: SyntheticEvent,
    { value, name }: InputProps
  ): void => {
    e.preventDefault();
    setCoordsInput({
      ...coords,
      [name]: value.replace(/[a-zÀ-ÿñÑ]/gi, '')
    });
    setIsError({ coordLat: '', coordLng: '', location: false });
    setCoords({ ...coords, [name]: parseFloat(value) });
  };

  const handlerOnAccept = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    const editLocation = location || DEFAULT_CENTER;

    let parsedAddress = null;

    if (!validateIsCoordsCorrect(coords)) {
      return;
    }

    if (checkIsCoordsCorrect(coords)) {
      if (editLocation.lat !== coords.lat || editLocation.lng !== coords.lng) {
        parsedAddress = await parseCoordsToAddress(coords);
        const isVanuatuLocation = ['Sanma Province', 'vanuatu'].includes(
          parsedAddress?.state || ''
        );

        if (isVanuatuLocation) {
          parsedAddress = {
            county: 'Sanma',
            state: 'Vanuatu',
            zipCode: '00000'
          };
        }

        if (!parsedAddress || !parsedAddress.zipCode) {
          LoggerInstance.error(
            'Failed parsed coords to address - Modal Edit Location Thing'
          );
          setIsError({ ...isError, location: true });
          return;
        }

        if (!isLicensee || (isLicensee && !isVanuatuLocation)) {
          setIsLoadingZipCode(true);
          try {
            const { hasCoverage } =
              await BackendRedSkyAlert.getCoverageByZipCode(
                parsedAddress.zipCode
              );

            if (!hasCoverage) {
              throw new Error('No coverage');
            }
          } catch (error) {
            LoggerInstance.error('Failed to get coverage by zip code', error);
            setIsError({ ...isError, coverage: true });
            return;
          } finally {
            setIsLoadingZipCode(false);
          }
        }
      }
    }

    const dataLocation = parsedAddress || address || DEFAULT_NEW_DATA;
    const newCoords: Interfaces.ICoords = parsedAddress ? coords : editLocation;

    onAccept({
      address: dataLocation,
      coords: [newCoords.lng, newCoords.lat]
    });
  };

  const renderMap = (): JSX.Element => {
    if (!checkIsCoordsCorrect(coords)) {
      return (
        <Segment placeholder className='placeholder-map'>
          <Empty
            iconName='map marker alternate'
            texts={{
              title: translations('modals.placeholders.mapLocationThing.title')
            }}
          />
        </Segment>
      );
    }
    return (
      <MapLicenses
        isDraggable={!isLoading || !isLoadingZipCode}
        isDisable={isLoading}
        setNewCoords={handleOnNewCoords}
        center={coords as Interfaces.ICoords}
        options={Constants.OPTIONS_MAP_EDIT_LOCATION_THING}
      />
    );
  };

  const keyView = !isLicensee ? 'user' : 'licensee';

  const errorMessage: { [key: string]: string } = {
    location: translations('maps.notAvailable'),
    coverage: translations('maps.notCoverage')
  };

  const keyNameError =
    Object.values(['location', 'coverage']).find(
      (key) => typeof isError[key] === 'boolean' && isError[key]
    ) || '';

  const isErrorMessage = keyNameError.length > 0;
  return (
    <Modal
      size='small'
      open={isOpen}
      className='wrapper-modal wrapper-modal-edit-thing-location'
      data-testid='modal-thing-location-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={translations('modals.titles.edit.thingLocation', {
            id: ''
          })}
          icon='map marker alternate'
        />
      </Modal.Header>
      <Modal.Content>
        <Form widths='equal' autoComplete='off'>
          <Header
            as='h5'
            content={translations(
              `modals.labels.thingLocation.view.${keyView}` as const
            )}
          />
          <Form.Field
            style={{ height: '250px', marginBottom: '0.7em' }}
            error={isError.location}
          >
            {renderMap()}
          </Form.Field>
          <Form.Group widths={2}>
            <Form.Input
              name='lat'
              value={coordsInput.lat}
              error={
                typeof isError.coordLat === 'string' &&
                !!isError.coordLat.length &&
                isError.coordLat
              }
              disabled={isLoading || isLoadingZipCode}
              label={translations('modals.labels.thingLocation.coords.lat')}
              placeholder={translations(
                'modals.labels.thingLocation.coords.lat'
              )}
              onChange={handleOnchangeInputCoords}
            />
            <Form.Input
              name='lng'
              value={coordsInput.lng}
              disabled={isLoading || isLoadingZipCode}
              error={
                typeof isError.coordLng === 'string' &&
                !!isError.coordLng.length &&
                isError.coordLng
              }
              label={translations('modals.labels.thingLocation.coords.lng')}
              placeholder={translations(
                'modals.labels.thingLocation.coords.lng'
              )}
              onChange={handleOnchangeInputCoords}
            />
          </Form.Group>
          <Message
            hidden={!isErrorMessage}
            negative
            size='small'
            icon='exclamation triangle'
            className='message-error-modal-thing-location'
            header={translations('error.warning')}
            content={errorMessage[keyNameError]}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={onCancel}
          content={translations('actions.cancel')}
          data-testid='button-cancel-modal-thing-location'
        />
        <Button
          positive
          disabled={isLoading || isLoadingZipCode}
          onClick={handlerOnAccept}
          loading={isLoading || isLoadingZipCode}
          content={translations('actions.accept')}
          data-testid='button-accept-modal-thing-location'
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalEditLocationThing;
