import { useEffect, useState } from 'react';
import moment from 'moment';
import Analytics from '../controllers/Analytics';
import BackendIoT from '../controllers/Backend/IoT';
import ModelDamage from '../models/ReportDamage';
import { DEFAULT_VALUE_NEXT_REQUEST } from '../config/constants';
import * as Interfaces from '../interfaces';

interface IUseDamageReport {
  isLoading: boolean;
  nextRequest: Interfaces.INextRequest;
  dataDamageReport: Interfaces.IParseDamageReport[];
  getMoreData: () => Promise<void>;
  refetchDataDamageReport: () => Promise<void>;
}

interface IState {
  query: Interfaces.IQuery;
  isLoading: IUseDamageReport['isLoading'];
  nextRequest: IUseDamageReport['nextRequest'];
  dataDamageReport: IUseDamageReport['dataDamageReport'];
}

interface IUseDamageReportProps {
  groupId: string;
  analytics: Interfaces.IAnalytics['analytics'];
}

const DEFAULT_QUERY: IState['query'] = { limit: 25 };

export const useDamageReport = ({
  groupId,
  analytics
}: IUseDamageReportProps): IUseDamageReport => {
  const [isLoading, setIsLoading] = useState<IState['isLoading']>(true);
  const [dataDamageReport, setDataDamageReport] = useState<
    IState['dataDamageReport']
  >([]);
  const [nextRequest, setNextRequest] = useState<IState['nextRequest']>(
    DEFAULT_VALUE_NEXT_REQUEST
  );
  const [query, setQuery] = useState<IState['query']>(DEFAULT_QUERY);

  const parseDataItemReport = (rawData: Interfaces.IDamageReport) =>
    new ModelDamage().init(rawData).parseReport();

  const fetchDataDamageReport = async (): Promise<void> => {
    if (!isLoading) {
      setIsLoading(true);
    }

    let newListDamageReport: Interfaces.IParseDamageReport[] = [];

    try {
      const currentDate = moment().format('YYYY-MM-DD');
      const response = await BackendIoT.getReportDamage(groupId, {
        ...query,
        date: currentDate
      });

      const { damageReports } = response;

      newListDamageReport = damageReports.map((report) =>
        parseDataItemReport(report)
      );

      setDataDamageReport(newListDamageReport);
    } catch (e) {
      console.log('Error in fetch response', e);
    } finally {
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }

    fetchDataDamageReport();
  }, []);

  const refetchDataDamageReport = async (): Promise<void> => {
    if (query.lastItem) {
      setDataDamageReport([]);
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
      setQuery(DEFAULT_QUERY);
    } else {
      await fetchDataDamageReport();
    }
  };

  const getMoreData = async (): Promise<void> => {
    const paramsQuery = {
      ...query,
      lastItem: JSON.stringify(nextRequest.lastItem)
    };
    setQuery(paramsQuery);
  };

  return {
    isLoading,
    getMoreData,
    nextRequest,
    dataDamageReport,
    refetchDataDamageReport
  };
};
