import React, { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useGroups } from '../../hooks/groups/useGroups';
import Analytics from '../../controllers/Analytics';
import Layout from '../../components/Layout';
import Layer from '../../components/Layer';
import ModalCreate from '../../components/Modals/Create/Group';
import ModalConfirm from '../../components/Modals/Delete/Confirm';
import { IPropsCtxRoutes, IAnalyticsViewPage } from '../../interfaces';
import names from '../../config/names';

import '../../scss/layout/Groups.scss';

interface IModalConfirm {
  id: string;
  name: string;
}

interface IState {
  selectGroup: IModalConfirm;
}

const DEFAULT_SELECT_GROUP = {
  id: '',
  name: ''
};

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.groups.main,
  title: 'Group'
};

const Groups = ({ isNavbar }: IPropsCtxRoutes): JSX.Element => {
  const { t } = useTranslation();
  const {
    isLoadingRequest,
    refetchGroups,
    dataGroups: { listInfo },
    sendCreate,
    sendDelete,
    getMoreGroups,
    nextRequest: { isLoadMore }
  } = useGroups({ analytics: ANALYTICS_PAGE_VIEW });

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [selectGroup, setSelectGroup] =
    useState<IState['selectGroup']>(DEFAULT_SELECT_GROUP);

  const clearSelectGroup = (): void => {
    setSelectGroup(DEFAULT_SELECT_GROUP);
  };

  const handlerModalAccept = async (name: string): Promise<void> => {
    await sendCreate(name);
    setIsOpenModal(!isOpenModal);
    refetchGroups();

    Analytics.sendEventCustom({
      category: 'Group',
      action: 'Create',
      label: 'Group'
    });
  };

  const handlerModalAcceptConfirm = async (): Promise<void> => {
    const { id, name } = selectGroup;
    await sendDelete(id, name);
    setIsOpenModalConfirm(!isOpenModalConfirm);
    refetchGroups();
    clearSelectGroup();

    Analytics.sendEventCustom({
      category: 'Group',
      action: 'Delete',
      label: 'Group'
    });
  };

  const handlerSetParam = (
    e: SyntheticEvent<HTMLElement>,
    params: IModalConfirm
  ): void => {
    e.preventDefault();
    setSelectGroup(params);
  };

  return (
    <Layout showNavbar={isNavbar} title={t('sections.groups')}>
      <Layer
        section='GROUPS'
        data={listInfo}
        loadMore={isLoadMore}
        fieldToSearch='groupName'
        onCreate={() => setIsOpenModal(!isOpenModal)}
        onDelete={() => setIsOpenModalConfirm(!isOpenModalConfirm)}
        onRefresh={refetchGroups}
        setModalParam={handlerSetParam}
        loading={isLoadingRequest.fetch}
        refetchLoadMore={getMoreGroups}
      />
      <ModalCreate
        isOpen={isOpenModal}
        isLoading={isLoadingRequest.create}
        onCancel={() => setIsOpenModal(!isOpenModal)}
        onAccept={handlerModalAccept}
      />
      <ModalConfirm
        header={{ title: t('modals.titles.warning') }}
        isOpen={isOpenModalConfirm}
        isLoading={isLoadingRequest.delete}
        onCancel={() => {
          setIsOpenModalConfirm(!isOpenModalConfirm);
          clearSelectGroup();
        }}
        onAccept={handlerModalAcceptConfirm}
      >
        {t('modals.paragraphs.confirm.delete', { element: selectGroup.name })}
      </ModalConfirm>
    </Layout>
  );
};

export default Groups;
