import React, { useEffect, useState } from 'react';
import AuthClient from './models/AuthClient';
import { saveActiveChangelogInLocalStorage } from './helpers';
import UpdateMessage from './components/UpdateMessage';

export interface PropsServiceWorkerWrapper {
  onLoad: () => void;
  isOpenMessage: boolean;
}

const ServiceWorkerWrapper = ({
  onLoad,
  isOpenMessage
}: PropsServiceWorkerWrapper): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenMessage);

  useEffect(() => {
    setIsOpen(isOpenMessage);
  }, [isOpenMessage]);

  const reloadPage = (): void => {
    setIsOpen(false);
    AuthClient.cleanInfoStorage();
    saveActiveChangelogInLocalStorage();
    AuthClient.redirectLogin(false, false);
    onLoad();
  };

  return <UpdateMessage onUpdate={reloadPage} isOpen={isOpen} />;
};

export default ServiceWorkerWrapper;
