import names from '../config/names';
import * as Interfaces from '../interfaces';

class AuthUsers {
  private static instanceUser: AuthUsers;

  private refreshToken: Interfaces.IAuthUsers['token'];

  private accessToken: Interfaces.IAuthUsers['token'];

  private keyStorage: string;

  constructor() {
    if (AuthUsers.instanceUser) {
      throw new Error(
        'Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.'
      );
    }
    AuthUsers.instanceUser = this;

    this.refreshToken = '';
    this.accessToken = '';
    this.keyStorage = names.storageKeys.userAccess;
  }

  public static getUserInstance(): AuthUsers {
    return AuthUsers.instanceUser;
  }

  public getAccessToken(): Interfaces.IAuthUsers['token'] {
    return this.accessToken;
  }

  public setTokens({
    refreshToken,
    accessToken
  }: Interfaces.IAuthUsers['tokens']): this {
    this.refreshToken = refreshToken;
    this.accessToken = accessToken;
    return this;
  }

  public resetToken(): void {
    this.accessToken = '';
  }

  public toJson() {
    const { refreshToken, accessToken } = this;
    return {
      refreshToken,
      accessToken
    };
  }

  public loadFromDefaults(): this {
    const tokenStorage = localStorage.getItem(this.keyStorage) || '';
    const parseTokens = tokenStorage ? JSON.parse(tokenStorage) : '';
    if (parseTokens) {
      this.accessToken = parseTokens.accessToken;
      this.refreshToken = parseTokens.refreshToken;
    }
    return this;
  }

  public saveToDefaults(): this {
    localStorage.setItem(this.keyStorage, JSON.stringify(this.toJson()));
    return this;
  }

  public removeFromDefaults(): this {
    localStorage.removeItem(this.keyStorage);
    return this;
  }
}

export default AuthUsers;
