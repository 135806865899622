import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStatusRequest } from '../../contexts/request';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import useAuthClient from '../useAuthClient';
import * as Helpers from '../../helpers';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

interface IProps extends Interfaces.IAnalytics {
  type: Types.TReceptorTypes;
}

interface IUseThingsByType {
  isLoading: {
    fetch: boolean;
    edit: boolean;
  };
  dataThings: {
    listInfoByType: Interfaces.IThingsTypeItem[];
    listFiltered: Interfaces.IThingsTypeItem[];
    totalConnections: Interfaces.ITotalTracking;
  };
  updateListThings: (list: Interfaces.IThingsTypeItem[]) => void;
  updateListSearchFilter: (value: any) => void;
  refetchThings: () => Promise<void>;
  getMoreThings: () => Promise<void>;
  nextRequest: Interfaces.INextRequest;
}

interface IState {
  query: Interfaces.IQueryThingsByReceptor;
  loading: IUseThingsByType['isLoading'];
  listThingsByType: IUseThingsByType['dataThings']['listInfoByType'];
  listSearchFilterRef: IUseThingsByType['dataThings']['listFiltered'];
  totalThingsConnection: IUseThingsByType['dataThings']['totalConnections'];
}

const { TOTAL_CONNECT_TRACKING, DEFAULT_VALUE_NEXT_REQUEST } = Constants;

const DEFAULT_LOADING = {
  fetch: false,
  edit: false
};

const LIMIT_PER_REQUEST = 50;

const LoggerInstance = ReporterLogger.getInstance();

export const useThingsByType = ({ type }: IProps): IUseThingsByType => {
  const { t } = useTranslation();
  const { customerId } = useAuthClient();
  const { UpdateMessageResponse } = useStatusRequest();

  const receptor = Helpers.receptorType(type);

  const DEFAULT_QUERY: IState['query'] = {
    receptor,
    limit: LIMIT_PER_REQUEST,
    customerId,
    licenses: true
  };

  const [isLoading, setIsLoading] =
    useState<IState['loading']>(DEFAULT_LOADING);
  const [listThingsByType, setListThingsByType] = useState<
    IState['listThingsByType']
  >([]);
  const [listSearchFiltered, setListSearchFiltered] = useState<
    IState['listSearchFilterRef']
  >([]);
  const [totalThingsConnections, setTotalThingsConnections] = useState<
    IState['totalThingsConnection']
  >(TOTAL_CONNECT_TRACKING);
  const [nextRequest, setNextRequest] = useState<
    IUseThingsByType['nextRequest']
  >(DEFAULT_VALUE_NEXT_REQUEST);
  const [query, setQuery] = useState<IState['query']>(DEFAULT_QUERY);

  const fetchThingsByStatus = async (isRefetch = false): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }
    try {
      const response = await BackendIoT.getThingsByType({
        ...query,
        receptor: Helpers.receptorQueryParam(receptor)
      });
      let newListThings: Interfaces.IThingsTypeItem[] = [];
      let newNextRequest = DEFAULT_VALUE_NEXT_REQUEST;
      let newTotalConnected: Interfaces.ITotalTracking = {
        registered: 0,
        disconnected: 0
      };
      const { things, lastItem } = response;
      if (things.length || (!things.length && nextRequest.isLoadMore)) {
        newListThings =
          nextRequest.isLoadMore && !isRefetch
            ? [...listThingsByType, ...things]
            : things;
        newTotalConnected = Helpers.countTotalThingsConnections(newListThings);
      }

      if (lastItem) {
        newNextRequest = {
          isLoadMore: !!newListThings.length,
          lastItem
        };
      }

      setNextRequest(newNextRequest);
      setListThingsByType(newListThings);
      setTotalThingsConnections(newTotalConnected);
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.things.fetch')
      });
      LoggerInstance.error(
        'Failed fetch things by type - useThingsByType - "fetchThingsByStatus"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  useEffect(() => {
    fetchThingsByStatus();
  }, [query]);

  useEffect(() => {
    setListSearchFiltered(listThingsByType);
  }, [listThingsByType]);

  useEffect(() => {
    setListThingsByType([]);
    if (receptor !== query.receptor) {
      setQuery({ ...DEFAULT_QUERY, receptor, customerId });
    }
  }, [type]);

  const updateListThings = (list: Interfaces.IThingsTypeItem[]): void => {
    setListThingsByType(list);
  };

  const updateListSearchFilter = (value: any) => {
    setListSearchFiltered(value);
  };

  const refetchThings = async (): Promise<void> => {
    if (query.lastItem) {
      setQuery(DEFAULT_QUERY);
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
    } else {
      await fetchThingsByStatus(true);
    }
  };

  const getMoreThings = async (): Promise<void> => {
    const paramsQuery = {
      ...query,
      lastItem: JSON.stringify(nextRequest.lastItem)
    };
    setQuery(paramsQuery);
  };

  return {
    isLoading,
    nextRequest,
    refetchThings,
    getMoreThings,
    updateListThings,
    updateListSearchFilter,
    dataThings: {
      listInfoByType: listThingsByType,
      listFiltered: listSearchFiltered,
      totalConnections: totalThingsConnections
    }
  };
};

export default useThingsByType;
