import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Label, ButtonProps } from 'semantic-ui-react';
import { IControlsCommandsList } from '../../interfaces';

export interface PropsButtonControl extends ButtonProps {
  element: IControlsCommandsList;
  isHidden?: boolean;
}

const ButtonControl = ({
  element,
  isHidden,
  ...restProps
}: PropsButtonControl): JSX.Element => {
  const { t } = useTranslation();

  if (isHidden) {
    return <></>;
  }

  return (
    <div
      className='container-button'
      style={{ order: element.order || 'unset' }}
    >
      <Button
        fluid
        animated='fade'
        data-testid='control-button-component'
        color={element.color || 'grey'}
        {...restProps}
      >
        <Button.Content visible>
          {element.icon && <Icon name={element.icon} />}
          {t(`${element.texts}.visible` as any)}
        </Button.Content>
        <Button.Content hidden>
          {t(`${element.texts}.hidden` as any)}
          &nbsp; <Icon name='arrow right' />
        </Button.Content>
      </Button>
      {element.name === 'customAudio' && (
        <Label floating content={t('actions.new')} color='red' />
      )}
    </div>
  );
};

ButtonControl.defaultProps = {
  isHidden: false
};

export default ButtonControl;
