import { SemanticCOLORS } from 'semantic-ui-react';
import { ACCOUNT_PERMISSION } from '../config/constants';
import * as Interfaces from '../interfaces';

interface IParseUsers {
  id: string;
  name: string;
  status: string;
  permissions: string;
  color: string;
  meta: string;
  email: string;
  createdBySystem: boolean;
  isNewUser: boolean;
}

const CREATE_BY_SYSTEM = '#SYSTEM#';

class Users {
  private id: Interfaces.IUsersItems['id'];

  private name: Interfaces.IUsersItems['name'];

  private status: Interfaces.IUsersItems['status'];

  private permissions: string;

  private color: SemanticCOLORS;

  private meta: string;

  private email: Interfaces.IUsersItems['email'];

  private createdBySystem: boolean;

  private isNewUser: Interfaces.IUsersItems['isNewUser'];

  constructor() {
    this.id = '';
    this.name = '';
    this.status = 'active';
    this.permissions = '';
    this.color = 'green';
    this.meta = '';
    this.email = '';
    this.createdBySystem = false;
    this.isNewUser = false;
  }

  init(user: Interfaces.IUsersItems): this {
    this.id = user.id;
    this.name = user.name;
    this.status = user.status;
    this.permissions =
      ACCOUNT_PERMISSION[user.adminLevel] ?? 'error.notAvailable';
    this.color = user.status !== 'deleted' ? 'green' : 'grey';
    this.meta =
      user.status !== 'deleted'
        ? 'users.status.active'
        : 'users.status.inactive';
    this.email = user.email;
    this.createdBySystem =
      user.createdBy === CREATE_BY_SYSTEM || user.adminLevel === 'owner';
    this.isNewUser = user.isNewUser;
    return this;
  }

  parseUsers(): IParseUsers {
    return {
      id: this.id,
      name: this.name,
      status: this.status ? this.status : 'active',
      permissions: this.permissions,
      color: this.color,
      meta: this.meta,
      email: this.email,
      createdBySystem: this.createdBySystem,
      isNewUser: this.isNewUser
    };
  }
}

export default Users;
