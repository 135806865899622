import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, InputProps, Modal } from 'semantic-ui-react';
import Analytics from '../../controllers/Analytics';
import ReporterLogger from '../../controllers/ReporterLogger';
import { useAuthState } from '../../contexts/auth';
import { useLicensesByStatus } from '../../hooks/licenses/useLicensesByStatus';
import { useLicenseDetail } from '../../hooks/licenses/useLicenseDetail';
import AssignLicenses from './components/AssignLicenses';
import ContentStep from './components/ContentStep';
import Steps from './components/Steps';
import images from '../../config/images';
import { validateEmail, validateFullName } from '../../helpers';
import { EViewsTutorial } from '../../types';
import * as Interfaces from '../../interfaces';
import * as Constants from '../../config/constants';

import './Tutorial.scss';

export interface PropsTutorial {
  isOpen: boolean;
  onClose: () => void;
}

const DEFAULT_STEP_INITIAL = 0;
const MAX_LICENSES_TO_ASSIGN = 3;
const DEFAULT_GOING_ASSIGN: Interfaces.IGoingToAssign = {
  defaultState: true,
  select: false,
  cancel: false
};
const DEFAULT_LICENSE_DATA: Interfaces.IParseLicensesAssignTutorial = {
  id: '',
  name: '',
  email: '',
  firstName: '',
  lastName: '',
  errors: {
    email: false,
    firstName: false,
    lastName: false
  }
};

const DEFAULT_LIST_TO_SEND: string[] = [];

const LICENSES_TYPE_ALLOW: Interfaces.IParseLicensesByStatus['receptor'][] = [
  'desk',
  'desk-plus'
];

const LoggerInstance = ReporterLogger.getInstance();

const Tutorial = ({ isOpen, onClose }: PropsTutorial): JSX.Element => {
  const { t: translation } = useTranslation();
  const { customer, adminLevel, isLicensee } = useAuthState();

  const havePermission = adminLevel !== 'user' && !isLicensee;
  const stepsPermission = Constants.STEP_PERMISSIONS[adminLevel];
  const TITLE_STEPS = Constants.STEPS_ONBOARDING_TITLES[adminLevel];
  const TOTAL_STEPS = TITLE_STEPS.length;
  const ASSIGN_STEP = stepsPermission.indexOf(EViewsTutorial.assignLicensee);

  const {
    isLoadingRequest: { fetch: loadingFetch },
    dataLicenses: { listParsed }
  } = useLicensesByStatus({ status: 'available', isTutorial: true });
  const { sendAssign, isLoadingRequest, errorAssign } = useLicenseDetail({
    isTutorial: true
  });

  const [stepActive, setStepActive] = useState<number>(DEFAULT_STEP_INITIAL);
  const [licensesDesk, setLicensesDesk] =
    useState<Interfaces.IListParseLicensesAssignTutorial>({});
  const [listIdLicensesDesk, setListIdLicensesDesk] = useState<string[]>([]);
  const [listIdLicensesToSend, setListIdLicensesToSend] =
    useState<string[]>(DEFAULT_LIST_TO_SEND);
  const [isAssignLicenses, setIsAssignLicenses] = useState<boolean>(false);
  const [isEmptyOrErrorForms, setIsEmptyOrErrorForms] = useState<boolean>(true);
  const [isGoingToAssign, setIsGoingToAssign] =
    useState<Interfaces.IGoingToAssign>(DEFAULT_GOING_ASSIGN);

  const parseLicensesDesk = (): void => {
    let parseListDesk: Interfaces.IListParseLicensesAssignTutorial = {};
    let listLicenses: string[] = [];

    const filterList = listParsed.filter(
      (license) =>
        LICENSES_TYPE_ALLOW.includes(license.receptor) &&
        !license.sharedWithEmail
    );

    if (filterList.length) {
      const deskLicenses =
        filterList.length > MAX_LICENSES_TO_ASSIGN
          ? filterList.slice(0, MAX_LICENSES_TO_ASSIGN)
          : filterList;
      listLicenses = deskLicenses.map((license) => license.id);
      deskLicenses.forEach(({ id, name }) => {
        parseListDesk = {
          ...parseListDesk,
          [id]: {
            ...DEFAULT_LICENSE_DATA,
            id,
            name
          }
        };
      });
    }

    setListIdLicensesDesk(listLicenses);
    setLicensesDesk(parseListDesk);
  };

  const toggleIsAssign = (): void => {
    setIsAssignLicenses(!isAssignLicenses);
  };

  const clearStates = (): void => {
    if (listParsed.length) {
      parseLicensesDesk();
    }
    setIsAssignLicenses(false);
    setIsGoingToAssign(DEFAULT_GOING_ASSIGN);
    setListIdLicensesToSend(DEFAULT_LIST_TO_SEND);
  };

  const checkEmptyAndErrorForms = (
    newData: Interfaces.IListParseLicensesAssignTutorial
  ): boolean => {
    let emptyData = false;
    let isError = false;

    listIdLicensesToSend.forEach((license) => {
      const { email, firstName, lastName, errors } = newData[license];
      const emptyDataLicense =
        !email.length || !firstName.length || !lastName.length;
      const isErrorLicense =
        errors.email || errors.firstName || errors.lastName;
      if (!emptyData && emptyDataLicense) {
        emptyData = true;
      }
      if (!isError && isErrorLicense) {
        isError = true;
      }
    });

    return emptyData || isError;
  };

  useEffect(() => {
    if (listIdLicensesToSend.length) {
      setIsEmptyOrErrorForms(checkEmptyAndErrorForms(licensesDesk));
    }
  }, [listIdLicensesToSend]);

  useEffect(() => {
    if (isOpen) {
      clearStates();
      Analytics.sendEventCustom({
        category: 'Tutorial',
        action: 'Open'
      });
    }
  }, [listParsed, isOpen]);

  const clearStatesAndClose = (): void => {
    onClose();
    setStepActive(DEFAULT_STEP_INITIAL);
  };

  const handlerPrimaryAction = (): void => {
    const nextStep = stepActive + 1;
    setStepActive(nextStep);

    if (nextStep === TOTAL_STEPS) {
      clearStatesAndClose();
    }
  };

  const handlerSecondaryAction = (): void => {
    const lastStep = stepActive - 1;

    if (stepActive === ASSIGN_STEP && errorAssign.isError) {
      clearStates();
    }

    setStepActive(lastStep);
  };

  const handlerAddLicensesToSend = (licensesId: string): void => {
    setListIdLicensesToSend((prevState) => [...prevState, licensesId]);
  };

  const handlerRemoveLicensesToSend = (licensesId: string): void => {
    setListIdLicensesToSend((prevState) =>
      prevState.filter((license) => license !== licensesId)
    );
  };

  const handlerMangesErrorInput = (name: string, value: string): boolean => {
    let isError = false;

    if (name !== 'email') {
      isError = !validateFullName(value);
    } else {
      isError = !validateEmail(value);
    }

    return isError;
  };

  const handlerOnchangeInputAssign = (
    e: SyntheticEvent,
    { value, name }: InputProps,
    licenseId: string
  ): void => {
    e.preventDefault();

    const isError = handlerMangesErrorInput(name, value);

    const errorsLicense = {
      ...licensesDesk[licenseId].errors,
      [name]: isError
    };

    const licensesData = {
      ...licensesDesk[licenseId],
      errors: errorsLicense,
      [name]: value
    };

    const newData = {
      ...licensesDesk,
      [licenseId]: licensesData
    };

    setLicensesDesk(newData);
    setIsEmptyOrErrorForms(checkEmptyAndErrorForms(newData));
  };

  const handlerAssignLicenses = (): void => {
    let totalAssignLicenses = 0;

    listIdLicensesToSend.forEach(async (license) => {
      const { id, email, firstName, lastName } = licensesDesk[license];
      const body = {
        email,
        customer,
        userName: { first: firstName, last: lastName }
      };
      try {
        await sendAssign(id, body);
      } catch (error) {
        LoggerInstance.error('Failed assign licenses - Tutorial', error);
      } finally {
        totalAssignLicenses += 1;
        if (totalAssignLicenses === listIdLicensesToSend.length) {
          toggleIsAssign();
          Analytics.sendEventCustom({
            category: 'Tutorial',
            action: 'Assign'
          });
        }
      }
    });
  };

  const handlerGoingToAssign = (
    key: 'select' | 'cancel' | 'defaultState',
    value: boolean
  ) => {
    setIsGoingToAssign((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  const renderContent = (): JSX.Element => {
    const textErrorFinal = errorAssign.isError ? 'finallyErrorAssign' : 'four';
    const textDescriptionFinal =
      (isGoingToAssign.cancel || !isGoingToAssign.select) &&
      listIdLicensesDesk.length &&
      havePermission
        ? 'cancelAssign'
        : textErrorFinal;

    switch (stepsPermission[stepActive]) {
      case EViewsTutorial.initial:
        return (
          <ContentStep
            title={translation('onBoarding.step.one.title')}
            description={translation('onBoarding.step.one.content')}
            image={images.tutorial.skyAlertTutorial}
          />
        );
      case EViewsTutorial.safety:
        return (
          <ContentStep
            title={translation('onBoarding.step.two.title')}
            description={translation('onBoarding.step.two.content')}
            image={images.tutorial.safetyTutorial}
          />
        );
      case EViewsTutorial.assignLicensee:
        return (
          <AssignLicenses
            errorAssign={errorAssign}
            havePermission={havePermission}
            isLicensee={isLicensee}
            isLoadingRequest={loadingFetch || isLoadingRequest.assign}
            isAssignLicenses={isAssignLicenses}
            isGoingToAssign={isGoingToAssign}
            handlerAssign={handlerGoingToAssign}
            nextStep={handlerPrimaryAction}
            tryAgain={() => {
              setIsAssignLicenses(false);
            }}
            handlerAddLicensesToSend={handlerAddLicensesToSend}
            handlerRemoveLicensesToSend={handlerRemoveLicensesToSend}
            licensesList={licensesDesk}
            idLicenses={listIdLicensesDesk}
            idLicensesToSend={listIdLicensesToSend}
            onChangeInput={handlerOnchangeInputAssign}
          />
        );
      case EViewsTutorial.final:
        return (
          <ContentStep
            title={translation(`onBoarding.step.${textErrorFinal}.title`)}
            description={translation(
              `onBoarding.step.${textDescriptionFinal}.content`
            )}
            image={images.tutorial.openTutorial}
            isErrorLicenseAssign={errorAssign.isError}
            note={
              errorAssign.isError
                ? translation('onBoarding.step.finallyErrorAssign.note')
                : ''
            }
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <Modal
      size='large'
      dimmer='blurring'
      open={isOpen}
      data-testid='tutorial-component'
      className='wrapper-tutorial'
    >
      <Modal.Header className='header-tutorial'>
        <Header as='h2' content={translation('onBoarding.title')} />
      </Modal.Header>
      <Modal.Content scrolling>
        <Steps
          steps={TITLE_STEPS}
          stepActive={stepActive}
          assignStep={ASSIGN_STEP}
          licensesTotal={listIdLicensesDesk.length}
          isErrorAssign={errorAssign.isError}
          havePermission={havePermission}
        />
        {renderContent()}
      </Modal.Content>
      <Modal.Actions>
        {stepActive !== DEFAULT_STEP_INITIAL && (
          <Button onClick={handlerSecondaryAction} className='secondary-action'>
            {translation('actions.former')}
          </Button>
        )}
        {!isAssignLicenses &&
        stepActive === ASSIGN_STEP &&
        !!listIdLicensesDesk.length &&
        havePermission &&
        isGoingToAssign.select &&
        !isGoingToAssign.cancel ? (
          <Button
            color='orange'
            className='licenses-action'
            onClick={handlerAssignLicenses}
            disabled={isLoadingRequest.assign || isEmptyOrErrorForms}
            loading={isLoadingRequest.assign}
          >
            {translation('actions.send')}
          </Button>
        ) : (
          <Button
            color='orange'
            className='primary-action'
            onClick={handlerPrimaryAction}
            disabled={
              stepActive === ASSIGN_STEP &&
              isGoingToAssign.defaultState &&
              !!listIdLicensesDesk.length &&
              havePermission
            }
          >
            {translation(
              `actions.${stepActive !== TOTAL_STEPS - 1 ? 'next' : 'finish'}`
            )}
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default Tutorial;
