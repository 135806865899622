import React, { useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import {
  Grid,
  Header,
  Segment,
  Table,
  Button,
  Label,
  Divider,
  Icon
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import { useSettings } from '../../contexts/settings';
import { useNavState } from '../../contexts/navigation';
import { useLicenseDetail } from '../../hooks/licenses/useLicenseDetail';
import { SkeletonDetailLicense } from '../../components/Skeletons';
import BreadcrumbCustom from '../../components/Breadcrumb';
import Empty from '../../components/Empty';
import Layout from '../../components/Layout';
import TableList from '../../components/TableList';
import ModalAssign from '../../components/Modals/Assign';
import {
  formatDate,
  parseKeysTypes,
  buildThingGroupId,
  parseParamsThingDetail,
  formatDateValidUntil
} from '../../helpers';
import {
  LICENSES_TYPES_LIST,
  HEADER_LIST_LICENSE_AUTHORIZATIONS,
  HEADER_LIST_LICENSE_RECEPTORS
} from '../../config/constants';
import {
  IPropsRoutes,
  IAnalyticsViewPage,
  ILicenseIoT
} from '../../interfaces';
import { TParamsLicenses } from '../../types';
import names from '../../config/names';

import '../../scss/layout/LicensesDetail.scss';
import { useAuthState } from '../../contexts/auth';

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.licenses.matchDetail,
  title: 'Licenses Detail'
};

const LicensesDetail = ({ isNavbar }: IPropsRoutes) => {
  const { t } = useTranslation();
  const { id, status: licenseStatus } =
    useParams<TParamsLicenses>() as TParamsLicenses;
  const { updateSection, updateSubsection } = useNavState();
  const { theme } = useSettings();
  const { isLicensee } = useAuthState();
  const {
    sendAssign,
    isLoadingRequest,
    updateListLicense,
    refetchLicenseDetail,
    dataLicense: {
      listInfoDetail: { detail, things, authorizations }
    }
  } = useLicenseDetail({ idLicense: id, analytics: ANALYTICS_PAGE_VIEW });

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const toggleIsOpen = (): void => {
    setIsOpenModal(!isOpenModal);
  };

  const handleOnAcceptModalAssign = async (
    email: string,
    name: string,
    lastName: string
  ): Promise<void> => {
    if (detail) {
      const { customer } = detail;
      const body = {
        email,
        customer,
        userName: { first: name as string, last: lastName as string }
      };
      sendAssign(id, body);
      setTimeout(() => {
        toggleIsOpen();
        refetchLicenseDetail();
      }, 500);

      Analytics.sendEventCustom({
        category: 'License Detail',
        action: 'Assign',
        label: 'License'
      });
    }
  };

  const fieldsDetail = detail as ILicenseIoT;
  const renderDetail = (): JSX.Element => {
    const info = t('licenses.detail.info', { returnObjects: true });
    const textColor = fieldsDetail.status
      ? LICENSES_TYPES_LIST[fieldsDetail.status].color || 'black'
      : 'black';

    return (
      <>
        <Header
          as='h1'
          inverted={theme !== 'light'}
          content={t('licenses.detail.details')}
        />
        <Segment inverted={theme !== 'light'} className='grid-licenses-detail'>
          <Grid columns='2' padded>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>{info.targetReceptor}</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {parseKeysTypes('RECEPTOR', fieldsDetail.targetReceptor)}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>{info.id}</strong>
              </Grid.Column>
              <Grid.Column width={8}>{fieldsDetail.id}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>{info.status}</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {fieldsDetail.sharedWithEmail ? (
                  <Label color='grey' content={t('licenses.state.shared')} />
                ) : (
                  <Label
                    color={textColor}
                    content={parseKeysTypes('LICENSE', fieldsDetail.status)}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>{info.createdAt}</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {formatDate(fieldsDetail.validFrom)}
              </Grid.Column>
            </Grid.Row>
            {isLicensee && (
              <Grid.Row>
                <Grid.Column width={8}>
                  <strong>{info.validUntil}</strong>
                </Grid.Column>
                <Grid.Column width={8}>
                  {formatDateValidUntil(fieldsDetail.validUntil)}
                </Grid.Column>
              </Grid.Row>
            )}
            {fieldsDetail.sharedWithEmail && (
              <>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Divider horizontal>
                      <Icon name='check circle' style={{ marginRight: 5 }} />
                      {t('tableList.header.license')} {t('assignation.shared')}
                    </Divider>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <strong>{info.sharedWithEmail}</strong>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    {fieldsDetail.sharedWithEmail}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <strong>{info.sharedAt}</strong>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    {formatDate(fieldsDetail.sharedAt || '')}
                  </Grid.Column>
                </Grid.Row>
              </>
            )}
          </Grid>
        </Segment>
      </>
    );
  };

  const renderAuthorizations = (): JSX.Element => {
    if (!authorizations?.length) {
      return (
        <Empty
          iconName='file alternate'
          texts={t('messages.licensesDetail.things', { returnObjects: true })}
        />
      );
    }
    return (
      <TableList
        divider
        stackable
        sortable
        singleLine
        padded='very'
        className={`table-authorizations ${
          fieldsDetail.sharedWithEmail ? 'sharedwith' : ''
        }`}
        title={t('licenses.detail.authorizations')}
      >
        <TableList.Header
          columns={HEADER_LIST_LICENSE_AUTHORIZATIONS}
          list={authorizations}
          onClickHeader={(dataOrder: any) => {
            updateListLicense('authorizations', dataOrder);
          }}
        />
        <TableList.Body>
          {Object.values(authorizations).map(({ authorization }, index) => (
            <Table.Row key={`auth-${index.toString()}`}>
              <Table.Cell>
                {parseKeysTypes('AUTHORIZATION', authorization)}
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
          ))}
        </TableList.Body>
      </TableList>
    );
  };

  const renderThings = () => {
    if (!things?.length) {
      return (
        <Empty
          iconName='television'
          texts={t('messages.licensesDetail.things', { returnObjects: true })}
        />
      );
    }

    const customerId = detail?.customer as string;
    return (
      <TableList
        divider
        stackable
        sortable
        singleLine
        padded='very'
        title={t('licenses.detail.devices')}
      >
        <TableList.Header
          columns={HEADER_LIST_LICENSE_RECEPTORS}
          list={things}
          onClickHeader={(dataOrder: any) => {
            updateListLicense('things', dataOrder);
          }}
        />
        <TableList.Body>
          {Object.values(things).map(
            ({ thingName, thingType, thing: code, status }, index) => (
              <Table.Row key={`receptor-${index.toString()}`}>
                <Table.Cell>
                  <Link
                    to={{
                      pathname: names.paths.things.detail(thingType),
                      search: parseParamsThingDetail(
                        code,
                        thingName,
                        buildThingGroupId(customerId, code, thingType)
                      )
                    }}
                    state={{
                      type: thingType,
                      name: thingName,
                      id: code,
                      groupId: buildThingGroupId(customerId, code, thingType)
                    }}
                    onClick={() => {
                      updateSection(names.paths.things.main);
                      updateSubsection(`/things/${thingType}`);
                    }}
                  >
                    {thingName}
                  </Link>
                </Table.Cell>
                <Table.Cell>{parseKeysTypes('RECEPTOR', thingType)}</Table.Cell>
                <Table.Cell>{code}</Table.Cell>
                <Table.Cell>{parseKeysTypes('ASSIGNATION', status)}</Table.Cell>
              </Table.Row>
            )
          )}
        </TableList.Body>
      </TableList>
    );
  };

  const renderButtonModal = () => (
    <Button
      className='assign-button'
      content={t('actions.assign')}
      icon='envelope outline'
      labelPosition='left'
      onClick={toggleIsOpen}
    />
  );

  if (!detail && !isLoadingRequest.fetch) {
    return <Navigate to={names.paths.notFound} />;
  }

  const renderState = () => {
    const status = detail?.status;
    const targetReceptor = detail?.targetReceptor;

    if (isLoadingRequest.fetch) {
      return <SkeletonDetailLicense />;
    }

    return (
      <>
        {status === names.licenses.available && targetReceptor === 'desk' ? (
          <Segment basic className='header-segment'>
            {renderButtonModal()}
          </Segment>
        ) : (
          <Divider style={{ margin: '1em auto' }} hidden />
        )}
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8}>{detail && renderDetail()}</Grid.Column>
            <Grid.Column width={8}>{renderAuthorizations()}</Grid.Column>
          </Grid.Row>
        </Grid>
        {detail?.status === 'assigned' && (
          <Divider style={{ margin: '2.5em auto' }} hidden />
        )}
        {detail?.status === 'assigned' && renderThings()}
      </>
    );
  };

  return (
    <Layout title={`${t('sections.licenses')} [${id}]`} showNavbar={isNavbar}>
      <Segment basic className='wrapper-licenses-detail'>
        <BreadcrumbCustom
          flow={[names.navigation.licenses, licenseStatus]}
          isDetail
        />
        <Segment basic className='segmentState'>
          {renderState()}
        </Segment>
      </Segment>
      <ModalAssign
        isOpen={isOpenModal}
        isLoading={isLoadingRequest.assign}
        onCancel={toggleIsOpen}
        onAccept={handleOnAcceptModalAssign}
      />
    </Layout>
  );
};

export default LicensesDetail;
