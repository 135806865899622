import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Analytics from '../controllers/Analytics';
import BackendIoT from '../controllers/Backend/IoT';
import ReporterLogger from '../controllers/ReporterLogger';
import { useAuthDispatch, useAuthState } from '../contexts/auth';
import { useClientDispatch, useClientState } from '../contexts/client';
import { useStatusRequest } from '../contexts/request';
import { useNavState } from '../contexts/navigation';
import names from '../config/names';
import * as Constants from '../config/constants';
import * as Types from '../types';
import * as Interfaces from '../interfaces';

interface IUseClients {
  updateQuery(search: Interfaces.ISearchQuery): void;
  sendEditClient(data: Interfaces.IClientData): Promise<void>;
  sendCreateClient(data: Interfaces.IClientData): Promise<void>;
  selectClient: (clientId: string, isRedirect?: boolean) => void;
  unselectClient: (isRedirect?: boolean) => void;
  isDisableNavigation: (location: string) => boolean;
  isLoadingRequest: {
    fetch: boolean;
    create: boolean;
    edit: boolean;
    select: boolean;
    unselect: boolean;
  };
}

interface IState {
  isLoading: IUseClients['isLoadingRequest'];
  query: Interfaces.IQueryClient;
  searchValue: string;
}

const DEFAULT_QUERY_CLIENT: Interfaces.IQueryClient = {
  search: 'skyalertId'
};

const DEFAULT_LOADING = {
  fetch: false,
  create: false,
  edit: false,
  select: false,
  unselect: false
};

const LoggerInstance = ReporterLogger.getInstance();

export const useClients = ({
  analytics
}: Interfaces.IAnalytics = {}): IUseClients => {
  const { t: translations } = useTranslation();
  const navigate = useNavigate();
  const { updateSection } = useNavState();
  const client = useClientState();
  const dispatch = useClientDispatch();
  const authDispatch = useAuthDispatch();
  const { isLicensee } = useAuthState();
  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });

  const { UpdateMessageResponse } = useStatusRequest();

  const [query, setQuery] = useState<IState['query']>(DEFAULT_QUERY_CLIENT);
  const [searchValue, setSearchValue] = useState<IState['searchValue']>('');
  const [isLoading, setIsLoading] =
    useState<IState['isLoading']>(DEFAULT_LOADING);

  const changeSection = (path: string): void => {
    updateSection(path);
    navigate(path);
  };

  const updateQuery = (search: Interfaces.ISearchQuery) => {
    setSearchValue(search.searchValue);
    setQuery(search.newQuery);
  };

  const storeDefaultInformation = (response: Interfaces.IUserIoT): void => {
    localStorage.setItem(
      names.storageKeys.clientInfo,
      JSON.stringify(response)
    );
  };

  const removeClientInformation = (): void => {
    localStorage.removeItem(names.storageKeys.clientInfo);
  };

  const fetchClient = async (): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }
    try {
      dispatch({ type: Types.EClientReducer.request });
      const body = { value: encodeURIComponent(searchValue) };
      const response = await BackendIoT.getClient(body, query);
      if (response?.id) {
        storeDefaultInformation({ ...response, selected: false });
        dispatch({
          type: Types.EClientReducer.fetch,
          payload: response
        });
      }
    } catch (error: any) {
      dispatch({
        type: Types.EClientReducer.error,
        isNotFound: error?.status === 404,
        error: (error as Error).message,
        payload: Constants.DEFAULT_VALUE_CLIENT
      });
      if (error?.status !== 404) {
        messageResponseRef.current = {
          type: 'error',
          action: translations('actions.requests.fetch.clients'),
          message: translations('error.requests.clients.fetch')
        };
        UpdateMessageResponse(messageResponseRef.current);
      }
      LoggerInstance.error(
        'Failed get client - useClients - "fetchClient"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
  }, []);

  useEffect(() => {
    if (searchValue.length) {
      fetchClient();
    }
  }, [query, searchValue]);

  const sendCreateClient = async (
    data: Interfaces.IClientData
  ): Promise<void> => {
    if (!isLoading.create) {
      setIsLoading({ ...isLoading, create: true });
    }
    try {
      dispatch({ type: Types.EClientReducer.request });
      const response = await BackendIoT.createClient(data);
      if (response?.attributes) {
        if (response?.attributes.customerAttributes) {
          const newClient = response?.attributes.customerAttributes;
          storeDefaultInformation({ ...newClient, selected: false });
          dispatch({
            type: Types.EClientReducer.fetch,
            payload: newClient
          });
        }
      }
      messageResponseRef.current = {
        type: 'success',
        action: translations('actions.requests.create.clients'),
        message: translations('success.requests.clients.create', {
          name: data.name
        })
      };
    } catch (error) {
      dispatch({
        type: Types.EClientReducer.error,
        error: (error as Error).message,
        payload: Constants.DEFAULT_VALUE_CLIENT
      });
      messageResponseRef.current = {
        type: 'error',
        action: translations('actions.requests.create.clients'),
        message: translations('error.requests.clients.create', {
          name: data.name
        })
      };
      LoggerInstance.error(
        'Failed create client - useClients - "sendCreateClient"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, create: false });
      UpdateMessageResponse(messageResponseRef.current);
    }
  };

  const sendEditClient = async (
    data: Interfaces.IClientData
  ): Promise<void> => {
    if (!isLoading.edit) {
      setIsLoading({ ...isLoading, edit: true });
    }
    try {
      dispatch({ type: Types.EClientReducer.request });
      const response = await BackendIoT.updateClient(
        data as Interfaces.IBodyClientEdit
      );
      if (response?.customer) {
        dispatch({
          type: Types.EClientReducer.fetch,
          payload: response?.customer
        });

        if (client.id === response.customer.id && client.selected) {
          storeDefaultInformation({ ...response.customer, selected: true });
          dispatch({ type: Types.EClientReducer.select });
        }
      }
      messageResponseRef.current = {
        type: 'success',
        action: translations('actions.requests.edit.clients'),
        message: translations('success.requests.clients.edit', {
          name: data.name
        })
      };
    } catch (error) {
      dispatch({
        type: Types.EClientReducer.error,
        error: (error as Error).message,
        payload: Constants.DEFAULT_VALUE_CLIENT
      });
      messageResponseRef.current = {
        type: 'error',
        action: translations('actions.requests.edit.clients'),
        message: translations('error.requests.clients.edit', {
          name: data.name
        })
      };
      LoggerInstance.error(
        'Failed edit client - useClients - "sendEditClient"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, edit: false });
      UpdateMessageResponse(messageResponseRef.current);
    }
  };

  const selectClient = async (
    clientId: string,
    isRedirect = true
  ): Promise<void> => {
    setIsLoading({ ...isLoading, select: true });
    try {
      const response = await BackendIoT.selectClient(clientId);
      if (response?.customer) {
        if (!client.id || client.id !== clientId) {
          dispatch({
            type: Types.EClientReducer.fetch,
            payload: { ...response.customer, selected: true }
          });
        } else {
          dispatch({ type: Types.EClientReducer.select });
        }
        storeDefaultInformation({ ...response.customer, selected: true });
        // FIXME: REPLACE THIS AUTH DISPATCH
        // authDispatch({
        //   type: Types.EActionsReducer.licensee,
        //   payload: response.customer.licensee
        // });
        if (isRedirect) {
          changeSection(names.paths.licenses.main);
        }
      }
    } catch (error: any) {
      dispatch({
        type: Types.EClientReducer.error,
        isNotFound: false, // TODO: ADD CORRECT VALIDATION
        error: (error as Error).message,
        payload: Constants.DEFAULT_VALUE_CLIENT
      });
      messageResponseRef.current = {
        type: 'error',
        action: translations('actions.requests.fetch.clients'),
        message: translations('error.requests.clients.fetch')
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed to select client - useClients - "selectClient"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, select: false });
    }
  };

  const unselectClient = async (isRedirect = true): Promise<void> => {
    setIsLoading({ ...isLoading, unselect: true });
    try {
      await BackendIoT.unselectClient();
      if (isRedirect) {
        changeSection(names.paths.clients);
      }
      removeClientInformation();
      dispatch({ type: Types.EClientReducer.unselect });
      // FIXME: REPLACE THIS AUTH DISPATCH
      authDispatch({
        type: Types.EActionsReducer.licensee,
        payload: null
      });
    } catch (error: any) {
      dispatch({
        type: Types.EClientReducer.error,
        isNotFound: false, // TODO: ADD CORRECT VALIDATION
        error: (error as Error).message,
        payload: Constants.DEFAULT_VALUE_CLIENT
      });
      messageResponseRef.current = {
        type: 'error',
        action: translations('actions.requests.fetch.clients'),
        message: translations('error.requests.clients.fetch')
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed to unselect client - useClients - "unselectClient"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, unselect: false });
    }
  };

  const isDisableNavigation = (location: string) => {
    return (
      !client.selected &&
      isLicensee &&
      !Constants.SECTIONS_DO_NOT_NEED_SELECT_CLIENT.includes(location)
    );
  };

  return {
    updateQuery,
    sendEditClient,
    sendCreateClient,
    selectClient,
    unselectClient,
    isDisableNavigation,
    isLoadingRequest: isLoading
  };
};

export default useClients;
