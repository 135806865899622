import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Header, Segment, Image, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavState } from '../../contexts/navigation';
import { useAuthState } from '../../contexts/auth';
import Analytics from '../../controllers/Analytics';
import Layout from '../../components/Layout';
import AuthClient from '../../models/AuthClient';
import { IAnalyticsViewPage } from '../../interfaces';
import names from '../../config/names';
import images from '../../config/images';

import '../../scss/layout/NotFound.scss';

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.notFound,
  title: 'Not Found'
};

const NotFound = (): JSX.Element => {
  const { t } = useTranslation();
  const { updateSection } = useNavState();
  const { customer } = useAuthState();

  useEffect(() => {
    Analytics.sendPageView(ANALYTICS_PAGE_VIEW.page, ANALYTICS_PAGE_VIEW.title);
  }, []);

  const onClickRedirect = async (): Promise<void> => {
    if (customer) {
      updateSection(names.paths.dashboard);
    } else {
      AuthClient.closeSession();
    }
  };

  const texts = t(customer ? 'notFound.private' : 'notFound.public', {
    returnObjects: true
  });

  const { title, subHeader } = texts;
  return (
    <Layout showNavbar={false} title=''>
      <Segment basic className='wrapper-notFound'>
        <Image src={images.images.notFound} size='huge' centered />
        <Header as='h2' icon>
          {title}
          <Header.Subheader>{subHeader}</Header.Subheader>
        </Header>
        <br />
        <Link
          to={names.paths[customer ? 'dashboard' : 'login']}
          onClick={onClickRedirect}
        >
          <Button primary className='button-link'>
            {t(`actions.back.${customer ? 'home' : 'login'}`)}
          </Button>
        </Link>
      </Segment>
    </Layout>
  );
};

export default NotFound;
