import React, { SyntheticEvent, useState } from 'react';
import {
  Button,
  Header,
  Modal,
  Table,
  Checkbox,
  CheckboxProps
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useLocation, Location } from 'react-router-dom';
import { useGroup } from '../../../../hooks/groups/useGroup';
import Empty from '../../../Empty';
import TableList from '../../../TableList';
import { parseKeysTypes } from '../../../../helpers';
import {
  HEADER_LIST_DEVICES_MODAL_ADD,
  OPTIONS_SEARCH_THINGS_GROUPS
} from '../../../../config/constants';
import { IBodyGroupThingCreate } from '../../../../interfaces';
import { SkeletonModalGroupThings } from '../../../Skeletons';
import Search from '../../../Search';

import '../../../../scss/layout/Modal.scss';

export interface PropsComponentModalGThings {
  isOpen: boolean;
  isLoading: boolean;
  onAccept(data: IBodyGroupThingCreate): void;
  onCancel(): void;
}

type thingsGroupState = Omit<Location, 'state'> & {
  state: {
    nameGroup: string;
    groupId: string;
  };
};

const GThings = ({
  isOpen,
  isLoading,
  onAccept,
  onCancel
}: PropsComponentModalGThings): JSX.Element => {
  const { t } = useTranslation();
  const { state } = useLocation() as thingsGroupState;
  const {
    isLoading: { things: isLoadingRequest },
    dataGroup: { listThings, searchListThings },
    updateSearchList
  } = useGroup({ mode: 'things', isOpen, groupId: state.groupId });

  const [selectListIds, setSelectListIds] = useState<string[]>([]);

  const handlerSelectOption = (
    e: SyntheticEvent,
    { value }: CheckboxProps
  ): void => {
    e.preventDefault();
    const valueId = value as string;
    if (!selectListIds.includes(valueId)) {
      const selectIds = [...selectListIds, valueId];
      const listIdsFiltered = selectIds.filter((id) => id);
      setSelectListIds(listIdsFiltered);
    } else {
      const withOutValue = selectListIds.filter((id) => {
        return id !== valueId;
      });
      setSelectListIds(withOutValue);
    }
  };

  const handlerCancel = (e: SyntheticEvent): void => {
    e.preventDefault();
    setSelectListIds([]);
    updateSearchList('things', listThings);
    onCancel();
  };

  const handlerAccept = (e: SyntheticEvent): void => {
    e.preventDefault();
    if (selectListIds.length) {
      const body = {
        thingIds: selectListIds
      };

      onAccept(body);
    }
  };

  const handleOnSearch = (
    e: SyntheticEvent,
    list: any
    // value: string
  ): void => {
    e.preventDefault();
    updateSearchList('things', list);
  };

  const renderStatus = (): JSX.Element => {
    if (isLoadingRequest) {
      return <SkeletonModalGroupThings />;
    }

    if (!listThings.length) {
      return (
        <Empty
          iconName='tv'
          texts={{
            title: t('error.devices.title'),
            subtitle: t('error.devices.subtitle')
          }}
        />
      );
    }

    if (!searchListThings.length) {
      return (
        <Empty
          iconName='search'
          texts={{
            title: t('error.search.title'),
            subtitle: t('error.search.subtitle')
          }}
        />
      );
    }

    return (
      <TableList hideDivider stackable sortable padded='very'>
        <TableList.Header
          columns={HEADER_LIST_DEVICES_MODAL_ADD}
          list={listThings}
        />
        <TableList.Body>
          {Object.values(searchListThings).map(
            ({ id: uuid, name, type }, index) => (
              <Table.Row key={`things-${index.toString()}`}>
                <Table.Cell collapsing>
                  <Checkbox
                    name={uuid}
                    value={uuid}
                    onChange={handlerSelectOption}
                    disabled={isLoading}
                    checked={selectListIds.includes(uuid)}
                  />
                </Table.Cell>
                <Table.Cell>{parseKeysTypes('RECEPTOR', type)}</Table.Cell>
                <Table.Cell className='collapse-texts thing-name'>
                  {name}
                </Table.Cell>
                <Table.Cell>{uuid}</Table.Cell>
              </Table.Row>
            )
          )}
        </TableList.Body>
      </TableList>
    );
  };

  return (
    <Modal
      size='small'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modalGThings-component'
    >
      <Modal.Header>
        <Header
          textAlign='center'
          as='h1'
          content={t('modals.titles.add.thing')}
        />
      </Modal.Header>
      <Modal.Content scrolling>
        {!!listThings.length && !isLoadingRequest && (
          <Search
            list={listThings}
            isDisable={isLoading}
            defaultOptions='name'
            options={OPTIONS_SEARCH_THINGS_GROUPS}
            onSearch={handleOnSearch}
          />
        )}
        {renderStatus()}
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={handlerCancel}
          content={t('actions.cancel')}
        />
        <Button
          positive
          content={t('actions.add')}
          disabled={!selectListIds.length || isLoading}
          loading={isLoading}
          onClick={handlerAccept}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default GThings;
