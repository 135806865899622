import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Modal } from 'semantic-ui-react';
import Markdown from 'markdown-to-jsx';
import DamageStatus from '../../DamageStatus';
import * as Interfaces from '../../../interfaces';

import '../../../scss/layout/Modal.scss';
import './DetailDamageReport.scss';

export interface PropsComponentModalDetailDamageReport {
  isOpen: boolean;
  isLoading: boolean;
  infoReport: Interfaces.IMoreInfoReport;
  onClose(e: SyntheticEvent): void;
}

const ModalDetailDamageReport = ({
  isOpen,
  infoReport,
  isLoading,
  onClose
}: PropsComponentModalDetailDamageReport): JSX.Element => {
  const { t } = useTranslation();
  const { thingName, damage, earthquakeDate, comments, reportDate } =
    infoReport;

  const reportDetail = comments || t('damageReport.modal.noDetail');

  const renderElement = (
    title: string,
    description: string,
    styleClass?: string
  ) => (
    <div className={`element-report ${styleClass || ''}`}>
      <Header
        as='h4'
        className={`title-info ${styleClass || ''}`}
        content={<Markdown>{title}</Markdown>}
      />
      <p className='description-element'>{description}</p>
    </div>
  );

  return (
    <Modal
      size='small'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal wrapper-modal-detail-damage-report'
      data-testid='modal-detail-damage-report-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={t('damageReport.modal.title')}
          icon='file alternate'
        />
      </Modal.Header>
      <Modal.Content>
        {renderElement(
          t('damageReport.modal.thingName'),
          thingName,
          'thing-name'
        )}
        <div className='container-dates'>
          {renderElement(
            t('damageReport.modal.dateEarthquake'),
            earthquakeDate
          )}
          {renderElement(t('damageReport.modal.dateReport'), reportDate)}
        </div>
        <Header
          as='h4'
          className='title-info'
          content={t('damageReport.modal.damageStatus')}
        />
        <DamageStatus damage={damage} type='detail' />
        {renderElement(t('damageReport.modal.detail'), reportDetail)}
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          disabled={isLoading}
          onClick={onClose}
          loading={isLoading}
          content={t('actions.close')}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalDetailDamageReport;
