import React from 'react';
import ReactDOM from 'react-dom';
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater';
import ReporterLogger from './controllers/ReporterLogger';
import Analytics from './controllers/Analytics';
import Contexts from './contexts';
import ApolloProvider from './Provider';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './config/i18n';

import 'semantic-ui-css/semantic.min.css';
import './scss/index.scss';

ReporterLogger.getInstance().init();
Analytics.getInstance().init();

const WrapperApp = (): JSX.Element => (
  <React.StrictMode>
    <ApolloProvider>
      <Contexts>
        <App />
      </Contexts>
    </ApolloProvider>
  </React.StrictMode>
);

ReactDOM.render(<WrapperApp />, document.getElementById('root'));

serviceWorkerRegistration.register({
  onUpdate: onServiceWorkerUpdate
});
