import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import { useAuthState } from '../../contexts/auth';
import { useClientState } from '../../contexts/client';
import { useStatusRequest } from '../../contexts/request';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';

interface IUseThings {
  isLoading: boolean;
  dataThings: {
    totalReceptors: Interfaces.IThingsTotal;
  };
  refetchThings: () => Promise<void>;
}

interface IState {
  isLoading: IUseThings['isLoading'];
  totalThings: IUseThings['dataThings']['totalReceptors'];
}

const { DEFAULT_TOTAL_THINGS } = Constants;

const LoggerInstance = ReporterLogger.getInstance();

export const useThings = ({
  analytics
}: Interfaces.IAnalytics = {}): IUseThings => {
  const { isLicensee } = useAuthState();
  const client = useClientState();
  const { t } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoading, setIsLoading] = useState<IState['isLoading']>(true);
  const [totalThings, setTotalThings] =
    useState<IState['totalThings']>(DEFAULT_TOTAL_THINGS);

  const fetchTotalThings = async (): Promise<void> => {
    if (!isLoading) {
      setIsLoading(true);
    }
    try {
      const response = await BackendIoT.getCustomer();
      let totalThingCount = DEFAULT_TOTAL_THINGS;
      if (response?.thingCount) {
        totalThingCount = response.thingCount;
      }
      setTotalThings(totalThingCount);
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.things.total')
      });
      LoggerInstance.error(
        'Failed fetch info things - useThings - "fetchTotalThings"',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
    if (!isLicensee) {
      fetchTotalThings();
    } else {
      setTotalThings(client.thingCount || DEFAULT_TOTAL_THINGS);
      setIsLoading(false);
    }
  }, []);

  const refetchThings = async (): Promise<void> => {
    await fetchTotalThings();
  };

  return {
    isLoading,
    dataThings: {
      totalReceptors: totalThings
    },
    refetchThings
  };
};

export default useThings;
