import React, { useState, SyntheticEvent } from 'react';
import type { TOptions, StringMap } from 'i18next';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dimmer,
  Divider,
  Header,
  List,
  ListItem,
  Loader,
  Checkbox,
  Modal,
  Grid,
  Icon
} from 'semantic-ui-react';
import HistoryConnections from './HistoryConnections';
import ModalConfirm from '../Delete/Confirm';
import { parseKeysTypes } from '../../../helpers';
import * as Interfaces from '../../../interfaces';
import * as Types from '../../../types';

import '../../../scss/layout/Modal.scss';
import './DetailMonitorThings.scss';

export interface PropsComponentModalDetailMonitorThings {
  isOpen: boolean;
  isError: boolean;
  isLoading: boolean;
  isLoadingRedirectThing: boolean;
  isLoadingSettingsThing: boolean;
  thingsIgnored: Interfaces.IThingIoT['id'][];
  infoThing: Interfaces.IParseMonitorThings;
  connectionsHistory: Interfaces.IRequestThingConnections['connectionsHistory'];
  rangeDateHistory: Interfaces.IRangeDateConnectionsHistory;
  selectorDaysHistory: Types.TSelectorDaysHistoryConnection;
  onClose(e: SyntheticEvent): void;
  onClickSelectorDay(
    value: Types.TSelectorDaysHistoryConnection,
    thingId: Interfaces.IThingIoT['id']
  ): void;
  onClickIdThing(
    customerId: Interfaces.IUserIoT['customer'],
    dataRedirect: Interfaces.IRedirect
  ): void;
  onChangeThingIgnored(
    thingId: Interfaces.IThingIoT['id'],
    isIgnored: boolean
  ): void;
}

const ModalDetailMonitorThings = ({
  isOpen,
  isError,
  isLoading,
  isLoadingRedirectThing,
  isLoadingSettingsThing,
  onClose,
  infoThing,
  thingsIgnored,
  onClickIdThing,
  rangeDateHistory,
  selectorDaysHistory,
  connectionsHistory,
  onClickSelectorDay,
  onChangeThingIgnored
}: PropsComponentModalDetailMonitorThings): JSX.Element => {
  const { t: translation } = useTranslation();

  const [isOpenConfirmThingIgnored, setIsOpenConfirmThingIgnored] =
    useState<boolean>(false);

  const wrapperMarkdownTranslation = (
    key:
      | 'client'
      | 'clientId'
      | 'receptor'
      | 'name'
      | 'id'
      | 'skyalertId'
      | 'version',
    options?: string | TOptions<StringMap>
  ) => (
    <Markdown>{translation(`monitor.detail.list.${key}`, options)}</Markdown>
  );

  const toggleModalConfirmThingIgnored = (): void => {
    setIsOpenConfirmThingIgnored((prev) => !prev);
  };

  const handleOnChangeThingIgnored = (): void => {
    if (thingsIgnored.includes(infoThing.id)) {
      onChangeThingIgnored(infoThing.id, !thingsIgnored.includes(infoThing.id));
    } else {
      toggleModalConfirmThingIgnored();
    }
  };

  const handleOnAcceptThingIgnored = (): void => {
    onChangeThingIgnored(infoThing.id, !thingsIgnored.includes(infoThing.id));
    toggleModalConfirmThingIgnored();
  };

  const renderContentInfoThing = (): JSX.Element => {
    const dataRedirect: Interfaces.IRedirect = {
      id: infoThing.id,
      type: infoThing.type,
      name: infoThing.name,
      groupId: infoThing.commandGroupId
    };
    return (
      <List relaxed>
        <ListItem
          icon='address book'
          content={wrapperMarkdownTranslation('skyalertId', {
            skyalertId: infoThing.customerSkyalertId
          })}
        />
        <ListItem
          icon='users'
          content={wrapperMarkdownTranslation('client', {
            client: infoThing.customerName
          })}
        />
        <ListItem
          icon='address card'
          content={wrapperMarkdownTranslation('clientId', {
            clientId: infoThing.customer
          })}
        />
        <ListItem
          icon='computer'
          content={wrapperMarkdownTranslation('receptor', {
            type: parseKeysTypes('RECEPTOR', infoThing.type)
          })}
        />
        <ListItem
          icon='tag'
          content={wrapperMarkdownTranslation('name', {
            name: infoThing.name
          })}
        />
        <ListItem
          icon='barcode'
          onClick={() => {
            onClickIdThing(infoThing.customer, dataRedirect);
          }}
          content={wrapperMarkdownTranslation('id', { id: infoThing.id })}
        />
        <ListItem
          icon='code branch'
          content={wrapperMarkdownTranslation('version', {
            version: infoThing.version || translation('error.notAvailable')
          })}
        />
      </List>
    );
  };

  const isIgnored = thingsIgnored
    ? thingsIgnored.includes(infoThing.id)
    : false;
  const keyPathLabel = `monitor.detail.ignored.mode${
    isIgnored ? 'Active' : 'Inactive'
  }`;
  return (
    <Modal
      size='small'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal wrapper-modal-detail-monitor'
      data-testid='modal-detail-monitor-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={translation('monitor.detail.title', {
            thingName: infoThing.name
          })}
          icon='list'
        />
      </Modal.Header>
      <Modal.Content scrolling>
        <Dimmer active={isLoadingRedirectThing} inverted>
          <Loader />
        </Dimmer>
        {renderContentInfoThing()}
        <Divider hidden />
        <Grid columns={2} style={{ width: '100%' }}>
          <Grid.Column
            verticalAlign='middle'
            style={{ display: 'inline-flex' }}
          >
            <Header
              as='h4'
              style={{ margin: '0 15px 0 0', display: 'inline-flex' }}
            >
              <Icon
                name={isIgnored ? 'low vision' : 'eye'}
                style={{ fontSize: '1.05em' }}
              />
              <Markdown>{translation(keyPathLabel as any)}</Markdown>
            </Header>
          </Grid.Column>
          <Grid.Column textAlign='right'>
            {isLoadingSettingsThing ? (
              <Icon size='big' color='blue' loading name='sync' disabled />
            ) : (
              <Checkbox
                toggle
                onChange={handleOnChangeThingIgnored}
                checked={isIgnored}
              />
            )}
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Divider horizontal>
          <Header
            as='h4'
            icon='time'
            content={translation('monitor.detail.history')}
          />
        </Divider>
        <Divider hidden />
        <HistoryConnections
          thingId={infoThing.id}
          selectorDaysHistory={selectorDaysHistory}
          isError={isError}
          isLoading={isLoading}
          connections={connectionsHistory}
          rangeDate={rangeDateHistory}
          onClickSelectorDay={onClickSelectorDay}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={onClose}
          disabled={isLoadingRedirectThing}
          content={translation('actions.close')}
        />
      </Modal.Actions>
      <ModalConfirm
        header={{
          title: translation('monitor.detail.ignored.title')
        }}
        isOpen={isOpenConfirmThingIgnored}
        isLoading={isLoadingSettingsThing}
        onAccept={handleOnAcceptThingIgnored}
        onCancel={toggleModalConfirmThingIgnored}
      >
        <Header
          as='h4'
          content={translation('monitor.detail.ignored.confirm')}
        />
      </ModalConfirm>
    </Modal>
  );
};

export default ModalDetailMonitorThings;
