import React, { useEffect, useState, RefObject } from 'react';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useFeed, useFeedAcknowledgments } from '../../hooks/useFeed';
import Layer from '../Layer';

export interface PropsComponentFeedAck {
  section: 'group' | 'thing';
  groupId: string;
  thingId?: string;
  containerRef?: RefObject<HTMLDivElement>;
}

const FeedAck = ({
  section,
  groupId,
  thingId
}: PropsComponentFeedAck): JSX.Element => {
  const isThing = section === 'thing';

  const { t } = useTranslation();
  const { data, error } = useFeed(groupId);

  const [isShowError, setIsShowError] = useState<boolean>(false);

  const {
    isLoading,
    getMoreFeed,
    dataAcknowledgments: { listParsed: listFeedAcknowledgments },
    refetchAcknowledgments,
    nextRequest: { isLoadMore }
  } = useFeedAcknowledgments(groupId, data, { isThing, thingId });

  useEffect(() => {
    if (error) {
      setIsShowError(false); // FIXME: REMOVE THIS STATE
    }
  }, [error]);

  return (
    <>
      {!data && error && isShowError && (
        <Message
          error
          icon='exclamation triangle'
          header={t('error.groups.title')}
          content={t('error.groups.subtitle')}
          onDismiss={() => setIsShowError(false)}
        />
      )}
      <Layer
        section={section !== 'thing' ? 'GROUPS-FEEDACK' : 'FEEDACK'}
        data={Object.values(listFeedAcknowledgments)}
        loading={isLoading}
        loadMore={isLoadMore}
        onRefresh={refetchAcknowledgments}
        refetchLoadMore={getMoreFeed}
      />
    </>
  );
};

FeedAck.defaultProps = {
  thingId: '',
  containerRef: null
};

export default FeedAck;
