import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Header, InputProps, Modal } from 'semantic-ui-react';
import * as Helpers from '../../../helpers';
import * as Interfaces from '../../../interfaces';

const { validateEmail, validateFullName } = Helpers;
export interface PropsComponentModalClients {
  action: 'edit' | 'create';
  editValues: Interfaces.IClientData | null;
  isOpen: boolean;
  isLoading: boolean;
  onAccept(data: Interfaces.IClientData): void;
  onCancel(): void;
}

interface IState {
  error: {
    name: boolean;
    email: boolean;
  };
  dataForm: Interfaces.IClientData;
}

// TODO: PAST TO CONST?

const MAX_LENGTH_FIELDS = {
  name: 30,
  email: 50
};

const DEFAULT_ERROR_MESSAGE: IState['error'] = {
  name: false,
  email: false
};
const DEFAULT_DATA_FORM: IState['dataForm'] = {
  name: '',
  email: ''
};

const ClientsActions = ({
  action,
  editValues,
  isOpen,
  isLoading,
  onCancel,
  onAccept
}: PropsComponentModalClients): JSX.Element => {
  const { t: translations } = useTranslation();

  const [dataForm, setDataForm] =
    useState<IState['dataForm']>(DEFAULT_DATA_FORM);
  const [isError, setIsError] = useState<IState['error']>(
    DEFAULT_ERROR_MESSAGE
  );

  const resetStates = (): void => {
    setDataForm(DEFAULT_DATA_FORM);
    setIsError(DEFAULT_ERROR_MESSAGE);
  };

  useEffect(() => {
    if (isOpen) {
      resetStates();
      if (action === 'edit' && editValues) {
        setDataForm({
          name: editValues.name,
          email: editValues.email
        });
      }
    }
  }, [isOpen]);

  const showError = (key: 'name' | 'email', value = true): void => {
    setIsError({ ...isError, [key]: value });
  };

  const handlerOnchange = (
    e: SyntheticEvent,
    { value, name }: InputProps
  ): void => {
    e.preventDefault();
    setDataForm({ ...dataForm, [name]: value });
    setIsError({ ...isError, [name]: false });
  };

  const handlerOnAccept = (e: SyntheticEvent): void => {
    e.preventDefault();
    const { email, name } = dataForm;
    if (name && !validateFullName(name)) {
      showError('name');
      return;
    }

    if (email && !validateEmail(email)) {
      showError('email');
      return;
    }

    if (editValues) {
      onAccept({ ...dataForm, id: editValues.id });
    } else {
      onAccept(dataForm);
    }
  };

  const renderControls = (): JSX.Element => (
    <Form widths='equal' autoComplete='off'>
      <Form.Input
        name='name'
        fluid
        maxLength={MAX_LENGTH_FIELDS.name}
        value={dataForm.name}
        error={isError.name && translations('error.clients.name')}
        label={translations('modals.labels.clients.name')}
        placeholder={translations('modals.placeholders.clients.name')}
        onChange={handlerOnchange}
        disabled={isLoading}
      />
      <Form.Input
        name='email'
        fluid
        maxLength={MAX_LENGTH_FIELDS.email}
        value={dataForm.email}
        error={isError.email && translations('error.clients.email')}
        label={translations('modals.labels.clients.email')}
        placeholder={translations('modals.placeholders.clients.email')}
        onChange={handlerOnchange}
        disabled={isLoading}
      />
    </Form>
  );

  const isDisabled = !dataForm.name || !dataForm.email;

  return (
    <Modal
      size='small'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modalClient-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={translations(`modals.titles.${action}.clients`)}
          icon='user'
        />
      </Modal.Header>
      <Modal.Content>{renderControls()}</Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={onCancel}
          content={translations('actions.cancel')}
        />
        <Button
          positive
          disabled={isDisabled || isLoading}
          onClick={handlerOnAccept}
          loading={isLoading}
          content={translations('actions.accept')}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ClientsActions;
