import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import { useStatusRequest } from '../../contexts/request';
import { useAuthDispatch, useAuthState } from '../../contexts/auth';
import { filterListGroups, hasContainIot } from '../../helpers';
import { EActionsReducer } from '../../types';
import { DEFAULT_VALUE_NEXT_REQUEST } from '../../config/constants';
import * as Interfaces from '../../interfaces';

interface IUseGroups {
  isLoadingRequest: {
    fetch: boolean;
    create: boolean;
    delete: boolean;
  };
  dataGroups: {
    listInfo: Interfaces.IGroupsIoT[];
  };
  sendCreate: (name: string) => Promise<void>;
  sendDelete: (id: string, name: string) => Promise<void>;
  refetchGroups: () => Promise<void>;
  getMoreGroups: () => Promise<void>;
  nextRequest: Interfaces.INextRequest;
}

interface IState {
  query: Interfaces.IQuery;
  isLoading: IUseGroups['isLoadingRequest'];
  listGroups: IUseGroups['dataGroups']['listInfo'];
}

const DEFAULT_QUERY: IState['query'] = { limit: 15 };

const LoggerInstance = ReporterLogger.getInstance();

export const useGroups = ({
  analytics
}: Interfaces.IAnalytics = {}): IUseGroups => {
  const { t } = useTranslation();

  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });

  const dispatch = useAuthDispatch();

  const { UpdateMessageResponse } = useStatusRequest();
  const { customer } = useAuthState();

  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    fetch: true,
    create: false,
    delete: false
  });
  const [listGroups, setListGroups] = useState<IState['listGroups']>([]);
  const [nextRequest, setNextRequest] = useState<IUseGroups['nextRequest']>(
    DEFAULT_VALUE_NEXT_REQUEST
  );
  const [query, setQuery] = useState<IState['query']>(DEFAULT_QUERY);

  const fetchGroups = async (
    isRequest = false,
    isDelete = false
  ): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }
    try {
      const response = await BackendIoT.getGroups(query);
      let newListGroups: Interfaces.IGroupsIoT[] = [];
      let newNextRequest = DEFAULT_VALUE_NEXT_REQUEST;
      if (response?.groups.length > 0) {
        const lastItem = response?.lastItem;
        const listFilter = filterListGroups(response.groups, customer);
        // const listFilter = response.groups;
        const groups = listFilter.length > 0 ? listFilter : listGroups;
        newListGroups = groups;

        if (isDelete && listFilter.length === 0) {
          newListGroups = listFilter;
        }

        if (nextRequest.isLoadMore && !isRequest) {
          newListGroups = [...listGroups, ...listFilter];
        }

        if (lastItem && !hasContainIot(lastItem.PK)) {
          newNextRequest = {
            isLoadMore: !!listFilter.length && !hasContainIot(lastItem.PK),
            lastItem
          };
        }
      }
      setNextRequest(newNextRequest);
      setListGroups(newListGroups);
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        message: t('error.requests.groups.fetch'),
        delay: 6000
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed to get all groups - useGroups - "fetchGroups"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
  }, []);

  useEffect(() => {
    fetchGroups();
  }, [query]);

  const refetchGroups = async (isDelete = false): Promise<void> => {
    if (query.lastItem) {
      setListGroups([]);
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
      setQuery(DEFAULT_QUERY);
    } else {
      await fetchGroups(true, isDelete);
    }
  };

  const fetchUser = async (): Promise<void> => {
    try {
      const responseUser = await BackendIoT.getUser();
      const { groups } = responseUser;
      dispatch({
        type: EActionsReducer.group,
        payload: groups
      });
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        message: t('error.requests.groups.fetchUsers'),
        delay: 6000
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed to get user groups  - useGroups - "fetchUser"',
        error
      );
    }
  };

  const sendCreate = async (name: string): Promise<void> => {
    if (!isLoading.create) {
      setIsLoading({ ...isLoading, create: true });
    }
    try {
      setIsLoading({ ...isLoading, create: true });
      await BackendIoT.createGroup(customer, name);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.create.group'),
        message: t('success.requests.groups.create', { name })
      };
      await fetchUser();
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.create.group'),
        message: t('error.requests.groups.create', { name })
      };
      LoggerInstance.error(
        'Failed to create group  - useGroups - "sendCreate"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, create: false });
    }

    refetchGroups();
  };

  const sendDelete = async (id: string, name: string): Promise<void> => {
    if (!isLoading.delete) {
      setIsLoading({ ...isLoading, delete: true });
    }
    try {
      await BackendIoT.deleteGroup(id);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.delete.group'),
        message: t('success.requests.groups.delete', { name })
      };
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.delete.group'),
        message: t('error.requests.groups.delete', { name })
      };
      LoggerInstance.error(
        'Failed to delete group  - useGroups - "sendDelete"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, delete: false });
      refetchGroups(true);
    }
  };

  const getMoreGroups = async (): Promise<void> => {
    const paramsQuery = {
      ...query,
      lastItem: JSON.stringify(nextRequest.lastItem)
    };
    setQuery(paramsQuery);
  };

  return {
    isLoadingRequest: isLoading,
    sendCreate,
    sendDelete,
    nextRequest,
    getMoreGroups,
    refetchGroups,
    dataGroups: {
      listInfo: listGroups
    }
  };
};

export default useGroups;
