import React, { useRef } from 'react';
import { Grid, Segment, Header, Ref, Divider } from 'semantic-ui-react';
import { useAuthState } from '../../contexts/auth';
import { useClientState } from '../../contexts/client';
// import { useNavState } from '../../contexts/navigation';
import { useSettings } from '../../contexts/settings';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import ValidationLicensee from '../ValidationLicensee';
import Ribbon from '../Ribbon';
import HeaderCustom from '../Header';
import NavSidebar from '../NavSidebar';

import './Layout.scss';

interface IProps {
  children?: React.ReactNode;
  title: string;
  showNavbar: boolean;
}

const OFFSET_ELEMENT = 13;

const Layout = ({ showNavbar, title, children }: IProps): JSX.Element => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const childrenRef = useRef<HTMLDivElement | null>(null);

  const { isLicensee } = useAuthState();
  const client = useClientState();
  // TODO: FIX BACK TO TOP FUNCTION WITH FLOATING BUTTON
  // const { topLayoutRef } = useNavState();

  const {
    screen: { isDesktop }
  } = useBreakpoint();

  const { theme } = useSettings();

  const onScroll = ({ target }: Event): void => {
    const { scrollTop } = target as Element;
    const windowHeight = window.innerHeight;
    const elementHeader = headerRef?.current;
    const elementChildren = childrenRef?.current;

    if (elementHeader && elementChildren) {
      const heightClient = elementHeader.scrollHeight - OFFSET_ELEMENT;
      const spaceLeftForHeader = elementChildren?.scrollHeight - windowHeight;
      if (scrollTop > heightClient && spaceLeftForHeader > heightClient) {
        elementHeader.classList.add('sticky');
      } else {
        elementHeader.classList.remove('sticky');
      }
      // TODO: FIX BACK TO TOP FUNCTION WITH FLOATING BUTTON
      // const totalScroll =
      //   elementHeader.scrollHeight + elementChildren?.scrollHeight;
      // updateIsDownTo(totalScroll);
    }
  };

  if (showNavbar) {
    return (
      <div
        className={`wrapper-layout ${
          isLicensee && client.selected ? 'client-selected' : ''
        }`}
      >
        {client.selected && <Ribbon clientName={client.name} />}
        <NavSidebar>
          <Segment
            padded={isDesktop}
            basic
            className='container-section-layout'
            onScroll={onScroll}
          >
            <Ref innerRef={headerRef}>
              <HeaderCustom title={title} />
            </Ref>
            <Grid>
              <Grid.Row only='mobile tablet' style={{ padding: 0 }}>
                <Grid.Column width={16} textAlign='center'>
                  <Header
                    as='h1'
                    className='header-title-section'
                    content={title}
                    inverted={theme !== 'light'}
                    style={{ margin: '1em auto' }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {/* TODO: FIX BACK TO TOP FUNCTION WITH FLOATING BUTTON */}
            {/* <Ref innerRef={topLayoutRef}> */}
            <Divider hidden className='topLayout' />
            {/* </Ref> */}
            <Ref innerRef={childrenRef}>
              <Segment basic className='children-segment'>
                <ValidationLicensee>{children}</ValidationLicensee>
              </Segment>
            </Ref>
          </Segment>
        </NavSidebar>
      </div>
    );
  }

  return (
    <>
      <div
        className={`wrapper-layout ${
          isLicensee && client.selected ? 'client-selected' : ''
        }`}
      >
        {client.selected && <Ribbon clientName={client.name} />}
        <Segment padded={isDesktop} className='container-section-layout'>
          {children}
        </Segment>
      </div>
    </>
  );
};

Layout.defaultProps = {
  children: <div />
};

export default Layout;
