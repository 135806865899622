import { GoogleMapProps } from '@react-google-maps/api';
import { useCallback, useRef } from 'react';
import { ICoords } from '../interfaces';

const ZOOM_MAP_DEFAULT = 18;
const DELAY_LOAD_MAP = 500;
const DIGITS_FOR_COORDS = 4;
interface IPropUseMaps {
  refMap: google.maps.Map | undefined;
  onLoad(map: google.maps.Map): void;
  onDragEnd(e: google.maps.MapMouseEvent): ICoords;
}

export const useMaps = (location: GoogleMapProps['center']): IPropUseMaps => {
  const refMap = useRef<google.maps.Map>();

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      refMap.current = map;
      if (refMap.current) {
        const bounds = new google.maps.LatLngBounds(location);
        refMap.current.fitBounds(bounds);
        setTimeout(() => {
          if (refMap.current) refMap.current.setZoom(ZOOM_MAP_DEFAULT);
        }, DELAY_LOAD_MAP);
      }
    },
    [location]
  );

  const centerMap = ({ lat, lng }: ICoords) => {
    if (refMap.current) {
      const newCenter = new google.maps.LatLng(lat, lng);
      const map = refMap.current as google.maps.Map;
      map.setCenter(newCenter);
    }
  };

  const onDragEnd = ({ latLng }: google.maps.MapMouseEvent): ICoords => {
    const lat = Number(latLng?.lat().toFixed(DIGITS_FOR_COORDS)) ?? 0;
    const lng = Number(latLng?.lng().toFixed(DIGITS_FOR_COORDS)) ?? 0;
    centerMap({ lat, lng });
    return { lat, lng };
  };

  return {
    refMap: refMap.current,
    onLoad,
    onDragEnd
  };
};
