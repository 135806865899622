import React from 'react';
import { AuthProvider } from './auth';
import { ClientProvider } from './client';
import { NavProvider } from './navigation';
import { SettingsProvider } from './settings';
import { StatusRequestProvider } from './request';

interface IPropsCtxRoutes {
  children: React.ReactNode;
}

type Provider = React.FC<IPropsCtxRoutes>;

export const combineProviders = (providers: Provider[]): Provider => {
  const FusionProviders: Provider = ({ children }) =>
    providers.reduceRight(
      (acc, Provider) => <Provider>{acc}</Provider>,
      <>{children}</>
    );

  const providerNames = providers
    .map((Provider) => Provider.displayName || Provider.name)
    .join(',');

  FusionProviders.displayName = `FusionProviders(${providerNames})`;

  return FusionProviders;
};

const Providers = combineProviders([
  NavProvider,
  AuthProvider,
  SettingsProvider,
  StatusRequestProvider,
  ClientProvider
]);

const Contexts = ({ children }: IPropsCtxRoutes): JSX.Element => (
  <Providers>{children}</Providers>
);

export default Contexts;
