import React, { SyntheticEvent } from 'react';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';
import { Button, List, Segment } from 'semantic-ui-react';
import { useAuthState } from '../../contexts/auth';
import { useNavState } from '../../contexts/navigation';
import { useSettings } from '../../contexts/settings';
import * as Constants from '../../config/constants';
import * as Helpers from '../../helpers';
import * as Types from '../../types';
import * as Interfaces from '../../interfaces';

export interface PropsComponentViewList {
  type: Types.TLayerTypes;
  data: any[];
  isSearch?: boolean;
  loadMore?: boolean;
  onDelete?: () => void;
  refetchLoadMore?: () => void;
  setModalParam?: ((e: SyntheticEvent<HTMLElement>, data: any) => void) | null;
}

const ViewList = ({
  type,
  data,
  isSearch,
  onDelete,
  loadMore,
  setModalParam,
  refetchLoadMore
}: PropsComponentViewList): JSX.Element => {
  const { groupPermission } = useNavState();
  const { t } = useTranslation();
  const { theme } = useSettings();
  const { adminLevel } = useAuthState();

  const renderActions = ({ id, name }: any): JSX.Element => {
    if (!setModalParam) {
      return <></>;
    }
    return (
      <>
        {onDelete && (
          <Button
            circular
            icon='trash alternate'
            onClick={(e: SyntheticEvent<HTMLElement>) => {
              setModalParam(e, { id, name });
              onDelete();
            }}
          />
        )}
      </>
    );
  };

  const renderFeedAck = (): JSX.Element[] => {
    return data.map(
      (
        {
          name,
          createdAt,
          event,
          description,
          eventData,
          typeEvent
        }: Interfaces.ISubscriptionFeed,
        index
      ) => {
        const header = type !== 'GROUPS-FEEDACK' ? t(event as any) : name;
        const descriptionData: Interfaces.IParseDescriptionThing = {
          description,
          intensity: (eventData?.intensity as number) || null,
          origin: (eventData?.originCluster?.name as string) || null,
          state: (eventData?.originCluster?.state as string) || null,
          nameAudio: (eventData?.name as string) || null,
          date: (eventData?.date as string) || null,
          address: (eventData?.address as Interfaces.IAddressLicense) || null,
          payload: (eventData?.payload as string) || null,
          typeEvent,
          isLicenseeSkyAlert: adminLevel === 'SkyAlertLicensee'
        };

        return (
          <List.Item key={`receptor-FeedAck-${index.toString()}`}>
            <List.Content>
              <List.Header>{header}</List.Header>
              <List.Description className='description'>
                {type === 'GROUPS-FEEDACK' && <span>{t(event as any)}</span>}
                <span>
                  <Markdown>
                    {Helpers.parseDescriptionMonitorThing(descriptionData)}
                  </Markdown>
                </span>
                <span>{Helpers.formatDate(createdAt, 'LL LTS')}</span>
              </List.Description>
            </List.Content>
          </List.Item>
        );
      }
    );
  };

  const renderGroupsThings = (): JSX.Element[] => {
    return data.map(({ thing, thingName, thingType }, index) => (
      <List.Item key={`devices-${index.toString()}`}>
        <List.Content>
          <List.Header>{thingName}</List.Header>
          <List.Description className='description'>
            <span>{Helpers.parseKeysTypes('RECEPTOR', thingType)}</span>
            <span>{thing}</span>
          </List.Description>
        </List.Content>
      </List.Item>
    ));
  };

  const renderGroupsUsers = (): JSX.Element[] => {
    return data.map(({ userName, email, permissions, user }, index) => (
      <List.Item key={`detail-users-${index.toString()}`}>
        <List.Content>
          <List.Header>{userName}</List.Header>
          <List.Description className='description'>
            <span>
              {permissions && t(Constants.GROUP_PERMISSIONS[permissions].text)}
            </span>
            <span>{email}</span>
          </List.Description>
          <List.Content className='actions'>
            {data.length > 1 &&
              groupPermission === 'admin' &&
              renderActions({ id: user, name: userName })}
          </List.Content>
        </List.Content>
      </List.Item>
    ));
  };

  const renderItems = (): JSX.Element[] | JSX.Element => {
    switch (type) {
      case 'FEEDACK':
        return renderFeedAck();
      case 'GROUPS-THINGS':
        return renderGroupsThings();
      case 'GROUPS-USERS':
        return renderGroupsUsers();
      case 'GROUPS-FEEDACK':
        return renderFeedAck();
      default:
        return <List.Item />;
    }
  };

  return (
    <Segment inverted={theme !== 'light'} className='wrapper-view listView'>
      <List divided relaxed ordered>
        {renderItems()}
        {loadMore && !isSearch && (
          <div className='wrapper-btn'>
            <Button
              content={t('actions.loadMore')}
              onClick={refetchLoadMore}
              className='load-more'
            />
          </div>
        )}
      </List>
    </Segment>
  );
};

ViewList.defaultProps = {
  loadMore: false,
  isSearch: false,
  onDelete: () => null,
  setModalParam: null,
  refetchLoadMore: () => null
};

export default ViewList;
