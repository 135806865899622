import React, { useReducer, Dispatch, useState, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import { initialState, AuthReducer } from '../../reducers/auth';
import * as Helpers from '../../helpers';
import { IUserIoT } from '../../interfaces';
import { TAuthActions } from '../../types';

interface IPropsCtxRoutes {
  children: RouteProps['children'];
}

const AuthStateContext = React.createContext(initialState);
const AuthDispatchContext = React.createContext({} as Dispatch<TAuthActions>);

export const useAuthState = () => {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
};

export const useAuthDispatch = () => {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
};

export const AuthProvider: React.FC<IPropsCtxRoutes> = ({
  children
}: IPropsCtxRoutes) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);
  const [userGroups, setUserGroups] = useState<IUserIoT['groups']>({});

  const isLicensee = user.adminLevel === 'licensee';

  // TODO: REVIEW FUNCTION IN HELPERS
  const filteredUserGroups = (): IUserIoT['groups'] => {
    const allGroups = user.groups;
    let newGroups = {};
    Object.keys(allGroups).forEach((item) => {
      const groupId = item.split(':');
      if (groupId[0] === user.customer) {
        newGroups = { ...newGroups, [item]: allGroups[item] };
      }
      return newGroups;
    });

    return newGroups;
  };

  useEffect(() => {
    if (user.groups) {
      setUserGroups(filteredUserGroups());
    }
  }, [user.groups]);

  return (
    <AuthStateContext.Provider
      value={{
        ...user,
        groups: userGroups,
        adminLevel: Helpers.getAdminLevelFromStore(),
        isLicensee
      }}
    >
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
