import React, { SyntheticEvent, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Sidebar,
  Segment,
  Menu,
  Image,
  Accordion,
  MenuItemProps,
  Button,
  Icon
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../contexts/auth';
import { useNavState } from '../../contexts/navigation';
import { useSettings } from '../../contexts/settings';
import { useClients } from '../../hooks/useClients';
import Version from '../Version';
import LogoLicensee from '../LogoLicensee';
import SkyAlertLogo from '../../assets/images/SkyAlert-Logo.png';
import SkyAlertDarkLogo from '../../assets/images/SkyAlert-Dark-Mode.png';
import names from '../../config/names';
import * as Helpers from '../../helpers';
import * as Constants from '../../config/constants';
import { INavigationItemsLinks, INavigationItems } from '../../interfaces';
import { TUserTypes } from '../../types';

import './NavSidebar.scss';

interface IProps {
  children?: React.ReactNode;
}

interface IAccordionSection {
  [key: string]: boolean;
}

const { NAVIGATION_OPTIONS, INITIAL_PATH_ADMIN_LEVEL } = Constants;

const SECTIONS = [
  names.paths.licenses.main,
  names.paths.things.main,
  names.paths.groups.main,
  names.paths.users,
  names.paths.support
];

const SUBSECTIONS = [
  names.paths.licenses.assigned,
  names.paths.licenses.available,
  names.paths.things.desk,
  names.paths.things.gadgetMini,
  names.paths.things.epicenter
];

const ACCORDION_TITLES = [names.paths.things.main, names.paths.licenses.main];

const DEFAULT_ACCORDION_ITEM: IAccordionSection = {
  [names.paths.things.main]: false,
  [names.paths.licenses.main]: false
};

const NavSidebar = ({ children }: IProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { adminLevel, customerLicensee } = useAuthState();
  const { pathname } = useLocation();
  const { isDisableNavigation } = useClients();
  const { theme } = useSettings();
  const {
    section,
    updateSection,
    subsection,
    isTabOpen,
    isVisibleSidebar,
    isMobileBreakpoint,
    setIsTabOpen,
    setIsVisibleSidebar,
    updateSubsection,
    updateVisibleSidebar
  } = useNavState();

  const [accordionItems, setAccordionItems] = useState<IAccordionSection>(
    DEFAULT_ACCORDION_ITEM
  );

  const isShowContentAccordion = (value: string): boolean =>
    ACCORDION_TITLES.includes(value);

  useEffect(() => {
    setAccordionItems(DEFAULT_ACCORDION_ITEM);
    if (isShowContentAccordion(section)) {
      setAccordionItems({ ...DEFAULT_ACCORDION_ITEM, [section]: true });
    }
  }, [section]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (pathname === names.paths.dashboard) {
      updateSection(INITIAL_PATH_ADMIN_LEVEL[adminLevel]);
    } else {
      if (pathname.includes('detail')) {
        const newSubSection = SUBSECTIONS.find((element) =>
          pathname.includes(element)
        );
        updateSubsection(newSubSection || pathname);
      }
      const newSection = SECTIONS.find((element) => pathname.includes(element));
      const newSubSection = SUBSECTIONS.find((element) =>
        pathname.includes(element)
      );
      updateSection(newSection || pathname);
      updateSubsection(newSubSection || pathname);
    }
  }, [pathname]);

  const handlerMenuItem = (
    e: SyntheticEvent<HTMLElement>,
    path: string
  ): void => {
    e.preventDefault();
    if (isShowContentAccordion(section)) {
      setAccordionItems({
        ...DEFAULT_ACCORDION_ITEM,
        [section]: !accordionItems[section]
      });
    }
    if (path !== section || subsection || isTabOpen) {
      updateSection(path);
      navigate(path);
      setIsTabOpen(false);
    }
  };

  const handleMenuSubItem = (
    e: SyntheticEvent<HTMLElement>,
    path: string
  ): void => {
    e.preventDefault();
    if (path !== subsection || isTabOpen) {
      updateSubsection(path);
      navigate(path);
      setIsTabOpen(false);
    }
  };

  const handlerOnHideSidebar = (): void => {
    if (isMobileBreakpoint) {
      setIsVisibleSidebar(false);
    }
  };

  // TODO: Change image cross mark
  const renderLogo = (): JSX.Element => (
    <>
      {isMobileBreakpoint && (
        <Button
          floated='right'
          className='navsidebar-button-close'
          onClick={updateVisibleSidebar}
        >
          <Image src='https://www.pngmart.com/files/13/Cross-Mark-PNG-Image.png' />
        </Button>
      )}
      <Link
        to={INITIAL_PATH_ADMIN_LEVEL[adminLevel] || '/'}
        onClick={(e) =>
          handlerMenuItem(e, INITIAL_PATH_ADMIN_LEVEL[adminLevel])
        }
      >
        <Image
          className='logo-menu'
          src={theme === 'light' ? SkyAlertLogo : SkyAlertDarkLogo}
          size='medium'
        />
      </Link>
    </>
  );

  const renderItemsNavigationChildren = (
    elements: INavigationItemsLinks[],
    item: string
  ): JSX.Element => {
    return (
      <Accordion.Content active={accordionItems[item]}>
        {Object.values(elements).map(
          ({ disabled, text, path }, index) =>
            !disabled && (
              <span
                className={`link-custom ${
                  subsection === path ? 'active' : ''
                } ${disabled ? 'disabled' : ''}`}
                key={`navigation-link-subsection-${index + 1}`}
                onClick={(e) => !disabled && handleMenuSubItem(e, path)}
                onKeyDown={(e) => !disabled && handleMenuSubItem(e, path)}
                role='link'
                tabIndex={index}
              >
                {t(text as any)}
              </span>
            )
        )}
      </Accordion.Content>
    );
  };

  const renderItemsNavigation = (): MenuItemProps => {
    const filterNavigationByPermissions = Object.values(
      NAVIGATION_OPTIONS.LIST
    ).filter(({ permission }) => permission.includes(adminLevel));

    const filteredNavigationForFeatureStaging =
      Helpers.filterListForFeatureStaging<INavigationItems>(
        filterNavigationByPermissions
      );

    return Object.values(filteredNavigationForFeatureStaging).map(
      ({ location, disabled, icon, text, links, permission }, index) => {
        const isDisable = isDisableNavigation(location);
        const isActive = section === location;
        const userRole = (adminLevel ?? 'owner') as TUserTypes;

        return (
          !disabled &&
          permission.includes(userRole) && (
            <Menu.Item
              key={`navigation-item-${index + 1}`}
              active={isActive}
              className={isActive && !disabled ? 'selected' : ''}
              disabled={disabled}
            >
              <Accordion
                className={`item-accordion ${isDisable ? 'isDisable' : ''}`}
                fluid
              >
                <Accordion.Title
                  active={accordionItems[location]}
                  index={index}
                  onClick={(e) =>
                    !disabled && !isDisable && handlerMenuItem(e, location)
                  }
                >
                  <Icon name={icon} />
                  {t(text as any)}
                  {isShowContentAccordion(location) && (
                    <Icon name='dropdown' className='icon-accordion' />
                  )}
                </Accordion.Title>
                {!!links?.length &&
                  renderItemsNavigationChildren(links, location)}
              </Accordion>
            </Menu.Item>
          )
        );
      }
    );
  };

  return (
    <Sidebar.Pushable as={Segment} basic className='wrapper-navsidebar'>
      <Sidebar
        secondary
        visible={isVisibleSidebar}
        as={Menu}
        fixed='left'
        animation={isMobileBreakpoint ? 'overlay' : 'push'}
        onHide={handlerOnHideSidebar}
        icon='labeled'
        vertical
        width='wide'
      >
        {renderLogo()}
        {renderItemsNavigation()}
        {isMobileBreakpoint && (
          <LogoLicensee
            section='navsidebar'
            customerLicensee={customerLicensee}
          />
        )}
        <Version />
      </Sidebar>
      <Sidebar.Pusher dimmed={isMobileBreakpoint && isVisibleSidebar}>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

NavSidebar.defaultProps = {
  children: <div />
};

export default NavSidebar;
