import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Header, Icon } from 'semantic-ui-react';
import * as Interfaces from '../../../../interfaces';

export interface PropsComponentModalStatusView {
  type: Interfaces.IModalControlInitialState['scheduled'];
  isSend: boolean;
  loading?: boolean;
  errorMessage?: string;
}

const Status = ({
  type,
  isSend,
  loading,
  errorMessage
}: PropsComponentModalStatusView): JSX.Element => {
  const { t } = useTranslation();

  const renderState = (): JSX.Element => {
    if (loading) {
      return <Icon name='sync' loading size='huge' />;
    }

    const keyLabelType = type === 'scheduled' ? 'schedule' : 'command';

    return (
      <Header textAlign='center' as='h2' icon>
        <Icon
          name={isSend ? 'check circle' : 'ban'}
          color={isSend ? 'green' : 'red'}
        />
        {isSend
          ? t(`control.status.send.${keyLabelType}`)
          : t(`control.status.fail.${keyLabelType}`)}
        {errorMessage && <Header.Subheader>{errorMessage}</Header.Subheader>}
      </Header>
    );
  };

  return (
    <Grid textAlign='center'>
      <Grid.Row columns='1'>
        <Grid.Column style={{ alignItems: 'center' }}>
          {renderState()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

Status.defaultProps = {
  errorMessage: '',
  loading: true
};

export default Status;
