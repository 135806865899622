import React, { SyntheticEvent } from 'react';
import { Button, DropdownProps, Grid, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import { useSettings } from '../../contexts/settings';
import DarkMode from './DarkMode';
import Language from './Language';
import * as Types from '../../types';

import '../../scss/layout/Modal.scss';

export interface PropsComponentSettings {
  isOpen: boolean;
  onCancel(): void;
}

const UserSettings = ({
  isOpen,
  onCancel
}: PropsComponentSettings): JSX.Element => {
  const { t } = useTranslation();
  const { language, setLanguageSelect, theme, setThemeMode } = useSettings();

  const handleOnChangeLanguage = (
    e: SyntheticEvent,
    { value }: DropdownProps
  ): void => {
    e.preventDefault();
    setLanguageSelect(value as Types.TLanguage);

    Analytics.sendEventCustom({
      category: 'Settings',
      action: 'Change',
      label: 'Language'
    });
  };

  const handleOnChangeTheme = (e: SyntheticEvent): void => {
    e.preventDefault();
    const newTheme = theme !== 'light' ? 'light' : 'dark';
    setThemeMode(newTheme);

    Analytics.sendEventCustom({
      category: 'Settings',
      action: 'Change',
      label: 'Theme'
    });
  };

  return (
    <Modal size='tiny' open={isOpen} className='wrapper-modal'>
      <Modal.Header>{t('settings.title')}</Modal.Header>
      <Modal.Content>
        <Grid verticalAlign='middle' textAlign='right'>
          <Grid.Row>
            <Grid.Column width={16}>
              <DarkMode
                isDarkMode={theme !== 'light'}
                onChangeTheme={handleOnChangeTheme}
              />
            </Grid.Column>
          </Grid.Row>
          <Language
            select={language}
            onChangeLanguage={handleOnChangeLanguage}
          />
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onCancel} content={t('actions.close')} />
      </Modal.Actions>
    </Modal>
  );
};

export default UserSettings;
