import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  Grid,
  Icon,
  Label,
  Segment,
  Tab,
  TabProps
} from 'semantic-ui-react';
import {
  useLocation,
  Navigate,
  Location,
  useSearchParams
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavState } from '../../../contexts/navigation';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import Analytics from '../../../controllers/Analytics';
import Layout from '../../../components/Layout';
import BreadcrumbCustom from '../../../components/Breadcrumb';
import Tracking from './Tracking';
import Control from './Control';
import Devices from './Devices';
import Users from './Users';
import Subgroups from './Subgroups';
import Reports from './Reports';
import { getParam } from '../../../helpers';
import {
  GROUP_PERMISSIONS,
  SECTIONS_GROUPS_DETAIL
} from '../../../config/constants';
import { IPropsRoutes, IAnalyticsViewPage } from '../../../interfaces';
import names from '../../../config/names';

import '../../../scss/layout/GroupsDetail.scss';

type groupState = Omit<Location, 'state'> & {
  state: {
    groupId: string;
    groupName: string;
  };
};

const DEFAULT_INDEX_PANE = 0;

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.groups.match,
  title: 'Group Detail'
};

const GroupsDetail = ({ isNavbar }: IPropsRoutes): JSX.Element => {
  const { t } = useTranslation();
  const { state } = useLocation() as groupState;
  const { setIsTabOpen, groupPermission } = useNavState();
  const [searchParams] = useSearchParams();
  const {
    screen: { isMobile },
    isMobileDevice
  } = useBreakpoint();

  const [activePane, setActivePane] = useState<number>(DEFAULT_INDEX_PANE);
  const [activeDropdown, setActiveDropdown] =
    useState<number>(DEFAULT_INDEX_PANE);

  useEffect(() => {
    Analytics.sendPageView(ANALYTICS_PAGE_VIEW.page, ANALYTICS_PAGE_VIEW.title);
  }, []);

  useEffect(() => {
    setActivePane(DEFAULT_INDEX_PANE);
    setIsTabOpen(true);
  }, [state]);

  const handlerOnTabChange = (
    e: SyntheticEvent,
    { active }: TabProps
  ): void => {
    if (active !== activePane) {
      setActivePane(active);
      setActiveDropdown(active);
    }
  };

  const handlerClickDropdownItem = (
    e: SyntheticEvent,
    { value }: DropdownItemProps
  ) => {
    if (value !== activePane && typeof value === 'number') {
      setActivePane(value);
      setActiveDropdown(value);
    }
  };

  const renderDropdownMenu = (): JSX.Element => {
    const { content, icon } = SECTIONS_GROUPS_DETAIL[activePane || 0];
    const text = content ?? 'groups.subsections.tracking';
    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={16}>
            <Dropdown
              as={Button}
              text={t(text as any)}
              icon={icon}
              floating
              labeled
              button
              className='icon'
              fluid
              color='orange'
              style={{ width: '80%', margin: 'auto', textAlign: 'center' }}
              value={activeDropdown}
            >
              <Dropdown.Menu>
                {SECTIONS_GROUPS_DETAIL.map((item, index) => (
                  <Dropdown.Item
                    key={`drop-${item.content}`}
                    icon={item.icon}
                    text={t(item.content as any)}
                    onClick={handlerClickDropdownItem}
                    value={index}
                    disabled={item.disabled}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const props = {
    groupId: state?.groupId || getParam('id', searchParams) || '',
    groupName: state?.groupName || getParam('name', searchParams) || ''
  };

  const sectionListComponents: React.ReactNode[] = [
    <Tracking {...props} key='Component-Tracking' />,
    <Control {...props} key='Component-Control' />,
    <Devices {...props} key='Component-Devices' />,
    <Users {...props} key='Component-Users' />,
    <Subgroups {...props} key='Component-Subgroups' />,
    <Reports {...props} key='Component-Reports' />
  ];

  const panes: TabProps['panes'] = SECTIONS_GROUPS_DETAIL.map(
    (section, index) => ({
      menuItem: {
        ...section,
        content: t(section.content as any),
        key: `pane-${index.toString()}`
      },
      render: () => sectionListComponents[index]
    })
  );

  const { groupId, groupName } = props;

  if (!groupId || !groupName) {
    return <Navigate to={names.paths.groups.main} />;
  }

  const isMobileView = isMobile || isMobileDevice;
  return (
    <Layout title={groupName} showNavbar={isNavbar}>
      <BreadcrumbCustom flow={[names.navigation.groups, groupName]} isDetail />
      <Segment basic className='wrapper-groups-detail'>
        {groupPermission && (
          <Label
            color='orange'
            style={
              isMobileView
                ? { display: 'flex', justifyContent: 'center', margin: 'auto' }
                : { position: 'absolute', left: '100%', top: '0', zIndex: '1' }
            }
            basic={isMobileView}
            ribbon={isMobileView ? false : 'right'}
            className='label-permission'
          >
            <Icon name={GROUP_PERMISSIONS[groupPermission].icon} />
            {t('breadcrumbCustom.view', {
              permission: t(GROUP_PERMISSIONS[groupPermission].text)
            })}
          </Label>
        )}
        {isMobileView && renderDropdownMenu()}
        <Tab
          className={`list-sections ${isMobileView ? 'hidden' : ''}`}
          activeIndex={activePane}
          onTabChange={handlerOnTabChange}
          menu={{ borderless: true, attached: false, tabular: false }}
          panes={panes}
        />
      </Segment>
    </Layout>
  );
};

export default GroupsDetail;
