import React from 'react';
import { Divider, Grid, Icon, Segment, Statistic } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../../contexts/settings';
import { useNavState } from '../../../../contexts/navigation';
import { useGroup } from '../../../../hooks/groups/useGroup';
import FeedAck from '../../../../components/FeedAck';
import { SkeletonStatics } from '../../../../components/Skeletons';
import {
  IGroupComponentProps,
  IAnalyticsViewPage
} from '../../../../interfaces';
import names from '../../../../config/names';

import '../../../../scss/layout/GroupsDetail.scss';

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.groups.main,
  title: 'Groups Detail Monitor'
};

const Tracking: React.FC<IGroupComponentProps> = ({
  groupId
}: IGroupComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { isMobileBreakpoint } = useNavState();
  const { theme } = useSettings();
  const {
    isLoading: { group: isLoadingRequest },
    dataGroup: { totalGroupThings }
  } = useGroup({ id: groupId, analytics: ANALYTICS_PAGE_VIEW });

  const renderInfoStatic = (): JSX.Element => (
    <Grid relaxed stackable padded columns='2'>
      <Grid.Row textAlign='center'>
        {[null, 'registered', 'disconnected', null].map((item, index) => {
          if (item) {
            const isDisconnected = item !== 'registered';
            const values = isDisconnected
              ? totalGroupThings.disconnected
              : totalGroupThings.registered;
            const color = isDisconnected ? 'red' : 'green';
            return (
              <Grid.Column width={4} key={`col-${index + 1}`}>
                <Statistic
                  horizontal={isMobileBreakpoint}
                  inverted={theme !== 'light'}
                  size='large'
                >
                  <Statistic.Value>{values}</Statistic.Value>
                  <Statistic.Label>
                    <Icon name='circle' size='small' color={color} />
                    {t(
                      `groups.tracking.${
                        isDisconnected ? 'disconnected' : 'registered'
                      }` as any,
                      { count: Number.isNaN(values) ? +values : 0 }
                    )}
                  </Statistic.Label>
                </Statistic>
              </Grid.Column>
            );
          }
          return <Grid.Column width={4} key={`col-${index + 1}`} />;
        })}
      </Grid.Row>
    </Grid>
  );

  return (
    <Segment basic className='wrapper-monitor-group'>
      {isLoadingRequest ? <SkeletonStatics /> : renderInfoStatic()}
      <Divider style={{ margin: '2.5em auto' }} hidden />
      <FeedAck section='group' groupId={groupId} />
    </Segment>
  );
};

export default Tracking;
