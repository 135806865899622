import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Segment, TabProps } from 'semantic-ui-react';
import { useSettings } from '../../contexts/settings';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useMonitorThings } from '../../hooks/useMonitorThings';
import Layout from '../../components/Layout';
import ModalDetailMonitorThings from '../../components/Modals/DetailMonitorThings';
import ModalThingsReport from '../../components/Modals/Create/ThingsReport';
import ModalCoords from '../../components/Modals/Coords';
import ContainerConnections from './ContainerConnections';
import names from '../../config/names';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';

import './MonitorThings.scss';

const { SECTIONS_MONITOR_THINGS } = Constants;

const ANALYTICS_PAGE_VIEW: Interfaces.IAnalyticsViewPage = {
  page: names.paths.monitorThings,
  title: 'Monitor'
};

const MonitorThings = ({ isNavbar }: Interfaces.IPropsRoutes): JSX.Element => {
  const { t } = useTranslation();
  const { theme } = useSettings();
  const {
    screen: { isMobile },
    isMobileDevice
  } = useBreakpoint();

  const {
    isLoading,
    isLoadingConnections,
    isLoadingRedirectThing,
    isLoadingSettingsThing,
    allThingsList,
    thingConnectionDetails,
    recordsConnection,
    activeTabPaneReceptors,
    selectThingInfo,
    selectorDaysHistory,
    addressThingSelect,
    coordsThingSelect,
    thingsIdIgnored,
    isOpenModalReport,
    isOpenModalCoords,
    isOpenModalDetailConnection,
    handleOnClickIdThing,
    handleRefreshThingInfo,
    handleOnChangeTabReceptor,
    handleOnCancelModalReport,
    handleOnCloseModalCoords,
    handleOnClickSelectorDays,
    handleOnChangeThingIgnored,
    handleOnCloseModalDetailConnection,
    handleOnClickModalContainerConnectionsAndCoords
  } = useMonitorThings({ analytics: ANALYTICS_PAGE_VIEW });

  const panes: TabProps['panes'] = SECTIONS_MONITOR_THINGS.map((section) => ({
    menuItem: {
      ...section,
      content: t(section.text as any),
      key: section.id,
      disabled: isLoading
    },
    render: () => (
      <ContainerConnections
        type={section.receptor}
        thingsList={allThingsList}
        thingsIgnored={thingsIdIgnored}
        isLoadingRequest={isLoading}
        recordsConnection={recordsConnection}
        handleReload={handleRefreshThingInfo}
        handleModalDetailConnections={
          handleOnClickModalContainerConnectionsAndCoords
        }
      />
    )
  }));

  const isMobileView = isMobile || isMobileDevice;
  return (
    <Layout title={t('sections.monitorThings')} showNavbar={isNavbar}>
      <Segment
        inverted={theme === 'dark'}
        className='wrapper-monitor-things'
        data-testid='monitorThings-routes'
        style={{ marginBlock: 25 }}
      >
        <Tab
          className={`list-sections ${isMobileView ? 'hidden' : ''}`}
          activeIndex={activeTabPaneReceptors}
          onTabChange={handleOnChangeTabReceptor}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
      </Segment>
      <ModalThingsReport
        isOpen={isOpenModalReport}
        isLoading={isLoading}
        onAccept={() => null}
        onCancel={handleOnCancelModalReport}
      />
      <ModalDetailMonitorThings
        isLoading={isLoadingConnections}
        isLoadingRedirectThing={isLoadingRedirectThing}
        isLoadingSettingsThing={isLoadingSettingsThing}
        isOpen={isOpenModalDetailConnection}
        isError={thingConnectionDetails.isError}
        infoThing={selectThingInfo}
        selectorDaysHistory={selectorDaysHistory}
        connectionsHistory={thingConnectionDetails.history}
        rangeDateHistory={thingConnectionDetails.dateRange}
        thingsIgnored={thingsIdIgnored}
        onClose={handleOnCloseModalDetailConnection}
        onClickSelectorDay={handleOnClickSelectorDays}
        onClickIdThing={handleOnClickIdThing}
        onChangeThingIgnored={handleOnChangeThingIgnored}
      />
      <ModalCoords
        address={addressThingSelect}
        coords={coordsThingSelect}
        isOpen={isOpenModalCoords}
        onCancel={handleOnCloseModalCoords}
      />
    </Layout>
  );
};

export default MonitorThings;
