import React, { useEffect, useState } from 'react';
import { Placeholder } from 'semantic-ui-react';
import {
  GoogleMap,
  GoogleMapProps,
  Marker,
  useJsApiLoader
} from '@react-google-maps/api';
import ReporterLogger from '../../controllers/ReporterLogger';
import { useMaps } from '../../hooks/useMaps';
import Empty from '../Empty';
import * as Interfaces from '../../interfaces';

import './Map.scss';

export interface PropsComponentsMapLicenses {
  children?: React.ReactNode;
  initialZoom?: number;
  isDraggable?: boolean;
  isDisable?: boolean;
  center: GoogleMapProps['center'];
  options: google.maps.MapOptions;
  setNewCoords?: (newCoords: Interfaces.ICoords) => void;
}

const styles = {
  map: {
    height: '100%',
    width: '100%'
  }
};

const DEFAULT_CENTER = {
  lat: 19.4284,
  lng: -99.1276
};

const LoggerInstance = ReporterLogger.getInstance();

export const MapLicenses = ({
  center,
  options,
  children,
  setNewCoords,
  initialZoom,
  isDraggable,
  isDisable
}: PropsComponentsMapLicenses): JSX.Element => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`
  });
  const { refMap, onLoad, onDragEnd } = useMaps(center);

  const [mapCenter, setMapCenter] =
    useState<GoogleMapProps['center']>(DEFAULT_CENTER);

  useEffect(() => {
    if (refMap && refMap.getZoom() !== initialZoom) {
      const currentZoom = refMap.getZoom() as number;
      refMap?.setZoom(currentZoom);
    }
    setMapCenter(center);
  }, [center]);

  const handlerOnDragEnd = (e: google.maps.MapMouseEvent): void => {
    const coords = onDragEnd(e);
    if (setNewCoords) setNewCoords(coords);
  };

  if (loadError) {
    LoggerInstance.error('Failed GoogleMap render - MapLicenses');
    return <Empty iconName='map signs' texts={{ title: 'error' }} />;
  }

  const renderMap = (): JSX.Element => {
    return isLoaded ? (
      <GoogleMap
        mapContainerStyle={styles.map}
        center={mapCenter}
        onLoad={onLoad}
        options={{ ...options }}
      >
        {children}
        <Marker
          position={mapCenter || DEFAULT_CENTER}
          draggable={isDraggable}
          onDragEnd={handlerOnDragEnd}
        />
      </GoogleMap>
    ) : (
      <></>
    );
  };

  const renderLoader = (): JSX.Element => (
    <Placeholder style={{ height: '100%', maxWidth: '100%' }}>
      <Placeholder.Image />
    </Placeholder>
  );

  return !isDisable ? renderMap() : renderLoader();
};

MapLicenses.defaultProps = {
  children: false,
  initialZoom: 8,
  isDraggable: false,
  isDisable: false,
  setNewCoords: () => null
};

export default React.memo(MapLicenses);
