export default {
  common: {
    actions: {
      add: 'Add',
      accept: 'Accept',
      affirm: 'Yes',
      backReturn: 'Back',
      cancel: 'Cancel',
      create: 'Create',
      close: 'Close',
      edit: 'Edit',
      delete: 'Delete',
      done: 'Done',
      deny: 'No',
      detail: 'Show detail',
      download: 'Download',
      save: 'Save',
      search: 'Search',
      seeAll: 'See all',
      select: 'Select',
      selectAll: 'Select All',
      next: 'Next',
      send: 'Send',
      generate: 'Generate',
      assign: 'Assign',
      assignMultiple: 'Assign Desk licenses',
      export: 'Export',
      filter: 'Filter',
      finish: 'Finish',
      former: 'Former',
      show: 'Show',
      update: 'Refresh',
      new: 'New',
      history: 'see detail',
      no: 'No',
      yes: 'Yes',
      loadMore: 'Load more records',
      resendTemporaryPassword: 'Password',
      back: {
        home: 'Back to home',
        login: 'Back to login'
      },
      login: {
        accept: {
          0: 'Login',
          1: 'Submit',
          2: 'Update password',
          3: 'Reset password'
        },
        links: {
          0: 'Forgot your password?',
          1: 'Log back in',
          2: '',
          3: 'Re-enter your email address'
        }
      },
      requests: {
        command: {
          send: 'Send command',
          fetch: 'Fetch commands',
          delete: 'Delete commands'
        },
        assign: {
          licenses: 'Assign license'
        },
        add: {
          thing: 'Add Device',
          user: 'Add User'
        },
        create: {
          group: 'Create Group',
          subgroup: 'Create Subgroup',
          clients: 'Create Clients',
          user: 'Create User'
        },
        delete: {
          group: 'Delete Group',
          subgroup: 'Delete Subgroup',
          user: 'Delete User'
        },
        edit: {
          users: 'Edit user',
          clients: 'Edit client',
          licenses: 'Edit license',
          thing: 'Edit device'
        },
        update: {
          settings: 'Update settings'
        },
        fetch: {
          clients: 'Search client',
          licenses: 'Consult licenses'
        }
      },
      control: {
        initial: {
          cancel: 'Cancel',
          accept: 'Next'
        },
        scheduled: {
          cancel: 'Cancel',
          accept: 'Send'
        },
        customAlert: {
          cancel: 'Back',
          accept: 'Next'
        },
        customAudio: {
          cancel: 'Back',
          accept: 'Next'
        },
        confirm: {
          cancel: 'Back',
          accept: 'Yes, I am sure'
        },
        result: {
          cancel: 'Close',
          accept: ''
        }
      },
      view: {
        cards: 'View Cards',
        table: 'Vista Table'
      }
    },
    assignation: {
      available: 'To Redeem',
      assigned: 'Redeemed',
      revoked: 'Suspended',
      expired: 'Expired',
      shared: 'Shared'
    },
    authorization: {
      earthquakes: 'Earthquake Alert',
      monitor: 'Monitoring',
      monitoring: 'Monitoring',
      customCommands: 'Custom Alert',
      internalMessaging: 'Internal Alert',
      storms: 'Storm Warning',
      cyclones: 'Cyclone Warning',
      tornadoes: 'Tornado Warning',
      hurricanes: 'Hurricane Warning',
      wildfires: 'Wildfires Warning',
      tsunami: 'Hydrological Warning',
      volcanoes: 'Volcano Warning'
    },
    avatar: {
      account: 'Account',
      settings: 'Settings',
      signOut: 'Close Session',
      tutorial: 'Tutorial'
    },
    breadcrumbCustom: {
      detail: 'Detail',
      available: 'To Redeem',
      assigned: 'Redeemed',
      revoked: 'Suspended',
      expired: 'Expired',
      epicenter: 'Epicenter',
      gadget: 'Gadget',
      desk: 'Desk',
      view: 'View of {{permission}}'
    },
    clients: {
      title: 'Clients',
      add: 'Add client',
      search: 'Search by',
      secondDropdown: ''
    },
    control: {
      customCommands: {
        title: 'Title',
        body: 'Content'
      },
      customAudio: {
        form: {
          title: 'Select the audio you want to send',
          label: 'Audio name',
          placeholder: 'Select a custom audio name',
          errorNoAudio: 'No audio records found',
          nameAudio:
            '<div className="sub header">Will play the following audio: *{{name}}*</div>'
        }
      },
      commands: {
        update: {
          visible: 'Update',
          hidden: 'Send Command',
          description: 'The device has been updated to the latest version'
        },
        restart: {
          visible: 'Reboot',
          hidden: 'Send Command',
          description: 'The computer rebooted remotely'
        },
        earthquake: {
          visible: 'Earthquake',
          hidden: 'Send Command',
          description: 'The device received an earthquake',
          intensity:
            'The device received an earthquake with the intensity **{{intensity}}**',
          origin: 'The device received an earthquake, (**{{origin}}**)',
          intensityAndOrigin:
            'The device received an earthquake with the intensity **{{intensity}}** (**{{origin}}**)'
        },
        drill: {
          start: {
            visible: 'Drill',
            hidden: 'Send Command',
            description: 'Received remote drill'
          },
          stop: {
            visible: 'Stop Drill',
            hidden: 'Send Command',
            description: 'The drill was stopped remotely'
          }
        },
        customAlert: {
          visible: 'Custom Alert',
          hidden: 'Open',
          description: 'You have received a personalized alert'
        },
        customAudio: {
          visible: 'Custom Sound',
          hidden: 'Open',
          description: 'You have received a personalized sound',
          nameAudio: 'Received personalized sound with the name **{{name}}**'
        },
        testSound: {
          visible: 'Test Sound',
          hidden: 'Send Command',
          description: 'Received sound test'
        },
        silentNotification: {
          visible: 'Silent Notification',
          hidden: 'Send Command',
          description: 'Received silent notification'
        },
        configuration: {
          visible: 'Change Config',
          hidden: 'Show Config',
          description: 'The configuration of the equipment has been updated'
        },
        updateLocation: {
          visible: 'Change Location',
          hidden: 'Send Command',
          description: 'The device location has been **remotely** updated',
          location:
            'The location of the device has been **remotely** updated to **{{state}}**, **{{county}}** (ZIP code {{zipCode}})'
        },
        scheduleLocalDrill: {
          visible: 'System',
          hidden: 'Send command',
          skyAlertLicensee: 'Local drill',
          skyAlertLicenseeDate: 'Local drill **({{date}})**'
        }
      },
      schedule: {
        title: '{{count}} Scheduled Command',
        table: {
          status: {
            pending: 'Pending',
            sent: 'Sent',
            canceled: 'Canceled'
          }
        },
        card: {
          date: 'Date: {{date}}',
          user: 'Scheduled by {{user}}'
        },
        cancel: {
          title: 'Are you sure you want to cancel the scheduled command?',
          subtitle:
            '<p className="subtitle">To confirm the cancellation of this command, we recommend verifying that the data is correct.</p><ul className="list-command-schedule "><li><strong>ID</strong>: {{id}}</li><li><strong>Command</strong>: {{command}}</li><li><strong>Scheduled Date</strong>: {{date}}</li></ul> <strong>Note</strong>: Remember that once you cancel it, you won\'t be able to change its status.'
        }
      },
      status: {
        send: {
          schedule: 'The command has been scheduled successfully',
          command: 'The command has been sent successfully'
        },
        fail: {
          schedule:
            'The scheduled command could not be sent, please try again later',
          command: 'The command could not be sent, please try again later'
        }
      },
      important: {
        groups:
          'Verify that all devices in the group are connected to receive commands correctly',
        things:
          'Verify that the device is connected to receive commands correctly'
      },
      empty: {
        title: 'Currently there are no scheduled commands'
      }
    },
    connectionStatus: {
      connected: 'Connected',
      disconnected: 'Disconnected',
      device: '{{count}} Device',
      device_other: '{{count}} Devices',
      notAvailable: 'Not available',
      monitorThings: 'Devices of all clients'
    },
    customAlerts: {
      alert: 'Alert',
      alert_0: 'Alert no available',
      alert_1: 'Alert',
      stateOutputs: {
        none: 'Not Available',
        allActive: 'Relay 1 & 2',
        relay: 'Relay {{number}}'
      }
    },
    damageReport: {
      title: 'Report Status',
      empty: { title: 'Everything is in order' },
      moreInfo: 'See detail',
      modal: {
        title: 'Report details',
        thingName: 'Device name',
        dateReport: 'Report date',
        dateEarthquake: 'Earthquake date',
        detail: 'Observations',
        noDetail: 'No observations',
        damageStatus: 'Damage reported'
      },
      important:
        '**Important:** The damage report form will be shown to the user of the **Desk** device when an earthquake of intensity <span className="moderate-color">**MODERATE**</span> to <span className="severe-color">**SEVERE**</span>.',
      description:
        'Check the damages reported by the **Desk** teams with respect to the earthquakes of the **last 24 hours**, the effects included in each report are the following:',
      reportedAt: 'Reported: {{date}}',
      earthquakeDate: 'Earthquake date: {{date}}',
      status: {
        info: {
          title: 'Information',
          description:
            'In order to provide a clearer understanding of the damage report, we provide you with the following descriptions and icons, each with their respective effects for reference.'
        },
        none: { title: 'None', description: 'There is no damage.' },
        people: {
          title: 'People',
          description:
            'Internal staff, visitors, clients, residents, or others.'
        },
        inventory: {
          title: 'Products',
          description:
            'Damages to products or services, disruptions in delivery, or other issues'
        },
        property: {
          title: 'Property',
          description:
            'Damage to physical infrastructure, such as buildings, facilities, or equipment.'
        },
        other: {
          title: 'Other',
          description: 'Comments regarding the status report were reported.'
        }
      }
    },
    date: {
      months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
      }
    },
    error: {
      notAvailable: 'Not available',
      notConnected: 'Not connected',
      notAssigned: 'Not assigned',
      noDataYet: 'No data yet',
      notDataRegister: 'Unrecorded data',
      notInfoAvailable: 'Information not available',
      unknown: 'Unknown',
      unknownCommand: 'Unknown command',
      warning: 'Warning!',
      login: {
        email: 'Enter a valid email address',
        passwordMatch: 'Passwords must be the same',
        cognitoException: {
          notAuthorized: 'Incorrect user and/or password',
          codeMismatch: 'The verification code provided is invalid, try later',
          limitExceeded:
            'You have reached the limit of attempts to recover password',
          passRequirements: 'You do not meet the password requirements',
          userNameEmpty: 'The code has expired',
          expiredCode:
            'The verification code has expired,  <a href="{{url}}">**generates another one**</a>'
        },
        notFound:
          'The user you are looking for does not exist, please verify it'
      },
      clients: {
        name: 'The name can only contain upper or lower case letters',
        email: 'Enter a valid email',
        commercialId:
          'The commercial ID can only contain letters, numbers and certain signs (-#/*).'
      },
      devices: {
        title: 'No records!',
        subtitle: 'At the moment you have no equipment available.'
      },
      feedAck: {
        failedGetInfo: 'Failure to get the information'
      },
      groups: {
        title: 'Oops! There was a problem',
        subtitle: "We couldn't get the most recent events, try again later."
      },
      modals: {
        labels: {
          nameLong: 'Name is too long',
          lastName: 'Enter a valid last name',
          name: 'Enter a valid name',
          email: 'Enter a valid email'
        }
      },
      search: {
        title: 'No results found for your search',
        subtitle: 'Try searching with another name'
      },
      requests: {
        group: {
          fetch: 'No information could be obtained from the group',
          fetchThings:
            'Records could not be obtained from the recipients in the group.',
          fetchUsers: 'Could not obtain records of users in the group.'
        },
        groups: {
          create: 'There was an error creating the group "{{name}}"',
          createThing: 'There was an error adding the device',
          createUser: 'There was an error deleting the user',
          delete: 'The group could not be deleted "{{name}}"',
          deleteUser: 'There was an error deleting the user',
          fetch: 'No information could be obtained from the groups',
          fetchUsers: 'Users could not be obtained',
          fetchThings:
            'It was not possible to obtain records of the devices in the group.',
          fetchReport: 'The report could not be obtained'
        },
        subgroups: {
          create: 'There was an error creating the subgroup',
          fetch: 'No records could be obtained for the following subgroups'
        },
        licenses: {
          fetch: 'No license records could be obtained',
          edit: 'A problem occurred when editing the license {{id}}',
          assign: 'A problem occurred while assigning the license'
        },
        things: {
          edit: 'There was a problem changing the device name to {{name}}',
          total: 'No information could be obtained from the devices',
          fetch: 'It was not possible to obtain the details of this device',
          list: 'Unable to obtain the devices',
          settings: 'Device configuration could not be obtained',
          updateSettings: 'Could not update device configuration'
        },
        commands: {
          send: 'Could not send command, try again later',
          fetch: 'Could not get commands scheduled, try again later',
          thingId: 'A problem occurred, please contact the administrator',
          delete: 'Could not delete command, try again later'
        },
        feedAck: {
          fetch: 'Failure to bring the information'
        },
        users: {
          fetch: 'Could not get user records',
          create: `There was an error creating user - error code: {{code}}`,
          delete: `There was an error deleting user - error code: {{code}}`,
          edit: `There was an error editing user - error code: {{code}}`,
          resendTemporaryPassword:
            'There was an error by sending the temporary password - error code: {{code}}'
        },
        clients: {
          fetch: 'There was an error search client',
          create: 'There was an error creating client {{name}}',
          edit: 'There was an error editing client {{name}}'
        },
        monitor: {
          historyConnection: 'There was an error getting connection history',
          thingsConnected: 'There was an error getting the devices connected',
          fetchThingsInfo:
            'There was a problem getting information from devices',
          statusConnection: 'There was a problem check the connection status'
        }
      },
      tutorial: {
        form: {
          firstName: 'Enter only letters',
          lastName: 'Enter only letters',
          email: 'Invalid email'
        }
      }
    },
    feedAck: {
      title_one: 'Showing last record',
      title_other: 'Showing last {{count}} records',
      events: {
        connected: 'Connected',
        disconnected: 'Disconnected',
        unknown: 'Event unknown',
        description: {
          receives: 'Event Received at ',
          connected: 'Connected equipment',
          disconnected: 'Disconnected equipment',
          unknown: 'Unknown',
          locationUpdated: 'The device location has been updated',
          locationUpdatedWithAddress:
            'The device location has been updated to **{{county}}**, **{{state}}** (ZIP code {{zipCode}})'
        }
      }
    },
    filteredTable: {
      searchName: 'Search by name...'
    },
    graph: {
      title_one: 'You currently have {{count}} license',
      title_other: 'You currently have {{count}} licenses',
      available: '{{percent}} To Redeem',
      assigned: '{{percent}} Redeemed',
      revoked: '{{percent}} Suspended',
      expired: '{{percent}} Canceled'
    },
    groups: {
      subsections: {
        tracking: 'Tracking',
        control: 'Control',
        devices: 'Devices',
        users: 'Users',
        subgroups: 'Subgroups',
        reports: 'Reports'
      },
      tracking: {
        registered_one: 'Connect',
        registered_other: 'Connected',
        disconnected_one: 'Disconnect',
        disconnected_other: 'Disconnected',
        register_one: 'Register',
        register_other: 'Registers'
      },
      subgroups: {
        registers_one: 'Subgroup',
        registers_other: 'Subgroups'
      },
      reports: {
        search: 'Search connection reports',
        monthly: 'Monthly',
        weekly: 'Weekly',
        reportWeeklyName: 'Report {{month}} week {{week}}',
        reportName: 'SkyAlert-Report-Group-{{group}}-{{date}}',
        selectType: 'Select the type of report:',
        selectDay: 'Select the day of the report',
        selectRange: 'Select the range of days, start and end',
        question: 'Do you want to download the report for {{date}}?',
        labels: {
          typeReport: 'Type report:',
          events: 'Events',
          damage: 'Damages'
        },
        placeholders: {
          year: 'Year',
          month: 'Month'
        },
        damage: {
          title: 'Damage report - {{date}}',
          totals: {
            registered: 'Registers',
            received: 'Received',
            noDamage: 'No Damage',
            damage: 'Damage',
            merchandise: 'Merchandise',
            structure: 'Structure',
            staff: 'Staff'
          }
        }
      }
    },
    infoCardConnection: {
      alive: 'Alive Since',
      connection: 'Connection Status',
      status: 'Connection',
      latency: 'Latency',
      output: 'Output Sound',
      validFrom: 'Valid From',
      validUntil: 'Valid until',
      volume: 'Volume',
      version: 'Version',
      license: 'License',
      name: 'Name',
      uuid: 'UUID',
      created: 'Created',
      type: 'Type',
      model: 'Model',
      state: 'State',
      system: 'System',
      street: 'Street',
      streetNumber: 'Street Num.',
      apartmentNumber: 'Apartment Num.',
      locality: 'Locality',
      sublocality: 'SubLocality',
      postalCode: 'Postal Code'
    },
    intensities: {
      unknown: 'Unknown',
      mx: {
        0: '',
        1: 'WEAK',
        2: 'LIGHT',
        3: 'MODERATE',
        4: 'STRONG',
        5: 'VIOLENT',
        6: 'SEVERE'
      }
    },
    layer: {
      header: {
        search: 'Search...',
        records_one: '{{count}} Record',
        records_other: '{{count}} Records'
      }
    },
    licenses: {
      showMap: 'Show Map',
      state: {
        available: 'To Redeem',
        assigned: 'Redeemed',
        revoked: 'Suspended',
        expired: 'Expired',
        shared: 'Assigned'
      },
      detail: {
        details: 'Details',
        authorizations: 'Authorizations',
        devices: 'Associated devices',
        info: {
          targetReceptor: 'Type',
          id: 'Code',
          status: 'Status',
          validUntil: 'Valid until',
          createdAt: 'Valid from',
          sharedWithEmail: 'Share with',
          sharedAt: 'Date assigned'
        }
      }
    },
    login: {
      titles: {
        0: 'Welcome to Safety Admin!',
        1: 'Forgot your password?',
        2: 'Create a new password',
        3: 'Register your verification code'
      },
      subtitle: 'Manage your devices, monitor your groups and more.',
      fields: {
        labels: {
          email: 'Email Address',
          pass: 'Password',
          changeNewPass: 'New password',
          changeConfirmNewPass: 'Confirm new password',
          forgotEmail: 'Email Address',
          forgotCode: 'Verification code',
          forgotNewPass: 'New password',
          forgotConfirmNewPass: 'Confirm new password'
        },
        placeholders: {
          email: 'Enter your email here',
          pass: 'Minimum 6 characters',
          changeNewPass: 'Minimum 6 characters',
          changeConfirmNewPass: 'Minimum 6 characters',
          forgotEmail: 'Registered email address',
          forgotCode: '',
          forgotNewPass: 'Minimum 6 characters',
          forgotConfirmNewPass: 'Minimum 6 characters'
        }
      },
      passwordRequirements: {
        header: 'Your password must include:',
        list: [
          'A minimum of 6 characters',
          `A special character e.g. (^ $ * .[]{}()?"!@#%&/\\,><':;|_~)`,
          'At least one number',
          'At least one uppercase letter',
          'At least one lowercase letter'
        ]
      },
      expiration: 'Your session has expired'
    },
    maps: {
      notAvailable: 'Location not available',
      notCoverage:
        'The address you entered is not covered. Please choose a valid address.',
      error: 'Error trying to load map',
      title: 'Location'
    },
    menu: {
      users: 'Users',
      groups: 'Groups',
      clients: 'Clients',
      dashboard: 'Dashboard',
      monitorThings: 'Monitor',
      damageReport: 'Report status',
      subscriptions: 'Subscriptions',
      alertsCustom: 'Alerts Custom',
      tickets: 'Tickets',
      support: 'Support',
      things: {
        label: 'Devices',
        gadget: 'Gadget',
        desk: 'Desk',
        saas: 'SaaS',
        epicenter: 'Epicenter'
      },
      licenses: {
        label: 'Licenses',
        available: 'To Redeem',
        assigned: 'Redeemed',
        revoked: 'Suspended',
        expired: 'Expired'
      }
    },
    messages: {
      incoming: 'Coming soon...',
      clients: {
        title: 'Find a customer to get started',
        subtitle: 'Select category, enter the value and click on search'
      },
      clientNotFound: {
        title: 'Customer not found',
        subtitle: 'Try searching with another category or value'
      },
      customAlerts: {
        title: 'You do not have customized alerts yet',
        subtitle: 'Start creating a new alert for your device'
      },
      customCommands: {
        title: "You don't have customized audios yet"
      },
      feedAck: {
        title: 'Everything is in order!',
        subtitle: 'You are up to date with the records of your devices.'
      },
      groups: {
        title: 'No groups yet',
        subtitle: 'Start by creating a new group with the name of your choice.'
      },
      groupsUser: {
        title: 'No group assigned yet',
        subtitle: 'Ask an administrator to assign you one.'
      },
      groupsDetail: {
        tracking: {
          title: 'Everything in order!',
          subtitle: 'You are up to date with your device logs.'
        },
        control: {
          title: 'Everything in order!',
          subtitle: 'At the moment you do not have any scheduled events.'
        },
        devices: {
          title: 'No records!',
          subtitle: 'At the moment there are no devices available.'
        },
        users: {
          title: 'No users available'
        },
        subgroups: {
          title: 'No records!',
          subtitle: 'At the moment there are no subgroups available.'
        },
        reports: {
          initial: {
            title: 'Looking for a report?',
            subtitle: 'Select time period (Today, Day, Range)'
          },
          notFound: {
            title: 'The report you are looking for was not found',
            subtitle: 'Try searching with another month and/or year'
          },
          invalidDate: {
            title: 'Month and/or year are not valid',
            subtitle: 'Please enter new data'
          }
        }
      },
      licenses: {
        title: 'You currently have no licenses',
        subtitle: 'Contact us and get your licenses.'
      },
      licensesClient: {
        title: 'You do not have a client selected yet',
        subtitle: 'Go to client section'
      },
      licensesDetail: {
        status: {
          title: 'Apparently you have no records',
          subtitle: 'Try selecting another option.'
        },
        authorizations: {
          title: 'No authorizations yet'
        },
        things: {
          title: 'No associated devices'
        }
      },
      monitorThings: {
        title: 'No connection logs',
        subtitle: ''
      },
      permission: {
        title: "You don't have permissions for this section"
      },
      things: {
        types: {
          title: 'Apparently you have no records',
          subtitle: 'Try selecting another option'
        },
        report: {
          title: 'No records yet',
          subtitle: 'Your device records are not available at the moment.'
        },
        groups: 'No groups assigned'
      },
      search: {
        title: `No results found for "{{results}}"`,
        subtitle: 'Try searching with other words'
      },
      users: {
        title: 'No users yet',
        subtitle: 'Start by creating a new user'
      },
      updateChangeLog: {
        title: 'Oh Nooo ...',
        subtitle: 'Sorry, something went wrong'
      }
    },
    modals: {
      titles: {
        info: 'Information',
        error: 'Error',
        warning: 'Warning!',
        add: {
          user: 'Add user',
          thing: 'Add device'
        },
        assign: {
          license: 'Assign license',
          licensesDesk: 'Assign Desk licenses',
          steps: {
            one: 'Upload user data',
            two: 'Submit'
          },
          errors: {
            data: 'The following data is invalid:',
            noData: 'The file had no data',
            invalidKeys: {
              header: 'Columns not allowed:',
              subheader:
                'Be sure to check that there are no spaces in the column names.'
            },
            missingKeys:
              'You are missing the following columns that are required: ',
            length:
              'Does not have enough licenses available, currently only has {{count}}'
          },
          reference:
            'The file should be an Excel file with three columns of data (Email, Name,  LastName), as shown in the following image: ',
          note: 'Note: The number of data you enter must not exceed the number of licenses you have available',
          reuploadFile: 'Reupload file',
          sending: 'Sending',
          success: 'Licenses sent'
        },
        create: {
          user: 'Create User',
          group: 'Create Group',
          subgroup: 'Create Subgroup',
          customAlert: 'Create Custom Alert',
          config: 'Settings',
          clients: 'Add a new client',
          thingsReport: 'Connections report'
        },
        edit: {
          thing: 'Edit name of device',
          user: 'Edit User',
          clients: 'Edit Client',
          thingLocation: 'Edit device location {{id}}',
          license: 'Edit license'
        },
        update: {
          config: 'Change Settings',
          changeLog: 'Update details'
        },
        control: {
          remember: `Don't forget!`,
          initial: 'Send Command "{{command}}"',
          scheduled: 'Schedule Command "{{command}}"',
          customAlert: '{{type}} Custom Alerts',
          customAudio: '{{type}} Custom Sound',
          confirm: 'Warning!',
          result: '',
          output: '',
          tipList: [
            'It is necessary to schedule the selected time at least 2 minutes in advance'
          ],
          form: {
            title: 'Select the date to send the command',
            date: 'Date',
            time: 'Time',
            invalid: 'Choose a schedule greater than the current one'
          }
        },
        scheduled: {
          now: 'Send Now',
          schedule: 'Schedule',
          once: 'Schedule: Once',
          daily: 'Schedule: Daily',
          weekly: 'Schedule: Weekly',
          monthly: 'Schedule: Monthly'
        }
      },
      labels: {
        assign: {
          name: 'Name(s)',
          lastName: 'Last Name',
          email: 'Mail'
        },
        group: {
          name: 'Name'
        },
        clients: {
          name: 'Name',
          email: 'Mail',
          commercialId: 'Commercial ID'
        },
        config: {
          anyIntensity: 'For any intensity',
          intensityTrigger:
            'This equipment is configured to alert earthquakes with intensity {{intensity}} or higher.',
          by: 'Por',
          drill: 'This equipment is configured to receive simulations.',
          drill_1: `This equipment is configured to DO receive SkyAlert drills.`,
          drill_0: `This equipment is configured NOT to receive SkyAlert drills.`,
          resetConfig: 'Recommended Configuration',
          ignoreDrills: 'Ignore Drills',
          startIntensity: 'Starting with intensity {{intensity}}',
          timezone: 'Time Zone',
          volume: 'Volume',
          outputs: {
            none: 'None',
            sound: 'Sound',
            relay1: 'Relay 1',
            relay2: 'Relay 2',
            all: 'Relay 1 & 2'
          },
          behaviors: {
            default: 'Use configuration for default',
            on: 'Stay ON',
            onOff: 'ON, then OFF',
            offOn: 'OFF, then ON',
            off: 'Stay OFF',
            inline: {
              onOff: 'ON',
              offOn: 'OFF'
            },
            append: {
              onOff: ', then OFF',
              offOn: ', then OFF'
            }
          }
        },
        customAlert: {
          name: 'Name'
        },
        license: {
          status: 'Status license'
        },
        users: {
          name: 'Name',
          email: 'Mail',
          permissions: 'Permissions'
        },
        thingLocation: {
          view: {
            licensee:
              'Drag the marker to the desired location or enter the coordinates.',
            user: 'Drag the marker to the desired location.'
          },
          coords: {
            lat: 'Latitude',
            lng: 'Longitude'
          },
          errorField: 'Field required',
          errorZeroCoord: '"0", is not a valid coordinate'
        }
      },
      placeholders: {
        assign: {
          name: 'Ex. John',
          lastName: 'Ex. Smith',
          email: 'Ex. john@mail.com'
        },
        clients: {
          name: 'Full name',
          email: 'Ex. john@mail.com',
          commercialId: 'ID Commercial'
        },
        config: {
          timezone: 'Select a time zone',
          earthquake: 'Select the minimum intensity',
          behavior: 'Select the expected behavior'
        },
        customAlert: {
          name: 'Name of custom alert',
          dropzone: {
            drag: 'Drag the file here',
            divider: 'or',
            select: 'Select file'
          }
        },
        users: {
          name: 'Full Name',
          email: 'Mail for new user',
          permissions: 'Permissions for new user',
          selectPermission: 'Select a permission'
        },
        mapLocationThing: {
          title: 'Map not available',
          subtitle: 'Enter valid coordinates'
        }
      },
      paragraphs: {
        confirm: {
          delete: 'Are you sure you want to remove "{{element}}"?',
          deleteUser: 'Are you sure you want to delete user "{{element}}"?',
          observation:
            'Note: By deleting it, the user will no longer have access to this group.',
          sendCommand: {
            now: {
              title: 'Are you sure to send the command "{{command}}"?',
              subtitle: {
                groups:
                  '<p className="subtitle-principal">The command will be sent to the group **{{name}}**</p> **Note:** Remember that your devices must be connected for the command to reach you',
                things:
                  '<p className="subtitle-principal">The command will be sent to the device **{{name}}** with the ID **{{id}}**</p> **Note:** Remember that your device must be connected for the command to reach you'
              }
            },
            scheduled: {
              title: 'Are you sure to send the command "{{command}}"?',
              subtitle: {
                groups:
                  '<p className="subtitle-principal">The command will be sent to the group **{{name}}** </p> Remember that the command will be sent on **{{date}}** at **{{time}}** hrs',
                things:
                  '<p className="subtitle-principal">The command will be sent to the device **{{name}}** with the ID **{{id}}** </p> Remember that the command will be sent on the day **{{date}}** at **{{time}}** hrs'
              }
            },
            title: 'Are you sure to send this command?',
            subtitle:
              'Remember that your devices must be connected for the command to reach you'
          },
          commandsThings: {
            title: 'Are you sure to send the command for "{{commandName}}"?',
            subtitle: {
              drill:
                'This command will be sent to **ALL** devices you currently have configured, which includes Desk, Gadget, and Epicenter. <br><br> **Important:** Remember that only devices that appear in the “connected” state will be able to receive the drill.',
              stopDrill:
                'Sending this command will “stop” the drill on **ALL** devices you have configured, which includes Desk, Gadget and Epicenter. <br><br> **Important:** Remember that only devices that appear in the “connected” state can the drill be stopped.'
            }
          }
        },
        stepsAssign: [
          'License Code, download link and user manual for SkyAlert Desk will be emailed to you.',
          'Verify that the email corresponds to the "Desk user" according to the location configured for the license.'
        ]
      }
    },
    monitor: {
      panes: {
        all: 'All',
        epicenter: 'Epicenter',
        desk: 'Desk',
        gadget: 'Gadget'
      },
      header: {
        reloadThing: 'Refresh devices',
        downloadReport: 'Download report',
        important: 'The connection status is updated every **5 minutes**',
        select: {
          today: 'Today',
          yesterday: 'Yesterday'
        },
        records: {
          total_one: '{{count}} Record',
          total_other: '{{count}} Records',
          connected_one: '{{count}} Connected',
          connected_other: '{{count}} Connected',
          disconnected_one: '{{count}} Offline',
          disconnected_other: '{{count}} Offline',
          ignored_one: '{{count}} Ignore',
          ignored_other: '{{count}} Ignored'
        },
        settingsButton: 'Settings'
      },
      card: {
        idThing: '**UUID**: {{id}}',
        thingType: '**Device Type:**: {{thingType}}',
        idCommercial: '**Commercial ID**: {{customer}}',
        client: '**Client**: '
      },
      detail: {
        title: 'Device Details {{thingName}}',
        history: 'Connection history',
        fromDate: '**From**: {{from}}',
        toDate: '**Until**: {{to}}',
        error: 'Could not get logs from this device',
        noConnections: {
          title: 'This device has no logs',
          subtitle:
            'Make sure the device is connected so it starts reporting its connection status'
        },
        ignored: {
          modeActive: 'Device **ignored**',
          modeInactive: 'Device **NOT ignored**',
          title: 'Attention!',
          confirm: 'Are you sure you want to ignore this device?',
          subtitle:
            '<p className="text-ignored">To confirm this action, we recommend verifying that the data is correct.</p><ul className="list-command-schedule"><li><strong>Device ID</strong>: {{id}}</li><li><strong>Alias/Name</strong>: {{alias}}</li><li><strong>Customer</strong>: {{customerName}}</li></ul> <strong>Note</strong>: Remember, you can enable or disable this device.'
        },
        list: {
          skyalertId: '**SkyAlert ID**: {{skyalertId}}',
          client: '**Client**: {{client}}',
          clientId: '**Client ID**: {{clientId}}',
          receptor: '**Type**: {{type}}',
          name: '**Device Name**: {{name}}',
          id: '**Device ID**: <span className="id-thing">{{id}}</span>',
          version: '**Version**: {{version}}'
        },
        selectorDays: {
          now: 'Today',
          threeDays: 'Three days ago',
          sevenDays: 'Week ago'
        },
        startHistory: '**Start of history**: ({{connectionStatus}})'
      },
      modalSettings: {
        title: 'Monitor Settings',
        hideDesk: 'Hide Desk',
        hideSkyAlert: 'Hide SkyAlert Accounts'
      }
    },
    newVersion: {
      title: 'Upgrade available!',
      content: 'Click the "OK" button to reload the page.'
    },
    notFound: {
      private: {
        title: 'Page not found...',
        subHeader: 'Try returning to the home page to continue your navigation.'
      },
      public: {
        title: 'We are sorry...',
        subHeader: 'We could not find the page you are looking for.'
      }
    },
    onBoarding: {
      title: 'Welcome to Safety Admin',
      step: {
        one: {
          section: 'About SkyAlert',
          title: 'Learn more about SkyAlert',
          content:
            'At SkyAlert we specialize in creating technological solutions for seismic warning and other natural hazards. Our innovative approach enables your business to make informed decisions, providing the time and information needed to effectively reduce risk. In addition, we have the only private alert system backed by a legal basis that allows us to operate without limitations throughout Mexico.'
        },
        two: {
          section: 'How does it work?',
          title: 'How does Safety Admin work?',
          content:
            'With Safety Admin, you can efficiently manage licenses, monitor the connectivity of your devices in real time, organize them into groups according to your needs and send personalized commands, such as drills, alerts, reboots, sound tests, silent notifications and updates in a timely manner. remote. In addition, you will have full control over the administration of users who require access to the platform.'
        },
        three: {
          section: 'Assign your licenses',
          title: 'Assign your Desk licenses',
          content:
            'Enjoy the seismic protection that SkyAlert offers your company. Each license grants your collaborators access to SkyAlert Desk, the reliable and timely seismic alert, designed to provide peace of mind and effective responses in risk situations. You can then assign the available licenses to your team.'
        },
        four: {
          section: 'Finish',
          title: `You're done!`,
          content:
            'Now you will be able to take full advantage of SkyAlert solutions and use our Safety Admin platform. If you have any questions or need additional assistance, do not hesitate to contact your sales advisor. You can repeat this process at any time from the drop-down menu in the top right corner.'
        },
        noLicenses: {
          title:
            'You have no unassigned Desk licenses associated with your account',
          content:
            'Unfortunately, we have not found any unassigned licenses linked to your account. If you are sure that you should have registered licenses or need assistance, do not hesitate to contact your sales advisor for personalized assistance. You can also contact us via email or call us at our customer service number.',
          note: 'You can repeat this process at any time from the drop-down menu in the top right corner.'
        },
        finallyErrorAssign: {
          title: 'A problem has occurred',
          content:
            'Sorry, it was not possible to assign your Desk licenses due to an error. Please contact your sales advisor for personalized assistance. You can also contact us via email or call us at customer service to help you resolve this issue.',
          note: 'You can repeat this process at any time from the drop-down menu in the top right corner.'
        },
        selectAssign: {
          title: 'Select an option:',
          assign: 'Assign licenses now',
          later: 'Assign at another time'
        },
        assignProcess: {
          title: 'Assign your Desk licenses',
          content:
            'In this section, you can assign up to three licenses that are associated with your account. Complete the required fields with the name, surname and email for the person who will receive each license.<br><br><strong>Note:</strong> Verify that the email corresponds to the user to whom you wish to assign the license. We will email the License Code, download link and user manual for SkyAlert Desk.',
          addMore: 'Show more licenses',
          anotherTime: 'Assign at another time',
          discard: 'Discard'
        },
        cancelAssign: {
          content:
            'Now you will be able to take full advantage of SkyAlert solutions and use our Safety Admin platform. If you have any questions or need additional assistance, do not hesitate to contact your sales advisor. You can assign pending licenses at any time from the drop-down menu in the upper right corner.'
        }
      },
      form: {
        firstName: 'Name(s)',
        lastName: 'LastName(s)',
        email: 'Mail'
      },
      assignText: {
        success: 'You have successfully assigned your licenses',
        error: {
          text: 'A problem has occurred',
          button: 'Try again',
          status: '(Error: {{status}})'
        }
      },
      contactUs: {
        whatsapp: {
          label: 'Phone',
          value: '55 7902 0029'
        },
        email: {
          label: 'Email',
          value: 'soporte-desk@skyalert.mx'
        }
      },
      message: {
        licensee: `As a Licensee, you will not be able to assign licenses from this section. You must select a Client and go to the 'Licenses' tab to assign them, either in a group or individually.`,
        user: 'Unfortunately, you do not have the required permissions to view this section. Only your administrator has the ability to assign licenses.'
      }
    },
    permissions: {
      user: {
        admin: 'Administrator',
        write: 'Supervisor',
        read: 'Reader'
      },
      account: {
        owner: 'Owner',
        admin: 'Admin',
        user: 'User',
        licensee: 'licensee'
      }
    },
    receptors: {
      'gadget-integrated': 'Gadget Integrated',
      'gadget-mini': 'Gadget',
      gadget: 'Gadget',
      desk: 'Desk',
      'desk-plus': 'Desk+',
      saas: 'SaaS',
      epicenter: 'Epicenter',
      'epicenter-pro': 'Epicenter Pro'
    },
    requests: {
      statusInfo: {
        '500': 'The email you entered is already linked to another user.'
      }
    },
    ribbon: {
      title: 'You are entering as ',
      button: 'Deselect'
    },
    settings: {
      title: 'Settings',
      darkMode: 'Dark mode',
      language: 'Language',
      locale: {
        spanish: 'Spanish',
        english: 'English'
      }
    },
    sections: {
      groups: 'Groups',
      yourGroups: 'Your groups',
      customAlerts: 'Custom Alerts',
      monitorThings: 'Connectivity monitoring',
      dashboard: 'Dashboard',
      licenses: 'Licenses',
      support: 'Support',
      things: 'Devices',
      users: 'Users'
    },
    success: {
      login: {
        resetPass: 'Password reset!',
        send: {
          header: 'Code sent',
          content: 'We send a code to your email, check your inbox.'
        },
        sendTemporaryPassword: {
          header: 'Temporary password sent',
          content: 'We send a temporary password to your email.'
        }
      },
      requests: {
        groups: {
          create: 'The group "{{name}}" has been created',
          createThing: 'Device was successfully added to the group',
          createUser: 'User has been successfully added to the group',
          delete: 'Group "{{name}}" has been successfully deleted',
          deleteUser: 'User has been successfully deleted from group'
        },
        subgroups: {
          create: 'Subgroup has been successfully created'
        },
        licenses: {
          assign: 'License has been successfully assigned',
          edit: 'The license has been successfully edited {{id}}'
        },
        things: {
          update: 'Device configuration was successfully updated',
          edit: 'Successfully edited name to {{name}}'
        },
        commands: {
          send: 'The command has been sent successfully',
          delete: 'The command has been successfully deleted'
        },
        user: {
          create: 'The user was successfully created',
          edit: `User "{{name}}" was edited successfully`,
          delete: `User "{{name}}" was successfully deleted`,
          resendTemporaryPassword:
            'A temporary password email has been sent to "{{name}}"'
        },
        clients: {
          create: 'The client {{name}} was successfully created',
          edit: 'Client "{{name}}" was edited successfully'
        }
      }
    },
    support: {
      contactUs: {
        title: 'Contact Us',
        phone: {
          label: 'Phone',
          content: '(55) 7902 0029'
        },
        mail: {
          label: 'Mail',
          content: 'soporte-desk@skyalert.mx'
        }
      },
      faq: {
        title: 'Questions about our devices',
        desk: {
          label: 'Desk',
          content: [
            {
              header: "I can't activate the key code",
              description:
                'On the home screen, verify that the license is the same as the one provided by SkyAlert IoT mail, also verify that you have an internet connection.'
            },
            {
              header: 'How can I be forewarned of earthquakes?',
              description:
                'You can enable the auto-start option in your application or we also recommend keeping the application in the background to receive our alerts.'
            },
            {
              header: 'How can I upgrade to the latest version?',
              description:
                'When you start the application it updates automatically or if you prefer you can update manually in the taskbar depending on your operating system.'
            }
          ]
        },
        gadget: {
          label: 'Gadget',
          content: [
            {
              header: 'What is the difference between epicenter and gadget?',
              description:
                'Epicenter uses a different type of operating system and is smaller than epicenter.'
            },
            {
              header: 'Where are the horns located?',
              description:
                'Gadget no longer has speakers included since its size is smaller than epicenter, so it uses 3.5" audio output technology.'
            },
            {
              header: 'What is the longest alert time?',
              description:
                'Receive alerts up to 120 seconds before an earthquake according to the epicenter.'
            }
          ]
        },
        epicenter: {
          label: 'Epicenter',
          content: [
            {
              header: 'What can epicenter do at the moment of an earthquake?',
              description:
                'Epicenter automates processes such as closing gas solenoid valves, shutting down production, opening emergency doors, etc.'
            },
            {
              header: 'What is the longest alert time?',
              description:
                'Receive alerts up to 120 seconds before an earthquake according to the epicenter.'
            },
            {
              header: 'Can I receive advice on the operation of my epicenter?',
              description:
                'Yes, at SkyAlert we give you the advice you need to be ready for the next earthquake.'
            }
          ]
        }
      },
      general: {
        title: 'General questions',
        licenses: {
          label: 'Licenses',
          resume:
            'Allows you to view the licenses that are to be redeemed (licenses to be used) and those that have already been redeemed (licenses that have already been used).'
        },
        things: {
          label: 'Devices',
          resume:
            'Allows to visualize information about the four alerting devices, shows the list of devices available to the user.'
        },
        groups: {
          label: 'Groups',
          resume:
            "It allows to visualize and create groups according to the user's needs, in which different devices can be linked in order to be able to monitor them."
        },
        users: {
          label: 'Users',
          resume:
            'It allows to visualize and create users so that they can have access to the platform.'
        }
      }
    },
    tableList: {
      header: {
        command: 'Command',
        connections: 'Connections',
        eventConnection: 'Event connections',
        date: 'Date',
        dateTime: 'Date/Time',
        dateAndTime: 'Date/Time',
        device: 'Device',
        devices: 'Devices',
        event: 'Event',
        email: 'Email',
        earthquakeDetail: 'Earthquake detail',
        description: 'Description',
        disconnections: 'Disconnections',
        name: 'Name',
        nameThing: 'Name thing',
        nameLicense: 'Name(s)',
        nameThings: 'Name/Alias',
        nameClient: 'Client name',
        lastName: 'Last name',
        meta: 'State',
        skyAlertId: 'SkyAlert ID',
        createdAt: 'Date Create',
        user: 'User',
        users: 'Users',
        state: 'State',
        subgroups: 'Subgroups',
        permission: 'Permission',
        type: 'Type',
        typeThing: 'Type thing',
        time: 'Time',
        uuid: 'UUID',
        status: 'Status',
        latency: 'Latency',
        options: 'Options',
        confirmation: 'Confirmation',
        permissions: 'Permission',
        report: 'Report',
        reportDetail: 'Detail',
        monitorDetail: 'Detail',
        origin: 'Place of origin',
        id: 'ID',
        intensity: 'Intensity',
        location: 'Location',
        locationThing: 'Location thing',
        license: 'License',
        auth: 'Authorization',
        attributes: 'Attributes',
        validUntil: 'Valid Until',
        frequency: 'Frequency',
        customerId: 'Customer ID',
        commercialId: 'Commercial ID',
        commandId: 'Command ID',
        dateScheduled: 'Date scheduled',
        dateReport: 'Date report',
        damage: 'Damage',
        version: 'Version'
      }
    },
    things: {
      devices_one: '{{count}} Device',
      devices_other: '{{count}} Devices',
      descriptions: {
        gadget: '',
        desk: '',
        epicenter: '',
        saas: ''
      },
      confirmEdit:
        'Are you sure you want to change the device name to **"{{newName}}"**>?',
      reports: {
        title: 'Connection report',
        subtitle:
          'This report will show the connections and disconnections of the receivers in the period you select (today, day and range). To generate it, select a type of receiver or all devices, then select the type of report you need, if the calendar is shown, select a date and press the download button, if you select the "Today" option, just download.',
        subtitlePart2:
          ' As a result, you will get a report with the receivers that have shown activity during that period.',
        reportNameThing: 'SkyAlert-Report-{{thing}}-{{date}}',
        reportName: 'SkyAlert-Report-Dispositivos-{{date}}',
        all: 'All devices',
        modal: {
          header: 'Select report type',
          headerDay: 'Selects the day of the report',
          headerRange: 'Select the range of days, start and end',
          question: 'Do you want to download the report for {{date}}?',
          select: {
            today: 'Today',
            day: 'Day',
            range: 'Range'
          }
        }
      },
      subsections: {
        tracking: 'Monitor',
        control: 'Control',
        details: 'Details',
        reports: 'Reports'
      },
      type: {
        connected_one: 'Connect',
        connected_other: 'Connected',
        disconnected_one: 'Disconnect',
        disconnected_other: 'Disconnected'
      },
      information: {
        receptor: 'Device',
        location: 'Location',
        history: 'History licenses'
      },
      commandThings: {
        header: 'Send commands to all devices',
        subheader: 'Select the command you want to send'
      }
    },
    time: {
      expiration: 'Expire',
      expiresOn: '(Expires on {{date}})',
      expiredAgo: '(Expired ago {{date}})',
      untilValid: '(Have {{date}})',
      seconds_one: '{{count}} Second',
      seconds_other: '{{count}} Seconds',
      days_one: '{{count}} Day',
      days_other: '{{count}} Days',
      months_one: '{{count}} Month',
      months_other: '{{count}} Months',
      years_one: '{{count}} Year',
      years_other: '{{count}} Years',
      latencies_one: '{{count}}ms',
      latencies_other: '{{count}}ms'
    },
    users: {
      status: {
        active: 'Active',
        inactive: 'Inactive'
      }
    },
    version: 'SkyAlert {{year}} | v{{version}}',
    viewType: {
      cards: {
        groups_zero: 'No Subgroups',
        groups_one: '{{count}} Subgroup',
        groups_other: '{{count}} Subgroups'
      }
    }
  }
};
