import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, Icon, Label, Segment } from 'semantic-ui-react';
import { useAuthState } from '../../../../contexts/auth';
import { useSettings } from '../../../../contexts/settings';
import { useAuthClient } from '../../../../hooks/useAuthClient';
import { useCommands } from '../../../../hooks/useCommands';
import useConnectionStatus from '../../../../hooks/useConnectionStatus';
import { useMaps } from '../../../../hooks/useMaps';
import { useThing } from '../../../../hooks/things/useThing';
import ReporterLogger from '../../../../controllers/ReporterLogger';
import { SkeletonBlock } from '../../../../components/Skeletons';
import FeedAck from '../../../../components/FeedAck';
import ConnectionStatus from '../../../../components/ConnectionStatus';
import ModalEditLocationThing from '../../../../components/Modals/EditLocationThing';
import Map from '../../../../components/Map';
import { DEFAULT_COORDS } from '../../../../config/constants';
import {
  addressLicensesFormant,
  convertLocationMap
} from '../../../../helpers';
import * as Interfaces from '../../../../interfaces';
import * as Types from '../../../../types';

import '../../../../scss/layout/ThingsDetail.scss';

interface IProps {
  id: string;
  groupId: string;
}

const SKELETON_STYLES = {
  maxWidth: '100%',
  height: 200
};

const OPTIONS_MAP_THING = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  draggable: false,
  zoomControl: true
};

const Monitor = ({ id, groupId }: IProps): JSX.Element => {
  const { type } = useParams<Types.TParamsThings>() as Types.TParamsThings;

  const { t } = useTranslation();
  const { customerId, isNotUserAdminLevel } = useAuthClient();
  const { isLicensee } = useAuthState();
  const { theme } = useSettings();
  const { isLoadingRequest, sendCreate } = useCommands({ groupId });
  const {
    dataThing: { listInfo },
    isLoadingRequest: isLoading,
    toggleReloadComponent
  } = useThing(id);

  const isDesk = ['desk', 'desk-plus'].includes(listInfo?.type || type);

  const { checkIfThingIsConnected, isLoadingRequestThingsConnected } =
    useConnectionStatus({
      query: { customerId, receptor: type },
      createIntervalRequest: !isDesk
    });

  const coords = listInfo?.location
    ? convertLocationMap(listInfo.location)
    : undefined;

  const { onLoad } = useMaps(coords);

  const [isOpenModalEdit, setIsOpenModalEdit] = useState<boolean>(false);

  const toggleOpenModalEdit = (): void => setIsOpenModalEdit((prev) => !prev);

  const LoggerInstance = ReporterLogger.getInstance();

  const handlerOnAcceptEditLocation = (data: Interfaces.ILocationThing) => {
    if (!data) {
      setIsOpenModalEdit(false);
      return;
    }
    try {
      if (
        coords &&
        (coords?.lat !== data.coords[1] || coords?.lng !== data.coords[0])
      ) {
        sendCreate({
          command: 'update-location',
          data
        });
      }
    } catch (e) {
      LoggerInstance.error('Failed to edit location - Licenses status', e);
    } finally {
      toggleOpenModalEdit();
    }

    setTimeout(() => {
      toggleReloadComponent();
    }, 5500);
  };

  const renderOptionChangeLocation = (): JSX.Element => {
    const textLocation = listInfo?.address
      ? addressLicensesFormant(listInfo?.address as Interfaces.IAddressLicense)
      : t('error.noDataYet');
    const isDeskValidations =
      isDesk && listInfo?.location.every((value) => value !== 0);
    const isGadgetOrEpicenter = [
      'gadget',
      'gadget-integrated',
      'gadget-mini',
      'epicenter',
      'epicenter-pro'
    ].includes(listInfo?.type || '');
    const isGadgetOrEpicenterValidations = isGadgetOrEpicenter && isLicensee;

    const showEditButton =
      (isGadgetOrEpicenterValidations || isDeskValidations) &&
      isNotUserAdminLevel;

    return (
      <Label attached='bottom' className='lbl-thing-location'>
        <Icon name='map marker alternate' />
        {textLocation}
        {showEditButton ? (
          <Button
            icon='pencil'
            size='tiny'
            style={{ marginLeft: '0.5em' }}
            circular
            onClick={toggleOpenModalEdit}
          />
        ) : null}
      </Label>
    );
  };

  const renderMapInfo = (): JSX.Element => {
    const thingConnectionType = isDesk
      ? (listInfo?.connectionStatus as Types.TConnectionStatus)
      : checkIfThingIsConnected(id);

    const thingConnection =
      !id || !listInfo?.connectionStatus ? 'notAvailable' : thingConnectionType;
    return (
      <Grid className='container-grid-map'>
        <Grid.Row stretched>
          <Grid.Column className='container-elements-map'>
            {isLoading.fetch ? (
              <SkeletonBlock styled={SKELETON_STYLES} />
            ) : (
              <Segment inverted={theme === 'dark'}>
                <ConnectionStatus
                  loading={isLoadingRequestThingsConnected}
                  connection={thingConnection}
                  isDisplayConnectionMessage={!isDesk}
                />
                <Map
                  coords={coords}
                  optionsMap={OPTIONS_MAP_THING}
                  onLoadMap={onLoad}
                  withMarker={{ position: coords || DEFAULT_COORDS }}
                />
                {listInfo?.address && renderOptionChangeLocation()}
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <Segment basic className='wrapper-monitor-group'>
      {renderMapInfo()}
      <Divider style={{ margin: '0.25em auto' }} hidden />
      <FeedAck section='thing' groupId={groupId} thingId={id} />
      <ModalEditLocationThing
        location={coords || null}
        address={listInfo?.address || null}
        isOpen={isOpenModalEdit}
        isLoading={isLoadingRequest.create}
        onAccept={handlerOnAcceptEditLocation}
        onCancel={toggleOpenModalEdit}
      />
    </Segment>
  );
};

export default Monitor;
