import api from '../../config/api';
import ApiRequest from './ApiRequest';
import * as Interfaces from '../../interfaces';

// TODO: CHANGE PROMISE<ANY>
class IoT {
  // ------------------------------------------------------------------
  // METHOD: GET
  // ------------------------------------------------------------------

  public static getClient(
    body: Interfaces.IBodyClientGet,
    query?: Interfaces.IQueryParamsRequestAny['query']
  ): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.getClient;
    const endpoint = { name: name(body.value as string), method };
    return ApiRequest.getInstance().buildAndFetchList(endpoint, query);
  }

  public static getUser(): Promise<Interfaces.IRequestUserIoT | any> {
    const { name, method } = api.skyAlert.endpoint.getUser;
    return ApiRequest.getInstance().buildAndFetch({ name, method });
  }

  public static getUsers(
    query?: Interfaces.IQueryParamsRequestAny['query']
  ): Promise<Interfaces.IRequestUsersIoT | any> {
    const { name, method } = api.skyAlert.endpoint.getUsers;
    return ApiRequest.getInstance().buildAndFetchList({ name, method }, query);
  }

  public static getScheduledCommandsInGroup(
    groupId: string,
    query?: Interfaces.IQueryParamsRequestAny['query']
  ): Promise<Interfaces.IRequestScheduledCommandsIoT> {
    const { name, method } = api.skyAlert.endpoint.getScheduledCommandsInGroup;
    return ApiRequest.getInstance().buildAndFetchList(
      {
        name: name(groupId),
        method
      },
      query
    );
  }

  public static getCustomer(): Promise<Interfaces.IRequestCustomerIoT> {
    const { name, method } = api.skyAlert.endpoint.getCustomer;
    return ApiRequest.getInstance().buildAndFetch({ name, method });
  }

  public static getGroups(
    query?: Interfaces.IQueryParamsRequestAny['query']
  ): Promise<Interfaces.IRequestGroupsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.getGroups;
    return ApiRequest.getInstance().buildAndFetchList({ name, method }, query);
  }

  public static getGroup(
    id: string
  ): Promise<Interfaces.IRequestGroupIoT[] | any> {
    const { name, method } = api.skyAlert.endpoint.getGroup;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(id),
      method
    });
  }

  public static getLastAcknowledgmentsFeed(
    id: string,
    query?: Interfaces.IQueryParamsRequestAny['query']
  ): Promise<Interfaces.IRequestFeedIoT> {
    const { name, method } = api.skyAlert.endpoint.getFeed;
    return ApiRequest.getInstance().buildAndFetchList(
      {
        name: name(id),
        method
      },
      query
    );
  }

  public static getThingsInGroup(
    id: string,
    query?: Interfaces.IQueryParamsRequestAny['query']
  ): Promise<Interfaces.IRequestGroupThingsIoT> {
    const { name, method } = api.skyAlert.endpoint.getThingsInGroup;
    return ApiRequest.getInstance().buildAndFetchList(
      {
        name: name(id),
        method
      },
      query
    );
  }

  public static getUsersInGroup(
    id: string,
    query?: Interfaces.IQueryParamsRequestAny['query']
  ): Promise<Interfaces.IRequestGroupUsersIoT> {
    const { name, method } = api.skyAlert.endpoint.getUsersInGroup;
    return ApiRequest.getInstance().buildAndFetchList(
      {
        name: name(id),
        method
      },
      query
    );
  }

  public static getSubgroupsInGroup(
    id: string
  ): Promise<Interfaces.IRequestGroupSubgroupsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.getSubgroupsInGroup;
    return ApiRequest.getInstance().buildAndFetch({ name: name(id), method });
  }

  public static getReportDamage(
    groupId: string,
    query?: Interfaces.IQueryParamsRequestAny['query']
  ): Promise<Interfaces.IRequestDamageReport> {
    const { name, method } = api.skyAlert.endpoint.getReportDamage;
    return ApiRequest.getInstance().buildAndFetchList(
      {
        name: name(groupId),
        method
      },
      query
    );
  }

  public static getCustomCommands(
    id: string
  ): Promise<Interfaces.IRequestCustomCommandsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.getCustomCommandsInGroup;
    return ApiRequest.getInstance().buildAndFetch({ name: name(id), method });
  }

  public static getThings(): Promise<Interfaces.IRequestThingsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.getThings;
    return ApiRequest.getInstance().buildAndFetch({ name, method });
  }

  public static getLicensesByStatus(
    query: Interfaces.IQueryLicensesByStatus
  ): Promise<Interfaces.IRequestLicensesTypesIoT | any> {
    const { name, method } = api.skyAlert.endpoint.getLicenses;
    return ApiRequest.getInstance().buildAndFetchList({ name, method }, query);
  }

  public static getClientLicensesByStatus(
    customerId: string,
    query: Interfaces.IQueryLicensesByStatus
  ): Promise<Interfaces.IRequestLicensesTypesIoT | any> {
    const { name, method } = api.skyAlert.endpoint.getClientLicenses;
    const endpoint = { name: name(customerId), method };
    return ApiRequest.getInstance().buildAndFetchList(endpoint, query);
  }

  public static getThing(id: string): Promise<Interfaces.IRequestThingById> {
    const { name, method } = api.skyAlert.endpoint.getThing;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(id),
      method
    });
  }

  public static getThingDetails(
    id: string
  ): Promise<Interfaces.IRequestThingDetails> {
    const { name, method } = api.skyAlert.endpoint.getThingDetails;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(id),
      method
    });
  }

  public static getCustomAlerts(): Promise<
    Interfaces.IRequestCustomAlertsIoT | any
  > {
    const { name, method } = api.skyAlert.endpoint.getCustomAlerts;
    return ApiRequest.getInstance().buildAndFetch({ name, method });
  }

  public static getThingsByType(
    query: Interfaces.IQueryThingsByReceptor
  ): Promise<Interfaces.IRequestThingsTypesIoT> {
    const { name, method } = api.skyAlert.endpoint.getThings;
    return ApiRequest.getInstance().buildAndFetchList({ name, method }, query);
  }

  public static getLicense(id: string): Promise<Interfaces.IRequestLicenseIoT> {
    const { name, method } = api.skyAlert.endpoint.getLicense;
    return ApiRequest.getInstance().buildAndFetchList({
      name: name(id),
      method
    });
  }

  public static getThingSettings(
    id: string
  ): Promise<Interfaces.IRequestThingSettingsIoT> {
    const { name, method } = api.skyAlert.endpoint.getThingSettings;
    const endpoint = { name: name(id), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint);
  }

  public static validateIsNewUser(userEmail: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.validateIsNewUser;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(userEmail),
      method
    });
  }

  public static getThingsConnected(
    query?: Interfaces.IQueryMonitorThings
  ): Promise<Interfaces.IRequestThingsConnected> {
    const { name, method } = api.skyAlert.endpoint.getThingsConnected;
    return ApiRequest.getInstance().buildAndFetchList({ name, method }, query);
  }

  public static getAllThings(
    query?: Interfaces.IQueryMonitorThings
  ): Promise<Interfaces.IRequestAllThingsIoT> {
    const { name, method } = api.skyAlert.endpoint.getThings;
    return ApiRequest.getInstance().buildAndFetchList({ name, method }, query);
  }

  public static getThingConnection(
    thingId: Interfaces.IThingItems['id'],
    query?: Interfaces.IQueryHistoryConnections
  ): Promise<Interfaces.IRequestThingConnections> {
    const { name, method } = api.skyAlert.endpoint.getThingConnections;
    return ApiRequest.getInstance().buildAndFetchList(
      {
        name: name(thingId),
        method
      },
      query
    );
  }

  public static getThingsReportLicensee(
    startDate: string,
    endDate: string,
    refresh: boolean
  ): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.getThingsReportLicensee;
    return ApiRequest.getInstance().buildAndFetchJustText({
      name: name(startDate, endDate, refresh),
      method
    });
  }

  public static getThingsReportByTypeLicensee(
    startDate: string,
    endDate: string,
    thingType: string,
    refresh: boolean
  ): Promise<any> {
    const { name, method } =
      api.skyAlert.endpoint.getThingsReportByTypeLicensee;
    return ApiRequest.getInstance().buildAndFetchJustText({
      name: name(startDate, endDate, thingType, refresh),
      method
    });
  }

  public static getGroupReport(
    startDate: string,
    endDate: string,
    groupId: string,
    customerId: string,
    refresh: boolean
  ): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.getGroupReport;
    return ApiRequest.getInstance().buildAndFetchJustText({
      name: name(startDate, endDate, groupId, customerId, refresh),
      method
    });
  }

  public static getThingsReport(
    startDate: string,
    endDate: string,
    customerId: string,
    refresh: boolean
  ): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.getThingsReport;
    return ApiRequest.getInstance().buildAndFetchJustText({
      name: name(startDate, endDate, customerId, refresh),
      method
    });
  }

  public static getThingsReportByType(
    startDate: string,
    endDate: string,
    thingType: string,
    customerId: string,
    refresh: boolean
  ): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.getThingsReportByType;
    return ApiRequest.getInstance().buildAndFetchJustText({
      name: name(startDate, endDate, thingType, customerId, refresh),
      method
    });
  }

  // ------------------------------------------------------------------
  // METHOD: POST
  // ------------------------------------------------------------------

  public static selectClient(customerId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.selectClient;
    const endpoint = { name: name(customerId), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint);
  }

  public static unselectClient(): Promise<any> {
    const endpoint = api.skyAlert.endpoint.unselectClient;
    return ApiRequest.getInstance().buildAndFetch(endpoint);
  }

  public static createClient(body: Interfaces.IBodyClientCreate): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.createClient;
    const endpoint = { name, method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static createGroup(
    customerId: string,
    nameGroup: string
  ): Promise<Interfaces.IRequestGroupsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.createGroupsInGroup;
    const endpoint = { name: name(customerId), method };
    const body = {
      name: nameGroup
    };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static createGroupThing(
    customerId: string,
    body: Interfaces.IBodyGroupThingCreate
  ): Promise<Interfaces.IRequestGroupsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.createThingsInGroup;
    const endpoint = { name: name(customerId), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static createGroupUser(
    groupId: string,
    body: Interfaces.IBodyGroupUserCreate
  ): Promise<Interfaces.IRequestGroupsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.createUsersInGroup;
    const endpoint = { name: name(groupId), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static createGroupSubgroup(
    groupId: string,
    body: Interfaces.IBodyGroupSubgroupCreate
  ): Promise<Interfaces.IRequestGroupsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.createGroupsInGroup;
    const endpoint = { name: name(groupId), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static createCommand(
    groupId: string,
    body: Interfaces.IBodyGroupCommandCreate
  ): Promise<Interfaces.IRequestGroupsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.createCommandsInGroup;
    const endpoint = { name: name(groupId), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static createAlert(
    body: Interfaces.IBodyCustomAlertCreate
  ): Promise<Interfaces.IRequestGroupsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.createCustomAlert;
    const endpoint = { name, method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static createUser(
    body: Interfaces.IBodyUserCreate
  ): Promise<Interfaces.IRequestGroupsIoT | any> {
    const { name, method } = api.skyAlert.endpoint.createUser;
    const endpoint = { name, method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static assignLicense(
    id: string,
    body: Interfaces.IBodyAssignLicense
  ): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.setUserLicense;
    const endpoint = { name: name(id), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static assignMultipleLicense(body: any): Promise<any> {
    const endpoint = api.skyAlert.endpoint.setMultipleLicense;
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static setThingSettings(
    id: string,
    body: Interfaces.IBodySettings
  ): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.setThingSettings;
    const endpoint = { name: name(id), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, body);
  }

  public static resendTemporaryPassword(id: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.resendTemporaryPassword;
    const endpoint = { name: name(id), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint);
  }

  // ------------------------------------------------------------------
  // METHOD: UPDATE
  // ------------------------------------------------------------------
  // TODO: IMPLEMENT UPDATES REQUESTS

  public static updateUser(body: Interfaces.IBodyUserEdit): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.updateUser;
    const endpoint = { name: name(body.id), method };
    const updateBody = { name: body.name, email: body.email };
    return ApiRequest.getInstance().buildAndFetch(endpoint, updateBody);
  }

  public static updateClient(body: Interfaces.IBodyClientEdit): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.updateClient;
    const { id, ...updateBody } = body;
    const endpoint = { name: name(id), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, updateBody);
  }

  public static updateThingName(
    body: Interfaces.IBodyThingNameEdit
  ): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.updateThingName;
    const { id, ...updateBody } = body;
    const endpoint = { name: name(id), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, updateBody);
  }

  public static updateLicenseStatus(
    body: Interfaces.IBodyLicenseEditStatus
  ): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.updateLicenseStatus;
    const { id, ...updateBody } = body;
    const endpoint = { name: name(id), method };
    return ApiRequest.getInstance().buildAndFetch(endpoint, updateBody);
  }

  // ------------------------------------------------------------------
  // METHOD: DELETE
  // ------------------------------------------------------------------
  // TODO: CHECK TYPE FOR DELETE REQUESTS

  public static deleteCustomer(customerId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.deleteCustomer;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(customerId),
      method
    });
  }

  public static deleteGroup(groupId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.deleteGroup;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(groupId),
      method
    });
  }

  public static deleteGroupUser(groupId: string, userId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.deleteGroupUser;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(groupId, userId),
      method
    });
  }

  public static deleteCommand(commandId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.deleteCommand;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(commandId),
      method
    });
  }

  public static deleteUser(userId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.deleteUsers;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(userId),
      method
    });
  }

  public static deleteLicense(licenseId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.deleteLicense;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(licenseId),
      method
    });
  }

  public static deleteThing(thingId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.deleteThing;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(thingId),
      method
    });
  }

  public static deleteScheluded(groupId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.deleteScheluded;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(groupId),
      method
    });
  }

  public static deleteLicenseThings(licenseId: string): Promise<any> {
    const { name, method } = api.skyAlert.endpoint.deleteLicenseThing;
    return ApiRequest.getInstance().buildAndFetch({
      name: name(licenseId),
      method
    });
  }
}

export default IoT;
