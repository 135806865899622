import React, { useContext, createContext, Dispatch, useReducer } from 'react';
import { RouteProps } from 'react-router-dom';
import { initialState, ClientReducer } from '../../reducers/client';

interface IPropsCtxRoutes {
  children: RouteProps['children'];
}

const ClientStateContext = createContext(initialState);
const ClientDispatchContext = createContext({} as Dispatch<any>);

export const useClientState = () => {
  const context = useContext(ClientStateContext);
  if (context === undefined) {
    throw new Error('useClientState must be used within a ClientProvider');
  }

  return context;
};

export const useClientDispatch = () => {
  const context = useContext(ClientDispatchContext);
  if (context === undefined) {
    throw new Error('useClientDispatch must be used within a ClientProvider');
  }

  return context;
};

export const ClientProvider = ({ children }: IPropsCtxRoutes) => {
  const [client, dispatch] = useReducer(ClientReducer, initialState);

  return (
    <ClientStateContext.Provider value={{ ...client }}>
      <ClientDispatchContext.Provider value={dispatch}>
        {children}
      </ClientDispatchContext.Provider>
    </ClientStateContext.Provider>
  );
};
