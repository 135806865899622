import React, { SyntheticEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../contexts/auth';
import { useNavState } from '../../contexts/navigation';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import ViewTable from '../ViewType/Table';
import ViewCards from '../ViewType/Cards';
import ViewList from '../ViewType/List';
import Empty from '../Empty';
import { SkeletonBlock, SkeletonListCard } from '../Skeletons';
import names from '../../config/names';
import { parseParamsGroupDetail } from '../../helpers';
import * as Constants from '../../config/constants';
import * as Types from '../../types';
import * as Interfaces from '../../interfaces';

import './Layer.scss';

export interface PropsComponentContainer {
  data: any[];
  isSearch?: boolean;
  loading: boolean;
  loadMore?: boolean;
  view: Types.TViewRecords;
  section: Types.TLayerTypes;
  onDelete?: () => void;
  onDeleteCommand?: (
    command: Interfaces.ICommandScheduledDeleteElement
  ) => void;
  onEdit?: (element?: any) => void;
  refetchLoadMore?: () => void;
  setModalParam?: (e: SyntheticEvent<HTMLElement>, data: any) => void;
}

interface IRedirect {
  id: string;
  name: string;
}

type THeadersTableSection = {
  [key in Types.TLayerTypes]: Interfaces.IHeaderListColumn[];
};

const CLIENT_LICENSES = Constants.HEADER_LIST_LICENSES_TYPES;
CLIENT_LICENSES.push(Constants.HEADER_ACTIONS);

// TODO: ADD MORE SECTIONS

const HEADERS_TABLE: THeadersTableSection = {
  DEFAULT: [],
  CLIENTS: [],
  GROUPS: Constants.HEADER_LIST_EVENTS_CONTROL_GROUPS,
  USERS: Constants.HEADER_LIST_EVENTS_CONTROL_USERS,
  THINGS: Constants.HEADER_LIST_DEVICES_THINGS,
  LICENSES: Constants.HEADER_LIST_LICENSES_TYPES,
  FEEDACK: Constants.HEADER_LIST_FEED_TRACKING,
  SUBGROUPS: Constants.HEADER_LIST_SUBGROUPS,
  'GROUPS-THINGS': Constants.HEADER_LIST_DEVICES_CONTROL,
  'GROUPS-USERS': Constants.HEADER_LIST_GROUPS_USERS,
  'GROUPS-FEEDACK': Constants.HEADER_LIST_GROUPS_FEED_TRACKING,
  'CONTROL-THINGS': Constants.HEADER_LIST_GROUPS_CONTROL_SCHEDULE_COMMANDS,
  'MONITOR-THINGS-ALL': Constants.HEADER_LIST_ALL_THINGS_CONNECTIONS,
  'MONITOR-THINGS': Constants.HEADER_LIST_THINGS_CONNECTIONS,
  'DAMAGE-REPORT': Constants.HEADER_LIST_DAMAGE_REPORT
};

const Container = ({
  data,
  view,
  section,
  loading,
  loadMore,
  isSearch,
  onDelete,
  onDeleteCommand,
  onEdit,
  refetchLoadMore,
  setModalParam
}: PropsComponentContainer): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { adminLevel } = useAuthState();
  const { updateGroupPermission } = useNavState();
  const {
    screen: { isMobile },
    isMobileDevice
  } = useBreakpoint();

  const [listData, setListData] = useState<any[]>([]);

  useEffect(() => {
    if (!loading) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (!loading && data) {
      const newData = data.map((dataItem) => ({
        isSelect: false,
        ...dataItem
      }));
      setListData(newData);
    }
  }, [loading, data]);

  const handlerClickRedirect = (
    e: SyntheticEvent<HTMLElement>,
    { id, name }: IRedirect,
    permission?: string
  ): void => {
    e.preventDefault();
    if (id) {
      const path = {
        pathname: names.paths.groups.detail,
        search: parseParamsGroupDetail(id, name)
      };
      navigate(path, { state: { groupName: name, groupId: id } });
    }
    if (permission) {
      updateGroupPermission(permission[0]);
    }
  };

  const renderState = (): JSX.Element => {
    if (!data.length) {
      const { texts, userText, icon } =
        Constants.PROPS_EMPTY_LAYER_CONTAINER[section || 'GROUPS'];
      const messageTexts = adminLevel !== 'user' ? texts : userText || texts;
      return (
        <Empty
          iconName={icon}
          texts={{
            title: t(messageTexts.title as any),
            subtitle: t(messageTexts.subtitle as any)
          }}
        />
      );
    }

    switch (view) {
      case 'TABLE':
        return (
          <ViewTable
            type={section}
            data={listData}
            loadMore={loadMore}
            isLoading={loading}
            isSearch={isSearch}
            refetchLoadMore={refetchLoadMore}
            headers={HEADERS_TABLE[section]}
            onClickTable={handlerClickRedirect}
            onDelete={onDelete}
            onDeleteCommand={onDeleteCommand}
            onEdit={onEdit}
            setModalParam={setModalParam}
          />
        );
      case 'CARDS':
        return (
          <ViewCards
            renderType={section}
            data={listData}
            loadMore={loadMore}
            refetchLoadMore={refetchLoadMore}
            onClickCard={handlerClickRedirect}
            onDelete={onDelete}
            onDeleteCommand={onDeleteCommand}
            onEdit={onEdit}
            setModalParam={setModalParam}
          />
        );
      case 'LIST':
        return (
          <ViewList
            type={section}
            data={listData}
            isSearch={isSearch}
            loadMore={loadMore}
            onDelete={onDelete}
            setModalParam={setModalParam}
            refetchLoadMore={refetchLoadMore}
          />
        );
      default:
        return <></>;
    }
  };

  if (loading) {
    if (isMobileDevice || isMobile) {
      return <SkeletonListCard itemsPerView={isMobileDevice ? 1 : 2} />;
    }

    return (
      <SkeletonBlock
        className='custom-placeholder'
        styled={{ maxWidth: '100%', height: '30em' }}
      />
    );
  }

  return <div className='wrapper-container'>{renderState()}</div>;
};

Container.defaultProps = {
  loadMore: false,
  isSearch: false,
  refetchLoadMore: () => null,
  onEdit: () => null,
  onDelete: () => null,
  onDeleteCommand: () => null,
  setModalParam: () => null
};

export default Container;
