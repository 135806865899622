import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Header,
  Icon,
  Segment,
  SemanticICONS,
  SemanticCOLORS
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavState } from '../../../../contexts/navigation';
import { SkeletonBlock } from '../../../../components/Skeletons';
import ModalEditThingName from '../../../../components/Modals/Edit/ThingName';
import names from '../../../../config/names';
import * as Helpers from '../../../../helpers';
import * as Interfaces from '../../../../interfaces';

export interface PropsThingInformation {
  information: Interfaces.ThingListInformation;
  isLoadingFetch: boolean;
  isLoadingEdit: boolean;
  isDarkMode: boolean;
  hasPermissionEdit: boolean;
  onSendEditThingName: (body: Interfaces.IBodyThingNameEdit) => Promise<void>;
}

interface IThingListInformation {
  id: string;
  keyThingInformation: keyof Interfaces.ThingListInformation;
  icon?: SemanticICONS;
  color?: SemanticCOLORS;
  text: string;
  type?: 'text' | 'date' | 'date-until' | 'receptor';
  renderType?: 'normal' | 'link' | 'editable';
  value?: string;
}

const INFO_RECEPTOR_THINGS: IThingListInformation[] = [
  {
    id: 'thingName',
    keyThingInformation: 'name',
    icon: 'tag',
    color: 'black',
    text: 'infoCardConnection.name',
    renderType: 'editable'
  },
  {
    id: 'thing-info-uuid',
    keyThingInformation: 'id',
    icon: 'barcode',
    color: 'black',
    text: 'infoCardConnection.uuid',
    type: 'text'
  },
  {
    id: 'thing-info-type',
    keyThingInformation: 'type',
    icon: 'tv',
    color: 'black',
    text: 'infoCardConnection.type',
    type: 'receptor'
  },
  {
    id: 'thing-info-license',
    keyThingInformation: 'license',
    icon: 'key',
    color: 'black',
    text: 'infoCardConnection.license',
    type: 'text',
    renderType: 'link'
  },
  {
    id: 'thing-info-created',
    keyThingInformation: 'validFrom',
    icon: 'clock',
    color: 'black',
    text: 'infoCardConnection.validFrom',
    type: 'date'
  },
  // TODO:  UNCOMMENT WHEN ODOO RETURNS VALID UNTIL
  // {
  //   id: 'thing-info-valid-until',
  //   keyThingInformation: 'validUntil',
  //   icon: 'calendar',
  //   color: 'black',
  //   text: 'infoCardConnection.validUntil',
  //   type: 'date-until'
  // },
  {
    id: 'thing-info-version',
    keyThingInformation: 'version',
    icon: 'code branch',
    color: 'black',
    text: 'infoCardConnection.version'
  }
];

const InformationThing: FC<PropsThingInformation> = ({
  information,
  isLoadingFetch,
  isLoadingEdit,
  isDarkMode,
  hasPermissionEdit,
  onSendEditThingName
}: PropsThingInformation): JSX.Element => {
  const { t } = useTranslation();

  const [isOpenEditThingName, setIsOpenEditThingName] =
    useState<boolean>(false);
  const { updateSection, updateSubsection } = useNavState();

  const handleOnClickRedirect = (path: string): void => {
    updateSection(names.paths.licenses.main);
    updateSubsection(path);
  };

  const handleOnClickEditThingName = (): void => {
    setIsOpenEditThingName(true);
  };

  const handleOnCloseModalEdit = (): void => {
    setIsOpenEditThingName((prev) => !prev);
  };

  const handleOnSaveModalEdit = async (newNameThing: string): Promise<void> => {
    await onSendEditThingName({
      id: information.id,
      thingName: newNameThing
    });
    setIsOpenEditThingName((prev) => !prev);
  };

  const thingInformation = Object.values(INFO_RECEPTOR_THINGS).map((item) => ({
    ...item,
    value: information[item.keyThingInformation]
  }));

  const renderValueInformation = ({
    renderType = 'normal',
    type = 'text',
    value
  }: IThingListInformation): JSX.Element => {
    if (renderType === 'link' && value && hasPermissionEdit) {
      return (
        <Link
          to={names.paths.licenses.detail(information.status, value)}
          onClick={() =>
            handleOnClickRedirect(`/licenses/${information.status}`)
          }
        >
          {value}
        </Link>
      );
    }

    const contentType = {
      text: t(value || ('error.notAvailable' as any)),
      date: Helpers.formatDate(value || '', 'D MMMM YYYY'),
      'date-until': Helpers.formatDateValidUntil(value || '', 'D MMMM YYYY'),
      receptor: t(`receptors.${[value]}` as any)
    };

    const isEditable = renderType === 'editable';
    if (isEditable) {
      return (
        <div className={`${isEditable ? 'container-wrapper-editable' : ''}`}>
          {isLoadingEdit ? (
            <SkeletonBlock styled={{ maxWidth: '100%', height: 40 }} />
          ) : (
            <Header
              as='h4'
              content={information.name}
              inverted={isDarkMode}
              className='info'
            />
          )}
          {!isLoadingEdit && hasPermissionEdit ? (
            <Button
              icon='pencil'
              size='tiny'
              style={{ marginLeft: '0.5em' }}
              onClick={handleOnClickEditThingName}
              circular
            />
          ) : null}
        </div>
      );
    }

    return (
      <Header
        as='h4'
        content={contentType[type]}
        inverted={isDarkMode}
        className='info'
      />
    );
  };

  if (isLoadingFetch) {
    return <SkeletonBlock styled={{ maxWidth: '100%', height: 180 }} />;
  }

  return (
    <>
      <Header as='h2' content={t('things.information.receptor')} />
      <Segment inverted={isDarkMode} className='wrapper-cardInfo'>
        <Grid columns={2} padded stackable>
          {Object.values(thingInformation).map((item, index) => (
            <Grid.Row verticalAlign='middle' key={`info-${index + 1}`}>
              <Grid.Column width={5}>
                <Icon
                  inverted={isDarkMode}
                  name={item.icon}
                  color={item.color || 'black'}
                  size='large'
                  style={{ marginRight: 5 }}
                />
                <strong>{t(item.text as any)}</strong>
              </Grid.Column>
              <Grid.Column width={11}>
                {renderValueInformation(item)}
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </Segment>
      <ModalEditThingName
        isOpen={isOpenEditThingName}
        thingName={information.name}
        isDisabled={!(information.name.length > 0)}
        isLoading={isLoadingEdit}
        onClose={handleOnCloseModalEdit}
        onSave={handleOnSaveModalEdit}
      />
    </>
  );
};

export default InformationThing;
