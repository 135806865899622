import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Icon, Label, SemanticICONS } from 'semantic-ui-react';

export interface PropsSteps {
  steps: string[];
  stepActive: number;
  assignStep: number;
  licensesTotal: number;
  isErrorAssign: boolean;
  havePermission: boolean;
}

interface IIconErrors {
  [key: string]: SemanticICONS;
}

const ICONS_ERRORS_ASSIGN: IIconErrors = {
  red: 'close',
  green: 'check',
  yellow: 'minus'
};

const Steps = ({
  steps,
  stepActive,
  assignStep,
  licensesTotal,
  isErrorAssign,
  havePermission
}: PropsSteps): JSX.Element => {
  const { t: translation } = useTranslation();

  const TOTAL_STEPS = steps.length;

  const renderStep = (step: number, text: string, id: string): JSX.Element => {
    const isAssignStep = assignStep === step && havePermission;
    const isPastAssign = stepActive > assignStep;
    const isActiveAssign = stepActive === assignStep;
    const colorErrorLicense =
      (isActiveAssign || isPastAssign) && isErrorAssign ? 'red' : 'grey';
    const colorNoLicense =
      (isActiveAssign || isPastAssign) && !licensesTotal
        ? 'yellow'
        : colorErrorLicense;
    const colorPastLicensesStep =
      isPastAssign && !isErrorAssign && !!licensesTotal
        ? 'green'
        : colorNoLicense;
    const colorLicensesStep =
      isActiveAssign && !isErrorAssign && !!licensesTotal
        ? 'orange'
        : colorPastLicensesStep;

    const colorPast = stepActive < step ? 'grey' : 'green';
    const colorActive = stepActive === step ? 'orange' : colorPast;

    return (
      <div key={`${id}`} className='container-step'>
        <Header as='h2' textAlign='center' className='step-item'>
          <Label
            circular
            color={`${!isAssignStep ? colorActive : colorLicensesStep}`}
            size='big'
          >
            {stepActive > step ? (
              <Icon
                name={`${
                  !isAssignStep
                    ? 'check'
                    : ICONS_ERRORS_ASSIGN[colorLicensesStep]
                }`}
              />
            ) : (
              step + 1
            )}
          </Label>
          <Header.Subheader className='text-step'>
            {translation(text as any)}
          </Header.Subheader>
        </Header>
        {TOTAL_STEPS - 1 !== step && (
          <hr className={`divider ${colorActive}`} />
        )}
      </div>
    );
  };

  return (
    <div className='wrapper-steps'>
      {steps.map((element, index) =>
        renderStep(index, element, `step-${index + 1}`)
      )}
    </div>
  );
};

export default Steps;
