import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  Segment,
  Table,
  Button,
  Icon,
  Header,
  SemanticICONS,
  SemanticCOLORS,
  Ref,
  Dropdown,
  Label,
  Popup
} from 'semantic-ui-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../contexts/auth';
import { useSettings } from '../../contexts/settings';
import { useNavState } from '../../contexts/navigation';
import useGeneralRequests from '../../hooks/useGeneralRequests';
import DamageStatus from '../DamageStatus';
import DropdownActions from '../DropdownActions';
import {
  formatDate,
  parseKeysTypes,
  parseStatus,
  getCounterThing,
  addressLicensesFormant,
  validateLocationZero,
  parseParamsThingDetail,
  parseParamsGroupDetail,
  parseDescriptionMonitorThing
} from '../../helpers';
import names from '../../config/names';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

export interface PropsComponentViewTable {
  type: Types.TLayerTypes;
  data: any[];
  loadMore?: boolean;
  isSearch?: boolean;
  isLoading?: boolean;
  headers: Interfaces.IHeaderListColumn[];
  onClickTable?: (
    e: SyntheticEvent<HTMLElement>,
    data: any,
    permission?: string
  ) => void;
  onDelete?: () => void;
  onDeleteCommand?: (
    command: Interfaces.ICommandScheduledDeleteElement
  ) => void;
  onEdit?: (element?: any) => void;
  refetchLoadMore?: () => void;
  setModalParam?: ((e: SyntheticEvent<HTMLElement>, data: any) => void) | null;
}

interface ISortIcons {
  [key: string]: SemanticICONS;
}

const EXCEPTION_TO_SORT = [
  'index',
  'actions',
  'client-name',
  'client-email',
  'customerId',
  'commercialId',
  'earthquakeDetail',
  'damage',
  'reportDetail',
  'connectionDetail'
];

const ICON_SORT: ISortIcons = {
  ascending: 'sort up',
  descending: 'sort down'
};

const BASIC_SEGMENTS = ['MONITOR-THINGS', 'MONITOR-THINGS-ALL'];

const ViewTable = ({
  type,
  data,
  headers,
  loadMore,
  isSearch,
  isLoading,
  onClickTable,
  onEdit,
  onDeleteCommand,
  onDelete,
  refetchLoadMore,
  setModalParam
}: PropsComponentViewTable): JSX.Element => {
  const rowRef = useRef<HTMLTableRowElement>(null);
  const lastRecordRef = useRef<number>(0);

  const navigate = useNavigate();
  const { groupPermission } = useNavState();
  const { status } =
    useParams<Types.TParamsLicenses>() as Types.TParamsLicenses;

  const { t } = useTranslation();
  const { adminLevel, email: userEmail } = useAuthState();
  const { theme } = useSettings();

  const { resendTemporaryPassword } = useGeneralRequests();

  const [direction, setDirection] = useState<Types.TSort>('ascending');
  const [listSort, setListSort] = useState<any[]>([]);
  const [columnToSort, setColumnToSort] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setListSort(data);
    if (!isLoading && data.length && type === 'FEEDACK') {
      lastRecordRef.current = data.length - Constants.ROWS_COVERING_HEADER;
      // rowRef?.current?.scrollIntoView();
    }
  }, [data, isLoading]);

  const handlerClickSort = (
    e: SyntheticEvent | SyntheticEvent<HTMLElement>,
    selected: string
  ): void => {
    e.preventDefault();
    if (!EXCEPTION_TO_SORT.includes(selected)) {
      const isDescending = direction === 'ascending';
      setColumnToSort(selected);
      setDirection(isDescending ? 'descending' : 'ascending');
      setListSort(
        orderBy(data, [`${selected}`], isDescending ? 'desc' : 'asc')
      );
      // This validation is for when the data is dynamic, for example updating the connection status
      if (
        setModalParam &&
        (type === 'THINGS' ||
          type === 'MONITOR-THINGS' ||
          type === 'MONITOR-THINGS-ALL')
      ) {
        const sortData: Interfaces.ISortDataTable = {
          column: selected,
          isDescending
        };
        setModalParam(e as SyntheticEvent<HTMLElement>, sortData);
      }
    }
  };

  const handlerClickRedirect = (
    e: SyntheticEvent,
    { name, id, type: typePath, groupId }: Interfaces.IRedirect
  ) => {
    e.preventDefault();
    if (id) {
      const path = {
        pathname: names.paths.things.detail(typePath),
        search: parseParamsThingDetail(id, name, groupId)
      };

      navigate(path, { state: { name, id, groupId } });
    }
  };

  const renderActions = ({ id, name, email }: any): JSX.Element => {
    if (!setModalParam) {
      return <></>;
    }
    const validateEmail = type !== 'USERS' ? true : email !== userEmail;
    return (
      <>
        {onDelete && validateEmail && (
          <Button
            circular
            icon='trash alternate'
            onClick={(e: SyntheticEvent<HTMLElement>) => {
              setModalParam(e, { id, name });
              onDelete();
            }}
          />
        )}
      </>
    );
  };

  const renderDropdownActions = ({
    id,
    name,
    email,
    isNewUser
  }: any): JSX.Element => {
    if (!setModalParam) {
      return <></>;
    }

    const validateEmail = type !== 'USERS' ? true : email !== userEmail;
    return (
      <Dropdown
        as={Button}
        className='actions-dropdown'
        circular
        icon='ellipsis vertical'
      >
        <Dropdown.Menu>
          {type === 'USERS' && isNewUser && (
            <Dropdown.Item
              icon='unlock'
              text={t('actions.resendTemporaryPassword')}
              onClick={() => {
                resendTemporaryPassword({ id: email, name });
              }}
            />
          )}
          {onDelete && validateEmail && (
            <Dropdown.Item
              icon='trash'
              text={t('actions.delete')}
              onClick={(e: SyntheticEvent<HTMLElement>) => {
                setModalParam(e, { id, name });
                onDelete();
              }}
            />
          )}
          {onEdit && type === 'USERS' && (
            <Dropdown.Item
              icon='pencil'
              text={t('actions.edit')}
              onClick={(e: SyntheticEvent<HTMLElement>) => {
                setModalParam(e, { id, name, email });
                onEdit();
              }}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderStatus = (
    sharedWithEmail?: string,
    color?: SemanticCOLORS
  ): JSX.Element => {
    const shared = sharedWithEmail && status === 'available';
    return (
      <Segment basic className='statusLicense'>
        <Icon color={shared ? `grey` : color} name='circle' size='small' />
        {t(`licenses.state.${shared ? 'shared' : status}` as any)}
      </Segment>
    );
  };

  const renderLocation = (
    location: string,
    address: Interfaces.IAddressLicense,
    isDisabled?: boolean
  ): JSX.Element => {
    const isLocationHaveZero = validateLocationZero(location);
    const textLocation = !isLocationHaveZero
      ? addressLicensesFormant(address)
      : t('error.noDataYet');
    const dataLocation: Interfaces.ICoordsAddress = {
      coords: location,
      address: textLocation
    };

    return (
      <Button
        className='button-location'
        onClick={(e) => {
          if (setModalParam) {
            setModalParam(e, dataLocation);
          }
        }}
        disabled={!location || isLocationHaveZero || !!isDisabled}
      >
        {location ? textLocation : t('error.notAvailable')}
      </Button>
    );
  };

  const renderLicenses = (): JSX.Element[] => {
    return listSort.map((element, index) => {
      const {
        id,
        name,
        location,
        type: licensesType,
        sharedWithEmail,
        address
      } = element;
      const { color } = Constants.LICENSES_TYPES_LIST[status];
      return (
        <Ref
          innerRef={index === lastRecordRef.current ? rowRef : null}
          key={`licenses-type-table-${index + 1}`}
        >
          <Table.Row>
            <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
            <Table.Cell>
              <Link to={names.paths.licenses.detail(status, id)}>{id}</Link>
            </Table.Cell>
            <Table.Cell>{renderStatus(sharedWithEmail, color)}</Table.Cell>
            <Table.Cell>{licensesType}</Table.Cell>
            <Table.Cell>{name || t('error.notAvailable')}</Table.Cell>
            <Table.Cell>{renderLocation(location, address)}</Table.Cell>
            {/* TODO: UNCOMMENT WHEN ODOO RETURNS VALID UNTIL */}
            {/* <Table.Cell>{dateUntil}</Table.Cell> */}
            {adminLevel === 'SkyAlertLicensee' && (
              <Table.Cell collapsing>
                <DropdownActions
                  type={type}
                  onEdit={onEdit}
                  element={element}
                />
              </Table.Cell>
            )}
          </Table.Row>
        </Ref>
      );
    });
  };

  const renderGroups = (): JSX.Element[] => {
    return listSort.map(
      ({ group, groupName, createdAt, subgroups, permissions }, index) => (
        <Ref
          innerRef={index === lastRecordRef.current ? rowRef : null}
          key={`group-table-${index + 1}`}
        >
          <Table.Row>
            <Table.Cell collapsing textAlign='center'>
              {index + 1}
            </Table.Cell>
            <Table.Cell>
              {onClickTable && (
                <Link
                  to={{
                    pathname: names.paths.groups.detail,
                    search: parseParamsGroupDetail(group, groupName)
                  }}
                  onClick={(e: SyntheticEvent<HTMLElement>) =>
                    onClickTable(
                      e,
                      { id: group, name: groupName },
                      permissions || ['admin']
                    )
                  }
                >
                  {groupName}
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>
              {formatDate(createdAt, Constants.FORMAT_DATE_CREATED)}
            </Table.Cell>
            <Table.Cell>
              {permissions ? (
                <Header as='h5' className='permission'>
                  <Icon
                    size='small'
                    name={Constants.GROUP_PERMISSIONS[permissions].icon}
                  />
                  <Header.Content>
                    {t(Constants.GROUP_PERMISSIONS[permissions].text)}
                  </Header.Content>
                </Header>
              ) : (
                ''
              )}
            </Table.Cell>
            <Table.Cell>{subgroups}</Table.Cell>
            <Table.Cell collapsing textAlign='center'>
              {adminLevel !== 'user' &&
                renderDropdownActions({ id: group, name: groupName })}
            </Table.Cell>
          </Table.Row>
        </Ref>
      )
    );
  };

  const renderUsers = (): JSX.Element[] => {
    return listSort.map(
      (
        {
          name,
          email,
          meta,
          permissions,
          color,
          id,
          createdBySystem,
          isNewUser
        },
        index
      ) => (
        <Table.Row key={`user-table-${index + 1}`}>
          <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
          <Table.Cell className='collapse-texts'>{name}</Table.Cell>
          <Table.Cell className='collapse-texts'>{email}</Table.Cell>
          <Table.Cell>{t(permissions)}</Table.Cell>
          <Table.Cell>
            <Icon color={color} name='circle' size='small' />
            {t(meta)}
          </Table.Cell>
          <Table.Cell collapsing textAlign='center'>
            {adminLevel !== 'user' &&
              !createdBySystem &&
              renderDropdownActions({ id, name, email, isNewUser })}
          </Table.Cell>
        </Table.Row>
      )
    );
  };

  const renderThings = (): JSX.Element[] => {
    return listSort.map((thingElement, index) => {
      const {
        type: receptor,
        name,
        id: uuid,
        commandGroup = '',
        connectionStatus,
        license,
        location,
        address
      }: Interfaces.IThingsTypeItem = thingElement;

      const { text: connection, color: iconColor } =
        parseStatus(connectionStatus);

      const parseLocation = Array.isArray(location)
        ? location.join(', ').toString()
        : '';
      return (
        <Ref
          innerRef={index === lastRecordRef.current ? rowRef : null}
          key={`things-${uuid}-${index.toString()}`}
        >
          <Table.Row>
            <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
            <Table.Cell>
              <Link
                className='link-name'
                to={{
                  pathname: names.paths.things.detail(receptor),
                  search: parseParamsThingDetail(uuid, name, commandGroup)
                }}
                onClick={(e) =>
                  handlerClickRedirect(e, {
                    name,
                    type: receptor,
                    id: uuid,
                    groupId: commandGroup
                  } as Interfaces.IRedirect)
                }
              >
                {name}
              </Link>
            </Table.Cell>
            <Table.Cell>{uuid}</Table.Cell>
            <Table.Cell>
              <Icon name='circle' color={iconColor} size='tiny' />
              {connection}
            </Table.Cell>
            <Table.Cell>{license?.id || t('error.notAvailable')}</Table.Cell>
            <Table.Cell>{renderLocation(parseLocation, address)}</Table.Cell>
            {/* <Table.Cell>
              {formatDateValidUntil(license?.validUntil || '')}
            </Table.Cell> */}
          </Table.Row>
        </Ref>
      );
    });
  };

  const renderFeedAck = (): JSX.Element[] => {
    return listSort.map(
      (
        {
          name,
          createdAt,
          event,
          description,
          eventData,
          typeEvent,
          payload = ''
        }: Interfaces.ISubscriptionFeed,
        index
      ) => {
        const descriptionData: Interfaces.IParseDescriptionThing = {
          description,
          intensity: (eventData?.intensity as number) || null,
          origin: (eventData?.originCluster?.name as string) || null,
          state: (eventData?.originCluster?.state as string) || null,
          nameAudio: (eventData?.name as string) || null,
          date: (eventData?.date as string) || null,
          address: (eventData?.address as Interfaces.IAddressLicense) || null,
          typeEvent,
          payload: payload || null,
          isLicenseeSkyAlert: adminLevel === 'SkyAlertLicensee'
        };
        return (
          <Ref
            innerRef={index === lastRecordRef.current ? rowRef : null}
            key={`receptor-FeedAck-${index.toString()}`}
          >
            <Table.Row>
              <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
              {type === 'GROUPS-FEEDACK' && <Table.Cell>{name}</Table.Cell>}
              <Table.Cell>{formatDate(createdAt, 'LL LTS')}</Table.Cell>
              <Table.Cell>{t(event as any)}</Table.Cell>
              <Table.Cell>
                <Markdown>
                  {parseDescriptionMonitorThing(descriptionData)}
                </Markdown>
              </Table.Cell>
            </Table.Row>
          </Ref>
        );
      }
    );
  };

  const renderSubgroups = (): JSX.Element[] => {
    return listSort.map(
      (
        { id, name, thingCount, userCount, subGroupCount, createdAt },
        index
      ) => (
        <Table.Row key={`subgroups-${index.toString()}`}>
          <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
          <Table.Cell>
            <Link
              to={{
                pathname: names.paths.groups.detail,
                search: parseParamsGroupDetail(id, name)
              }}
              state={{ groupId: id, groupName: name }}
            >
              {name}
            </Link>
          </Table.Cell>
          <Table.Cell>{getCounterThing(thingCount)}</Table.Cell>
          <Table.Cell>{userCount}</Table.Cell>
          <Table.Cell>{subGroupCount}</Table.Cell>
          <Table.Cell>
            {formatDate(createdAt, Constants.FORMAT_DATE_CREATED)}
          </Table.Cell>
        </Table.Row>
      )
    );
  };

  const renderGroupsThings = (): JSX.Element[] => {
    return listSort.map(({ thing, thingName, thingType }, index) => (
      <Table.Row key={`devices-${index.toString()}`}>
        <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
        <Table.Cell>{thingName}</Table.Cell>
        <Table.Cell>{parseKeysTypes('RECEPTOR', thingType)}</Table.Cell>
        <Table.Cell>{thing}</Table.Cell>
      </Table.Row>
    ));
  };

  const renderGroupsUsers = (): JSX.Element[] => {
    return listSort.map(({ userName, email, permissions, user }, index) => (
      <Table.Row key={`detail-users-${index + 1}`}>
        <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
        <Table.Cell>{userName}</Table.Cell>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell>
          {permissions && t(Constants.GROUP_PERMISSIONS[permissions].text)}
        </Table.Cell>
        <Table.Cell collapsing>
          {listSort.length > 1 &&
            groupPermission === 'admin' &&
            renderActions({ id: user, name: userName })}
        </Table.Cell>
      </Table.Row>
    ));
  };

  const renderControlThings = () => {
    return listSort.map(
      (
        {
          id,
          actionName,
          sendingTimeFormat,
          status: statusCommand,
          disabledCancelled
        },
        index
      ) => (
        <Table.Row className='row-schedule' key={`command-schedule-${id}`}>
          <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
          <Table.Cell>{id}</Table.Cell>
          <Table.Cell>{actionName}</Table.Cell>
          <Table.Cell>{sendingTimeFormat}</Table.Cell>
          <Table.Cell>{statusCommand}</Table.Cell>
          <Table.Cell collapsing>
            <Button
              circular
              icon='trash alternate'
              disabled={groupPermission !== 'admin' || disabledCancelled}
              onClick={() =>
                onDeleteCommand &&
                onDeleteCommand({
                  id,
                  actionName,
                  sendingTimeFormat
                })
              }
            />
          </Table.Cell>
        </Table.Row>
      )
    );
  };

  const renderMonitorThings = (): JSX.Element[] => {
    return listSort.map((element: Interfaces.IParseMonitorThings, index) => {
      const {
        name,
        type: thingType,
        connectionStatus,
        customerName,
        customerSkyalertId,
        address,
        location,
        version,
        isDisabled
      } = element;
      const { text: stateConnection, color: iconColor } =
        parseStatus(connectionStatus);
      return (
        <Table.Row
          key={`user-table-${index + 1}`}
          style={{ position: 'relative' }}
        >
          <Table.Cell textAlign='center'>
            {isDisabled ? (
              <Popup
                content={
                  <strong>
                    <Markdown>
                      {t('monitor.detail.ignored.modeActive')}
                    </Markdown>
                  </strong>
                }
                trigger={
                  <Label
                    as='a'
                    circular
                    style={{
                      fontSize: '0.8em',
                      position: 'absolute',
                      left: -32.5
                    }}
                  >
                    <Icon name='low vision' style={{ margin: 'auto' }} />
                  </Label>
                }
              />
            ) : null}
            <span style={{ opacity: isDisabled ? '0.5' : '1' }}>
              {index + 1}
            </span>
          </Table.Cell>
          <Table.Cell disabled={isDisabled}>
            {!customerSkyalertId ? (
              <span className='not-available'>{t('error.notAvailable')}</span>
            ) : (
              <>{customerSkyalertId}</>
            )}
          </Table.Cell>
          <Table.Cell disabled={isDisabled}>
            {!customerName.length ? (
              <span className='not-available'>{t('error.notAvailable')}</span>
            ) : (
              <>{customerName}</>
            )}
          </Table.Cell>
          <Table.Cell disabled={isDisabled}>{name}</Table.Cell>
          {type === 'MONITOR-THINGS-ALL' && (
            <Table.Cell disabled={isDisabled}>
              {parseKeysTypes('RECEPTOR', thingType)}
            </Table.Cell>
          )}
          <Table.Cell disabled={isDisabled}>
            <Icon color={iconColor} name='circle' size='small' />
            {stateConnection}
          </Table.Cell>
          <Table.Cell disabled={isDisabled}>
            {version || t('error.notAvailable')}
          </Table.Cell>
          <Table.Cell disabled={isDisabled}>
            {renderLocation(
              location,
              address as Interfaces.IAddressLicense,
              isDisabled
            )}
          </Table.Cell>
          <Table.Cell>
            <Button
              className='button-more-info'
              onClick={(e) => {
                if (setModalParam) {
                  setModalParam(e, element);
                }
              }}
            >
              {t('actions.history')}
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const renderDamageReport = () => {
    return listSort.map(
      (
        {
          name,
          uuid,
          reportDate,
          damage,
          location,
          address,
          comments,
          earthquakeDate
        },
        index
      ) => {
        const dataInfo: Interfaces.IMoreInfoReport = {
          thingName: name,
          earthquakeDate,
          reportDate,
          damage,
          comments
        };
        return (
          <Table.Row className='row-schedule' key={`command-schedule-${uuid}`}>
            <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
            <Table.Cell>{name}</Table.Cell>
            <Table.Cell>{uuid}</Table.Cell>
            <Table.Cell>{reportDate}</Table.Cell>
            <Table.Cell>
              <DamageStatus damage={damage} type='icon' />
            </Table.Cell>
            <Table.Cell>{renderLocation(location, address)}</Table.Cell>
            <Table.Cell>
              <Button
                className='button-more-info'
                onClick={(e) => {
                  if (setModalParam) {
                    setModalParam(e, dataInfo);
                  }
                }}
              >
                {t('damageReport.moreInfo')}
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      }
    );
  };

  const renderCells = (): JSX.Element[] | JSX.Element => {
    switch (type) {
      case 'GROUPS':
        return renderGroups();
      case 'USERS':
        return renderUsers();
      case 'LICENSES':
        return renderLicenses();
      case 'THINGS':
        return renderThings();
      case 'FEEDACK':
        return renderFeedAck();
      case 'SUBGROUPS':
        return renderSubgroups();
      case 'GROUPS-THINGS':
        return renderGroupsThings();
      case 'GROUPS-USERS':
        return renderGroupsUsers();
      case 'GROUPS-FEEDACK':
        return renderFeedAck();
      case 'CONTROL-THINGS':
        return renderControlThings();
      case 'MONITOR-THINGS-ALL':
        return renderMonitorThings();
      case 'MONITOR-THINGS':
        return renderMonitorThings();
      case 'DAMAGE-REPORT':
        return renderDamageReport();
      default:
        return <Table.Row />;
    }
  };

  const isSelectOption = ['MONITOR-THINGS-ALL', 'MONITOR-THINGS'].includes(
    type
  );
  return (
    <Segment
      inverted={theme !== 'light'}
      className='wrapper-view tableView'
      basic={BASIC_SEGMENTS.includes(type)}
    >
      <Table
        basic='very'
        inverted={theme !== 'light'}
        selectable={isSelectOption}
      >
        <Table.Header>
          <Table.Row>
            {headers.map(({ text, column }, index) => {
              const arrowSort =
                columnToSort !== column ? 'sort' : ICON_SORT[direction];

              return (
                <Table.HeaderCell
                  key={`header-table-${index + 1}`}
                  onClick={(e: SyntheticEvent) => handlerClickSort(e, column)}
                  textAlign={
                    EXCEPTION_TO_SORT.includes(column) ? 'center' : 'left'
                  }
                  width={column === 'description' ? 7 : undefined}
                  style={{ padding: 0, paddingBottom: '0.9em' }}
                >
                  {t(text as any)}
                  {!EXCEPTION_TO_SORT.includes(column) && (
                    <Icon
                      color='grey'
                      style={{ marginLeft: '0.5em' }}
                      name={arrowSort}
                    />
                  )}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderCells()}</Table.Body>
      </Table>
      {/* TODO: FIX BACK TO TOP FUNCTION WITH FLOATING BUTTON */}
      {/* <Ref innerRef={viewInWindowRef}> */}
      <div>
        {loadMore && !isSearch && (
          <Button
            content={t('actions.loadMore')}
            onClick={() => {
              if (refetchLoadMore) {
                refetchLoadMore();
              }
            }}
            className='load-more'
          />
        )}
      </div>
      {/* TODO: FIX BACK TO TOP FUNCTION WITH FLOATING BUTTON */}
      {/* </Ref> */}
      {/* {isDownTo && !isLoading && (
        <ButtonFloat
          isHidden={isLoading || false}
          onClick={handlerToTop}
          color='blue'
          icon='arrow up'
          size='large'
        />
      )} */}
    </Segment>
  );
};

ViewTable.defaultProps = {
  loadMore: false,
  isSearch: false,
  isLoading: false,
  onClickTable: () => null,
  onDelete: () => null,
  onEdit: () => null,
  onDeleteCommand: () => null,
  refetchLoadMore: () => null,
  setModalParam: null
};

export default ViewTable;
