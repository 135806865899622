export default {
  common: {
    actions: {
      add: 'Agregar',
      accept: 'Aceptar',
      affirm: 'Si',
      backReturn: 'Regresar',
      cancel: 'Cancelar',
      create: 'Crear',
      close: 'Cerrar',
      edit: 'Editar',
      delete: 'Eliminar',
      deny: 'No',
      done: 'Entendido',
      detail: 'Ver detalle',
      download: 'Descargar',
      save: 'Guardar',
      search: 'Buscar',
      seeAll: 'Ver todo',
      select: 'Seleccionar',
      selectAll: 'Seleccionar todos',
      next: 'Siguiente',
      send: 'Enviar',
      generate: 'Generar',
      assign: 'Asignar',
      assignMultiple: 'Asignar licencias Desk',
      export: 'Exportar',
      filter: 'Filtrar',
      finish: 'Terminar',
      former: 'Anterior',
      show: 'Mostrar',
      update: 'Actualizar',
      new: 'Nuevo',
      history: 'ver detalle',
      no: 'No',
      yes: 'Si',
      loadMore: 'Cargar más registros',
      resendTemporaryPassword: 'Contraseña',
      back: {
        home: 'Regresar al inicio',
        login: 'Regresar para iniciar sesión'
      },
      login: {
        accept: {
          0: 'Iniciar sesión',
          1: 'Envíar',
          2: 'Actualizar contraseña',
          3: 'Restablecer contraseña'
        },
        links: {
          0: '¿Olvidaste tu contraseña?',
          1: 'Volver a iniciar de sesión',
          2: '',
          3: 'Volver a ingresar el correo electrónico'
        }
      },
      requests: {
        command: {
          send: 'Envió de comando',
          fetch: 'Obtener comandos',
          delete: 'Eliminar comando'
        },
        assign: {
          licenses: 'Asignar licencia'
        },
        add: {
          thing: 'Agregar Dispositivo',
          user: 'Agregar Usuario'
        },
        create: {
          group: 'Crear Grupo',
          subgroup: 'Crear Subgrupo',
          clients: 'Crear cliente',
          user: 'Crear Usuario'
        },
        delete: {
          group: 'Eliminar Grupo',
          subgroup: 'Eliminar Subgrupo',
          user: 'Eliminar Usuario'
        },
        edit: {
          users: 'Editar usuario',
          clients: 'Editar cliente',
          licenses: 'Editar licencia',
          thing: 'Editar dispositivo'
        },
        update: {
          settings: 'Actualizar configuración'
        },
        fetch: {
          clients: 'Consultar cliente',
          licenses: 'Consultar licencias'
        }
      },
      control: {
        initial: {
          cancel: 'Cancelar',
          accept: 'Siguiente'
        },
        scheduled: {
          cancel: 'Cancelar',
          accept: 'Envíar'
        },
        customAlert: {
          cancel: 'Regresar',
          accept: 'Siguiente'
        },
        customAudio: {
          cancel: 'Regresar',
          accept: 'Siguiente'
        },
        confirm: {
          cancel: 'Regresar',
          accept: 'Si, estoy seguro'
        },
        result: {
          cancel: 'Cerrar',
          accept: ''
        }
      },
      view: {
        cards: 'Vista en modo Tarjeta',
        table: 'Vista en modo Tabla'
      }
    },
    assignation: {
      available: 'Por redimir ',
      assigned: 'Redimidas',
      revoked: 'Suspendidas',
      expired: 'Expiradas',
      shared: 'Asignada'
    },
    authorization: {
      earthquakes: 'Alerta Sísmica',
      monitor: 'Monitoreo',
      monitoring: 'Monitoreo',
      customCommands: 'Alerta Personalizada',
      internalMessaging: 'Alerta Interna',
      storms: 'Alerta Tormentas',
      cyclones: 'Alerta Ciclones',
      tornadoes: 'Alerta Tornados',
      hurricanes: 'Alerta Huracanes',
      wildfires: 'Incendios Forestales',
      tsunami: 'Alerta Hidrológica',
      volcanoes: 'Alerta Volcanes'
    },
    avatar: {
      account: 'Cuenta',
      settings: 'Ajustes',
      signOut: 'Cerrar Sesión',
      tutorial: 'Tutorial'
    },
    breadcrumbCustom: {
      detail: 'Detalle',
      available: 'Por redimir',
      assigned: 'Redimidas',
      revoked: 'Suspendidas',
      expired: 'Expiradas',
      epicenter: 'Epicenter',
      gadget: 'Gadget',
      desk: 'Desk',
      view: 'Vista de {{permission}}'
    },
    clients: {
      title: 'Clientes',
      add: 'Agregar cliente',
      search: 'Buscar por',
      secondDropdown: ''
    },
    control: {
      customCommands: {
        title: 'Titulo',
        body: 'Contenido'
      },
      customAudio: {
        form: {
          title: 'Selecciona el audio que desees mandar',
          label: 'Nombre de audio',
          placeholder: 'Selecciona un nombre del audio personalizado',
          errorNoAudio: 'No se han encontrado registros de audio',
          nameAudio:
            '<div className="sub header">Reproducirá el siguiente audio: *{{name}}*</div>'
        }
      },
      commands: {
        update: {
          visible: 'Actualizar',
          hidden: 'Enviar comando',
          description: 'Se actualizó el dispositivo a la última versión'
        },
        restart: {
          visible: 'Reiniciar',
          hidden: 'Enviar Comando',
          description: 'El equipo se reinicio remotamente'
        },
        earthquake: {
          visible: 'Sismo',
          hidden: 'Enviar comando',
          description: 'El dispositivo recibió un sismo',
          intensity:
            'El dispositivo recibió un sismo con la intensidad **{{intensity}}**',
          origin: 'El dispositivo recibió un sismo, (**{{origin}}**)',
          intensityAndOrigin:
            'El dispositivo recibió un sismo con la intensidad **{{intensity}}** (**{{origin}}**)'
        },
        drill: {
          start: {
            visible: 'Simulacro',
            hidden: 'Enviar comando',
            description: 'Recibió un simulacro remoto'
          },
          stop: {
            visible: 'Detener Simulacro',
            hidden: 'Enviar comando',
            description: 'Se detuvo el simulacro remotamente'
          }
        },
        customAlert: {
          visible: 'Alerta Personalizada',
          hidden: 'Abrir',
          description: 'Recibió alerta personalizada'
        },
        customAudio: {
          visible: 'Audio Personalizado',
          hidden: 'Abrir',
          description: 'Recibió sonido personalizado',
          nameAudio:
            'Recibió sonido personalizado con el nombre de **{{name}}**'
        },
        testSound: {
          visible: 'Prueba de Sonido',
          hidden: 'Enviar comando',
          description: 'Recibió prueba de sonido'
        },
        silentNotification: {
          visible: 'Notificación Silenciosa',
          hidden: 'Enviar comando',
          description: 'Recibió notificación silenciosa'
        },
        configuration: {
          visible: 'Cambiar Configuración',
          hidden: 'Mostrar configuración',
          description: 'Se actualizó la configuración del equipo'
        },
        updateLocation: {
          visible: 'Cambio de ubicación',
          hidden: 'Enviar comando',
          description: 'Se actualizó **remotamente**  ubicación del equipo',
          location:
            'Se actualizó **remotamente** la ubicación del equipo a **{{county}}**, **{{state}}** (C.P. {{zipCode}})'
        },
        scheduleLocalDrill: {
          visible: 'Sistema',
          hidden: 'Enviar comando',
          skyAlertLicensee: 'Simulacro local',
          skyAlertLicenseeDate: 'Simulacro local **({{date}})**'
        }
      },
      schedule: {
        title: '{{count}} Comando(s) agendado(s)',
        table: {
          status: {
            pending: 'Pendiente',
            sent: 'Enviado',
            canceled: 'Cancelado'
          }
        },
        card: {
          date: 'Fecha: {{date}}',
          user: 'Agendado por {{user}}'
        },
        cancel: {
          title: '¿Estás seguro de cancelar el comando agendado?',
          subtitle:
            '<p className="subtitle">Para confirmar la cancelación de este comando, te recomendamos verificar que los datos sean correctos.</p><ul className="list-command-schedule "><li><strong>ID</strong>: {{id}}</li><li><strong>Comando</strong>: {{command}}</li><li><strong>Fecha programada</strong>: {{date}}</li></ul> <strong>Nota</strong>: Recuerda que una vez que lo canceles, no podrá cambiar de estado.'
        }
      },
      status: {
        send: {
          schedule: 'El comando se ha agendado correctamente',
          command: 'El comando se ha enviado correctamente'
        },
        fail: {
          schedule: 'No se pudo enviar el comando agendado, intente más tarde',
          command: 'No se pudo enviar el comando, intente más tarde'
        },
        latency: 'Latencia',
        received: 'Recibido',
        executed: 'Ejecutado'
      },
      important: {
        groups:
          'Verifica que todos los dispositivos del grupo estén conectados para recibir los comandos correctamente',
        things:
          'Verifica que el dispositivo este conectado para recibir los comandos correctamente'
      },
      empty: {
        title: 'Actualmente no hay comandos agendados'
      }
    },
    connectionStatus: {
      connected: 'Conectado',
      disconnected: 'Desconectado',
      device: '{{count}} Dispositivo',
      device_other: '{{count}} Dispositivos',
      notAvailable: 'No disponible',
      monitorThings: 'Dispositivos de todos los clientes'
    },
    customAlerts: {
      alert: 'Alerta',
      alert_0: 'Alerta no disponible',
      alert_1: 'Alerta',
      stateOutputs: {
        none: 'No Disponible',
        allActive: 'Relevador 1 y 2',
        relay: 'Relevador {{number}}'
      }
    },
    damageReport: {
      title: 'Informe de estado',
      empty: {
        title: 'Todo está en orden'
      },
      moreInfo: 'Ver detalle',
      modal: {
        title: 'Detalle del reporte',
        thingName: 'Nombre de dispositivo',
        dateReport: 'Fecha del reporte',
        dateEarthquake: 'Fecha del sismo',
        detail: 'Comentarios',
        noDetail: 'No hay comentarios',
        damageStatus: 'Daños reportados'
      },
      important:
        '**Importante:** El formulario de informe de estado se mostrará al usuario del dispositivo **Desk** cuando ocurra un sismo de intensidad <span className="moderate-color">**MODERADO**</span> a <span className="severe-color">**SEVERO**</span>.',
      description:
        'Consulta informes reportados por los equipos **Desk** con respecto a los sismos de las **últimas 24 horas**, las afectaciones que se incluyen en cada reporte son las siguientes:',
      reportedAt: 'Reportado: {{date}}',
      earthquakeDate: 'Fecha del sismo: {{date}}',
      status: {
        info: {
          title: 'Información',
          description:
            'Con el objetivo de brindar una comprensión más clara del informe de daños, te proporcionamos las siguientes descripciones e iconos, cada uno con sus respectivas afectaciones como referencia.'
        },
        none: { title: 'Ninguno', description: 'Sin incidentes.' },
        people: {
          title: 'Personas',
          description:
            'Personal interno, visitantes, clientes, residentes u otros.'
        },
        inventory: {
          title: 'Productos',
          description:
            'Daños a productos o servicios, interrupciones en la entrega u otros.'
        },
        property: {
          title: 'Inmueble',
          description:
            'Daño a la infraestructura física, como edificaciones, instalaciones o equipos.'
        },
        other: {
          title: 'Comentarios',
          description: 'Se reportó comentarios sobre el informe de estado.'
        }
      }
    },
    date: {
      months: {
        january: 'Enero',
        february: 'Febrero',
        march: 'Marzo',
        april: 'Abril',
        may: 'Mayo',
        june: 'Junio',
        july: 'Julio',
        august: 'Agosto',
        september: 'Septiembre',
        october: 'Octubre',
        november: 'Noviembre',
        december: 'Diciembre'
      }
    },
    error: {
      notAvailable: 'No disponible',
      notConnected: 'No conectado',
      notAssigned: 'No asignado',
      noDataYet: 'Aún no contiene datos',
      notDataRegister: 'Datos no registrados',
      notInfoAvailable: 'Información no disponible',
      unknown: 'Desconocido',
      unknownCommand: 'Comando desconocido',
      warning: '¡Advertencia!',
      login: {
        email: 'Ingresa un correo electrónico válido',
        passwordMatch: 'Las contraseñas deben de ser las mismas',
        cognitoException: {
          notAuthorized: 'Usuario y/o contraseña incorrecta',
          codeMismatch:
            'El código de verificación proporcionado no es válido, intenta más tarde',
          limitExceeded:
            'Has alcanzado el límite de intentos para recuperar contraseña',
          passRequirements: 'No cumple los requisitos de contraseña',
          userNameEmpty: 'El código a expirado',
          expiredCode:
            'El código de verificación a expirado, <a href="{{url}}">**genera otro**</a>'
        },
        notFound: 'El usuario que busca no existe, favor de verificarlo'
      },
      clients: {
        name: 'El nombre solo puede contener letras mayúsculas o minúsculas.',
        email: 'Ingrese un correo valido.',
        commercialId:
          'El ID comercial solo puede contener letras, números y ciertos signos (-#/*).'
      },
      devices: {
        title: '¡Sin registros!',
        subtitle: 'Por el momento no tienes equipos disponibles.'
      },
      feedAck: {
        failedGetInfo: 'Fallo al traer la información'
      },
      groups: {
        title: '¡Oops! Hubo un problema',
        subtitle:
          'No pudimos obtener los eventos más recientes, intenta más tarde.'
      },
      modals: {
        labels: {
          nameLong: 'El nombre es demasiado largo',
          name: 'Ingresa un nombre válido',
          lastName: 'Ingresa un apellido válido',
          email: 'Ingresa un correo válido'
        }
      },
      search: {
        title: 'No se ha encontrado resultados de su búsqueda',
        subtitle: 'Intenta buscando con otro nombre'
      },
      requests: {
        group: {
          fetch: 'No se pudo obtener registros de los grupos',
          fetchThings:
            'No se pudo obtener registros de los receptores en el grupo',
          fetchUsers: 'No se pudo obtener registros de los usuarios en el grupo'
        },
        groups: {
          create: 'Hubo un error al crear el grupo {{name}}',
          createThing: 'Hubo un error al agregar el dispositivo',
          createUser: 'Hubo un error al eliminar el usuario',
          delete: 'No se pudo eliminar el grupo {{name}}',
          deleteUser: 'Hubo un error al eliminar el usuario',
          fetch: 'No se pudo obtener información de los grupos',
          fetchUsers: 'No se pudo obtener los usuarios',
          fetchThings:
            'No se pudo obtener registros de los receptores en el grupo',
          fetchReport: 'No se pudo obtener el reporte'
        },
        subgroups: {
          create: 'Hubo un error al crear el subgrupo',
          fetch: 'No se pudo obtener registros de los subgrupos'
        },
        licenses: {
          fetch: 'No se pudo obtener registros de las licencias',
          edit: 'Ha ocurrido un problema al editar la licencia {{id}}',
          assign: 'Ocurrió un problema al asignar la licencia'
        },
        things: {
          edit: 'Ha ocurrido un problema al cambiar el nombre del dispositivo a {{name}}',
          total: 'No se pudo obtener información de los dispositivos',
          fetch: 'No se pudo obtener el detalle de este dispositivo',
          list: 'No se pudo obtener los dispositivos',
          settings: 'No se pudo obtener la configuración del dispositivo',
          updateSettings:
            'No se pudo actualizar la configuración del dispositivo'
        },
        commands: {
          send: 'No se pudo enviar el comando, intente más tarde',
          fetch: 'No se pudo obtener los comandos agendados',
          thingId: 'Ocurrió un problema, comuníquese con el administrador',
          delete: 'No se pudo eliminar el comando, intente más tarde'
        },
        feedAck: {
          fetch: 'Fallo al traer la información'
        },
        users: {
          fetch: 'No se pudo obtener registros de los usuarios',
          create: `Hubo un error al crear el usuario - código de error: {{status}}`,
          delete: `Hubo un error al eliminar el usuario - código de error: {{status}}`,
          edit: `Hubo un error al editar el usuario - código de error: {{status}}`,
          resendTemporaryPassword:
            'Hubo un error al mandar la contraseña temporal - código de error: {{status}}'
        },
        clients: {
          fetch: 'Ha ocurrido un problema al consultar los clientes',
          create: 'Ha ocurrido un problema al crear el cliente {{name}}',
          edit: 'Ha ocurrido un problema al editar el cliente {{name}}'
        },
        monitor: {
          historyConnection:
            'Ha ocurrido un problema al consultar el historial del dispositivo',
          thingsConnected:
            'Ha ocurrido un problema al obtener los equipos conectados',
          fetchThingsInfo:
            'Ha ocurrido un problema al obtener la información de los dispositivos',
          statusConnection:
            'Ha ocurrido un problema al consultar el estatus de conexión'
        }
      },
      tutorial: {
        form: {
          firstName: 'Escribe solo letras',
          lastName: 'Escribe solo letras',
          email: 'Correo no válido'
        }
      }
    },
    filteredTable: {
      searchName: 'Buscar por nombre...'
    },
    feedAck: {
      title_one: 'Mostrando último registro',
      title_other: 'Mostrando últimos {{count}} registros',
      events: {
        connected: 'Conexión',
        disconnected: 'Desconexión',
        unknown: 'Evento desconocido',
        locationUpdated: 'Cambio de ubicación',
        description: {
          receives: 'Evento Recibido a las',
          connected: 'Equipo conectado',
          disconnected: 'Equipo desconectado',
          unknown: 'Desconocido',
          locationUpdated: 'Se actualizó la ubicación del equipo',
          locationUpdatedWithAddress:
            'Se actualizó la ubicación del equipo a **{{county}}**, **{{state}}** (C.P. {{zipCode}})'
        }
      }
    },
    graph: {
      title_one: 'Actualmente cuentas {{count}} licencia',
      title_other: 'Actualmente cuentas {{count}} licencias',
      available: '{{percent}} Por redimir',
      assigned: '{{percent}} Redimidas',
      revoked: '{{percent}} Suspendidas',
      expired: '{{percent}} Expiradas'
    },
    groups: {
      subsections: {
        tracking: 'Monitor',
        control: 'Control',
        devices: 'Equipos',
        users: 'Usuarios',
        subgroups: 'Subgrupos',
        reports: 'Reportes'
      },
      tracking: {
        registered_one: 'Conectado',
        registered_other: 'Conectados',
        disconnected_one: 'Desconectado',
        disconnected_other: 'Desconectados',
        register_one: 'Registrado',
        register_other: 'Registrados'
      },
      subgroups: {
        registers_one: 'Subgrupo',
        registers_other: 'Subgrupos'
      },
      reports: {
        search: 'Buscar reportes de conexión',
        monthly: 'Mensual',
        weekly: 'Semanal',
        reportWeeklyName: 'Reporte de {{month}} semana {{week}}',
        reportName: 'SkyAlert-Reporte-Grupo-{{group}}-{{date}}',
        selectType: 'Selecciona el tipo de reporte:',
        selectDay: 'Selecciona el día del reporte',
        selectRange: 'Selecciona el rango de días, el inicial y final',
        question: '¿Quieres descargar el reporte de {{date}}?',
        select: {
          today: 'Hoy',
          day: 'Día',
          range: 'Rango'
        },
        labels: {
          typeReport: 'Tipo de reporte:',
          events: 'Eventos',
          damage: 'Daños'
        },
        placeholders: {
          year: 'Año',
          month: 'Mes'
        },
        damage: {
          title: 'Reporte de daños {{date}}',
          totals: {
            registered: 'Registrados',
            received: 'Recibidos',
            noDamage: 'Sin daños',
            damage: 'Con daños',
            merchandise: 'Mercancía',
            structure: 'Estructural',
            staff: 'Personal'
          }
        }
      }
    },
    infoCardConnection: {
      alive: 'Vivo desde',
      status: 'Estado',
      connection: 'Estado de conexión',
      latency: 'Latencia',
      output: 'Salida de Audio',
      validFrom: 'Válido desde',
      validUntil: 'Válido hasta',
      volume: 'Volumen',
      version: 'Versión',
      license: 'Licencia',
      name: 'Nombre',
      uuid: 'UUID',
      created: 'Creado',
      type: 'Tipo',
      model: 'Modelo',
      state: 'Estado',
      system: 'Sistema',
      street: 'Calle',
      streetNumber: 'Número Ext.',
      apartmentNumber: 'Número Int.',
      locality: 'Colonia',
      sublocality: 'Municipio',
      postalCode: 'Código Postal'
    },
    intensities: {
      unknown: 'Desconocido',
      mx: {
        0: '',
        1: 'DÉBIL',
        2: 'LEVE',
        3: 'MODERADO',
        4: 'FUERTE',
        5: 'VIOLENTO',
        6: 'SEVERO'
      }
    },
    layer: {
      header: {
        search: 'Buscar...',
        records_one: '{{count}} Registro',
        records_other: '{{count}} Registros'
      }
    },
    licenses: {
      showMap: 'Ver Mapa',
      state: {
        available: 'Por redimir',
        assigned: 'Redimida',
        revoked: 'Suspendido',
        expired: 'Expirado',
        shared: 'Asignada'
      },
      detail: {
        details: 'Detalles',
        authorizations: 'Autorizaciones',
        devices: 'Dispositivos Asociados',
        info: {
          targetReceptor: 'Tipo',
          id: 'Código',
          status: 'Estado',
          validUntil: 'Fecha de vigencia',
          createdAt: 'Fecha de inicio',
          sharedWithEmail: 'Asignado a',
          sharedAt: 'Fecha de asignación'
        }
      }
    },
    login: {
      titles: {
        0: '¡Bienvenido a Safety Admin!',
        1: '¿Has olvidado tu contraseña?',
        2: 'Crea una nueva contraseña',
        3: 'Registra tu código de verificación'
      },
      subtitle: 'Administra tus dispositivos, monitorea tus grupos y más.',
      fields: {
        labels: {
          email: 'Correo electrónico',
          pass: 'Contraseña',
          changeNewPass: 'Nueva contraseña',
          changeConfirmNewPass: 'Confirmar nueva contraseña',
          forgotEmail: 'Correo electrónico',
          forgotCode: 'Código de verificación',
          forgotNewPass: 'Nueva contraseña',
          forgotConfirmNewPass: 'Confirmar nueva contraseña'
        },
        placeholders: {
          email: 'Ingresa aquí tu correo',
          pass: 'Mínimo 6 caracteres',
          changeNewPass: 'Mínimo 6 caracteres',
          changeConfirmNewPass: 'Mínimo 6 caracteres',
          forgotEmail: 'Dirección del correo electrónico registrado',
          forgotCode: '',
          forgotNewPass: 'Mínimo 6 caracteres',
          forgotConfirmNewPass: 'Mínimo 6 caracteres'
        }
      },
      passwordRequirements: {
        header: 'Tu contraseña debe incluir:',
        list: [
          'Un mínimo de 6 caracteres',
          `Un carácter especial ej. (^ $ * .[]{}()?"!@#%&/\\,><':;|_~)`,
          'Al menos un número',
          'Al menos una letra mayúscula',
          'Al menos una letra minúscula'
        ]
      },
      expiration: 'Tu sesión ha caducado'
    },
    maps: {
      notAvailable: 'Ubicación no disponible',
      notCoverage:
        'La dirección que seleccionaste NO cuenta con cobertura de alertamiento. Por favor, elige otra dirección.',
      error: 'Error al intentar cargar mapa',
      title: 'Ubicación'
    },
    menu: {
      users: 'Usuarios',
      groups: 'Grupos',
      clients: 'Clientes',
      dashboard: 'Inicio',
      monitorThings: 'Monitor',
      damageReport: 'Informe de estado',
      subscriptions: 'Subscripciones',
      alertsCustom: 'Alertas Personalizadas',
      tickets: 'Tickets de soporte',
      support: 'Soporte',
      things: {
        label: 'Dispositivos',
        gadget: 'Gadget',
        desk: 'Desk',
        saas: 'SaaS',
        epicenter: 'Epicenter'
      },
      licenses: {
        label: 'Licencias',
        available: 'Por redimir ',
        assigned: 'Redimidas',
        revoked: 'Suspendidas',
        expired: 'Expiradas'
      }
    },
    messages: {
      incoming: 'Proximamente...',
      clients: {
        title: 'Busca un cliente para empezar',
        subtitle: 'Selecciona categoría, ingresa el valor y da a buscar'
      },
      clientNotFound: {
        title: 'No se encontró el cliente',
        subtitle: 'Intenta buscando con otra categoría o valor'
      },
      customAlerts: {
        title: 'Aún no tienes alertas personalizadas',
        subtitle: 'Inicia creando un nueva alerta para tu dispositivo'
      },
      customCommands: {
        title: 'Aún no tienes audios personalizados'
      },
      feedAck: {
        title: '¡Todo en orden!',
        subtitle: 'Estás al día con los registros de tus dispositivos.'
      },
      groups: {
        title: 'Aún no tienes grupos',
        subtitle: 'Inicia creando un nuevo grupo con el nombre que tu requieras'
      },
      groupsUser: {
        title: 'Aún no tienes ningún grupo asignado',
        subtitle: 'Pídele a un administrador que te asigne uno'
      },
      groupsDetail: {
        tracking: {
          title: '¡Todo en orden!',
          subtitle: 'Estás al día con los registros de tus dispositivos.'
        },
        control: {
          title: '¡Todo en orden!',
          subtitle: 'Por el momento no tienes ningún evento programado.'
        },
        status: {
          title: 'Aún no tienes audios personalizados'
        },
        devices: {
          title: '¡Sin registros!',
          subtitle: 'Por el momento no tienes equipos disponibles.'
        },
        users: {
          title: 'No hay usuarios disponibles'
        },
        subgroups: {
          title: '¡Sin registros!',
          subtitle: 'Por el momento no tienes Subgrupo disponibles.'
        },
        reports: {
          initial: {
            title: '¿Buscas algún reporte?',
            subtitle: 'Seleciona el periodo de tiempo (Hoy, Día, Rango)'
          },
          notFound: {
            title: 'No se encontró el reporte que estás buscando',
            subtitle: 'Intenta buscando con otro mes y/o año'
          },
          invalidDate: {
            title: 'El mes y/o el año no son validos',
            subtitle: 'Por favor ingrese nuevos datos'
          }
        }
      },
      licenses: {
        title: 'Actualmente no cuentas con licencias',
        subtitle: 'Contacte con nosotros y obtenga sus licencias'
      },
      licensesClient: {
        title: 'Aún no tienes un cliente seleccionado',
        subtitle: 'Ve a la seccion de cliente'
      },
      licensesDetail: {
        status: {
          title: 'Al parecer no tienes registros',
          subtitle: 'Intenta seleccionando otra opción'
        },
        authorizations: {
          title: 'Aún no tienes autorizaciones'
        },
        things: {
          title: 'No hay dispositivos asociados'
        }
      },
      monitorThings: {
        title: 'No hay registros de conexión',
        subtitle: ''
      },
      permission: {
        title: 'No cuentas con permisos para esta sección'
      },
      things: {
        types: {
          title: 'Al parecer no tienes registros',
          subtitle: 'Intenta seleccionando otra opción'
        },
        report: {
          title: 'Aún no tienes registros',
          subtitle:
            'Por el momento no se encuentran disponibles los registros de tu dispositivo'
        },
        groups: 'Sin grupos asignados'
      },
      search: {
        title: `No se ha encontrado resultados para "{{results}}"`,
        subtitle: 'Intenta buscando con otras palabras'
      },
      users: {
        title: 'Aún no tienes usuarios',
        subtitle: 'Inicia creando un nuevo usuario'
      },
      updateChangeLog: {
        title: 'Oh Nooo ...',
        subtitle: 'Lo sentimos, algo salió mal'
      }
    },
    modals: {
      titles: {
        info: 'Información',
        error: 'Error',
        warning: '¡Advertencia!',
        add: {
          user: 'Agregar usuario',
          thing: 'Agregar Equipo'
        },
        assign: {
          license: 'Asignar licencia',
          licensesDesk: 'Asignar licencias de Desk',
          steps: {
            one: 'Subir datos de usuarios',
            two: 'Enviar'
          },
          errors: {
            data: 'Los siguientes datos no son validos:',
            noData: 'El archivo no contaba con datos',
            invalidKeys: {
              header: 'Columnas no permitidas:',
              subheader:
                'Asegúrese de revisar que no haya espacios en los nombres de las columnas.'
            },
            missingKeys:
              'Le falta las siguientes columnas que son requeridas: ',
            length:
              'No cuenta con suficientes licencias disponibles, actualemente solo cuenta con {{count}}'
          },
          reference:
            'El archivo debe ser un Excel con tres columnas de datos (Correo, Nombre, Apellidos), como se muestra en la siguiente imagen: ',
          note: 'Nota: El número de datos que ingrese no debe exceder las licencias que tiene disponible',
          reuploadFile: 'Volver a subir archivo',
          sending: 'Enviando',
          success: 'Licencias enviadas'
        },
        create: {
          user: 'Nuevo usuario',
          group: 'Nuevo Grupo',
          subgroup: 'Nuevo Subgrupo',
          customAlert: 'Nueva Alerta Personalizada',
          config: 'Configuración',
          clients: 'Agregar un nuevo cliente',
          thingsReport: 'Reporte de conexiones'
        },
        edit: {
          thing: 'Editar nombre del dispositivo',
          user: 'Editar usuario',
          clients: 'Editar cliente',
          thingLocation: 'Editar ubicación de dispositivo {{id}}',
          license: 'Editar licencia'
        },
        update: {
          config: 'Cambiar Configuración',
          changeLog: 'Detalles de actualización'
        },
        control: {
          remember: '¡Recuerda!',
          initial: '{{typeSchedule}} Comando "{{command}}"',
          customAlert: '{{typeSchedule}} Alerta Personalizada',
          customAudio: '{{typeSchedule}} Audio Personalizada',
          confirm: '¡Atención!',
          result: '',
          send: 'Enviar',
          schedule: 'Agendar',
          tipList: [
            'Es necesario programar la hora seleccionada con al menos 2 minutos de antelación'
          ],
          form: {
            title: 'Seleccione la fecha para enviar el comando',
            date: 'Fecha',
            time: 'Hora',
            invalid: 'Elige una horario mayor a la actual'
          }
        },
        scheduled: {
          now: 'Enviar Ahora',
          schedule: 'Agendar',
          once: 'Agendar: Una vez',
          daily: 'Agendar: Diariamente',
          weekly: 'Agendar: Semanalmente',
          monthly: 'Agendar: Mensualmente'
        }
      },
      labels: {
        assign: {
          name: 'Nombre(s)',
          lastName: 'Apellidos',
          email: 'Correo'
        },
        group: {
          name: 'Nombre'
        },
        clients: {
          name: 'Nombre',
          email: 'Correo',
          commercialId: 'ID Comercial'
        },
        config: {
          anyIntensity: 'Para cualquier intensidad',
          intensityTrigger:
            'Este equipo esta configurado para alertar sismos con intensidad {{intensity}} o superior',
          by: 'Por',
          drill: 'Este equipo está configurado para recibir simulacros.',
          drill_1: `Este equipo está configurado para SI recibir simulacros de SkyAlert.`,
          drill_0: `Este equipo está configurado para NO recibir simulacros de SkyAlert.`,
          resetConfig: 'Configuración Recomendada',
          ignoreDrills: 'Ignorar Simulacros',
          startIntensity: 'Empezando con intensidad {{intensity}}',
          timezone: 'Zona Horaria',
          volume: 'Volumen',
          outputs: {
            none: 'Ninguna',
            sound: 'Sonido',
            relay1: 'Relevador 1',
            relay2: 'Relevador 2',
            all: 'Relevador 1 y 2'
          },
          behaviors: {
            default: 'Usar la configuración por defecto',
            on: 'Permanecer ENCENDIDO',
            onOff: 'ENCENDER, luego APAGAR',
            offOn: 'APAGAR, luego ENCENDER',
            off: 'Permanecer APAGADO',
            inline: {
              onOff: 'ENCENDER',
              offOn: 'APAGAR'
            },
            append: {
              onOff: ', Luego APAGA',
              offOn: ', Luego ENCIENDE'
            }
          }
        },
        customAlert: {
          name: 'Nombre',
          dropzone: {
            drag: 'Arrastre aquí el archivo',
            divider: 'o',
            select: 'Seleccionar archivo'
          }
        },
        license: {
          status: 'Estado de la licencia'
        },
        users: {
          name: 'Nombre',
          email: 'Correo',
          permissions: 'Permisos'
        },
        thingLocation: {
          view: {
            licensee:
              'Arrastra el marcador a la ubicación deseada o ingresa las coordenadas',
            user: 'Arrastra el marcador a la ubicación deseada'
          },
          coords: {
            lat: 'Latitud',
            lng: 'Longitud'
          },
          errorField: 'Campo requerido',
          errorZeroCoord: '"0", no es una coordenada valida'
        }
      },
      placeholders: {
        assign: {
          name: 'Nombre',
          lastName: ' Apellidos',
          email: 'Correo'
        },
        clients: {
          name: 'Nombre completo',
          email: 'Correo del nuevo cliente',
          commercialId: 'ID Comercial'
        },
        config: {
          timezone: 'Seleccione un horario',
          earthquake: 'Seleccione la intensidad mínima',
          behavior: 'Seleccione el comportamiento esperado'
        },
        customAlert: {
          name: 'Nombre de la nueva alerta personalizada'
        },
        users: {
          name: 'Nombre Completo',
          email: 'Correo para el nuevo usuario',
          permissions: 'Permisos para el nuevo usuario',
          selectPermission: 'Selecciona un permiso'
        },
        mapLocationThing: {
          title: 'Mapa no disponible',
          subtitle: 'Introduzca coordenadas válidas'
        }
      },
      paragraphs: {
        confirm: {
          delete: '¿Estás seguro que deseas eliminar "{{element}}"?',
          deleteUser:
            '¿Estás seguro que deseas eliminar al usuario "{{element}}"?',
          observation:
            'Nota: Al eliminarlo el usuario ya no tendrá acceso a este grupo',
          sendCommand: {
            now: {
              title: '¿Estás seguro de mandar el comando "{{command}}"?',
              subtitle: {
                groups:
                  '<p className="subtitle-principal">El comando se enviará a el grupo **{{name}}**</p>  **Nota:** Recuerda que tus dispositivos deben estar conectados para que le llegue el comando',
                things:
                  '<p className="subtitle-principal">El comando se enviará al dispositivo **{{name}}** con el ID **{{id}}**</p>  **Nota:** Recuerda que tu dispositivo debe de estar conectado para que le llegue el comando'
              }
            },
            scheduled: {
              title: '¿Estás seguro de mandar el comando "{{command}}"?',
              subtitle: {
                groups:
                  '<p className="subtitle-principal">El comando se enviará a el grupo **{{name}}** </p> Recuerda que el comando se enviará el día **{{date}}** a las **{{time}}** hrs',
                things:
                  '<p className="subtitle-principal">El comando se enviará al dispositivo **{{name}}** con el ID **{{id}}** </p> Recuerda que el comando se enviará el día **{{date}}** a las **{{time}}** hrs'
              }
            },
            title: '¿Esta seguro de mandar este comando?',
            subtitle:
              'Recuerda que tus dispositivos deben estar conectados para que le llegue el comando'
          },
          commandsThings: {
            title: '¿Estás seguro de enviar el comando de "{{commandName}}"?',
            subtitle: {
              drill:
                'Este comando se enviará a **TODOS** los dispositivos que tienes configurados en este momento, lo cual incluye Desk, Gadget y Epicenter. <br><br> **Importante:** Recuerda que solo los dispositivos que aparezcan en estado “conectado” podrán recibir el simulacro.',
              stopDrill:
                'Al enviar este comando se “detendrá” el simulacro en **TODOS** los dispositivos que tienes configurados, lo cual incluye Desk, Gadget y Epicenter. <br><br> **Importante:** Recuerda que solo en los dispositivos que aparezcan en estado “conectado” se podrá detener el simulacro.'
            }
          }
        },
        stepsAssign: [
          'Se enviará por correo electrónico el Código de Licencia, enlace de descarga y manual de usuario de SkyAlert Desk.',
          'Verifica que el correo electrónico corresponde al “usuario Desk” de acuerdo a la ubicación configurada para la licencia.'
        ]
      }
    },
    monitor: {
      panes: {
        all: 'Todos',
        epicenter: 'Epicenter',
        desk: 'Desk',
        gadget: 'Gadget'
      },
      header: {
        reloadThing: 'Actualizar dispositivos',
        downloadReport: 'Descargar Reporte',
        important: 'El estado de conexión se actualiza cada **5 minutos**',
        select: {
          today: 'Hoy',
          yesterday: 'Ayer'
        },
        records: {
          total_one: '{{count}} Registro',
          total_other: '{{count}} Registros',
          connected_one: '{{count}} Conectado',
          connected_other: '{{count}} Conectados',
          disconnected_one: '{{count}} Desconectado',
          disconnected_other: '{{count}} Desconectados',
          ignored_one: '{{count}} Ignorado',
          ignored_other: '{{count}} Ignorados'
        },
        settingsButton: 'Configuración'
      },
      card: {
        idThing: '**ID Dispositivo**: {{id}}',
        thingType: '**Tipo dispositivo**: {{thingType}}',
        idCommercial: '**ID Comercial**: {{customer}}',
        client: '**Cliente**: '
      },
      detail: {
        title: 'Detalles del equipo {{thingName}}',
        history: 'Historial de conexión',
        fromDate: '**Desde:** {{from}}',
        toDate: '**Hasta:** {{to}}',
        error: 'No se pudo obtener registros de este dispositivo',
        noConnections: {
          title: 'Este dispositivo no tiene registros',
          subtitle:
            'Asegurate que el dispositivo este conectado para que empiece a reportar su estado de conexión'
        },
        ignored: {
          modeActive: 'Dispositivo **ignorado**',
          modeInactive: 'Dispositivo **NO ignorado**',
          title: '¡Atención!',
          confirm: '¿Estás seguro de ignorar este dispositivo?',
          subtitle:
            '<p className="text-ignored">Para confirmar esta acción, te recomendamos verificar que los datos sean correctos.</p><ul className="list-command-schedule"><li><strong>ID del dispositivo</strong>: {{id}}</li><li><strong>Alias/Nombre</strong>: {{alias}}</li><li><strong>Cliente</strong>: {{customerName}}</li></ul> <strong>Nota</strong>: Recuerda que puedes habilitar o deshabilitar este dispositivo.'
        },
        list: {
          skyalertId: '**SkyAlert ID**: {{skyalertId}}',
          client: '**Cliente**: {{client}}',
          clientId: '**ID Cliente**: {{clientId}}',
          receptor: '**Receptor**: {{type}}',
          name: '**Alias/Nombre**: {{name}}',
          id: '**ID Dispositivo**: <a className="id-thing">{{id}}</a>',
          version: '**Versión**: {{version}}'
        },
        selectorDays: {
          now: 'Hoy',
          threeDays: 'Hace 3 días',
          sevenDays: 'Hace 7 días'
        },
        startHistory: '**Inicio del historial**: ({{connectionStatus}})'
      },
      modalSettings: {
        title: 'Configuración del monitor',
        hideDesk: 'Ocultar Desk',
        hideSkyAlert: 'Ocultar cuentas de SkyAlert'
      }
    },
    newVersion: {
      title: '¡Actualización disponible!',
      content:
        'Haz clic en el botón de "Aceptar" para volver a cargar la página.'
    },
    notFound: {
      private: {
        title: 'Página no encontrada...',
        subHeader: 'Intenta regresando al inicio para seguir con tu navegación'
      },
      public: {
        title: 'Lo sentimos',
        subHeader: 'No hemos podido encontrar la página que buscas',
        link: 'Regresar para iniciar sesión'
      }
    },
    onBoarding: {
      title: 'Te damos la bienvenida a Safety Admin',
      step: {
        one: {
          section: 'Sobre SkyAlert',
          title: 'Conoce más sobre SkyAlert',
          content:
            'En SkyAlert nos especializamos en crear soluciones tecnológicas de alertamiento sísmico y otros peligros naturales. Nuestro enfoque innovador permite que tu empresa tome decisiones fundamentadas, ofreciendo el tiempo y la información necesarios para reducir los riesgos de manera efectiva. Además, contamos con el único sistema de alerta privada respaldado por una base legal que nos permite operar sin limitaciones en todo México.'
        },
        two: {
          section: '¿Cómo funciona?',
          title: '¿Cómo funciona Safety Admin?',
          content:
            'Con Safety Admin, podrás gestionar licencias de forma eficiente, supervisar en tiempo real la conectividad de tus dispositivos, organizarlos en grupos según tus necesidades y enviar comandos personalizados, como simulacros, alertas, reinicios, pruebas de sonido, notificaciones silenciosas y actualizaciones de manera remota. Además, tendrás control total sobre la administración de usuarios que requieran acceso a la plataforma.'
        },
        three: {
          section: 'Asigna tus licencias',
          title: 'Asigna tus licencias Desk',
          content:
            'Disfruta de la protección sísmica que SkyAlert ofrece a tu empresa. Cada licencia otorga a tus colaboradores acceso a SkyAlert Desk, la alerta sísmica confiable y oportuna, diseñada para brindar tranquilidad y respuestas efectivas en situaciones de riesgo. A continuación podrás asignar las licencias disponibles a tu equipo.'
        },
        four: {
          section: 'Finalizar',
          title: '¡Has terminado!',
          content:
            'Ahora podrás aprovechar al máximo las soluciones de SkyAlert y utilizar nuestra plataforma Safety Admin. Si tienes alguna pregunta o necesitas asistencia adicional, no dudes en contactar a tu asesor(a) de ventas. Puedes repetir este proceso en cualquier momento desde el menú desplegable en la esquina superior derecha.'
        },
        noLicenses: {
          title: 'No tienes licencias Desk sin asignar asociadas a tu cuenta',
          content:
            'Lamentablemente, no hemos encontrado licencias sin asignar vinculadas con tu cuenta. Si estás seguro(a) que deberías tener licencias registradas o necesitas asistencia, no dudes en ponerte en contacto con tu asesor de ventas para recibir asistencia personalizada. También puedes comunicarte con nosotros a través de correo electrónico o llamarnos a nuestro número de atención al cliente.',
          note: 'Puedes repetir este proceso en cualquier momento desde el menú desplegable en la esquina superior derecha.'
        },
        finallyErrorAssign: {
          title: 'Ha ocurrido un problema',
          content:
            'Lo sentimos, no fue posible asignar tus licencias Desk debido a un error. Por favor, acércate tu asesor de ventas para recibir asistencia personalizada. También puedes comunicarte con nosotros a través de correo electrónico o llamarnos al teléfono de atención al cliente para ayudarte a resolver este problema.',
          note: 'Puedes repetir este proceso en cualquier momento desde el menú desplegable en la esquina superior derecha.'
        },
        selectAssign: {
          title: 'Selecciona una opción:',
          assign: 'Asignar licencias ahora',
          later: 'Asignar en otro momento'
        },
        assignProcess: {
          title: 'Asigna tus licencias Desk',
          content:
            'En esta sección, puedes asignar hasta tres licencias que estén asociadas a tu cuenta. Completa los campos requeridos con el nombre, apellidos y correo para la persona que recibirá cada licencia.<br><br><strong>Nota:</strong> Verifica que el correo electrónico corresponde al usuario al que deseas asignar la licencia. Enviaremos por correo electrónico el Código de Licencia, enlace de descarga y manual de usuario de SkyAlert Desk.',
          addMore: 'Mostrar más licencias',
          anotherTime: 'Asignar en otro momento',
          discard: 'Descartar'
        },
        cancelAssign: {
          content:
            'Ahora podrás aprovechar al máximo las soluciones de SkyAlert y utilizar nuestra plataforma Safety Admin. Si tienes alguna pregunta o necesitas asistencia adicional, no dudes en contactar a tu asesor(a) de ventas. Podrás asignar las licencias que dejaste pendientes en cualquier momento desde el menú desplegable en la esquina superior derecha.'
        }
      },
      form: {
        firstName: 'Nombre(s)',
        lastName: 'Apellido(s)',
        email: 'Correo'
      },
      assignText: {
        success: 'Has asignado exitosamente tus licencias',
        error: {
          text: 'Ha ocurrido un problema',
          button: 'Intentar de nuevo',
          status: '(Error: {{status}})'
        }
      },
      contactUs: {
        whatsapp: {
          label: 'Teléfono',
          value: '55 7902 0029'
        },
        email: {
          label: 'Correo electrónico',
          value: 'soporte-desk@skyalert.mx'
        }
      },
      message: {
        licensee: `Como Licenciatario(a), no podrás asignar licencias desde esta sección. Deberás seleccionar un Cliente y dirigirte a la pestaña de ‘Licencias’ para asignarlas, ya sea en grupo o de forma individual.`,
        user: 'Lamentablemente, no tienes los permisos requeridos para visualizar esta sección. Únicamente tu administrador, tiene la capacidad de asignar licencias.'
      }
    },
    permissions: {
      user: {
        admin: 'Administrator',
        write: 'Supervisor',
        read: 'Lector'
      },
      account: {
        owner: 'Propietario',
        admin: 'Administrador',
        user: 'Usuario',
        licensee: 'Licenciatario'
      }
    },
    receptors: {
      'gadget-integrated': 'Gadget Integrado',
      'gadget-mini': 'Gadget',
      gadget: 'Gadget',
      desk: 'Desk',
      'desk-plus': 'Desk+',
      saas: 'SaaS',
      epicenter: 'Epicenter',
      'epicenter-pro': 'Epicenter Pro'
    },
    requests: {
      statusInfo: {
        '500': 'El correo que ingreso ya está vinculado a otro usuario'
      }
    },
    ribbon: {
      title: 'Usted está entrando como ',
      button: 'Cambiar de cliente'
    },
    settings: {
      title: 'Ajustes',
      language: 'Idioma',
      darkMode: 'Modo oscuro',
      locale: {
        spanish: 'Español',
        english: 'Inglés'
      }
    },
    sections: {
      groups: 'Grupos',
      yourGroups: 'Tus grupos',
      customAlerts: 'Alertas personalizadas',
      monitorThings: 'Monitoreo de conectividad',
      dashboard: 'Inicio',
      licenses: 'Licencias',
      support: 'Soporte',
      things: 'Dispositivos',
      users: 'Usuarios'
    },
    success: {
      login: {
        resetPass: '¡Contraseña restablecida!',
        send: {
          header: 'Código enviado',
          content:
            'Enviamos un código a tu correo, revisa tu bandeja de entrada.'
        },
        sendTemporaryPassword: {
          header: 'Contraseña temporal enviado',
          content: 'Enviamos una contraseña temporal a tu correo.'
        }
      },
      requests: {
        groups: {
          create: 'Se ha creado el grupo "{{name}}"',
          createThing: 'Se agrego con éxito el dispositivo al grupo',
          createUser: 'Se agrego con éxito el usuario al grupo',
          delete: 'Group "{{name}}" has been successfully deleted',
          deleteUser: 'Se elimino con éxito el usuario al grupo'
        },
        subgroups: {
          create: 'Se a creado con éxito el subgrupo'
        },
        licenses: {
          assign: 'Se asigno con éxito la licencia',
          edit: 'Se a editado la licencia con éxito {{id}}'
        },
        things: {
          update: 'Se actualizo con éxito la configuración del dispositivo',
          edit: 'Se ha editado con éxito el nombre a {{name}}'
        },
        commands: {
          send: 'El comando se ha enviado correctamente',
          delete: 'Se ha eliminado el comando correctamente'
        },
        user: {
          create: 'El usuario fue creado con éxito',
          edit: 'El usuario {{name}} fue editado con éxito',
          delete: 'El usuario {{name}} fue eliminado con éxito',
          resendTemporaryPassword:
            'Se ha enviado un correo con contraseña temporal a {{name}}'
        },
        clients: {
          create: 'Se ha creado al cliente con éxito {{name}}',
          edit: 'Se ha editado al cliente con éxito {{name}}'
        }
      }
    },
    support: {
      contactUs: {
        title: 'Contáctanos',
        phone: {
          label: 'Teléfono',
          content: '(55) 7902 0029'
        },
        mail: {
          label: 'Correo',
          content: 'soporte-desk@skyalert.mx'
        }
      },
      faq: {
        title: 'Preguntas sobre nuestros dispositivos',
        desk: {
          label: 'Desk',
          content: [
            {
              header: 'No puedo activar el código de clave',
              description:
                'En la pantalla de inicio, verifica que la licencia sea la misma proporcionada por correo de SkyAlert IoT, también verifica que tengas una conexión a internet.'
            },
            {
              header: '¿Comó puedo estar prevenido de los sismos?',
              description:
                'Puedes habilitar la opción de inicio automático en tu aplicación o también recomendamos mantener la aplicación en segundo plano para recibir nuestras alertas.'
            },
            {
              header: '¿Comó puedo actualizar a la versión más reciente?',
              description:
                'Al iniciar la aplicación se actualiza automáticamente o si prefieres puedes actualizar manualmente en la barra de tareas dependiendo de tu sistema operativo.'
            }
          ]
        },
        gadget: {
          label: 'Gadget',
          content: [
            {
              header: '¿Qué diferencia hay entre epicenter y gadget?',
              description:
                'Epicenter usa diferente tipo de sistema operativo y es más pequeño que epicenter'
            },
            {
              header: '¿Dónde se encuentran las bocinas?',
              description:
                'Gadget ya no cuenta con bocinas incluídas ya que su tamaño es menor a epicenter, por lo que usa tecnología de salida de audio 3.5'
            },
            {
              header: '¿Cuál es el mayor tiempo de alerta?',
              description:
                'Recibe alertas hasta 120 segundos antes de un sismo de acuerdo con el epicentro.'
            }
          ]
        },
        epicenter: {
          label: 'Epicenter',
          content: [
            {
              header: '¿Qué puede hacer epicenter al momento de un sismo?',
              description:
                'Epicenter automatiza procesos como cerrar electroválvulas de gas,suspender producción, abrir puertas de emergencia, etc.'
            },
            {
              header: '¿Cuál es el mayor tiempo de alerta?',
              description:
                'Recibe alertas hasta 120 segundos antes de un sismo de acuerdo con el epicentro.'
            },
            {
              header:
                '¿Puedo recibir asesoría sobre el funcionamiento de mi epicenter?',
              description:
                'Sí, en SkyAlert te damos la asesoría necesaria para que estés listo en el próximo terremoto.'
            }
          ]
        }
      },
      general: {
        title: 'Preguntas generales',
        licenses: {
          label: 'Licencias',
          resume:
            'Permite visualizar las licencias que se encuentran por redimir (licencias por usar) y las que ya fueron redimidas (licencias ya usadas).'
        },
        things: {
          label: 'Dispositivos',
          resume:
            'Permite visualizar información acerca de los 4 dispositivos de alertamiento, muestra el listado de dispositivos con los que cuenta el usuario.'
        },
        groups: {
          label: 'Grupos',
          resume:
            'Permite visualizar y crear grupos según la necesidad del usuario, en este se pueden vincular distintos dispositivos así poder monitorearlos.'
        },
        users: {
          label: 'Usuarios',
          resume:
            'Permite visualizar y crear usuarios para que puedan tener acceso a la plataforma.'
        }
      }
    },
    tableList: {
      header: {
        command: 'Comando',
        connections: 'Conexiones',
        eventConnection: 'Evento de conexión',
        date: 'Fecha',
        dateTime: 'Fecha/Hora',
        dateAndTime: 'Fecha y Hora',
        device: 'Dispositivo',
        devices: 'Dispositivos',
        event: 'Evento',
        email: 'Correo',
        earthquakeDetail: 'Detalle del sismo',
        description: 'Descripción',
        disconnections: 'Desconexiones',
        name: 'Nombre',
        nameThing: 'Nombre/Alías',
        nameLicense: 'Nombre(s)',
        nameThings: 'Nombre/Alias',
        nameClient: 'Nombre del cliente',
        lastName: 'Apellidos',
        meta: 'Estado',
        skyAlertId: 'SkyAlert ID',
        createdAt: 'Fecha de creación',
        user: 'Usuario',
        users: 'Usuarios',
        state: 'Estado',
        subgroups: 'Subgrupos',
        permission: 'Permisos',
        admin: 'Administrator',
        write: 'Supervisor',
        read: 'Lector',
        type: 'Tipo',
        typeThing: 'Tipo de dispositivo',
        time: 'Tiempo',
        uuid: 'UUID',
        status: 'Estado',
        latency: 'Latencia',
        options: 'Opciones',
        frecuency: 'Frecuencia',
        confirmation: 'Confirmación',
        permissions: 'Permisos',
        report: 'Reporte',
        reportDetail: 'Detalle',
        monitorDetail: 'Detalle',
        origin: 'Lugar de origen',
        id: 'ID',
        intensity: 'Intensidad',
        location: 'Ubicación',
        locationThing: 'Ubicación del dispositivo',
        license: 'Licencia',
        auth: 'Autorización',
        attributes: 'Atributos',
        validUntil: 'Válido hasta',
        frequency: 'Frecuencia',
        customerId: 'ID Customer',
        commercialId: 'ID Comercial',
        commandId: 'ID Comando',
        dateScheduled: 'Fecha Programada',
        dateReport: 'Fecha de reporte',
        damage: 'Daños',
        version: 'Versión'
      }
    },
    things: {
      devices_one: '{{count}} Dispositivo',
      devices_other: '{{count}} Dispositivos',
      descriptions: {
        gadget: '',
        desk: '',
        epicenter: '',
        saas: ''
      },
      confirmEdit:
        '¿Estás seguro que deseas cambiar el nombre del dispositivo a **"{{newName}}"**?',
      reports: {
        title: 'Reporte de conexión',
        subtitle:
          'Este reporte mostrará las conexiones y desconexiones de los receptores en el periodo que selecciones (hoy, día y rango). Para generarlo, selecciona un tipo de receptor o todos los dipositivos, posteriormente al mostrarse un seleccionar escoja que tipo de reporte necesita, si se muestra el calendario selecciona una fecha y presiona el botón descargar, de seleccionar la opcion de "Hoy", solo descargue.',
        subtitlePart2:
          ' Como resultado, obtendrás un reporte con los receptores que hayan mostrado actividad durante ese periodo.',
        reportNameThing: 'SkyAlert-Reporte-{{thing}}-{{date}}',
        reportName: 'SkyAlert-Reporte-Devices-{{date}}',
        all: 'Todos los dispositivos',
        modal: {
          header: 'Selecciona el tipo de reporte',
          headerDay: 'Selecciona el día del reporte',
          headerRange: 'Selecciona el rango de días, el inicial y final',
          question: '¿Quieres descargar el reporte de {{date}}?',
          select: {
            today: 'Hoy',
            day: 'Día',
            range: 'Rango'
          }
        }
      },
      subsections: {
        tracking: 'Monitor',
        control: 'Control',
        details: 'Detalles',
        reports: 'Reportes'
      },
      type: {
        connected_one: 'Conectado',
        connected_other: 'Conectados',
        disconnected_one: 'Desconectado',
        disconnected_other: 'Desconectados'
      },
      information: {
        receptor: 'Dispositivo',
        location: 'Ubicación',
        history: 'Historial de licencias'
      },
      commandThings: {
        header: 'Mandar comandos a todos los dispositivos',
        subheader: 'Selecciona el comando que deseas mandar'
      }
    },
    time: {
      expiration: 'Expirado',
      expiresOn: '(Expira en {{date}})',
      expiredAgo: '(Expirado hace {{date}})',
      untilValid: '(Quedan {{date}})',
      seconds_one: '{{count}} Segundo',
      seconds_other: '{{count}} Segundos',
      days_one: '{{count}} Día',
      days_other: '{{count}} Días',
      months_one: '{{count}} Mes',
      months_other: '{{count}} Meses',
      years_one: '{{count}} Año',
      years_other: '{{count}} Años',
      latencies_one: '{{count}}ms',
      latencies_other: '{{count}}ms'
    },
    users: {
      status: {
        active: 'Activo',
        inactive: 'Inactivo'
      }
    },
    version: 'SkyAlert {{year}} | v{{version}}',
    viewType: {
      cards: {
        groups_zero: 'Sin Subgrupos',
        groups_one: '{{count}} Subgrupo',
        groups_other: '{{count}} Subgrupos'
      }
    }
  }
};
