import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Header, Button, Icon, ButtonProps } from 'semantic-ui-react';
import { DateInput, DateInputProps } from 'semantic-ui-calendar-react';
import moment from 'moment';
import Search from '../../components/Search';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

interface HeaderConnectionPros {
  data: any[];
  type: Types.TFilterReceptorMonitorThings;
  records: Interfaces.ICounterMonitorConnection;
  isLoading: boolean;
  isLoadingDownloadReport: boolean;
  searchDate: Interfaces.ISearchDate;
  onClickSettings: () => void;
  onReport: () => void;
  onReload: (e: SyntheticEvent<HTMLElement>) => void;
  onChangeDate: (searchDate: Interfaces.ISearchDate) => void;
  onSearchTable: (e: SyntheticEvent, list: any, value: any) => void;
}

const IS_ACTIVE_DATE_SEARCH = false;

const HeaderConnection = ({
  data,
  type,
  records,
  isLoading,
  isLoadingDownloadReport,
  searchDate,
  onReport,
  onChangeDate,
  onSearchTable,
  onReload,
  onClickSettings
}: HeaderConnectionPros): JSX.Element => {
  const { t } = useTranslation();

  const [selectTypeDate, setSelectTypeDate] = useState<Types.TTypeDateMonitor>(
    searchDate.typeDate
  );

  const handlerChangeDate = (
    e: SyntheticEvent,
    { value, name }: DateInputProps | ButtonProps
  ): void => {
    e.preventDefault();
    const newSearchDate = {
      typeDate: name,
      date: value || ''
    };
    setSelectTypeDate(name);
    onChangeDate(newSearchDate);
  };

  const renderRecords = (): JSX.Element => (
    <div className='container-records'>
      <Header
        as='h2'
        content={t('monitor.header.records.total', { count: +records.total })}
      />
      <div className='record-connections'>
        <div className='header-connection'>
          <Icon name='circle' size='small' color='green' />
          <Header
            as='h3'
            color='green'
            className='records-connection'
            content={t('monitor.header.records.connected', {
              count: +records.connected
            })}
          />
        </div>
        <div className='header-connection'>
          <Icon name='circle' size='small' color='red' />
          <Header
            as='h3'
            color='red'
            className='records-connection'
            content={t('monitor.header.records.disconnected', {
              count: +records.disconnected
            })}
          />
        </div>
        <div className='header-connection'>
          <Icon name='circle' size='small' color='grey' />
          <Header
            as='h3'
            color='grey'
            className='records-connection'
            content={t('monitor.header.records.ignored', {
              count: +records.ignored
            })}
          />
        </div>
      </div>
    </div>
  );

  const renderDateSearchButton = (): JSX.Element => (
    <div className='date-search-buttons'>
      <Button icon='redo' className='redo-button' />
      <Button.Group basic className='days-button'>
        <Button
          className={`${selectTypeDate === 'yesterday' ? 'active-button' : ''}`}
          content={t('monitor.header.select.yesterday')}
          name='yesterday'
          onClick={handlerChangeDate}
        />
        <Button
          className={`${selectTypeDate === 'today' ? 'active-button' : ''}`}
          name='today'
          color={selectTypeDate === 'today' ? 'grey' : 'blue'}
          onClick={handlerChangeDate}
          content={t('monitor.header.select.today')}
        />
      </Button.Group>
      <DateInput
        name='date'
        icon='calendar'
        className='input-calendar'
        autocomplete='off'
        placeholder={t('modals.titles.control.form.date')}
        value={searchDate.date}
        marked={[moment(searchDate.date)]}
        minDate={moment()}
        dateFormat={Constants.DEFAULT_DATE_FORMAT}
        markColor='orange'
        iconPosition='left'
        hideMobileKeyboard
        onChange={handlerChangeDate}
      />
    </div>
  );

  const renderSearchSection = (): JSX.Element => (
    <div className='header-section search-report'>
      {IS_ACTIVE_DATE_SEARCH && renderDateSearchButton()}
      <div className='search-table'>
        <Search
          list={data}
          options={Constants.OPTIONS_SEARCH_MONITOR_THINGS}
          isDisable={isLoading}
          onSearch={onSearchTable}
          defaultOptions='customerSkyalertId'
        />
      </div>
      <Button
        icon='redo'
        className='redo-button'
        onClick={onReload}
        labelPosition='right'
        content={t('monitor.header.reloadThing')}
      />
    </div>
  );

  return (
    <Segment basic className='wrapper-header-connections'>
      <div className='header-section records-download'>
        {renderRecords()}
        <div>
          {type === 'all' && (
            <Button
              content={t('monitor.header.settingsButton')}
              icon='settings'
              labelPosition='right'
              onClick={onClickSettings}
              className='button-settings'
            />
          )}
          <Button
            color='orange'
            className='button-download-report'
            onClick={onReport}
            loading={isLoadingDownloadReport}
            disabled={isLoadingDownloadReport}
          >
            {t('monitor.header.downloadReport')}
            <Icon name='download' className='icon-download' />
          </Button>
        </div>
      </div>
      {renderSearchSection()}
    </Segment>
  );
};

export default HeaderConnection;
