export default {
  UserAgent: 'aws-amplify/cli',
  Version: '0.1.0',
  IdentityManager: {
    Default: {}
  },
  CognitoUserPool: {
    Default: {
      PoolId: process.env.REACT_APP_COGNITO_POOL_ID,
      AppClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      // AppClientSecret: "1q0va043noull6s43509acc755bib496gbkr612j7uh2ilj1hk06",
      Region: 'us-east-1'
    }
  },
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: ".skyalert.mx",
    //   // OPTIONAL - Cookie path
    //   path: "/",
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: "strict",
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  API: {
    // OPTIONAL - Amazon API
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME_ENDPOINT,
        endpoint: process.env.REACT_APP_API_URL
      }
    ]
  }
};
