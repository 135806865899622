import React from 'react';
import { Divider, Grid, Segment } from 'semantic-ui-react';
import { useSettings } from '../../../../contexts/settings';
import { useNavState } from '../../../../contexts/navigation';
import { useAuthState } from '../../../../contexts/auth';
import { useThing } from '../../../../hooks/things/useThing';
import { useAuthClient } from '../../../../hooks/useAuthClient';
import { useThingDetails } from '../../../../hooks/things/useThingDetails';
import InformationGroups from './Groups';
import InformationHistoryLicenses from './HistoryLicenses';
import InformationThing from './Thing';
import names from '../../../../config/names';
import * as Interfaces from '../../../../interfaces';

import '../../../../scss/layout/ThingsDetail.scss';

interface IProps {
  id: string;
}

const Information = ({ id }: IProps): JSX.Element => {
  const { theme } = useSettings();
  const { adminLevel } = useAuthState();
  const { isNotUserAdminLevel } = useAuthClient();
  const { updateSection, updateSubsection } = useNavState();
  const {
    dataThing: { listInfo, licenseInfo },
    isLoadingRequest: isLoadingThing,
    sendEditThingName
  } = useThing(id);
  const {
    dataThing: { listInfoDetail },
    isLoading: isLoadingDetail
  } = useThingDetails(id);

  const handleClickRedirectGroup = (): void => {
    updateSection(names.paths.groups.main);
    updateSubsection('');
  };

  const isAllowedRedirect = ['user', 'licensee'].includes(adminLevel);
  const isDarkThemeMode = theme !== 'light';

  const thingInformation: Interfaces.ThingListInformation = {
    id: listInfo?.id || '',
    type: listInfo?.type || 'saas',
    name: listInfo?.name || '',
    version: listInfo?.version || '',
    license: licenseInfo?.id || licenseInfo?.license || '',
    status: licenseInfo?.status || 'expired',
    validUntil: licenseInfo?.validUntil || '',
    validFrom: licenseInfo?.validFrom || ''
  };

  return (
    <>
      <Segment basic className='wrapper-things-information'>
        <Grid stackable columns={2}>
          <Grid.Row stretched>
            <Grid.Column width={8}>
              <InformationThing
                hasPermissionEdit={isNotUserAdminLevel}
                isLoadingFetch={isLoadingThing.fetch}
                isLoadingEdit={isLoadingThing.edit}
                isDarkMode={isDarkThemeMode}
                information={thingInformation}
                onSendEditThingName={sendEditThingName}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <InformationGroups
                isDarkMode={isDarkThemeMode}
                isLoading={isLoadingDetail}
                groups={listInfoDetail?.groups || []}
                isAllowedRedirect={isAllowedRedirect}
                onClickRedirectGroup={handleClickRedirectGroup}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {isNotUserAdminLevel ? (
          <>
            <Divider style={{ margin: '2.5em auto' }} hidden />
            <InformationHistoryLicenses
              licenses={listInfoDetail?.licenses || []}
              isLoading={isLoadingDetail}
            />
          </>
        ) : null}
      </Segment>
    </>
  );
};

export default Information;
