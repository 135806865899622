import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStatusRequest } from '../../contexts/request';
import Analytics from '../../controllers/Analytics';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import * as Interfaces from '../../interfaces';

interface IProps extends Interfaces.IAnalytics {
  groupId: string;
}

interface IUseSubgroup {
  isLoadingRequest: {
    create: boolean;
    fetch: boolean;
  };
  dataGroups: {
    listInfoSubgroup: Interfaces.IGroupSubgroupsItems[];
  };
  sendCreate: (name: string) => Promise<void>;
  refetchSubgroups: () => Promise<void>;
}

interface IState {
  isLoading: IUseSubgroup['isLoadingRequest'];
  listSubgroups: IUseSubgroup['dataGroups']['listInfoSubgroup'];
}

const LoggerInstance = ReporterLogger.getInstance();

export const useSubgroups = ({ groupId, analytics }: IProps) => {
  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });
  const { t } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    fetch: true,
    create: false
  });
  const [listSubgroups, setListSubgroups] = useState<IState['listSubgroups']>(
    []
  );

  const fetchSubgroups = async (
    id: Interfaces.IGroupComponentProps['groupId']
  ): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }
    try {
      const response = await BackendIoT.getSubgroupsInGroup(id);
      let newListSubgroups: Interfaces.IGroupSubgroupsItems[] = [];
      if (response.groups?.length) {
        newListSubgroups = response.groups;
      }

      setListSubgroups(newListSubgroups);
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        message: t('error.requests.subgroups.fetch'),
        delay: 6000
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed to get subgroups  - useSubgroups - "fetchSubgroups"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }

    fetchSubgroups(groupId);
  }, []);

  const sendCreate = async (name: string): Promise<void> => {
    try {
      setIsLoading({ ...isLoading, create: true });
      await BackendIoT.createGroupSubgroup(groupId, {
        name
      });
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.create.subgroup'),
        message: t('success.requests.subgroups.create')
      };
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.create.subgroup'),
        message: t('error.requests.subgroups.create')
      };
      LoggerInstance.error(
        'Failed to create subgroups  - useSubgroups - "sendCreate"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, create: false });
    }

    await fetchSubgroups(groupId);
  };

  const refetchSubgroups = async (): Promise<void> => {
    await fetchSubgroups(groupId);
  };

  return {
    isLoadingRequest: isLoading,
    sendCreate,
    refetchSubgroups,
    dataGroups: {
      listInfoSubgroup: listSubgroups
    }
  };
};

export default useSubgroups;
