import React, { SyntheticEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { CheckboxProps, Icon, Segment } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import Analytics from '../../controllers/Analytics';
import useReports from '../../hooks/useReports';
import HeaderConnection from './Header';
// import ModalThingsReport from '../../components/Modals/Create/ThingsReport';
import ModalSettingsMonitor from '../../components/Modals/SettingsMonitor';
import Empty from '../../components/Empty';
import Layer from '../../components/Layer';
import {
  filteredDataList,
  isModeDevelop,
  isModeStaging,
  parseInUTC
} from '../../helpers';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

interface ContainerConnectionsProps {
  type: Types.TFilterReceptorMonitorThings;
  thingsList: Interfaces.IParseMonitorThings[];
  thingsIgnored: Interfaces.IParseMonitorThings['id'][];
  isLoadingRequest: boolean;
  handleModalDetailConnections: (
    e: SyntheticEvent<HTMLElement>,
    thingInfo:
      | Interfaces.IParseMonitorThings
      | Interfaces.ICoordsAddress
      | Interfaces.ISortDataTable
  ) => void;
  handleReload: (e: SyntheticEvent<HTMLElement>) => void;
  recordsConnection: Interfaces.ICounterMonitorConnection;
}

interface ISearching extends Interfaces.ISearchValue {
  isFound: boolean;
  isInitial: boolean;
}
interface IState {
  searching: ISearching;
}

const DEFAULT_DATE: Interfaces.ISearchDate = {
  typeDate: 'today',
  date: ''
};

const DEFAULT_SEARCHING_VALUE: ISearching = {
  value: '',
  field: '',
  isFound: false,
  isInitial: true
};

const DEFAULT_SORT_VALUES = {
  column: '',
  isDescending: true
};

const INITIAL_COUNTER_MONITOR_CONNECTION: Interfaces.ICounterMonitorConnection =
  {
    total: '--',
    connected: '--',
    disconnected: '--',
    ignored: '--'
  };

const SKYALERT_ACCOUNTS_STAGING: string[] = ['ID-javier'];

const SKYALERT_ACCOUNTS_PRODUCTION: string[] = [
  'skyalert',
  'DEMO#SKYALERT_LABS',
  'random-company-01',
  'ATTDEMO20230705',
  'D084',
  'D0AF',
  'D0AM'
];

const ContainerConnections = ({
  type,
  thingsList,
  thingsIgnored,
  isLoadingRequest,
  recordsConnection,
  handleReload,
  handleModalDetailConnections
}: ContainerConnectionsProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    fetchThingsReport,
    fetchThingsReportByType,
    downloadReport,
    isLoading: isLoadingReport
  } = useReports();

  // const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenModalSettings, setIsOpenModalSettings] =
    useState<boolean>(false);
  const [isHideDeskThings, setIsHideDeskThings] = useState<boolean>(true);
  const [isFilteredSkyAlert, setIsFilteredSkyAlert] = useState<boolean>(true);
  // const [selectType, setSelectType] = useState<string>('');
  const [searchDate, setSearchDate] =
    useState<Interfaces.ISearchDate>(DEFAULT_DATE);
  const [filterData, setFilterData] =
    useState<Interfaces.IParseMonitorThings[]>(thingsList);
  const [searching, setSearching] = useState<IState['searching']>(
    DEFAULT_SEARCHING_VALUE
  );
  const [sortData, setSortData] =
    useState<Interfaces.ISortDataTable>(DEFAULT_SORT_VALUES);
  const [connectionRecord, setConnectionRecord] =
    useState<Interfaces.ICounterMonitorConnection>(
      INITIAL_COUNTER_MONITOR_CONNECTION
    );

  const clearStatesSearch = (): void => {
    setSearching(DEFAULT_SEARCHING_VALUE);
    setSortData(DEFAULT_SORT_VALUES);
  };

  const toggleOpenModalSettings = (): void => {
    setIsOpenModalSettings((prevState) => !prevState);
  };

  const filteredDeskList = (
    list: Interfaces.IParseMonitorThings[]
  ): Interfaces.IParseMonitorThings[] => {
    const filteredDesk: Interfaces.IParseMonitorThings[] = list.filter(
      (dataThing) => !['desk', 'desk-plus'].includes(dataThing.type)
    );
    return filteredDesk;
  };

  const countRecordsConnections = (
    things: Interfaces.IParseMonitorThings[]
  ): Interfaces.ICounterMonitorConnection => {
    if (!things.length) {
      return {
        total: '0',
        connected: '0',
        disconnected: '0',
        ignored: '0'
      };
    }

    const thingsConnected = things.filter(
      (thing) => thing.connectionStatus === 'connected'
    );

    const countThingsMonitoring = things.reduce(
      (acc, thing) => (thing.isDisabled ? acc + 1 : acc),
      0
    );

    const records: Interfaces.ICounterMonitorConnection = {
      total: things.length.toString(),
      connected: thingsConnected.length.toString(),
      disconnected: (
        things.length -
        thingsConnected.length -
        countThingsMonitoring
      ).toString(),
      ignored: countThingsMonitoring.toString()
    };

    return records;
  };

  const filteredSkyAlertAccounts = (list: Interfaces.IParseMonitorThings[]) => {
    // TODO: STAGING THE VALIDATION IS FOR DEMO ISSUES
    const isStaging = isModeDevelop() || isModeStaging();
    const skyAlertAccounts = isStaging
      ? SKYALERT_ACCOUNTS_STAGING
      : SKYALERT_ACCOUNTS_PRODUCTION;

    const filteredAccounts: Interfaces.IParseMonitorThings[] = list.filter(
      (dataThing) =>
        !skyAlertAccounts.includes(dataThing.customerSkyalertId || '')
    );
    return filteredAccounts;
  };
  // TODO: REFACTOR VALIDATIONS AND FILTERS MOVE TO HOOK
  const validateFilteredSearchMonitor = (): void => {
    let dataThings: Interfaces.IParseMonitorThings[] = thingsList;

    if (searching.value.length) {
      const { value, field } = searching;
      dataThings = filteredDataList(thingsList, value, field);
      const isFoundInfo = !!dataThings.length;
      setSearching({ ...searching, isFound: isFoundInfo });
    }

    if (sortData.column.length) {
      dataThings = orderBy(
        dataThings,
        [`${sortData.column}`],
        sortData.isDescending ? 'desc' : 'asc'
      );
    }

    if (isHideDeskThings) {
      dataThings = filteredDeskList(dataThings);
    }

    if (isFilteredSkyAlert) {
      dataThings = filteredSkyAlertAccounts(dataThings);
    }

    const newRecordsConnection = countRecordsConnections(dataThings);
    setConnectionRecord(newRecordsConnection);
    setFilterData(dataThings);
  };

  useEffect(() => {
    clearStatesSearch();
  }, [type]);

  useEffect(() => {
    if (isLoadingRequest) {
      clearStatesSearch();
    }
    if (thingsList.length && !isLoadingRequest) {
      if (
        !sortData.column.length &&
        !sortData.column.length &&
        !isHideDeskThings &&
        !isFilteredSkyAlert
      ) {
        setConnectionRecord(recordsConnection);
        setFilterData(thingsList);
      } else {
        validateFilteredSearchMonitor();
      }
    }
  }, [
    thingsList,
    isLoadingRequest,
    isHideDeskThings,
    isFilteredSkyAlert,
    thingsIgnored,
    recordsConnection
  ]);

  // const handlerDownloadReport = async (
  //   startDate: string,
  //   endDate: string,
  //   typeReport: string
  // ) => {
  //   let responseRawText = '';
  //   const refresh = typeReport === 'today';
  //   const parseEndDate = parseEndDateMonitorReport(endDate);
  //   const reportDate =
  //     typeReport !== 'range' ? startDate : `${startDate}-${endDate}`;

  //   const reportName =
  //     selectType !== 'all'
  //       ? t('things.reports.reportNameThing', {
  //           thing: selectType.toUpperCase(),
  //           date: reportDate
  //         })
  //       : t('things.reports.reportName', {
  //           date: reportDate
  //         });

  //   if (!selectType) {
  //     responseRawText = await fetchThingsReport(
  //       startDate,
  //       parseEndDate,
  //       refresh
  //     );
  //   } else {
  //     const typeFilterReport = selectType === 'all' ? '' : selectType;
  //     responseRawText = await fetchThingsReportByType(
  //       startDate,
  //       parseEndDate,
  //       typeFilterReport,
  //       refresh
  //     );
  //   }

  //   if (responseRawText) {
  //     downloadReport(reportName, responseRawText);
  //   }

  //   if (!thingsFetch && !download) {
  //     toggleOpenModal();
  //   }

  //   Analytics.sendEventCustom({
  //     category: 'Monitor',
  //     action: 'Download',
  //     label: 'Report '
  //   });
  // };

  const processToDownloadReport = async (typeThing: string): Promise<void> => {
    const dateNow = moment();
    const endDate = dateNow.format(Constants.DEFAULT_DATE_FORMAT_TIME);
    const startDate = moment(dateNow)
      .subtract('hours', 6)
      .format(Constants.DEFAULT_DATE_FORMAT_TIME);
    const reportDate = dateNow.format(Constants.DEFAULT_DATE_FORMAT);
    const parseStartDate = parseInUTC(startDate);
    const parseEndDate = parseInUTC(endDate);

    let responseRawText = '';

    const reportName =
      typeThing !== 'all'
        ? t('things.reports.reportNameThing', {
            thing: typeThing.toUpperCase(),
            date: reportDate
          })
        : t('things.reports.reportName', {
            date: reportDate
          });

    if (!typeThing) {
      responseRawText = await fetchThingsReport(
        parseStartDate,
        parseEndDate,
        true
      );
    } else {
      const typeFilterReport = typeThing === 'all' ? '' : typeThing;
      responseRawText = await fetchThingsReportByType(
        parseStartDate,
        parseEndDate,
        typeFilterReport,
        true
      );
    }

    if (responseRawText) {
      downloadReport(reportName, responseRawText);
    }

    Analytics.sendEventCustom({
      category: 'Monitor',
      action: 'Download',
      label: 'Report '
    });
  };

  const handlerOnChangeDate = (dateToSearch: Interfaces.ISearchDate): void => {
    setSearchDate(dateToSearch);
  };

  const handlerOnSearchData = (
    e: SyntheticEvent,
    list: any[],
    searchValue: Interfaces.ISearchValue
  ) => {
    e.preventDefault();
    let newList: Interfaces.IParseMonitorThings[] = list;

    if (isHideDeskThings) {
      newList = filteredDeskList(newList);
    }

    if (isFilteredSkyAlert) {
      newList = filteredSkyAlertAccounts(newList);
    }

    const newRecordsConnection = countRecordsConnections(newList);

    setConnectionRecord(newRecordsConnection);
    setFilterData(newList);
    setSearching({
      ...searchValue,
      isFound: !!newList.length,
      isInitial: false
    });
  };

  const handleOnReportHeaderConnection = (): void => {
    // setSelectType(type as string);
    // toggleOpenModal();
    processToDownloadReport(type);

    Analytics.sendEventCustom({
      category: 'Monitor',
      action: 'Click',
      label: `Report ${type ?? 'all devices'} `
    });
  };

  const handleSetModalParams = (
    e: SyntheticEvent<HTMLElement>,
    data:
      | Interfaces.IParseMonitorThings
      | Interfaces.ICoordsAddress
      | Interfaces.ISortDataTable
  ) => {
    const keysThingsInfo = Object.keys(data);
    if (keysThingsInfo.includes('column')) {
      setSortData(data as Interfaces.ISortDataTable);
    } else {
      handleModalDetailConnections(e, data);
    }
  };

  const handleOnChangeSettings = (
    e: SyntheticEvent,
    { name, checked }: CheckboxProps
  ): void => {
    e.preventDefault();
    if (name === 'hideDesk') {
      setIsHideDeskThings(!!checked);
    }

    if (name === 'filteredSkyAlert') {
      setIsFilteredSkyAlert(!!checked);
    }
  };

  const renderRibbonImport = (): JSX.Element => (
    <Segment
      className='important-ribbon'
      style={{ marginTop: 0, marginBottom: 30 }}
    >
      <Icon name='warning circle' />
      <Markdown>{t('monitor.header.important')}</Markdown>
    </Segment>
  );

  const renderContent = (): JSX.Element => {
    const { isFound, isInitial } = searching;

    if ((!isFound && !isInitial) || (!thingsList.length && !isLoadingRequest)) {
      const iconEmpty = !thingsList.length ? 'frown outline' : 'search';
      const typeEmpty = !thingsList.length ? 'monitorThings' : 'search';
      return (
        <Empty
          iconName={iconEmpty}
          texts={{
            title: t(`messages.${typeEmpty}.title`, {
              results: searching.value
            }),
            subtitle: t(`messages.${typeEmpty}.subtitle`)
          }}
        />
      );
    }

    return (
      <Layer
        section={type !== 'all' ? 'MONITOR-THINGS' : 'MONITOR-THINGS-ALL'}
        data={filterData}
        loadMore={false}
        fieldToSearch='name'
        setModalParam={handleSetModalParams}
        loading={isLoadingRequest}
      />
    );
  };

  const isLoadingProcessReport = isLoadingReport.thingsFetch;
  return (
    <Segment basic className='wrapper-connections'>
      {!isLoadingRequest && (
        <HeaderConnection
          type={type}
          data={thingsList}
          isLoading={isLoadingRequest}
          isLoadingDownloadReport={isLoadingProcessReport}
          onClickSettings={toggleOpenModalSettings}
          records={connectionRecord}
          searchDate={searchDate}
          onReport={handleOnReportHeaderConnection}
          onReload={handleReload}
          onChangeDate={handlerOnChangeDate}
          onSearchTable={handlerOnSearchData}
        />
      )}
      {!isLoadingRequest && renderRibbonImport()}
      {renderContent()}
      {/* <ModalThingsReport
        isOpen={isOpenModal}
        isLoading={thingsFetch || download}
        onAccept={handlerDownloadReport}
        onCancel={toggleOpenModal}
      /> */}
      <ModalSettingsMonitor
        isOpen={isOpenModalSettings}
        settings={{
          isHideDesk: isHideDeskThings,
          isFilteredSkyAlert
        }}
        onChangeSettings={handleOnChangeSettings}
        onAccept={toggleOpenModalSettings}
      />
    </Segment>
  );
};

export default ContainerConnections;
