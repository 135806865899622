import React, { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { Button, Header, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../contexts/auth';
import { version } from '../../../package.json';
import { SkeletonModalReleaseNotes } from '../Skeletons';
import Empty from '../Empty';

interface IProps {
  isOpen: boolean;
  onAccept(): void;
}

const ReleaseNotes = ({ isOpen, onAccept }: IProps): JSX.Element => {
  const { t: translations, i18n } = useTranslation();
  const { isLicensee, adminLevel } = useAuthState();

  const isSkyAlertLicensee = adminLevel === 'SkyAlertLicensee';

  const [contentBody, setContentBody] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const clearStates = (): void => {
    setContentBody('');
    setIsLoading(false);
  };

  const fetchFile = async (
    fileExtension = '',
    controller: AbortController
  ): Promise<string> => {
    const MAIN_PATH = `${window.location.origin}/versions/v${version}/${i18n.language}/`;
    const fileName = `CHANGELOG${fileExtension}.md`;
    const pathFile = `${MAIN_PATH}${fileName}`;
    const rawFile = await fetch(pathFile, { signal: controller.signal });
    const parseFile = await rawFile.text();
    return parseFile;
  };

  const containTemplate = (content: string): boolean =>
    /<!doctype html>/gi.test(content);

  const fetchReleaseNotes = async (
    controller: AbortController
  ): Promise<void> => {
    let textContent = '';

    const fetchFileLicenseeMD = async (): Promise<string> => {
      const extension = !isSkyAlertLicensee
        ? '_LICENSEE'
        : '_LICENSEE_SKYALERT';
      const response = await fetchFile(extension, controller);
      return response;
    };

    if (isLicensee) {
      textContent = await fetchFileLicenseeMD();
    }

    const textForUser = await fetchFile('', controller);
    setContentBody(
      textContent.concat(textContent.length ? '\n\r' : '', textForUser)
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchReleaseNotes(controller);
    setTimeout(() => setIsLoading(false), 100);

    return () => {
      controller.abort();
      clearStates();
    };
  }, []);

  const rendererContentBody = (): JSX.Element => {
    if (isLoading) {
      return <SkeletonModalReleaseNotes />;
    }

    if (containTemplate(contentBody)) {
      const texts = translations('messages.updateChangeLog', {
        returnObjects: true
      }) as any;
      return <Empty iconName='meh outline' texts={texts} />;
    }

    return (
      <Markdown data-testid='markdown-release-component'>
        {contentBody}
      </Markdown>
    );
  };

  return (
    <Modal open={isOpen} className='wrapper-modal' data-testid='release-notes'>
      <Modal.Header>
        <Header
          as='h3'
          content={translations('modals.titles.update.changeLog')}
          icon='sticky note outline'
        />
      </Modal.Header>
      <Modal.Content scrolling>{rendererContentBody()}</Modal.Content>
      <Modal.Actions>
        <Button
          positive
          onClick={onAccept}
          content={translations('actions.accept')}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ReleaseNotes;
