import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../package.json';

const Version = (): JSX.Element => {
  const { t } = useTranslation('common');
  const year = moment().format('YYYY');

  return (
    <p className='version' data-testid='version-component'>
      {t('version', { year, version: packageJson.version || 'x.x.x' })}
    </p>
  );
};

export default Version;
