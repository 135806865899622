import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Header,
  Icon,
  Input,
  Label,
  List,
  Loader,
  Modal,
  Segment,
  SemanticCOLORS
} from 'semantic-ui-react';
import * as xlsx from 'xlsx';
import ReporterLogger from '../../../controllers/ReporterLogger';
import { useSettings } from '../../../contexts/settings';
import useLicenses from '../../../hooks/licenses/useLicenses';
import { validateEmail, validateFullName } from '../../../helpers';
import { IBodyAssignMultipleLicenses } from '../../../interfaces';

import MOCKUP_SPANISH from '../../../assets/images/example-excel-data-assign.jpg';
import MOCKUP_ENGLISH from '../../../assets/images/example-excel-data-assign-english.jpg';

import './Assign.scss';

export interface PropsComponentModalAssign {
  isOpen: boolean;
  isLoading: {
    list: boolean;
    assign: boolean;
  };
  isErrorRequest: boolean;
  listLicenses: any[];
  onAccept(body: IBodyAssignMultipleLicenses): void;
  onCancel(): void;
}

interface IOptionsSearch {
  [key: string]: any[];
}

// THESE FIELDS ARE RELATED TO THOSE REQUESTED FROM THE USER

type TFieldsNameSpanish = 'Correo' | 'Nombre' | 'Apellidos';
type TFieldsNameEnglish = 'Email' | 'Name' | 'LastName';

type TFieldsSpanish = {
  [key in TFieldsNameSpanish]: string;
};

type TFieldsEnglish = {
  [key in TFieldsNameEnglish]: string;
};

type TDefaultFieldsInvalid = {
  email: string[];
  name: string[];
  lastName: string[];
};

const FIELDS_SPANISH: TFieldsNameSpanish[] = ['Correo', 'Nombre', 'Apellidos'];
const FIELDS_ENGLISH: TFieldsNameEnglish[] = ['Email', 'Name', 'LastName'];

const DEFAULT_FIELDS_INVALID: TDefaultFieldsInvalid = {
  email: [],
  name: [],
  lastName: []
};

const DEFAULT_STEP_INITIAL = 1;

const LoggerInstance = ReporterLogger.getInstance();

const ModalAssignMultiple = ({
  isOpen,
  isLoading,
  isErrorRequest,
  onAccept,
  onCancel
}: PropsComponentModalAssign): JSX.Element => {
  const { t: translation } = useTranslation();

  const {
    dataLicenses: { listParsed }
  } = useLicenses();

  const { language } = useSettings();

  const [stepActive, setStepActive] = useState<number>(DEFAULT_STEP_INITIAL);
  const [dataList, setDataList] = useState<any[]>([]);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [invalidKeys, setInvalidKeys] = useState<string[]>([]);
  const [missingKeys, setMissingKeys] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [fieldsInvalid, setFieldsInvalid] = useState<TDefaultFieldsInvalid>(
    DEFAULT_FIELDS_INVALID
  );

  const isSpanish = language === 'es';

  const FIELDS_NAMES: string[] = isSpanish ? FIELDS_SPANISH : FIELDS_ENGLISH;
  const MOCK_UP = isSpanish ? MOCKUP_SPANISH : MOCKUP_ENGLISH;

  const resetStates = (): void => {
    setStepActive(DEFAULT_STEP_INITIAL);
    setFile(null);
    setDataList([]);
    setMissingKeys([]);
    setInvalidKeys([]);
    setFieldsInvalid(DEFAULT_FIELDS_INVALID);
  };

  useEffect(() => {
    if (isOpen) {
      resetStates();
    }
  }, [isOpen]);

  useEffect(() => {
    const disableState =
      !file ||
      !!invalidKeys.length ||
      !!fieldsInvalid.email.length ||
      !!fieldsInvalid.name.length ||
      !!fieldsInvalid.lastName.length;
    setIsDisable(disableState);
  }, [file, fieldsInvalid, invalidKeys]);

  const dataInvalid = (jsonData: any[]): TDefaultFieldsInvalid => {
    let fieldsError = fieldsInvalid;
    if (isSpanish) {
      jsonData.forEach(({ Correo, Nombre, Apellidos }) => {
        if (!validateEmail(Correo)) {
          fieldsError = {
            ...fieldsError,
            email: [...fieldsError.email, Correo]
          };
        }
        if (!validateFullName(Nombre)) {
          fieldsError = {
            ...fieldsError,
            name: [...fieldsError.name, Nombre]
          };
        }
        if (!validateFullName(Apellidos)) {
          fieldsError = {
            ...fieldsError,
            lastName: [...fieldsError.lastName, Apellidos]
          };
        }
      });
    } else {
      jsonData.forEach(({ Email, Name, LastName }) => {
        if (!validateEmail(Email)) {
          fieldsError = {
            ...fieldsError,
            email: [...fieldsError.email, Email]
          };
        }
        if (!validateFullName(Name)) {
          fieldsError = {
            ...fieldsError,
            name: [...fieldsError.name, Name]
          };
        }
        if (!validateFullName(LastName)) {
          fieldsError = {
            ...fieldsError,
            lastName: [...fieldsError.lastName, LastName]
          };
        }
      });
    }
    return fieldsError ?? fieldsInvalid;
  };

  const validateFields = (jsonData: any[]): boolean => {
    if (!jsonData.length) {
      return false;
    }

    const keyObjectValuesData = Object.keys(jsonData[0]);
    let invalidKeysArray: string[] = [];

    keyObjectValuesData.forEach((keyName) => {
      if (!FIELDS_NAMES.includes(keyName)) {
        invalidKeysArray = [...invalidKeysArray, keyName];
      }
    });

    if (invalidKeysArray.length) {
      setInvalidKeys(invalidKeysArray);
      return false;
    }

    let missing: string[] = [];
    FIELDS_NAMES.forEach((element) => {
      if (!keyObjectValuesData.includes(element)) {
        missing = [...missing, element];
      }
    });

    if (missing.length) {
      setMissingKeys(missing);
      return false;
    }

    const fieldsError = dataInvalid(jsonData);
    setFieldsInvalid(fieldsError);

    const isFieldsErrorEmpty = Object.values(fieldsError).every(
      (field) => !field.length
    );
    return isFieldsErrorEmpty;
  };

  const parseFileToJSON = async (newFile: File): Promise<any> => {
    let response: any = [];
    const data = await newFile.arrayBuffer();
    const workbox = xlsx.read(data, { type: 'binary' });
    let rowObject: any = [];
    workbox.SheetNames.forEach((sheet) => {
      rowObject = xlsx.utils.sheet_to_json(workbox.Sheets[sheet]);
    });
    response = rowObject;
    setDataList(response);
    return response;
  };

  const parseLicensesToShare = (jsonData: any[]): any[] => {
    let listLicensesShare = [];
    if (isSpanish) {
      const parseJsonSpanish = jsonData as TFieldsSpanish[];
      listLicensesShare = parseJsonSpanish.map(
        ({ Correo, Nombre, Apellidos }) => {
          const newElement = {
            email: Correo,
            userName: {
              first: Nombre,
              last: Apellidos
            }
          };
          return newElement;
        }
      );
    } else {
      const parseJsonEnglish = jsonData as TFieldsEnglish[];
      listLicensesShare = parseJsonEnglish.map(({ Email, Name, LastName }) => {
        const newElement = {
          email: Email,
          userName: {
            first: Name,
            last: LastName
          }
        };
        return newElement;
      });
    }
    return listLicensesShare;
  };

  const handlerOnAccept = async (): Promise<void> => {
    if (!file) {
      LoggerInstance.error('Not found file for parse to json');
      return;
    }

    setStepActive(2);
    const jsonParsed = await parseFileToJSON(file);
    const isValidFields = validateFields(jsonParsed);
    if (
      isValidFields &&
      !!jsonParsed.length &&
      jsonParsed.length <= +listParsed.available.value
    ) {
      setStepActive(3);
      const listLicensesShare = parseLicensesToShare(jsonParsed);
      onAccept({ recipients: listLicensesShare });
    }
  };

  const uploadFile = ({
    target
  }: React.ChangeEvent<HTMLInputElement>): void => {
    if (!target.files?.length || !target.files) {
      LoggerInstance.error('Could not upload file');
      return;
    }

    const fileElement = target.files[0];
    setFile(fileElement);
  };

  const renderSteps = (): JSX.Element => {
    const colors: { [key: number]: SemanticCOLORS } = {
      1: 'orange',
      2: 'red'
    };
    return (
      <div className='container-step'>
        <Header as='h2' textAlign='center' className='step-item'>
          <Label circular color={colors[stepActive] || 'grey'} size='big'>
            1
          </Label>
          <Header.Subheader>
            {translation('modals.titles.assign.steps.one')}
          </Header.Subheader>
        </Header>
        <Icon name='long arrow alternate right' />
        <Header as='h2' textAlign='center' className='step-item'>
          <Label
            circular
            color={stepActive === 3 ? 'orange' : 'grey'}
            size='big'
          >
            2
          </Label>
          <Header.Subheader>
            {translation('modals.titles.assign.steps.two')}
          </Header.Subheader>
        </Header>
      </div>
    );
  };

  const renderInputFile = (): JSX.Element => {
    return (
      <>
        <Input type='file' accept='.xls,.xlsx' onChange={uploadFile} />
        <Header
          size='small'
          content={translation('modals.titles.assign.reference')}
        />
        <img alt='Excel file' src={MOCK_UP} />
        <Header
          size='small'
          content={translation('modals.titles.assign.note')}
        />
      </>
    );
  };

  const renderErrorsFields = (): JSX.Element => {
    if (!dataList.length) {
      return (
        <Header content={translation('modals.titles.assign.errors.noData')} />
      );
    }

    if (dataList.length > +listParsed.available.value) {
      return (
        <>
          <Header
            content={translation('modals.titles.assign.errors.length', {
              count: +listParsed.available.value
            })}
          />
          <Button
            content={translation('modals.titles.assign.reuploadFile')}
            onClick={resetStates}
          />
        </>
      );
    }

    if (invalidKeys.length) {
      return (
        <>
          <Header
            size='medium'
            content={translation(
              'modals.titles.assign.errors.invalidKeys.header'
            )}
            subheader={translation(
              'modals.titles.assign.errors.invalidKeys.subheader'
            )}
          />
          <List bulleted>
            {invalidKeys.map((element) => {
              return (
                <List.Item key={`list-element-${element}`}>
                  {`"${element}"`}
                </List.Item>
              );
            })}
          </List>
          <Button
            content={translation('modals.titles.assign.reuploadFile')}
            onClick={resetStates}
          />
        </>
      );
    }

    if (missingKeys.length) {
      return (
        <>
          <Header
            size='medium'
            content={translation('modals.titles.assign.errors.missingKeys')}
          />
          <List bulleted>
            {missingKeys.map((element) => {
              return (
                <List.Item key={`list-element-${element}`}>
                  {`"${element}"`}
                </List.Item>
              );
            })}
          </List>
          <Button
            content={translation('modals.titles.assign.reuploadFile')}
            onClick={resetStates}
          />
        </>
      );
    }

    if (
      fieldsInvalid.email.length ||
      fieldsInvalid.name.length ||
      fieldsInvalid.lastName.length
    ) {
      const parseList = fieldsInvalid as IOptionsSearch;
      return (
        <>
          <Header
            size='medium'
            content={translation('modals.titles.assign.errors.data')}
          />
          {Object.keys(parseList).map((key) => {
            return (
              <List key={`list-${key}`} bulleted>
                {/* <Header size='tiny'>{key}</Header> */}
                {!!parseList[key as string].length &&
                  parseList[key as string].map((element) => (
                    <List.Item key={`list-element-${element}`}>
                      {`"${element}"`}
                    </List.Item>
                  ))}
              </List>
            );
          })}
          <Button
            content={translation('modals.titles.assign.reuploadFile')}
            onClick={resetStates}
          />
        </>
      );
    }
    return <></>;
  };

  const renderThanks = (): JSX.Element => {
    if (isLoading.assign) {
      return (
        <div className='container-loader'>
          <Loader active inline='centered' inverted size='big' />
          <Header
            as='h2'
            content={translation('modals.titles.assign.sending')}
          />
        </div>
      );
    }
    if (isErrorRequest) {
      return (
        <Header as='h2' icon textAlign='center'>
          <Icon name='delete' color='red' />
          {translation('modals.titles.assign.success')}
        </Header>
      );
    }
    return (
      <Header as='h2' icon textAlign='center'>
        <Icon name='checkmark' color='green' />
        {translation('modals.titles.assign.success')}
      </Header>
    );
  };

  const renderViewSteps = (): JSX.Element => {
    switch (stepActive) {
      case 1:
        return renderInputFile();
      case 2:
        return renderErrorsFields();
      case 3:
        return renderThanks();
      default:
        return <></>;
    }
  };

  return (
    <Modal open={isOpen} size='small'>
      <Modal.Header>
        <Header
          icon='envelope'
          content={translation('modals.titles.assign.licensesDesk')}
        />
      </Modal.Header>
      <Modal.Content scrolling>
        {renderSteps()}
        <Segment basic className='container-steps-multiple'>
          {renderViewSteps()}
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={onCancel}
          content={translation('actions.close')}
        />
        {stepActive !== 3 && (
          <Button
            positive
            loading={isLoading.list}
            disabled={isDisable}
            onClick={handlerOnAccept}
            content={translation(
              `actions.${stepActive === 1 ? 'next' : 'send'}` as any
            )}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ModalAssignMultiple;
