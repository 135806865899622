import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Segment } from 'semantic-ui-react';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useClients } from '../../hooks/useClients';

import './Ribbon.scss';

export interface PropsComponentRibbon {
  clientName: string;
}

function Ribbon({ clientName }: PropsComponentRibbon) {
  const { t: translations } = useTranslation();
  const { unselectClient, isLoadingRequest } = useClients();
  const {
    screen: { isMobile }
  } = useBreakpoint();

  const handleUnSelectClient = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    unselectClient();
  };

  return (
    <Segment invert='true' className='wrapper-client-ribbon'>
      {translations('ribbon.title')}
      <span className='client-name'>{clientName}</span>
      {isMobile ? (
        <Button
          icon='exchange'
          loading={isLoadingRequest.unselect}
          onClick={handleUnSelectClient}
        />
      ) : (
        <Button
          icon='exchange'
          loading={isLoadingRequest.unselect}
          content={translations('ribbon.button')}
          onClick={handleUnSelectClient}
        />
      )}
    </Segment>
  );
}

export default Ribbon;
