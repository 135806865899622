import { parseKeysTypes, formatDateValidUntil } from '../helpers';
import * as Interfaces from '../interfaces';
import * as Types from '../types';

interface IParseToViewTable {
  id: string;
  name: string;
  location: string;
  type: string;
  receptor: Types.TReceptorTypes;
  dateUntil: string;
  validFrom: string;
  validUntil: string;
  sharedWithEmail: string | undefined;
  address: {
    county: string;
    state: string;
    zipCode: string;
  };
}

class Licenses {
  private id: Interfaces.ILicensesTypeItem['id'];

  private name: Interfaces.IDefaultRecipient['name'];

  private mail: Interfaces.ILicensesTypeItem['sharedWithEmail'];

  private coords: string;

  private status: Interfaces.ILicensesTypeItem['status'];

  private type: Interfaces.ILicensesTypeItem['targetReceptor'];

  private dateUntil: Interfaces.ILicensesTypeItem['validUntil'];

  private address: Interfaces.IAddressLicense;

  private validFrom: Interfaces.ILicensesTypeItem['validFrom'];

  private validUntil: Interfaces.ILicensesTypeItem['validUntil'];

  constructor() {
    this.id = '';
    this.name = '';
    this.mail = '';
    this.coords = '';
    this.type = 'gadget';
    this.status = 'assigned';
    this.dateUntil = '';
    this.validFrom = '';
    this.validUntil = '';
    this.address = { county: '', state: '', zipCode: '' };
  }

  init(license: Interfaces.ILicensesTypeItem): this {
    const coords = license.defaultRecipient?.location;
    const licenseType = license.linkedToApp
      ? 'desk-plus'
      : license.targetReceptor;
    this.id = license.id;
    this.name = license.defaultRecipient?.name ?? '';
    this.mail = license.sharedWithEmail ?? '';
    this.coords = Array.isArray(coords) ? coords.join(', ').toString() : '';
    this.type = licenseType ?? this.type;
    this.dateUntil = license.validUntil ?? this.dateUntil;
    this.validFrom = license.validFrom ?? this.validFrom;
    this.validUntil = license.validUntil ?? this.validUntil;
    this.status = license.status;
    this.address = license.defaultRecipient?.address ?? {
      county: '',
      state: '',
      zipCode: ''
    };
    return this;
  }

  parseToViewTable(): IParseToViewTable {
    return {
      id: this.id,
      name: this.name,
      location: this.coords,
      type: parseKeysTypes('RECEPTOR', this.type),
      receptor: this.type,
      dateUntil: formatDateValidUntil(this.dateUntil),
      sharedWithEmail: this.mail,
      address: this.address,
      validFrom: this.validFrom,
      validUntil: this.validUntil
    };
  }
}

export default Licenses;
