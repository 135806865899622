import { DEFAULT_ADDRESS_THING } from '../config/constants';
import { parseCoords } from '../helpers';
import * as Interfaces from '../interfaces';

class MonitorThings {
  private id: Interfaces.IMonitorAllThings['id'];

  private name: Interfaces.IMonitorAllThings['name'];

  private type: Interfaces.IMonitorAllThings['type'];

  private customer: Interfaces.IMonitorAllThings['customer'];

  private customerName: Interfaces.IMonitorAllThings['customerName'];

  private customerSkyalertId: Interfaces.IMonitorAllThings['customerSkyalertId'];

  private address: Interfaces.IMonitorAllThings['address'];

  private location: Interfaces.IParseMonitorThings['location'];

  private commandGroupId: Interfaces.IMonitorAllThings['commandGroup'];

  private version: Interfaces.IMonitorAllThings['version'];

  private isDisabled: boolean;

  constructor() {
    this.id = '';
    this.name = '';
    this.type = 'desk';
    this.customer = '';
    this.customerName = '';
    this.address = DEFAULT_ADDRESS_THING;
    this.location = '';
    this.commandGroupId = '';
    this.version = '';
    this.isDisabled = false;
  }

  init(thing: Interfaces.IMonitorAllThings): this {
    this.id = thing.id;
    this.name = thing.name;
    this.type = thing.type;
    this.customer = thing.customer || '';
    this.customerName = thing.customerName || '';
    this.customerSkyalertId = thing.customerSkyalertId || '';
    this.address = thing.address || DEFAULT_ADDRESS_THING;
    this.location = parseCoords(thing.location);
    this.commandGroupId = thing.commandGroup || '';
    this.version = thing.version || '';
    this.isDisabled = !!thing.settings?.monitoringDisabled;

    return this;
  }

  parseReport(
    thingsConnected: Interfaces.IRequestThingsConnected['connectedThings']
  ): Interfaces.IParseMonitorThings {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      customer: this.customer,
      customerName: this.customerName,
      customerSkyalertId: this.customerSkyalertId,
      location: this.location,
      address: this.address,
      commandGroupId: this.commandGroupId,
      version: this.version,
      isDisabled: this.isDisabled,
      connectionStatus: thingsConnected.includes(this.id)
        ? 'connected'
        : 'disconnected'
    };
  }
}

export default MonitorThings;
