import React from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Modal,
  CheckboxProps
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useThingSettings } from '../../../hooks/things/useThingSettings';
import { SkeletonBlock } from '../../Skeletons';

export interface PropsComponentModalConfig {
  thingId: string;
  isOpen: boolean;
  onCancel(): void;
  onAccept(): void;
}

const DEFAULT_INTENSITY_TRIGGER = 3;

const ModalConfig = ({
  thingId,
  isOpen,
  onAccept,
  onCancel
}: PropsComponentModalConfig): JSX.Element => {
  const { t } = useTranslation();

  const {
    isLoadingRequest,
    dataThing: { listInfoSettings },
    sendConfigThing,
    updateListThingSettings
  } = useThingSettings(thingId, isOpen);

  const handlerChangeSettings = (
    name: string,
    value: boolean | string
  ): void => {
    const newSettings = {
      ...listInfoSettings,
      [name]: value
    };
    updateListThingSettings(newSettings);
  };

  const handlerSaveSettings = async (): Promise<void> => {
    await sendConfigThing();
    onAccept();
  };

  const isLoading = isLoadingRequest.create || isLoadingRequest.fetch;
  const renderStatus = (): JSX.Element => {
    if (isLoadingRequest.fetch) {
      return (
        <SkeletonBlock
          styled={{ maxWidth: '95%', height: 50, margin: 'auto' }}
        />
      );
    }

    const { drillOptOut, intensityTrigger } = listInfoSettings;
    const isReceiveDrills = !drillOptOut;

    const textEarthquakeIntensity = t(
      `intensities.mx.${intensityTrigger || 0}` as any
    );

    const isDisplayIntensityTrigger =
      typeof intensityTrigger === 'number' &&
      intensityTrigger > DEFAULT_INTENSITY_TRIGGER;

    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={10}>
            <Header
              as='h4'
              icon={isReceiveDrills ? 'bell' : 'bell slash'}
              content={t('modals.labels.config.drill', {
                context: isReceiveDrills ? '1' : '0'
              })}
            />
          </Grid.Column>
          <Grid.Column textAlign='right' width={6}>
            <Checkbox
              toggle
              name='drillOptOut'
              disabled={isLoading}
              checked={isReceiveDrills}
              onClick={(_, { checked }: CheckboxProps) => {
                handlerChangeSettings('drillOptOut', !checked);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        {isDisplayIntensityTrigger ? (
          <Grid.Row>
            <Grid.Column width={14}>
              <Header
                as='h4'
                icon='bullhorn'
                content={t('modals.labels.config.intensityTrigger', {
                  intensity: textEarthquakeIntensity
                })}
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
    );
  };

  return (
    <Modal
      size='tiny'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modalConfig-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={t('modals.titles.create.config')}
          icon='cogs'
        />
      </Modal.Header>
      <Modal.Content>{renderStatus()}</Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onCancel} content={t('actions.cancel')} />
        <Button
          positive
          loading={isLoadingRequest.create}
          onClick={handlerSaveSettings}
          content={t('actions.save')}
          disabled={isLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalConfig;
