import React, { SyntheticEvent, useEffect, useReducer } from 'react';
import { Button, Header, Icon, Message, Modal } from 'semantic-ui-react';
import Turndown from 'turndown';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ViewStatus from './views/Status';
import ViewScheduled from './views/Scheduled';
import ViewCustomAlerts from './views/CustomAlert';
import ViewCustomAudio from './views/CustomAudio';
import ViewConfirm from './views/Confirm';
import * as ReducerModalControl from '../../../reducers/modals/control';
import * as Interfaces from '../../../interfaces';
import * as Types from '../../../types';

import '../../../scss/layout/Modal.scss';

export interface PropsComponentModalControl {
  view?: Types.TControlViews;
  isOpen: boolean;
  isSend: boolean;
  isLoading: boolean;
  errorMessage?: string;
  recipient: Interfaces.IControlRecipient;
  commandName: string;
  onCancel(e: SyntheticEvent): void;
  onFinish(data: Interfaces.IModalControlDataFinish): void;
}
interface messageOptions {
  [key: string]:
    | Interfaces.ICustomAlertMessageItems
    | Interfaces.ICustomAudioName;
}

const LIST_NAME_COMMANDS: Interfaces.IkeyText = {
  drill: 'control.commands.drill.start.visible',
  stopDrill: 'control.commands.drill.stop.visible',
  customAlert: 'control.commands.customAlert.visible',
  customAudio: 'control.commands.customAudio.visible',
  silentNotification: 'control.commands.silentNotification.visible',
  restart: 'control.commands.restart.visible',
  update: 'control.commands.update.visible',
  testSound: 'control.commands.testSound.visible',
  notAvailable: 'error.notAvailable'
};

const CUSTOM_VIEWS: any = {
  customAlert: Types.EViewsControl.customAlert,
  customAudio: Types.EViewsControl.customAudio
};

const ModalControl = ({
  view,
  isOpen,
  isSend,
  isLoading,
  commandName,
  errorMessage,
  recipient,
  onCancel,
  onFinish
}: PropsComponentModalControl): JSX.Element => {
  const { t } = useTranslation();

  const commandNameTranslate = t(
    LIST_NAME_COMMANDS[commandName || 'notAvailable']
  );

  const [commands, dispatch] = useReducer(
    ReducerModalControl.reducer,
    ReducerModalControl.initialState
  );

  const messageCustomAlerts: messageOptions = {
    customAlert: commands.contentCustomAlert,
    customAudio: commands.contentCustomAudio
  };

  const resetStates = (): void => {
    dispatch({ type: Types.EActionsReducerControl.clear });
  };

  const isScheduleType = commands.scheduled === 'scheduled';

  const isValidTimeForScheduled = (dateVerify: string): boolean => {
    const dateCurrent = moment().add(2, 'minutes');
    const dateCheck = moment(dateVerify, 'DD-MM-YYYY HH:mm:ss');
    return dateCurrent.isSameOrBefore(dateCheck);
  };

  useEffect(() => {
    if (isOpen) {
      resetStates();
    }
  }, [isOpen]);

  useEffect(() => {
    const { date, time } = commands.date;
    const dateVerify = `${date} ${time}:00`;
    const isInvalidTime =
      isScheduleType &&
      date.length > 0 &&
      time.length > 0 &&
      !isValidTimeForScheduled(dateVerify);
    dispatch({
      type: Types.EActionsReducerControl.setIsInvalidTime,
      isInvalidTime
    });
  }, [commands.scheduled, commands.date]);

  const parseToMD = (content: string): string => {
    if (!content) {
      return content;
    }

    const turndownService = new Turndown();
    return turndownService.turndown(content);
  };

  const handleOnSelectCalendar = (
    value: Interfaces.IModalControlInitialState['date']
  ): void => {
    dispatch({
      type: Types.EActionsReducerControl.setDateCalendar,
      date: value
    });
  };

  const handleOnSelectTypeSchedule = (
    value: Interfaces.IModalControlInitialState['scheduled']
  ): void => {
    dispatch({
      type: Types.EActionsReducerControl.setTypeSchedule,
      scheduled: value
    });
  };

  const handleOnFinishFormCustomAlert = ({
    title,
    body
  }: Interfaces.IModalControlInitialState['contentCustomAlert']): void => {
    dispatch({
      type: Types.EActionsReducerControl.setContentCustomAlert,
      contentCustomAlert: { title, body: parseToMD(body) }
    });
  };

  const handleOnChangeCustomAudio = ({
    name
  }: Interfaces.IModalControlInitialState['contentCustomAudio']): void => {
    dispatch({
      type: Types.EActionsReducerControl.setContentCustomAudio,
      contentCustomAudio: { name }
    });
  };

  const handleOnBack = (e: SyntheticEvent): void => {
    switch (commands.view) {
      case Types.EViewsControl.confirm:
        dispatch({
          type: Types.EActionsReducerControl.setView,
          view: Types.EViewsControl.initial
        });
        break;
      case Types.EViewsControl.customAlert:
        dispatch({
          type: Types.EActionsReducerControl.setView,
          view: Types.EViewsControl.initial
        });
        break;
      case Types.EViewsControl.customAudio:
        dispatch({
          type: Types.EActionsReducerControl.setView,
          view: Types.EViewsControl.initial
        });
        break;
      default:
        resetStates();
        onCancel(e);
        break;
    }
  };

  const handleOnClickNextSection = (e: SyntheticEvent): void => {
    e.preventDefault();
    switch (commands.view) {
      case Types.EViewsControl.initial:
        dispatch({
          type: Types.EActionsReducerControl.setView,
          view: CUSTOM_VIEWS[commandName] || Types.EViewsControl.confirm
        });
        break;
      case Types.EViewsControl.customAlert:
        dispatch({
          type: Types.EActionsReducerControl.setView,
          view: Types.EViewsControl.confirm
        });
        break;
      case Types.EViewsControl.customAudio:
        dispatch({
          type: Types.EActionsReducerControl.setView,
          view: Types.EViewsControl.confirm
        });
        break;
      case Types.EViewsControl.confirm:
        dispatch({
          type: Types.EActionsReducerControl.setView,
          view: Types.EViewsControl.result
        });
        onFinish({
          type: commands.view,
          date: commands.date,
          message: messageCustomAlerts[commandName] || null,
          scheduled: commands.scheduled,
          command: commandNameTranslate,
          audioName: ''
        });
        break;
      default:
        break;
    }
  };

  const renderAction = (): JSX.Element => {
    switch (commands.view) {
      case Types.EViewsControl.initial:
        return (
          <ViewScheduled
            typeOption={commands.scheduled}
            initialDate={commands.date}
            isErrorTime={commands.isInvalidTime}
            onSelectCalendar={handleOnSelectCalendar}
            onSelectOption={handleOnSelectTypeSchedule}
          />
        );
      case Types.EViewsControl.customAlert:
        return (
          <ViewCustomAlerts onFinishMessage={handleOnFinishFormCustomAlert} />
        );
      case Types.EViewsControl.customAudio:
        return (
          <ViewCustomAudio
            handleOnSelectNameAudio={handleOnChangeCustomAudio}
          />
        );
      case Types.EViewsControl.confirm:
        return (
          <ViewConfirm
            view={view}
            recipient={recipient}
            command={commandNameTranslate}
            type={commands.scheduled}
            date={commands.date}
          />
        );
      case Types.EViewsControl.result:
        return (
          <ViewStatus
            type={commands.scheduled}
            errorMessage={errorMessage}
            isSend={isSend}
            loading={isLoading}
          />
        );
      default:
        return <div />;
    }
  };

  const isCustomAlert = commands.view === Types.EViewsControl.customAlert;
  const isCustomAudio = commands.view === Types.EViewsControl.customAudio;
  const isInitialView = commands.view === Types.EViewsControl.initial;

  let isDisabled = false;

  if (isScheduleType) {
    const { date, time } = commands.date;
    const dateVerify = `${date} ${time}:00`;
    isDisabled = !date || !time || !isValidTimeForScheduled(dateVerify);
  }

  if (isCustomAlert) {
    isDisabled = Object.values(commands.contentCustomAlert).some(
      (value) => !value
    );
  }

  if (isCustomAudio) {
    isDisabled = Object.values(commands.contentCustomAudio).some(
      (value) => !value
    );
  }

  const keyTranslateTypeSchedule = isScheduleType ? 'schedule' : 'send';
  const translateTypeSchedule = t(
    `modals.titles.control.${keyTranslateTypeSchedule}`
  );

  return (
    <Modal
      size='small'
      open={isOpen}
      dimmer='blurring'
      className='wrapper-modal wrapper-modal-control'
    >
      <Modal.Header>
        <Header
          textAlign='center'
          as='h1'
          content={t(`modals.titles.control.${commands.view}` as any, {
            command: commandNameTranslate,
            typeSchedule: translateTypeSchedule
          })}
        />
        <Icon
          name='close'
          className='icon-close-control'
          disabled={isLoading}
          onClick={(e: SyntheticEvent) => {
            onCancel(e);
            resetStates();
          }}
        />
      </Modal.Header>
      <Modal.Content scrolling={!isCustomAudio}>
        {renderAction()}
        {isScheduleType && isInitialView && (
          <Message
            compact
            size='small'
            icon='idea'
            header={t('modals.titles.control.remember' as any)}
            list={t('modals.titles.control.tipList', { returnObjects: true })}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          disabled={isLoading}
          content={t(`actions.control.${commands.view}.cancel` as const)}
          onClick={handleOnBack}
        />
        {commands.view !== Types.EViewsControl.result && (
          <Button
            positive
            loading={isLoading}
            disabled={isDisabled || isLoading}
            content={t(`actions.control.${commands.view}.accept` as const)}
            onClick={handleOnClickNextSection}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
};

ModalControl.defaultProps = {
  view: 'groups',
  errorMessage: ''
};

export default ModalControl;
