import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Segment, Tab, TabProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  useLocation,
  useParams,
  Location,
  useSearchParams
} from 'react-router-dom';
import Analytics from '../../../controllers/Analytics';
import { useNavState } from '../../../contexts/navigation';
import Layout from '../../../components/Layout';
import BreadcrumbCustom from '../../../components/Breadcrumb';
import Monitor from './Monitor';
import Control from './Control';
import Reports from './Reports';
import Information from './Information';
import { getParam } from '../../../helpers';
import { SECTIONS_THINGS_DETAIL } from '../../../config/constants';
import names from '../../../config/names';
import { IPropsRoutes } from '../../../interfaces';
import { TParamsThings } from '../../../types';

import '../../../scss/layout/ThingsDetail.scss';

type thingsState = Omit<Location, 'state'> & {
  state: {
    id?: string;
    name?: string;
    groupId?: string;
  };
};

const DEFAULT_INDEX_PANE = 0;

const ThingsDetail = ({ isNavbar }: IPropsRoutes): JSX.Element => {
  const { t } = useTranslation();
  const { setIsTabOpen } = useNavState();
  const { state } = useLocation() as thingsState;
  const { type } = useParams<TParamsThings>() as TParamsThings;
  const [searchParams] = useSearchParams();

  const [selectSection, setSelectSection] = useState<string>('');

  const filteredSections = SECTIONS_THINGS_DETAIL.filter(
    (section) => section.disabled === false
  );

  const sendAnalytics = (section: string): void => {
    const titleSection = `Thing ${type} Detail ${section}`;
    Analytics.sendPageView(names.paths.things.detail(type), titleSection);
  };

  useEffect(() => {
    setIsTabOpen(true);
    const initialSection = filteredSections[DEFAULT_INDEX_PANE].name;
    sendAnalytics(initialSection);
  }, []);

  const props = {
    type,
    id: state?.id || getParam('id', searchParams) || '',
    name: state?.name || getParam('name', searchParams) || '',
    groupId: state?.groupId || getParam('commandId', searchParams) || ''
  };

  const handlerOnTabChangeAnalytics = (
    event: SyntheticEvent,
    data: any
  ): void => {
    event.preventDefault();
    const tabName = filteredSections[data.activeIndex].name;
    if (tabName !== selectSection) {
      setSelectSection(tabName);
      sendAnalytics(tabName);
    }
  };

  const sectionListComponents: React.ReactNode[] = [
    <Monitor {...props} key='Component-Monitor' />,
    <Control {...props} key='Component-Control' />,
    <Information {...props} key='Component-Control' />,
    <Reports {...props} key='Component-Reports' />
  ];

  const panes: TabProps['panes'] = filteredSections.map((section, index) => ({
    menuItem: {
      ...section,
      content: t(section.content as any),
      key: `pane-${index.toString()}`
    },
    render: () => sectionListComponents[index]
  }));

  const { id, groupId, name } = props;

  if (!id || !groupId) {
    return <Navigate to={names.paths.things.main} />;
  }
  const thingType = type === 'gadget-mini' ? 'gadget' : type;

  return (
    <Layout title={`${name} [${id}]`} showNavbar={isNavbar}>
      <BreadcrumbCustom flow={[names.navigation.things, thingType]} isDetail />
      <Segment basic className='wrapper-things-detail'>
        <Tab
          className='list-sections'
          defaultActiveIndex={DEFAULT_INDEX_PANE}
          menu={{ borderless: true, attached: false, tabular: false }}
          panes={panes}
          onTabChange={handlerOnTabChangeAnalytics}
        />
      </Segment>
    </Layout>
  );
};

export default ThingsDetail;
