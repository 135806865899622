import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import {
  Button,
  Checkbox,
  Header,
  Icon,
  Input,
  InputProps,
  Segment,
  Table
} from 'semantic-ui-react';
import useBreakpoint from '../../../hooks/useBreakpoint';
import ContactUs from './ContactUs';
import Empty from '../../Empty';
import { SkeletonBlock } from '../../Skeletons';
import * as Interfaces from '../../../interfaces';

export interface PropsAssignLicenses {
  idLicenses: string[];
  idLicensesToSend: string[];
  errorAssign: Interfaces.IErrorAssign;
  isLicensee: boolean;
  havePermission: boolean;
  isLoadingRequest: boolean;
  isAssignLicenses: boolean;
  isGoingToAssign: Interfaces.IGoingToAssign;
  licensesList: Interfaces.IListParseLicensesAssignTutorial;
  tryAgain: () => void;
  nextStep: () => void;
  handlerAddLicensesToSend: (licensesId: string) => void;
  handlerRemoveLicensesToSend: (licensesId: string) => void;
  handlerAssign: (
    key: 'select' | 'cancel' | 'defaultState',
    value: boolean
  ) => void;
  onChangeInput: (
    e: SyntheticEvent,
    { value, name }: InputProps,
    licenseId: string
  ) => void;
}

// TODO: PASS FUNCTIONS THAT ARE DIRECT IN ONCLICK TO HANDLERS

const AssignLicenses = ({
  nextStep,
  tryAgain,
  idLicenses,
  isLicensee,
  errorAssign,
  licensesList,
  onChangeInput,
  handlerAssign,
  havePermission,
  isGoingToAssign,
  isAssignLicenses,
  isLoadingRequest,
  idLicensesToSend,
  handlerAddLicensesToSend,
  handlerRemoveLicensesToSend
}: PropsAssignLicenses) => {
  const { t: translation } = useTranslation();
  const { windowSize } = useBreakpoint();

  const { isError, status } = errorAssign;
  const isDisable = isLoadingRequest || isGoingToAssign.cancel;

  const handlerAddLicenses = (): void => {
    const filterRemainingLicenses = idLicenses.filter(
      (licenses) => !idLicensesToSend.includes(licenses)
    );
    handlerAddLicensesToSend(filterRemainingLicenses.shift() as string);
  };

  const handlerSelectAssignButton = (): void => {
    handlerAssign('select', true);
    handlerAssign('defaultState', false);
    handlerAddLicenses();
  };

  const handlerSelectNoAssignButton = (): void => {
    handlerAssign('select', false);
    handlerAssign('defaultState', false);
    nextStep();
  };

  const renderForm = (
    license: Interfaces.IParseLicensesAssignTutorial
  ): JSX.Element => {
    const {
      id: licensesId,
      name,
      email,
      firstName,
      lastName,
      errors
    } = license;

    const isMobile = windowSize.width < 768;
    const isErrors = errors.email || errors.firstName || errors.lastName;
    const isDataFine =
      email.length && firstName.length && lastName.length && !isErrors;
    return (
      <>
        <Table.Row>
          <div className='header-form'>
            <p className='data-license'>
              {!!isDataFine && isMobile && (
                <Icon
                  color='green'
                  size='big'
                  name='check circle'
                  className='check-data'
                />
              )}
              <span>{`${licensesId} - ${name}`}</span>
            </p>
            {idLicenses[0] !== licensesId && (
              <Button
                className='discard-button'
                disabled={isDisable}
                onClick={() => {
                  handlerRemoveLicensesToSend(licensesId);
                }}
              >
                <span>
                  {translation('onBoarding.step.assignProcess.discard')}
                </span>
                <svg
                  width='12'
                  height='16'
                  viewBox='0 0 12 16'
                  fill='none'
                  className='icon-discard'
                >
                  <path
                    d='M1.16659 13.682C1.16659 14.124 1.34218 14.5479 1.65474 14.8605C1.9673 15.173 2.39122 15.3486 2.83325 15.3486H9.49992C9.94195 15.3486 10.3659 15.173 10.6784 14.8605C10.991 14.5479 11.1666 14.124 11.1666 13.682V3.68197H1.16659V13.682ZM2.83325 5.34863H9.49992V13.682H2.83325V5.34863ZM9.08325 1.18197L8.24992 0.348633H4.08325L3.24992 1.18197H0.333252V2.84863H11.9999V1.18197H9.08325Z'
                    fill='#DB2828'
                  />
                </svg>
              </Button>
            )}
          </div>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <div className='cell-input'>
              <Input
                name='firstName'
                placeholder={translation('onBoarding.form.firstName')}
                value={firstName}
                error={errors.firstName}
                loading={isLoadingRequest}
                disabled={isDisable}
                iconPosition='left'
                onChange={(event, data) => {
                  onChangeInput(event, data, licensesId);
                }}
              />
              <div className='error-input'>
                {errors.firstName &&
                  translation('error.tutorial.form.firstName')}
              </div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className='cell-input'>
              <Input
                name='lastName'
                placeholder={translation('onBoarding.form.lastName')}
                value={lastName}
                error={errors.lastName}
                loading={isLoadingRequest}
                disabled={isDisable}
                iconPosition='left'
                onChange={(event, data) => {
                  onChangeInput(event, data, licensesId);
                }}
              />
              <div className='error-input'>
                {errors.lastName && translation('error.tutorial.form.lastName')}
              </div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className='cell-input'>
              <Input
                name='email'
                placeholder={translation('onBoarding.form.email')}
                value={email}
                error={errors.email}
                loading={isLoadingRequest}
                disabled={isDisable}
                iconPosition='left'
                onChange={(event, data) => {
                  onChangeInput(event, data, licensesId);
                }}
              />
              <div className='error-input'>
                {errors.email && translation('error.tutorial.form.email')}
              </div>
            </div>
          </Table.Cell>
          <Table.Cell>
            {!!isDataFine && !isMobile && (
              <Icon color='green' size='big' name='check circle' />
            )}
          </Table.Cell>
        </Table.Row>
      </>
    );
  };

  const renderSelect = (): JSX.Element => (
    <Segment basic className='container-select-assign'>
      <Header
        as='h3'
        content={translation('onBoarding.step.selectAssign.title')}
      />
      <div className='select-assign'>
        <Button
          fluid
          className='button-select'
          onClick={handlerSelectAssignButton}
        >
          <svg
            width='60'
            height='61'
            viewBox='0 0 60 61'
            fill='none'
            className='select-assign-icon'
          >
            <path
              d='M52.5 13.3486V18.3486H7.5V13.3486H52.5ZM7.5 43.3486H30V38.3486H7.5V43.3486ZM7.5 30.8486H52.5V25.8486H7.5V30.8486ZM45 35.8486C47.775 35.8486 50 38.0986 50 40.8486C50 43.5986 47.775 45.8486 45 45.8486C42.225 45.8486 40 43.6236 40 40.8486C40 38.0736 42.25 35.8486 45 35.8486ZM35 55.8486V53.3486C35 50.5986 39.475 48.3486 45 48.3486C50.525 48.3486 55 50.5986 55 53.3486V55.8486H35Z'
              fill='#C4C4C4'
            />
          </svg>
          <span>{translation('onBoarding.step.selectAssign.assign')}</span>
        </Button>
        <Button
          fluid
          className='button-select'
          onClick={handlerSelectNoAssignButton}
        >
          <svg
            width='60'
            height='61'
            viewBox='0 0 60 61'
            fill='none'
            className='select-assign-icon'
          >
            <path
              d='M30 50.8486C35.3043 50.8486 40.3914 48.7415 44.1421 44.9908C47.8929 41.24 50 36.153 50 30.8486C50 25.5443 47.8929 20.4572 44.1421 16.7065C40.3914 12.9558 35.3043 10.8486 30 10.8486C24.6957 10.8486 19.6086 12.9558 15.8579 16.7065C12.1071 20.4572 10 25.5443 10 30.8486C10 36.153 12.1071 41.24 15.8579 44.9908C19.6086 48.7415 24.6957 50.8486 30 50.8486ZM30 5.84863C33.283 5.84863 36.5339 6.49528 39.5671 7.75164C42.6002 9.00801 45.3562 10.8495 47.6777 13.171C49.9991 15.4924 51.8406 18.2484 53.097 21.2815C54.3534 24.3147 55 27.5656 55 30.8486C55 37.479 52.3661 43.8379 47.6777 48.5263C42.9893 53.2147 36.6304 55.8486 30 55.8486C16.175 55.8486 5 44.5986 5 30.8486C5 24.2182 7.63392 17.8594 12.3223 13.171C17.0107 8.48255 23.3696 5.84863 30 5.84863ZM31.25 18.3486V31.4736L42.5 38.1486L40.625 41.2236L27.5 33.3486V18.3486H31.25Z'
              fill='#C4C4C4'
            />
          </svg>

          <span>{translation('onBoarding.step.selectAssign.later')}</span>
        </Button>
      </div>
    </Segment>
  );

  const renderBody = (): JSX.Element => {
    if (!havePermission || isLicensee) {
      const texts = `onBoarding.message.${isLicensee ? 'licensee' : 'user'}`;
      return (
        <div className='container-empty'>
          <Empty
            iconName='info circle'
            texts={{
              title: translation(texts as any)
            }}
          />
        </div>
      );
    }

    if (!isGoingToAssign.select && idLicenses.length) {
      return renderSelect();
    }

    if (!idLicenses.length) {
      return (
        <div className='container-assign-contact-us'>
          <ContactUs />
        </div>
      );
    }
    if (isAssignLicenses) {
      return (
        <Segment basic className='assign-licenses-message'>
          <Header as='h2' icon>
            <Icon
              color={!isError ? 'green' : 'red'}
              name={!isError ? 'check circle' : 'warning circle'}
            />
            <div className='assigned-text'>
              {translation(
                `onBoarding.assignText.${!isError ? 'success' : 'error.text'}`
              )}
              {isError && !!status && (
                <span className='status-error'>
                  {translation('onBoarding.assignText.error.status', {
                    status
                  })}
                </span>
              )}
            </div>
            {isError && (
              <Header.Subheader>
                <Button
                  basic
                  className='try-again-button'
                  onClick={tryAgain}
                  content={translation('onBoarding.assignText.error.button')}
                />
              </Header.Subheader>
            )}
          </Header>
        </Segment>
      );
    }

    return (
      <>
        <Table basic='very' className='table-forms'>
          <Table.Body>
            {idLicensesToSend.map((license) => {
              return renderForm(licensesList[license]);
            })}
          </Table.Body>
        </Table>
        <div
          className={`footer-form ${
            idLicensesToSend.length === idLicenses.length ? 'just-one' : ''
          }`}
        >
          {idLicensesToSend.length !== idLicenses.length && (
            <Button
              content={translation('onBoarding.step.assignProcess.addMore')}
              onClick={handlerAddLicenses}
              disabled={isDisable}
              className='add-license-button'
            />
          )}
          <Checkbox
            className='checkbox-another-time'
            label={{
              children: translation('onBoarding.step.assignProcess.anotherTime')
            }}
            checked={isGoingToAssign.cancel}
            disabled={isLoadingRequest}
            onClick={(e, { checked }) => {
              e.preventDefault();
              if (!isLoadingRequest) {
                handlerAssign('cancel', checked ?? true);
              }
            }}
          />
        </div>
      </>
    );
  };

  const textNoLicenses = idLicenses.length ? 'three' : 'noLicenses';
  const textContent =
    isGoingToAssign.select || !havePermission
      ? 'assignProcess'
      : textNoLicenses;

  if (isLoadingRequest && isGoingToAssign.defaultState) {
    return <SkeletonBlock />;
  }

  return (
    <Segment basic className='wrapper-assign-license'>
      {havePermission && (
        <>
          <Header
            as='h3'
            content={translation(`onBoarding.step.${textContent}.title`)}
            className='header-step'
          />
          <p className='description-step'>
            <Markdown>
              {translation(`onBoarding.step.${textContent}.content`)}
            </Markdown>
          </p>
        </>
      )}
      {renderBody()}
      {!idLicenses.length && havePermission && (
        <p className='description-step'>
          {translation(`onBoarding.step.noLicenses.note`)}
        </p>
      )}
    </Segment>
  );
};

export default AssignLicenses;
