import React from 'react';
import { Header, Icon, Image, Segment, SemanticICONS } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import images from '../../../config/images';

interface IContactInfo {
  icon: SemanticICONS;
  label: string;
  value: string;
  link: string;
  style: string;
}

const CONTACT_INFORMATION: IContactInfo[] = [
  {
    icon: 'whatsapp',
    label: 'onBoarding.contactUs.whatsapp.label',
    value: 'onBoarding.contactUs.whatsapp.value',
    link: 'https://api.whatsapp.com/send/?phone=52557902002',
    style: 'whatsapp-contact'
  },
  {
    icon: 'mail outline',
    label: 'onBoarding.contactUs.email.label',
    value: 'onBoarding.contactUs.email.value',
    link: 'mailto:soporte-desk@skyalert.mx',
    style: 'email-contact'
  }
];

export const ContactUs = () => {
  const { t: translation } = useTranslation();

  return (
    <Segment basic className='wrapper-contact-us'>
      {CONTACT_INFORMATION.map(({ icon, label, value, link, style }, index) => (
        <Header key={`contact-${index + 1}`} as='h2' className={`${style}`}>
          <Icon name={icon} size='big' color='orange' />
          <Header.Content>
            <a href={link} target='_blank' rel='noreferrer'>
              {translation(label as any)}
              <Image src={images.tutorial.linkVector} className='link-icon' />
            </a>
            <Header.Subheader>{translation(value as any)}</Header.Subheader>
          </Header.Content>
        </Header>
      ))}
    </Segment>
  );
};

export default ContactUs;
