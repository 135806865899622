import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Segment,
  Button,
  Grid,
  Image,
  Header,
  DropdownProps,
  DropdownItemProps
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthClient from '../../models/AuthClient';
import { useSettings } from '../../contexts/settings';
import { useAuthState } from '../../contexts/auth';
import { useNavState } from '../../contexts/navigation';
import SkyAlertLogo from '../../assets/images/SkyAlert-Logo.png';
import SkyAlertDarkLogo from '../../assets/images/SkyAlert-Dark-Mode.png';
import LogoLicensee from '../LogoLicensee';
import Avatar from '../Avatar';
import { AVATAR_OPTIONS_LIST } from '../../config/constants';
import names from '../../config/names';
import { TUserTypes } from '../../types';

import './Header.scss';

interface PropsComponentHeaderCustom {
  title?: string;
}

const HeaderCustom = ({ title }: PropsComponentHeaderCustom): JSX.Element => {
  const userCtx = useAuthState();
  const { t } = useTranslation();
  const { theme, toggleStateSettings, toggleModalTutorial } = useSettings();
  const { updateVisibleSidebar, isMobileBreakpoint } = useNavState();

  const [valueOptionAvatar, setValueOptionAvatar] =
    useState<DropdownProps['value']>('');

  useEffect(() => {
    switch (valueOptionAvatar) {
      case 'logout':
        AuthClient.closeSession();
        break;
      case 'settings':
        toggleStateSettings();
        break;
      case 'tutorial':
        toggleModalTutorial();
        break;
      default:
        break;
    }
  }, [valueOptionAvatar]);

  const handlerChange = async (
    e: SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ): Promise<void> => {
    setValueOptionAvatar(value);
  };

  const handlerClearValue = (): void => {
    setValueOptionAvatar('');
  };

  const isDarkMode = theme !== 'light';

  const filteredOptions = AVATAR_OPTIONS_LIST.map((element) =>
    element.disabled !== true
      ? { ...element, text: t(element.text as any) }
      : false
  ).filter((item) => item) as DropdownItemProps[];

  const userPermission = userCtx.adminLevel as TUserTypes;

  return (
    <Segment
      basic
      className='wrapper-headerCustom'
      data-testid='headerCustom-component'
    >
      <Grid verticalAlign='middle'>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column width={9} only='computer'>
            <Header
              as='h2'
              size='large'
              content={t(title as any)}
              inverted={isDarkMode}
              className='title'
            />
          </Grid.Column>
          <Grid.Column width={3} only='mobile tablet' className='grid-colum'>
            <Button
              circular
              floated='left'
              icon='bars'
              className='header-btn-sidebar'
              onClick={updateVisibleSidebar}
            />
          </Grid.Column>
          <Grid.Column width={10} only='mobile tablet' className='grid-colum'>
            <Link to={names.paths.licenses.main}>
              <Image
                className='logo-menu'
                src={isDarkMode ? SkyAlertDarkLogo : SkyAlertLogo}
                size='small'
              />
            </Link>
          </Grid.Column>
          <Grid.Column
            computer={7}
            mobile={3}
            tablet={3}
            className='grid-colum'
          >
            <div className='container-header-buttons'>
              <LogoLicensee
                section='header'
                customerLicensee={userCtx.customerLicensee}
              />
              <Avatar
                pointing='top right'
                userName={userCtx.name}
                userPermission={userPermission}
                pic=''
                onChangeSelect={handlerChange}
                onClick={handlerClearValue}
                options={filteredOptions}
                selectOnBlur={false}
                icon={isMobileBreakpoint ? null : 'dropdown'}
                value={valueOptionAvatar}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

HeaderCustom.defaultProps = {
  title: ''
};

export default HeaderCustom;
