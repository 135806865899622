import epicenter from '../assets/images/epicenter.png';
import epicenterLite from '../assets/images/epicenter-lite.webp';
import gadget from '../assets/images/gadget.png';
import desk from '../assets/images/desk.png';
import gadgetMini from '../assets/images/gadget.webp';
import mail from '../assets/svg/mail.svg';
import key from '../assets/svg/key.svg';
import users from '../assets/svg/users.svg';
import support from '../assets/svg/support-person.svg';
import notFound from '../assets/svg/notfound.svg';
import DASI from '../assets/images/LicenseeLogos/DASI.png';
import FAAM from '../assets/images/LicenseeLogos/FAAM.png';
import skyAlertTutorial from '../assets/images/Tutorial/tutorial-skyalert.webp';
import safetyTutorial from '../assets/images/Tutorial/tutorial-safety.webp';
import openTutorial from '../assets/images/Tutorial/open-tutorial.gif';
import linkVector from '../assets/images/Tutorial/link.svg';
import mockEarthquake from '../assets/images/SISMO_MODERADO_MOCK.jpg';

const images = {
  devices: {
    epicenter,
    epicenterLite,
    gadget,
    gadgetMini,
    desk
  },
  icons: {
    mail,
    key,
    users
  },
  images: {
    support,
    notFound
  },
  logo: {
    DASI,
    FAAM
  },
  tutorial: {
    skyAlertTutorial,
    safetyTutorial,
    openTutorial,
    linkVector
  },
  mocks: { mockEarthquake }
};

export default images;
