import React from 'react';
import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from '@apollo/client';
import AppSync from './config/appsync';

interface IProps {
  children: React.ReactNode;
}

const url = AppSync.graphqlEndpoint || '';
const { region, apiKey = '' } = AppSync;
const auth: AuthOptions = {
  type: AUTH_TYPE.API_KEY,
  apiKey
};

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

const WithApolloProvider: React.FC<IProps> = ({
  children
}: IProps): JSX.Element => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default WithApolloProvider;
