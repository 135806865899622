/* eslint-disable import/no-unresolved */
import { Auth } from 'aws-amplify';
import AuthUsers from './AuthUsers';
import { PARAMS_URL_REDIRECT } from '../config/constants';
import * as Interfaces from '../interfaces';
import names from '../config/names';

class AuthClient extends AuthUsers {
  private static instance: AuthClient = new AuthClient();

  private instanceUser: AuthUsers;

  private userCognito: Interfaces.CognitoUserInterface | null;

  constructor() {
    super();

    if (AuthClient.instance) {
      throw new Error(
        'Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.'
      );
    }
    AuthClient.instance = this;

    this.instanceUser = AuthUsers.getUserInstance();
    this.userCognito = null;
  }

  public static getInstance(): AuthClient {
    return AuthClient.instance;
  }

  public async isDefaultPassword(
    email: string,
    password: string
  ): Promise<boolean> {
    const userCognito = await Auth.signIn(email, password);
    const isRequiredNewPass =
      userCognito.challengeName === names.cognito.challengeName;
    if (isRequiredNewPass) {
      this.userCognito = userCognito;
    }
    return isRequiredNewPass;
  }

  public async completeNewPassword(newPassword: string): Promise<void> {
    if (this.userCognito) {
      const userCognito = await Auth.completeNewPassword(
        this.userCognito,
        newPassword
      );
      const { refreshToken, accessToken } = userCognito.signInUserSession;
      this.instanceUser
        .setTokens({
          accessToken: accessToken.jwtToken,
          refreshToken: refreshToken.token
        })
        .saveToDefaults();

      this.userCognito = null;
    }
  }

  public static async sendForgotPassword(email: string): Promise<void> {
    await Auth.forgotPassword(email);
  }

  public static async sendForgotCodePassword(
    email: string,
    code: string,
    newPassword: string
  ): Promise<void> {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  public async login(email: string, password: string): Promise<void> {
    const userCognito = await Auth.signIn(email, password);
    const { refreshToken, accessToken } = userCognito.signInUserSession;

    this.instanceUser
      .setTokens({
        accessToken: accessToken.jwtToken,
        refreshToken: refreshToken.token
      })
      .saveToDefaults();
  }

  public static closeSession(isExpired = false): void {
    AuthClient.logout();
    AuthClient.cleanInfoStorage();
    AuthClient.redirectLogin(isExpired);
  }

  public static async logout(global = true): Promise<void> {
    try {
      await Auth.signOut({ global });
    } catch (error) {
      throw String(`Can't Auth logout -> ${error}`);
    }
  }

  public static cleanInfoStorage(): void {
    const monitorInfoThings = localStorage.getItem(
      names.storageKeys.monitorInfoThings
    );
    localStorage.clear();
    if (monitorInfoThings) {
      localStorage.setItem(
        names.storageKeys.monitorInfoThings,
        monitorInfoThings
      );
    }
  }

  public static redirectLogin(isExpired = false, isForceReload = true): void {
    const {
      session: { key, value }
    } = PARAMS_URL_REDIRECT;
    window.location.replace(names.paths.login);
    if (isForceReload) {
      window.location.reload();
    }
    if (isExpired) {
      window.location.replace(`${names.paths.login}?${key}=${value}`);
    }
  }

  public resetToken(): void {
    this.instanceUser.resetToken();
  }

  public setToken(
    accessToken: Interfaces.IAuthUsers['token'],
    refreshToken: Interfaces.IAuthUsers['token']
  ) {
    this.instanceUser
      .setTokens({
        accessToken,
        refreshToken
      })
      .saveToDefaults();
  }

  public updateUser(user: Interfaces.IAuthUsers['tokens']) {
    this.instanceUser
      .setTokens({
        accessToken: user.accessToken,
        refreshToken: user.refreshToken
      })
      .saveToDefaults();
  }

  async loadInfoData() {
    this.instanceUser.loadFromDefaults();
  }

  public static async isLoggedIn(): Promise<boolean> {
    try {
      const session = await Auth.currentSession();
      return session && session.isValid();
    } catch (error) {
      console.warn('Not found session valid');
      return false;
    }
  }

  public static async refreshUserToken(): Promise<void> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();

    cognitoUser.refreshSession(
      refreshToken,
      async (
        err: Error,
        { refreshToken: newRefreshToken, accessToken: newAccessToken }: any
      ) => {
        if (err) {
          AuthClient.closeSession();
          throw new Error(`Auth refresh ${err}`);
        }

        await this.getInstance().setToken(
          newAccessToken.jwtToken,
          newRefreshToken.token
        );
      }
    );
  }
}

export default AuthClient;
