import React, { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { useNavState } from '../../../../contexts/navigation';
import Analytics from '../../../../controllers/Analytics';
import { useGroupsThings } from '../../../../hooks/groups/useGroupsThings';
import ButtonFloat from '../../../../components/ButtonFloat';
import ModalCreateThing from '../../../../components/Modals/Create/Group/GThings';
import Layer from '../../../../components/Layer';
import {
  IGroupComponentProps,
  IBodyGroupThingCreate,
  IAnalyticsViewPage
} from '../../../../interfaces';
import names from '../../../../config/names';

import '../../../../scss/layout/GroupsDetail.scss';

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.groups.match,
  title: 'Group Detail Devices'
};

const Devices = ({ groupId }: IGroupComponentProps): JSX.Element => {
  const { groupPermission } = useNavState();
  const {
    isLoadingRequest,
    getMoreGroupsThings,
    sendCreate,
    refetchGroupThings,
    nextRequest: { isLoadMore },
    dataGroups: { listInfoThings }
  } = useGroupsThings({ groupId, analytics: ANALYTICS_PAGE_VIEW });

  const [isOpenModalReceptor, setIsOpenModalReceptor] =
    useState<boolean>(false);

  const handlerToggleModal = (): void => {
    setIsOpenModalReceptor(!isOpenModalReceptor);
  };

  const handlerModalAccept = async (
    bodyModal: IBodyGroupThingCreate
  ): Promise<void> => {
    await sendCreate(bodyModal);
    handlerToggleModal();

    Analytics.sendEventCustom({
      category: 'Group Detail',
      action: 'Add',
      label: 'Thing in Group'
    });
  };

  return (
    <Segment basic className='wrapper-devices-group'>
      <Layer
        section='GROUPS-THINGS'
        data={Object.values(listInfoThings)}
        loading={isLoadingRequest.fetch}
        loadMore={isLoadMore}
        onRefresh={refetchGroupThings}
        refetchLoadMore={getMoreGroupsThings}
      />
      {groupPermission === 'admin' && (
        <ButtonFloat
          isHidden={isLoadingRequest.fetch}
          onClick={handlerToggleModal}
        />
      )}
      <ModalCreateThing
        isOpen={isOpenModalReceptor}
        isLoading={isLoadingRequest.create}
        onAccept={handlerModalAccept}
        onCancel={handlerToggleModal}
      />
    </Segment>
  );
};

export default Devices;
