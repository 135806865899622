import React, { SyntheticEvent } from 'react';
import {
  Checkbox,
  Segment,
  Icon,
  SemanticICONS,
  Header
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import '../../scss/layout/Modal.scss';

export interface PropsComponentDarkMode {
  isDarkMode: boolean;
  onChangeTheme: (event: SyntheticEvent<HTMLElement>) => void;
}

const DarkMode = ({
  isDarkMode,
  onChangeTheme
}: PropsComponentDarkMode): JSX.Element => {
  const { t } = useTranslation('common');
  const iconMode: SemanticICONS = isDarkMode ? 'moon' : 'sun';
  return (
    <div className='wrapper-dark-mode' data-testid='darkMode-component'>
      <Segment basic fluid='true' className='segment-checkbox'>
        <Icon name={iconMode} inverted={isDarkMode} />
        <Header
          as='span'
          className='text-dark-mode'
          content={t('settings.darkMode')}
          inverted={isDarkMode}
        />
        <Checkbox
          toggle
          checked={isDarkMode}
          onClick={onChangeTheme}
          className='checkbox'
        />
      </Segment>
    </div>
  );
};

export default DarkMode;
