import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import { useStatusRequest } from '../../contexts/request';
import { countTotalThings } from '../../helpers';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';

interface IProps extends Interfaces.IAnalytics {
  id?: string;
  groupId?: string;
  isOpen?: boolean;
  mode?: 'things' | 'users';
}

interface IUseGroup {
  isLoading: {
    group: boolean;
    users: boolean;
    things: boolean;
  };
  dataGroup: {
    totalGroupThings: Interfaces.ITotalTracking;
    listInfo: Interfaces.IGroupThingItems[] | undefined;
    listUsers: Interfaces.IUsersItems[];
    listThings: Interfaces.IThingItems[];
    searchListUsers: Interfaces.IUsersItems[];
    searchListThings: Interfaces.IThingItems[];
  };
  updateSearchList(
    type: 'users' | 'things',
    newList: Interfaces.IThingItems[] | Interfaces.IUsersItems[]
  ): void;
}

interface IState {
  isLoading: IUseGroup['isLoading'];
  listUsers: Interfaces.IUsersItems[];
  listThings: Interfaces.IThingItems[];
  group: IUseGroup['dataGroup']['listInfo'];
  totalGroupThings: IUseGroup['dataGroup']['totalGroupThings'];
}

interface IRefListMode {
  things: string[];
  users: string[];
}

const { TOTAL_CONNECT_TRACKING } = Constants;

const LoggerInstance = ReporterLogger.getInstance();

const DEFAULT_VALUE_LIST_SELECTED: IRefListMode = {
  things: [],
  users: []
};

export const useGroup = ({
  id,
  mode,
  isOpen = false,
  groupId,
  analytics
}: IProps): IUseGroup => {
  const refListModeSelected = useRef<IRefListMode>(DEFAULT_VALUE_LIST_SELECTED);
  const { t } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();

  const [group, setGroup] = useState<IState['group']>(undefined);
  const [listUsers, setListUsers] = useState<IState['listUsers']>([]);
  const [listThings, setListThings] = useState<IState['listThings']>([]);
  const [searchListThings, setSearchListThings] = useState<
    IState['listThings']
  >([]);
  const [searchListUsers, setSearchListUsers] = useState<IState['listUsers']>(
    []
  );
  const [totalGroupThings, setTotalGroupThings] = useState<
    IState['totalGroupThings']
  >(TOTAL_CONNECT_TRACKING);
  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    group: true,
    users: true,
    things: true
  });

  const fetchGroup = async (): Promise<void> => {
    if (!isLoading.group) {
      setIsLoading({ ...isLoading, group: true });
    }
    try {
      const newId = id || '';
      const response = await BackendIoT.getGroup(newId);
      let newGroup;
      let newTotalGroupThingCount = TOTAL_CONNECT_TRACKING;
      if (Object.keys(response).length) {
        const { thingCount, connectedCount } = response;
        newGroup = response;
        newTotalGroupThingCount = countTotalThings(thingCount, connectedCount);
      }
      setGroup(newGroup);
      setTotalGroupThings(newTotalGroupThingCount);
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.group.fetch'),
        delay: 6000
      });
      LoggerInstance.error(
        'Failed to get the count of things in a group - useGroup - "fetchGroup"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, group: false });
    }
  };

  const fetchGroupThings = async (): Promise<void> => {
    try {
      const response = await BackendIoT.getThingsInGroup(groupId ?? '');
      let groupThings: string[] = [];
      if (response.things) {
        groupThings = response.things.map((item) => item.thing);
      }
      refListModeSelected.current.things = groupThings;
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.group.fetchThings'),
        delay: 6000
      });
      LoggerInstance.error(
        'Failed to get things in a group - useGroup - "fetchGroupThings"',
        error
      );
    }
  };

  const fetchGroupUsers = async (): Promise<void> => {
    try {
      const response = await BackendIoT.getUsersInGroup(groupId ?? '');
      let groupUsers: string[] = [];
      if (response.users) {
        groupUsers = response.users.map((item) => item.user);
      }
      refListModeSelected.current.users = groupUsers;
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.group.fetchUsers'),
        delay: 6000
      });
      LoggerInstance.error(
        'Failed to get users in a group - useGroup - "fetchGroupUsers"',
        error
      );
    }
  };

  const fetchUsers = async (): Promise<void> => {
    if (!isLoading.users) {
      setIsLoading({ ...isLoading, users: true });
    }
    try {
      const response = await BackendIoT.getUsers();
      if (Object.keys(response?.users).length > 0) {
        let filteredListUsers = response.users;
        const { users: refListUsers } = refListModeSelected.current;
        if (refListUsers) {
          filteredListUsers = filteredListUsers.filter(
            (item: Interfaces.IUsersItems) => !refListUsers.includes(item.id)
          );
        }

        setListUsers(filteredListUsers);
        setSearchListUsers(filteredListUsers);
      }
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.group.fetch'),
        delay: 6000
      });
      LoggerInstance.error(
        'Failed to get all users - useGroup - "fetchUsers"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, users: false });
    }
  };

  const fetchThings = async (): Promise<void> => {
    if (!isLoading.things) {
      setIsLoading({ ...isLoading, things: true });
    }
    try {
      const response = await BackendIoT.getThings();
      if (Object.keys(response?.things).length > 0) {
        let filteredListThings = response.things;
        const { things: refListThings } = refListModeSelected.current;
        if (refListThings) {
          filteredListThings = filteredListThings.filter(
            (item: Interfaces.IUsersItems) => !refListThings.includes(item.id)
          );
        }

        setListThings(filteredListThings);
        setSearchListThings(filteredListThings);
      }
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.group.fetch'),
        delay: 6000
      });
      LoggerInstance.error(
        'Failed to get all things - useGroup - "fetchThings"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, things: false });
    }
  };

  const updateSearchList = (
    type: 'users' | 'things',
    newList: Interfaces.IThingItems[] | Interfaces.IUsersItems[]
  ): void => {
    if (type !== 'users') {
      setSearchListThings(newList as Interfaces.IThingItems[]);
    } else {
      setSearchListUsers(newList as Interfaces.IUsersItems[]);
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
      console.log('Groups Monitor');
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchGroup();
    }

    if (isOpen) {
      if (mode !== 'things') {
        fetchGroupUsers();
        fetchUsers();
      } else {
        fetchGroupThings();
        fetchThings();
      }
    }
  }, [id, isOpen]);

  return {
    isLoading,
    updateSearchList,
    dataGroup: {
      listInfo: group,
      listUsers,
      listThings,
      searchListUsers,
      totalGroupThings,
      searchListThings
    }
  };
};

export default useGroup;
