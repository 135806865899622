import React, { SyntheticEvent, useState } from 'react';
import { Button, Header, InputProps, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import FormAssignLicense from '../../FormAssignLicense';
import { validateEmail, validateFullName } from '../../../helpers';

import '../../../scss/layout/Modal.scss';

export interface PropsComponentModalAssign {
  isOpen: boolean;
  isLoading: boolean;
  onAccept(
    email: string | null,
    name: string | null,
    lastName: string | null
  ): void;
  onCancel(e?: SyntheticEvent): void;
}

interface IState {
  error: {
    name: boolean;
    lastName: boolean;
    email: boolean;
  };
  dataForm: {
    name: string | null;
    lastName: string | null;
    email: string | null;
  };
}

const DEFAULT_ERROR_MESSAGE: IState['error'] = {
  name: false,
  lastName: false,
  email: false
};

const DEFAULT_DATA_FORM: IState['dataForm'] = {
  name: null,
  lastName: null,
  email: null
};

const Assign = ({
  isOpen,
  isLoading,
  onAccept,
  onCancel
}: PropsComponentModalAssign): JSX.Element => {
  const { t } = useTranslation();

  const [isError, setIsError] = useState<IState['error']>(
    DEFAULT_ERROR_MESSAGE
  );
  const [dataForm, setDataForm] =
    useState<IState['dataForm']>(DEFAULT_DATA_FORM);

  const showError = (
    key: 'name' | 'email' | 'lastName',
    value = true
  ): void => {
    setIsError({ ...isError, [key]: value });
  };

  const handlerOnchange = (
    e: SyntheticEvent,
    { value, name }: InputProps
  ): void => {
    e.preventDefault();
    setDataForm({ ...dataForm, [name]: value });
    showError(name, false);
  };

  const handlerOnAccept = (e: SyntheticEvent): void => {
    e.preventDefault();
    const { email, name, lastName } = dataForm;
    if (name && !validateFullName(name)) {
      showError('name');
      return;
    }

    if (lastName && !validateFullName(lastName)) {
      showError('lastName');
      return;
    }

    if (email && !validateEmail(email)) {
      showError('email');
      return;
    }

    onAccept(email, name, lastName);
  };

  const isDisabled = !dataForm.name || !dataForm.lastName || !dataForm.email;
  return (
    <Modal open={isOpen} size='small' className='wrapper-modal'>
      <Modal.Header>
        <Header icon='envelope' content={t('modals.titles.assign.license')} />
      </Modal.Header>
      <Modal.Content>
        <FormAssignLicense
          isLoading={isLoading}
          isError={isError}
          handlerOnchange={handlerOnchange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onCancel} content={t('actions.cancel')} />
        <Button
          positive
          loading={isLoading}
          disabled={isLoading || isDisabled}
          onClick={handlerOnAccept}
          content={t('actions.send')}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default Assign;
