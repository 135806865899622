import React, { SyntheticEvent, useState } from 'react';
import {
  Button,
  Header,
  Modal,
  Table,
  Checkbox,
  CheckboxProps,
  Form
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useLocation, Location } from 'react-router-dom';
import { useGroup } from '../../../../hooks/groups/useGroup';
import TableList from '../../../TableList';
import Empty from '../../../Empty';
import Search from '../../../Search';
import { SkeletonModalGroupUsers } from '../../../Skeletons';
import {
  OPTIONS_PERMISSIONS_USERS,
  HEADER_LIST_USERS_MODAL_ADD,
  ACCOUNT_PERMISSION,
  OPTIONS_SEARCH_USERS
} from '../../../../config/constants';
import { IBodyGroupUserCreate } from '../../../../interfaces';

import '../../../../scss/layout/Modal.scss';

export interface PropsComponentModalGUsers {
  isOpen: boolean;
  isLoading: boolean;
  onAccept(data: IBodyGroupUserCreate): void;
  onCancel(): void;
}

type UsersState = Omit<Location, 'state'> & {
  state: {
    groupName: string;
    groupId: string;
  };
};

const GUsers = ({
  isOpen,
  isLoading,
  onAccept,
  onCancel
}: PropsComponentModalGUsers): JSX.Element => {
  const { t } = useTranslation();
  const { state } = useLocation() as UsersState;
  const {
    isLoading: { users: isLoadingRequest },
    dataGroup: { listUsers, searchListUsers },
    updateSearchList
  } = useGroup({ mode: 'users', isOpen, groupId: state.groupId });

  const [selectId, setSelectId] = useState<CheckboxProps['value']>('');
  const [selectPermissions, setSelectPermissions] = useState<string[]>([]);

  const resetStates = (): void => {
    setSelectId('');
    setSelectPermissions([]);
    updateSearchList('users', listUsers);
  };

  const handlerSelectOption = (
    e: SyntheticEvent,
    { value }: CheckboxProps
  ): void => {
    e.preventDefault();
    if (selectId !== value) {
      setSelectId(value);
    }
  };

  const handlerCancel = (e: SyntheticEvent): void => {
    e.preventDefault();
    resetStates();
    onCancel();
  };

  const handlerAccept = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    const user = listUsers.find((item) => item.id === selectId);
    if (user && selectId) {
      const body = {
        groupName: state.groupName,
        user: user.id,
        userName: user.name,
        userEmail: user.email,
        permissions: selectPermissions
      };

      onAccept(body);
    }
  };

  const handlerChangeDropdown = (e: SyntheticEvent, { value }: any): void => {
    if (value !== selectPermissions && value) {
      setSelectPermissions([value.toString()]);
    }
  };

  const handleOnSearch = (
    e: SyntheticEvent,
    list: any
    // value: string
  ): void => {
    e.preventDefault();
    updateSearchList('users', list);
  };

  const renderPermissions = (): JSX.Element => {
    const TRANSLATE_OPTIONS_PERMISSIONS_USERS = Object.values(
      OPTIONS_PERMISSIONS_USERS
    ).map((item) => ({
      ...item,
      text: t(item.text as any)
    }));
    return (
      <Form autoComplete='off'>
        <Form.Select
          fluid
          onChange={handlerChangeDropdown}
          options={TRANSLATE_OPTIONS_PERMISSIONS_USERS}
          disabled={isLoading}
          label={t('modals.labels.users.permissions')}
          placeholder={t('modals.placeholders.users.selectPermission')}
        />
      </Form>
    );
  };

  const renderStatus = (): JSX.Element => {
    if (isLoadingRequest) {
      return <SkeletonModalGroupUsers />;
    }

    if (!listUsers.length) {
      return (
        <Empty
          iconName='address card'
          texts={{
            title: t('messages.groupsDetail.users.title')
          }}
        />
      );
    }

    if (!searchListUsers.length) {
      return (
        <Empty
          iconName='search'
          texts={{
            title: t('error.search.title'),
            subtitle: t('error.search.subtitle')
          }}
        />
      );
    }

    return (
      <>
        <TableList hideDivider stackable sortable padded='very'>
          <TableList.Header
            columns={HEADER_LIST_USERS_MODAL_ADD}
            list={listUsers}
          />
          <TableList.Body>
            {Object.values(searchListUsers).map(
              ({ id, name, email, adminLevel }, index) => (
                <Table.Row key={`users-${index.toString()}`}>
                  <Table.Cell collapsing>
                    <Checkbox
                      name={id}
                      value={id}
                      disabled={isLoading}
                      onChange={handlerSelectOption}
                      checked={selectId === id}
                    />
                  </Table.Cell>
                  <Table.Cell className='collapse-texts'>{name}</Table.Cell>
                  <Table.Cell className='collapse-texts'>{email}</Table.Cell>
                  <Table.Cell>{t(ACCOUNT_PERMISSION[adminLevel])}</Table.Cell>
                </Table.Row>
              )
            )}
          </TableList.Body>
        </TableList>
        {renderPermissions()}
      </>
    );
  };

  const isDisabled = selectId && selectPermissions?.toString().length;
  return (
    <Modal
      size='small'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modalGUsers-component'
    >
      <Modal.Header>
        <Header
          textAlign='center'
          as='h1'
          content={t('modals.titles.add.user')}
        />
      </Modal.Header>
      <Modal.Content>
        {!!listUsers.length && !isLoadingRequest && (
          <Search
            list={listUsers}
            defaultOptions='name'
            isDisable={isLoading}
            options={OPTIONS_SEARCH_USERS}
            onSearch={handleOnSearch}
          />
        )}
        {renderStatus()}
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={handlerCancel}
          content={t('actions.cancel')}
        />

        <Button
          positive
          content={t('actions.add')}
          disabled={!isDisabled || isLoading}
          loading={isLoading}
          onClick={handlerAccept}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default GUsers;
