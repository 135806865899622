import { DEFAULT_USER_SETTINGS } from '../config/constants';
import * as Interfaces from '../interfaces';
import * as Types from '../types';

export const initialState: Interfaces.IStateCtxSettings = {
  storageSettings: DEFAULT_USER_SETTINGS,
  isCompleteTutorial: false,
  isOpenReleaseNotes: false,
  isOpenTutorial: false,
  isOpenSettings: false
};

export const settingsReducer = (
  state: Interfaces.IStateCtxSettings,
  action: Types.TSettingsActions
): Interfaces.IStateCtxSettings => {
  switch (action.type) {
    case Types.EActionsReducerCtxSettings.setOpenReleaseNotes:
      return {
        ...state,
        isOpenReleaseNotes: action.isOpenReleaseNotes
      };
    case Types.EActionsReducerCtxSettings.setOpenTutorial:
      return {
        ...state,
        isOpenTutorial: action.isOpenTutorial
      };
    case Types.EActionsReducerCtxSettings.setOpenSettings:
      return {
        ...state,
        isOpenSettings: action.isOpenSettings
      };
    case Types.EActionsReducerCtxSettings.setValuesStorageSettings:
      return {
        ...state,
        storageSettings: action.storageSettings
      };
    case Types.EActionsReducerCtxSettings.setCompleteTutorial:
      return {
        ...state,
        isCompleteTutorial: action.isCompleteTutorial
      };
    default:
      return state;
  }
};
