import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  Header,
  DropdownProps,
  DropdownItemProps
} from 'semantic-ui-react';
import Markdown from 'markdown-to-jsx';
import { useSearchParams } from 'react-router-dom';
import { useThingSettings } from '../../../../hooks/things/useThingSettings';
import { getParam } from '../../../../helpers';
import * as Interfaces from '../../../../interfaces';
import { SkeletonBlock } from '../../../Skeletons';
import Empty from '../../../Empty';

interface PropsComponentModalCustomAudio {
  handleOnSelectNameAudio(
    nameSound: Interfaces.IModalControlInitialState['contentCustomAudio']
  ): void;
}

const CustomAudio = ({
  handleOnSelectNameAudio
}: PropsComponentModalCustomAudio): JSX.Element => {
  const { t: translation } = useTranslation();
  const [searchParams] = useSearchParams();

  const thingId = getParam('id', searchParams) || '';

  const { isLoadingRequest, dataThing } = useThingSettings(thingId, true);

  const handleOnChangeListCustomAudio = (
    e: SyntheticEvent,
    { value }: DropdownProps
  ): void => {
    e.preventDefault();
    handleOnSelectNameAudio({ name: value as string });
  };

  const renderDropdownCustomAudio = (): JSX.Element => {
    const { listInfoSettings } = dataThing;

    const parseElementAudio = (
      nameCustomAudio: string,
      nameFileCustomAudio: string
    ): JSX.Element => (
      <Header
        as='h4'
        icon='bullhorn icon'
        content={nameCustomAudio}
        subheader={
          <Markdown>
            {translation('control.customAudio.form.nameAudio', {
              name: nameFileCustomAudio
            })}
          </Markdown>
        }
      />
    );

    let optionListCustomAudio: DropdownItemProps[] = [];

    if (isLoadingRequest.fetch) {
      return <SkeletonBlock styled={{ maxWidth: '100%', height: '60px' }} />;
    }

    if (
      (!isLoadingRequest.fetch && !listInfoSettings.customAudio) ||
      !listInfoSettings.customAudio?.files
    ) {
      return (
        <Empty
          iconName='remove circle'
          texts={{
            title: translation('control.customAudio.form.errorNoAudio')
          }}
        />
      );
    }

    const { customAudio } = listInfoSettings;

    if (customAudio?.files && Object.keys(customAudio?.files).length) {
      optionListCustomAudio = Object.entries(customAudio?.files).map(
        ([key, value], index) => ({
          key: `${key}-${index.toString()}`,
          text: key,
          value: key,
          content: parseElementAudio(key, value)
        })
      );
    }

    return (
      <>
        <Header
          as='h3'
          content={translation('control.customAudio.form.title')}
        />
        <Dropdown
          clearable
          fluid
          selection
          options={optionListCustomAudio}
          onChange={handleOnChangeListCustomAudio}
          disabled={isLoadingRequest.fetch}
          loading={isLoadingRequest.fetch}
          placeholder={translation('control.customAudio.form.placeholder')}
        />
      </>
    );
  };

  return (
    <div className='container-custom-audio'>{renderDropdownCustomAudio()}</div>
  );
};

export default CustomAudio;
