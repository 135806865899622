import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BackendIoT from '../controllers/Backend/IoT';
import ReporterLogger from '../controllers/ReporterLogger';
import { useStatusRequest } from '../contexts/request';
import ModelCommandScheduled from '../models/CommandScheduled';
import { TEMP_MILLISECONDS_DURATION_DRILL } from '../config/constants';
import * as Interfaces from '../interfaces';
import * as Types from '../types';

type TViewModalName = 'commands' | 'config' | 'confirm';

type TModalIsOpen = {
  [key in TViewModalName]: boolean;
};

interface IManagerCommand {
  scheduled?: Types.TScheduledType;
  message: Interfaces.IModalControlDataFinish['message'];
  scheduleAt?: string;
}

interface IBodyCommandInfo {
  message: Interfaces.IModalControlDataFinish['message'];
}

interface IBodyCommand {
  command: Types.TCommands;
  data: Types.TDataCommandBody;
}

interface CheckGroupId extends Interfaces.IControlsCommandsList {
  isValidId: boolean;
}

interface IUseCommands {
  isLoadingRequest: {
    fetch: boolean;
    create: boolean;
    delete: boolean;
  };
  isOpenModal: TModalIsOpen;
  processRequest: {
    isSend: boolean;
    message: string;
  };
  checkGroupId: (data: CheckGroupId) => void;
  selectCommandOption: (data: Interfaces.IControlsCommandsList) => void;
  managerCommandFinish: (body: IManagerCommand) => Promise<void>;
  toggleOpenModal: (type: TViewModalName) => void;
  actionCommandsModal: Interfaces.IControlsCommandsList['section'];
  sendCommand: Interfaces.IControlsCommandsList['command'] | null;
  sendDeleteCommandScheduled: (commandId: string) => Promise<void>;
  nameButton: string;
  dataScheduledCommands: {
    listParsed: Interfaces.IParseCommandSchedule[];
  };
  refetchScheduleCommands: () => Promise<void>;
  getMoreScheduleCommands: () => Promise<void>;
  nextRequest: {
    isLoadMore: boolean;
    lastItem?: Interfaces.ILastItemFeed;
  };
  sendCreate: (body: Interfaces.IBodyGroupCommandCreate) => Promise<void>;
}

interface IState {
  nameButton: string;
  query: Interfaces.IQueryScheduleCommands;
  isOpenModal: TModalIsOpen;
  isLoading: IUseCommands['isLoadingRequest'];
  scheduledCommands: IUseCommands['dataScheduledCommands']['listParsed'];
  processRequest: IUseCommands['processRequest'];
  sendCommand: Interfaces.IControlsCommandsList['command'] | null;
  actionCommandsModal: Interfaces.IControlsCommandsList['section'];
  nextRequest: IUseCommands['nextRequest'];
}

interface IUseCommandsProps {
  activeFetchScheduleCommands?: boolean;
  groupId?: string;
}

const LoggerInstance = ReporterLogger.getInstance();

const DEFAULT_STATE_MODAL: TModalIsOpen = {
  config: false,
  commands: false,
  confirm: false
};

const DEFAULT_VALUE_PROCESS_REQUEST: IState['processRequest'] = {
  isSend: false,
  message: ''
};

const DEFAULT_QUERY: IState['query'] = { limit: 65 };

const DEFAULT_VALUE_NEXT_REQUEST: IState['nextRequest'] = {
  isLoadMore: false
};

const parseDataItemCommandScheduled = (
  rawData: Interfaces.IScheduledCommandsItems
) => new ModelCommandScheduled().init(rawData).parseToDataDisplay();

const orderItemsCommandScheduled = (
  orderList: Interfaces.IParseCommandSchedule[]
) => ModelCommandScheduled.orderElementsBySendingTime(orderList);

export const useCommands = ({
  groupId,
  activeFetchScheduleCommands = false
}: IUseCommandsProps): IUseCommands => {
  const { t } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();

  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });

  const [listScheduledCommands, setListScheduledCommands] = useState<
    IState['scheduledCommands']
  >([]);
  const [query, setQuery] = useState<IState['query']>(DEFAULT_QUERY);
  const [nextRequest, setNextRequest] = useState<IState['nextRequest']>(
    DEFAULT_VALUE_NEXT_REQUEST
  );
  const [isOpenModal, setIsOpenModal] =
    useState<IState['isOpenModal']>(DEFAULT_STATE_MODAL);
  const [nameButton, setNameButton] = useState<IState['nameButton']>('');
  const [actionCommandsModal, setActionCommandsModal] =
    useState<IState['actionCommandsModal']>('initial');
  const [sendCommand, setSendCommand] = useState<IState['sendCommand']>(null);
  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    fetch: false,
    create: false,
    delete: false
  });
  const [processRequest, setProcessRequest] = useState<
    IState['processRequest']
  >(DEFAULT_VALUE_PROCESS_REQUEST);

  const fetchScheduleCommands = async (isRefetch = false): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }

    let newListScheduledCommands: Interfaces.IParseCommandSchedule[] = [];
    let newNextRequest = DEFAULT_VALUE_NEXT_REQUEST;

    try {
      // TODO: REFACTOR THIS
      const { commands, lastItem } =
        await BackendIoT.getScheduledCommandsInGroup(groupId || '', query);

      if (commands.length) {
        newListScheduledCommands = commands.map((command) =>
          parseDataItemCommandScheduled(command)
        );

        if (nextRequest.isLoadMore && !isRefetch) {
          newListScheduledCommands = [
            ...listScheduledCommands,
            ...newListScheduledCommands
          ];
        }

        if (lastItem) {
          newNextRequest = {
            isLoadMore: !!newListScheduledCommands.length,
            lastItem
          };
        }
      }

      setNextRequest(newNextRequest);
      setListScheduledCommands(
        orderItemsCommandScheduled(newListScheduledCommands)
      );
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.command.fetch'),
        message: t('error.requests.commands.fetch'),
        delay: 6000
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed fetch commands - useCommands - "fetchScheduleCommands"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  const refetchScheduleCommands = async (): Promise<void> => {
    if (query.lastItem) {
      setQuery(DEFAULT_QUERY);
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
    } else {
      await fetchScheduleCommands(true);
    }
  };

  const sendCreate = async (
    body: Interfaces.IBodyGroupCommandCreate
  ): Promise<void> => {
    let statusRequest: IState['processRequest'] | null = null;
    setIsLoading({ ...isLoading, create: true });
    try {
      if (!groupId) {
        statusRequest = {
          isSend: false,
          message: 'Empty group id'
        };
        throw String('Empty group id');
      }

      const response = await BackendIoT.createCommand(groupId, body);
      if (response.result) {
        messageResponseRef.current = {
          type: 'success',
          action: t('actions.requests.command.send'),
          message: t('success.requests.commands.send'),
          delay: 6000
        };
        statusRequest = {
          isSend: true,
          message: ''
        };
      }
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.command.send'),
        message: t('error.requests.commands.send'),
        delay: 6000
      };
      statusRequest = {
        isSend: false,
        message: t('error.requests.commands.send')
      };
      LoggerInstance.error(
        'Failed create command - useCommands - "sendCreate"',
        error
      );
    } finally {
      setProcessRequest(statusRequest || DEFAULT_VALUE_PROCESS_REQUEST);
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, create: false });
    }
  };

  const sendDeleteCommandScheduled = async (
    commandId: string
  ): Promise<void> => {
    setIsLoading({ ...isLoading, delete: true });
    try {
      await BackendIoT.deleteCommand(commandId);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.command.delete'),
        message: t('success.requests.commands.delete'),
        delay: 6000
      };
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.command.delete'),
        message: t('error.requests.commands.delete'),
        delay: 6000
      };
      LoggerInstance.error(
        'Failed delete command - useCommands - "sendDelete"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, delete: false });
      refetchScheduleCommands();
    }
  };

  const toggleOpenModal = (type: TViewModalName): void => {
    setIsOpenModal({ ...DEFAULT_STATE_MODAL, [type]: !isOpenModal[type] });
  };

  const bodyCommand = ({ message }: IBodyCommandInfo): IBodyCommand => {
    if (sendCommand) {
      let data: Types.TDataCommandBody = null;
      switch (sendCommand) {
        // TODO: VALIDATION JUST IN GADGET CASE
        case 'drill-start': {
          data = {
            userTriggered: true,
            duration: TEMP_MILLISECONDS_DURATION_DRILL
          };
          break;
        }
        case 'test-alert': {
          data =
            nameButton !== 'silentNotification'
              ? { sound: true, relays: true }
              : null;
          break;
        }
        case 'custom-alert': {
          data = message as Interfaces.ICustomAlertMessageItems;
          break;
        }
        case 'custom-audio': {
          data = message as Interfaces.ICustomAudioName;
          break;
        }
        default:
          data = null;
          break;
      }

      return {
        command: sendCommand,
        data
      };
    }

    return {
      command: 'test-alert',
      data: null
    };
  };

  const managerCommandFinish = async ({
    message,
    scheduled,
    scheduleAt
  }: IManagerCommand): Promise<void> => {
    const bodyParseCommand = bodyCommand({ message });
    const body = {
      ...bodyParseCommand,
      scheduleAt
    };

    if (scheduled !== 'scheduled') {
      delete body.scheduleAt;
    }

    await sendCreate(body);

    if (scheduled === 'scheduled') {
      fetchScheduleCommands();
    }
  };

  const selectCommandOption = ({
    name,
    section,
    command
  }: Interfaces.IControlsCommandsList): void => {
    setNameButton(name);
    if (name !== 'configuration') {
      setActionCommandsModal(section);
      toggleOpenModal('commands');
      setSendCommand(command);
    } else {
      toggleOpenModal('config');
    }
  };

  const checkGroupId = (data: Interfaces.IControlsCommandsList): void => {
    const { isValidId } = data;

    if (!isValidId) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.command.send'),
        message: t('error.requests.commands.thingId'),
        delay: 6000
      };
      UpdateMessageResponse(messageResponseRef.current);
      return;
    }
    selectCommandOption(data);
  };

  useEffect(() => {
    if (activeFetchScheduleCommands) {
      fetchScheduleCommands();
    }
  }, [groupId, query, activeFetchScheduleCommands]);

  const getMoreScheduleCommands = async (): Promise<void> => {
    const paramsQuery = {
      ...query,
      lastItem: JSON.stringify(nextRequest.lastItem)
    };
    setQuery(paramsQuery);
  };

  return {
    isLoadingRequest: isLoading,
    isOpenModal,
    checkGroupId,
    processRequest,
    toggleOpenModal,
    selectCommandOption,
    managerCommandFinish,
    actionCommandsModal,
    sendCreate,
    sendCommand,
    sendDeleteCommandScheduled,
    nextRequest,
    refetchScheduleCommands,
    getMoreScheduleCommands,
    nameButton,
    dataScheduledCommands: {
      listParsed: listScheduledCommands
    }
  };
};

export default useCommands;
