import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../contexts/auth';
import { useDashboard } from '../../hooks/useDashboard';
import Layout from '../../components/Layout';
import LicensesStatus from './LicensesStatus';
import ThingsCount from './ThingsCount';
import GroupsList from './GroupsList';
import UsersList from './UsersList';
import { IAnalyticsViewPage, IPropsRoutes } from '../../interfaces';
import names from '../../config/names';

import '../../scss/layout/Dashboard.scss';

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.dashboard,
  title: 'Dashboard'
};

const Dashboard = ({ isNavbar }: IPropsRoutes): JSX.Element => {
  const { t } = useTranslation();
  const { adminLevel } = useAuthState();

  const {
    isLoading,
    dataCustomer: { totalThings, listLicenses }
  } = useDashboard({ analytics: ANALYTICS_PAGE_VIEW });

  return (
    <Layout title={t('sections.dashboard')} showNavbar={isNavbar}>
      <Grid className='wrapper-dashboard' data-testid='dashboard-route'>
        <Grid.Row className=''>
          <Grid.Column computer={8} tablet={8} mobile={16}>
            {adminLevel !== 'user' && (
              <LicensesStatus
                isLoading={isLoading}
                listLicenses={listLicenses}
              />
            )}
            <ThingsCount isLoading={isLoading} totalThings={totalThings} />
          </Grid.Column>
          <Grid.Column computer={8} tablet={8} mobile={16}>
            {adminLevel !== 'user' && <UsersList />}
            <GroupsList />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
};

export default Dashboard;
