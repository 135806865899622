import api from '../../config/api';
import AuthClient from '../../models/AuthClient';
import {
  IHeaders,
  IEndpoint,
  IQueryParamsRequest,
  IQueryParamsRequestAny
} from '../../interfaces';
import { TRoot } from '../../types';

class ApiRequest {
  private static instance: ApiRequest = new ApiRequest();

  private AuthClient: AuthClient;

  private root: TRoot;

  private headers: IHeaders;

  constructor() {
    if (ApiRequest.instance) {
      throw new Error(
        'Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.'
      );
    }
    ApiRequest.instance = this;

    this.root = api.skyAlert.staging.root;
    this.headers = api.skyAlert.staging.headers;

    this.AuthClient = AuthClient.getInstance();
    this.AuthClient.loadInfoData();
  }

  public static getInstance(): ApiRequest {
    return ApiRequest.instance;
  }

  private async sendRequest<T>(
    url: string,
    method: string,
    body?: undefined,
    headers: IHeaders | any = this.headers,
    multipart = false,
    isResponseJustText = false,
    isRequiredAuth = true
  ): Promise<T | any> {
    try {
      const accessToken = this.AuthClient.getAccessToken();
      const headersParse = new Headers(headers);

      if (multipart) {
        headersParse.delete('Content-Type');
      }

      if (accessToken && isRequiredAuth) {
        headersParse.append('Authorization', accessToken);
      }

      const response = await fetch(url, {
        method,
        headers: headersParse,
        body: body && !multipart ? JSON.stringify(body) : undefined
      });

      if (response.status === api.skyAlert.response.refresh) {
        try {
          await AuthClient.refreshUserToken();
          return await this.sendRequest(url, method, body);
        } catch (error) {
          AuthClient.closeSession(true);
        }
      }
      if (response.status === api.skyAlert.response.forbidden) {
        const messageForbidden = { status: response.status };
        throw messageForbidden;
      } else if (response.status < 200 || response.status > 299) {
        const messageNotOk = { status: response.status };
        throw messageNotOk;
      }

      if (isResponseJustText) {
        const responseBase = await response.text();
        return responseBase;
      }

      const responseBody = await response.json();
      return responseBody;
    } catch (error) {
      console.error('Error in request', error);
      throw error;
    }
  }

  public buildAndFetch(endpoint: IEndpoint, body?: any | undefined) {
    const { name, method } = endpoint;
    const url = this.root + name;
    return this.sendRequest(url, method, body);
  }

  public buildAndFetchJustText(endpoint: IEndpoint) {
    const { name, method } = endpoint;
    const url = this.root + name;
    const isBase64 = true;
    return this.sendRequest(url, method, undefined, undefined, false, isBase64);
  }

  public async buildAndFetchList(
    endpoint: IEndpoint,
    extra: IQueryParamsRequestAny['query'] = {}
  ) {
    const objToQueryParams = (params: IQueryParamsRequest['query']): string =>
      Object.keys(params)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        )
        .join('&');

    const { name, method } = endpoint;
    const params = {
      ...extra
    };
    const url = `${this.root}${name}?${objToQueryParams(params)}`;
    return this.sendRequest(url, method);
  }

  public buildAndFetchApiRedSkyAlert(endpoint: IEndpoint) {
    const apiRedSkyAlert = api.redSkyAlert.staging;
    const { root } = apiRedSkyAlert;
    const { name, method } = endpoint;
    const url = root + name;

    return this.sendRequest(url, method, undefined, {}, false, false, false);
  }
}

export default ApiRequest;
