import * as Interfaces from '../interfaces';

// *** TYPES *** //
export type TRoot = string | undefined;

export type TActionsRequest = 'GET' | 'POST' | 'PATCH' | 'DELETE';

export type TAssignationStatusTypes = 'assigned' | 'revoked';

export type TPermissionTypes = 'read' | 'write' | 'admin';

export type TReportType = 'monthly' | 'weekly';

export type TSharedLicenses = 'shared' | 'available';

export type TLicenseStatus = 'available' | 'assigned' | 'revoked' | 'expired';

// export type TModalControlSection = 'customCommands' | 'scheduled' | 'status';

export type TControlViews = 'groups' | 'things';

export type TConnectionStatus = 'connected' | 'disconnected';

export type TConnectionStatusLog = 'CONNECTED' | 'DISCONNECTED';

export type TUserStatuses = 'active' | 'deleted';

export type TActionsModal = 'edit' | 'create';

export type TModeTheme = 'light' | 'dark';

export type TLanguage = 'es' | 'en';

export type TSort = 'ascending' | 'descending';

export type TScheduledType = 'now' | 'scheduled';

export type TViewRecords = 'CARDS' | 'TABLE' | 'LIST' | 'NONE';

export type TEntity = 'LOG' | 'ACKNOWLEDGEMENT';

export type TFilterReceptorMonitorThings =
  | 'all'
  | 'gadget'
  | 'desk'
  | 'epicenter';

export type TTypeDateMonitor = 'today' | 'yesterday' | 'specific';

export type TSelectorDaysHistoryConnection =
  | 'now'
  | 'threeDaysAgo'
  | 'sevenDaysAgo';

export type TStatusDamageReport =
  | 'none'
  | 'people'
  | 'inventory'
  | 'property'
  | 'other';

export type TModalControlViews =
  | 'initial'
  | 'customAlert'
  | 'customAudio'
  | 'confirm'
  | 'result'
  | 'scheduled';

export type TUserTypes =
  | 'owner'
  | 'admin'
  | 'user'
  | 'licensee'
  | 'SkyAlertLicensee';

export type TLayerTypes =
  | 'CLIENTS'
  | 'GROUPS'
  | 'THINGS'
  | 'USERS'
  | 'FEEDACK'
  | 'SUBGROUPS'
  | 'LICENSES'
  | 'CONTROL-THINGS'
  | 'MONITOR-THINGS-ALL'
  | 'MONITOR-THINGS'
  | 'GROUPS-THINGS'
  | 'GROUPS-USERS'
  | 'GROUPS-FEEDACK'
  | 'DAMAGE-REPORT'
  | 'DEFAULT';

export type TReceptorTypes =
  | 'epicenter'
  | 'gadget'
  | 'desk'
  | 'desk-plus'
  | 'saas'
  | 'gadget-mini'
  | 'gadget-integrated'
  | 'epicenter-pro';

export type TAuthorizationTypes =
  | 'earthquakes'
  | 'monitor'
  | 'customCommands'
  | 'internalMessaging'
  | 'storms'
  | 'cyclones'
  | 'tornadoes'
  | 'hurricanes'
  | 'wildfires'
  | 'tsunami'
  | 'volcanoes';

export type TLicenseListDetailFields =
  | 'targetReceptor'
  | 'id'
  | 'status'
  | 'validUntil'
  | 'createdAt';

// TODO: use @SkyAlertIot Types
export type TCommands =
  | 'update'
  | 'stop-app'
  | 'stop-actions'
  | 'test-alert'
  | 'custom-alert'
  | 'custom-audio'
  | 'restart-app'
  | 'reboot'
  | 'system-restart'
  | 'system-update'
  | 'silent-notification'
  | 'set-config'
  | 'set-config-delta'
  | 'run-script'
  | 'notify-update-available'
  | 'drill-start'
  | 'drill-stop'
  | 'earthquake'
  | 'update-location'
  | 'schedule-local-drill';

export type TReportTotals =
  | 'registered'
  | 'received'
  | 'noDamage'
  | 'damage'
  | 'merchandise'
  | 'structure'
  | 'staff';

export type TMonths =
  | 'january'
  | 'february'
  | 'march'
  | 'april'
  | 'may'
  | 'june'
  | 'july'
  | 'august'
  | 'september'
  | 'october'
  | 'november'
  | 'december';

export type EventsReportDamage = 'earthquake' | 'cyclone' | 'volcano';

export type TEventMonitorThings = TCommands | TNameLogsEvent;

export type TCommandsEvent = {
  [key in TCommands]: Interfaces.ICommandLogNameDescription;
};

export type TNameLogsEvent =
  | TConnectionStatusLog
  | 'LOCATION_UPDATED'
  | 'UNKNOWN';

export type TLogsEvent = {
  [key in TNameLogsEvent]: Interfaces.ICommandLogNameDescription;
};

export type TDataCommandTestSound = {
  sound: boolean;
  relays?: boolean;
};

export type TDataCommandBody =
  | Interfaces.ICustomAlertMessageItems
  | TDataCommandTestSound
  | Interfaces.ICustomAudioName
  | Interfaces.ILocationThing
  | { userTriggered: boolean; duration?: number }
  | null;

// *** CLIENTS *** //

export type TClientActions =
  | { type: EClientReducer.request }
  | { type: EClientReducer.select }
  | { type: EClientReducer.unselect }
  | Interfaces.IClientReducerFetch
  | Interfaces.IClientReducerError;

export type TFieldClients =
  | 'id'
  | 'customerId'
  | 'skyalertId'
  | 'name'
  | 'commercialId'
  | 'email';

// *** TYPES PARAMS ROUTES ** //

export type TParamsLicenses = {
  id: string;
  status: TLicenseStatus;
};

export type TParamsThings = {
  type: TReceptorTypes;
};

export type TAccountPermission = {
  [key in TUserTypes]: any;
};

export type TSteps = {
  [key in TUserTypes]: string[];
};

// *** TYPES ACTIONS ** //

export interface EActionsLoginReducerTypeLogin {
  type: EActionsLoginReducer.login;
  payload: Interfaces.IStateAuthLogin | any;
}

export interface EActionsLoginReducerTypeError {
  type: EActionsLoginReducer.error;
  error: string | null;
}

export interface EActionsReducerTypeGroup {
  type: EActionsReducer.group;
  payload: { [key: string]: string };
}

export interface EActionsReducerTypeLicensee {
  type: EActionsReducer.licensee;
  payload: string | null;
}

export interface EActionsLoginReducerTypeNewUser {
  type: EActionsLoginReducer.newUser;
  isNewUser: boolean;
}

export type TActionClearReducerControl = {
  type: EActionsReducerControl.clear;
};

export type TActionViewReducerControl = {
  type: EActionsReducerControl.setView;
  view: Interfaces.IModalControlInitialState['view'];
};

export type TActionDateCalendarReducerControl = {
  type: EActionsReducerControl.setDateCalendar;
  date: Interfaces.IModalControlInitialState['date'];
};

export type TActionTypeScheduleReducerControl = {
  type: EActionsReducerControl.setTypeSchedule;
  scheduled: Interfaces.IModalControlInitialState['scheduled'];
};

export type TActionCustomAlertReducerControl = {
  type: EActionsReducerControl.setContentCustomAlert;
  contentCustomAlert: Interfaces.IModalControlInitialState['contentCustomAlert'];
};

export type TActionCustomAudioReducerControl = {
  type: EActionsReducerControl.setContentCustomAudio;
  contentCustomAudio: Interfaces.IModalControlInitialState['contentCustomAudio'];
};

export type TActionTypeReducerControl = {
  type: EActionsReducerControl.setIsInvalidTime;
  isInvalidTime: Interfaces.IModalControlInitialState['isInvalidTime'];
};

export type TActionStateReleaseNotes = {
  type: EActionsReducerCtxSettings.setOpenReleaseNotes;
  isOpenReleaseNotes: Interfaces.IStateCtxSettings['isOpenReleaseNotes'];
};

export type TActionStateTutorial = {
  type: EActionsReducerCtxSettings.setOpenTutorial;
  isOpenTutorial: Interfaces.IStateCtxSettings['isOpenTutorial'];
};

export type TActionStateSettings = {
  type: EActionsReducerCtxSettings.setOpenSettings;
  isOpenSettings: Interfaces.IStateCtxSettings['isOpenSettings'];
};

export type TActionStateSettingsStorage = {
  type: EActionsReducerCtxSettings.setValuesStorageSettings;
  storageSettings: Interfaces.IStateCtxSettings['storageSettings'];
};

export type TActionStateCompleteTutorial = {
  type: EActionsReducerCtxSettings.setCompleteTutorial;
  isCompleteTutorial: Interfaces.IStateCtxSettings['isCompleteTutorial'];
};

export type TActionsTypeReducerControl =
  | TActionViewReducerControl
  | TActionClearReducerControl
  | TActionDateCalendarReducerControl
  | TActionTypeScheduleReducerControl
  | TActionCustomAlertReducerControl
  | TActionCustomAudioReducerControl
  | TActionTypeReducerControl;

export type TAuthActions =
  | { type: EActionsLoginReducer.request }
  | EActionsLoginReducerTypeLogin
  | { type: EActionsLoginReducer.logout; action: EActionsLoginReducer }
  | EActionsLoginReducerTypeError
  | EActionsReducerTypeGroup
  | EActionsReducerTypeLicensee
  | EActionsLoginReducerTypeNewUser;

export type TSettingsActions =
  | TActionStateReleaseNotes
  | TActionStateTutorial
  | TActionStateSettings
  | TActionStateCompleteTutorial
  | TActionStateSettingsStorage;

// *** ENUMS *** //
export enum EActionsLoginReducer {
  request = 'LOGIN_REQUEST',
  login = 'LOGIN_SUCCESS',
  error = 'LOGIN_ERROR',
  logout = 'LOGOUT',
  newUser = 'CHECK_NEW_USER'
}

export enum EActionsReducer {
  group = 'UPDATE_GROUPS',
  licensee = 'UPDATE_LICENSEE'
}

export enum EViews {
  LOGIN,
  FORGOT_PASSWORD,
  CHANGE_DEFAULT_PASSWORD,
  REQUEST_CODE
}

export enum EClientReducer {
  request = 'CLIENT_REQUEST',
  fetch = 'CLIENT_SUCCESS',
  select = 'CLIENT_SELECT',
  unselect = 'CLIENT_UNSELECT',
  notFound = 'CLIENT_NOT_FOUND',
  error = 'CLIENT_ERROR'
}

export enum EActionsReducerControl {
  clear = 'CLEAR',
  setTypeSchedule = 'SET_TYPE_SCHEDULE',
  setView = 'SET_VIEW',
  setDateCalendar = 'SET_DATE_CALENDAR',
  setContentCustomAlert = 'SET_VALUE_CUSTOM_ALERT',
  setContentCustomAudio = 'SET_VALUE_CUSTOM_SOUND',
  setErrorCustomAudio = 'SET_ERROR_CUSTOM_SOUND',
  setIsInvalidTime = 'SET_VALUE_INVALID_TIME'
}

export enum EViewsControl {
  initial = 'initial',
  customAlert = 'customAlert',
  customAudio = 'customAudio',
  confirm = 'confirm',
  result = 'result'
}

export enum EActionsReducerCtxSettings {
  setOpenSettings = 'SET_OPEN_MODAL_SETTINGS',
  setOpenReleaseNotes = 'SET_OPEN_MODAL_RELEASE_NOTES',
  setOpenTutorial = 'SET_OPEN_MODAL_TUTORIAL',
  setCompleteTutorial = 'SET_FINISH_TUTORIAL',
  setValuesStorageSettings = 'SET_VALUES_STORAGE_SETTINGS'
}

export enum EViewsTutorial {
  initial = 'initial',
  safety = 'safety',
  assignLicensee = 'assignLicensee',
  final = 'final'
}

export enum ESelectorDaysHistoryConnection {
  now = 'now',
  threeDays = 'threeDaysAgo',
  sevenDays = 'sevenDaysAgo'
}
