import React from 'react';
import { Segment, Grid, Statistic, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Navigate, useParams } from 'react-router-dom';
import { useSettings } from '../../contexts/settings';
import { useNavState } from '../../contexts/navigation';
import useAuthClient from '../../hooks/useAuthClient';
import useConnectionStatus from '../../hooks/useConnectionStatus';
import useThingTypeValidation from '../../hooks/things/useThingTypeValidation';
import { useThingsByType } from '../../hooks/things/useThingsByType';
import Layout from '../../components/Layout';
import Layer from '../../components/Layer';
import BreadcrumbCustom from '../../components/Breadcrumb';
import ModalCoords from '../../components/Modals/Coords';
import names from '../../config/names';
import * as Helpers from '../../helpers';
import * as Constants from '../../config/constants';
import * as Types from '../../types';
import * as Interfaces from '../../interfaces';

import '../../scss/layout/ThingsType.scss';

interface IState {
  list: Interfaces.IThingsTypeItem[];
  type: Types.TParamsThings;
}

type TypeDevices = 'epicenter' | 'gadget' | 'desk' | 'saas';

const INFO_DISCONNECT = false;

const TypeThings = ({ isNavbar }: Interfaces.IPropsRoutes) => {
  const { t: translation } = useTranslation();

  const { type } = useParams<IState['type']>() as IState['type'];
  const { isMobileBreakpoint } = useNavState();
  const { customerId } = useAuthClient();

  const thingType = type !== 'gadget-mini' ? type : 'gadget';
  const isDesk = ['desk', 'desk-plus'].includes(thingType);

  const parseThingType = Helpers.receptorType(type);

  const ANALYTICS_PAGE_VIEW: Interfaces.IAnalyticsViewPage = {
    page: names.paths.things[thingType as TypeDevices],
    title: `Things ${thingType}`
  };
  const { theme } = useSettings();
  const {
    isLoading,
    refetchThings,
    getMoreThings,
    nextRequest: { isLoadMore },
    dataThings: { listInfoByType, listFiltered, totalConnections }
  } = useThingsByType({ type, analytics: ANALYTICS_PAGE_VIEW });

  const { thingsConnected } = useConnectionStatus({
    query: { customerId, receptor: parseThingType as Types.TReceptorTypes },
    createIntervalRequest: !isDesk,
    isInitialRequest: !isDesk
  });

  const {
    coords,
    addressLicense,
    isOpenModalCoords,
    thingsUpdateConnection,
    handleOnCancelModalCoords,
    handleSearchData,
    handleSetModalParams
  } = useThingTypeValidation({
    query: {
      thingsConnected,
      listFilteredThings: listFiltered,
      isLoadingFetch: isLoading.fetch,
      isDesk
    }
  });

  const renderInfoStatic = (): JSX.Element => {
    if (!listInfoByType.length || !listFiltered.length) {
      return <div />;
    }
    return (
      <Grid relaxed stackable padded columns='2'>
        <Grid.Row textAlign='center'>
          {[null, 'disconnected', 'registered', null].map((item, index) => {
            if (item) {
              const isDisconnected = item !== 'registered';
              const values = isDisconnected
                ? totalConnections.registered
                : totalConnections.disconnected;
              const color = isDisconnected ? 'green' : 'red';
              return (
                <Grid.Column width={4} key={`col-${index + 1}`}>
                  <Statistic
                    horizontal={isMobileBreakpoint}
                    inverted={theme !== 'light'}
                    size='large'
                  >
                    <Statistic.Value>{values}</Statistic.Value>
                    <Statistic.Label>
                      <Icon name='circle' size='small' color={color} />
                      {translation(
                        `things.type.${
                          isDisconnected ? 'connected' : 'disconnected'
                        }`,
                        {
                          count: Number.isNaN(values) ? +values : 0
                        }
                      )}
                    </Statistic.Label>
                  </Statistic>
                </Grid.Column>
              );
            }
            return <Grid.Column width={4} key={`col-${index + 1}`} />;
          })}
        </Grid.Row>
      </Grid>
    );
  };

  const lowerCaseType = i18next.format(
    translation(Constants.THINGS_TYPES_LIST[type].text as any),
    'lowercase'
  );

  if (!Object.keys(Constants.THINGS_TYPES_LIST).includes(type)) {
    return <Navigate to={names.paths.things.main} />;
  }
  return (
    <Layout
      title={`${translation('sections.things')} ${lowerCaseType}`}
      showNavbar={isNavbar}
    >
      <Segment basic className='wrapper-things-type'>
        <BreadcrumbCustom flow={[names.navigation.things, type]} />
        {INFO_DISCONNECT && renderInfoStatic()}
        <Layer
          section='THINGS'
          fieldToSearch='name'
          data={thingsUpdateConnection}
          loading={isLoading.fetch}
          loadMore={isLoadMore}
          onRefresh={refetchThings}
          onSetSearchData={handleSearchData}
          refetchLoadMore={getMoreThings}
          setModalParam={handleSetModalParams}
        />
        <ModalCoords
          coords={coords}
          address={addressLicense}
          isOpen={isOpenModalCoords}
          onCancel={handleOnCancelModalCoords}
        />
      </Segment>
    </Layout>
  );
};

export default TypeThings;
