import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStatusRequest } from '../contexts/request';
import BackendIoT from '../controllers/Backend/IoT';
import ReporterLogger from '../controllers/ReporterLogger';
import * as Interfaces from '../interfaces';

interface IUseGeneralRequests {
  isLoadingRequest: {
    resendTemporaryPassword: boolean;
  };
  resendTemporaryPassword: (
    values: Interfaces.IParamsActionsUseUser['resendTemporaryPassword']
  ) => Promise<void>;
}

interface IState {
  isLoading: IUseGeneralRequests['isLoadingRequest'];
}

const LoggerInstance = ReporterLogger.getInstance();

export const useGeneralRequests = (): IUseGeneralRequests => {
  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });

  const { t: translation } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    resendTemporaryPassword: false
  });

  const resendTemporaryPassword = async ({
    id,
    name
  }: Interfaces.IParamsActionsUseUser['resendTemporaryPassword']): Promise<void> => {
    if (!isLoading.resendTemporaryPassword) {
      setIsLoading({ ...isLoading, resendTemporaryPassword: true });
    }
    try {
      await BackendIoT.resendTemporaryPassword(id);
      messageResponseRef.current = {
        type: 'success',
        action: translation('actions.resendTemporaryPassword'),
        message: translation('success.requests.user.resendTemporaryPassword', {
          name
        })
      };
    } catch (error: any) {
      messageResponseRef.current = {
        type: 'error',
        action: translation('actions.resendTemporaryPassword'),
        message: translation('error.requests.users.resendTemporaryPassword', {
          code: error?.status ?? ' '
        })
      };
      LoggerInstance.error(
        'Failed resend temporary password - useGeneralRequests - "resendTemporaryPassword"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, resendTemporaryPassword: false });
    }
  };

  return {
    isLoadingRequest: isLoading,
    resendTemporaryPassword
  };
};

export default useGeneralRequests;
