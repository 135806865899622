import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Header, List, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Empty from '../../../../components/Empty';
import { SkeletonBlock } from '../../../../components/Skeletons';
import names from '../../../../config/names';
import * as Helpers from '../../../../helpers';
import * as Constants from '../../../../config/constants';
import * as Interfaces from '../../../../interfaces';

const { formatDate, parseParamsGroupDetail } = Helpers;

const { FORMAT_DATE_CREATED } = Constants;

export interface PropsComponentInformationGroups {
  isLoading: boolean;
  groups: Interfaces.IGroupsIoT[];
  isDarkMode?: boolean;
  isAllowedRedirect: boolean;
  onClickRedirectGroup: () => void;
}

const InformationGroups: FC<PropsComponentInformationGroups> = ({
  isLoading,
  groups,
  isDarkMode,
  isAllowedRedirect,
  onClickRedirectGroup
}: PropsComponentInformationGroups): JSX.Element => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <SkeletonBlock
        styled={{ maxWidth: '100%', height: '80%', marginTop: '3em' }}
      />
    );
  }

  if (!groups.length) {
    return (
      <Segment basic className='groups'>
        <Empty
          mainContainer
          iconName='users'
          texts={{ title: t('messages.things.groups') }}
        />
      </Segment>
    );
  }

  return (
    <>
      <Header as='h2' content={t('sections.groups')} />
      <Segment basic className='groups'>
        <List
          inverted={isDarkMode}
          divided
          padded
          as={Segment}
          className='list-groups'
        >
          {Object.values(groups).map(
            ({ group, groupName, createdAt }, index) => (
              <List.Item key={`group-${index.toString()}`}>
                <List.Icon name='cubes' size='large' verticalAlign='middle' />
                <List.Content>
                  <List.Header as='h3'>
                    {isAllowedRedirect ? (
                      <Link
                        to={{
                          pathname: names.paths.groups.detail,
                          search: parseParamsGroupDetail(group, groupName)
                        }}
                        state={{ groupName, groupId: group }}
                        onClick={onClickRedirectGroup}
                      >
                        {groupName}
                      </Link>
                    ) : (
                      groupName
                    )}
                  </List.Header>
                  <List.Description>
                    {formatDate(createdAt, FORMAT_DATE_CREATED)}
                  </List.Description>
                </List.Content>
              </List.Item>
            )
          )}
        </List>
      </Segment>
    </>
  );
};

InformationGroups.defaultProps = {
  isDarkMode: false
};

export default InformationGroups;
