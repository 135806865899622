import React from 'react';
import { Header, Image, Segment } from 'semantic-ui-react';
import ContactUs from './ContactUs';

export interface PropsContentStep {
  title: string;
  description: string;
  image: string;
  note?: string;
  isErrorLicenseAssign?: boolean;
}

const ContentStep = ({
  title,
  description,
  image,
  note,
  isErrorLicenseAssign
}: PropsContentStep): JSX.Element => {
  return (
    <Segment basic className='wrapper-content-step'>
      <Header as='h3' content={title} className='header-step' />
      <p className='description-step'>{description}</p>
      {!isErrorLicenseAssign ? (
        <Image src={image} size='huge' />
      ) : (
        <ContactUs />
      )}
      {!!note?.length && <p className='description-step'>{note}</p>}
    </Segment>
  );
};

ContentStep.defaultProps = {
  note: '',
  isErrorLicenseAssign: false
};

export default ContentStep;
