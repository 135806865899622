import { useState, useEffect } from 'react';
import { SIZES_SCREEN, BREAKPOINTS_SCREEN } from '../config/constants';

interface ISize {
  width: number;
  height: number;
}

interface IScreenSizes {
  isMobile: boolean;
  isDesktop: boolean;
}

type TBreakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface IUseBreakpoint {
  breakpoint: TBreakpoints;
  windowSize: ISize;
  isMobileDevice: boolean;
  screen: IScreenSizes;
}

export function useBreakpoint(): IUseBreakpoint {
  const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);

  const [windowSize, setWindowSize] = useState<ISize>({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [breakpoint, setBreakPoint] = useState<TBreakpoints>(
    isMobileDevice ? 'sm' : 'lg'
  );

  function updateSizeScreen(): void {
    const { XS, SM, MD, LG, XL } = SIZES_SCREEN;
    if (XS.MIN < windowSize.width && windowSize.width < XS.MAX) {
      setBreakPoint(BREAKPOINTS_SCREEN[XS.MIN]);
    }
    if (SM.MIN < windowSize.width && windowSize.width < SM.MAX) {
      setBreakPoint(BREAKPOINTS_SCREEN[SM.MIN]);
    }
    if (MD.MIN < windowSize.width && windowSize.width < MD.MAX) {
      setBreakPoint(BREAKPOINTS_SCREEN[MD.MIN]);
    }
    if (LG.MIN < windowSize.width && windowSize.width < LG.MAX) {
      setBreakPoint(BREAKPOINTS_SCREEN[LG.MIN]);
    }
    if (windowSize.width >= XL.MIN) {
      setBreakPoint(BREAKPOINTS_SCREEN[XL.MIN]);
    }
  }

  function getScreenSize(): IScreenSizes {
    const sizes = {
      mobile: ['xs', 'sm', 'md'],
      desktop: ['lg', 'xl']
    };

    return {
      isMobile: sizes.mobile.includes(breakpoint),
      isDesktop: sizes.desktop.includes(breakpoint)
    };
  }

  function handleResize(): void {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    updateSizeScreen();
    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);

  return {
    breakpoint,
    windowSize,
    isMobileDevice,
    screen: getScreenSize()
  };
}

export default useBreakpoint;
