import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Header,
  Modal,
  InputProps,
  DropdownProps
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  validateEmail,
  validateFullName,
  validateNotJustSpaces
} from '../../../helpers';
import { OPTIONS_ACCOUNT_PERMISSIONS } from '../../../config/constants';
import { IModalUserData } from '../../../interfaces';

import '../../../scss/layout/Modal.scss';

interface IState {
  error: {
    name: boolean;
    email: boolean;
  };
  dataForm: {
    name: string;
    email?: string;
    permission?: string;
  };
}

export interface PropsComponentModalUser {
  action: 'edit' | 'create';
  editValues: {
    id: string;
    name: string;
    email?: string;
  } | null;
  isOpen: boolean;
  isLoading: boolean;
  onAccept(data: IModalUserData): void;
  onCancel(e?: SyntheticEvent): void;
}

const MAX_LENGTH_FIELDS = {
  name: 85,
  email: 70
};

const DEFAULT_ERROR_MESSAGE: IState['error'] = {
  name: false,
  email: false
};
const DEFAULT_DATA_FORM: IState['dataForm'] = {
  name: '',
  email: ''
};

const ModalUserActions = ({
  action,
  editValues,
  isOpen,
  isLoading,
  onCancel,
  onAccept
}: PropsComponentModalUser): JSX.Element => {
  const { t } = useTranslation();

  const [isError, setIsError] = useState<IState['error']>(
    DEFAULT_ERROR_MESSAGE
  );
  const [dataForm, setDataForm] =
    useState<IState['dataForm']>(DEFAULT_DATA_FORM);

  const resetStates = (): void => {
    setDataForm(DEFAULT_DATA_FORM);
    setIsError(DEFAULT_ERROR_MESSAGE);
  };

  useEffect(() => {
    if (isOpen) {
      resetStates();
      if (action === 'edit' && editValues) {
        setDataForm(editValues);
      }
    }
  }, [isOpen]);

  const handlerOnchange = (
    e: SyntheticEvent,
    { value, name }: InputProps
  ): void => {
    e.preventDefault();
    setDataForm({ ...dataForm, [name]: value });
    setIsError({ ...isError, [name]: false });
  };

  const handlerOnChangePermission = (
    e: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ): void => {
    e.preventDefault();
    setDataForm({ ...dataForm, permission: value as string });
  };

  const handlerAccept = (e: SyntheticEvent): void => {
    e.preventDefault();
    const { name, email, permission } = dataForm;

    if (name && (!validateFullName(name) || !validateNotJustSpaces(name))) {
      setIsError({ ...isError, name: true });
      return;
    }

    if (email && !validateEmail(email)) {
      setIsError({ ...isError, email: true });
      return;
    }

    const editValuesID = editValues ? editValues.id : '';
    const data: IModalUserData = {
      id: permission ? undefined : editValuesID,
      name: name.trim(),
      email: email ?? '',
      adminLevel: permission || undefined
    };
    onAccept(data);
  };

  const handlerOnClose = (e: SyntheticEvent): void => {
    e.preventDefault();
    resetStates();
    onCancel();
  };

  const renderControls = (): JSX.Element => {
    const TRANSLATE_OPTIONS_ACCOUNT_PERMISSIONS = Object.values(
      OPTIONS_ACCOUNT_PERMISSIONS
    ).map((item) => ({ ...item, text: t(item.text as any) }));
    return (
      <Form widths='equal' autoComplete='off'>
        <Form.Input
          name='name'
          fluid
          maxLength={MAX_LENGTH_FIELDS.name}
          value={dataForm.name}
          error={isError.name && t('error.modals.labels.name')}
          label={t('modals.labels.users.name')}
          placeholder={t('modals.placeholders.users.name')}
          onChange={handlerOnchange}
          disabled={isLoading}
        />
        <Form.Input
          name='email'
          fluid
          maxLength={MAX_LENGTH_FIELDS.email}
          value={dataForm.email}
          error={isError.email && t('error.modals.labels.email')}
          label={t('modals.labels.users.email')}
          placeholder={t('modals.placeholders.users.email')}
          onChange={handlerOnchange}
          disabled={isLoading}
        />
        {action === 'create' && (
          <Form.Select
            options={TRANSLATE_OPTIONS_ACCOUNT_PERMISSIONS}
            label={t('modals.labels.users.permissions')}
            placeholder={t('modals.placeholders.users.permissions')}
            onChange={handlerOnChangePermission}
            disabled={isLoading}
          />
        )}
      </Form>
    );
  };

  const isDisabled =
    !dataForm.name ||
    !dataForm.email ||
    (action === 'create' && !dataForm.permission);
  return (
    <Modal
      size='small'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modalUser-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={t(`modals.titles.${action}.user` as const)}
          icon='user'
        />
      </Modal.Header>
      <Modal.Content>{renderControls()}</Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={handlerOnClose}
          content={t('actions.cancel')}
        />
        <Button
          positive
          disabled={isDisabled || isLoading}
          onClick={handlerAccept}
          loading={isLoading}
          content={t(`actions.${action}` as const)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalUserActions;
