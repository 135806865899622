import React, { SyntheticEvent } from 'react';
import {
  Button,
  Grid,
  Segment,
  Dropdown,
  Header,
  InputProps
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../contexts/settings';
import { useAuthState } from '../../contexts/auth';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import ButtonFloat from '../ButtonFloat';
import Search from '../Search';
import { SkeletonHeaderLayer } from '../Skeletons';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

import './Layer.scss';

export interface PropsComponentHeaderLayer {
  data: any[];
  section: Types.TLayerTypes;
  loading: boolean;
  totalItems: number;
  isFound: boolean;
  onFilter?: () => void;
  onCreate?: () => void | null;
  onRefresh?: () => Promise<void> | null;
  onSearch: (
    e: SyntheticEvent,
    data: InputProps,
    searchValue: Interfaces.ISearchValue
  ) => void;
  onSearchTrigger?: (search: any) => void | null;
  statistic?: () => JSX.Element | null;
}

interface IOptionsSearch {
  [key: string]: any[];
}

const WITHOUT_SEARCHING: Types.TLayerTypes[] = [
  'FEEDACK',
  'SUBGROUPS',
  'GROUPS-THINGS',
  'GROUPS-USERS',
  'GROUPS-FEEDACK',
  'CONTROL-THINGS',
  'MONITOR-THINGS',
  'MONITOR-THINGS-ALL'
];

const OPTIONS_SEARCH: IOptionsSearch = {
  USERS: Constants.OPTIONS_SEARCH_USERS,
  THINGS: Constants.OPTIONS_SEARCH_THINGS,
  LICENSES: Constants.OPTIONS_SEARCH_LICENSES,
  'DAMAGE-REPORT': Constants.OPTIONS_SEARCH_DAMAGE_REPORT
};

const DEFAULT_SEARCH_OPTION: any = {
  GROUPS: 'groupName',
  USERS: 'name',
  THINGS: 'name',
  LICENSES: 'name',
  'DAMAGE-REPORT': 'name'
};

const HeaderLayer = ({
  data,
  section,
  loading,
  isFound,
  totalItems,
  statistic,
  onCreate,
  onFilter,
  onSearch,
  onRefresh,
  onSearchTrigger
}: PropsComponentHeaderLayer): JSX.Element => {
  const { t } = useTranslation();
  const { adminLevel } = useAuthState();
  const { status } =
    useParams<Types.TParamsLicenses>() as Types.TParamsLicenses;
  const { theme } = useSettings();

  const {
    isMobileDevice,
    screen: { isMobile }
  } = useBreakpoint();

  const isDarkMode = theme !== 'light';
  const isSearch = !WITHOUT_SEARCHING.includes(section);
  const isDisableActions = (isFound && !data.length) || loading;
  const validSendLicenses =
    section !== 'LICENSES' ? true : status === 'available';

  if (loading) {
    return (
      <SkeletonHeaderLayer
        isMobile={isMobile}
        margin='2.5em'
        isSearch={isSearch}
        isStatics={statistic}
      />
    );
  }

  const renderHeader = () => {
    return (
      <>
        {isSearch && (
          <Segment basic className='wrapper-layer'>
            <Grid as={Segment} inverted={isDarkMode}>
              <Grid.Row>
                <Grid.Column computer={7} mobile={16}>
                  <Search
                    list={data}
                    options={OPTIONS_SEARCH[section] || null}
                    isDisable={isDisableActions}
                    onSearch={onSearch}
                    defaultOptions={DEFAULT_SEARCH_OPTION[section] || 'name'}
                    updateValueSearch={onSearchTrigger}
                  />
                </Grid.Column>
                <Grid.Column width={3} only='computer' />
                <Grid.Column
                  width={6}
                  className='controls-buttons'
                  only='computer'
                >
                  <Dropdown
                    text={t('actions.filter')}
                    icon='filter'
                    floating
                    labeled
                    button
                    className='icon'
                    disabled
                    onClick={onFilter}
                    style={{ marginRight: 15, display: 'none' }}
                  />
                  {!isMobileDevice &&
                    section !== 'THINGS' &&
                    Constants.EXCLUDE_ROLE_FOR_CLIENT_USERS.includes(
                      adminLevel
                    ) &&
                    onCreate &&
                    validSendLicenses && (
                      <Button
                        color='orange'
                        content={t(
                          `actions.${
                            section === 'LICENSES' ? 'assignMultiple' : 'create'
                          }`
                        )}
                        icon={section === 'LICENSES' ? 'mail' : 'plus'}
                        labelPosition='left'
                        size='large'
                        onClick={onCreate}
                        disabled={isDisableActions}
                      />
                    )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {(isMobileDevice || isMobile) &&
              section !== 'THINGS' &&
              Constants.EXCLUDE_ROLE_FOR_CLIENT_USERS.includes(adminLevel) &&
              onCreate && <ButtonFloat isHidden={false} onClick={onCreate} />}
          </Segment>
        )}
        {section !== 'MONITOR-THINGS' && section !== 'MONITOR-THINGS-ALL' && (
          <Segment basic className='wrapper-subheader'>
            <Grid>
              {statistic && (
                <Grid.Row>
                  {typeof statistic === 'function' && statistic()}
                </Grid.Row>
              )}
              <Grid.Row verticalAlign='middle'>
                <Grid.Column width={8}>
                  <Header
                    as='h2'
                    className='header-list'
                    content={t('layer.header.records', { count: totalItems })}
                    inverted={isDarkMode}
                    disabled={totalItems < 1}
                  />
                </Grid.Column>
                <Grid.Column width={8} only='computer' textAlign='right'>
                  {onRefresh && (
                    <Button
                      icon='redo'
                      className='refresh-data'
                      onClick={onRefresh}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
      </>
    );
  };

  if (onCreate) return renderHeader();

  return renderHeader();
};

HeaderLayer.defaultProps = {
  statistic: null,
  onFilter: () => null,
  onRefresh: () => null,
  onCreate: null,
  onSearchTrigger: null
};

export default HeaderLayer;
