import React, { SyntheticEvent } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useClientState } from '../../contexts/client';
import { useClients } from '../../hooks/useClients';
import useGeneralRequests from '../../hooks/useGeneralRequests';
import * as Types from '../../types';

import './DropdownActions.scss';

export interface PropsComponentDropdownActions {
  type: Types.TLayerTypes;
  element?: any;
  onDelete?: () => void | null;
  onEdit?: (element?: any) => void | null;
  setModalParam?: (e: SyntheticEvent<HTMLElement>, data: any) => void;
}

function DropdownActions({
  type,
  setModalParam,
  onDelete,
  onEdit,
  element
}: PropsComponentDropdownActions) {
  const { t } = useTranslation();
  const { isLoadingRequest } = useClients();
  const { resendTemporaryPassword } = useGeneralRequests();
  const client = useClientState();

  return (
    <Dropdown
      circular
      as={Button}
      loading={isLoadingRequest.select}
      icon='ellipsis vertical'
      className='wrapper-actions-dropdown'
      data-testid='dropdown-actions-component'
    >
      <Dropdown.Menu>
        <Dropdown.Item style={{ display: 'none' }} />
        {type === 'CLIENTS' && client.isNewCustomer && (
          <Dropdown.Item
            icon='unlock'
            text={t('actions.resendTemporaryPassword')}
            onClick={() => {
              resendTemporaryPassword({ id: client.email, name: client.name });
            }}
          />
        )}
        {onEdit && !setModalParam && (
          <Dropdown.Item
            icon='edit'
            text={t('actions.edit')}
            disabled
            style={{ display: 'none' }}
          />
        )}
        {onDelete && setModalParam && (
          <Dropdown.Item
            icon='trash'
            text={t('actions.delete')}
            onClick={(e: SyntheticEvent<HTMLElement>) => {
              setModalParam(e, element);
              onDelete();
            }}
          />
        )}
        {onEdit && setModalParam && (
          <Dropdown.Item
            icon='pencil'
            text={t('actions.edit')}
            onClick={(e: SyntheticEvent<HTMLElement>) => {
              setModalParam(e, element);
              onEdit(element);
            }}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

DropdownActions.defaultProps = {
  setModalParam: () => null,
  element: '',
  onDelete: null,
  onEdit: null
};

export default DropdownActions;
