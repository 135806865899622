import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  DropdownProps,
  Input,
  InputOnChangeData
} from 'semantic-ui-react';
import * as Interfaces from '../../interfaces';

import './Search.scss';

export interface PropsComponentSearch {
  list: any;
  options?: any[] | null;
  isDisable: boolean;
  placeholder?: string;
  placeholderInput?: string;
  defaultOptions: string;
  onSearch?: (
    e: SyntheticEvent,
    list: any,
    searchValue: Interfaces.ISearchValue
  ) => void;
  updateValueSearch?: (value: any) => void | null;
}

const DEFAULT_SEARCH_VALUE: Interfaces.ISearchValue = {
  value: '',
  field: ''
};

const Search = ({
  list,
  options,
  isDisable,
  onSearch,
  placeholder,
  defaultOptions,
  placeholderInput,
  updateValueSearch
}: PropsComponentSearch): JSX.Element => {
  const { t: translation } = useTranslation();
  const [searchItem, setSearchItem] = useState<string>('');
  const [field, setField] = useState<string>(defaultOptions);

  let TRANSLATE_OPTIONS = [];

  const handlerOnChangeField = (
    e: SyntheticEvent,
    { value }: DropdownProps
  ): void => {
    setField(value as string);
    setSearchItem('');
    if (onSearch && !updateValueSearch) {
      onSearch(e, list, DEFAULT_SEARCH_VALUE);
    }
  };

  const handlerSearch = (
    e: SyntheticEvent,
    { value }: InputOnChangeData
  ): void => {
    e.preventDefault();
    setSearchItem(value);
    if (onSearch && !updateValueSearch) {
      const filteredList = list.filter((element: any): any[] => {
        const searchValue = value.toString().toLowerCase();

        if (field.includes('.')) {
          const keys = field.split('.');
          return element[keys[0]][keys[1]]
            .toString()
            .toLowerCase()
            .includes(searchValue);
        }

        return element[field].toString().toLowerCase().includes(searchValue);
      });

      const searchValue: Interfaces.ISearchValue = {
        value,
        field
      };

      onSearch(e, filteredList, searchValue);
    }
  };

  const handlerAction = (): void => {
    if (updateValueSearch) {
      updateValueSearch({ value: searchItem, type: field });
    }
  };

  const handlerClear = (e: SyntheticEvent): void => {
    if (onSearch) {
      onSearch(e, list, DEFAULT_SEARCH_VALUE);
    }
    setSearchItem('');
  };

  if (options) {
    TRANSLATE_OPTIONS = Object.values(options).map((item) => ({
      ...item,
      text: translation(item.text as any)
    }));
  }

  return (
    <div className='wrapper-search'>
      {options && (
        <Dropdown
          button
          value={field}
          options={TRANSLATE_OPTIONS}
          disabled={isDisable}
          placeholder={placeholder}
          onChange={handlerOnChangeField}
        />
      )}
      <Input
        fluid
        className='field-search'
        data-testid='search-component-input'
        value={searchItem}
        disabled={isDisable}
        placeholder={placeholderInput || translation('actions.search')}
        onChange={handlerSearch}
        labelPosition='left'
        action={
          updateValueSearch ? (
            <Button
              className='action-button'
              icon='search'
              onClick={handlerAction}
              disabled={isDisable}
            />
          ) : (
            false
          )
        }
      />
      {!updateValueSearch && (
        <Button
          icon='close'
          className='button-clear'
          circular
          disabled={isDisable}
          onClick={handlerClear}
        />
      )}
    </div>
  );
};

Search.defaultProps = {
  options: null,
  placeholder: '',
  placeholderInput: '',
  onSearch: () => null,
  updateValueSearch: null
};

export default Search;
