import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Icon,
  Message,
  Image,
  InputOnChangeData,
  DropdownProps,
  Dropdown
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../contexts/settings';
import { useLogin } from '../../hooks/useLogin';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import MessageUI from '../../components/MessageUI';
import Version from '../../components/Version';
import { FIELDS_DISPLAY, OPTIONS_LANGUAGE } from '../../config/constants';
import { IAnalyticsViewPage } from '../../interfaces';
import * as Types from '../../types';
import names from '../../config/names';

import '../../scss/layout/Login.scss';

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.login,
  title: 'Login'
};

const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const { language, setLanguageSelect } = useSettings();
  const {
    windowSize: { width: screenWidth }
  } = useBreakpoint();

  const {
    view,
    fields,
    isSend,
    isLoading,
    validations,
    fieldsShowPass,
    showFieldsPass,
    messageRequest,
    changeViewLink,
    updateValueFields,
    resetMessageRequest,
    managerStateView
  } = useLogin({ analytics: ANALYTICS_PAGE_VIEW });

  const handlerOnChangeInputs = (
    e: SyntheticEvent,
    { name, value }: InputOnChangeData
  ) => {
    e.preventDefault();
    updateValueFields(name, value);
  };

  const handleOnChangeLanguage = (
    e: SyntheticEvent,
    { value }: DropdownProps
  ): void => {
    e.preventDefault();
    setLanguageSelect(value as Types.TLanguage);
  };

  const renderFields = (): React.ReactElement[] =>
    Object.values(FIELDS_DISPLAY[view]).map((item) => {
      const { showPass, ...input } = item;
      return (
        <div key={`${item.name}-${showPass}`} style={{ position: 'relative' }}>
          <Form.Input
            {...input}
            label={t(input.label)}
            placeholder={t(input.placeholder)}
            type={fieldsShowPass[item.name] ? 'text' : item.type}
            disabled={isLoading}
            key={item.placeholder}
            value={fields[item.name]}
            onChange={handlerOnChangeInputs}
          />
          {item.type === 'password' && (
            <Icon
              link
              disabled={isLoading}
              name={!fieldsShowPass[item.name] ? 'eye' : 'eye slash'}
              className='show-pass'
              onClick={() => showFieldsPass(item.name)}
            />
          )}
        </div>
      );
    });

  const {
    isViewLogin,
    isDisabledLogin,
    isViewDefaultPassword,
    isViewPasswordRequirements
  } = validations;
  const { type, content } = messageRequest;

  const TRANSLATE_OPTIONS_LANGUAGE = Object.values(OPTIONS_LANGUAGE).map(
    (item) => ({ ...item, text: t(item.text as any) })
  );

  const textsTitles = t('login.titles', { returnObjects: true });
  const textsAccept = t('actions.login.accept', { returnObjects: true });
  const textsLinks = t('actions.login.links', { returnObjects: true });
  const textsMessageSend = t(
    `success.login.send${isSend.code ? '' : 'TemporaryPassword'}`,
    { returnObjects: true }
  );
  const textsPasswordRequirements = t('login.passwordRequirements', {
    returnObjects: true
  });

  const isTableSize = screenWidth <= 1286;

  return (
    <Grid
      stackable
      verticalAlign='middle'
      className='wrapper-login'
      data-testid='login-route'
    >
      <Grid.Row columns={2}>
        <Grid.Column computer={6} tablet={16} mobile={16}>
          <Form size='large' className='form-style'>
            <Image
              centered={isTableSize}
              src='https://skyalert.mx/img/logo.png'
              size='small'
            />
            <Header
              textAlign={isTableSize ? 'center' : 'left'}
              as={isViewLogin ? 'h1' : 'h2'}
              content={textsTitles[view]}
              color='orange'
              subheader={isViewLogin ? t('login.subtitle') : ''}
              className='title-main'
            />
            <MessageUI
              duration={650}
              negative={type === 'error'}
              success={type === 'success'}
              warning={type === 'warning'}
              icon='warning circle'
              isVisible={type !== 'none'}
              content={content}
              onDismiss={resetMessageRequest}
            />
            <MessageUI
              duration={650}
              success
              icon='send'
              isVisible={isSend.code || isSend.password}
              onDismiss={resetMessageRequest}
              {...textsMessageSend}
            />
            {renderFields()}
            {!isViewPasswordRequirements && (
              <Message size='small' {...textsPasswordRequirements} />
            )}
            <Segment basic textAlign='center'>
              <Button
                color='orange'
                size='large'
                fluid
                onClick={managerStateView}
                disabled={isLoading || isDisabledLogin}
                loading={isLoading}
                content={textsAccept[view]}
              />
              <br />
              {!isViewDefaultPassword && (
                <Segment
                  basic
                  className='link-color'
                  to='#'
                  as={Link}
                  disabled={isLoading}
                  style={{ outline: 'none', marginTop: '2em' }}
                  onClick={() => changeViewLink()}
                  content={textsLinks[view]}
                />
              )}
            </Segment>
          </Form>
        </Grid.Column>
        <Grid.Column className='image-form' computer={10} />
        <Version />
        <div className='container-language'>
          <Icon name='globe' disabled={isLoading} />
          <Dropdown
            upward
            floating
            disabled={isLoading}
            options={TRANSLATE_OPTIONS_LANGUAGE}
            onChange={handleOnChangeLanguage}
            value={language}
          />
        </div>
      </Grid.Row>
    </Grid>
  );
};

export default Login;
