import { IAddressLicense, ICoords } from '../interfaces';

export const geocodingAddress = async (
  request: google.maps.GeocoderRequest
): Promise<google.maps.GeocoderAddressComponent[]> => {
  const geocoder = new google.maps.Geocoder();
  const response = await geocoder.geocode(request);
  return response.results[0].address_components;
};

export const parseCoordsToAddress = async ({
  lat,
  lng
}: ICoords): Promise<IAddressLicense | null> => {
  if (typeof lat === 'number' && typeof lng === 'number') {
    try {
      const rawAddress: google.maps.GeocoderAddressComponent[] =
        await geocodingAddress({
          address: `${lat},${lng}`
        });
      const parseAddress: IAddressLicense = {
        county: '',
        state: '',
        zipCode: ''
      };

      if (rawAddress.length) {
        rawAddress.forEach((element) => {
          const { types } = element;
          if (types.includes('administrative_area_level_1')) {
            parseAddress.state = element.long_name;
          }
          if (types.includes('postal_code')) {
            parseAddress.zipCode = element.long_name;
          }
          if (types.includes('sublocality')) {
            parseAddress.county = element.long_name;
          }
        });
      }
      return parseAddress;
    } catch (e) {
      console.warn('Could not get geocoding for address', e);
      return null;
    }
  }

  return null;
};
