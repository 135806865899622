import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Icon, Segment } from 'semantic-ui-react';
import Markdown from 'markdown-to-jsx';
import moment from 'moment';
import * as Types from '../../../../types';
import * as Interfaces from '../../../../interfaces';

interface IPropsViewConfirm {
  view?: Types.TControlViews;
  type: Interfaces.IModalControlInitialState['scheduled'];
  date?: Interfaces.IModalControlInitialState['date'];
  command: string;
  recipient: Interfaces.IControlRecipient;
}

const DATE_OLD_FORMAT = 'DD-MM-YYYY';
const DATE_NEW_FORMAT = 'LL';

const ViewConfirm = ({
  view,
  type,
  date,
  command,
  recipient
}: IPropsViewConfirm): JSX.Element => {
  const { t } = useTranslation();
  moment().locale('es');
  const dateFormat = moment(date?.date, DATE_OLD_FORMAT).format(
    DATE_NEW_FORMAT
  );

  return (
    <Segment basic data-testid='modalsCommandsScheduled-component'>
      <Header as='h2'>
        <div className='header-confirm-container'>
          <Icon name='warning circle' size='large' />
          {t(`modals.paragraphs.confirm.sendCommand.${type}.title`, {
            command
          })}
        </div>
        <Header.Subheader className='subheader-confirm-container'>
          <Markdown>
            {t(
              `modals.paragraphs.confirm.sendCommand.${type}.subtitle.${view}` as any,
              {
                date: dateFormat,
                time: date?.time,
                name: recipient.name,
                id: recipient.id
              }
            )}
          </Markdown>
        </Header.Subheader>
      </Header>
    </Segment>
  );
};

ViewConfirm.defaultProps = {
  view: 'groups',
  date: ''
};

export default ViewConfirm;
