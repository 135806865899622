import names from '../../config/names';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

const clientInfo = localStorage.getItem(names.storageKeys.clientInfo);

export const initialState: Interfaces.IStateCtxClient = {
  id: clientInfo ? JSON.parse(clientInfo).id : null,
  name: clientInfo ? JSON.parse(clientInfo).name : null,
  email: clientInfo ? JSON.parse(clientInfo).email : null,
  skyalertId: clientInfo ? JSON.parse(clientInfo).skyalertId : '',
  selected: clientInfo ? JSON.parse(clientInfo).selected : false,
  licenseCount: clientInfo ? JSON.parse(clientInfo).licenseCount : null,
  thingCount: clientInfo ? JSON.parse(clientInfo).thingCount : null,
  isNewCustomer: clientInfo ? JSON.parse(clientInfo).isNewCustomer : false,
  loading: false,
  errorMessage: null,
  notFound: false
};

export const ClientReducer = (
  state: Interfaces.IStateCtxClient,
  action: Types.TClientActions
) => {
  const { type } = action;
  switch (type) {
    case Types.EClientReducer.request:
      return {
        ...state,
        selected: false,
        notFound: false,
        loading: true
      };
    case Types.EClientReducer.fetch: {
      const {
        payload: {
          id,
          name,
          email,
          skyalertId,
          licenseCount,
          thingCount,
          selected
        }
      } = action as Interfaces.IClientReducerFetch;
      return {
        ...state,
        id,
        name,
        email,
        skyalertId,
        licenseCount,
        thingCount,
        selected: selected || false,
        notFound: false,
        loading: false
      };
    }
    case Types.EClientReducer.select:
      return {
        ...state,
        selected: true,
        notFound: false,
        loading: false
      };
    case Types.EClientReducer.unselect:
      return {
        ...state,
        selected: false,
        notFound: false,
        loading: false
      };
    case Types.EClientReducer.error: {
      const {
        error,
        isNotFound,
        payload: { id, name, email, skyalertId }
      } = action as Interfaces.IClientReducerError;
      return {
        ...state,
        id,
        name,
        email,
        skyalertId,
        selected: false,
        loading: false,
        notFound: isNotFound,
        errorMessage: error
      };
    }
    default: {
      throw new Error('Unhandled action type for ClientReducer');
      return state;
    }
  }
};
