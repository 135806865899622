import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Grid,
  Header,
  Modal
} from 'semantic-ui-react';
import * as Interfaces from '../../../interfaces';

export interface PropsComponentModalSettingsMonitor {
  isOpen: boolean;
  settings: Interfaces.ISettingsMonitor;
  onAccept(): void;
  onChangeSettings(e: SyntheticEvent, data: CheckboxProps): void;
}

const ModalSettingsMonitor = ({
  isOpen,
  settings,
  onAccept,
  onChangeSettings
}: PropsComponentModalSettingsMonitor): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      size='mini'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modal-settings-monitor-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={t('monitor.modalSettings.title')}
          icon='settings'
        />
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header as='h4' content={t('monitor.modalSettings.hideDesk')} />
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <Checkbox
                toggle
                name='hideDesk'
                onClick={onChangeSettings}
                checked={settings.isHideDesk}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header
                as='h4'
                content={t('monitor.modalSettings.hideSkyAlert')}
              />
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <Checkbox
                toggle
                name='filteredSkyAlert'
                onChange={onChangeSettings}
                checked={settings.isFilteredSkyAlert}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={onAccept} content={t('actions.accept')} />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalSettingsMonitor;
