import React from 'react';
import { Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Empty from '../../../../components/Empty';

const Reports = () => {
  const { t } = useTranslation();
  return (
    <Segment basic placeholder textAlign='center' className='empty-section'>
      <Empty
        fullContainer
        iconName='file alternate'
        texts={t('messages.things.report', { returnObjects: true })}
      />
    </Segment>
  );
};

export default Reports;
