import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Divider, Form, Header, InputProps } from 'semantic-ui-react';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_PROPS_QUILL,
  DEFAULT_VALUES_MODAL_CUSTOM_ALERTS
} from '../../../../config/constants';
import { ICustomAlertMessageItems } from '../../../../interfaces';

import 'react-quill/dist/quill.snow.css';

interface PropsComponentModalCustomAlert {
  onFinishMessage(data: ICustomAlertMessageItems): void;
}

const EMPTY_TAG_QUILL = '<p><br></p>';

const CustomCommands = ({
  onFinishMessage
}: PropsComponentModalCustomAlert): JSX.Element => {
  const { t } = useTranslation();

  const [messageCustom, setMessageCustom] = useState<ICustomAlertMessageItems>(
    DEFAULT_VALUES_MODAL_CUSTOM_ALERTS
  );

  useEffect(() => {
    const isCompleted = Object.values(messageCustom).every((item) => item);
    if (isCompleted) {
      onFinishMessage(messageCustom);
    }
  }, [messageCustom]);

  const mergeState = (name: string, value: string): void => {
    setMessageCustom((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handlerOnChangeQuill = (value: string) => {
    if (value !== EMPTY_TAG_QUILL) {
      mergeState('body', value);
    } else {
      mergeState('body', '');
      onFinishMessage({ ...messageCustom, body: '' });
    }
  };

  const handlerOnChange = (e: SyntheticEvent, { value }: InputProps): void => {
    e.preventDefault();
    if (!value) {
      onFinishMessage({ ...messageCustom, title: '' });
    }
    mergeState('title', value);
  };

  const { title, body } = messageCustom;
  return (
    <Form data-testid='modalCustomAlert-component'>
      <Form.Group>
        <Form.Field width={12}>
          <Header as='h4' content={t('control.customCommands.title')} />
          <Form.Input
            name='form-input'
            onChange={handlerOnChange}
            value={title}
          />
        </Form.Field>
      </Form.Group>
      <Divider hidden />
      <Form.Group>
        <Form.Field width={16}>
          <Header as='h4' content={t('control.customCommands.body')} />
          <ReactQuill
            {...DEFAULT_PROPS_QUILL}
            value={body}
            onChange={handlerOnChangeQuill}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default CustomCommands;
