import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Analytics from '../controllers/Analytics';
import BackendIoT from '../controllers/Backend/IoT';
import ReporterLogger from '../controllers/ReporterLogger';
import { useStatusRequest } from '../contexts/request';
import { IAnalytics } from '../interfaces';
import { parseInUTC } from '../helpers';

interface IUseReports {
  isLoading: {
    fetch: boolean;
    download: boolean;
    thingsFetch: boolean;
  };
  fetchGroupReport: (
    startDate: string,
    endDate: string,
    groupId: string,
    customerId: string,
    refresh: boolean
  ) => Promise<string>;
  fetchThingsReport: (
    startDate: string,
    endDate: string,
    refresh: boolean
  ) => Promise<string>;
  fetchThingsReportByType: (
    startDate: string,
    endDate: string,
    thingType: string,
    refresh: boolean
  ) => Promise<string>;
  downloadReport: (reportName: string, responseText: string) => void;
}

interface IState {
  isLoading: IUseReports['isLoading'];
}

const DEFAULT_LOADING = {
  fetch: false,
  download: false,
  thingsFetch: false
};

const MEDIA_TYPE = 'data:text/csv; charset=utf-8,';
const UNIVERSAL_BOM = '\uFEFF';

const LoggerInstance = ReporterLogger.getInstance();

export const useReports = ({ analytics }: IAnalytics = {}): IUseReports => {
  const { t } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoading, setIsLoading] =
    useState<IState['isLoading']>(DEFAULT_LOADING);

  const fetchGroupReport = async (
    startDate: string,
    endDate: string,
    groupId: string,
    customerId: string,
    refresh: boolean
  ): Promise<string> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }
    let rawText = '';
    try {
      const response = await BackendIoT.getGroupReport(
        startDate,
        endDate,
        groupId,
        customerId,
        refresh
      );
      if (response) {
        rawText = response;
      }
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.groups.fetchReport')
      });
      LoggerInstance.error(
        'Failed create group report - useReports - "fetchGroupReport"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
    return rawText;
  };

  const fetchThingsReport = async (
    startDate: string,
    endDate: string,
    refresh: boolean
  ): Promise<string> => {
    if (!isLoading.thingsFetch) {
      setIsLoading({ ...isLoading, thingsFetch: true });
    }
    let rawText = '';
    try {
      const response = await BackendIoT.getThingsReportLicensee(
        startDate,
        endDate,
        refresh
      );
      if (response) {
        rawText = response;
      }
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.groups.fetchReport')
      });
      LoggerInstance.error(
        'Failed create all things report - useReports - "fetchThingsReport"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, thingsFetch: false });
    }
    return rawText;
  };

  const fetchThingsReportByType = async (
    startDate: string,
    endDate: string,
    thingType: string,
    refresh: boolean
  ): Promise<string> => {
    if (!isLoading.thingsFetch) {
      setIsLoading({ ...isLoading, thingsFetch: true });
    }

    let rawText = '';

    const parseType: { [key: string]: string } = {
      gadget: 'gadget-mini',
      epicenter: 'epicenter,epicenter-pro'
    };

    const deviceType = parseType[thingType] || thingType;
    try {
      const response = await BackendIoT.getThingsReportByTypeLicensee(
        parseInUTC(startDate),
        parseInUTC(endDate),
        deviceType,
        refresh
      );
      if (response) {
        rawText = response;
      }
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.groups.fetchReport')
      });
      LoggerInstance.error(
        'Failed create things report for type - useReports - "fetchThingsReportByType"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, thingsFetch: false });
    }
    return rawText;
  };

  const parseUrl = (responseText: string): string =>
    MEDIA_TYPE + encodeURIComponent(`${UNIVERSAL_BOM}${responseText}`);

  const downloadReport = (reportName: string, responseText: string): void => {
    if (!isLoading.download) {
      setIsLoading({ ...isLoading, download: true });
    }
    const newUrl = parseUrl(responseText);
    const temporalAnchor = document.createElement('a');
    temporalAnchor.setAttribute('href', newUrl);
    temporalAnchor.setAttribute('download', reportName);
    temporalAnchor.click();
    temporalAnchor.remove();
    setIsLoading({ ...isLoading, download: false });
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
  }, []);

  return {
    isLoading,
    fetchGroupReport,
    fetchThingsReport,
    fetchThingsReportByType,
    downloadReport
  };
};

export default useReports;
