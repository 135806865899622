import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Header, Segment } from 'semantic-ui-react';
import Analytics from '../../../controllers/Analytics';
import { useUsers } from '../../../hooks/useUsers';
import { SkeletonBlock } from '../../../components/Skeletons';
import Empty from '../../../components/Empty';
import names from '../../../config/names';
import * as Constants from '../../../config/constants';

import './UsersList.scss';

const UsersList = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isLoadingRequest,
    dataUsers: { listFiltered }
  } = useUsers();

  const renderState = (): JSX.Element => {
    if (isLoadingRequest.fetch) {
      return <SkeletonBlock styled={{ maxWidth: '100%', height: 80 }} />;
    }

    if (!listFiltered.length) {
      const { texts, icon } = Constants.PROPS_EMPTY_LAYER_CONTAINER.USERS;
      return (
        <Empty
          iconName={icon}
          texts={{
            title: t(texts.title as any),
            subtitle: t(texts.subtitle as any)
          }}
        />
      );
    }

    return (
      <Segment className='list-users' basic>
        {listFiltered.map(({ name }, index) => {
          const initial = name ? name.slice(0, 1) : '';
          return (
            index < 4 && (
              <Header
                as='h5'
                key={`user-item-${index + 1}`}
                className='user-item'
              >
                <div className='container__name'>
                  <p>{initial}</p>
                </div>
                <span className='name'>{name}</span>
              </Header>
            )
          );
        })}
      </Segment>
    );
  };

  return (
    <Segment className='wrapper-users-list'>
      <div className='header-section'>
        <Header content={t('sections.users')} />
        <Button
          basic
          size='mini'
          content={t('actions.seeAll')}
          className='see-all'
          labelPosition='right'
          icon='right chevron'
          disabled={isLoadingRequest.fetch}
          onClick={() => {
            navigate(names.paths.users);

            Analytics.sendEventCustom({
              category: 'Dashboard',
              action: 'Redirect',
              label: 'Users'
            });
          }}
        />
      </div>
      {renderState()}
    </Segment>
  );
};

export default UsersList;
