import moment, { Moment } from 'moment';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_USER_SETTINGS,
  TOTAL_CONNECT_TRACKING
} from '../config/constants';
import i18next from '../config/i18n';
import names from '../config/names';
import * as Types from '../types';
import * as Interfaces from '../interfaces';

const DAYS_BEFORE_TO_EXPIRED = 7;

export const getAdminLevelFromStore = (): Interfaces.IUserIoT['adminLevel'] => {
  let userType: Interfaces.IUserIoT['adminLevel'] = 'owner';
  const userInfo = localStorage.getItem(names.storageKeys.userInfo);
  if (userInfo) {
    const { adminLevel, licensee } = JSON.parse(userInfo);
    userType = licensee === 'skyalert' ? 'SkyAlertLicensee' : adminLevel;
  }
  return userType;
};

export const totalDays = (days: number) => {
  const pastToPositive = days < 0 ? days * -1 : days;
  return pastToPositive === 1
    ? i18next.t('time.days_one', { count: pastToPositive })
    : i18next.t('time.days_other', { count: pastToPositive });
};

export const formatExpireAgo = (
  years: number,
  months: number,
  days: number
): string => {
  let dateExpired = '';

  if (years >= 1 || (months === 11 && days > 7)) {
    const year = months === 11 && days > 7;
    dateExpired = i18next.t('time.years', {
      count: year ? 1 : years
    });
  } else if (years <= 0 && months >= 1 && months <= 11) {
    dateExpired = i18next.t('time.months', {
      count: days > 7 ? months + 1 : months
    });
  } else if (months <= 0 && days >= -DAYS_BEFORE_TO_EXPIRED && days !== 0) {
    return `${i18next.t('time.expiresOn', {
      date: totalDays(days)
    })}`;
  } else if (months <= 0 && days > 0) {
    dateExpired = totalDays(days);
  } else if (months === 0 && days === 0 && years === 0) {
    dateExpired = `${days}`;
    return `(${i18next.t('time.expiration')})`;
  }

  return `${i18next.t('time.expiredAgo', {
    date: dateExpired
  })}`;
};

export const formatDateValidUntil = (
  date: Date | string,
  formatted: string = DEFAULT_DATE_FORMAT
): string => {
  if (moment(date).isValid()) {
    const dateNow = moment();
    const dateValid = moment(date);
    const diffDate = moment.duration(dateValid.diff(dateNow));
    let years = diffDate.years();
    let months = diffDate.months();
    let days = diffDate.days();

    let dateExpiration = '';
    if (years >= 1 || (months === 11 && days > 7)) {
      const year = months === 11 && days > 7;
      dateExpiration = i18next.t('time.years', {
        count: year ? 1 : diffDate.years()
      });
    } else if (years <= 0 && months >= 1 && months <= 11) {
      dateExpiration = i18next.t('time.months', {
        count: days > 7 ? months + 1 : months
      });
    } else if (months <= 0 && days >= 0 && days >= DAYS_BEFORE_TO_EXPIRED) {
      dateExpiration = totalDays(days);
    } else if (
      (months <= 0 && days >= 0 && days <= 7) ||
      (months <= 0 && days <= 0)
    ) {
      const dateExpired = formatExpireAgo(
        (years *= -1),
        (months *= -1),
        (days *= -1)
      );
      return `${dateValid.format(formatted)} ${dateExpired}`;
    }

    return `${dateValid.format(formatted)} ${i18next.t('time.untilValid', {
      date: dateExpiration
    })}`;
  }

  return i18next.t('error.notAvailable');
};

export const formatDate = (
  date: Date | string,
  formatted = DEFAULT_DATE_FORMAT,
  parseToUTC = false
) => {
  moment.locale(i18next.language);

  const dateMoment = moment(date);
  if (parseToUTC) {
    dateMoment.utc();
  }

  return dateMoment.isValid()
    ? dateMoment.format(formatted)
    : i18next.t('error.unknown');
};

export const parseKeysTypes = (
  type: 'LICENSE' | 'RECEPTOR' | 'ASSIGNATION' | 'AUTHORIZATION' | 'CONNECTION',
  value:
    | Types.TReceptorTypes
    | Types.TLicenseStatus
    | Types.TAssignationStatusTypes
    | Types.TAuthorizationTypes
    | Types.TConnectionStatus
): string => {
  switch (type) {
    case 'LICENSE': {
      return i18next.t(`assignation.${value}`) ?? i18next.t('error.unknown');
    }
    case 'RECEPTOR': {
      return i18next.t(`receptors.${value}`) ?? i18next.t('error.unknown');
    }
    case 'ASSIGNATION': {
      return i18next.t(`assignation.${value}`) ?? i18next.t('error.unknown');
    }
    case 'AUTHORIZATION': {
      return i18next.t(`authorization.${value}`) ?? i18next.t('error.unknown');
    }
    case 'CONNECTION': {
      return (
        i18next.t(`connectionStatus.${value}`) ??
        i18next.t(`connectionStatus.disconnected`)
      );
    }
    default:
      return value;
  }
};

export const parseStatus = (
  status: Types.TConnectionStatus | undefined
): any => {
  let color = 'red';

  if (status !== undefined) {
    color = status !== 'disconnected' ? 'green' : 'red';
  }

  const textTranslated =
    status !== undefined
      ? i18next.t(`connectionStatus.${status}`)
      : i18next.t(`connectionStatus.disconnected`);

  return { text: textTranslated, color };
};

export const convertLocationMap = (
  coordinates: number[]
): { lng: number; lat: number } => ({
  lat: coordinates[1],
  lng: coordinates[0]
});

export const convertLicensesLocationMap = (
  location: string
): { lng: number; lat: number } => {
  const locationCoords = location.split(',').map((item) => parseFloat(item));
  return { lat: locationCoords[1], lng: locationCoords[0] };
};

export const validateLocationZero = (location: string) => {
  let haveZero = false;

  if (!location) {
    return haveZero;
  }

  if (location.includes(',')) {
    const locationCoords = location.split(',').map((item) => parseFloat(item));
    haveZero = locationCoords[0] === 0 || locationCoords[1] === 0;
  }

  return haveZero;
};

export const validateEmail = (email: string): boolean => {
  const regex =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const validateFullName = (name: string): boolean => {
  const regex = /^[ a-zA-ZÀ-ÿñÑ]*$/;
  return regex.test(name);
};

export const validateOnlyAlphaNumeric = (value: string): boolean => {
  const regex = /^[a-zA-Z0-9]+$/;
  return regex.test(value);
};

export const validateSpecialCharacters = (value: string): boolean => {
  const regex = /[.,/#!$%^&*;:{}=`~()”“"…]/;
  return regex.test(value);
};

export const validateNotJustSpaces = (value: string): boolean => {
  const noSpaces = value.replace(/\s/g, '');
  return !!noSpaces.length;
};

export const validateGroupName = (name: string): boolean => {
  if (name.length) {
    const regex = /^[-a-zA-Z0-9]+$/;
    const isValid = validateNotJustSpaces(name) && !name.startsWith('-');
    return isValid && regex.test(name);
  }
  return false;
};

export const validateNameSound = (name: string): boolean => {
  if (name.length) {
    const regex = /^[-a-zA-Z0-9]+$/;
    const isValid = validateNotJustSpaces(name) && !name.startsWith('-');
    return isValid && regex.test(name);
  }
  return false;
};

export const validateCommercialId = (name: string): boolean => {
  const regex = /^[-#/*a-zA-Z0-9]+$/;
  return regex.test(name) && validateNotJustSpaces(name);
};

export const validateThingName = (name: string): boolean => {
  if (name.length) {
    const regex = /^[-#*._a-zA-ZÀ-ÿñÑ0-9\s]+$/;
    const isValid = validateNotJustSpaces(name) && !name.startsWith('-');
    return isValid && regex.test(name);
  }
  return false;
};

export const countTotalThingsConnections = (
  list: Interfaces.IThingsTypeItem[] | Interfaces.IGroupThingItems[]
): Interfaces.ITotalTracking => {
  if (!list.length) return TOTAL_CONNECT_TRACKING;

  const totalConnected = Object.values(list).reduce(
    (prev, curr) =>
      curr.connectionStatus && curr.connectionStatus !== 'disconnected'
        ? prev + 1
        : prev,
    0
  );

  const disconnected = list.length - totalConnected;

  return { registered: totalConnected, disconnected };
};

export const countTotalThings = (
  thingCount: Interfaces.IThingReceptorsCount,
  connectedCount = 0
): Interfaces.ITotalTracking => {
  const isPositiveConnection = connectedCount > 0;
  const thingsValues = Object.values(thingCount);

  if (!thingsValues.length) return TOTAL_CONNECT_TRACKING;
  const totalThings = Object.values(thingCount).reduce(
    (a: number, b: any): number => a + b,
    0
  );
  const disconnectedSubtract = totalThings ? totalThings - connectedCount : 0;
  const connections = isPositiveConnection ? connectedCount : 0;
  const disconnected = isPositiveConnection
    ? disconnectedSubtract
    : totalThings;

  return { registered: connections, disconnected };
};

export const filterListGroups = (
  listGroups: Interfaces.IGroupsIoT[],
  customerId: string
): Interfaces.IGroupsIoT[] => {
  if (listGroups.length || Array.isArray(listGroups)) {
    const filteredGroups = Object.values(listGroups).filter((itemGroup) => {
      if (itemGroup?.group) {
        const groupId = itemGroup.group.split(':');
        if (groupId[0] !== customerId) {
          return null;
        }
        return itemGroup;
      }
      return null;
    });

    return filteredGroups;
  }
  return [];
};

export const buildThingGroupId = (
  customerId: string,
  thingId: string,
  type: Types.TReceptorTypes
): string => {
  const blockIoT = 'skyalert-iot:customers';
  const blockThing = 'things';
  return `${blockIoT}:${customerId}:${blockThing}:${type}:${thingId}`;
};

export const hasContainIot = (value: string): boolean => {
  const blockIoT = 'skyalert-iot:customers';
  return value.includes(blockIoT);
};

export const receptorType = (type: string): Types.TReceptorTypes => {
  const { gadget, gadgetMini, gadgetIntegrated } = names.things;

  const receptor = (
    type !== gadget ? type : `${gadgetMini},${gadgetIntegrated}`
  ) as Types.TReceptorTypes;

  return receptor;
};

export const receptorQueryParam = (
  type: Types.TReceptorTypes
): Types.TReceptorTypes | string => {
  const { epicenter, epicenterPro } = names.things;
  return type === epicenter ? `${epicenter},${epicenterPro}` : type;
};

export const getCounterThing = (
  elements: Interfaces.IThingReceptorsCount
): number => {
  if (elements) {
    return Object.values(elements).reduce(
      (a: number, b: any): number => a + b,
      0
    );
  }
  return 0;
};

export const formatCoordDecimals = (
  coords: Interfaces.ICoords,
  returnArray = false
): Interfaces.ICoords | number[] => {
  const NUM_DECIMALS = 6;
  const lat = +coords.lat.toFixed(NUM_DECIMALS);
  const lng = +coords.lng.toFixed(NUM_DECIMALS);

  return !returnArray ? { lat, lng } : [lng, lat];
};

export const pastToPositive = (num: number): number => {
  if (num < 0) {
    return num * -1;
  }
  return num;
};

export const saveActiveChangelogInLocalStorage = (): void => {
  const { userSettings } = names.storageKeys;
  localStorage.setItem(
    userSettings,
    JSON.stringify({
      ...DEFAULT_USER_SETTINGS,
      activeChangelog: true
    })
  );
};

export const addressLicensesFormant = (
  address: Interfaces.IAddressLicense
): string => {
  let fullAddress = i18next.t('error.notDataRegister');

  if (!address) {
    return fullAddress;
  }

  if (Object.values(address).length) {
    const { county, state, zipCode } = address;
    fullAddress = '';

    if (state && state !== i18next.t('error.notAvailable')) {
      fullAddress += ` ${state}`;
    }
    if (county && county !== i18next.t('error.notAvailable')) {
      fullAddress += `${fullAddress.length && ','} ${county}`;
    }
    if (zipCode && zipCode !== i18next.t('error.notAvailable')) {
      fullAddress += `${fullAddress.length && ','} C.P.${zipCode} `;
    }
  }
  return fullAddress.length ? fullAddress : i18next.t('error.notDataRegister');
};

export const sectionSameAsValue = (section: string, value: string) =>
  section === value;

export const validateGroupIdThing = (
  groupId: string,
  customerId: string,
  thingId: string
): boolean => {
  const ELEMENTS_OF_THE_THING_ID = 6;
  const POSITION_CUSTOMER_ID = 2;
  const POSITION_THING_ID = 5;

  const groupIdArray = groupId.split(':');
  const isLengthId = groupIdArray.length === ELEMENTS_OF_THE_THING_ID;
  const customerInPosition = customerId === groupIdArray[POSITION_CUSTOMER_ID];
  const idThingInPosition = thingId === groupIdArray[POSITION_THING_ID];

  return isLengthId && customerInPosition && idThingInPosition;
};

export const validateGroupIdGroup = (
  groupId: string,
  customerId: string,
  groupName: string
): boolean => {
  const groupIdArray = groupId.split(':');

  const POSITION_CUSTOMER_ID = 0;
  const POSITION_GROUP_NAME = groupIdArray.length - 1;

  const customerInPosition = customerId === groupIdArray[POSITION_CUSTOMER_ID];
  const groupNameInPosition = groupName === groupIdArray[POSITION_GROUP_NAME];

  return customerInPosition && groupNameInPosition;
};

// TODO: ADD ENV VARIABLES FOR STAGING & PROD
export const isModeDevelop = (hostname = window.location.hostname): boolean => {
  return ['localhost', '127.0.0.1', '', '::1'].includes(hostname);
};

export const isModeStaging = (hostname = window.location.hostname): boolean => {
  return hostname.includes('staging');
};

export const isFeatureForStaging = (): boolean =>
  isModeDevelop() || isModeStaging();

export const formatDateReport = (date: string | Moment): string => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

export const parseEndDateMonitorReport = (date: string | Moment): string => {
  const nextDate = moment(date).add('hours', 6);
  return formatDateReport(nextDate);
};

export const parseInUTC = (date: string | Moment): string => {
  return moment(date).utc().format();
};

export const filterListForFeatureStaging = <
  T extends { isShowInStaging?: boolean }
>(
  list: T[]
): T[] => {
  const filteredList = Object.values(list).filter((item) => {
    if (!item?.isShowInStaging) {
      return item;
    }

    const isValidForStaging = isFeatureForStaging() ? item : undefined;
    return isValidForStaging;
  });

  return filteredList;
};

export const groupNameThings = (customerID: string): string => {
  return `skyalert-iot:customers:${customerID}:things`;
};

export const parseSearchParams = (params: { [key: string]: string }): string =>
  new URLSearchParams(params).toString();

export const parseParamsThingDetail = (
  id: string,
  name: string,
  commandId: string
): string => {
  const params = {
    id: encodeURIComponent(id),
    name: encodeURIComponent(name),
    commandId: encodeURIComponent(commandId)
  };
  return `?${parseSearchParams(params)}`;
};

export const parseParamsGroupDetail = (id: string, name: string): string => {
  const params = {
    id: encodeURIComponent(id),
    name: encodeURIComponent(name)
  };
  return `?${parseSearchParams(params)}`;
};

export const getParam = (key: string, searchParams: any): string =>
  decodeURIComponent(searchParams.get(key) ?? '');

export const parseCoords = (
  coords: Interfaces.IThingItems['location']
): string => (Array.isArray(coords) ? coords.join(', ').toString() : '');

export const parseDescriptionMonitorThing = ({
  description,
  intensity,
  origin,
  state,
  date,
  nameAudio,
  address,
  typeEvent,
  payload,
  isLicenseeSkyAlert
}: Interfaces.IParseDescriptionThing): string => {
  if (typeEvent === 'earthquake') {
    if (intensity && origin?.length) {
      const earthquakeIntensity = i18next
        .t(`intensities.mx.${intensity}`)
        .toLowerCase();
      const originState = state ? `${origin}, ${state}` : origin;
      return i18next.t('control.commands.earthquake.intensityAndOrigin', {
        intensity: earthquakeIntensity,
        origin: originState
      });
    }
    if (intensity && !origin?.length) {
      const earthquakeIntensity = i18next
        .t(`intensities.mx.${intensity}`)
        .toLowerCase();
      return i18next.t('control.commands.earthquake.intensity', {
        intensity: earthquakeIntensity
      });
    }
    if (!intensity && origin) {
      const originState = state ? `${origin}, ${state}` : origin;
      return i18next.t('control.commands.earthquake.origin', {
        origin: originState
      });
    }

    return i18next.t(description as any);
  }
  if (typeEvent === 'custom-audio' && nameAudio) {
    return i18next.t('control.commands.customAudio.nameAudio', {
      name: nameAudio
    });
  }

  if (typeEvent === 'update-location' && address) {
    const {
      state: addressState = '',
      county: addressCounty = '',
      zipCode: addressZipCode = ''
    } = address;
    return i18next.t('control.commands.updateLocation.location', {
      state: addressState,
      county: addressCounty,
      zipCode: addressZipCode
    });
  }

  if (typeEvent === 'schedule-local-drill' && isLicenseeSkyAlert) {
    if (!date) {
      return i18next.t('control.commands.scheduleLocalDrill.skyAlertLicensee');
    }
    const DATE_FORMAT = 'LLL';
    const parseDate = moment(date).format(DATE_FORMAT);
    return i18next.t(
      'control.commands.scheduleLocalDrill.skyAlertLicenseeDate',
      {
        date: parseDate
      }
    );
  }

  if (typeEvent === 'LOCATION_UPDATED' && payload) {
    const parsePayload = JSON.parse(payload);
    return i18next.t('feedAck.events.description.locationUpdatedWithAddress', {
      state: parsePayload?.address?.state ?? '',
      county: parsePayload?.address?.county ?? '',
      zipCode: parsePayload?.address?.zipCode ?? ''
    });
  }

  return i18next.t(description as any);
};

export const filteredDataList = (
  list: any[],
  value: string,
  field: string
): any[] => {
  const filteredList = list.filter((element: any): any[] => {
    const searchValue = value.toString().toLowerCase();

    if (field.includes('.')) {
      const keys = field.split('.');
      return element[keys[0]][keys[1]]
        .toString()
        .toLowerCase()
        .includes(searchValue);
    }

    return element[field].toString().toLowerCase().includes(searchValue);
  });

  return filteredList;
};

export const getTotalThings = ({
  type,
  things
}: Interfaces.IParamsGetTotalThings): number => {
  const checkIsNaN = (value: string | number): number => {
    return Number.isNaN(+value) ? 0 : (value as number);
  };
  if (type === 'gadget') {
    const totalGadget = checkIsNaN(things.gadget);
    const totalGadgetMini = checkIsNaN(things['gadget-mini']);
    const totalGadgetIntegrated = checkIsNaN(things['gadget-integrated']);
    return totalGadget + totalGadgetMini + totalGadgetIntegrated;
  }

  if (type === 'epicenter') {
    const thingsEpicenter = checkIsNaN(things.epicenter);
    const thingsEpicenterPro = checkIsNaN(things['epicenter-pro']);
    return thingsEpicenter + thingsEpicenterPro;
  }

  return (things[receptorType(`${type}`)] as number) ?? 0;
};
