import React from 'react';
import useAuthClient from '../../../../hooks/useAuthClient';
import useThingDetails from '../../../../hooks/things/useThingDetails';
import Control from '../../../../components/Control';
import { validateGroupIdThing } from '../../../../helpers';
import { IControlRecipient } from '../../../../interfaces';
import { TReceptorTypes } from '../../../../types';

interface IProps {
  id: string;
  name: string;
  groupId: string;
  type: TReceptorTypes;
}

const ThingControl = ({ id, name, groupId, type }: IProps): JSX.Element => {
  const { customerId, isNotUserAdminLevel } = useAuthClient();
  const {
    dataThing: { hasPermissions }
  } = useThingDetails(id);

  const canSendCommands = hasPermissions || isNotUserAdminLevel;
  const isValidId = validateGroupIdThing(groupId, customerId, id);

  const recipient: IControlRecipient = { id, name };

  return (
    <Control
      view='things'
      thingId={id}
      thingType={type}
      groupId={groupId}
      isValidId={isValidId}
      recipient={recipient}
      hasPermissions={canSendCommands}
    />
  );
};

export default ThingControl;
