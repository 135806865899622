import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  ButtonProps,
  Form,
  Header,
  InputProps,
  Modal
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { validateGroupName } from '../../../../helpers';
import { LIMIT_CHARACTERS_NAME_GROUP } from '../../../../config/constants';

import '../../../../scss/layout/Modal.scss';

export interface PropsComponentModalCreateGroup {
  isOpen: boolean;
  isLoading: boolean;
  isSubgroups?: boolean;
  onAccept(data: string): void;
  onCancel(e: SyntheticEvent, data: ButtonProps): void;
}

interface IError {
  state: boolean;
  message: string;
}

const ModalCreateGroup = ({
  isOpen,
  isLoading,
  isSubgroups,
  onAccept,
  onCancel
}: PropsComponentModalCreateGroup): JSX.Element => {
  const { t } = useTranslation();

  const DEFAULT_ERROR = {
    state: false,
    message: t('error.modals.labels.name')
  };

  const [isError, setIsError] = useState<IError>(DEFAULT_ERROR);
  const [name, setName] = useState<string>('');

  const resetStates = (): void => {
    setIsError(DEFAULT_ERROR);
    setName('');
  };

  useEffect(() => {
    if (isOpen) {
      resetStates();
    }
  }, [isOpen]);

  const handlerValidation = (e: SyntheticEvent): void => {
    e.preventDefault();
    if (!validateGroupName(name)) {
      setIsError({ ...isError, state: true });
      return;
    }

    if (name && name.length > LIMIT_CHARACTERS_NAME_GROUP) {
      setIsError({
        state: true,
        message: t('error.modals.labels.nameLong')
      });
      return;
    }

    onAccept(name.trim());
  };

  const handlerOnChange = (e: SyntheticEvent, { value }: InputProps): void => {
    e.preventDefault();
    setName(value.replace(/\s+/g, '-'));
    setIsError(DEFAULT_ERROR);
  };

  const handlerOnClose = (e: SyntheticEvent, data: ButtonProps): void => {
    resetStates();
    onCancel(e, data);
  };

  return (
    <Modal
      size='mini'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modalCreateGroup-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={t(
            `modals.titles.create.${!isSubgroups ? 'group' : 'subgroup'}` as any
          )}
          icon={!isSubgroups ? 'cubes' : 'folder open'}
        />
      </Modal.Header>
      <Modal.Content>
        <Form autoComplete='off'>
          <Form.Input
            fluid
            name='name'
            disabled={isLoading}
            error={isError.state && isError.message}
            onChange={handlerOnChange}
            placeholder={t('modals.labels.group.name')}
            maxLength={LIMIT_CHARACTERS_NAME_GROUP}
            value={name}
          />
        </Form>
        <Header
          as='h6'
          content={`${name.length}/${LIMIT_CHARACTERS_NAME_GROUP}`}
          className='letter-count'
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={handlerOnClose}
          content={t('actions.cancel')}
        />
        <Button
          positive
          disabled={!name || isLoading || name.length <= 1}
          onClick={handlerValidation}
          loading={isLoading}
          content={t('actions.create')}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalCreateGroup.defaultProps = {
  isSubgroups: false
};

export default ModalCreateGroup;
