import React from 'react';
import { Image } from 'semantic-ui-react';
import images from '../../config/images';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';

import './LogoLicensee.scss';

interface IProps {
  section: 'navsidebar' | 'header';
  customerLicensee: string | null;
}

const LICENSEE_IMAGE: Interfaces.IkeyText = {
  DASI: images.logo.DASI,
  FAAM: images.logo.FAAM
};

const LogoLicensee = ({ section, customerLicensee }: IProps): JSX.Element => {
  const licenseeName = Constants.LICENSEE_ID[customerLicensee as string] || '';

  return (
    <div className={`wrapper-logo-licensee ${section}`}>
      {licenseeName && (
        <Image
          fluid
          src={LICENSEE_IMAGE[licenseeName as string]}
          className={`logo-licensee ${licenseeName}`}
        />
      )}
    </div>
  );
};

export default LogoLicensee;
