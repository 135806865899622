import React from 'react';
import { Header, Icon, IconProps, Segment } from 'semantic-ui-react';

import './Empty.scss';

export interface PropsComponentEmpty extends IconProps {
  texts: {
    title: string;
    subtitle?: string;
  };
  fullContainer?: boolean;
  mainContainer?: boolean;
  iconName: IconProps['name'];
}

const Empty = ({
  texts,
  iconName,
  mainContainer,
  fullContainer
}: PropsComponentEmpty): JSX.Element => {
  return (
    <div
      className={`wrapper-empty-section ${fullContainer ? 'full' : ''} ${
        mainContainer ? 'main' : ''
      }`}
    >
      <Segment
        basic
        placeholder
        textAlign='center'
        className='container-empty-section'
      >
        <Header icon className='title' data-testid='empty-title'>
          <Icon name={iconName} className='asset' />
          {texts.title}
          {texts.subtitle && (
            <Header.Subheader className='subtitle'>
              {texts.subtitle}
            </Header.Subheader>
          )}
        </Header>
      </Segment>
    </div>
  );
};

Empty.defaultProps = {
  fullContainer: false,
  mainContainer: false
};

export default Empty;
