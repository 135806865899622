import { useAuthState } from '../contexts/auth';
import { useClientState } from '../contexts/client';
import * as Interfaces from '../interfaces';

interface IUseAuthClient {
  customerId: string;
  isNotUserAdminLevel: boolean;
  validateThingCount(
    thingCount: Interfaces.IThingsTotal
  ): Interfaces.IThingsTotal;
}

export function useAuthClient(): IUseAuthClient {
  const { id: clientId, thingCount: thingCountClient } = useClientState();
  const { customer, isLicensee, adminLevel } = useAuthState();

  const customerId = isLicensee ? clientId : customer;

  const isNotUserAdminLevel = adminLevel !== 'user';

  const validateThingCount = (
    thingCount: Interfaces.IThingsTotal
  ): Interfaces.IThingsTotal =>
    (isLicensee ? thingCountClient : thingCount) as Interfaces.IThingsTotal;

  return {
    customerId,
    isNotUserAdminLevel,
    validateThingCount
  };
}

export default useAuthClient;
