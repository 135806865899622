import React, {
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
  Ref
} from 'react';
import { useAuthState } from '../auth';
import useBreakpoint from '../../hooks/useBreakpoint';
import { INavigation } from '../../interfaces';
import { NAVIGATION_OPTIONS } from '../../config/constants';
import names from '../../config/names';

interface IStateCtxNav {
  section: string;
  subsection: string;
  groupPermission: string;
  isVisibleSidebar: boolean;
  isMobileBreakpoint: boolean;
  isTabOpen: boolean;
  isDownTo: boolean;
  viewInWindowRef: Ref<HTMLElement>;
  topLayoutRef: Ref<HTMLElement>;
  handlerToTop: () => void;
  updateSection: (value: string) => void;
  updateSubsection: (value: string) => void;
  updateVisibleSidebar: () => void;
  updateGroupPermission: (value: string) => void;
  updateIsDownTo: (totalScroll: number) => void;
  setIsVisibleSidebar: Dispatch<SetStateAction<boolean>>;
  setIsTabOpen: Dispatch<SetStateAction<boolean>>;
}

interface IPropsCtxRoutes {
  children: React.ReactNode;
}

const NavStateContext = React.createContext<IStateCtxNav>({} as IStateCtxNav);

export const useNavState = () => {
  const context = useContext(NavStateContext);
  if (context === undefined) {
    throw new Error('useNavState must be used within a NavProvider');
  }

  return context;
};

const getPermissionStore = (): string => {
  const userInfo = localStorage.getItem(names.storageKeys.navigation);
  const permission: string = userInfo
    ? JSON.parse(userInfo).groupPermission
    : 'admin';
  return permission;
};

export const NavProvider: React.FC<IPropsCtxRoutes> = ({
  children
}: IPropsCtxRoutes): JSX.Element => {
  const viewInWindowRef = useRef<HTMLDivElement | null>(null);
  const topLayoutRef = useRef<HTMLDivElement | null>(null);

  const { DEFAULT } = NAVIGATION_OPTIONS;
  const { adminLevel } = useAuthState();
  const {
    screen: { isMobile: isMobileBreakpoint }
  } = useBreakpoint();
  const { navigation } = names.storageKeys;
  const [section, setSection] = useState<string>(
    adminLevel !== 'user' ? DEFAULT.section : names.paths.things.main
  );
  const [subsection, setSubsection] = useState<string>(DEFAULT.subsection);
  const [isTabOpen, setIsTabOpen] = useState<boolean>(false);
  const [isDownTo, setIsDownTo] = useState<boolean>(false);
  const [isVisibleSidebar, setIsVisibleSidebar] = useState<boolean>(
    !isMobileBreakpoint
  );
  const [groupPermission, setGroupPermission] = useState<string>(
    getPermissionStore()
  );

  useEffect(() => {
    setIsVisibleSidebar(!isMobileBreakpoint);
  }, [isMobileBreakpoint]);

  const storeNavigation = (store: INavigation): void => {
    localStorage.setItem(navigation, JSON.stringify(store));
  };

  const updateSection = (newSection: string): void => {
    setSection(newSection);
    setSubsection('');
    storeNavigation({ section: newSection, subsection: '', groupPermission });
  };

  const updateSubsection = (newSubsection: string): void => {
    setSubsection(newSubsection);
    storeNavigation({ section, subsection: newSubsection, groupPermission });
  };

  const updateVisibleSidebar = (): void => {
    setIsVisibleSidebar(!isVisibleSidebar);
  };

  const updateGroupPermission = (permission: string): void => {
    setGroupPermission(permission);
    storeNavigation({ section, subsection, groupPermission: permission });
  };

  const updateIsDownTo = (totalScroll: number): void => {
    if (viewInWindowRef?.current) {
      const windowHeight = window.innerHeight;
      const position = viewInWindowRef?.current?.getBoundingClientRect();
      const isVisibleScreen =
        position.top < windowHeight && position.bottom > 0;
      const windowPercentage = (totalScroll * 100) / windowHeight;
      const isLargeEnough = windowPercentage >= 150;

      setIsDownTo(isVisibleScreen && isLargeEnough);
    } else {
      setIsDownTo(false);
    }
  };

  const handlerToTop = (): void => {
    topLayoutRef?.current?.scrollIntoView();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <NavStateContext.Provider
      value={{
        section,
        subsection,
        groupPermission,
        viewInWindowRef,
        topLayoutRef,
        handlerToTop,
        isDownTo,
        isTabOpen,
        isVisibleSidebar,
        isMobileBreakpoint,
        updateSection,
        updateSubsection,
        updateVisibleSidebar,
        updateGroupPermission,
        setIsVisibleSidebar,
        setIsTabOpen,
        updateIsDownTo
      }}
    >
      {children}
    </NavStateContext.Provider>
  );
};
