/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState, useRef, SyntheticEvent } from 'react';
import type { GoogleMapProps } from '@react-google-maps/api';
import type { TabProps } from 'semantic-ui-react';
import sortBy from 'lodash/sortBy';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useStatusRequest } from '../contexts/request';
import { useClients } from './useClients';
import Analytics from '../controllers/Analytics';
import ReporterLogger from '../controllers/ReporterLogger';
import BackendIoT from '../controllers/Backend/IoT';
import ModelMonitorThing from '../models/MonitorThings';
import { convertLicensesLocationMap, parseParamsThingDetail } from '../helpers';
import names from '../config/names';
import * as Constants from '../config/constants';
import * as Interfaces from '../interfaces';
import * as Types from '../types';

interface IUseMonitorThings {
  isLoading: boolean;
  isLoadingConnections: boolean;
  isLoadingRedirectThing: boolean;
  isLoadingSettingsThing: boolean;
  isOpenModalReport: boolean;
  isOpenModalDetailConnection: boolean;
  isOpenModalCoords: boolean;
  activeTabPaneReceptors: number;
  thingsIdConnected: Interfaces.IRequestThingsConnected['connectedThings'];
  thingsIdIgnored: Interfaces.IRequestThingsConnected['connectedThings'];
  thingConnectionDetails: FetchThingConnectionDetails;
  allThingsList: Interfaces.IParseMonitorThings[];
  recordsConnection: Interfaces.ICounterMonitorConnection;
  selectThingInfo: Interfaces.IParseMonitorThings;
  selectorDaysHistory: Types.TSelectorDaysHistoryConnection;
  coordsThingSelect: GoogleMapProps['center'];
  addressThingSelect: string;
  handleOnCancelModalReport: () => void;
  handleOnCloseModalDetailConnection: () => void;
  handleOnCloseModalCoords: () => void;
  handleRefreshThingInfo: (event: SyntheticEvent) => void;
  handleOnClickSelectorDays: (
    value: Types.TSelectorDaysHistoryConnection,
    thingId: Interfaces.IThingIoT['id']
  ) => void;
  handleOnChangeTabReceptor: (
    event: SyntheticEvent,
    tabProps: TabProps
  ) => void;
  handleOnClickModalContainerConnectionsAndCoords: (
    event: SyntheticEvent,
    thingInfo:
      | Interfaces.IParseMonitorThings
      | Interfaces.ICoordsAddress
      | Interfaces.ISortDataTable
  ) => void;
  handleOnClickIdThing: (
    customerId: Interfaces.IUserIoT['customer'],
    dataRedirect: Interfaces.IRedirect
  ) => Promise<void>;
  handleOnChangeThingIgnored: (
    thingId: Interfaces.IThingIoT['id'],
    isIgnored: boolean
  ) => void;
}

type FetchThingsConnection =
  Interfaces.IRequestThingsConnected['connectedThings'];

interface DateRangeThingsConnections {
  from: Interfaces.IRequestThingConnections['from'];
  to: Interfaces.IRequestThingConnections['to'];
}

interface FetchThingConnectionDetails {
  history: Interfaces.IRequestThingConnections['connectionsHistory'];
  dateRange: DateRangeThingsConnections;
  isError: boolean;
}

interface FetchThingsInformation {
  things: Interfaces.IRequestAllThingsIoT['things'];
  isError: boolean;
}

interface FetchInfoThingsAndThingsConnected {
  things: Interfaces.IRequestAllThingsIoT['things'];
  connected: Interfaces.IRequestThingsConnected['connectedThings'];
  isError: boolean;
}

interface ParamsForFilterByTypeReceptorWithCounterConnected {
  things: Interfaces.IRequestAllThingsIoT['things'];
  thingsConnected: Interfaces.IRequestThingsConnected['connectedThings'];
  filterReceptor: Types.TFilterReceptorMonitorThings;
}

interface FilterByTypeReceptorWithCounterConnected {
  filteredThings: Interfaces.IRequestAllThingsIoT['things'];
  countThingsConnected: number;
  countThingsIgnored: number;
}

const LoggerInstance = ReporterLogger.getInstance();

const { SECTIONS_MONITOR_THINGS } = Constants;

const DEFAULT_INDEX_PANE_RECEPTOR = 0;
const DEFAULT_FILTER_RECEPTOR_BY_PANE: Types.TFilterReceptorMonitorThings =
  SECTIONS_MONITOR_THINGS[DEFAULT_INDEX_PANE_RECEPTOR].receptor;

const INITIAL_COUNTER_MONITOR_CONNECTION: Interfaces.ICounterMonitorConnection =
  {
    total: '--',
    connected: '--',
    disconnected: '--',
    ignored: '--'
  };

const DEFAULT_COUNTER_MONITOR_CONNECTION: Interfaces.ICounterMonitorConnection =
  {
    total: '0',
    connected: '0',
    disconnected: '0',
    ignored: '0'
  };

const DEFAULT_THING_INFO: Interfaces.IParseMonitorThings = {
  id: '',
  name: '',
  type: 'saas',
  customer: '',
  customerName: '',
  connectionStatus: 'disconnected',
  location: '',
  customerSkyalertId: '',
  commandGroupId: '',
  version: '',
  address: Constants.DEFAULT_ADDRESS_THING,
  isDisabled: false
};

const createDateRangeBySelectorDays = (
  selector: Types.TSelectorDaysHistoryConnection
): Interfaces.IRangeDateConnectionsHistory => {
  const convertDateInUTCToISOString = (date: Moment) =>
    moment.utc(date).toISOString();
  if (selector === Types.ESelectorDaysHistoryConnection.now) {
    return {
      to: convertDateInUTCToISOString(moment().endOf('day')),
      from: convertDateInUTCToISOString(moment().startOf('day'))
    };
  }

  if (selector === Types.ESelectorDaysHistoryConnection.threeDays) {
    const threeDaysAgo = moment().subtract(3, 'days');
    return {
      to: convertDateInUTCToISOString(moment()),
      from: convertDateInUTCToISOString(threeDaysAgo)
    };
  }

  const aWeekAgo = moment().subtract(1, 'weeks');
  return {
    to: convertDateInUTCToISOString(moment()),
    from: convertDateInUTCToISOString(aWeekAgo)
  };
};

const DEFAULT_SELECTOR_DAYS_HISTORY_CONNECTIONS: Types.TSelectorDaysHistoryConnection =
  Types.ESelectorDaysHistoryConnection.threeDays;

const DEFAULT_THING_CONNECTION: FetchThingConnectionDetails = {
  history: [],
  dateRange: createDateRangeBySelectorDays(
    DEFAULT_SELECTOR_DAYS_HISTORY_CONNECTIONS
  ),
  isError: false
};

const MILLISECONDS_PER_REQUEST = 300000;
const MILLISECONDS_DELAY_FOR_MESSAGE_RESPONSE = 60000;

let isFirstTimeToSendLoggerErrorForThingsConnected = true;

export const useMonitorThings = ({
  analytics
}: Interfaces.IAnalytics = {}): IUseMonitorThings => {
  const refFilterReceptor = useRef<Types.TFilterReceptorMonitorThings>(
    DEFAULT_FILTER_RECEPTOR_BY_PANE
  );
  const refThingsWithoutFilter = useRef<
    Interfaces.IRequestAllThingsIoT['things']
  >([]);
  const refIntervalIdForRequestThingsConnected =
    useRef<ReturnType<typeof setInterval>>();
  const refInQueueRequestThingsConnected = useRef<boolean>(false);

  const { t: translation } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();
  const { selectClient } = useClients();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingConnections, setIsLoadingConnections] =
    useState<boolean>(false);
  const [isLoadingRedirectThing, setIsLoadingRedirectThing] =
    useState<boolean>(false);
  const [isLoadingSettingsThing, setIsLoadingSettingsThing] =
    useState<boolean>(false);
  const [isOpenModalReport, setIsOpenModalReport] = useState<boolean>(false);
  const [isOpenModalDetailConnection, setIsOpenModalDetailConnection] =
    useState<boolean>(false);
  const [isOpenModalCoords, setIsOpenModalCoords] = useState<boolean>(false);
  const [activeTabPaneReceptors, setActiveTabPaneReceptors] = useState<number>(
    DEFAULT_INDEX_PANE_RECEPTOR
  );
  const [filterTypeReceptor, setFilterTypeReceptor] =
    useState<Types.TFilterReceptorMonitorThings>(
      DEFAULT_FILTER_RECEPTOR_BY_PANE
    );
  const [recordsConnection, setRecordsConnection] =
    useState<Interfaces.ICounterMonitorConnection>(
      INITIAL_COUNTER_MONITOR_CONNECTION
    );
  const [allThingsList, setAllThingsList] = useState<
    Interfaces.IParseMonitorThings[]
  >([]);
  const [thingsIdIgnored, setThingsIdIgnored] = useState<
    Interfaces.IThingItems['id'][]
  >([]);
  const [thingsIdConnected, setThingsIdConnected] = useState<
    Interfaces.IRequestThingsConnected['connectedThings']
  >([]);
  const [thingConnectionDetails, setThingConnectionDetails] =
    useState<FetchThingConnectionDetails>(DEFAULT_THING_CONNECTION);
  const [selectThingInfo, setSelectThingInfo] =
    useState<Interfaces.IParseMonitorThings>(DEFAULT_THING_INFO);
  const [coordsThingSelect, setCoordsThingSelect] = useState<
    GoogleMapProps['center']
  >(Constants.DEFAULT_COORDS);
  const [addressThingSelect, setAddressThingSelect] = useState<string>('');
  useState<Interfaces.IParseMonitorThings>(DEFAULT_THING_INFO);
  const [selectorDaysHistory, setSelectorDaysHistory] =
    useState<Types.TSelectorDaysHistoryConnection>(
      DEFAULT_SELECTOR_DAYS_HISTORY_CONNECTIONS
    );

  const saveThingInfoInLocalStorage = (
    things: Interfaces.IRequestAllThingsIoT['things']
  ): void => {
    localStorage.setItem(
      names.storageKeys.monitorInfoThings,
      JSON.stringify(things)
    );
  };

  const getThingsInfoFromLocalStorage =
    (): Interfaces.IRequestAllThingsIoT['things'] => {
      const thingsStored = localStorage.getItem(
        names.storageKeys.monitorInfoThings
      );
      if (thingsStored) {
        return JSON.parse(thingsStored);
      }

      return [];
    };

  const searchNameReceptorTypeWithIndex = (
    index: number
  ): Types.TFilterReceptorMonitorThings =>
    SECTIONS_MONITOR_THINGS[index].receptor;

  const parseDataItemThing = (
    rawData: Interfaces.IMonitorAllThings,
    thingsConnected: Interfaces.IRequestThingsConnected['connectedThings']
  ) => new ModelMonitorThing().init(rawData).parseReport(thingsConnected);

  const orderThingsByConnectionStatus = (
    things: Interfaces.IParseMonitorThings[]
  ): Interfaces.IParseMonitorThings[] => {
    return sortBy(things, ({ connectionStatus }) =>
      connectionStatus?.toLowerCase() === 'disconnected' ? 0 : 1
    );
  };

  const filterByTypeReceptorAndCountingThingsConnected = ({
    things,
    thingsConnected,
    filterReceptor
  }: ParamsForFilterByTypeReceptorWithCounterConnected): FilterByTypeReceptorWithCounterConnected => {
    if (filterReceptor === 'all') {
      const countDisabledMonitoring = things.reduce(
        (acc: string[], { settings, id }) =>
          settings && !!settings?.monitoringDisabled ? acc.concat(id) : acc,
        []
      );
      return {
        filteredThings: things,
        countThingsConnected: thingsConnected.length,
        countThingsIgnored: countDisabledMonitoring.length
      };
    }

    let countThingsConnected = 0;
    let countThingsIgnored = 0;

    const thingsFilteredByReceptor = things.filter(({ type, id, settings }) => {
      const isConnected = thingsConnected.includes(id);
      const isMatchingType = type.includes(filterReceptor);
      if (
        isConnected &&
        isMatchingType &&
        settings &&
        !!settings?.monitoringDisabled
      ) {
        countThingsConnected += 1;
        countThingsIgnored += 1;
      }

      return isMatchingType;
    });

    return {
      filteredThings: thingsFilteredByReceptor,
      countThingsConnected,
      countThingsIgnored
    };
  };

  const updateThingsAndConnections = ({
    filteredThings,
    countThingsConnected,
    countThingsIgnored,
    isError = false
  }: {
    filteredThings: Interfaces.IMonitorAllThings[];
    countThingsConnected: number;
    countThingsIgnored: number;
    isError?: boolean;
  }): void => {
    let parsedThingsForRender: Interfaces.IParseMonitorThings[] = [];
    let counterThingsConnection: Interfaces.ICounterMonitorConnection = {
      ...DEFAULT_COUNTER_MONITOR_CONNECTION
    };

    if (filteredThings.length) {
      // TODO: AVOID MAP
      const parsedThingsByReceptor = filteredThings.map((thing) =>
        parseDataItemThing(thing, thingsIdConnected)
      );

      parsedThingsForRender = orderThingsByConnectionStatus(
        parsedThingsByReceptor
      );
    }

    const totalThings = parsedThingsForRender.length.toString();

    const disconnectedThingsCount =
      parsedThingsForRender.length > countThingsConnected
        ? parsedThingsForRender.length - countThingsConnected
        : countThingsConnected;

    counterThingsConnection = {
      total: totalThings,
      connected: countThingsConnected.toString(),
      disconnected:
        countThingsConnected > 0
          ? disconnectedThingsCount.toString()
          : totalThings,
      ignored: countThingsIgnored.toString()
    };

    if (isError) {
      counterThingsConnection = INITIAL_COUNTER_MONITOR_CONNECTION;
    }

    setAllThingsList(parsedThingsForRender);
    setRecordsConnection(counterThingsConnection);
  };

  const toggleOpenModalThing = (): void => {
    setIsOpenModalReport((prevState) => !prevState);
  };

  const toggleOpenModalDetailConnection = (): void => {
    setIsOpenModalDetailConnection((prev) => !prev);
  };

  const toggleOpenModalCoords = (): void => {
    setIsOpenModalCoords((prev) => !prev);
  };

  // REQUESTS

  const fetchThingsInformation = async (): Promise<FetchThingsInformation> => {
    try {
      const { things } = await BackendIoT.getAllThings();
      saveThingInfoInLocalStorage(things);
      return {
        things,
        isError: false
      };
    } catch (error) {
      LoggerInstance.error(
        'Failed to get info things - useMonitorThings - "fetchThingConnections"',
        error
      );
      UpdateMessageResponse({
        type: 'error',
        message: translation('error.requests.monitor.fetchThingsInfo'),
        delay: MILLISECONDS_DELAY_FOR_MESSAGE_RESPONSE
      });
      saveThingInfoInLocalStorage([]);
    }
    return {
      things: [],
      isError: true
    };
  };

  const fetchThingConnections = async (
    thingId: Interfaces.IThingItems['id'],
    range = thingConnectionDetails.dateRange
  ): Promise<FetchThingConnectionDetails> => {
    const query = { ...range };
    try {
      const { connectionsHistory, from, to } =
        await BackendIoT.getThingConnection(thingId, query);

      const history = connectionsHistory
        .reverse()
        .map((recordConnection, index) => ({
          ...recordConnection,
          index: index + 1
        }));

      return {
        history,
        dateRange: { from, to },
        isError: false
      };
    } catch (error) {
      LoggerInstance.error(
        'Failed to get history connection - useMonitorThings - "fetchThingConnections"',
        error
      );
      UpdateMessageResponse({
        type: 'error',
        message: translation('error.requests.monitor.historyConnection'),
        delay: MILLISECONDS_DELAY_FOR_MESSAGE_RESPONSE
      });
      return {
        ...DEFAULT_THING_CONNECTION,
        isError: true
      };
    }
  };

  const fetchThingsConnected = async (): Promise<FetchThingsConnection> => {
    try {
      const { connectedThings } = await BackendIoT.getThingsConnected();
      return connectedThings;
    } catch (error) {
      if (isFirstTimeToSendLoggerErrorForThingsConnected) {
        isFirstTimeToSendLoggerErrorForThingsConnected = false;
        LoggerInstance.error(
          'Failed to get things connected - useMonitorThings - "fetchThingConnections"',
          error
        );
        UpdateMessageResponse({
          type: 'error',
          message: translation('error.requests.monitor.thingsConnected'),
          delay: MILLISECONDS_DELAY_FOR_MESSAGE_RESPONSE
        });
      }
      return [];
    }
  };

  const fetchThingsInfoAndConnections = async () => {
    try {
      const thingsRequests: [
        Promise<Interfaces.IRequestThingsConnected>,
        Promise<Interfaces.IRequestAllThingsIoT>
      ] = [BackendIoT.getThingsConnected(), BackendIoT.getAllThings()];
      const [responseConnected, responseInformation] = await Promise.all(
        thingsRequests
      );
      saveThingInfoInLocalStorage(responseInformation.things);
      return {
        connected: responseConnected.connectedThings,
        things: responseInformation.things,
        isError: false
      };
    } catch (error) {
      LoggerInstance.error(
        'Failed to get things information (connection & information) - useMonitorThings - "fetchThingConnections"',
        error
      );
      UpdateMessageResponse({
        type: 'error',
        message: translation('error.requests.monitor.fetchThingsInfo'),
        delay: MILLISECONDS_DELAY_FOR_MESSAGE_RESPONSE
      });
      saveThingInfoInLocalStorage([]);
      return {
        connected: [],
        things: [],
        isError: true
      };
    }
  };

  const fetchInfoThingsAndThingsConnected =
    async (): Promise<FetchInfoThingsAndThingsConnected> => {
      const storedThings = getThingsInfoFromLocalStorage();
      if (storedThings.length <= 0) {
        return fetchThingsInfoAndConnections();
      }

      const connected = await fetchThingsConnected();
      return {
        connected,
        things: storedThings,
        isError: false
      };
    };

  const sendConfigThing = async (
    id: Interfaces.IThingItems['id'],
    settings: Interfaces.IBodySettings
  ): Promise<boolean> => {
    try {
      await BackendIoT.setThingSettings(id, settings);
      UpdateMessageResponse({
        type: 'success',
        action: translation('actions.requests.update.settings'),
        message: translation('success.requests.things.update')
      });
      return true;
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: translation('error.requests.things.updateSettings'),
        delay: 6000
      });
      LoggerInstance.error(
        'Failed edit settings - useThingSettings - "sendConfigThing"',
        error
      );
      return false;
    }
  };

  const requestInfoThings = async (): Promise<void> => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const { things, isError: isErrorRequestThings } =
      await fetchThingsInformation();
    const countThingsMonitoring = things.reduce(
      (acc: string[], { settings, id }) =>
        settings && !!settings?.monitoringDisabled ? acc.concat(id) : acc,
      []
    );

    updateThingsAndConnections({
      filteredThings: things,
      countThingsConnected: thingsIdConnected.length,
      countThingsIgnored: countThingsMonitoring.length,
      isError: isErrorRequestThings
    });
    setThingsIdConnected(thingsIdConnected);
    setIsLoading(false);
  };

  const requestThingsConnected = async (): Promise<void> => {
    if (refInQueueRequestThingsConnected.current) {
      return;
    }

    refInQueueRequestThingsConnected.current = true;
    const thingsConnected = await fetchThingsConnected();
    setThingsIdConnected(thingsConnected);
    refInQueueRequestThingsConnected.current = false;
  };

  const requestThingConnections = async (
    thingId: Interfaces.IThingItems['id'],
    selector = selectorDaysHistory
  ): Promise<void> => {
    setIsLoadingConnections(true);
    const { isError, history, dateRange } = await fetchThingConnections(
      thingId,
      createDateRangeBySelectorDays(selector)
    );
    setThingConnectionDetails({
      history,
      dateRange,
      isError
    });
    setIsLoadingConnections(false);
  };

  // INTERVAL
  const createIntervalForThingsConnected = (): void => {
    refIntervalIdForRequestThingsConnected.current = setInterval(
      requestThingsConnected,
      MILLISECONDS_PER_REQUEST
    );
  };

  const clearIntervalForThingsConnected = (): void => {
    if (refIntervalIdForRequestThingsConnected.current) {
      clearInterval(refIntervalIdForRequestThingsConnected.current);
    }
  };

  const resetIntervalForThingsConnected = async (): Promise<void> => {
    clearIntervalForThingsConnected();
    await requestThingsConnected();
    createIntervalForThingsConnected();
  };

  const resetIntervalForInfoThings = async (): Promise<void> => {
    clearIntervalForThingsConnected();
    await requestInfoThings();
    createIntervalForThingsConnected();
  };

  const initialRequestThings = async (): Promise<void> => {
    const {
      connected: thingsConnected,
      things,
      isError
    } = await fetchInfoThingsAndThingsConnected();

    refThingsWithoutFilter.current = things;

    const { filteredThings, countThingsConnected, countThingsIgnored } =
      filterByTypeReceptorAndCountingThingsConnected({
        things,
        thingsConnected,
        filterReceptor: filterTypeReceptor
      });

    const filteredThingsIgnored = things.reduce(
      (acc: string[], { settings, id }) =>
        settings && settings.monitoringDisabled ? acc.concat(id) : acc,
      []
    );

    updateThingsAndConnections({
      filteredThings,
      countThingsConnected,
      countThingsIgnored,
      isError
    });
    setThingsIdConnected(thingsConnected);
    setThingsIdIgnored(filteredThingsIgnored);
    setIsLoading(false);
  };

  useEffect(() => {
    initialRequestThings();
    createIntervalForThingsConnected();

    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
    return () => clearIntervalForThingsConnected();
  }, []);

  useEffect(() => {
    if (refFilterReceptor.current !== filterTypeReceptor) {
      refFilterReceptor.current = filterTypeReceptor;
      resetIntervalForThingsConnected();

      if (refThingsWithoutFilter.current.length) {
        const { filteredThings, countThingsConnected, countThingsIgnored } =
          filterByTypeReceptorAndCountingThingsConnected({
            things: refThingsWithoutFilter.current,
            thingsConnected: thingsIdConnected,
            filterReceptor: filterTypeReceptor
          });

        updateThingsAndConnections({
          filteredThings,
          countThingsConnected,
          countThingsIgnored
        });
      }
    }
  }, [filterTypeReceptor]);

  useEffect(() => {
    if (refThingsWithoutFilter.current.length) {
      const { filteredThings, countThingsConnected, countThingsIgnored } =
        filterByTypeReceptorAndCountingThingsConnected({
          things: refThingsWithoutFilter.current,
          thingsConnected: thingsIdConnected,
          filterReceptor: filterTypeReceptor
        });

      updateThingsAndConnections({
        filteredThings,
        countThingsConnected,
        countThingsIgnored
      });
    }
  }, [thingsIdConnected]);

  const handleRefreshThingInfo = (event: SyntheticEvent): void => {
    event.preventDefault();
    resetIntervalForInfoThings();
  };

  const handleOnChangeTabReceptor = (
    event: SyntheticEvent,
    { activeIndex: activeIndexTabReceptor }: TabProps
  ): void => {
    event.preventDefault();
    if (activeIndexTabReceptor !== activeTabPaneReceptors) {
      const parseActiveTabIndex = Number(activeIndexTabReceptor);
      setActiveTabPaneReceptors(parseActiveTabIndex);
      setFilterTypeReceptor(
        searchNameReceptorTypeWithIndex(parseActiveTabIndex)
      );
    }
  };

  const handleOnCancelModalReport = (): void => {
    toggleOpenModalThing();
  };

  const handleOnClickModalContainerConnectionsAndCoords = (
    event: SyntheticEvent,
    thingInfo:
      | Interfaces.IParseMonitorThings
      | Interfaces.ICoordsAddress
      | Interfaces.ISortDataTable
  ): void => {
    event.preventDefault();
    const keysThingsInfo = Object.keys(thingInfo);
    if (keysThingsInfo.includes('coords')) {
      const parseLocation = thingInfo as Interfaces.ICoordsAddress;
      if (parseLocation.coords.includes(',')) {
        setCoordsThingSelect(convertLicensesLocationMap(parseLocation.coords));
        setAddressThingSelect(parseLocation.address);
        toggleOpenModalCoords();
      }
    } else {
      const parseMoreInfo = thingInfo as Interfaces.IParseMonitorThings;
      toggleOpenModalDetailConnection();
      requestThingConnections(parseMoreInfo.id);
      setSelectThingInfo(parseMoreInfo);
    }
  };

  const handleOnCloseModalDetailConnection = (): void => {
    toggleOpenModalDetailConnection();
    setSelectorDaysHistory(DEFAULT_SELECTOR_DAYS_HISTORY_CONNECTIONS);
  };

  const handleOnCloseModalCoords = (): void => {
    toggleOpenModalCoords();
  };

  const handleOnClickSelectorDays = (
    value: Types.TSelectorDaysHistoryConnection,
    thingId: Interfaces.IThingIoT['id']
  ): void => {
    if (selectorDaysHistory !== value) {
      setSelectorDaysHistory(value);
      requestThingConnections(thingId, value);
    }
  };

  const createTempRedirectToDevice = (dataRedirect: Interfaces.IRedirect) => {
    const { name, id, type: typePath, groupId } = dataRedirect;
    const path = `${names.paths.things.detail(
      typePath
    )}${parseParamsThingDetail(id, name, groupId)}`;

    const createLinkTemp = document.createElement('a');

    Object.assign(createLinkTemp, {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: path
    }).click();

    setTimeout(() => {
      createLinkTemp.remove();
    }, 100);
  };

  const handleOnClickIdThing = async (
    customerId: Interfaces.IUserIoT['customer'],
    dataRedirect: Interfaces.IRedirect
  ): Promise<void> => {
    setIsLoadingRedirectThing(true);
    let isError = false;
    try {
      selectClient(customerId, false);
    } catch (error) {
      isError = true;
      LoggerInstance.error(
        'Failed to select client on redirect thing- useMonitorThings - "handleOnClickIdThing"',
        error
      );
    } finally {
      setIsLoadingRedirectThing(false);
      if (!isError) {
        createTempRedirectToDevice(dataRedirect);
      }
    }
  };

  const handleOnChangeThingIgnored = async (
    thingId: Interfaces.IThingIoT['id'],
    isIgnored: boolean
  ): Promise<void> => {
    setIsLoadingSettingsThing(true);

    const successfullyRequest = await sendConfigThing(thingId, {
      monitoringDisabled: isIgnored
    });

    if (successfullyRequest) {
      resetIntervalForInfoThings();
      const thingsFromLocalStorage = getThingsInfoFromLocalStorage();
      const updateThingsWithNewThingSettings = thingsFromLocalStorage.map(
        (thing) =>
          thing.id === thingId
            ? {
                ...thing,
                settings: { ...thing.settings, monitoringDisabled: isIgnored }
              }
            : thing
      );
      saveThingInfoInLocalStorage(updateThingsWithNewThingSettings);

      let tempThingsIgnored = [];
      if (!isIgnored) {
        tempThingsIgnored = thingsIdIgnored.filter((id) => id !== thingId);
      } else {
        tempThingsIgnored = [...thingsIdIgnored, thingId];
      }
      setThingsIdIgnored(tempThingsIgnored);
    }

    setIsLoadingSettingsThing(false);
  };

  return {
    isLoading,
    isLoadingConnections,
    isLoadingRedirectThing,
    isLoadingSettingsThing,
    thingConnectionDetails,
    activeTabPaneReceptors,
    allThingsList,
    recordsConnection,
    selectThingInfo,
    selectorDaysHistory,
    addressThingSelect,
    coordsThingSelect,
    thingsIdConnected,
    isOpenModalReport,
    isOpenModalCoords,
    isOpenModalDetailConnection,
    thingsIdIgnored,
    handleOnClickIdThing,
    handleRefreshThingInfo,
    handleOnChangeTabReceptor,
    handleOnCancelModalReport,
    handleOnCloseModalCoords,
    handleOnClickSelectorDays,
    handleOnChangeThingIgnored,
    handleOnCloseModalDetailConnection,
    handleOnClickModalContainerConnectionsAndCoords
  };
};

export default useMonitorThings;
