import React, { useEffect, useState } from 'react';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';
import {
  withServiceWorkerUpdater,
  ServiceWorkerUpdaterProps
} from '@3m1/service-worker-updater';
import Amplify from 'aws-amplify';
import AWSconfig from './config/aws-exports';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { useAuthState } from './contexts/auth';
import { useSettings } from './contexts/settings';
import Settings from './components/Settings';
import Tutorial from './components/Tutorial';
import ReleaseNotes from './components/ReleaseNotes';
import RoutesAppFilter, { filterRoutesForBrowserRouter } from './config/routes';
import * as Interfaces from './interfaces';

import './scss/layout/App.scss';

Amplify.configure(AWSconfig);

const App = ({
  newServiceWorkerDetected,
  onLoadNewServiceWorkerAccept
}: ServiceWorkerUpdaterProps): JSX.Element => {
  const [routesAppDynamic, setRoutesAppDynamic] =
    useState<Interfaces.PropsCustomObjectRoutes[]>(RoutesAppFilter);

  const { adminLevel } = useAuthState();
  const {
    isOpenReleaseNotes,
    onAcceptReleaseNote,
    isOpenSettings,
    toggleStateSettings,
    isOpenTutorial,
    onFinishTutorial
  } = useSettings();

  const isNotClientRoleUser = adminLevel !== 'user';

  useEffect(() => {
    if (isNotClientRoleUser) {
      setRoutesAppDynamic(filterRoutesForBrowserRouter(adminLevel));
    }
  }, [adminLevel]);

  return (
    <>
      <ServiceWorkerWrapper
        onLoad={onLoadNewServiceWorkerAccept}
        isOpenMessage={newServiceWorkerDetected}
      />
      <RouterProvider
        router={createBrowserRouter(routesAppDynamic as RouteObject[])}
      />
      <Settings isOpen={isOpenSettings} onCancel={toggleStateSettings} />
      {isOpenTutorial && <Tutorial isOpen onClose={onFinishTutorial} />}
      {isOpenReleaseNotes && (
        <ReleaseNotes isOpen onAccept={onAcceptReleaseNote} />
      )}
    </>
  );
};

export default withServiceWorkerUpdater(App);
