import { SyntheticEvent, useEffect, useState } from 'react';
import { GoogleMapProps } from '@react-google-maps/api';
import orderBy from 'lodash/orderBy';
import { convertLicensesLocationMap, filteredDataList } from '../../helpers';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

interface PropsUseThingTypeValidation {
  query: {
    thingsConnected: Interfaces.IRequestThingsConnected['connectedThings'];
    listFilteredThings: Interfaces.IThingsTypeItem[];
    isLoadingFetch: boolean;
    isDesk: boolean;
  };
}
interface IUseThingTypeValidation {
  coords: GoogleMapProps['center'];
  addressLicense: string;
  isOpenModalCoords: boolean;
  thingsUpdateConnection: Interfaces.IThingsTypeItem[];
  handleOnCancelModalCoords(): void;
  handleSearchData(searchValue: Interfaces.ISearchValue): void;
  handleSetModalParams(
    e: SyntheticEvent<HTMLElement>,
    data: Interfaces.ICoordsAddress | Interfaces.ISortDataTable
  ): void;
}

const DEFAULT_SEARCHING_VALUE: Interfaces.ISearchValue = {
  value: '',
  field: ''
};

const DEFAULT_SORT_VALUES: Interfaces.ISortDataTable = {
  column: '',
  isDescending: true
};

export const useThingTypeValidation = ({
  query
}: PropsUseThingTypeValidation): IUseThingTypeValidation => {
  const { thingsConnected, listFilteredThings, isLoadingFetch, isDesk } = query;

  const [coords, setCoords] = useState<GoogleMapProps['center']>(
    Constants.DEFAULT_COORDS
  );
  const [addressLicense, setAddressLicense] = useState<string>('');
  const [isOpenModalCoords, setIsOpenModalCoords] = useState<boolean>(false);
  const [thingsUpdateConnection, setThingsUpdateConnection] =
    useState<Interfaces.IThingsTypeItem[]>(listFilteredThings);
  const [searching, setSearching] = useState<Interfaces.ISearchValue>(
    DEFAULT_SEARCHING_VALUE
  );
  const [sortData, setSortData] =
    useState<Interfaces.ISortDataTable>(DEFAULT_SORT_VALUES);

  const clearStatesSearch = (): void => {
    setSearching(DEFAULT_SEARCHING_VALUE);
    setSortData(DEFAULT_SORT_VALUES);
  };

  const validateFilteredSearchThingsList = (
    listThings: Interfaces.IThingsTypeItem[]
  ): void => {
    let dataThings: Interfaces.IThingsTypeItem[] = listThings;

    const thingsConnection: Interfaces.IThingsTypeItem[] = Object.values(
      listFilteredThings
    ).map((thing) => {
      const statusConnection: Types.TConnectionStatus =
        thingsConnected.includes(thing.id) ? 'connected' : 'disconnected';
      return { ...thing, connectionStatus: statusConnection };
    });

    dataThings = thingsConnection;

    if (searching.value.length) {
      const { value, field } = searching;
      dataThings = filteredDataList(dataThings, value, field);
    }

    if (sortData.column.length) {
      dataThings = orderBy(
        dataThings,
        [`${sortData.column}`],
        sortData.isDescending ? 'desc' : 'asc'
      );
    }

    setThingsUpdateConnection(dataThings);
  };

  useEffect(() => {
    if (isLoadingFetch) {
      clearStatesSearch();
    }
    if (isDesk) {
      setThingsUpdateConnection(listFilteredThings);
    } else {
      validateFilteredSearchThingsList(listFilteredThings);
    }
  }, [thingsConnected, listFilteredThings, searching, isLoadingFetch]);

  const handleSetModalParams = (
    e: SyntheticEvent<HTMLElement>,
    data: Interfaces.ICoordsAddress | Interfaces.ISortDataTable
  ) => {
    const keysThingsInfo = Object.keys(data);
    if (keysThingsInfo.includes('column')) {
      setSortData(data as Interfaces.ISortDataTable);
    } else if (keysThingsInfo.includes('coords')) {
      const location = data as Interfaces.ICoordsAddress;

      if (location.coords.includes(',')) {
        setCoords(convertLicensesLocationMap(location.coords));
        setAddressLicense(location.address);
        setIsOpenModalCoords(true);
      }
    }
  };

  const handleSearchData = (searchValue: Interfaces.ISearchValue) =>
    setSearching(searchValue);

  const handleOnCancelModalCoords = () => setIsOpenModalCoords(false);

  return {
    coords,
    addressLicense,
    isOpenModalCoords,
    thingsUpdateConnection,
    handleOnCancelModalCoords,
    handleSearchData,
    handleSetModalParams
  };
};

export default useThingTypeValidation;
