import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Modal } from 'semantic-ui-react';

export interface PropsComponentModalInfo {
  isError?: boolean;
  isOpen: boolean;
  textInfo: string;
  onAccept(): void;
  onCancel?(): void | null;
}

const ModalInfo = ({
  isError,
  isOpen,
  textInfo,
  onAccept,
  onCancel
}: PropsComponentModalInfo): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal
      size='mini'
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modalInfo-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={t(isError ? 'modals.titles.error' : 'modals.titles.info')}
          icon={isError ? 'warning circle' : 'info circle'}
        />
      </Modal.Header>
      <Modal.Content>
        <Header as='h4' content={textInfo} />
      </Modal.Content>
      <Modal.Actions>
        {onCancel && (
          <Button negative onClick={onCancel} content={t('actions.cancel')} />
        )}
        <Button positive onClick={onAccept} content={t('actions.done')} />
      </Modal.Actions>
    </Modal>
  );
};

ModalInfo.defaultProps = {
  isError: false,
  onCancel: null
};

export default ModalInfo;
