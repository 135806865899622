import React from 'react';
import {
  Message,
  MessageContentProps,
  SemanticTRANSITIONS,
  Transition,
  TransitionPropDuration
} from 'semantic-ui-react';
import Markdown from 'markdown-to-jsx';

import './MessageUI.scss';

export interface PropsComponentMessageUI extends MessageContentProps {
  isVisible: boolean;
  animation?: SemanticTRANSITIONS;
  duration?: number | string | TransitionPropDuration;
}

const MessageUI = ({
  isVisible,
  animation,
  duration,
  content,
  ...propsMessage
}: PropsComponentMessageUI): JSX.Element => {
  return (
    <Transition animation={animation} duration={duration} visible={isVisible}>
      <Message
        className='wrapper-messageUI'
        content={content ? <Markdown>{content as string}</Markdown> : ''}
        {...propsMessage}
      />
    </Transition>
  );
};

MessageUI.defaultProps = {
  animation: 'fade',
  duration: 900
};

export default MessageUI;
