import React, { SyntheticEvent } from 'react';
import {
  Dropdown,
  Image,
  DropdownProps,
  StrictDropdownProps,
  Header
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavState } from '../../contexts/navigation';
// import defaultImage from '../../assets/images/user.png';
import { ACCOUNT_PERMISSION } from '../../config/constants';
import { TUserTypes } from '../../types';

import './Avatar.scss';

export interface PropsComponentAvatar extends StrictDropdownProps {
  onChangeSelect: (
    event: SyntheticEvent<HTMLElement>,
    Dropdown: DropdownProps
  ) => void;
  onClick(): void;
  userName: string;
  userPermission: TUserTypes;
  pic: string;
}

const Avatar = ({
  onChangeSelect,
  onClick,
  userName,
  userPermission,
  pic,
  ...rest
}: PropsComponentAvatar): JSX.Element => {
  const { t: translations } = useTranslation();
  const { isMobileBreakpoint } = useNavState();
  const initial = userName ? userName.slice(0, 1) : '';

  const renderImage = () => {
    if (pic && pic.length) {
      return <Image avatar src={pic} />;
    }
    if (userName && userName.length) {
      return (
        <div className='container__name'>
          <p>{initial}</p>
        </div>
      );
    }
    return <></>;
    // return <Image avatar src={defaultImage} />;
  };

  const trigger = (
    <span className='user-profile'>
      {renderImage()}
      {!isMobileBreakpoint && (
        <Header
          as='h3'
          content={userName}
          subheader={translations(ACCOUNT_PERMISSION[userPermission])}
          style={{ margin: '0' }}
          className='userName'
        />
      )}
    </span>
  );

  return (
    <Dropdown
      data-testid='avatar-component'
      className='wrapper-avatar'
      trigger={trigger}
      onChange={onChangeSelect}
      onClick={onClick}
      {...rest}
    />
  );
};

export default Avatar;
