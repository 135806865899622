import React, { SyntheticEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Header, Icon, Table, Segment, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../../controllers/Analytics';
import { useNavState } from '../../../contexts/navigation';
import { useAuthState } from '../../../contexts/auth';
import { useGroups } from '../../../hooks/groups/useGroups';
import { SkeletonBlock } from '../../../components/Skeletons';
import Empty from '../../../components/Empty';
import names from '../../../config/names';
import { GROUP_PERMISSIONS } from '../../../config/constants';
import * as Constants from '../../../config/constants';
import { IRedirect } from '../../../interfaces';
import { TPermissionTypes } from '../../../types';

import './GroupsList.scss';
import { parseParamsGroupDetail } from '../../../helpers';

const GroupsList = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { adminLevel } = useAuthState();
  const { updateGroupPermission } = useNavState();
  const {
    isLoadingRequest,
    dataGroups: { listInfo }
  } = useGroups();

  const handlerClickRedirect = (
    e: SyntheticEvent<HTMLElement>,
    { id, name }: IRedirect,
    permissions: TPermissionTypes[]
  ): void => {
    e.preventDefault();
    if (id) {
      const path = {
        pathname: names.paths.groups.detail,
        search: parseParamsGroupDetail(id, name)
      };
      navigate(path, { state: { groupName: name, groupId: id } });
    }
    updateGroupPermission(permissions[0]);

    Analytics.sendEventCustom({
      category: 'Dashboard',
      action: 'Redirect',
      label: 'Groups'
    });
  };

  const renderState = (): JSX.Element => {
    if (isLoadingRequest.fetch) {
      return <SkeletonBlock />;
    }

    if (!listInfo.length) {
      const { texts, userText, icon } =
        Constants.PROPS_EMPTY_LAYER_CONTAINER.GROUPS;
      const messageTexts = adminLevel !== 'user' ? texts : userText || texts;
      return (
        <Empty
          iconName={icon}
          texts={{
            title: t(messageTexts.title as any),
            subtitle: t(messageTexts.subtitle as any)
          }}
        />
      );
    }

    return (
      <Table basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ padding: 0, paddingBottom: '0.9em' }}>
              {t('tableList.header.name')}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ padding: 0, paddingBottom: '0.9em' }}>
              {t('tableList.header.permission')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {listInfo.map(({ group, groupName, permissions }, index) => (
            <Table.Row key={`row-${index + 1}`}>
              <Table.Cell>
                <Link
                  to={{
                    pathname: names.paths.groups.detail,
                    search: parseParamsGroupDetail(group, groupName)
                  }}
                  onClick={(e: SyntheticEvent<HTMLElement>) =>
                    handlerClickRedirect(
                      e,
                      { id: group, name: groupName } as IRedirect,
                      (permissions as TPermissionTypes[]) ||
                        (['admin'] as TPermissionTypes[])
                    )
                  }
                >
                  <Icon bordered color='orange' name='cube' />
                  {groupName}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {permissions ? (
                  <Header as='h5' className='permission'>
                    <Icon
                      size='small'
                      name={GROUP_PERMISSIONS[permissions[0]].icon}
                    />
                    <Header.Content>
                      {t(GROUP_PERMISSIONS[permissions[0]].text)}
                    </Header.Content>
                  </Header>
                ) : (
                  ''
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Segment className='wrapper-groups'>
      <div className='header-section'>
        <Header content={t('sections.yourGroups')} />
        <Button
          basic
          size='mini'
          content={t('actions.seeAll')}
          className='see-all'
          labelPosition='right'
          icon='right chevron'
          disabled={isLoadingRequest.fetch}
          onClick={() => {
            navigate(names.paths.groups.main);
          }}
        />
      </div>
      {renderState()}
    </Segment>
  );
};

export default GroupsList;
