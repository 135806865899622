import moment from 'moment';
import * as Helpers from '../helpers';
import * as Interfaces from '../interfaces';

const DEFAULT_ADDRESS_LICENSE: Interfaces.IAddressLicense = {
  county: '',
  state: '',
  zipCode: ''
};
class ReportDamage {
  private eventId: Interfaces.IDamageReport['eventId'];

  private address: Interfaces.IAddressLicense;

  private date: string;

  private thingId: Interfaces.IDamageReport['thingId'];

  private thingName: Interfaces.IDamageReport['thingName'];

  private reportDate: Interfaces.IDamageReport['reportedAt'];

  private reportStatus: Interfaces.IDamageStatus;

  private location: string;

  private comments: Interfaces.IDamageReport['comments'];

  constructor() {
    this.eventId = '';
    this.thingName = '';
    this.thingId = '';
    this.location = '';
    this.address = DEFAULT_ADDRESS_LICENSE;
    this.date = moment().format('LLL');
    this.reportDate = moment().format('LLL');
    this.reportStatus = {
      inventory: false,
      people: false,
      property: false,
      other: false
    };
    this.comments = '';
  }

  init(report: Interfaces.IDamageReport): this {
    this.eventId = report.eventId;
    this.address = report.address || DEFAULT_ADDRESS_LICENSE;
    this.date = moment(report.date).format('LLL');
    this.location = Helpers.parseCoords(report.location);
    this.thingName = report.thingName;
    this.thingId = report.thingId;
    this.reportDate = moment(report.reportedAt).format('LLL');
    this.reportStatus = {
      inventory: report.inventoryDamaged,
      people: report.peopleDamaged,
      property: report.infrastructureDamaged,
      other: report.otherDamage
    };
    this.comments = report.comments || '';

    return this;
  }

  parseReport(): Interfaces.IParseDamageReport {
    return {
      uuid: this.thingId || 'error.notAvailable',
      earthquakeId: this.eventId || 'error.notAvailable',
      thingName: this.thingName,
      earthquakeDate: this.date,
      name: this.thingName,
      location: this.location,
      damage: this.reportStatus,
      reportDate: this.reportDate,
      address: this.address,
      comments: this.comments
    };
  }
}

export default ReportDamage;
