import React from 'react';
import {
  Label,
  Icon,
  Popup,
  Segment,
  SemanticCOLORS,
  SemanticICONS
} from 'semantic-ui-react';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';
import { SkeletonBlock } from '../Skeletons';

import './ConnectionStatus.scss';

interface ConnectionStatusProps {
  loading: boolean;
  connection: 'connected' | 'disconnected' | 'notAvailable';
  isDisplayConnectionMessage?: boolean;
}

type ConnectionTypeIcon = {
  [key in ConnectionStatusProps['connection']]: {
    iconName: SemanticICONS;
    color: SemanticCOLORS;
  };
};

const connectionTypeIcon: ConnectionTypeIcon = {
  disconnected: {
    iconName: 'circle',
    color: 'red'
  },
  connected: {
    iconName: 'circle',
    color: 'green'
  },
  notAvailable: {
    iconName: 'question circle',
    color: 'grey'
  }
};

const ConnectionStatus = ({
  loading,
  connection,
  isDisplayConnectionMessage
}: ConnectionStatusProps) => {
  const { t: translation } = useTranslation();

  if (loading) {
    return (
      <SkeletonBlock
        styled={{
          width: 275,
          height: 40,
          position: 'absolute',
          left: '1.5em',
          top: '1.5em',
          zIndex: 3
        }}
      />
    );
  }

  const colorConnection = connectionTypeIcon[connection].color;
  return (
    <div className={`wrapper-connection-status ${colorConnection}`}>
      <Segment raised className='container-connection-status'>
        <div className='container-status'>
          <Icon className='icon-status' name='wi-fi' />
          {translation('infoCardConnection.connection')}
        </div>
        <div className='container-connection'>
          <Icon
            className='icon-connection'
            name={connectionTypeIcon[connection].iconName}
            color={connectionTypeIcon[connection].color}
          />
          <span className={`text ${colorConnection}`}>
            {translation(`connectionStatus.${connection}` as any)}
          </span>
        </div>
        {isDisplayConnectionMessage ? (
          <Popup
            className='popup-important-thing'
            content={
              <Markdown>{translation('monitor.header.important')}</Markdown>
            }
            trigger={
              <Label
                as='a'
                circular
                color='blue'
                style={{ marginLeft: 15, fontSize: '0.8em' }}
              >
                <Icon name='info' style={{ margin: 'auto' }} />
              </Label>
            }
          />
        ) : null}
      </Segment>
    </div>
  );
};

ConnectionStatus.defaultProps = {
  isDisplayConnectionMessage: false
};

export default ConnectionStatus;
