import React, { SyntheticEvent, useState } from 'react';
import {
  Checkbox,
  CheckboxProps,
  Divider,
  Header,
  Table,
  TableHeaderCellProps,
  TableProps
} from 'semantic-ui-react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../contexts/settings';

interface IListColumns {
  column: string;
  text: string;
}

export interface IPropsMain extends TableProps {
  title?: string;
  divider?: boolean;
  hideDivider?: boolean;
  children: React.ReactNode;
}

export interface IPropsHeader extends TableHeaderCellProps {
  onClickHeader?: (listOrder: any) => void;
  columns: Array<IListColumns>;
  children?: React.ReactNode;
  list: any;
  isAllSelect?: boolean;
  onClickAllSelect?: (e: SyntheticEvent, data: CheckboxProps) => void;
}

export interface IPropsBody {
  children?: React.ReactNode;
}

export interface IPropsFooter {
  colspan?: number;
  children?: React.ReactNode;
}

const TableList = ({
  title,
  divider,
  children,
  hideDivider,
  ...restProps
}: IPropsMain): JSX.Element => {
  const { theme } = useSettings();
  return (
    <>
      {title && <Header inverted={theme !== 'light'} as='h1' content={title} />}
      {hideDivider && <Divider hidden={divider} />}
      <Table inverted={theme !== 'light'} {...restProps}>
        {children}
      </Table>
    </>
  );
};

const TableListHeader = ({
  list,
  columns,
  children,
  isAllSelect,
  onClickHeader,
  onClickAllSelect
}: IPropsHeader): JSX.Element => {
  const { t } = useTranslation();
  const [direction, setDirection] =
    useState<TableHeaderCellProps['sorted']>(undefined);
  const [column, setColumn] = useState<string | undefined>(undefined);

  const handlerClickSort = (e: SyntheticEvent, selected: string): void => {
    e.preventDefault();
    const isDescending = direction === 'ascending';
    if (column !== selected) {
      setColumn(selected);
      setDirection('ascending');
      if (onClickHeader) onClickHeader(orderBy(list, [`${selected}`], 'asc'));
      return;
    }

    setDirection(isDescending ? 'descending' : 'ascending');
    const orderList = orderBy(
      list,
      [`${selected}`],
      isDescending ? 'desc' : 'asc'
    );
    if (onClickHeader) onClickHeader(orderList);
  };

  return (
    <>
      {columns?.length && (
        <Table.Header>
          <Table.Row>
            {isAllSelect && (
              <Table.HeaderCell key='select-all'>
                <Checkbox disabled onChange={onClickAllSelect} />
              </Table.HeaderCell>
            )}
            {Object.values(columns).map((item, index) => (
              <Table.HeaderCell
                key={`table-list-header-${index + 1}`}
                sorted={column === item.column ? direction : undefined}
                onClick={(e: SyntheticEvent) =>
                  handlerClickSort(e, item.column)
                }
              >
                {item.text && t(item.text as any)}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
      )}
      {children && children}
    </>
  );
};

const TableListBody = ({ children }: IPropsBody): JSX.Element => (
  <Table.Body>{children && children}</Table.Body>
);

const TableListFooter = ({ colspan, children }: IPropsFooter): JSX.Element => (
  <Table.Footer>
    <Table.Row>
      <Table.HeaderCell colSpan={colspan} textAlign='center'>
        {children && children}
      </Table.HeaderCell>
    </Table.Row>
  </Table.Footer>
);

TableList.Header = TableListHeader;
TableList.Body = TableListBody;
TableList.Footer = TableListFooter;

TableList.defaultProps = {
  title: '',
  divider: true,
  hideDivider: false
};

TableListHeader.defaultProps = {
  isAllSelect: false,
  onClickAllSelect: () => null,
  onClickHeader: () => null,
  children: <Table.Header />
};

TableListBody.defaultProps = {
  children: <Table.Body />
};

TableListFooter.defaultProps = {
  children: <Table.Footer />,
  colspan: 0
};

export default TableList;
