import React, { SyntheticEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { GoogleMapProps } from '@react-google-maps/api';
import Analytics from '../../controllers/Analytics';
import ReporterLogger from '../../controllers/ReporterLogger';
import { useLicensesByStatus } from '../../hooks/licenses/useLicensesByStatus';
import ModalCoords from '../../components/Modals/Coords';
import ModalEditLicense from '../../components/Modals/EditLicense';
import Layout from '../../components/Layout';
import Layer from '../../components/Layer';
import BreadcrumbCustom from '../../components/Breadcrumb';
import ModalAssignMultiple from '../../components/Modals/Assign/Multiple';
import names from '../../config/names';
import * as Constants from '../../config/constants';
import * as Helpers from '../../helpers';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

import '../../scss/layout/LicensesType.scss';

interface IState {
  addressLicense: string;
  coords: GoogleMapProps['center'];
  list: IParseToViewTable[];
  isOpenModal: boolean;
  isOpenModalAssign: boolean;
  status: Types.TParamsLicenses;
  searchItem: string;
  selectLicense: Interfaces.ISelectLicense;
}

interface IParseToViewTable {
  id: string;
  name: string;
  location: string;
  type: string;
  dateUntil: string;
  sharedWithEmail?: string;
}

const LoggerInstance = ReporterLogger.getInstance();

const TypeLicenses = ({ isNavbar }: Interfaces.IPropsRoutes) => {
  const { t } = useTranslation();
  const { status } = useParams<IState['status']>() as IState['status'];

  const ANALYTICS_PAGE_VIEW: Interfaces.IAnalyticsViewPage = {
    page: names.paths.licenses[status],
    title: `Licenses ${status}`
  };

  const {
    isLoadingRequest: {
      fetch: loadingFetch,
      editStatus,
      deleteLicenseAvailable,
      assign: loadingAssignLicenses
    },
    isErrorAssign,
    refetchLicenses,
    sendAssignMultiple,
    sendEditLicenseStatus,
    deleteLicenseToBeAvailable,
    dataLicenses: { listParsed },
    getMoreLicenses,
    nextRequest: { isLoadMore }
  } = useLicensesByStatus({ status, analytics: ANALYTICS_PAGE_VIEW });

  const loadingEdit = editStatus || deleteLicenseAvailable;

  const [isOpenModalEdit, setIsOpenModalEdit] =
    useState<IState['isOpenModal']>(false);
  const [isOpenModal, setIsOpenModal] = useState<IState['isOpenModal']>(false);
  const [isOpenModalAssign, setIsOpenModalAssign] =
    useState<IState['isOpenModalAssign']>(false);
  const [coords, setCoords] = useState<IState['coords']>(
    Constants.DEFAULT_COORDS
  );
  const [addressLicense, setAddressLicense] =
    useState<IState['addressLicense']>('');
  const [selectLicense, setSelectLicense] = useState<IState['selectLicense']>(
    Constants.DEFAULT_SELECT_LICENSE
  );

  const handlerEdit = (element: Interfaces.ISelectLicense): void => {
    const { location, ...editElement } = element;
    let formatLocation = location;
    if (typeof location === 'string') {
      formatLocation = Helpers.convertLicensesLocationMap(location);
    }
    setIsOpenModalEdit(true);
    setSelectLicense({ ...editElement, location: formatLocation, status });

    Analytics.sendEventCustom({
      category: `Licenses ${status}`,
      action: 'Edit',
      label: 'License'
    });
  };

  const handlerOnAccept = (data: Interfaces.ISelectLicense) => {
    if (!data) {
      setIsOpenModalEdit(false);
      return;
    }

    const { id, status: newStatus } = data;
    const { status: selectStatus, id: selectId } = selectLicense;

    try {
      // TODO: REFACTOR VALIDATION TO REQUEST STATUS AND DATES
      if (newStatus !== selectStatus) {
        if (newStatus === 'available') {
          deleteLicenseToBeAvailable(id ?? '');
        } else {
          sendEditLicenseStatus({
            id: id || selectId,
            status: newStatus || selectStatus
          });
        }
      }
    } catch (e) {
      LoggerInstance.error('Failed to edit location - Licenses status', e);
    } finally {
      if (!loadingEdit) {
        setTimeout(() => {
          setIsOpenModalEdit(false);
        }, 300);
      }
    }
  };

  const handlerAssignMultiple = (
    body: Interfaces.IBodyAssignMultipleLicenses
  ) => {
    sendAssignMultiple(body);

    Analytics.sendEventCustom({
      category: `Licenses ${status}`,
      action: 'Assign',
      label: 'Multiple License'
    });
  };

  const handlerCoords = (
    e: SyntheticEvent<HTMLElement>,
    location: Interfaces.ICoordsAddress
  ): void => {
    if (location.coords.includes(',')) {
      setCoords(Helpers.convertLicensesLocationMap(location.coords));
      setAddressLicense(location.address);
      setIsOpenModal(true);
    }
  };

  const lowerCaseStatus = i18next.format(
    t(Constants.LICENSES_TYPES_LIST[status].text as any),
    'lowercase'
  );

  const listLicensesDesk = listParsed.filter(
    (license) => license.type === 'Desk'
  );
  const listForAssign = listLicensesDesk.map((license) => {
    const newElement = {
      ...license,
      selectLicense: false,
      email: '',
      userName: {
        first: '',
        last: ''
      }
    };
    return newElement;
  });

  return (
    <Layout
      title={`${t('sections.licenses')} ${lowerCaseStatus}`}
      showNavbar={isNavbar}
    >
      <BreadcrumbCustom flow={[names.navigation.licenses, status]} />
      <Segment basic className='wrapper-licenses-list'>
        <Layer
          section='LICENSES'
          fieldToSearch='name'
          data={listParsed}
          loading={loadingFetch}
          loadMore={isLoadMore}
          onEdit={handlerEdit}
          onRefresh={refetchLicenses}
          onCreate={() => setIsOpenModalAssign(true)}
          refetchLoadMore={getMoreLicenses}
          setModalParam={handlerCoords}
        />
        <ModalEditLicense
          editValues={selectLicense}
          isOpen={isOpenModalEdit}
          isLoading={loadingEdit}
          onAccept={handlerOnAccept}
          onCancel={() => setIsOpenModalEdit(false)}
        />
        <ModalCoords
          coords={coords}
          address={addressLicense}
          isOpen={isOpenModal}
          onCancel={() => setIsOpenModal(false)}
        />
        <ModalAssignMultiple
          isOpen={isOpenModalAssign}
          isLoading={{ list: loadingFetch, assign: loadingAssignLicenses }}
          isErrorRequest={isErrorAssign}
          listLicenses={listForAssign}
          onCancel={() => setIsOpenModalAssign(false)}
          onAccept={handlerAssignMultiple}
        />
      </Segment>
    </Layout>
  );
};

export default TypeLicenses;
