import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, InputProps, Segment } from 'semantic-ui-react';

export interface PropsFormAssignLicense {
  isLoading: boolean;
  handlerOnchange: (e: SyntheticEvent, element: InputProps) => void;
  isError: {
    name: boolean;
    lastName: boolean;
    email: boolean;
  };
}

const FormAssignLicense = ({
  isLoading,
  handlerOnchange,
  isError
}: PropsFormAssignLicense): JSX.Element => {
  const { t: translation } = useTranslation();

  const listSteps = translation('modals.paragraphs.stepsAssign', {
    returnObjects: true
  });

  return (
    <Segment basic>
      {listSteps.map((text, index) => (
        <p key={`step-${index.toString()}`}>
          <strong>{`${index + 1}.- `} </strong> {text}
        </p>
      ))}
      <Form autoComplete='off'>
        <Grid>
          <Grid.Column width={8}>
            <Form.Input
              name='name'
              data-testid='assign-component-input-name'
              disabled={isLoading}
              label={translation('modals.labels.assign.name')}
              onChange={handlerOnchange}
              placeholder={translation('modals.placeholders.assign.name')}
              error={isError.name && translation('error.modals.labels.name')}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input
              name='lastName'
              data-testid='assign-component-input-last-name'
              disabled={isLoading}
              label={translation('modals.labels.assign.lastName') as string}
              onChange={handlerOnchange}
              placeholder={translation('modals.placeholders.assign.lastName')}
              error={
                isError.lastName && translation('error.modals.labels.lastName')
              }
            />
          </Grid.Column>
        </Grid>
        <Form.Input
          name='email'
          data-testid='assign-component-input-email'
          disabled={isLoading}
          label={translation('modals.labels.assign.email')}
          onChange={handlerOnchange}
          placeholder={translation('modals.placeholders.assign.email')}
          error={isError.email && translation('error.modals.labels.email')}
        />
      </Form>
    </Segment>
  );
};

export default FormAssignLicense;
