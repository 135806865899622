import React, { useEffect, useState } from 'react';
import { Card, Grid, Header, Loader, Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import { useClientState } from '../../contexts/client';
import { useSettings } from '../../contexts/settings';
import { useClients } from '../../hooks/useClients';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { SkeletonBlock } from '../../components/Skeletons';
import ModalActions from '../../components/Modals/Actions/Clients';
import DropdownActions from '../../components/DropdownActions';
import Layout from '../../components/Layout';
import Empty from '../../components/Empty';
import Search from '../../components/Search';
import names from '../../config/names';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

import './Clients.scss';

const { DEFAULT_SELECT_CLIENT } = Constants;

const ANALYTICS_PAGE_VIEW: Interfaces.IAnalyticsViewPage = {
  page: names.paths.clients,
  title: 'Clients'
};

const Clients = ({ isNavbar }: Interfaces.IPropsCtxRoutes): JSX.Element => {
  const { t: translation } = useTranslation();

  const client = useClientState();
  const {
    selectClient,
    // isLoadingRequest,
    updateQuery,
    sendEditClient,
    sendCreateClient,
    isLoadingRequest
  } = useClients({ analytics: ANALYTICS_PAGE_VIEW });
  const { theme } = useSettings();
  const {
    screen: { isMobile }
  } = useBreakpoint();

  const isDarkMode = theme !== 'light';

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isInitial, setIsInitial] = useState<boolean>(false);
  const [action, setAction] = useState<Types.TActionsModal>('create');
  const [selectClientToEdit, setSelectClientToEdit] =
    useState<Interfaces.IClientData>(DEFAULT_SELECT_CLIENT);

  useEffect(() => {
    setIsInitial(true);
  }, []);

  const toggleModalActions = (newAction: Types.TActionsModal): void => {
    setAction(newAction);
    setIsOpenModal(!isOpenModal);
  };

  const handlerEdit = (element: any) => {
    toggleModalActions('edit');
    const { id, name, email, skyalertId } = element;
    const clientToEdit = { id, name, email, skyalertId: skyalertId || '' };
    setSelectClientToEdit(clientToEdit);

    Analytics.sendEventCustom({
      category: 'Clients',
      action: 'Edit'
    });
  };

  const handlerSearch = (search: any) => {
    const newSearch = {
      searchValue: search.value as string,
      newQuery: { search: search.type } as Interfaces.IQueryClient
    };
    updateQuery(newSearch);
    setIsInitial(false);
  };

  const handlerOnAccept = (data: Interfaces.IClientData) => {
    if (action !== 'create') {
      sendEditClient(data);
    } else {
      sendCreateClient(data);
    }
    toggleModalActions(action);

    Analytics.sendEventCustom({
      category: 'Clients',
      action
    });
  };

  const isLoading =
    isLoadingRequest.fetch || isLoadingRequest.create || isLoadingRequest.edit;
  const clientData = client.id?.length ? [client] : [];

  const renderTable = (): JSX.Element => {
    if (!clientData.length) {
      return <></>;
    }
    const { id, name, skyalertId, email } = clientData[0];
    return (
      <Segment disabled={isLoadingRequest.select}>
        <Loader active={isLoadingRequest.select} />
        <Table basic='very' inverted={isDarkMode} disable>
          <Table.Header>
            <Table.Row>
              {Constants.HEADER_LIST_CLIENTS.map(({ text }, index) => {
                return (
                  <Table.HeaderCell
                    key={`header-table-${index + 1}`}
                    textAlign='center'
                    style={{ padding: 0, paddingBottom: '0.9em' }}
                  >
                    {translation(text as any)}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Row
            className='row-client'
            textAlign='center'
            onClick={() => {
              if (client.id) {
                selectClient(client.id);
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <Table.Cell>{id}</Table.Cell>
            <Table.Cell className='collapse-texts'>{name}</Table.Cell>
            <Table.Cell>{skyalertId}</Table.Cell>
            <Table.Cell>{email}</Table.Cell>
            <Table.Cell collapsing>
              <DropdownActions
                type='CLIENTS'
                onEdit={handlerEdit}
                element={clientData[0]}
              />
            </Table.Cell>
          </Table.Row>
        </Table>
      </Segment>
    );
  };

  const renderCard = (): JSX.Element => {
    if (!clientData.length) {
      return <></>;
    }
    const { name, skyalertId, email } = clientData[0];
    return (
      <Segment disabled={isLoadingRequest.select} basic>
        <Card
          className='card-client'
          raised
          onClick={() => {
            if (client.id) {
              selectClient(client.id);
            }
          }}
        >
          <Loader active={isLoadingRequest.select} />
          <Card.Content>
            <Card.Header className='header-divider card-header'>
              <div className='card-header-left'>
                <span className='card-name'>{name}</span>
              </div>
              <DropdownActions
                type='CLIENTS'
                onEdit={handlerEdit}
                element={clientData[0]}
              />
            </Card.Header>
            <Card.Meta className='card-meta'>
              <span className='collapse-texts'>{email}</span>
            </Card.Meta>
          </Card.Content>
          <Card.Content extra className='card-extra'>
            <Header as='h4' icon='user' content={skyalertId} />
          </Card.Content>
        </Card>
      </Segment>
    );
  };

  const renderContent = (): JSX.Element => {
    if (isLoading) {
      return <SkeletonBlock />;
    }
    if (!clientData.length && (isInitial || client.notFound)) {
      const textsEmpty = isInitial ? 'clients' : 'clientNotFound';

      return (
        <Empty
          iconName={isInitial ? 'user' : 'search'}
          texts={{
            title: translation(`messages.${textsEmpty}.title` as any),
            subtitle: translation(`messages.${textsEmpty}.subtitle` as any)
          }}
        />
      );
    }
    if (!isMobile) {
      return renderTable();
    }
    return renderCard();
  };

  return (
    <Layout title={translation('clients.title')} showNavbar={isNavbar}>
      <Segment basic className='wrapper-clients'>
        <Grid as={Segment} inverted={isDarkMode}>
          <Grid.Row>
            <Grid.Column computer={7} mobile={16}>
              <Search
                list={clientData}
                options={Constants.OPTIONS_SEARCH_CLIENTS}
                isDisable={isLoading}
                defaultOptions='skyalertId'
                updateValueSearch={handlerSearch}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {renderContent()}
        <ModalActions
          action={action}
          editValues={selectClientToEdit}
          isOpen={isOpenModal}
          isLoading={isLoadingRequest[action]}
          onAccept={handlerOnAccept}
          onCancel={() => toggleModalActions(action)}
        />
      </Segment>
    </Layout>
  );
};

export default Clients;
