const api = {
  skyAlert: {
    staging: {
      root: process.env.REACT_APP_API_URL,
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      }
    },
    endpoint: {
      getCustomer: {
        name: `customer`,
        method: 'GET'
      },
      getClient: {
        name: (searchValue: string): string => `customer/${searchValue}`,
        method: 'GET'
      },
      getGroups: {
        name: 'groups',
        method: 'GET'
      },
      getGroup: {
        name: (groupId: string): string => `group/${groupId}`,
        method: 'GET'
      },
      getSubgroupsInGroup: {
        name: (groupId: string): string => `group/${groupId}/groups`,
        method: 'GET'
      },
      getUsersInGroup: {
        name: (groupId: string): string => `group/${groupId}/users`,
        method: 'GET'
      },
      getThingsInGroup: {
        name: (groupId: string): string => `group/${groupId}/things`,
        method: 'GET'
      },
      getCustomCommandsInGroup: {
        name: (groupId: string): string => `group/${groupId}/customCommands`,
        method: 'GET'
      },
      getFeed: {
        name: (groupId: string): string => `group/${groupId}/feed`,
        method: 'GET'
      },
      getScheduledCommandsInGroup: {
        name: (groupId: string): string => `group/${groupId}/commands`,
        method: 'GET'
      },
      getThings: {
        name: 'things',
        method: 'GET'
      },
      getThing: {
        name: (thingId: string): string => `thing/${thingId}`,
        method: 'GET'
      },
      getThingConnections: {
        name: (thingId: string): string => `thing/${thingId}/connections`,
        method: 'GET'
      },
      getThingsConnected: {
        name: 'things/connected',
        method: 'GET'
      },
      getThingDetails: {
        name: (thingId: string): string => `thing/${thingId}/details`,
        method: 'GET'
      },
      getLicenses: {
        name: 'licenses',
        method: 'GET'
      },
      getLicense: {
        name: (licenseId: string): string => `license/${licenseId}`,
        method: 'GET'
      },
      getClientLicenses: {
        name: (customerId: string): string => `customer/${customerId}/licenses`,
        method: 'GET'
      },
      getCustomAlerts: {
        name: 'customAlerts',
        method: 'GET'
      },
      getUsers: {
        name: `users`,
        method: 'GET'
      },
      getUser: {
        name: `user`,
        method: 'GET'
      },
      getThingSettings: {
        name: (thingId: string): string => `thing/${thingId}/configuration`,
        method: 'GET'
      },
      validateIsNewUser: {
        name: (userEmail: string): string => `user/${userEmail}/isNewUser`,
        method: 'GET'
      },
      // FIXME: REMOVE HARDCORE PARAMETERS; INSTEAD USE "buildAndFetchList" FUNCTION
      getThingsReport: {
        name: (
          startDate: string,
          endDate: string,
          customerId: string,
          refresh: boolean
        ): string =>
          `monitoring/connections/customer/${customerId}&from=${startDate}&to=${endDate}${
            refresh ? '&refresh=true' : ''
          }`,
        method: 'GET'
      },
      // FIXME: REMOVE HARDCORE PARAMETERS; INSTEAD USE "buildAndFetchList" FUNCTION
      getThingsReportByType: {
        name: (
          startDate: string,
          endDate: string,
          thingType: string,
          customerId: string,
          refresh: boolean
        ): string =>
          `monitoring/connections/customer/${customerId}?thingType=${thingType}&from=${startDate}&to=${endDate}${
            refresh ? '&refresh=true' : ''
          }`,
        method: 'GET'
      },
      // FIXME: REMOVE HARDCORE PARAMETERS; INSTEAD USE "buildAndFetchList" FUNCTION
      getGroupReport: {
        name: (
          startDate: string,
          endDate: string,
          groupId: string,
          customerId: string,
          refresh: boolean
        ): string =>
          `monitoring/connections/customer/${customerId}/group/${groupId}?from=${startDate}&to=${endDate}${
            refresh ? '&refresh=true' : ''
          }`,
        method: 'GET'
      },
      // FIXME: REMOVE HARDCORE PARAMETERS; INSTEAD USE "buildAndFetchList" FUNCTION
      getThingsReportLicensee: {
        name: (startDate: string, endDate: string, refresh: boolean): string =>
          `monitoring/connections?from=${startDate}&to=${endDate}${
            refresh ? '&refresh=true' : ''
          }`,
        method: 'GET'
      },
      // FIXME: REMOVE HARDCORE PARAMETERS; INSTEAD USE "buildAndFetchList" FUNCTION
      getThingsReportByTypeLicensee: {
        name: (
          startDate: string,
          endDate: string,
          thingType: string,
          refresh: boolean
        ): string =>
          `monitoring/connections?${
            thingType ? `thingType=${thingType}&` : ''
          }from=${startDate}&to=${endDate}${refresh ? '&refresh=true' : ''}`,
        method: 'GET'
      },
      getReportDamage: {
        name: (groupId: string): string => `group/${groupId}/damage-report`,
        method: 'GET'
      },
      selectClient: {
        name: (customerId: string): string =>
          `licensee/customer/${customerId}/login`,
        method: 'POST'
      },
      unselectClient: {
        name: 'licensee/customer/logout',
        method: 'POST'
      },
      createClient: {
        name: 'customer',
        method: 'POST'
      },
      createGroupsInGroup: {
        name: (customerId: string): string => `group/${customerId}/groups`,
        method: 'POST'
      },
      createScheduledInGroup: {
        name: (groupId: string): string => `group/${groupId}/scheduled`,
        method: 'POST'
      },
      createCommandsInGroup: {
        name: (groupId: string): string => `group/${groupId}/command`,
        method: 'POST'
      },
      createThingsInGroup: {
        name: (customerId: string): string => `group/${customerId}/things`,
        method: 'POST'
      },
      createUsersInGroup: {
        name: (groupId: string): string => `group/${groupId}/users`,
        method: 'POST'
      },
      createCustomAlert: {
        name: `customAlert`,
        method: 'POST'
      },
      createUser: {
        name: `users`,
        method: 'POST'
      },
      setThingSettings: {
        name: (thingId: string): string => `thing/${thingId}/configuration`,
        method: 'POST'
      },
      setUserLicense: {
        name: (licenseId: string): string => `license/${licenseId}/share`,
        method: 'POST'
      },
      setMultipleLicense: {
        name: 'licenses/share',
        method: 'POST'
      },
      resendTemporaryPassword: {
        name: (userId: string): string => `user/${userId}/password/resend`,
        method: 'POST'
      },
      deleteCustomer: {
        name: (customerId: string): string => `delete/customer/${customerId}`,
        method: 'DELETE'
      },
      deleteGroup: {
        name: (groupId: string): string => `group/${groupId}`,
        method: 'DELETE'
      },
      deleteCommand: {
        name: (commandId: string): string => `commands/${commandId}/schedule`,
        method: 'DELETE'
      },
      deleteGroupUser: {
        name: (groupId: string, userId: string): string =>
          `group/${groupId}/user/${userId}`,
        method: 'DELETE'
      },
      deleteUsers: {
        name: (userId: string): string => `user/${userId}`,
        method: 'DELETE'
      },
      deleteScheluded: {
        name: (groupId: string): string => `delete/group/${groupId}/scheluded`,
        method: 'DELETE'
      },
      deleteLicense: {
        name: (licenseId: string): string => `delete/license/${licenseId}`,
        method: 'DELETE'
      },
      deleteThing: {
        name: (thingId: string): string => `delete/thing/${thingId}`,
        method: 'DELETE'
      },
      deleteLicenseThing: {
        name: (licenseId: string): string => `license/${licenseId}/thing`,
        method: 'DELETE'
      },
      updateUser: {
        name: (userId: string): string => `user/${userId}`,
        method: 'PUT'
      },
      updateClient: {
        name: (customerId: string): string => `customer/${customerId}`,
        method: 'PUT'
      },
      updateThingName: {
        name: (thingId: string): string => `thing/${thingId}`,
        method: 'PATCH'
      },
      updateLicenseStatus: {
        name: (licenseId: string): string => `license/${licenseId}/status`,
        method: 'POST'
      }
    },
    response: {
      unauthorized: 401,
      refresh: 401,
      forbidden: 403
    }
  },
  redSkyAlert: {
    staging: {
      root: 'https://at2rg6hps5.execute-api.us-east-1.amazonaws.com/prod',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    endpoint: {
      getCoverageByZipCode: {
        name: (zipCode: string): string => `/zip?code=${zipCode}`,
        method: 'GET'
      }
    }
  }
};

export default api;
