import React from 'react';
import { AppRoutesProtect, AppRoutesPublic } from './AppRoutes';
import Login from '../../routes/Login';
import Users from '../../routes/Users';
import Things from '../../routes/Things';
import ThingsDetail from '../../routes/Things/Detail';
import Groups from '../../routes/Groups';
import Licenses from '../../routes/Licenses';
import ThingsType from '../../routes/Things/Type';
import LicensesStatus from '../../routes/Licenses/Status';
import LicensesDetail from '../../routes/Licenses/Detail';
import NotFound from '../../routes/NotFound';
import Support from '../../routes/Support';
import GroupsDetail from '../../routes/Groups/Detail';
import Clients from '../../routes/Clients';
import MonitorThings from '../../routes/MonitorThings';
import Dashboard from '../../routes/Dashboard';
import DamageReport from '../../routes/DamageReport';
import names from '../names';
import * as Helpers from '../../helpers';
import * as Constants from '../constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

const {
  ROLES_FOR_ALL,
  ROLES_FOR_LICENSEES,
  ROLES_FOR_SKYALERT_LICENSEES,
  EXCLUDE_ROLE_FOR_CLIENT_USERS
} = Constants;

const initialRouteAdminLevel = (
  adminLevel: Types.TUserTypes
): React.ReactNode => {
  const initialElements: { [key in Types.TUserTypes]: React.ReactNode } = {
    SkyAlertLicensee: <Clients isNavbar />,
    licensee: <Clients isNavbar />,
    owner: <Dashboard isNavbar />,
    admin: <Dashboard isNavbar />,
    user: <Dashboard isNavbar />
  };

  return initialElements[adminLevel];
};

const adminLevel = Helpers.getAdminLevelFromStore();

const routesSite: Interfaces.PropsCustomObjectRoutes[] = [
  {
    path: names.paths.dashboard,
    element: <AppRoutesProtect />,
    roles: ROLES_FOR_ALL,
    children: [
      {
        index: true,
        element: initialRouteAdminLevel(adminLevel),
        roles: ROLES_FOR_ALL
      },
      {
        path: names.paths.clients,
        element: <Clients isNavbar />,
        roles: ROLES_FOR_LICENSEES
      },
      {
        path: names.paths.monitorThings,
        element: <MonitorThings isNavbar />,
        roles: ROLES_FOR_SKYALERT_LICENSEES
      },
      {
        path: names.paths.damageReport,
        element: <DamageReport isNavbar />,
        roles: EXCLUDE_ROLE_FOR_CLIENT_USERS,
        isShowInStaging: true
      },
      {
        path: names.paths.licenses.matchDetail,
        element: <LicensesDetail isNavbar />,
        roles: EXCLUDE_ROLE_FOR_CLIENT_USERS
      },
      {
        path: names.paths.licenses.matchStatus,
        element: <LicensesStatus isNavbar />,
        roles: EXCLUDE_ROLE_FOR_CLIENT_USERS
      },
      {
        path: names.paths.licenses.main,
        element: <Licenses isNavbar />,
        roles: EXCLUDE_ROLE_FOR_CLIENT_USERS
      },
      {
        path: names.paths.groups.match,
        element: <GroupsDetail isNavbar />,
        roles: ROLES_FOR_ALL
      },
      {
        path: names.paths.groups.main,
        element: <Groups isNavbar />,
        roles: ROLES_FOR_ALL
      },
      {
        path: names.paths.things.matchDetail,
        element: <ThingsDetail isNavbar />,
        roles: ROLES_FOR_ALL
      },
      {
        path: names.paths.things.matchType,
        element: <ThingsType isNavbar />,
        roles: ROLES_FOR_ALL
      },
      {
        path: names.paths.things.main,
        element: <Things isNavbar />,
        roles: ROLES_FOR_ALL
      },
      {
        path: names.paths.users,
        element: <Users isNavbar />,
        roles: EXCLUDE_ROLE_FOR_CLIENT_USERS
      },
      {
        path: names.paths.support,
        element: <Support isNavbar />,
        roles: ROLES_FOR_ALL
      },
      {
        path: names.paths.notFound,
        element: <NotFound />,
        roles: ROLES_FOR_ALL
      }
    ]
  },
  {
    path: names.paths.login,
    element: <AppRoutesPublic />,
    roles: ROLES_FOR_ALL,
    children: [
      {
        index: true,
        element: <Login />,
        roles: ROLES_FOR_ALL
      },
      {
        path: names.paths.notFound,
        element: <NotFound />,
        roles: ROLES_FOR_ALL
      }
    ]
  }
];

const filterRoutesByRoles = (
  routes: Interfaces.PropsCustomObjectRoutes[],
  currentUserRole: Types.TUserTypes
): Interfaces.PropsCustomObjectRoutes[] => {
  const routesFiltered = routes
    .filter(
      (route) =>
        !route.roles ||
        route.roles.some((role) => [currentUserRole].includes(role))
    )
    .map((route) => ({
      ...route,
      ...(route.children && {
        children: filterRoutesByRoles(
          route.children as Interfaces.PropsCustomObjectRoutes[],
          currentUserRole
        )
      })
    }));

  return routesFiltered;
};

export const filterRoutesForBrowserRouter = (
  currentAdminLevel: Types.TUserTypes
): Interfaces.PropsCustomObjectRoutes[] => {
  const filteredRoutes = filterRoutesByRoles(routesSite, currentAdminLevel);

  const filterNewRoutesByFeatureStaging =
    Helpers.filterListForFeatureStaging<Interfaces.PropsCustomObjectRoutes>(
      filteredRoutes
    );

  return filterNewRoutesByFeatureStaging;
};

export default filterRoutesForBrowserRouter(adminLevel);
