import React, { useContext, useEffect, useReducer, useMemo } from 'react';
import i18next from '../../config/i18n';
import { releaseNote } from '../../../package.json';
import { useAuthState } from '../auth';
import names from '../../config/names';
import { settingsReducer, initialState } from '../../reducers/settings';
import { DEFAULT_USER_SETTINGS } from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

interface IPropsCtxRoutes {
  children: React.ReactNode;
}

const SettingsStateContext = React.createContext<Interfaces.ICtxSettings>(
  {} as Interfaces.ICtxSettings
);

export const useSettings = () => {
  const context = useContext(SettingsStateContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }

  return context;
};

export const SettingsProvider: React.FC<IPropsCtxRoutes> = ({
  children
}: IPropsCtxRoutes) => {
  const user = useAuthState();
  const { userSettings } = names.storageKeys;

  const [state, dispatch] = useReducer(settingsReducer, initialState);

  const saveInStoreSettingsValue = (
    value: Interfaces.ISettingsStorage
  ): void => {
    localStorage.setItem(userSettings, JSON.stringify(value));
  };

  const updateThemeAttributeInDOM = (mode: Types.TModeTheme): void => {
    const userTheme = document.getElementsByTagName('html')[0];
    userTheme.className = `theme-${mode}`;
  };

  const updateStateStorageSettings = (
    storageSettings: Interfaces.IStateCtxSettings['storageSettings']
  ): void => {
    dispatch({
      type: Types.EActionsReducerCtxSettings.setValuesStorageSettings,
      storageSettings
    });
  };

  const updateStateReleaseNotes = (
    isOpenReleaseNotes: Interfaces.IStateCtxSettings['isOpenReleaseNotes']
  ): void => {
    dispatch({
      type: Types.EActionsReducerCtxSettings.setOpenReleaseNotes,
      isOpenReleaseNotes
    });
  };

  const updateStateTutorial = (
    isOpenTutorial: Interfaces.IStateCtxSettings['isOpenTutorial']
  ): void => {
    dispatch({
      type: Types.EActionsReducerCtxSettings.setOpenTutorial,
      isOpenTutorial
    });
  };

  const existSettingsInStorage = (): boolean =>
    !!localStorage.getItem(userSettings);

  const getValueSettingsStore = (): Interfaces.ISettingsStorage => {
    const settingsFromLocalStore = localStorage.getItem(userSettings) as string;
    const parseSettingsStore = JSON.parse(
      settingsFromLocalStore
    ) as Interfaces.ISettingsStorage;
    return parseSettingsStore;
  };

  useEffect(() => {
    let initialConfig: Interfaces.ISettingsStorage = DEFAULT_USER_SETTINGS;
    if (!existSettingsInStorage()) {
      saveInStoreSettingsValue(initialConfig);
    } else {
      initialConfig = getValueSettingsStore();
    }

    updateStateStorageSettings(initialConfig);
    updateStateTutorial(initialConfig.isOpenTutorial);
    updateStateReleaseNotes(initialConfig.activeChangelog);
    updateThemeAttributeInDOM(initialConfig.theme);
  }, []);

  useEffect(() => {
    const { activeChangelog } = getValueSettingsStore();
    const releaseState = !!user.id && releaseNote && activeChangelog;
    updateStateReleaseNotes(releaseState);
  }, [user.id]);

  useEffect(() => {
    const storeValue = getValueSettingsStore();
    if (!storeValue.isOpenTutorial) {
      const tutorialState =
        !!user.id && user.isNewUser && !state.isCompleteTutorial;
      updateStateTutorial(tutorialState);
      saveInStoreSettingsValue({
        ...storeValue,
        isOpenTutorial: tutorialState
      });
    }
  }, [user.id, user.isNewUser]);

  const setThemeMode = (value: Interfaces.ISettingsStorage['theme']): void => {
    updateThemeAttributeInDOM(value);
    const newSettings = { ...state.storageSettings, theme: value };
    dispatch({
      type: Types.EActionsReducerCtxSettings.setValuesStorageSettings,
      storageSettings: newSettings
    });
    saveInStoreSettingsValue(newSettings);
  };

  const setLanguageSelect = (
    value: Interfaces.ISettingsStorage['language']
  ): void => {
    i18next.changeLanguage(value as Types.TLanguage);
    const newSettings = { ...state.storageSettings, language: value };
    dispatch({
      type: Types.EActionsReducerCtxSettings.setValuesStorageSettings,
      storageSettings: newSettings
    });
    saveInStoreSettingsValue(newSettings);
  };

  const toggleStateSettings = (): void => {
    dispatch({
      type: Types.EActionsReducerCtxSettings.setOpenSettings,
      isOpenSettings: !state.isOpenSettings
    });
  };

  const toggleModalTutorial = (): void => {
    updateStateTutorial(!state.isOpenTutorial);
    saveInStoreSettingsValue({
      ...state.storageSettings,
      isOpenTutorial: !state.isOpenTutorial
    });
  };

  const toggleModalReleaseNotes = (): void => {
    updateStateReleaseNotes(!state.isOpenReleaseNotes);

    saveInStoreSettingsValue({
      ...state.storageSettings,
      activeChangelog: !state.isOpenReleaseNotes
    });
  };

  const onFinishTutorial = (): void => {
    dispatch({
      type: Types.EActionsReducerCtxSettings.setCompleteTutorial,
      isCompleteTutorial: !state.isCompleteTutorial
    });

    toggleModalTutorial();
  };

  const onAcceptReleaseNote = (): void => {
    toggleModalReleaseNotes();
  };

  const value = useMemo(
    () => ({
      ...state,
      theme: state.storageSettings.theme,
      language: state.storageSettings.language,
      setThemeMode,
      setLanguageSelect,
      toggleStateSettings,
      toggleModalTutorial,
      onFinishTutorial,
      onAcceptReleaseNote
    }),
    [state]
  );

  return (
    <SettingsStateContext.Provider value={{ ...value }}>
      {children}
    </SettingsStateContext.Provider>
  );
};
