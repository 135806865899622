import React, { SyntheticEvent, useState } from 'react';
import { GoogleMapProps } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import {
  Header,
  Icon,
  Segment,
  SemanticCOLORS,
  SemanticICONS
} from 'semantic-ui-react';
import Markdown from 'markdown-to-jsx';
import { useDamageReport } from '../../hooks/useDamageReport';
import { useAuthClient } from '../../hooks/useAuthClient';
import Layout from '../../components/Layout';
import Layer from '../../components/Layer';
import ModalCoords from '../../components/Modals/Coords';
import ModalDetailDamageReport from '../../components/Modals/DetailDamageReport';
import * as Constants from '../../config/constants';
import * as Helpers from '../../helpers';
import * as Interfaces from '../../interfaces';

import '../../scss/layout/DamageReport.scss';

const ANALYTICS_PAGE_VIEW: Interfaces.IAnalyticsViewPage = {
  page: '/damage-report',
  title: 'Damage report admin'
};

const DEFAULT_DETAIL_REPORT: Interfaces.IMoreInfoReport = {
  thingName: '',
  earthquakeDate: '',
  reportDate: '',
  damage: {
    people: false,
    property: false,
    inventory: false,
    other: false
  },
  comments: ''
};

export const DamageReport = ({
  isNavbar
}: Interfaces.IPropsCtxRoutes): JSX.Element => {
  const { t: translation } = useTranslation();
  const { customerId } = useAuthClient();

  const groupId = `${Helpers.groupNameThings(customerId)}:desk`;

  const {
    isLoading,
    getMoreData,
    dataDamageReport,
    refetchDataDamageReport,
    nextRequest: { isLoadMore }
  } = useDamageReport({
    groupId,
    analytics: ANALYTICS_PAGE_VIEW
  });

  const [isOpenModalCoords, setIsOpenModalCoords] = useState<boolean>(false);
  const [isOpenModalReportDetail, setIsOpenModalReportDetail] =
    useState<boolean>(false);
  const [reportDetail, setReportDetail] = useState<Interfaces.IMoreInfoReport>(
    DEFAULT_DETAIL_REPORT
  );
  const [coords, setCoords] = useState<GoogleMapProps['center']>(
    Constants.DEFAULT_COORDS
  );
  const [addressThing, setAddressThing] = useState<string>('');

  const toggleModalReportDetail = (): void => {
    setIsOpenModalReportDetail((prev) => !prev);
  };

  const handlerModalsCoordsAndDetail = (
    e: SyntheticEvent<HTMLElement>,
    data: Interfaces.ICoordsAddress | Interfaces.IMoreInfoReport
  ): void => {
    const keysData = Object.keys(data);
    if (keysData.includes('coords')) {
      const parseLocation = data as Interfaces.ICoordsAddress;
      if (parseLocation.coords.includes(',')) {
        setCoords(Helpers.convertLicensesLocationMap(parseLocation.coords));
        setAddressThing(parseLocation.address);
        setIsOpenModalCoords(true);
      }
    } else {
      const parseMoreInfo = data as Interfaces.IMoreInfoReport;
      setReportDetail(parseMoreInfo);
      toggleModalReportDetail();
    }
  };

  const renderRibbonImport = (): JSX.Element => (
    <Segment className='important-ribbon' style={{ margin: '15px 0' }}>
      <Icon name='warning circle' color='blue' size='large' />
      <Markdown>{translation('damageReport.important')}</Markdown>
    </Segment>
  );

  const renderIconDescription = (
    iconName: SemanticICONS,
    content: string,
    subtitle: string,
    color?: SemanticCOLORS
  ): JSX.Element => (
    <div className='damage-description'>
      <Icon circular size='large' name={iconName} color={color} />
      <Header
        as='h4'
        content={translation(content as any)}
        subheader={translation(subtitle as any)}
        className='description-type-damage'
      />
    </div>
  );
  return (
    <Layout title={translation('damageReport.title')} showNavbar={isNavbar}>
      <Segment basic textAlign='center' className='wrapper-damage-report'>
        <p className='info-report'>
          <Markdown>{translation('damageReport.description')}</Markdown>
        </p>
        <div className='container-damage-description'>
          {Constants.ICONS_DESCRIPTIONS_DAMAGE_REPORT.map(
            ({ iconName, content, subtitle, color }) =>
              renderIconDescription(iconName, content, subtitle, color)
          )}
        </div>
        {renderRibbonImport()}
        <Layer
          section='DAMAGE-REPORT'
          fieldToSearch='name'
          data={dataDamageReport}
          loading={isLoading}
          loadMore={isLoadMore}
          onRefresh={refetchDataDamageReport}
          refetchLoadMore={getMoreData}
          setModalParam={handlerModalsCoordsAndDetail}
        />
        <ModalCoords
          coords={coords}
          address={addressThing}
          isOpen={isOpenModalCoords}
          onCancel={() => setIsOpenModalCoords(false)}
        />
        <ModalDetailDamageReport
          isOpen={isOpenModalReportDetail}
          infoReport={reportDetail}
          isLoading={isLoading}
          onClose={toggleModalReportDetail}
        />
      </Segment>
    </Layout>
  );
};

export default DamageReport;
