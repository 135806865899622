import {
  TabPaneProps,
  CheckboxProps,
  FormFieldProps,
  DropdownItemProps
} from 'semantic-ui-react';
import * as Types from '../../types';
import * as Interfaces from '../../interfaces';
import timezone from '../timezones';
import names from '../names';
import images from '../images';

export const TEMP_MILLISECONDS_DURATION_DRILL = 120000;

export const ROWS_COVERING_HEADER = 4;

export const LIMIT_CHARACTERS_NAME_GROUP = 20;

export const LIMIT_CHARACTERS_NAME_THING = 30;

export const FORMAT_DATE_CREATED = 'DD/MM/YYYY HH:mm';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const DEFAULT_DATE_FORMAT_TIME = 'YYYY-MM-DD HH:mm:ss';

export const DEFAULT_USER_SETTINGS: Interfaces.ISettingsStorage = {
  theme: 'light',
  language: 'es',
  activeChangelog: false,
  isOpenTutorial: false
};

export const DEFAULT_DROP_ZONE_CONFIG = {
  maxFiles: 1,
  accept: 'audio/mpeg',
  noClick: true,
  noKeyboard: true
};

export const PARAMS_URL_REDIRECT = {
  session: {
    key: 'ex',
    value: '1'
  }
};

export const DEFAULT_PROPS_QUILL = {
  theme: 'snow',
  modules: {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
          { align: [] }
        ],
        ['clean']
      ]
    }
  },
  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'size',
    'emoji',
    'list',
    'bullet',
    'indent',
    'align'
  ]
};

export const DEFAULT_VALUES_MODAL_CUSTOM_ALERTS: Interfaces.ICustomAlertMessageItems =
  {
    title: '',
    body: ''
  };

export const DEFAULT_VALUES_MODAL_CUSTOM_SOUND: Interfaces.ICustomAudioName = {
  name: ''
};

export const DEFAULT_VALUES_MODAL_DATE: Interfaces.IDateSchedule = {
  date: '',
  time: ''
};

export const DEFAULT_VALUES_FIELDS: Interfaces.IkeyText = {
  email: '',
  pass: '',
  changeNewPass: '',
  changeConfirmNewPass: '',
  forgotEmail: '',
  forgotCode: '',
  forgotNewPass: '',
  forgotConfirmNewPass: ''
};

export const DEFAULT_VALUES_FIELDS_PASS: Interfaces.IkeyText = {
  pass: false,
  changeNewPass: false,
  changeConfirmNewPass: false,
  forgotNewPass: false,
  forgotConfirmNewPass: false
};

export const DEFAULT_VALUE_NEXT_REQUEST = {
  isLoadMore: false,
  lastItem: {
    SK: '',
    PK: ''
  }
};

export const DEFAULT_COORDS = {
  lat: 19.4284,
  lng: -99.1276
};

export const DEFAULT_TOTAL_THINGS_CONNECTIONS = {
  registered: '--',
  disconnected: '--'
};

export const LOGIN_FIELDS: FormFieldProps[] = [
  {
    name: 'email',
    value: '',
    fluid: true,
    icon: 'user',
    iconPosition: 'left',
    label: 'login.fields.labels.email',
    placeholder: 'login.fields.placeholders.email',
    disabled: false
  },
  {
    name: 'pass',
    value: '',
    fluid: true,
    icon: 'lock',
    iconPosition: 'left',
    label: 'login.fields.labels.pass',
    placeholder: 'login.fields.placeholders.pass',
    type: 'password',
    showPass: false,
    disabled: false
  }
];

export const CHANGE_DEFAULT_PASSWORD_FIELDS: FormFieldProps[] = [
  {
    name: 'changeNewPass',
    value: '',
    fluid: true,
    icon: 'lock open',
    iconPosition: 'left',
    label: 'login.fields.labels.changeNewPass',
    placeholder: 'login.fields.placeholders.changeNewPass',
    type: 'password',
    showPass: false,
    disabled: false
  },
  {
    name: 'changeConfirmNewPass',
    value: '',
    fluid: true,
    icon: 'lock open',
    iconPosition: 'left',
    label: 'login.fields.labels.changeConfirmNewPass',
    placeholder: 'login.fields.placeholders.changeConfirmNewPass',
    type: 'password',
    showPass: false,
    disabled: false
  }
];

export const FORGOT_PASSWORD_FIELDS: FormFieldProps[] = [
  {
    name: 'forgotEmail',
    value: '',
    fluid: true,
    icon: 'mail',
    iconPosition: 'left',
    label: 'login.fields.labels.forgotEmail',
    placeholder: 'login.fields.placeholders.forgotEmail',
    disabled: false
  }
];

export const REQUEST_CODE_FIELDS: FormFieldProps[] = [
  {
    name: 'forgotCode',
    value: '',
    fluid: true,
    icon: 'send',
    iconPosition: 'left',
    label: 'login.fields.labels.forgotCode',
    placeholder: 'login.fields.placeholders.forgotCode',
    disabled: false
  },
  {
    name: 'forgotNewPass',
    value: '',
    fluid: true,
    icon: 'lock open',
    iconPosition: 'left',
    label: 'login.fields.labels.forgotNewPass',
    placeholder: 'login.fields.placeholders.forgotNewPass',
    type: 'password',
    showPass: false,
    disabled: false
  },
  {
    name: 'forgotConfirmNewPass',
    value: '',
    fluid: true,
    icon: 'lock open',
    iconPosition: 'left',
    label: 'login.fields.labels.forgotConfirmNewPass',
    placeholder: 'login.fields.placeholders.forgotConfirmNewPass',
    type: 'password',
    showPass: false,
    disabled: false
  }
];

export const FIELDS_DISPLAY: Interfaces.IFieldsLoginDisplay = {
  [Types.EViews.LOGIN]: LOGIN_FIELDS,
  [Types.EViews.FORGOT_PASSWORD]: FORGOT_PASSWORD_FIELDS,
  [Types.EViews.CHANGE_DEFAULT_PASSWORD]: CHANGE_DEFAULT_PASSWORD_FIELDS,
  [Types.EViews.REQUEST_CODE]: REQUEST_CODE_FIELDS
};

export const FIELDS_VALIDATION: Interfaces.IFieldsLoginDisplay = {
  [Types.EViews.LOGIN]: ['email', 'pass'],
  [Types.EViews.FORGOT_PASSWORD]: ['forgotEmail'],
  [Types.EViews.CHANGE_DEFAULT_PASSWORD]: [
    'changeNewPass',
    'changeConfirmNewPass'
  ],
  [Types.EViews.REQUEST_CODE]: [
    'forgotCode',
    'forgotNewPass',
    'forgotConfirmNewPass'
  ]
};

export const EXPIRED_CODE: Interfaces.IkeyText = {
  'Invalid verification code provided, please try again.':
    'error.login.cognitoException.expiredCode',
  'Invalid code provided, please request a code again.':
    'error.login.cognitoException.expiredCode',
  'Username cannot be empty': 'error.login.cognitoException.expiredCode',
  CodeMismatchException: 'error.login.cognitoException.expiredCode'
};

export const ERRORS_COGNITO_LOGIN: Interfaces.IkeyText = {
  NotAuthorizedException: 'error.login.cognitoException.notAuthorized',
  LimitExceededException: 'error.login.cognitoException.limitExceeded',
  InvalidPasswordException: 'error.login.cognitoException.passRequirements',
  CodeMismatchException: 'error.login.cognitoException.codeMismatch',
  ExpiredCodeException: 'error.login.cognitoException.expiredCode',
  'Username cannot be empty': 'error.login.cognitoException.userNameEmpty', // TODO:(WARNING) THIS IS MESSAGE FROM COGNITO -> CHECK CODE FOR THIS EXCEPTION
  'Invalid verification code provided, please try again.':
    'error.login.cognitoException.expiredCode',
  'Attempt limit exceeded, please try after some time.':
    'error.login.cognitoException.limitExceeded'
};

export const ERRORS_BACKEND_LOGIN: Interfaces.IkeyText = {
  404: 'error.login.notFound'
};

export const INITIAL_PATH_ADMIN_LEVEL = {
  SkyAlertLicensee: names.paths.clients,
  licensee: names.paths.clients,
  owner: names.paths.dashboard,
  admin: names.paths.dashboard,
  user: names.paths.dashboard
};

export const ROLES_FOR_ALL: Types.TUserTypes[] = [
  'admin',
  'owner',
  'user',
  'licensee',
  'SkyAlertLicensee'
];

export const EXCLUDE_ROLE_FOR_CLIENT_USERS: Types.TUserTypes[] = [
  'admin',
  'owner',
  'licensee',
  'SkyAlertLicensee'
];

export const ROLES_FOR_CLIENTS: Types.TUserTypes[] = ['admin', 'owner', 'user'];

export const ROLES_FOR_LICENSEES: Types.TUserTypes[] = [
  'licensee',
  'SkyAlertLicensee'
];

export const ROLES_FOR_SKYALERT_LICENSEES: Types.TUserTypes[] = [
  'SkyAlertLicensee'
];

export const NAVIGATION_OPTIONS: Interfaces.INavigationOptions = {
  DEFAULT: {
    section: names.paths.dashboard,
    subsection: '',
    groupPermission: ''
  },
  LIST: [
    {
      name: names.navigation.dashboard,
      icon: 'dashboard',
      text: 'menu.dashboard',
      disabled: false,
      location: names.paths.dashboard,
      links: [],
      permission: ROLES_FOR_CLIENTS
    },
    {
      name: names.navigation.clients,
      icon: 'address card outline',
      text: 'menu.clients',
      disabled: false,
      location: names.paths.clients,
      links: [],
      permission: ROLES_FOR_LICENSEES
    },
    {
      name: names.navigation.monitorThings,
      icon: 'tasks',
      text: 'menu.monitorThings',
      disabled: false,
      location: names.paths.monitorThings,
      links: [],
      permission: ROLES_FOR_SKYALERT_LICENSEES
    },
    {
      name: names.navigation.damageReport,
      icon: 'clipboard outline',
      text: 'menu.damageReport',
      disabled: false,
      location: names.paths.damageReport,
      links: [],
      permission: EXCLUDE_ROLE_FOR_CLIENT_USERS,
      isShowInStaging: true
    },
    {
      name: names.navigation.licenses,
      icon: 'key',
      text: 'menu.licenses.label',
      disabled: false,
      location: names.paths.licenses.main,
      links: [
        {
          text: 'menu.licenses.available',
          path: names.paths.licenses.available,
          disabled: false
        },
        {
          text: 'menu.licenses.assigned',
          path: names.paths.licenses.assigned,
          disabled: false
        },
        {
          text: 'menu.licenses.revoked',
          path: names.paths.licenses.revoked,
          disabled: false
        },
        {
          text: 'menu.licenses.expired',
          path: names.paths.licenses.expired,
          disabled: false
        }
      ],
      permission: EXCLUDE_ROLE_FOR_CLIENT_USERS
    },
    {
      name: names.navigation.things,
      icon: 'television',
      text: 'menu.things.label',
      disabled: false,
      location: names.paths.things.main,
      links: [
        {
          text: 'menu.things.gadget',
          path: names.paths.things.gadgetMini,
          disabled: false
        },
        {
          text: 'menu.things.desk',
          path: names.paths.things.desk,
          disabled: false
        },
        {
          text: 'menu.things.epicenter',
          path: names.paths.things.epicenter,
          disabled: false
        },
        {
          text: 'menu.things.saas',
          path: names.paths.things.saas,
          disabled: true
        }
      ],
      permission: ROLES_FOR_ALL
    },
    {
      name: names.navigation.groups,
      icon: 'cubes',
      text: 'menu.groups',
      disabled: false,
      location: names.paths.groups.main,
      links: [],
      permission: ROLES_FOR_CLIENTS
    },
    {
      name: names.navigation.alerts,
      icon: 'announcement',
      text: 'menu.alertsCustom',
      location: names.paths.alerts,
      disabled: true,
      permission: EXCLUDE_ROLE_FOR_CLIENT_USERS
    },
    {
      name: names.navigation.subscriptions,
      icon: 'drivers license',
      text: 'menu.subscriptions',
      location: names.paths.subscriptions,
      disabled: true,
      permission: EXCLUDE_ROLE_FOR_CLIENT_USERS
    },
    {
      name: names.navigation.users,
      icon: 'group',
      text: 'menu.users',
      location: names.paths.users,
      disabled: false,
      permission: ['admin', 'owner']
    },
    {
      name: names.navigation.tickets,
      icon: 'conversation',
      text: 'menu.tickets',
      location: names.paths.tickets,
      disabled: true,
      permission: EXCLUDE_ROLE_FOR_CLIENT_USERS
    },
    {
      name: names.navigation.support,
      icon: 'question',
      text: 'menu.support',
      location: names.paths.support,
      disabled: false,
      permission: ROLES_FOR_ALL
    }
  ]
};

export const BREADCRUMBS_CUSTOM: Interfaces.IkeyText = {
  available: 'breadcrumbCustom.available',
  assigned: 'breadcrumbCustom.assigned',
  revoked: 'breadcrumbCustom.revoked',
  expired: 'breadcrumbCustom.expired',
  epicenter: 'breadcrumbCustom.epicenter',
  'epicenter-pro': 'breadcrumbCustom.epicenter',
  'gadget-integrated': 'breadcrumbCustom.gadget',
  'gadget-mini': 'breadcrumbCustom.gadget',
  gadget: 'breadcrumbCustom.gadget',
  desk: 'breadcrumbCustom.desk'
};

export const ELEMENTS_BREADCRUMBS: Interfaces.IListBreadcrumbs = {
  [names.navigation.groups]: {
    content: 'menu.groups',
    location: names.paths.groups.main
  },
  [names.navigation.things]: {
    content: 'menu.things.label',
    location: names.paths.things.main
  },
  [names.navigation.licenses]: {
    content: 'menu.licenses.label',
    location: names.paths.licenses.main
  },
  [names.navigation.alerts]: {
    content: 'menu.alertsCustom',
    location: names.paths.alerts
  },
  [names.navigation.users]: {
    content: 'menu.users',
    location: names.paths.users
  },
  [names.navigation.subscriptions]: {
    content: 'menu.subscriptions',
    location: names.paths.subscriptions
  },
  [names.navigation.tickets]: {
    content: 'menu.tickets',
    location: names.paths.tickets
  }
};

export const AVATAR_OPTIONS_LIST: DropdownItemProps[] = [
  {
    key: 'user',
    text: 'avatar.account',
    icon: 'user',
    value: 'account',
    disabled: true
  },
  {
    key: 'settings',
    text: 'avatar.settings',
    icon: 'settings',
    value: 'settings'
  },
  {
    key: 'tutorial',
    text: 'avatar.tutorial',
    icon: 'info circle',
    value: 'tutorial'
  },
  {
    key: 'sign-out',
    text: 'avatar.signOut',
    icon: 'sign out',
    value: 'logout'
  }
];

export const SECTIONS_GROUPS_DETAIL: TabPaneProps[] = [
  {
    icon: 'eye',
    content: 'groups.subsections.tracking'
  },
  {
    icon: 'keyboard',
    content: 'groups.subsections.control'
  },
  {
    icon: 'hdd',
    content: 'groups.subsections.devices'
  },
  {
    icon: 'address card',
    content: 'groups.subsections.users'
  },
  {
    icon: 'folder open',
    content: 'groups.subsections.subgroups'
  },
  {
    icon: 'file alternate',
    content: 'groups.subsections.reports'
  }
];

export const LICENSES_TYPES_LIST: Interfaces.ILicenseList = {
  available: {
    icon: 'history',
    text: 'licenses.state.available',
    path: names.paths.licenses.available,
    color: 'blue',
    value: '--'
  },
  assigned: {
    icon: 'check circle outline',
    text: 'licenses.state.assigned',
    path: names.paths.licenses.assigned,
    color: 'green',
    value: '--'
  },
  revoked: {
    icon: 'minus circle',
    text: 'licenses.state.revoked',
    path: names.paths.licenses.revoked,
    color: 'yellow',
    value: '--'
  },
  expired: {
    icon: 'times circle outline',
    text: 'licenses.state.expired',
    path: names.paths.licenses.expired,
    color: 'red',
    value: '--'
  }
};

export const HEADER_LIST_LICENSES_TYPES: Interfaces.IHeaderListColumn[] = [
  {
    column: 'index',
    text: '#'
  },
  {
    column: 'id',
    text: 'tableList.header.license'
  },
  {
    column: 'status',
    text: 'tableList.header.status'
  },
  {
    column: 'type',
    text: 'tableList.header.typeThing'
  },
  {
    column: 'name',
    text: 'tableList.header.nameThing'
  },
  {
    column: 'location',
    text: 'tableList.header.location'
  }
  // TODO: UNCOMMENT WHEN ODOO RETURNS VALID UNTIL
  // {
  //   column: 'dateUntil',
  //   text: 'tableList.header.validUntil'
  // }
];

export const HEADER_LIST_LICENSE_AUTHORIZATIONS: Interfaces.IHeaderListColumn[] =
  [
    {
      column: 'name',
      text: 'tableList.header.auth'
    },
    {
      column: 'attributes',
      text: 'tableList.header.attributes'
    }
  ];

export const HEADER_LIST_LICENSE_RECEPTORS: Interfaces.IHeaderListColumn[] = [
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'type',
    text: 'tableList.header.type'
  },
  {
    column: 'id',
    text: 'tableList.header.uuid'
  },
  {
    column: 'status',
    text: 'tableList.header.status'
  }
];

export const TOTAL_CONNECT_TRACKING: Interfaces.ITotalTracking = {
  registered: '--',
  disconnected: '--'
};

export const HEADER_LIST_FEED_TRACKING: Interfaces.IHeaderListColumn[] = [
  {
    column: 'index',
    text: '#'
  },
  {
    column: 'createdAt',
    text: 'tableList.header.dateTime'
  },

  {
    column: 'event',
    text: 'tableList.header.event'
  },
  {
    column: 'description',
    text: 'tableList.header.description'
  }
];

export const HEADER_LIST_GROUPS_CONTROL_SCHEDULE_COMMANDS: Interfaces.IHeaderListColumn[] =
  [
    {
      column: 'index',
      text: '#'
    },
    {
      column: 'id',
      text: 'tableList.header.commandId'
    },
    {
      column: 'command',
      text: 'tableList.header.command'
    },
    {
      column: 'sendingTime',
      text: 'tableList.header.dateScheduled'
    },
    {
      column: 'status',
      text: 'tableList.header.state'
    },
    {
      column: 'actions',
      text: ''
    }
  ];

export const HEADER_LIST_GROUPS_FEED_TRACKING: Interfaces.IHeaderListColumn[] =
  [
    {
      column: 'index',
      text: '#'
    },
    {
      column: 'name',
      text: 'tableList.header.device'
    },
    {
      column: 'createdAt',
      text: 'tableList.header.dateTime'
    },

    {
      column: 'event',
      text: 'tableList.header.event'
    },
    {
      column: 'description',
      text: 'tableList.header.description'
    }
  ];

export const HEADER_LIST_DAMAGE_REPORT: Interfaces.IHeaderListColumn[] = [
  {
    column: 'index',
    text: '#'
  },
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'UUID',
    text: 'tableList.header.uuid'
  },
  {
    column: 'date',
    text: 'tableList.header.dateReport'
  },
  {
    column: 'damage',
    text: 'tableList.header.damage'
  },
  {
    column: 'location',
    text: 'tableList.header.locationThing'
  },
  {
    column: 'reportDetail',
    text: 'tableList.header.reportDetail'
  }
];

export const COMMANDS_EVENTS: Types.TCommandsEvent = {
  update: {
    name: 'control.commands.update.visible',
    description: 'control.commands.update.description'
  },
  'stop-app': {
    name: '',
    description: ''
  },
  'stop-actions': {
    name: '',
    description: ''
  },
  'test-alert': {
    name: 'control.commands.testSound.visible',
    description: 'control.commands.testSound.description'
  },
  'custom-alert': {
    name: 'control.commands.customAlert.visible',
    description: 'control.commands.customAlert.description'
  },
  'custom-audio': {
    name: 'control.commands.customAudio.visible',
    description: 'control.commands.customAudio.description'
  },
  'restart-app': {
    name: 'control.commands.restart.visible',
    description: 'control.commands.restart.description'
  },
  reboot: {
    name: 'control.commands.restart.visible',
    description: 'control.commands.restart.description'
  },
  'system-restart': {
    name: '',
    description: ''
  },
  'system-update': {
    name: '',
    description: ''
  },
  'silent-notification': {
    name: 'control.commands.silentNotification.visible',
    description: 'control.commands.silentNotification.description'
  },
  'set-config': {
    name: '',
    description: ''
  },
  'set-config-delta': {
    name: '',
    description: ''
  },
  'run-script': {
    name: '',
    description: ''
  },
  'notify-update-available': {
    name: '',
    description: ''
  },
  'drill-start': {
    name: 'control.commands.drill.start.visible',
    description: 'control.commands.drill.start.description'
  },
  'drill-stop': {
    name: 'control.commands.drill.stop.visible',
    description: 'control.commands.drill.stop.description'
  },
  earthquake: {
    name: 'control.commands.earthquake.visible',
    description: 'control.commands.earthquake.description'
  },
  'update-location': {
    name: 'control.commands.updateLocation.visible',
    description: 'control.commands.updateLocation.description'
  },
  'schedule-local-drill': {
    name: 'control.commands.scheduleLocalDrill.visible',
    description: 'control.commands.scheduleLocalDrill.skyAlertLicensee'
  }
};

export const LOGS_EVENTS: Types.TLogsEvent = {
  CONNECTED: {
    name: 'feedAck.events.connected',
    description: 'feedAck.events.description.connected'
  },
  DISCONNECTED: {
    name: 'feedAck.events.disconnected',
    description: 'feedAck.events.description.disconnected'
  },
  LOCATION_UPDATED: {
    name: 'feedAck.events.locationUpdated',
    description: 'feedAck.events.description.locationUpdated'
  },
  UNKNOWN: {
    name: 'feedAck.events.unknown',
    description: 'feedAck.events.description.unknown'
  }
};

export const LIST_CONTROLS_BUTTONS: Interfaces.IControlsCommandsList[] = [
  {
    id: 'control-button-drill-start',
    order: 1,
    name: 'drill',
    command: 'drill-start',
    texts: 'control.commands.drill.start',
    icon: 'hand paper'
  },
  {
    id: 'control-button-custom-alert',
    order: 2,
    name: 'customAlert',
    texts: 'control.commands.customAlert',
    command: 'custom-alert',
    icon: 'window restore outline'
  },
  {
    id: 'control-button-drill-stop',
    order: 3,
    name: 'stopDrill',
    texts: 'control.commands.drill.stop',
    command: 'drill-stop',
    icon: 'stop circle'
  },
  {
    id: 'control-button-custom-audio',
    order: 4,
    name: 'customAudio',
    texts: 'control.commands.customAudio',
    command: 'custom-audio',
    icon: 'bullhorn'
  },
  {
    id: 'control-button-restart-app',
    order: 5,
    name: 'restart',
    texts: 'control.commands.restart',
    command: 'restart-app',
    icon: 'refresh'
  },
  {
    id: 'control-button-test-alert-silent',
    order: 6,
    name: 'silentNotification',
    texts: 'control.commands.silentNotification',
    command: 'test-alert',
    icon: 'bell slash'
  },
  {
    id: 'control-button-test-alert-audio',
    order: 7,
    name: 'testSound',
    texts: 'control.commands.testSound',
    command: 'test-alert',
    icon: 'volume up'
  },
  {
    id: 'control-button-update',
    order: 8,
    name: 'update',
    texts: 'control.commands.update',
    command: 'update',
    icon: 'download'
  },
  {
    id: 'control-button-configuration',
    order: 9,
    name: 'configuration',
    texts: 'control.commands.configuration',
    command: 'silent-notification',
    icon: 'cogs'
  }
];

export const LIST_COMMANDS_BUTTONS_THINGS: Interfaces.IControlsCommandsList[] =
  [
    {
      id: 'commands-things-drill',
      name: 'drill',
      color: 'orange',
      icon: 'play circle outline',
      texts: 'control.commands.drill.start',
      command: 'drill-start'
    },
    {
      id: 'commands-things-stopDrill',
      name: 'stopDrill',
      color: 'orange',
      icon: 'stop circle outline',
      texts: 'control.commands.drill.stop',
      command: 'drill-stop'
    }
  ];

export const OPTIONS_LIST_SCHEDULE_COMMAND: Interfaces.IListSchedule[] = [
  {
    label: 'modals.titles.scheduled.now',
    value: 'now',
    disabled: false
  },
  {
    label: 'modals.titles.scheduled.schedule',
    value: 'scheduled',
    disabled: false
  }
];

export const HEADER_LIST_EVENTS_CONTROL: Interfaces.IHeaderListColumn[] = [
  {
    column: 'command',
    text: 'tableList.header.event'
  },
  {
    column: 'frequency',
    text: 'tableList.header.frequency'
  },
  {
    column: '',
    text: 'tableList.header.options'
  }
];

export const BODY_LIST_OPTIONS_EVENTS_CONTROL: Interfaces.IBodyListOptions[] = [
  {
    name: 'btn-events-edit',
    action: 'edit',
    circular: true,
    icon: 'edit',
    color: 'blue',
    disabled: true
  },
  {
    name: 'btn-events-delete',
    action: 'delete',
    circular: true,
    icon: 'trash',
    color: 'red',
    disabled: false
  }
];

export const HEADER_LIST_DETAIL_STATUS: Interfaces.IHeaderListColumn[] = [
  {
    column: 'createdAt',
    text: 'tableList.header.uuid'
  },
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'executed',
    text: 'tableList.header.confirmation'
  },
  {
    column: 'latency',
    text: 'tableList.header.latency'
  }
];

export const STATUS_SEND_COMMANDS: Interfaces.IStatusSendCommands = {
  received: {
    label: 'modals.control.received',
    value: '-/- '
  },
  executed: {
    label: 'modals.control.executed',
    value: '-/- '
  },
  latency: {
    label: 'modals.control.latency',
    value: '-'
  }
};

export const OPTIONS_LIST_SETTINGS_TIMEZONE: DropdownItemProps['options'] =
  timezone.map((id) => ({ text: id, value: id }));

export const OPTIONS_LIST_SETTINGS_OUTPUTS: Interfaces.IListSettingsOutputs &
  Interfaces.IkeyText = {
  sound: 'modals.labels.config.outputs.sound',
  relay1: 'modals.labels.config.outputs.relay1',
  relay2: 'modals.labels.config.outputs.relay2'
};

export const OPTIONS_LIST_SETTINGS_BEHAVIOR: Interfaces.IListSettingsBehavior &
  Interfaces.IkeyText = {
  default: {
    option: 'modals.labels.config.behaviors.default',
    inline: 'modals.labels.config.behaviors.default',
    usesValue: false,
    append: ''
  },
  on: {
    option: 'modals.labels.config.behaviors.on',
    inline: 'modals.labels.config.behaviors.on',
    usesValue: false,
    append: ''
  },
  onOff: {
    option: 'modals.labels.config.behaviors.onOff',
    inline: 'modals.labels.config.behaviors.inline.onOff',
    usesValue: true,
    append: 'modals.labels.config.behaviors.append.onOff'
  },
  offOn: {
    option: 'modals.labels.config.behaviors.offOn',
    inline: 'modals.labels.config.behaviors.inline.offOn',
    usesValue: true,
    append: 'modals.labels.config.behaviors.append.offOn'
  },
  off: {
    option: 'modals.labels.config.behaviors.off',
    inline: 'modals.labels.config.behaviors.off',
    usesValue: false,
    append: ''
  }
};

export const TOTAL_DEVICES_STATUS: Interfaces.ITotalTracking = {
  registered: '--',
  disconnected: '--'
};

export const HEADER_LIST_DEVICES_CONTROL: Interfaces.IHeaderListColumn[] = [
  {
    column: 'index',
    text: '#'
  },
  {
    column: 'thingName',
    text: 'tableList.header.name'
  },
  {
    column: 'thingType',
    text: 'tableList.header.type'
  },
  {
    column: 'thing',
    text: 'tableList.header.uuid'
  }
];

export const HEADER_LIST_DEVICES_MODAL_ADD: Interfaces.IHeaderListColumn[] = [
  {
    column: '',
    text: ''
  },
  {
    column: 'type',
    text: 'tableList.header.type'
  },
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'id',
    text: 'tableList.header.uuid'
  }
];

export const OPTIONS_PERMISSIONS_USERS = [
  {
    key: 'admin',
    text: 'permissions.user.admin',
    value: 'admin'
  },
  {
    key: 'supervisor',
    text: 'permissions.user.write',
    value: 'write'
  },
  {
    key: 'reader',
    text: 'permissions.user.read',
    value: 'read'
  }
];

export const GROUP_PERMISSIONS: any = {
  admin: {
    text: 'permissions.user.admin',
    icon: 'id badge'
  },
  write: {
    text: 'permissions.user.write',
    icon: 'file'
  },
  read: {
    text: 'permissions.user.read',
    icon: 'book'
  }
};

export const ACCOUNT_PERMISSION: Types.TAccountPermission = {
  owner: 'permissions.account.owner',
  admin: 'permissions.account.admin',
  user: 'permissions.account.user',
  licensee: 'permissions.account.licensee',
  SkyAlertLicensee: 'permissions.account.licensee'
};

export const OPTIONS_ACCOUNT_PERMISSIONS = [
  {
    key: 'admin',
    text: 'permissions.account.admin',
    value: 'admin'
  },
  {
    key: 'user',
    text: 'permissions.account.user',
    value: 'user'
  }
];
export const LICENSEE_ID: Interfaces.IkeyText = {
  D019: 'DASI',
  G01H: 'FAAM'
};

export const HEADER_LIST_GROUPS_DOWNLOAD: Interfaces.IHeadersTypeListColum = {
  monthly: [
    {
      column: 'filename',
      text: 'groups.reports.monthly'
    },
    {
      column: 'download',
      text: ''
    }
  ],
  weekly: [
    {
      column: 'filename',
      text: 'groups.reports.weekly'
    },
    {
      column: 'download',
      text: ''
    }
  ]
};

export const HEADER_LIST_GROUPS_USERS: Interfaces.IHeaderListColumn[] = [
  {
    column: 'index',
    text: '#'
  },
  {
    column: 'userName',
    text: 'tableList.header.name'
  },
  {
    column: 'email',
    text: 'tableList.header.email'
  },
  {
    column: 'permissions',
    text: 'tableList.header.permissions'
  },
  {
    column: 'actions',
    text: ''
  }
];

export const HEADER_LIST_USERS_MODAL_ADD: Interfaces.IHeaderListColumn[] = [
  {
    column: '',
    text: ''
  },
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'email',
    text: 'tableList.header.email'
  },
  {
    column: 'permissions',
    text: 'tableList.header.permissions'
  }
];

export const HEADER_LIST_SUBGROUPS: Interfaces.IHeaderListColumn[] = [
  {
    column: 'index',
    text: '#'
  },
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'thingCount',
    text: 'tableList.header.devices'
  },
  {
    column: 'usersCount',
    text: 'tableList.header.users'
  },
  {
    column: 'groupsCount',
    text: 'tableList.header.subgroups'
  },
  {
    column: 'createdAt',
    text: 'tableList.header.createdAt'
  }
];

export const HEADER_LIST_EVENTS_CONTROL_GROUPS: Interfaces.IHeaderListColumn[] =
  [
    {
      column: 'index',
      text: '#'
    },
    {
      column: 'groupName',
      text: 'tableList.header.name'
    },
    {
      column: 'createdAt',
      text: 'tableList.header.createdAt'
    },
    {
      column: 'permissions',
      text: 'tableList.header.permission'
    },
    {
      column: 'subgroups',
      text: 'tableList.header.subgroups'
    },
    {
      column: 'actions',
      text: 'Acciones'
    }
  ];

export const HEADER_LIST_EVENTS_CONTROL_USERS: Interfaces.IHeaderListColumn[] =
  [
    {
      column: 'index',
      text: '#'
    },
    {
      column: 'name',
      text: 'tableList.header.name'
    },
    {
      column: 'email',
      text: 'tableList.header.email'
    },
    {
      column: 'permissions',
      text: 'tableList.header.permission'
    },
    {
      column: 'status',
      text: 'tableList.header.meta'
    },
    {
      column: 'actions',
      text: 'Acciones'
    }
  ];

export const DEFAULT_TOTAL_THINGS: Interfaces.IThingsTotal = {
  epicenter: '--',
  gadget: '--',
  'gadget-mini': '--',
  'gadget-integrated': '--',
  desk: '--',
  'desk-plus': '--',
  saas: '--',
  'epicenter-pro': '--'
};

export const THINGS_TYPES_LIST: Interfaces.IThingsList = {
  [names.things.gadget]: {
    type: names.things.gadget,
    text: 'menu.things.gadget',
    path: names.paths.things.gadgetMini,
    image: images.devices.gadgetMini,
    disabled: false,
    explanation: 'things.descriptions.gadget'
  },
  [names.things.desk]: {
    type: names.things.desk,
    text: 'menu.things.desk',
    path: names.paths.things.desk,
    image: images.devices.desk,
    disabled: false,
    explanation: 'things.descriptions.desk'
  },
  [names.things.epicenter]: {
    type: names.things.epicenter,
    text: 'menu.things.epicenter',
    path: names.paths.things.epicenter,
    image: images.devices.epicenterLite,
    disabled: false,
    explanation: 'things.descriptions.epicenter'
  },
  [names.things.saas]: {
    type: names.things.saas,
    text: 'menu.things.saas',
    path: names.paths.things.saas,
    image: 'https://react.semantic-ui.com/images/wireframe/image.png',
    disabled: true,
    explanation: 'things.descriptions.saas'
  }
};

export const THINGS_TYPES_LIST_REPORT: Interfaces.IThingsListReport = {
  all: {
    text: 'things.reports.all',
    image: 'https://skyalert.mx/img/logo.png'
  },
  [names.things.gadget]: {
    type: names.things.gadget,
    text: 'menu.things.gadget',
    image: images.devices.gadgetMini
  },
  [names.things.desk]: {
    type: names.things.desk,
    text: 'menu.things.desk',
    image: images.devices.desk
  },
  [names.things.epicenter]: {
    type: names.things.epicenter,
    text: 'menu.things.epicenter',
    image: images.devices.epicenter
  }
};

export const HEADER_LIST_THINGS_CONNECTIONS: Interfaces.IHeaderListColumn[] = [
  {
    column: 'index',
    text: '#'
  },
  {
    column: 'customerSkyalertId',
    text: 'tableList.header.skyAlertId'
  },
  {
    column: 'customerName',
    text: 'tableList.header.nameClient'
  },
  {
    column: 'name',
    text: 'tableList.header.nameThings'
  },
  {
    column: 'connectionStatus',
    text: 'tableList.header.state'
  },
  {
    column: 'location',
    text: 'tableList.header.location'
  },
  {
    column: 'connectionDetail',
    text: ''
  }
];

export const HEADER_LIST_ALL_THINGS_CONNECTIONS: Interfaces.IHeaderListColumn[] =
  [
    {
      column: 'index',
      text: '#'
    },
    {
      column: 'customerSkyalertId',
      text: 'tableList.header.skyAlertId'
    },
    {
      column: 'customerName',
      text: 'tableList.header.nameClient'
    },
    {
      column: 'name',
      text: 'tableList.header.nameThings'
    },
    {
      column: 'type',
      text: 'tableList.header.type'
    },
    {
      column: 'connectionStatus',
      text: 'tableList.header.state'
    },
    {
      column: 'version',
      text: 'tableList.header.version'
    },
    {
      column: 'location',
      text: 'tableList.header.location'
    },
    {
      column: 'connectionDetail',
      text: ''
    }
  ];

interface CustomTabPanePropMonitorThings extends TabPaneProps {
  id: string;
  receptor: Types.TFilterReceptorMonitorThings;
  text: string;
}

export const SECTIONS_MONITOR_THINGS: CustomTabPanePropMonitorThings[] = [
  {
    id: 'pane-filter-all',
    receptor: 'all',
    text: 'monitor.panes.all'
  },
  {
    id: 'pane-filter-epicenter',
    receptor: 'epicenter',
    text: 'monitor.panes.epicenter'
  },
  // NOTE: DESK IS NOT RELEVANT DATA FOR CUSTOMER SUCCESS, AT THE MOMENT
  // {
  //   id: 'pane-filter-desk',
  //   receptor: 'desk',
  //   text: 'monitor.panes.desk'
  // },
  {
    id: 'pane-filter-gadget',
    receptor: 'gadget',
    text: 'monitor.panes.gadget'
  }
];

export const DEFAULT_ADDRESS_THING: Interfaces.IAddressLicense = {
  county: '',
  state: '',
  zipCode: ''
};

export const HEADER_LIST_DEVICES_THINGS: Interfaces.IHeaderListColumn[] = [
  {
    column: 'index',
    text: '#'
  },
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'id',
    text: 'tableList.header.uuid'
  },
  {
    column: 'connectionStatus',
    text: 'tableList.header.status'
  },
  {
    column: 'license',
    text: 'tableList.header.license'
  },
  {
    column: 'location',
    text: 'tableList.header.location'
  }
  // TODO: UNCOMMENT WHEN ODOO RETURNS VALID UNTIL
  // {
  //   column: 'dateUntil',
  //   text: 'tableList.header.validUntil'
  // }
];

export const HEADER_LIST_DEVICES_CONNECTION: Interfaces.IHeaderListColumn[] = [
  {
    column: 'index',
    text: '#'
  },
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'id',
    text: 'tableList.header.uuid'
  },
  {
    column: 'connectionStatus',
    text: 'tableList.header.time'
  }
];

export const SECTIONS_THINGS_DETAIL: TabPaneProps[] = [
  {
    icon: 'eye',
    content: 'things.subsections.tracking',
    disabled: false,
    name: 'Monitor'
  },
  {
    icon: 'keyboard',
    content: 'things.subsections.control',
    disabled: false,
    name: 'Control'
  },
  {
    icon: 'info circle',
    content: 'things.subsections.details',
    disabled: false,
    name: 'Info'
  }
];

export const HEADER_LIST_GROUPS_THINGS: Interfaces.IHeaderListColumn[] = [
  {
    column: 'name',
    text: 'tableList.header.name'
  }
];

export const HEADER_LIST_SELECT_EVENT: Interfaces.IHeaderListColumn[] = [
  {
    column: 'date',
    text: 'tableList.header.dateAndTime'
  },
  {
    column: 'origin',
    text: 'tableList.header.origin'
  },
  {
    column: 'intensity',
    text: 'tableList.header.intensity'
  },
  {
    column: 'detail',
    text: ''
  }
];

export const HEADER_LIST_INFO_DAMAGE: Interfaces.IHeaderListColumn[] = [
  {
    column: 'status',
    text: 'tableList.header.status'
  },
  {
    column: 'report',
    text: 'tableList.header.report'
  },
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'uuid',
    text: 'tableList.header.uuid'
  }
];

export const HEADER_LIST_LICENSES_THINGS: Interfaces.IHeaderListColumn[] = [
  {
    column: 'name',
    text: 'tableList.header.name'
  },
  {
    column: 'status',
    text: 'tableList.header.status'
  }
];

export const OPTIONS_STATUS_LICENSES_EDIT = {
  available: [
    {
      key: 'available',
      text: 'licenses.state.available',
      value: 'available'
    },
    {
      key: 'expired',
      text: 'licenses.state.expired',
      value: 'expired'
    },
    {
      key: 'revoked',
      text: 'licenses.state.revoked',
      value: 'revoked'
    }
  ],
  assigned: [
    {
      key: 'assigned',
      text: 'licenses.state.assigned',
      value: 'assigned'
    },
    {
      key: 'available',
      text: 'licenses.state.available',
      value: 'available'
    },
    {
      key: 'expired',
      text: 'licenses.state.expired',
      value: 'expired'
    },
    {
      key: 'revoked',
      text: 'licenses.state.revoked',
      value: 'revoked'
    }
  ],
  expired: [
    {
      key: 'available',
      text: 'licenses.state.available',
      value: 'available'
    },
    {
      key: 'expired',
      text: 'licenses.state.expired',
      value: 'expired'
    },
    {
      key: 'revoked',
      text: 'licenses.state.revoked',
      value: 'revoked'
    }
  ],
  revoked: [
    {
      key: 'available',
      text: 'licenses.state.available',
      value: 'available'
    },
    {
      key: 'expired',
      text: 'licenses.state.expired',
      value: 'expired'
    },
    {
      key: 'revoked',
      text: 'licenses.state.revoked',
      value: 'revoked'
    }
  ]
};

export const ICON_STATUS: Interfaces.IParamsIconStatus = {
  critic: {
    name: 'exclamation triangle',
    color: 'red'
  },
  medium: {
    name: 'exclamation triangle',
    color: 'orange'
  },
  moderate: {
    name: 'exclamation triangle',
    color: 'yellow'
  },
  okey: {
    name: 'check circle',
    color: 'green'
  },
  nothing: { name: 'help' }
};

export const LIST_OPTIONS_OUTPUTS: CheckboxProps[] = [
  {
    name: 'relay1',
    label: 'modals.labels.config.relay1',
    checked: false
  },
  {
    name: 'relay2',
    label: 'modals.labels.config.relay2',
    checked: false
  }
];

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

export const CONTACT_US: Interfaces.IContactUs[] = [
  {
    content: 'support.contactUs.phone.content',
    subheader: 'support.contactUs.phone.label',
    icon: 'whatsapp'
  },
  {
    content: 'support.contactUs.mail.content',
    subheader: 'support.contactUs.mail.label',
    icon: 'mail outline'
  }
];

export const FREQUENTLY_ASKED_QUESTIONS: Interfaces.ICardSupport[] = [
  {
    image: images.devices.desk,
    title: 'support.faq.desk.label',
    section: 'desk',
    permission: ROLES_FOR_ALL
  },
  {
    image: images.devices.gadgetMini,
    title: 'support.faq.gadget.label',
    section: 'gadget',
    permission: ROLES_FOR_ALL
  },
  {
    image: images.devices.epicenter,
    title: 'support.faq.epicenter.label',
    section: 'epicenter',
    permission: ROLES_FOR_ALL
  }
];

export const QUESTIONS_ABOUT_DEVICES: Interfaces.IFAQ = {
  desk: 'support.faq.desk.content',
  gadget: 'support.faq.gadget.content',
  epicenter: 'support.faq.epicenter.content'
};

export const GENERAL_QUESTIONS: Interfaces.ICardSupport[] = [
  {
    icon: 'key',
    title: 'support.general.licenses.label',
    section: 'licenses',
    permission: EXCLUDE_ROLE_FOR_CLIENT_USERS
  },
  {
    icon: 'television',
    title: 'support.general.things.label',
    section: 'things',
    permission: ROLES_FOR_ALL
  },
  {
    icon: 'cubes',
    title: 'support.general.groups.label',
    section: 'groups',
    permission: ROLES_FOR_ALL
  },
  {
    icon: 'users',
    title: 'support.general.users.label',
    section: 'users',
    permission: EXCLUDE_ROLE_FOR_CLIENT_USERS
  }
];

export const PROPS_EMPTY: Interfaces.IEmptyProps = {
  groups: {
    icon: 'cube',
    texts: 'messages.groups',
    userText: 'messages.groupsUser'
  },
  users: {
    icon: 'group',
    texts: 'messages.users',
    userText: 'messages.groupsUser'
  }
};

export const GENERAL_INFORMATION: Interfaces.IGeneralInformation = {
  licenses: 'support.general.licenses.resume',
  things: 'support.general.things.resume',
  groups: 'support.general.groups.resume',
  users: 'support.general.users.resume'
};

export const BREAKPOINTS_SCREEN: Interfaces.IkeyText = {
  0: 'xs',
  600: 'sm',
  992: 'md',
  1280: 'lg',
  1920: 'xl'
};

export const SIZES_SCREEN: Interfaces.IkeyText = {
  XS: {
    MIN: 0,
    MAX: 600
  },
  SM: {
    MIN: 600,
    MAX: 991
  },
  MD: {
    MIN: 991,
    MAX: 1280
  },
  LG: {
    MIN: 1280,
    MAX: 1920
  },
  XL: {
    MIN: 1920
  }
};

export const OPTIONS_LANGUAGE = [
  { key: 1, text: 'settings.locale.spanish', value: 'es' },
  { key: 2, text: 'settings.locale.english', value: 'en' }
];

export const PROPS_EMPTY_LAYER_CONTAINER: Interfaces.IEmptyPropsLayer = {
  CLIENTS: {
    icon: 'user',
    texts: {
      title: 'messages.clients.title',
      subtitle: 'messages.clients.subtitle'
    }
  },
  GROUPS: {
    icon: 'cube',
    texts: {
      title: 'messages.groups.title',
      subtitle: 'messages.groups.subtitle'
    },
    userText: {
      title: 'messages.groupsUser.title',
      subtitle: 'messages.groupsUser.subtitle'
    }
  },
  USERS: {
    icon: 'group',
    texts: {
      title: 'messages.users.title',
      subtitle: 'messages.users.subtitle'
    },
    userText: {
      title: 'messages.groupsUser.title',
      subtitle: 'messages.groupsUser.subtitle'
    }
  },
  THINGS: {
    icon: 'computer',
    texts: {
      title: 'messages.things.types.title',
      subtitle: 'messages.things.types.subtitle'
    }
  },
  LICENSES: {
    icon: 'key',
    texts: {
      title: 'messages.licenses.title',
      subtitle: 'messages.licenses.subtitle'
    }
  },
  FEEDACK: {
    icon: 'thumbs up',
    texts: {
      title: 'messages.feedAck.title',
      subtitle: 'messages.feedAck.subtitle'
    }
  },
  SUBGROUPS: {
    icon: 'folder open',
    texts: {
      title: 'messages.groupsDetail.subgroups.title',
      subtitle: 'messages.groupsDetail.subgroups.subtitle'
    }
  },
  'GROUPS-THINGS': {
    icon: 'tv',
    texts: {
      title: 'messages.groupsDetail.tracking.title',
      subtitle: 'messages.groupsDetail.tracking.subtitle'
    }
  },
  'GROUPS-USERS': {
    icon: 'tv',
    texts: {
      title: 'messages.groupsDetail.users'
    }
  },
  'GROUPS-FEEDACK': {
    icon: 'thumbs up',
    texts: {
      title: 'messages.feedAck.title',
      subtitle: 'messages.feedAck.subtitle'
    }
  },
  'CONTROL-THINGS': {
    icon: 'calendar alternate outline',
    texts: {
      title: 'control.empty.title',
      subtitle: ''
    }
  },
  'MONITOR-THINGS': {
    icon: 'calendar alternate outline',
    texts: {
      title: 'control.empty.title',
      subtitle: ''
    }
  },
  'MONITOR-THINGS-ALL': {
    icon: 'calendar alternate outline',
    texts: {
      title: 'messages.monitorThings.title',
      subtitle: ''
    }
  },
  'DAMAGE-REPORT': {
    icon: 'calendar alternate outline',
    texts: {
      title: 'messages.monitorThings.title',
      subtitle: ''
    }
  }
};

export const OPTIONS_TYPE_REPORT = [
  { key: 'today', value: 'today', text: 'things.reports.modal.select.today' },
  { key: 'day', value: 'day', text: 'things.reports.modal.select.day' },
  { key: 'range', value: 'range', text: 'things.reports.modal.select.range' }
];

const ALL_STEPS_TITLES: string[] = [
  'onBoarding.step.one.section',
  'onBoarding.step.two.section',
  'onBoarding.step.three.section',
  'onBoarding.step.four.section'
];

const STEPS_WITHOUT_ASSIGN_LICENSE_TITLES: string[] = [
  'onBoarding.step.one.section',
  'onBoarding.step.two.section',
  'onBoarding.step.four.section'
];

export const STEPS_ONBOARDING_TITLES: Types.TSteps = {
  owner: ALL_STEPS_TITLES,
  admin: ALL_STEPS_TITLES,
  user: STEPS_WITHOUT_ASSIGN_LICENSE_TITLES,
  licensee: STEPS_WITHOUT_ASSIGN_LICENSE_TITLES,
  SkyAlertLicensee: STEPS_WITHOUT_ASSIGN_LICENSE_TITLES
};

const ALL_STEPS: string[] = [
  Types.EViewsTutorial.initial,
  Types.EViewsTutorial.safety,
  Types.EViewsTutorial.assignLicensee,
  Types.EViewsTutorial.final
];

const STEPS_WITHOUT_ASSIGN_LICENSE: string[] = [
  Types.EViewsTutorial.initial,
  Types.EViewsTutorial.safety,
  Types.EViewsTutorial.final
];

export const STEP_PERMISSIONS: Types.TSteps = {
  owner: ALL_STEPS,
  admin: ALL_STEPS,
  user: STEPS_WITHOUT_ASSIGN_LICENSE,
  licensee: STEPS_WITHOUT_ASSIGN_LICENSE,
  SkyAlertLicensee: STEPS_WITHOUT_ASSIGN_LICENSE
};

// ------------------------------------------------------------------
// MODAL ASSIGN LICENSES
// ------------------------------------------------------------------

export const HEADER_LIST_LICENSES_ASSIGN_MODAL: Interfaces.IHeaderListColumn[] =
  [
    {
      column: 'index',
      text: '#'
    },
    {
      column: 'id',
      text: 'tableList.header.license'
    },
    {
      column: 'name',
      text: 'tableList.header.name'
    },
    {
      column: 'location',
      text: 'tableList.header.location'
    },
    {
      column: 'email',
      text: 'tableList.h eader.email'
    },
    {
      column: 'name',
      text: 'tableList.header.nameLicense'
    },
    {
      column: 'lastName',
      text: 'tableList.header.lastName'
    }
  ];

// ------------------------------------------------------------------
// CLIENTS
// ------------------------------------------------------------------

export const HEADER_LIST_CLIENTS: Interfaces.IHeaderListColumn[] = [
  {
    column: 'customerId',
    text: 'tableList.header.customerId'
  },
  {
    column: 'client-name',
    text: 'tableList.header.name'
  },
  {
    column: 'commercialId',
    text: 'tableList.header.commercialId'
  },
  {
    column: 'client-email',
    text: 'tableList.header.email'
  },
  {
    column: 'actions',
    text: ''
  }
];

export const OPTIONS_SEARCH_CLIENTS = [
  {
    key: 'commercialId',
    text: 'tableList.header.commercialId',
    value: 'skyalertId'
  },
  {
    key: 'customerId',
    text: 'tableList.header.customerId',
    value: 'customerId'
  },
  {
    key: 'email',
    text: 'tableList.header.email',
    value: 'email'
  }
];

export const OPTIONS_SEARCH_LICENSES = [
  {
    key: 'idLicenses',
    text: 'tableList.header.license',
    value: 'id'
  },
  {
    key: 'name',
    text: 'tableList.header.name',
    value: 'name'
  }
];

export const OPTIONS_SEARCH_THINGS = [
  {
    key: 'name',
    text: 'tableList.header.name',
    value: 'name'
  },
  {
    key: 'UUID',
    text: 'tableList.header.uuid',
    value: 'id'
  },
  {
    key: 'license',
    text: 'tableList.header.license',
    value: 'license.id'
  }
];

export const OPTIONS_SEARCH_USERS = [
  {
    key: 'name',
    text: 'tableList.header.name',
    value: 'name'
  },
  {
    key: 'email',
    text: 'tableList.header.email',
    value: 'email'
  }
];

export const OPTIONS_SEARCH_THINGS_GROUPS = [
  {
    column: 'type',
    text: 'tableList.header.type',
    value: 'type'
  },
  {
    key: 'name',
    text: 'tableList.header.name',
    value: 'name'
  },
  {
    key: 'UUID',
    text: 'tableList.header.uuid',
    value: 'id'
  }
];

export const OPTIONS_SEARCH_MONITOR_THINGS = [
  {
    column: 'customerSkyalertId',
    text: 'tableList.header.skyAlertId',
    value: 'customerSkyalertId'
  },
  {
    column: 'name',
    text: 'tableList.header.nameThings',
    value: 'name'
  },
  {
    column: 'customerName',
    text: 'tableList.header.nameClient',
    value: 'customerName'
  },
  {
    column: 'customer',
    text: 'tableList.header.customerId',
    value: 'customer'
  }
];

export const OPTIONS_SEARCH_DAMAGE_REPORT = [
  {
    key: 'name',
    text: 'tableList.header.name',
    value: 'name'
  },
  {
    key: 'UUID',
    text: 'tableList.header.uuid',
    value: 'id'
  }
];

export const ICONS_DESCRIPTIONS_DAMAGE_REPORT: Interfaces.IDescriptionIconsDamageReport[] =
  [
    {
      iconName: 'user circle',
      content: 'damageReport.status.people.title',
      subtitle: 'damageReport.status.people.description'
    },
    {
      iconName: 'shopping basket',
      content: 'damageReport.status.inventory.title',
      subtitle: 'damageReport.status.inventory.description'
    },
    {
      iconName: 'home',
      content: 'damageReport.status.property.title',
      subtitle: 'damageReport.status.property.description'
    },
    {
      iconName: 'comment alternate',
      content: 'damageReport.status.other.title',
      subtitle: 'damageReport.status.other.description'
    },
    {
      iconName: 'thumbs up',
      color: 'green',
      content: 'damageReport.status.none.title',
      subtitle: 'damageReport.status.none.description'
    }
  ];

export const HEADER_ACTIONS = {
  column: 'actions',
  text: ''
};

export const DEFAULT_SELECT_CLIENT: Interfaces.IClientData = {
  id: '',
  name: '',
  email: ''
};

export const DEFAULT_VALUE_CLIENT = {
  id: '',
  name: '',
  email: '',
  skyalertId: ''
};

export const SECTIONS_DO_NOT_NEED_SELECT_CLIENT = [
  names.paths.dashboard,
  names.paths.monitorThings,
  names.paths.clients,
  names.paths.support
];

export const OPTIONS_MAP_EDIT_LICENSES: google.maps.MapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  draggable: true,
  zoomControl: true
};

export const OPTIONS_MAP_EDIT_LOCATION_THING: google.maps.MapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  draggable: true,
  zoomControl: true
};

export const ALL_VALID_SECTIONS = [
  names.paths.login,
  names.paths.dashboard,
  names.paths.clients,
  names.paths.groups.main,
  names.paths.groups.match,
  names.paths.things.main,
  names.paths.things.matchType,
  names.paths.things.matchDetail,
  names.paths.licenses.main,
  names.paths.licenses.matchStatus,
  names.paths.licenses.matchDetail,
  names.paths.users,
  names.paths.support,
  names.paths.notFound
];

export const DEFAULT_SELECT_LICENSE: Interfaces.ISelectLicense = {
  id: '',
  county: '',
  state: '',
  zipCode: '',
  status: 'available',
  location: { lng: 0, lat: 0 }
};
