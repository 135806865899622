import React, { useContext, useState } from 'react';
import MessageUI from '../../components/MessageUI';
import ModalInfo from '../../components/Modals/Custom/Info';

interface IPropsCtxRoutes {
  children: React.ReactNode;
}

type TRequest = 'success' | 'error';

interface IStateStatus {
  type: TRequest;
  value: boolean;
}

interface IResponse {
  type: TRequest;
  action?: string;
  message: string;
  errorInfo?: string;
  delay?: number;
}

interface IMessage {
  header?: string;
  content: string;
  info?: string;
}

interface IStateCtx {
  UpdateMessageResponse: (props: IResponse) => void;
  activeModalInfo: (text: string) => void;
}

const DEFAULT_MESSAGE = {
  header: '',
  content: '',
  info: ''
};

const StatusRequestContext = React.createContext<IStateCtx>({} as IStateCtx);

export const useStatusRequest = (): IStateCtx => {
  const context = useContext(StatusRequestContext);
  if (context === undefined) {
    throw new Error(
      'useStatusRequest must be used within a StatusRequestProvider'
    );
  }

  return context;
};

export const StatusRequestProvider = ({
  children
}: IPropsCtxRoutes): JSX.Element => {
  const [isInfo, setIsInfo] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>(DEFAULT_MESSAGE);

  const changeStatus = (
    type: IStateStatus['type'],
    value: IStateStatus['value']
  ): void => {
    if (type === 'success') {
      setIsSuccess(value);
    } else {
      setIsError(value);
    }
  };

  const handlerOnDismiss = (): void => {
    const type = isSuccess ? 'success' : 'error';
    changeStatus(type, false);
  };

  const toggleOpenModal = (): void => {
    setIsOpenModal(!isOpenModal);
  };

  const UpdateMessageResponse = ({
    type,
    action: header,
    message: content,
    errorInfo: info,
    delay = 3000
  }: IResponse): void => {
    changeStatus(type, true);
    setMessage({
      header,
      content,
      info
    });
    if (info) {
      setIsInfo(false);
      toggleOpenModal();
    }
    setTimeout(() => {
      changeStatus(type, false);
    }, delay);
  };
  const activeModalInfo = (text: string): void => {
    setIsInfo(true);
    setMessage({
      ...DEFAULT_MESSAGE,
      info: text
    });
    toggleOpenModal();
  };

  const typeIcon = isSuccess ? 'check circle' : 'warning circle';
  return (
    <StatusRequestContext.Provider
      value={{ UpdateMessageResponse, activeModalInfo }}
    >
      <ModalInfo
        isError={!isInfo}
        isOpen={isOpenModal}
        textInfo={message.info ? message.info : ''}
        onAccept={toggleOpenModal}
      />
      <MessageUI
        className='wrapper-messageUI message-safety'
        animation='fade left'
        negative={isError}
        success={isSuccess}
        icon={typeIcon}
        isVisible={isError || isSuccess}
        header={message.header}
        content={message.content}
        onDismiss={handlerOnDismiss}
      />
      {children}
    </StatusRequestContext.Provider>
  );
};
