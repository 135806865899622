import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  DropdownProps,
  Form,
  Header,
  Modal
} from 'semantic-ui-react';

import * as Constants from '../../../config/constants';
import * as Interfaces from '../../../interfaces';
import * as Types from '../../../types';

import '../../../scss/layout/Modal.scss';

export interface PropsComponentModalLicenses {
  editValues: Interfaces.ISelectLicense;
  isOpen: boolean;
  isLoading: boolean;
  onAccept(data: Interfaces.ISelectLicense): void;
  onCancel(): void;
}

interface IState {
  error: {
    location: boolean;
    coordLat: string;
    coordLng: string;
    validDateUntil: string;
    validDateFrom: string;
  };
  status: Types.TLicenseStatus;
}

const DEFAULT_STATUS: IState['status'] = 'available';

const EditLicenses = ({
  editValues,
  isOpen,
  isLoading,
  onCancel,
  onAccept
}: PropsComponentModalLicenses): JSX.Element => {
  const { t: translations } = useTranslation();
  const [newStatus, setNewStatus] = useState<IState['status']>(DEFAULT_STATUS);

  const TRANSLATE_OPTIONS_STATUS = Constants.OPTIONS_STATUS_LICENSES_EDIT[
    editValues.status as Types.TLicenseStatus
  ].map((item) => ({
    ...item,
    text: translations(item.text as any)
  }));

  const resetStates = (): void => {
    setNewStatus(DEFAULT_STATUS);
  };

  useEffect(() => {
    if (isOpen) {
      resetStates();
      if (editValues.status) {
        setNewStatus(editValues.status);
      }
    }
  }, [isOpen]);

  const handlerOnChangeDropdown = (
    e: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ): void => {
    e.preventDefault();
    setNewStatus(value as Types.TLicenseStatus);
  };

  const handlerOnAccept = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();

    const newData: Interfaces.ISelectLicense = {
      ...editValues,
      status: newStatus
    };

    onAccept(newData);
  };

  return (
    <Modal
      size='small'
      open={isOpen}
      className='wrapper-modal wrapper-modal-edit-licenses'
      data-testid='modal-licenses-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={translations('modals.titles.edit.license')}
          icon='key'
        />
      </Modal.Header>
      <Modal.Content>
        <Form widths='equal' autoComplete='off'>
          <Header
            as='h5'
            content={translations('modals.labels.license.status')}
          />
          <Dropdown
            fluid
            selection
            name='status'
            options={TRANSLATE_OPTIONS_STATUS}
            value={newStatus}
            onChange={handlerOnChangeDropdown}
            disabled={isLoading}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={onCancel}
          content={translations('actions.cancel')}
          data-testid='button-cancel-modal-licenses'
        />
        <Button
          positive
          disabled={isLoading}
          onClick={handlerOnAccept}
          loading={isLoading}
          content={translations('actions.accept')}
          data-testid='button-accept-modal-licenses'
        />
      </Modal.Actions>
    </Modal>
  );
};

export default EditLicenses;
