import { TParamsLicenses } from '../types';

const names = {
  themes: {
    light: 'theme-light',
    dark: 'theme-dark'
  },
  paths: {
    login: '/login',
    support: '/support',
    users: '/users',
    clients: '/clients',
    damageReport: '/damageReport',
    monitorThings: '/monitor',
    groups: {
      main: '/groups',
      match: '/groups/detail',
      detail: '/groups/detail'
    },
    alerts: '/alerts',
    tickets: '/tickets',
    dashboard: '/',
    subscriptions: '/subscriptions',
    licenses: {
      main: '/licenses',
      matchStatus: '/licenses/:status',
      matchDetail: '/licenses/:status/:id/detail',
      available: '/licenses/available',
      assigned: '/licenses/assigned',
      revoked: '/licenses/revoked',
      expired: '/licenses/expired',
      detail: (status: TParamsLicenses['status'], id: TParamsLicenses['id']) =>
        `/licenses/${status}/${id}/detail`
    },
    things: {
      main: '/things',
      matchType: '/things/:type',
      matchDetail: '/things/:type/detail',
      epicenter: '/things/epicenter',
      gadgetMini: '/things/gadget',
      gadget: '/things/gadget',
      desk: '/things/desk',
      saas: '/things/saas',
      detail: (type: string) => `/things/${type}/detail`
    },
    notFound: '*'
  },
  things: {
    epicenter: 'epicenter',
    epicenterPro: 'epicenter-pro',
    gadgetIntegrated: 'gadget-integrated',
    gadgetMini: 'gadget-mini',
    gadget: 'gadget',
    desk: 'desk',
    saas: 'saas'
  },
  licenses: {
    available: 'available',
    assigned: 'assigned',
    revoked: 'revoked',
    expired: 'expired'
  },
  navigation: {
    dashboard: 'NAVIGATION_DASHBOARD',
    damageReport: 'NAVIGATION_DAMAGE_REPORT',
    monitorThings: 'NAVIGATION_MONITOR_THINGS',
    clients: 'NAVIGATION_CLIENTS',
    groups: 'NAVIGATION_GROUPS',
    alerts: 'NAVIGATION_ALERTS',
    licenses: 'NAVIGATION_LICENSES',
    users: 'NAVIGATION_USERS',
    subscriptions: 'NAVIGATION_SUBSCRIPTIONS',
    things: 'NAVIGATION_THINGS',
    tickets: 'NAVIGATION_TICKETS',
    faq: 'NAVIGATION_FAQ',
    support: 'NAVIGATION_SUPPORT'
  },
  storageKeys: {
    userInfo: '@CURRENT_USER:key',
    userAccess: '@CURRENT_ACCESS:key',
    userSettings: '@CURRENT_SETTINGS:key',
    navigation: '@NAVIGATION:key',
    clientInfo: '@CURRENT_CLIENT:key',
    monitorInfoThings: '@MONITOR_INFO_THINGS:key'
  },
  cognito: {
    challengeName: 'NEW_PASSWORD_REQUIRED'
  }
};

export default names;
