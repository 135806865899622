import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useClients } from '../../hooks/useClients';
import Empty from '../Empty';
import * as Constants from '../../config/constants';

interface IProps {
  children?: React.ReactNode;
}

function ValidationLicensee({ children }: IProps) {
  const { pathname } = useLocation();
  const { isDisableNavigation } = useClients();
  const { t: translation } = useTranslation();

  if (Constants.SECTIONS_DO_NOT_NEED_SELECT_CLIENT.includes(pathname)) {
    return <>{children}</>;
  }

  if (isDisableNavigation(pathname)) {
    const textsEmpty = translation('messages.licensesClient', {
      returnObjects: true
    });
    return <Empty texts={textsEmpty} iconName='address card outline' />;
  }

  return <>{children}</>;
}

ValidationLicensee.defaultProps = {
  children: <div />
};

export default ValidationLicensee;
