/* eslint-disable no-case-declarations */
import React, { SyntheticEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { DateInput, DatesRangeInput } from 'semantic-ui-calendar-react';
import {
  Button,
  ButtonProps,
  Form,
  Header,
  Modal,
  Select
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import { OPTIONS_TYPE_REPORT } from '../../../../config/constants';

import '../../../../scss/layout/Modal.scss';

export interface PropsComponentModalThingsReport {
  isOpen: boolean;
  isLoading: boolean;
  onAccept(startDate: string, endDate: string, typeReport: string): void;
  onCancel(e: SyntheticEvent, data: ButtonProps): void;
}

const REPORTING_START_DATE = '01-01-2023';
const DATE_FORMAT = 'YYYY-MM-DD';

const ModalThingsReport = ({
  isOpen,
  isLoading,
  onAccept,
  onCancel
}: PropsComponentModalThingsReport): JSX.Element => {
  const { t } = useTranslation();
  const {
    screen: { isMobile },
    isMobileDevice
  } = useBreakpoint();

  const responsiveMobile = isMobileDevice || isMobile;
  const [date, setDate] = useState<string>('');
  const [typeReport, setTypeReport] = useState<string>('');

  const OPTIONS_SELECT = OPTIONS_TYPE_REPORT.map((element) => ({
    ...element,
    text: t(element.text as any)
  }));

  const resetStates = (): void => {
    setDate('');
    setTypeReport('');
  };

  useEffect(() => {
    if (isOpen) {
      resetStates();
    }
  }, [isOpen]);

  const handlerAccept = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    let initialDate = moment().format(DATE_FORMAT);
    let endDate = moment().format(DATE_FORMAT);

    switch (typeReport) {
      case 'day':
        initialDate = date;
        endDate = date;
        break;
      case 'range':
        const datesReport = date.split(' - ');
        [initialDate, endDate] = datesReport;
        break;
      default:
        break;
    }

    onAccept(initialDate, endDate, typeReport);
  };

  const handlerOnChangeSelect = (e: SyntheticEvent, { value }: any): void => {
    e.preventDefault();
    const dateValue = value !== 'today' ? '' : moment().format(DATE_FORMAT);
    setTypeReport(value as string);
    setDate(dateValue);
  };

  const handlerOnChange = (e: SyntheticEvent, { value }: any): void => {
    e.preventDefault();
    setDate(value);
  };

  const handlerOnClose = (e: SyntheticEvent, data: ButtonProps): void => {
    resetStates();
    onCancel(e, data);
  };

  const renderSelectDate = (): JSX.Element => {
    switch (typeReport) {
      case 'day':
        return (
          <>
            <Header as='h4' content={t('things.reports.modal.headerDay')} />
            <DateInput
              inline
              name='date'
              value={date}
              dateFormat={DATE_FORMAT}
              iconPosition='left'
              minDate={REPORTING_START_DATE}
              maxDate={moment()}
              onChange={handlerOnChange}
            />
          </>
        );
      case 'range':
        return (
          <>
            <Header as='h4' content={t('things.reports.modal.headerRange')} />
            <DatesRangeInput
              inline
              name='datesRange'
              value={date}
              dateFormat={DATE_FORMAT}
              iconPosition='left'
              maxDate={moment()}
              onChange={handlerOnChange}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Modal
      size={responsiveMobile ? 'large' : 'mini'}
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modalThingsReport-component'
    >
      <Modal.Header>
        <Header
          as='h3'
          content={t('modals.titles.create.thingsReport')}
          icon='file'
        />
      </Modal.Header>
      <Modal.Content>
        <Form autoComplete='off'>
          <Header content={t('things.reports.modal.header')} />
          <Select options={OPTIONS_SELECT} onChange={handlerOnChangeSelect} />
          {renderSelectDate()}

          {date && (
            <Header
              as='h5'
              content={t('things.reports.modal.question', { date })}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          disabled={isLoading}
          onClick={handlerOnClose}
          content={t('actions.cancel')}
        />
        <Button
          positive
          disabled={
            !date ||
            (typeReport !== 'range' ? false : !date.split(' - ')[1].length) ||
            isLoading
          }
          onClick={handlerAccept}
          loading={isLoading}
          content={t('actions.download')}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ModalThingsReport;
