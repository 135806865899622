import React from 'react';
import {
  Card,
  Divider,
  Grid,
  Placeholder,
  PlaceholderHeader,
  PlaceholderLine,
  Segment,
  SemanticWIDTHSNUMBER
} from 'semantic-ui-react';

import './Skeletons.scss';

interface IPropsList {
  itemsPerView: SemanticWIDTHSNUMBER;
}

interface IPropsBlock {
  styled?: React.CSSProperties;
  className?: string;
}

interface IPropsSearch {
  isMobile: boolean;
  isStatics: boolean;
  margin: number | string;
}

export const SkeletonListCard = ({ itemsPerView }: IPropsList): JSX.Element => (
  <div className='wrapper-skeletons'>
    <Card.Group itemsPerRow={itemsPerView}>
      {new Array(itemsPerView * 3).fill('card-loading').map((name, index) => (
        <Card key={`skeleton-list-card-${index + 1}`} className={name}>
          <Card.Content>
            <Placeholder>
              <Placeholder.Line length='full' />
              <Placeholder.Line length='medium' />
            </Placeholder>
            <Placeholder className='icon-loading'>
              <Placeholder.Image />
            </Placeholder>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  </div>
);
export const SkeletonBlock = ({
  styled,
  className
}: IPropsBlock): JSX.Element => {
  const defaultStyles: React.CSSProperties = {
    maxWidth: '100%',
    height: 175
  };
  return (
    <Placeholder style={styled || defaultStyles} className={className}>
      <Placeholder.Image />
    </Placeholder>
  );
};

export const SkeletonThingsType = (): JSX.Element => (
  <>
    <Divider style={{ margin: '1em auto' }} hidden />
    <SkeletonBlock styled={{ maxWidth: '100%', height: 80 }} />
    <Divider style={{ margin: '1em auto' }} hidden />
    <SkeletonBlock styled={{ marginLeft: '35%', maxWidth: '30%' }} />
    <Divider style={{ margin: '2em auto' }} hidden />
    <SkeletonBlock styled={{ maxWidth: '100%', height: '30vh' }} />
  </>
);

export const SkeletonStatic = (): JSX.Element => (
  <Grid relaxed stackable padded>
    <Grid.Row>
      <Grid.Column width={16}>
        <Placeholder style={{ margin: 'auto' }}>
          <Placeholder.Image style={{ height: '10em' }} />
        </Placeholder>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export const SkeletonSearch = ({
  isMobile,
  margin,
  isStatics
}: IPropsSearch): JSX.Element => (
  <Segment basic style={{ marginTop: margin }} className='search'>
    <SkeletonBlock
      styled={{ height: isMobile ? 132 : 55, maxWidth: '100%' }}
      className='search-block'
    />
    {isStatics && <SkeletonStatic />}
    <Grid style={{ margin: '1rem -1rem' }}>
      <Grid.Row>
        <Grid.Column width={8}>
          <SkeletonBlock styled={{ height: 36 }} />
        </Grid.Column>
        <Grid.Column width={8} only='computer'>
          <SkeletonBlock
            styled={{ marginLeft: 'auto', height: 36, width: 134 }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export const SkeletonStatics = (): JSX.Element => (
  <Grid columns='2' stackable>
    <Grid.Row>
      <Grid.Column width={4} />
      <Grid.Column width={4}>
        <Placeholder>
          <Placeholder.Image style={{ height: '10em' }} />
        </Placeholder>
      </Grid.Column>
      <Grid.Column width={4}>
        <Placeholder>
          <Placeholder.Image style={{ height: '10em' }} />
        </Placeholder>
      </Grid.Column>
      <Grid.Column width={4} />
    </Grid.Row>
  </Grid>
);

export const SkeletonHeaderLayer = ({
  isMobile,
  margin,
  isSearch,
  isStatics
}: any): JSX.Element => {
  if (isSearch) {
    return (
      <SkeletonSearch
        isMobile={isMobile}
        margin={margin}
        isStatics={isStatics}
      />
    );
  }
  return (
    <Grid style={{ margin: '1rem -1rem' }}>
      <Grid.Row>
        <Grid.Column width={8}>
          <SkeletonBlock styled={{ height: 36 }} />
        </Grid.Column>
        <Grid.Column width={8} only='computer'>
          <SkeletonBlock
            styled={{ marginLeft: 'auto', height: 36, width: 134 }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const SkeletonListLicenses = ({
  itemsPerView
}: IPropsList): JSX.Element => (
  <div className='wrapper-skeletons'>
    <Card.Group itemsPerRow={itemsPerView} className='licenses'>
      {new Array(itemsPerView * 4).fill('card-loading').map((name, index) => (
        <Card key={`skeleton-licenses-${index + 1}`} className={name}>
          <Card.Content>
            <Placeholder className='icon'>
              <Placeholder.Image />
            </Placeholder>
            <Placeholder>
              <Placeholder.Line length='full' />
              <Placeholder.Line length='full' />
            </Placeholder>
            <Placeholder className='button'>
              <Placeholder.Image />
            </Placeholder>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  </div>
);

export const SkeletonDetailLicense = (): JSX.Element => (
  <>
    <SkeletonBlock
      styled={{ marginLeft: '75%', maxWidth: '15%', height: 50 }}
    />
    <Grid style={{ marginTop: '2em' }}>
      <Grid.Column width={8}>
        <Placeholder style={{ maxWidth: 450, height: 250 }}>
          <Placeholder.Image />
        </Placeholder>
      </Grid.Column>
      <Grid.Column width={8}>
        <Placeholder style={{ maxWidth: 450, height: 250 }}>
          <Placeholder.Image />
        </Placeholder>
      </Grid.Column>
    </Grid>
    <Divider hidden style={{ margin: '2em auto' }} />
    <SkeletonBlock styled={{ maxWidth: '100%', height: 150 }} />
  </>
);

export const SkeletonGraphsLicense = (): JSX.Element => (
  <Placeholder style={{ height: 200, maxWidth: '100%' }}>
    <Placeholder.Image />
  </Placeholder>
);

export const SkeletonModalGroupUsers = (): JSX.Element => (
  <>
    <Placeholder style={{ maxWidth: '95%', height: 40, margin: 'auto' }}>
      <Placeholder.Image />
    </Placeholder>
    <br />
    <Placeholder style={{ maxWidth: '95%', height: 95, margin: 'auto' }}>
      <Placeholder.Image />
    </Placeholder>
    <br />
    <Placeholder style={{ maxWidth: '95%', height: 95, margin: 'auto' }}>
      <Placeholder.Image />
    </Placeholder>
  </>
);

export const SkeletonModalGroupThings = (): JSX.Element => (
  <>
    <Placeholder style={{ maxWidth: '95%', height: 40, margin: 'auto' }}>
      <Placeholder.Image />
    </Placeholder>
    <br />
    <Placeholder style={{ maxWidth: '95%', height: 95, margin: 'auto' }}>
      <Placeholder.Image />
    </Placeholder>
  </>
);

export const SkeletonModalSettings = (): JSX.Element => (
  <>
    <Placeholder style={{ maxWidth: '95%', height: 45, margin: 'auto' }}>
      <Placeholder.Image />
    </Placeholder>
    <br />
    <Placeholder style={{ maxWidth: '95%', height: 95, margin: 'auto' }}>
      <Placeholder.Image />
    </Placeholder>
    <br />
    <Placeholder style={{ maxWidth: '95%', height: 110, margin: 'auto' }}>
      <Placeholder.Image />
    </Placeholder>
  </>
);

export const SkeletonModalReleaseNotes = (): JSX.Element => (
  <Placeholder fluid>
    <Placeholder.Header image>
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Header>
    <Placeholder.Paragraph>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Paragraph>
  </Placeholder>
);

export const SkeletonThingsCount = (): JSX.Element => (
  <div>
    <SkeletonBlock
      styled={{ maxWidth: '100%', height: 45, marginTop: '0.5rem' }}
    />
    <SkeletonBlock
      styled={{ maxWidth: '100%', height: 45, marginTop: '0.5rem' }}
    />
    <SkeletonBlock
      styled={{ maxWidth: '100%', height: 45, marginTop: '0.5rem' }}
    />
  </div>
);

export const SkeletonHistoryConnections = (): JSX.Element => (
  <>
    <Grid style={{ margin: '1rem -1rem' }}>
      <Grid.Row>
        <Grid.Column width={16}>
          <SkeletonBlock styled={{ height: 24, width: 150 }} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <SkeletonBlock styled={{ height: 24 }} />
        </Grid.Column>
        <Grid.Column width={8} only='computer'>
          <SkeletonBlock
            styled={{ marginLeft: 'auto', height: 24, width: 134 }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <SkeletonBlock
      styled={{ maxWidth: '100%', height: 200, marginTop: '0.5rem' }}
    />
  </>
);

export const SkeletonInfoThing = (): JSX.Element => {
  return (
    <div>
      {new Array(2).fill('_').map((_, index) => (
        <Placeholder key={`placeholder-info-thing-${index.toString()}`}>
          <PlaceholderHeader image>
            <PlaceholderLine />
            <PlaceholderLine />
          </PlaceholderHeader>
        </Placeholder>
      ))}
    </div>
  );
};

SkeletonBlock.defaultProps = {
  styled: { maxWidth: '100%', height: 175 },
  className: ''
};
