import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import { useStatusRequest } from '../../contexts/request';
import { DEFAULT_VALUE_NEXT_REQUEST } from '../../config/constants';
import * as Interfaces from '../../interfaces';

interface IProps extends Interfaces.IAnalytics {
  groupId: Interfaces.IGroupComponentProps['groupId'];
}

interface IUseGroupUsers {
  isLoadingRequest: {
    fetch: boolean;
    delete: boolean;
    added: boolean;
  };
  dataGroups: {
    listInfo: Interfaces.IGroupUserItems[];
    usersPrevSelected: string[];
  };
  nextRequest: Interfaces.INextRequest;
  refetchGroupUsers: () => Promise<void>;
  getMoreGroupUsers: () => Promise<void>;
  updateListUsers: (list: any) => void;
  sendAdded: (body: Interfaces.IBodyGroupUserCreate) => Promise<void>;
  sendDelete: (userId: string) => Promise<void>;
}

interface IState {
  isLoading: IUseGroupUsers['isLoadingRequest'];
  listUsers: IUseGroupUsers['dataGroups']['listInfo'];
  listSelectedUsers: IUseGroupUsers['dataGroups']['usersPrevSelected'];
  nextRequest: Interfaces.INextRequest;
  query: Interfaces.IQuery;
}

const DEFAULT_QUERY: IState['query'] = { limit: 8 };

const LoggerInstance = ReporterLogger.getInstance();

export const useGroupUsers = ({
  groupId,
  analytics
}: IProps): IUseGroupUsers => {
  const { t } = useTranslation();
  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });

  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    fetch: true,
    delete: false,
    added: false
  });
  const [listUsers, setListUsers] = useState<IState['listUsers']>([]);
  const [listSelectedUsers, setListSelectedUsers] = useState<
    IState['listSelectedUsers']
  >([]);
  const [query, setQuery] = useState<IState['query']>(DEFAULT_QUERY);
  const [nextRequest, setNextRequest] = useState<IState['nextRequest']>(
    DEFAULT_VALUE_NEXT_REQUEST
  );

  const fetchUsers = async (isRefetch = false): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }

    try {
      const response = await BackendIoT.getUsersInGroup(groupId, query);
      let newNextRequest = DEFAULT_VALUE_NEXT_REQUEST;
      let newListUsers: Interfaces.IGroupUserItems[] = [];
      let newListSelectedUsers: string[] = [];

      if (response.users?.length) {
        const { users } = response;
        newListUsers = users;
        newListSelectedUsers = users.map(
          (item: Interfaces.IGroupUserItems) => item.user
        );

        if (nextRequest.isLoadMore && !isRefetch) {
          newListUsers = [...listUsers, ...newListUsers];
        }

        if (response?.lastItem) {
          newNextRequest = {
            isLoadMore: !!newListUsers.length,
            lastItem: response.lastItem
          };
        }
      }

      setListSelectedUsers(newListSelectedUsers);
      setListUsers(newListUsers);
      setNextRequest(newNextRequest);
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.group.fetch')
      });
      LoggerInstance.error(
        'Failed to get users in group - useGroupUsers - "fetchUsers"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [query]);

  const refetchGroupUsers = async (): Promise<void> => {
    if (query.lastItem) {
      setQuery(DEFAULT_QUERY);
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
    } else {
      await fetchUsers(true);
    }
  };

  const sendAdded = async (
    body: Interfaces.IBodyGroupUserCreate
  ): Promise<void> => {
    try {
      setIsLoading({ ...isLoading, added: true });
      await BackendIoT.createGroupUser(groupId, body);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.add.user'),
        message: t('success.requests.groups.createUser')
      };
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.add.user'),
        message: t('error.requests.groups.createUser')
      };
      LoggerInstance.error(
        'Failed to add users in group - useGroupUsers - "sendAdded"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, added: false });
    }

    refetchGroupUsers();
  };

  const sendDelete = async (userId: string): Promise<void> => {
    try {
      setIsLoading({ ...isLoading, delete: true });
      await BackendIoT.deleteGroupUser(groupId, userId);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.delete.user'),
        message: t('success.requests.groups.deleteUser')
      };
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.delete.user'),
        message: t('error.requests.groups.deleteUser')
      };
      LoggerInstance.error(
        'Failed to delete users in group - useGroupUsers - "sendDelete"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, delete: false });
    }

    refetchGroupUsers();
  };

  const updateListUsers = (list: any): void => {
    setListUsers(list);
  };

  const getMoreGroupUsers = async (): Promise<void> => {
    const paramsQuery = {
      ...query,
      lastItem: JSON.stringify(nextRequest.lastItem)
    };
    setQuery(paramsQuery);
  };

  return {
    isLoadingRequest: isLoading,
    sendAdded,
    sendDelete,
    updateListUsers,
    refetchGroupUsers,
    getMoreGroupUsers,
    nextRequest,
    dataGroups: {
      listInfo: listUsers,
      usersPrevSelected: listSelectedUsers
    }
  };
};

export default useGroupUsers;
