import React, { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, Icon, Button, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../../controllers/Analytics';
import { useNavState } from '../../../contexts/navigation';
import { SkeletonBlock } from '../../../components/Skeletons';
import Empty from '../../../components/Empty';
import * as Constants from '../../../config/constants';
import * as Interfaces from '../../../interfaces';

import './LicenseStatus.scss';

export interface PropsComponentLicensesStatus {
  isLoading: boolean;
  listLicenses: Interfaces.ILicenseList;
}

const LicensesStatus = ({
  isLoading,
  listLicenses
}: PropsComponentLicensesStatus): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateSubsection } = useNavState();

  const handlerClickRedirect = (e: SyntheticEvent, path: string): void => {
    e.preventDefault();
    updateSubsection(path);
    navigate(path);

    Analytics.sendEventCustom({
      category: 'Dashboard',
      action: 'Redirect',
      label: 'Licenses'
    });
  };

  const statusLicenses = Object.keys(listLicenses);
  const licensesList = Object.values(listLicenses);

  const renderState = (): JSX.Element => {
    const noLicenses =
      !listLicenses.available.value &&
      !listLicenses.assigned.value &&
      !listLicenses.revoked.value &&
      !listLicenses.expired.value;

    if (noLicenses && !isLoading) {
      const { texts, icon } = Constants.PROPS_EMPTY_LAYER_CONTAINER.LICENSES;
      return (
        <Empty
          iconName={icon}
          texts={{
            title: t(texts.title as any),
            subtitle: t(texts.subtitle as any)
          }}
        />
      );
    }

    return (
      <>
        {licensesList.map(({ text, icon, color, path, value }, index) => {
          if (!isLoading) {
            return (
              <Button
                basic
                className={`item-status ${statusLicenses[index]}`}
                key={`licenses-card-main-${index + 1}`}
                onClick={(e) => handlerClickRedirect(e, path)}
              >
                <div className={`wrapper-icon ${color}-card`}>
                  <Icon size='big' name={icon} color={color} />
                </div>
                <span className='section-container-license'>
                  <Header
                    as='h4'
                    className='title'
                    content={t(text as any)}
                    color={color}
                  />
                  <span>
                    <Header
                      as='h3'
                      className='values'
                      content={value < 0 ? '--' : value}
                    />
                  </span>
                </span>
              </Button>
            );
          }
          return (
            <SkeletonBlock
              key={`licenses-card-main-${index + 1}`}
              styled={{ maxWidth: '90%', height: 60, marginTop: '0.5rem' }}
            />
          );
        })}
      </>
    );
  };

  return (
    <Segment
      className={`wrapper-license-status ${isLoading ? 'loadingFetch' : ''}`}
    >
      <Header
        as='h2'
        content={t('sections.licenses')}
        className='title-section'
      />
      {renderState()}
    </Segment>
  );
};

export default LicensesStatus;
