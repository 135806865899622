import React, { SyntheticEvent, useMemo, useCallback } from 'react';
import { Segment, Card, Header, Icon, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../../contexts/auth';
import { useNavState } from '../../contexts/navigation';
import { useLicenses } from '../../hooks/licenses/useLicenses';
import Layout from '../../components/Layout';
import GraphLicenses from '../../components/GraphsUI';
import { SkeletonListLicenses } from '../../components/Skeletons';
import { IAnalyticsViewPage, IPropsRoutes } from '../../interfaces';
import names from '../../config/names';

import '../../scss/layout/Licenses.scss';

const ITEMS_PER_VIEW = 1;

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.licenses.main,
  title: 'Licenses Main'
};

const Licenses = ({ isNavbar }: IPropsRoutes): JSX.Element => {
  const { isLicensee, adminLevel } = useAuthState();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateSubsection, isMobileBreakpoint } = useNavState();
  const {
    isLoading,
    dataLicenses: { listParsed },
    refetchLicenses
  } = useLicenses({ analytics: ANALYTICS_PAGE_VIEW });

  const handlerClickShow = useCallback(
    (e: SyntheticEvent, path: string): void => {
      e.preventDefault();
      updateSubsection(path);
      navigate(path);
    },
    [navigate, updateSubsection]
  );

  const renderState = useMemo((): JSX.Element => {
    if (isLoading) {
      return <SkeletonListLicenses itemsPerView={ITEMS_PER_VIEW} />;
    }

    return (
      <Card.Group itemsPerRow={ITEMS_PER_VIEW}>
        {Object.values(listParsed).map(
          ({ text, icon, color, path, value }, index) => {
            return (
              <Card
                key={`licenses-card-main-${index + 1}`}
                raised
                className={`item-status ${color}-card`}
              >
                <Card.Content>
                  <Icon
                    className='icons'
                    size='huge'
                    name={icon}
                    color={color}
                  />
                  <Header
                    as='h4'
                    className='title'
                    content={t(text as any)}
                    color={color}
                  />
                  <Header
                    as='h2'
                    className='values'
                    content={Number.isNaN(value) || +value <= -1 ? '--' : value}
                  />
                  <Button
                    fluid
                    color={color}
                    content={t('actions.show')}
                    onClick={(e) => handlerClickShow(e, path)}
                  />
                </Card.Content>
              </Card>
            );
          }
        )}
      </Card.Group>
    );
  }, [listParsed, handlerClickShow, isLoading]);

  const renderSegment = (): JSX.Element => (
    <>
      {!isMobileBreakpoint && (
        <Segment basic className='licenses-graphic-button'>
          <GraphLicenses
            loading={isLoading}
            available={{
              count: listParsed.available.value as number,
              color: listParsed.available.color ?? 'black'
            }}
            assigned={{
              count: listParsed.assigned.value as number,
              color: listParsed.assigned.color ?? 'black'
            }}
            revoked={{
              count: listParsed.revoked.value as number,
              color: listParsed.revoked.color ?? 'black'
            }}
            expired={{
              count: listParsed.expired.value as number,
              color: listParsed.expired.color ?? 'black'
            }}
          />
        </Segment>
      )}
      <div className='container-licenses-list'>
        {!isLicensee && (
          <Button
            onClick={refetchLicenses}
            disabled={isLoading}
            content={t('actions.update')}
            className='refetch'
          />
        )}
        {renderState}
      </div>
    </>
  );

  return (
    <Layout title={t('sections.licenses')} showNavbar={isNavbar}>
      <Segment
        basic
        textAlign='center'
        className={`wrapper-licenses ${adminLevel}-view`}
      >
        {renderSegment()}
      </Segment>
    </Layout>
  );
};

export default Licenses;
