import {
  DEFAULT_VALUES_MODAL_CUSTOM_ALERTS,
  DEFAULT_VALUES_MODAL_CUSTOM_SOUND,
  DEFAULT_VALUES_MODAL_DATE
} from '../../config/constants';
import * as Interfaces from '../../interfaces';
import * as Types from '../../types';

export const initialState: Interfaces.IModalControlInitialState = {
  view: Types.EViewsControl.initial,
  scheduled: 'now',
  date: DEFAULT_VALUES_MODAL_DATE,
  contentCustomAlert: DEFAULT_VALUES_MODAL_CUSTOM_ALERTS,
  contentCustomAudio: DEFAULT_VALUES_MODAL_CUSTOM_SOUND,
  isInvalidTime: false
};

export const reducer = (
  commands: Interfaces.IModalControlInitialState,
  action: Types.TActionsTypeReducerControl
): Interfaces.IModalControlInitialState => {
  switch (action.type) {
    case Types.EActionsReducerControl.setView:
      return {
        ...commands,
        view: action.view
      };
    case Types.EActionsReducerControl.setTypeSchedule:
      return {
        ...commands,
        scheduled: action.scheduled
      };
    case Types.EActionsReducerControl.setDateCalendar:
      return {
        ...commands,
        date: action.date
      };
    case Types.EActionsReducerControl.setContentCustomAlert:
      return {
        ...commands,
        contentCustomAlert: action.contentCustomAlert
      };
    case Types.EActionsReducerControl.setContentCustomAudio:
      return {
        ...commands,
        contentCustomAudio: action.contentCustomAudio
      };
    case Types.EActionsReducerControl.setIsInvalidTime:
      return {
        ...commands,
        isInvalidTime: action.isInvalidTime
      };
    case Types.EActionsReducerControl.clear:
      return initialState;
    default:
      return commands;
  }
};
