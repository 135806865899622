import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import { useNavState } from '../../contexts/navigation';
import { useStatusRequest } from '../../contexts/request';
import * as Constants from '../../config/constants';
import * as Interfaces from '../../interfaces';

interface IUseLicenses {
  isLoading: boolean;
  dataLicenses: {
    listParsed: Interfaces.ILicenseList;
    totalLicenses: number;
  };
  refetchLicenses: () => Promise<void>;
}

interface IState {
  isLoading: IUseLicenses['isLoading'];
  parsedListLicenses: IUseLicenses['dataLicenses']['listParsed'];
  totalLicenses: IUseLicenses['dataLicenses']['totalLicenses'];
}

const { LICENSES_TYPES_LIST } = Constants;

const LoggerInstance = ReporterLogger.getInstance();

export const useLicenses = ({
  analytics
}: Interfaces.IAnalytics = {}): IUseLicenses => {
  const { t } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();
  const { updateSubsection, subsection } = useNavState();

  const [isLoading, setIsLoading] = useState<IUseLicenses['isLoading']>(true);
  const [parsedListLicenses, setParsedListLicenses] =
    useState<IState['parsedListLicenses']>(LICENSES_TYPES_LIST);
  const [totalLicenses, setTotalLicenses] =
    useState<IState['totalLicenses']>(0);

  // TODO: FIX TYPE
  const parsedList = (licensesTypes: any): void => {
    if (licensesTypes) {
      let counter = 0;
      const updateValues = parsedListLicenses;
      Object.keys(licensesTypes).forEach((key) => {
        if (updateValues[key]) {
          counter += licensesTypes[key];
          updateValues[key].value = licensesTypes[key];
        }
      });
      setTotalLicenses(counter);
      setParsedListLicenses(updateValues);
    }
  };

  const fetchLicensesCount = async (): Promise<void> => {
    if (!isLoading) {
      setIsLoading(true);
    }
    try {
      const response = await BackendIoT.getCustomer();
      if (response?.licenseCount) {
        const licensesTypes = response.licenseCount;
        parsedList(licensesTypes);
      }
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.licenses.fetch')
      });
      LoggerInstance.error(
        'Failed to get license count - useLicenses - "fetchLicensesCount"',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (subsection) {
      updateSubsection('');
    }
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
    fetchLicensesCount();
  }, []);

  const refetchLicenses = async (): Promise<void> => {
    await fetchLicensesCount();
  };

  return {
    isLoading,
    refetchLicenses,
    dataLicenses: {
      listParsed: parsedListLicenses,
      totalLicenses
    }
  };
};

export default useLicenses;
