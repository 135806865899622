import React, { memo } from 'react';
import {
  Container,
  Header,
  Icon,
  Segment,
  SemanticCOLORS
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Empty from '../Empty';
import { SkeletonGraphsLicense } from '../Skeletons';

import './GraphsUI.scss';

interface IValues {
  count: number;
  color: SemanticCOLORS;
}

export interface PropsComponentGraphsUI {
  loading: boolean;
  assigned: IValues;
  available: IValues;
  revoked: IValues;
  expired: IValues;
}

const LICENSES_STATUS = ['available', 'assigned', 'revoked', 'expired'];

const Licenses = memo(
  ({
    loading,
    assigned,
    available,
    revoked,
    expired
  }: PropsComponentGraphsUI): JSX.Element => {
    const { t } = useTranslation();
    const licensesStatusValues = [
      available,
      assigned,
      revoked,
      expired
    ] as IValues[];

    const totalLicenses = licensesStatusValues.reduce(
      (previousValue, currentValue) =>
        previousValue + (currentValue.count >= 0 ? currentValue.count : 0),
      0
    );
    const percentage = (value: IValues['count']): number => {
      const result = (value * 100) / totalLicenses;
      return parseFloat(result.toFixed(1));
    };

    const renderNoLicenses = (): JSX.Element => (
      <Segment basic className='wrapper-graph-licenses no-licenses'>
        <Empty
          iconName='key'
          texts={{
            title: t('messages.licenses.title'),
            subtitle: t('messages.licenses.subtitle')
          }}
        />
      </Segment>
    );

    const renderGraph = (): JSX.Element => (
      <div className='graphic'>
        {licensesStatusValues.map(
          ({ count, color }, index) =>
            count > 0 && (
              <div
                key={`bars-${index + 1}`}
                className={`${LICENSES_STATUS[index]} ${color}`}
                style={{ width: `${percentage(count)}%` }}
              >
                <Container />
              </div>
            )
        )}
      </div>
    );

    const renderSubHeaders = (): JSX.Element => (
      <Segment basic className='subheaders'>
        {licensesStatusValues.map(({ count, color }, index) => (
          <p key={`text-${index + 1}`}>
            <Icon color={color} name='square' size='small' />
            {t(`graph.${LICENSES_STATUS[index]}` as any, {
              percent:
                count <= -1
                  ? '--'
                  : `${percentage(licensesStatusValues[index].count)}%`
            })}
          </p>
        ))}
      </Segment>
    );

    if (loading) {
      return <SkeletonGraphsLicense />;
    }

    return !totalLicenses ? (
      renderNoLicenses()
    ) : (
      <Segment className='wrapper-graph-licenses'>
        <Header as='h2' className='header-segment'>
          {t('graph.title', { count: totalLicenses })}
        </Header>
        {renderGraph()}
        {renderSubHeaders()}
      </Segment>
    );
  }
);

export default Licenses;
