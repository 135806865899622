import i18next, { i18n } from 'i18next';
import moment from 'moment';
import { formatDate } from '../helpers';
import * as Constants from '../config/constants';
import * as Interfaces from '../interfaces';
import * as Types from '../types';

const { COMMANDS_EVENTS } = Constants;

class CommandSchedule {
  private id: string;

  private action: Types.TCommands;

  private sendingTime: string;

  private successfullySent: boolean;

  private cancelled: boolean;

  private data: Types.TDataCommandBody;

  private translation: i18n['t'];

  constructor() {
    this.translation = i18next.t;
    this.id = '';
    this.action = 'silent-notification';
    this.sendingTime = '';
    this.successfullySent = false;
    this.cancelled = false;
    this.data = null;
  }

  init(command: Interfaces.IScheduledCommandsItems): this {
    this.id = command.id;
    this.action = command.action;
    this.cancelled = command.cancelled;
    this.sendingTime = command.sendingTime;
    this.data = command.data || null;
    this.successfullySent = command.successfullySent;
    return this;
  }

  parseToDataDisplay(): Interfaces.IParseCommandSchedule {
    return {
      id: this.id,
      actionName: this.commandNameTranslate(this.action, this.data || null),
      status: this.commandStatus(this.sendingTime, this.cancelled),
      sendingTimeFormat: formatDate(this.sendingTime, 'll LT'),
      sendingTime: this.sendingTime,
      disabledCancelled:
        this.successfullySent ||
        this.cancelled ||
        moment().isAfter(this.sendingTime)
    };
  }

  static orderElementsBySendingTime(
    list: Interfaces.IParseCommandSchedule[]
  ): Interfaces.IParseCommandSchedule[] {
    return list.sort((a, b) => {
      const dateA = moment(a.sendingTime);
      const dateB = moment(b.sendingTime);

      if (dateB.isBefore(dateA)) {
        return -1;
      }

      return dateB.isAfter(dateA) ? 1 : 0;
    });
  }

  static commandDisableForCancel(
    canceled: boolean,
    sendingTime: string
  ): boolean {
    const currentDate = moment();
    const sendingDate = moment(sendingTime).add('6', 'hour');
    return canceled || currentDate.isSameOrAfter(sendingDate);
  }

  commandNameTranslate(
    command: Types.TCommands,
    data: Types.TDataCommandBody
  ): string {
    const isNotSoundData = command === 'test-alert' && !data;
    const nameDataCheck = isNotSoundData ? 'silent-notification' : command;
    const commandName = COMMANDS_EVENTS[nameDataCheck] || '';
    if (commandName) {
      return this.translation(`${commandName.name}` as any);
    }

    return this.translation('error.notAvailable');
  }

  commandStatus(sendingTime: string, cancelled: boolean): string {
    if (this.successfullySent) {
      return this.translation('control.schedule.table.status.sent');
    }

    if (cancelled) {
      return this.translation('control.schedule.table.status.canceled');
    }

    const currentDate = moment();
    const sendingDate = moment(sendingTime).add('6', 'hour');

    if (currentDate.isBefore(sendingDate)) {
      return this.translation('control.schedule.table.status.pending');
    }

    if (currentDate.isAfter(sendingDate)) {
      return this.translation('control.schedule.table.status.sent');
    }

    return this.translation('error.notAvailable');
  }
}

export default CommandSchedule;
