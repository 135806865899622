import React, { useEffect, useReducer, memo } from 'react';
import moment, { Moment } from 'moment';
import sortBy from 'lodash/sortBy';
import Markdown from 'markdown-to-jsx';
import {
  Divider,
  Table,
  Icon,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Header,
  ListItem,
  List,
  ButtonGroup,
  Button,
  Grid
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../contexts/settings';
import Empty from '../../Empty';
import { SkeletonHistoryConnections } from '../../Skeletons';
import * as Interfaces from '../../../interfaces';
import * as Types from '../../../types';

export interface PropsHistoryConnections {
  thingId: Interfaces.IThingIoT['id'];
  isError: boolean;
  isLoading: boolean;
  selectorDaysHistory: Types.TSelectorDaysHistoryConnection;
  connections: Interfaces.IRequestThingConnections['connectionsHistory'];
  rangeDate: Interfaces.IRangeDateConnectionsHistory;
  onClickSelectorDay: (
    value: Types.TSelectorDaysHistoryConnection,
    thingId: Interfaces.IThingIoT['id']
  ) => void;
}

interface StateReducerHistoryConnections {
  column: keyof Interfaces.IConnectionStatusSwitching | null;
  data: Interfaces.IRequestThingConnections['connectionsHistory'];
  direction?: 'ascending' | 'descending';
}

type TActionStateChangeSort = {
  type: EActionsReducerHistoryConnections.changeSort;
  column: StateReducerHistoryConnections['column'];
};

type TActionStateUpdateData = {
  type: EActionsReducerHistoryConnections.updateData;
  data: StateReducerHistoryConnections['data'];
};

enum EActionsReducerHistoryConnections {
  changeSort = 'CHANGE_SORT',
  updateData = 'UPDATE_DATA'
}

type THistoryConnectionsActions =
  | TActionStateChangeSort
  | TActionStateUpdateData;

interface ButtonsSelectorDaysHistory {
  text: string;
  value: Types.TSelectorDaysHistoryConnection;
}

const BUTTONS_SELECTOR_DAYS_HISTORY: ButtonsSelectorDaysHistory[] = [
  {
    text: 'monitor.detail.selectorDays.now',
    value: Types.ESelectorDaysHistoryConnection.now
  },
  {
    text: 'monitor.detail.selectorDays.threeDays',
    value: Types.ESelectorDaysHistoryConnection.threeDays
  },
  {
    text: 'monitor.detail.selectorDays.sevenDays',
    value: Types.ESelectorDaysHistoryConnection.sevenDays
  }
];

const HistoryConnections = ({
  thingId,
  isLoading,
  isError,
  connections,
  rangeDate,
  selectorDaysHistory,
  onClickSelectorDay
}: PropsHistoryConnections): JSX.Element => {
  const { t } = useTranslation();
  const { theme } = useSettings();

  const historyConnectionsReducer = (
    state: StateReducerHistoryConnections,
    action: THistoryConnectionsActions
  ): StateReducerHistoryConnections => {
    switch (action.type) {
      case EActionsReducerHistoryConnections.changeSort:
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending'
          };
        }

        return {
          column: action.column,
          data: sortBy(state.data, [action.column || '']),
          direction: 'ascending'
        };
      case EActionsReducerHistoryConnections.updateData:
        return {
          ...state,
          data: action.data
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(historyConnectionsReducer, {
    column: null,
    data: connections,
    direction: undefined
  });

  useEffect(() => {
    if (!isLoading && connections.length) {
      dispatch({
        type: EActionsReducerHistoryConnections.updateData,
        data: connections
      });
    }
  }, [isLoading, connections]);

  const convertInLocalUTCFormat = (date: string, format = 'LLLL'): string => {
    return moment.utc(date).local().format(format);
  };

  const convertInLocalUTCDate = (date: string): Moment => {
    return moment.utc(date).local();
  };

  const handleOnClickSortConnectionStatus = (): void => {
    dispatch({
      type: EActionsReducerHistoryConnections.changeSort,
      column: 'connectionStatus'
    });
  };

  const handleOnClickSortTime = (): void => {
    dispatch({
      type: EActionsReducerHistoryConnections.changeSort,
      column: 'time'
    });
  };

  const handleOnClickSortIndex = (): void => {
    dispatch({
      type: EActionsReducerHistoryConnections.changeSort,
      column: 'index'
    });
  };

  const { column, data, direction } = state;

  if (isLoading) {
    return <SkeletonHistoryConnections />;
  }

  if (isError) {
    return (
      <Empty
        iconName='frown'
        texts={{
          title: t('monitor.detail.error')
        }}
      />
    );
  }

  if (!data.length) {
    return (
      <Empty
        iconName='history'
        texts={{
          title: t('monitor.detail.noConnections.title'),
          subtitle: t('monitor.detail.noConnections.subtitle')
        }}
      />
    );
  }

  const isNow =
    selectorDaysHistory === Types.ESelectorDaysHistoryConnection.now;
  const toDate = isNow
    ? convertInLocalUTCDate(rangeDate.to).subtract(1, 'second').format('LLLL')
    : convertInLocalUTCFormat(rangeDate.to);
  const fromDate = isNow
    ? convertInLocalUTCDate(rangeDate.from).add(5, 'minutes').format('LLLL')
    : convertInLocalUTCFormat(rangeDate.from);
  return (
    <>
      <div className='header-records-date'>
        <Header
          content={t('monitor.header.records.total', {
            count: connections.length
          })}
        />
        <Divider hidden />
      </div>
      <div className='header-records-date'>
        <Grid relaxed columns={2}>
          <Grid.Row>
            <Grid.Column>
              <List relaxed>
                <ListItem
                  icon='calendar'
                  content={
                    <Markdown>
                      {t('monitor.detail.fromDate', {
                        from: fromDate
                      })}
                    </Markdown>
                  }
                />
                <ListItem
                  icon='calendar'
                  content={
                    <Markdown>
                      {t('monitor.detail.toDate', {
                        to: toDate
                      })}
                    </Markdown>
                  }
                />
              </List>
            </Grid.Column>
            <Grid.Column verticalAlign='bottom' textAlign='right'>
              <ButtonGroup size='tiny' floated='right'>
                {BUTTONS_SELECTOR_DAYS_HISTORY.map(({ text, value }) => (
                  <Button
                    key={value}
                    content={t(text as any)}
                    color={selectorDaysHistory === value ? 'orange' : undefined}
                    onClick={() => onClickSelectorDay(value, thingId)}
                  />
                ))}
              </ButtonGroup>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <Table striped sortable inverted={theme === 'dark'}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell
              sorted={column === 'index' ? direction : undefined}
              onClick={handleOnClickSortIndex}
            >
              #
            </TableHeaderCell>
            <TableHeaderCell
              sorted={column === 'connectionStatus' ? direction : undefined}
              onClick={handleOnClickSortConnectionStatus}
            >
              {t('tableList.header.eventConnection')}
            </TableHeaderCell>
            <TableHeaderCell
              sorted={column === 'time' ? direction : undefined}
              onClick={handleOnClickSortTime}
            >
              {t('tableList.header.date')}
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map(({ connectionStatus, time, index }) => {
            const isLastRecord = data.length === index;
            const colorConnection =
              connectionStatus === 'connected' ? 'green' : 'red';
            const textConnection = isLastRecord
              ? t('monitor.detail.startHistory', {
                  connectionStatus: t(
                    `connectionStatus.${connectionStatus}` as any
                  )
                })
              : t(`connectionStatus.${connectionStatus}` as any);
            return (
              <TableRow
                key={`connection-history-thing-${index}`}
                warning={isLastRecord}
              >
                <TableCell width={1}>{index}</TableCell>
                <TableCell width={6}>
                  <Icon
                    color={isLastRecord ? 'orange' : colorConnection}
                    name={isLastRecord ? 'attention' : 'circle'}
                  />
                  <Markdown>{textConnection}</Markdown>
                </TableCell>
                <TableCell width={8}>{convertInLocalUTCFormat(time)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

const MemoizedHistoryConnections = memo(HistoryConnections);

export default MemoizedHistoryConnections;
