import React, { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import Analytics from '../../../../controllers/Analytics';
import { useNavState } from '../../../../contexts/navigation';
import { useSubgroups } from '../../../../hooks/groups/useGroupsSubgroups';
import ButtonFloat from '../../../../components/ButtonFloat';
import ModalCreateSubgroup from '../../../../components/Modals/Create/Group';
import Layer from '../../../../components/Layer';
import {
  IGroupComponentProps,
  IAnalyticsViewPage
} from '../../../../interfaces';
import names from '../../../../config/names';

import '../../../../scss/layout/GroupsDetail.scss';

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.groups.main,
  title: 'Groups Detail Subgroups'
};

const Subgroups = ({ groupId }: IGroupComponentProps): JSX.Element => {
  const { groupPermission } = useNavState();
  const {
    isLoadingRequest,
    dataGroups: { listInfoSubgroup },
    sendCreate,
    refetchSubgroups
  } = useSubgroups({ groupId, analytics: ANALYTICS_PAGE_VIEW });

  const [isOpenModalSubgroups, setIsOpenModalSubgroups] =
    useState<boolean>(false);

  const handlerToggleModal = (): void => {
    setIsOpenModalSubgroups(!isOpenModalSubgroups);
  };

  const handlerModalAccept = async (nameSubgroup: string): Promise<void> => {
    sendCreate(nameSubgroup);
    handlerToggleModal();

    Analytics.sendEventCustom({
      category: 'Groups Detail',
      action: 'Create',
      label: 'Subgroups in Group'
    });
  };

  return (
    <Segment basic className='wrapper-users-group'>
      <Layer
        section='SUBGROUPS'
        data={Object.values(listInfoSubgroup)}
        loading={isLoadingRequest.fetch}
        onRefresh={refetchSubgroups}
      />
      {groupPermission === 'admin' && (
        <ButtonFloat
          isHidden={isLoadingRequest.fetch}
          onClick={handlerToggleModal}
        />
      )}
      <ModalCreateSubgroup
        isSubgroups
        isOpen={isOpenModalSubgroups}
        isLoading={isLoadingRequest.create}
        onAccept={handlerModalAccept}
        onCancel={handlerToggleModal}
      />
    </Segment>
  );
};

export default Subgroups;
