import React, { useState, SyntheticEvent } from 'react';
import { Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useUsers } from '../../hooks/useUsers';
import Analytics from '../../controllers/Analytics';
import Layout from '../../components/Layout';
import ModalUserActions from '../../components/Modals/Actions/User';
import ModalConfirm from '../../components/Modals/Delete/Confirm';
import Layer from '../../components/Layer';
import {
  IPropsCtxRoutes,
  IModalUserData,
  IParamsActionsUseUser,
  IAnalyticsViewPage
} from '../../interfaces';
import { TActionsModal } from '../../types';
import names from '../../config/names';

import '../../scss/layout/Users.scss';

interface ISelectUser {
  id: string;
  name: string;
  email?: string;
}

const DEFAULT_SELECT_USER = {
  id: '',
  name: ''
};

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.users,
  title: 'Users'
};

const Users = ({ isNavbar }: IPropsCtxRoutes): JSX.Element => {
  const { t } = useTranslation();
  const {
    sendEdit,
    sendCreate,
    sendDelete,
    refetchUsers,
    isLoadingRequest,
    getMoreUsers,
    nextRequest: { isLoadMore },
    dataUsers: { listFiltered }
  } = useUsers({ analytics: ANALYTICS_PAGE_VIEW });

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [action, setAction] = useState<TActionsModal>('create');
  const [selectUser, setSelectUser] =
    useState<ISelectUser>(DEFAULT_SELECT_USER);

  const toggleModalActions = (newAction: TActionsModal): void => {
    setAction(newAction);
    setIsOpenModal(!isOpenModal);
  };

  const toggleModalDelete = (): void => {
    setIsOpenModalConfirm(!isOpenModalConfirm);
  };

  const clearSelectUser = (): void => {
    setSelectUser(DEFAULT_SELECT_USER);
  };

  const handlerModalActions = async (data: IModalUserData): Promise<void> => {
    if (action === 'create' && data?.adminLevel) {
      const paramsCreate = data as IParamsActionsUseUser['create'];
      await sendCreate(paramsCreate);
    } else {
      const paramsEdit = data as IParamsActionsUseUser['edit'];
      await sendEdit(paramsEdit);
    }
    toggleModalActions(action);
    setIsOpenModal(false);

    Analytics.sendEventCustom({
      category: 'Users',
      action
    });
  };

  const handlerConfirmDelete = async (): Promise<void> => {
    await sendDelete(selectUser);
    toggleModalDelete();
    clearSelectUser();

    Analytics.sendEventCustom({
      category: 'Users',
      action: 'Delete'
    });
  };

  const handlerCancelDelete = (): void => {
    toggleModalDelete();
    clearSelectUser();
  };

  const handlerParam = (
    e: SyntheticEvent<HTMLElement>,
    params: ISelectUser
  ): void => {
    e.preventDefault();
    setSelectUser(params);
  };

  return (
    <Layout title={t('sections.users')} showNavbar={isNavbar}>
      <Segment basic className='wrapper-users' data-testid='users-routes'>
        <Layer
          section='USERS'
          fieldToSearch='name'
          data={listFiltered}
          loadMore={isLoadMore}
          onCreate={() => toggleModalActions('create')}
          onEdit={() => toggleModalActions('edit')}
          onDelete={toggleModalDelete}
          onRefresh={refetchUsers}
          refetchLoadMore={getMoreUsers}
          setModalParam={handlerParam}
          loading={isLoadingRequest.fetch}
        />
        <ModalUserActions
          action={action}
          editValues={selectUser}
          isOpen={isOpenModal}
          isLoading={isLoadingRequest[action]}
          onCancel={() => toggleModalActions(action)}
          onAccept={handlerModalActions}
        />
        <ModalConfirm
          header={{ title: t('modals.titles.warning') }}
          isOpen={isOpenModalConfirm}
          isLoading={isLoadingRequest.delete}
          onCancel={handlerCancelDelete}
          onAccept={handlerConfirmDelete}
        >
          {t('modals.paragraphs.confirm.deleteUser', {
            element: selectUser.name
          })}
        </ModalConfirm>
      </Segment>
    </Layout>
  );
};

export default Users;
