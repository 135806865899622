import { IStateCtxAuth } from '../../interfaces';
import {
  EActionsLoginReducer,
  TAuthActions,
  EActionsLoginReducerTypeLogin,
  EActionsLoginReducerTypeError,
  EActionsReducer,
  EActionsReducerTypeGroup,
  EActionsReducerTypeLicensee,
  EActionsLoginReducerTypeNewUser
} from '../../types';
import names from '../../config/names';

const userInfo = localStorage.getItem(names.storageKeys.userInfo);

export const initialState: IStateCtxAuth = {
  id: userInfo ? JSON.parse(userInfo).id : null,
  customer: userInfo ? JSON.parse(userInfo).customer : null,
  name: userInfo ? JSON.parse(userInfo).name : null,
  email: userInfo ? JSON.parse(userInfo).email : null,
  adminLevel: userInfo ? JSON.parse(userInfo).adminLevel : 'SkyAlertLicensee',
  groups: userInfo ? JSON.parse(userInfo).groups : [],
  licenseeId: userInfo ? JSON.parse(userInfo).licensee : null,
  customerLicensee: userInfo ? JSON.parse(userInfo).customerLicensee : null,
  isLicensee: false,
  isNewUser: false,
  loading: false,
  errorMessage: null
};

export const AuthReducer = (state: IStateCtxAuth, action: TAuthActions) => {
  const { type } = action;
  switch (type) {
    case EActionsLoginReducer.request:
      return {
        ...state,
        loading: true
      };
    case EActionsLoginReducer.login: {
      const {
        payload: { id, customer, name, email, adminLevel, groups, licensee }
      } = action as EActionsLoginReducerTypeLogin;
      return {
        ...state,
        id,
        customer,
        name,
        email,
        adminLevel,
        groups,
        licenseeId: licensee,
        loading: false
      };
    }
    case EActionsLoginReducer.logout:
      return {
        ...state,
        customer: null,
        name: null,
        email: null,
        adminLevel: null,
        customerLicensee: null,
        groups: []
      };

    case EActionsLoginReducer.error: {
      const { error } = action as EActionsLoginReducerTypeError;
      return {
        ...state,
        loading: false,
        errorMessage: error
      };
    }

    // TODO: REMOVE WHEN YOU HAVE THE INFORMATION IF YOU HAVE ALREADY SEEN THE TUTORIAL
    case EActionsLoginReducer.newUser: {
      const { isNewUser } = action as EActionsLoginReducerTypeNewUser;
      return {
        ...state,
        isNewUser,
        loading: false
      };
    }

    case EActionsReducer.group: {
      const { payload: groups } = action as EActionsReducerTypeGroup;
      return {
        ...state,
        groups
      };
    }

    case EActionsReducer.licensee: {
      const { payload: customerLicensee } =
        action as EActionsReducerTypeLicensee;
      return {
        ...state,
        customerLicensee
      };
    }

    default:
      throw new Error(`Unhandled action type`);
  }
};
