import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStatusRequest } from '../contexts/request';
import Analytics from '../controllers/Analytics';
import BackendIoT from '../controllers/Backend/IoT';
import ReporterLogger from '../controllers/ReporterLogger';
import ModelUser from '../models/Users';
import { DEFAULT_VALUE_NEXT_REQUEST } from '../config/constants';
import * as Interfaces from '../interfaces';

interface IParseUser {
  id: string;
  name: string;
  meta: string;
  email: string;
  permissions: string;
  color: string;
}

interface IUseUsers {
  isLoadingRequest: {
    fetch: boolean;
    create: boolean;
    delete: boolean;
    edit: boolean;
  };
  dataUsers: {
    listInfo: Interfaces.IUsersItems[];
    listParsed: IParseUser[];
    listFiltered: IParseUser[];
  };
  refetchUsers: () => Promise<void>;
  getMoreUsers: () => Promise<void>;
  nextRequest: Interfaces.INextRequest;
  sendDelete: (
    values: Interfaces.IParamsActionsUseUser['delete']
  ) => Promise<void>;
  sendCreate: (
    values: Interfaces.IParamsActionsUseUser['create']
  ) => Promise<void>;
  sendEdit: (values: Interfaces.IParamsActionsUseUser['edit']) => Promise<void>;
}

interface IState {
  isLoading: IUseUsers['isLoadingRequest'];
  listUsers: IUseUsers['dataUsers']['listInfo'];
  parsedListUsers: IUseUsers['dataUsers']['listParsed'];
  listSearchFilterRef: IUseUsers['dataUsers']['listFiltered'];
  nextRequest: Interfaces.INextRequest;
  query: Interfaces.IQuery;
}

const DEFAULT_QUERY: IState['query'] = { limit: 8 };

const LoggerInstance = ReporterLogger.getInstance();

export const useUsers = ({
  analytics
}: Interfaces.IAnalytics = {}): IUseUsers => {
  const { t } = useTranslation();

  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });
  const listSearchFilterRef = useRef<IState['listSearchFilterRef']>([]);

  const { UpdateMessageResponse } = useStatusRequest();

  const [listUsers, setListUsers] = useState<IState['listUsers']>([]);
  const [query, setQuery] = useState<IState['query']>(DEFAULT_QUERY);
  const [nextRequest, setNextRequest] = useState<IState['nextRequest']>(
    DEFAULT_VALUE_NEXT_REQUEST
  );
  const [parsedListUsers, setParsedListUsers] = useState<
    IState['parsedListUsers']
  >([]);
  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    fetch: true,
    delete: false,
    create: false,
    edit: false
  });

  const fetchUsers = async (isRefetch = false): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }

    try {
      const response = await BackendIoT.getUsers(query);
      let newListUsers: Interfaces.IUsersItems[] = [];
      let newNextRequest = DEFAULT_VALUE_NEXT_REQUEST;
      if (response.users?.length) {
        newListUsers = response.users;

        if (nextRequest.isLoadMore && !isRefetch) {
          newListUsers = [...listUsers, ...newListUsers];
        }

        if (response?.lastItem) {
          newNextRequest = {
            isLoadMore: !!newListUsers.length,
            lastItem: response.lastItem
          };
        }
      }
      setNextRequest(newNextRequest);
      setListUsers(newListUsers);
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        message: t('error.requests.users.fetch'),
        delay: 5000
      };
      LoggerInstance.error('Failed get users - useUsers - "fetchUsers"', error);
      UpdateMessageResponse(messageResponseRef.current);
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [query]);

  useEffect(() => {
    if (listUsers.length) {
      const newParseList = Object.values(listUsers).map((user) =>
        new ModelUser().init(user).parseUsers()
      );
      listSearchFilterRef.current = newParseList;
      setParsedListUsers(newParseList);
    }
  }, [listUsers]);

  const refetchUsers = async (): Promise<void> => {
    if (query.lastItem) {
      setListUsers([]);
      setQuery(DEFAULT_QUERY);
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
    } else {
      await fetchUsers(true);
    }
  };

  const sendCreate = async (
    data: Interfaces.IParamsActionsUseUser['create']
  ): Promise<void> => {
    try {
      setIsLoading({ ...isLoading, create: true });
      await BackendIoT.createUser(data);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.create.user'),
        message: t('success.requests.user.create')
      };
    } catch (error: any) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.create.user'),
        message: t('error.requests.users.create', {
          code: error?.status ?? ''
        }),
        errorInfo: t('requests.statusInfo.500'),
        delay: 5000
      };
      LoggerInstance.error(
        'Failed create users - useUsers - "sendCreate"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, create: false });
    }

    refetchUsers();
  };

  const sendDelete = async ({
    id,
    name
  }: Interfaces.IParamsActionsUseUser['delete']): Promise<void> => {
    try {
      setIsLoading({ ...isLoading, delete: true });
      await BackendIoT.deleteUser(id);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.delete.user'),
        message: t('success.requests.user.delete', { name })
      };
    } catch (error: any) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.delete.user'),
        message: t('error.requests.users.delete', {
          code: error?.status ?? ''
        })
      };
      LoggerInstance.error(
        'Failed delete users - useUsers - "sendDelete"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, delete: false });
    }

    refetchUsers();
  };

  const sendEdit = async (
    data: Interfaces.IParamsActionsUseUser['edit']
  ): Promise<void> => {
    try {
      setIsLoading({ ...isLoading, edit: true });
      await BackendIoT.updateUser(data);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.edit.users'),
        message: t('success.requests.user.edit', { name: data.name })
      };
    } catch (error: any) {
      messageResponseRef.current = {
        type: 'error',
        action: t('actions.requests.edit.users'),
        message: t('error.requests.users.edit', {
          code: error?.status ?? ''
        }),
        delay: 5000
      };
      LoggerInstance.error('Failed edit users - useUsers - "sendEdit"', error);
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, edit: false });
    }

    refetchUsers();
  };

  const getMoreUsers = async (): Promise<void> => {
    const paramsQuery = {
      ...query,
      lastItem: JSON.stringify(nextRequest.lastItem)
    };
    setQuery(paramsQuery);
  };

  return {
    sendEdit,
    sendCreate,
    sendDelete,
    nextRequest,
    getMoreUsers,
    refetchUsers,
    isLoadingRequest: isLoading,
    dataUsers: {
      listInfo: listUsers,
      listParsed: parsedListUsers,
      listFiltered: listSearchFilterRef.current
    }
  };
};

export default useUsers;
