import React, { useState, SyntheticEvent } from 'react';
import { Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../../../controllers/Analytics';
import { useNavState } from '../../../../contexts/navigation';
import { useGroupUsers } from '../../../../hooks/groups/useGroupsUsers';
import Layer from '../../../../components/Layer';
import ButtonFloat from '../../../../components/ButtonFloat';
import ModalCreateUserGroup from '../../../../components/Modals/Create/Group/GUsers';
import ModalConfirm from '../../../../components/Modals/Delete/Confirm';
import {
  IBodyGroupUserCreate,
  IGroupComponentProps,
  IAnalyticsViewPage
} from '../../../../interfaces';
import names from '../../../../config/names';

import '../../../../scss/layout/GroupsDetail.scss';

const DEFAULT_SELECT_TO_DELETE = {
  id: '',
  name: ''
};
interface IModalConfirm {
  id: string;
  name: string;
}

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.groups.match,
  title: 'Group Detail Users'
};

const Users = ({ groupId }: IGroupComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const { groupPermission } = useNavState();

  const {
    isLoadingRequest,
    dataGroups: { listInfo },
    sendAdded,
    sendDelete,
    nextRequest: { isLoadMore },
    getMoreGroupUsers,
    refetchGroupUsers
  } = useGroupUsers({ groupId, analytics: ANALYTICS_PAGE_VIEW });

  const [isOpenModalUsers, setIsOpenModalUsers] = useState<boolean>(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<IModalConfirm>(
    DEFAULT_SELECT_TO_DELETE
  );

  const clearSelectUser = (): void => {
    setUserToDelete(DEFAULT_SELECT_TO_DELETE);
  };

  const handlerToggleModal = (): void => {
    setIsOpenModalUsers(!isOpenModalUsers);
  };

  const handlerToggleModalConfirm = (): void => {
    setIsOpenModalConfirm(!isOpenModalConfirm);
  };

  const handlerModalAccept = async (
    body: IBodyGroupUserCreate
  ): Promise<void> => {
    await sendAdded(body);
    handlerToggleModal();

    Analytics.sendEventCustom({
      category: 'Group Detail',
      action: 'Add',
      label: 'User in Group'
    });
  };

  const handlerModalAcceptConfirm = async (): Promise<void> => {
    const { id } = userToDelete;
    if (id) {
      await sendDelete(id);
      handlerToggleModalConfirm();
      clearSelectUser();

      Analytics.sendEventCustom({
        category: 'Group Detail',
        action: 'Delete',
        label: 'User in Group'
      });
    }
  };

  const handlerModalCancelConfirm = (): void => {
    handlerToggleModalConfirm();
    clearSelectUser();
  };

  const handlerDelete = (
    e: SyntheticEvent<HTMLElement>,
    params: IModalConfirm
  ): void => {
    e.preventDefault();
    setUserToDelete(params);
  };

  return (
    <Segment basic className='wrapper-users-group'>
      <Layer
        section='GROUPS-USERS'
        data={listInfo}
        loading={isLoadingRequest.fetch}
        loadMore={isLoadMore}
        onDelete={handlerToggleModalConfirm}
        setModalParam={handlerDelete}
        onRefresh={refetchGroupUsers}
        refetchLoadMore={getMoreGroupUsers}
      />
      {groupPermission === 'admin' && (
        <ButtonFloat
          isHidden={isLoadingRequest.fetch}
          onClick={handlerToggleModal}
        />
      )}
      <ModalCreateUserGroup
        isOpen={isOpenModalUsers}
        isLoading={isLoadingRequest.added}
        onAccept={handlerModalAccept}
        onCancel={handlerToggleModal}
      />
      <ModalConfirm
        header={{ title: t('modals.titles.warning') }}
        size='tiny'
        isOpen={isOpenModalConfirm}
        isLoading={isLoadingRequest.delete}
        onCancel={handlerModalCancelConfirm}
        onAccept={handlerModalAcceptConfirm}
      >
        {t('modals.paragraphs.confirm.deleteUser', {
          element: userToDelete.name
        })}
        <p>{t('modals.paragraphs.confirm.observation')}</p>
      </ModalConfirm>
    </Segment>
  );
};

export default Users;
