import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Modal, SemanticICONS } from 'semantic-ui-react';

import '../../../scss/layout/Modal.scss';

export interface PropsComponentModalConfirm {
  header: {
    title: string;
    icon?: SemanticICONS;
  };
  children: React.ReactNode;
  isOpen: boolean;
  isLoading: boolean;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  onAccept(e: SyntheticEvent): void;
  onCancel(e: SyntheticEvent): void;
}

const ModalConfirm = ({
  header: { title, icon = 'warning circle' },
  isOpen,
  isLoading,
  onAccept,
  onCancel,
  children,
  size
}: PropsComponentModalConfirm): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal
      size={size}
      dimmer='blurring'
      open={isOpen}
      className='wrapper-modal'
      data-testid='modal-confirm-component'
    >
      <Modal.Header>
        <Header as='h3' content={title} icon={icon} />
      </Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button
          negative
          disabled={isLoading}
          onClick={onCancel}
          content={t('actions.no')}
        />
        <Button
          positive
          disabled={isLoading}
          onClick={onAccept}
          loading={isLoading}
          content={t('actions.yes')}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalConfirm.defaultProps = {
  size: 'tiny'
};

export default ModalConfirm;
