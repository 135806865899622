import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  List,
  Segment
} from 'semantic-ui-react';
import Analytics from '../../controllers/Analytics';
import {
  CONTACT_US,
  GENERAL_QUESTIONS,
  FREQUENTLY_ASKED_QUESTIONS,
  QUESTIONS_ABOUT_DEVICES,
  GENERAL_INFORMATION
} from '../../config/constants';
import images from '../../config/images';
import { useAuthState } from '../../contexts/auth';
import { useSettings } from '../../contexts/settings';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import Layout from '../../components/Layout';
import { sectionSameAsValue } from '../../helpers';
import * as Interfaces from '../../interfaces';
import names from '../../config/names';

import '../../scss/layout/Support.scss';

type TypeSupport = 'info' | 'devices' | null;

const ANALYTICS_PAGE_VIEW: Interfaces.IAnalyticsViewPage = {
  page: names.paths.support,
  title: 'Support'
};

const Support = ({ isNavbar }: Interfaces.IPropsCtxRoutes) => {
  const { t } = useTranslation();
  const { adminLevel } = useAuthState();
  const { theme } = useSettings();
  const {
    screen: { isMobile },
    windowSize: { width: screenWidth }
  } = useBreakpoint();

  useEffect(() => {
    Analytics.sendPageView(ANALYTICS_PAGE_VIEW.page, ANALYTICS_PAGE_VIEW.title);
  }, []);

  const isNotTableSize = screenWidth >= 768;
  const isDarkMode: boolean = theme !== 'light';

  const [typeSection, setTypeSection] = useState<TypeSupport>(null);
  const [selectSection, setSelectSection] = useState<string>('');
  const [headerDescription, setHeaderDescription] = useState<string>('');
  const [questionList, setQuestionList] = useState<Interfaces.IFAQDevices[]>([
    {
      header: '',
      description: ''
    }
  ]);

  const handlerOnClickCard = (
    section: string,
    title: string,
    type: TypeSupport
  ): void => {
    if (typeSection === type && selectSection === section) {
      setTypeSection(null);
      return;
    }
    if (type === 'devices') {
      const texts = t(QUESTIONS_ABOUT_DEVICES[section] as any, {
        returnObjects: true
      });
      setQuestionList(texts);
    }
    setTypeSection(type);
    setSelectSection(section);
    setHeaderDescription(title);

    Analytics.sendEventCustom({
      category: 'Support',
      action: 'Questions',
      label: `${typeSection ?? ''} - ${section}`
    });
  };

  const renderContactUs = (): JSX.Element => (
    <>
      <Header
        as='h2'
        textAlign='left'
        content={t('support.contactUs.title')}
        className='Header-ContactUs'
        key='Header-ContactUs'
      />
      <Grid as={Segment} className='ContactUs' stackable>
        <Grid.Column computer={6} only='computer' className='image-support'>
          <Image src={images.images.support} size='medium' />
        </Grid.Column>
        {CONTACT_US.map(({ content, subheader, icon, image }, index) => (
          <Grid.Column
            key={`contact-${index + 1}`}
            className='item'
            computer={5}
            tablet={8}
            mobile={8}
          >
            {icon && <Icon name={icon} color='orange' size='huge' />}
            {image && <Image src={image} size='tiny' />}
            <Header
              as='h3'
              content={t(content as any)}
              subheader={t(subheader as any)}
            />
          </Grid.Column>
        ))}
      </Grid>
    </>
  );

  const renderDevicesFAQ = (): JSX.Element => (
    <List size='large'>
      {questionList.map(({ header, description }, index) => {
        return (
          <List.Item key={`Desk-${index + 1}`}>
            <List.Content>
              <List.Header>
                <Icon name='circle' size='tiny' />
                {header}
              </List.Header>
              <List.Description>{description}</List.Description>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );

  const renderDescription = (): JSX.Element => {
    return (
      <Segment className='segment-list'>
        <Header as='h3'>
          {t(headerDescription as any)}
          <Divider />
          <Header.Subheader>
            {typeSection === 'info' &&
              t(GENERAL_INFORMATION[selectSection] as any)}
            {typeSection === 'devices' && renderDevicesFAQ()}
          </Header.Subheader>
        </Header>
      </Segment>
    );
  };

  const renderSection = (
    element: Interfaces.ICardSupport,
    type: TypeSupport,
    index: number
  ): JSX.Element => {
    const { image, icon, title, section } = element;
    return (
      <Grid.Column
        key={`faq-button-card-${index + 1}`}
        className='button-card'
        width={4}
      >
        <Button
          as={Segment}
          onClick={() => {
            handlerOnClickCard(section, title, type);
          }}
        >
          <Label attached='bottom'>{t(title as any)}</Label>
          {icon && <Icon name={icon} color='black' size='huge' />}
          {image && (
            <Image
              src={image}
              size='small'
              className={isDarkMode ? 'dark' : ''}
            />
          )}
        </Button>
        {isMobile &&
          !isNotTableSize &&
          typeSection &&
          sectionSameAsValue(selectSection, section) &&
          renderDescription()}
      </Grid.Column>
    );
  };

  const renderCards = (
    listCards: Interfaces.ICardSupport[],
    type: TypeSupport
  ): JSX.Element => (
    <>
      <Header
        as='h2'
        textAlign='left'
        content={t('support.general.title')}
        key={`support-${type}`}
      />
      <Grid
        key={`support-button-${type}`}
        className={`button-questions ${type !== 'info' ? '' : 'info'}`}
        stackable
      >
        {listCards.map((element, index) => {
          const { permission } = element;
          if (permission.includes(adminLevel)) {
            return renderSection(element, type, index);
          }
          return <div key={`no-card-${index + 1}`} />;
        })}
      </Grid>
    </>
  );

  return (
    <Layout title={t('sections.support')} showNavbar={isNavbar}>
      <Segment basic className='wrapper-support'>
        {renderContactUs()}
        <Divider hidden />
        {renderCards(FREQUENTLY_ASKED_QUESTIONS, 'devices')}
        {typeSection === 'devices' && isNotTableSize && renderDescription()}
        <Divider hidden />
        {renderCards(GENERAL_QUESTIONS, 'info')}
        {typeSection === 'info' && isNotTableSize && renderDescription()}
      </Segment>
    </Layout>
  );
};

export default Support;
