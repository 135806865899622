import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { isModeDevelop, isModeStaging } from '../helpers';
import PkgJSON from '../../package.json';

const NAME_TAB_BUGSNAG_IOT = 'Data IoT';

interface CustomInformationIoT {
  company: {
    id: string;
    name: string;
    licensee: string;
  };
  user: {
    id: string;
    email: string;
  };
}

function getAppVersion(): string {
  return PkgJSON.version || '1.0.0';
}

class ReportLogger {
  protected static instance: ReportLogger = new ReportLogger();

  private apiSecret: string;

  private stage: 'staging' | 'production';

  private isDev: boolean;

  constructor() {
    if (ReportLogger.instance) {
      throw new Error(
        "Singleton Report Logger can't be instantiated more than once."
      );
    }

    this.apiSecret =
      process.env.REACT_APP_BUGSNAG_API_KEY ??
      '4089b2b86d020df2b74cf3eff4428ac3';
    this.isDev = isModeDevelop();
    this.stage = isModeStaging() ? 'staging' : 'production';
  }

  static getInstance(): ReportLogger {
    return ReportLogger.instance;
  }

  init(): void {
    if (!this.apiSecret) {
      console.error('Could not initialize Report Logger (missing api key)');
      return;
    }

    if (this.isDev) {
      console.info('Bugsnag in mode DEV');
      return;
    }

    try {
      console.log('Initialize Bugsnag');
      Bugsnag.start({
        apiKey: this.apiSecret,
        releaseStage: this.stage,
        enabledReleaseStages: ['production', 'staging'],
        collectUserIp: false,
        plugins: [new BugsnagPluginReact()],
        appVersion: getAppVersion()
      });
    } catch (e) {
      console.error('Could not initialize Report Logger', e);
    }
  }

  setUser(userId: string, email: string | undefined): void {
    if (this.isDev) {
      console.log(userId, email);
      return;
    }
    Bugsnag.setUser(userId, email);
  }

  setCustomInformationIoT(data: CustomInformationIoT): void {
    if (this.isDev) {
      console.log(data);
      return;
    }

    Bugsnag.addMetadata(NAME_TAB_BUGSNAG_IOT, data);
  }

  error(
    message: string,
    context?: Record<string, unknown> | Error | unknown
  ): void {
    if (this.isDev) {
      console.error(message, context);
      return;
    }

    const contentMessageError =
      context instanceof Error
        ? { message: context.message, name: context.name }
        : context ?? {};

    Bugsnag.notify({
      name: message,
      message: JSON.stringify(contentMessageError)
    });
  }
}

export default ReportLogger;
