import React, { useState } from 'react';
import moment from 'moment';
import Markdown from 'markdown-to-jsx';
import { Segment, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Analytics from '../../controllers/Analytics';
import { useCommands } from '../../hooks/useCommands';
import ModalControl from '../Modals/Control';
import ModalConfirm from '../Modals/Delete/Confirm';
import ModalSettings from '../Modals/Custom/Configuration';
import Empty from '../Empty';
import Layer from '../Layer';
import ControlButton from './Button';
import * as Constants from '../../config/constants';
import * as Types from '../../types';
import * as Interfaces from '../../interfaces';

import './Control.scss';

const { LIST_CONTROLS_BUTTONS } = Constants;

export interface PropsComponentControl {
  view?: Types.TControlViews;
  groupId: string;
  thingId?: string;
  isValidId?: boolean;
  thingType?: Types.TReceptorTypes;
  hasPermissions: boolean;
  recipient: Interfaces.IControlRecipient;
}

const DEFAULT_COMMAND_DELETE: Interfaces.ICommandScheduledDeleteElement = {
  id: '',
  actionName: '',
  sendingTimeFormat: ''
};

const Control = ({
  view,
  groupId,
  thingId,
  isValidId,
  thingType,
  hasPermissions,
  recipient
}: PropsComponentControl): JSX.Element => {
  const { t } = useTranslation();
  const [commandDelete, setCommandDelete] =
    useState<Interfaces.ICommandScheduledDeleteElement>(DEFAULT_COMMAND_DELETE);
  const {
    isOpenModal,
    checkGroupId,
    toggleOpenModal,
    isLoadingRequest,
    managerCommandFinish,
    nameButton,
    processRequest: { isSend, message },
    dataScheduledCommands: { listParsed },
    sendDeleteCommandScheduled,
    refetchScheduleCommands,
    getMoreScheduleCommands,
    nextRequest
  } = useCommands({ groupId, activeFetchScheduleCommands: true });

  const handlerOnFinish = async ({
    message: msg,
    date,
    scheduled: typeScheduled
  }: Interfaces.IModalControlDataFinish): Promise<void> => {
    const isScheduled = typeScheduled === 'scheduled';
    const scheduleAt = moment(
      `${date.date} ${date.time}:00`,
      'DD-MM-YYYY HH:mm:ss'
    );

    await managerCommandFinish({
      scheduled: typeScheduled,
      message: msg,
      scheduleAt: isScheduled
        ? scheduleAt.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
        : ''
    });

    Analytics.sendEventCustom({
      category: `${view}`,
      action: 'Send Command',
      label: `${nameButton}`
    });
  };

  const handleOnConfirmDeleteCommandScheduled = async (): Promise<void> => {
    const { id } = commandDelete;
    await sendDeleteCommandScheduled(id);
    setCommandDelete(DEFAULT_COMMAND_DELETE);
    toggleOpenModal('confirm');
  };

  const handleOnDeleteCommandScheduled = (
    command: Interfaces.ICommandScheduledDeleteElement
  ): void => {
    setCommandDelete(command);
    toggleOpenModal('confirm');
  };

  const renderControls = (): JSX.Element => {
    const isDeskThing = ['desk', 'desk-plus'].includes(thingType || 'gadget');
    const isViewGroups = view === 'groups';

    return (
      <Segment basic className='commands-container'>
        {LIST_CONTROLS_BUTTONS.map((element) => {
          const isHidden =
            (isViewGroups && element.name === 'configuration') ||
            (isDeskThing && element.name === 'customAudio') ||
            (isViewGroups && element.name === 'customAudio');
          return (
            <ControlButton
              key={element.id}
              isHidden={isHidden}
              element={element}
              onClick={() =>
                checkGroupId({ ...element, isValidId: !!isValidId })
              }
            />
          );
        })}
      </Segment>
    );
  };

  const renderRibbonImport = (): JSX.Element => (
    <Segment
      className='important-ribbon'
      style={{ marginTop: 0, marginBottom: 30 }}
    >
      <Icon name='warning circle' />
      {t(`control.important.${view}` as any)}
    </Segment>
  );

  return (
    <Segment basic className='wrapper-control-group'>
      {renderRibbonImport()}
      {hasPermissions ? (
        renderControls()
      ) : (
        <Empty
          iconName='eye slash outline'
          texts={{
            title: t('messages.permission.title')
          }}
        />
      )}
      <Layer
        section='CONTROL-THINGS'
        data={listParsed}
        loading={isLoadingRequest.fetch}
        loadMore={nextRequest.isLoadMore}
        onRefresh={refetchScheduleCommands}
        onDeleteCommand={handleOnDeleteCommandScheduled}
        refetchLoadMore={getMoreScheduleCommands}
      />
      <ModalControl
        view={view}
        isOpen={isOpenModal.commands}
        isSend={isSend}
        errorMessage={message}
        commandName={nameButton}
        recipient={recipient}
        isLoading={isLoadingRequest.create}
        onCancel={() => toggleOpenModal('commands')}
        onFinish={handlerOnFinish}
      />
      <ModalConfirm
        header={{
          title: t('control.schedule.cancel.title', {
            command: commandDelete.actionName
          })
        }}
        isOpen={isOpenModal.confirm}
        isLoading={isLoadingRequest.delete}
        onAccept={handleOnConfirmDeleteCommandScheduled}
        onCancel={() => toggleOpenModal('confirm')}
      >
        <Markdown>
          {t('control.schedule.cancel.subtitle', {
            id: commandDelete.id,
            command: commandDelete.actionName,
            date: commandDelete.sendingTimeFormat
          })}
        </Markdown>
      </ModalConfirm>
      <ModalSettings
        thingId={thingId ?? ''}
        isOpen={isOpenModal.config}
        onAccept={() => toggleOpenModal('config')}
        onCancel={() => toggleOpenModal('config')}
      />
    </Segment>
  );
};

Control.defaultProps = {
  thingId: '',
  view: 'groups',
  isValidId: true,
  thingType: 'gadget'
};

export default Control;
