/* eslint-disable no-case-declarations */
import React, { SyntheticEvent, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DateInput, DatesRangeInput } from 'semantic-ui-calendar-react';
import { Button, Grid, Header, Segment, Select } from 'semantic-ui-react';
import Analytics from '../../../../controllers/Analytics';
import { useAuthState } from '../../../../contexts/auth';
import useReports from '../../../../hooks/useReports';
import Empty from '../../../../components/Empty';
import { OPTIONS_TYPE_REPORT } from '../../../../config/constants';
import { parseEndDateMonitorReport } from '../../../../helpers';
import { IAnalyticsViewPage } from '../../../../interfaces';
import names from '../../../../config/names';

import '../../../../scss/layout/GroupsDetail.scss';

interface IProps {
  groupId: string;
  groupName: string;
}

const ANALYTICS_PAGE_VIEW: IAnalyticsViewPage = {
  page: names.paths.groups.main,
  title: 'Groups Detail Report'
};

const REPORTING_START_DATE = '01-01-2023';
const DATE_FORMAT = 'YYYY-MM-DD';

const Reports = ({ groupId, groupName }: IProps): JSX.Element => {
  const { customer } = useAuthState();
  const { t } = useTranslation();

  const {
    fetchGroupReport,
    downloadReport,
    isLoading: { fetch, download }
  } = useReports();

  useEffect(() => {
    Analytics.sendPageView(ANALYTICS_PAGE_VIEW.page, ANALYTICS_PAGE_VIEW.title);
  }, []);

  const [date, setDate] = useState<string>('');
  const [typeReport, setTypeReport] = useState<string>('initial');

  const OPTIONS_SELECT = OPTIONS_TYPE_REPORT.map((element) => ({
    ...element,
    text: t(element.text as any)
  }));

  const resetStates = (): void => {
    setDate('');
    setTypeReport('initial');
  };

  const handlerOnChangeSelect = (e: SyntheticEvent, { value }: any): void => {
    e.preventDefault();
    const dateValue = value !== 'today' ? '' : moment().format(DATE_FORMAT);
    setTypeReport(value as string);
    setDate(dateValue);
  };

  const handlerOnChange = (e: SyntheticEvent, { value }: any): void => {
    e.preventDefault();
    setDate(value);
  };

  const handlerAccept = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    let initialDate = moment().format(DATE_FORMAT);
    let endDate = moment().format(DATE_FORMAT);

    switch (typeReport) {
      case 'day':
        initialDate = date;
        endDate = date;
        break;
      case 'range':
        const datesReport = date.split(' - ');
        [initialDate, endDate] = datesReport;
        break;
      default:
        break;
    }
    const reportDate =
      typeReport !== 'range' ? initialDate : `${initialDate}-${endDate}`;
    const reportName = t('groups.reports.reportName', {
      group: groupName,
      date: reportDate
    });

    const parseEndDate = parseEndDateMonitorReport(endDate);

    const responseRawText = await fetchGroupReport(
      initialDate,
      parseEndDate,
      groupId,
      customer,
      false
    );

    if (responseRawText) {
      downloadReport(reportName, responseRawText);
    }

    Analytics.sendEventCustom({
      category: 'Groups Detail',
      action: 'Create',
      label: 'Report'
    });
  };

  const renderCalendar = (): JSX.Element => {
    switch (typeReport) {
      case 'initial':
        const translate = t('messages.groupsDetail.reports.initial', {
          returnObjects: true
        });
        return <Empty iconName='search' texts={translate} />;
      case 'day':
        return (
          <>
            <Header as='h4' content={t('things.reports.modal.headerDay')} />
            <DateInput
              inline
              name='date'
              value={date}
              dateFormat={DATE_FORMAT}
              iconPosition='left'
              minDate={REPORTING_START_DATE}
              maxDate={moment()}
              onChange={handlerOnChange}
            />
          </>
        );
      case 'range':
        return (
          <>
            <Header as='h4' content={t('things.reports.modal.headerRange')} />
            <DatesRangeInput
              inline
              name='datesRange'
              value={date}
              dateFormat={DATE_FORMAT}
              iconPosition='left'
              maxDate={moment()}
              onChange={handlerOnChange}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  const renderForm = (): JSX.Element => (
    <Segment basic className='container-confirm'>
      <Header as='h4' content={t('groups.reports.selectType')} />
      <Select
        value={typeReport}
        options={OPTIONS_SELECT}
        onChange={handlerOnChangeSelect}
        className='select-type-report'
      />
    </Segment>
  );

  const renderConfirmDownload = () => (
    <Segment className='container-confirm'>
      <Header as='h4' content={t('things.reports.modal.question', { date })} />
      <Button
        onClick={resetStates}
        content={t('actions.cancel')}
        className='cancel-button'
        disabled={fetch || download}
      />
      <Button
        disabled={
          !date ||
          (typeReport !== 'range' ? false : !date.split(' - ')[1].length) ||
          fetch ||
          download
        }
        icon='arrow down'
        labelPosition='right'
        color='blue'
        className='download-button'
        onClick={handlerAccept}
        content={t('actions.download')}
        loading={fetch || download}
      />
    </Segment>
  );

  return (
    <Segment basic placeholder className='wrapper-reports'>
      <Grid
        as={Segment}
        stackable
        basic
        container
        padded
        style={{ padding: 0 }}
      >
        <Grid.Row stretched>
          <Header content={t('groups.reports.search')} />
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column width={16} className='formReport'>
            {renderForm()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} className='tableSearch'>
          {!!date.length &&
            (typeReport !== 'range' ? true : !!date.split(' - ')[1].length) &&
            renderConfirmDownload()}
          <Segment basic>{renderCalendar()}</Segment>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default Reports;
