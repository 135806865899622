import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbProps, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavState } from '../../contexts/navigation';
import {
  ELEMENTS_BREADCRUMBS,
  BREADCRUMBS_CUSTOM
} from '../../config/constants';
import names from '../../config/names';

import './BreadcrumbCustom.scss';

export interface PropsComponentBreadcrumbCustom {
  flow: string[];
  isDetail?: boolean;
}

const BreadcrumbCustom = ({
  flow,
  isDetail
}: PropsComponentBreadcrumbCustom): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateSection, isMobileBreakpoint } = useNavState();

  const [breadSections, setBreadSections] = useState<
    BreadcrumbProps['sections']
  >([]);

  useEffect(() => {
    const listSection: BreadcrumbProps['sections'] = flow.map(
      (item: string, index) => {
        const firstSection = ELEMENTS_BREADCRUMBS[item];
        if (firstSection) {
          const { content, location } = firstSection;
          return {
            key: content,
            content: t(content),
            link: true,
            onClick: () => {
              navigate(location);
              updateSection(location);
            }
          };
        }

        return {
          key: item,
          content: t(BREADCRUMBS_CUSTOM[item]) || item,
          link: true,
          onClick: () => {
            const isGroupLocation =
              ELEMENTS_BREADCRUMBS[flow[0]].location ===
              names.paths.groups.main;
            const slug = index === 1 && !isGroupLocation ? `/${item}` : '';
            navigate(`${ELEMENTS_BREADCRUMBS[flow[0]].location}${slug}`);
          }
        };
      }
    );

    if (isDetail) {
      listSection.push({
        key: t('breadcrumbCustom.detail'),
        content: t('breadcrumbCustom.detail'),
        active: true
      });
    }

    setBreadSections(listSection);
  }, [flow, isDetail]);

  return (
    <Segment
      basic
      data-testid='breadcrumbCustom-component'
      className='wrapper-breadcrumbCustom'
      style={{ margin: 0, padding: 0 }}
    >
      <Breadcrumb
        size={isMobileBreakpoint ? 'large' : undefined}
        icon='right angle'
        className='breadcrumb'
        sections={breadSections}
      />
    </Segment>
  );
};

BreadcrumbCustom.defaultProps = {
  isDetail: false
};

export default BreadcrumbCustom;
