import React, { useEffect } from 'react';
import Analytics from '../../../../controllers/Analytics';
import { useNavState } from '../../../../contexts/navigation';
import Control from '../../../../components/Control';
import { validateGroupIdGroup } from '../../../../helpers';
import names from '../../../../config/names';
import * as Interfaces from '../../../../interfaces';
import useAuthClient from '../../../../hooks/useAuthClient';

const ANALYTICS_PAGE_VIEW: Interfaces.IAnalyticsViewPage = {
  page: names.paths.groups.main,
  title: 'Groups Detail Control'
};

const GroupsControl = ({
  groupId,
  groupName = ''
}: Interfaces.IGroupComponentProps): JSX.Element => {
  const { customerId } = useAuthClient();
  const { groupPermission } = useNavState();

  useEffect(() => {
    Analytics.sendPageView(ANALYTICS_PAGE_VIEW.page, ANALYTICS_PAGE_VIEW.title);
  }, []);

  const propsControl = { groupId, hasPermissions: groupPermission !== 'read' };

  const isValidId = validateGroupIdGroup(groupId, customerId, groupName);
  const recipient: Interfaces.IControlRecipient = {
    id: groupId,
    name: groupName
  };

  return (
    <Control {...propsControl} isValidId={isValidId} recipient={recipient} />
  );
};

GroupsControl.defaultProps = {
  groupName: ''
};

export default GroupsControl;
