import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';
import { gql, useSubscription } from '@apollo/client';
import { useAuthState } from '../contexts/auth';
import { useStatusRequest } from '../contexts/request';
import BackendIoT from '../controllers/Backend/IoT';
import ReporterLogger from '../controllers/ReporterLogger';
import ModelFeed from '../models/Feed';
import * as Interfaces from '../interfaces';

interface ISubscriptionFeedData {
  onPublishNewFeed: Interfaces.ISubscriptionFeed[] | any;
}

interface IParamsFeedAcknowledgments {
  isThing?: boolean;
  thingId?: string;
}

interface IUseFeedAcknowledgments {
  isLoading: boolean;
  refetchAcknowledgments: () => Promise<void>;
  getMoreFeed: () => Promise<void>;
  dataAcknowledgments: {
    listParsed: Interfaces.ISubscriptionFeed[] | [];
  };
  nextRequest: {
    isLoadMore: boolean;
    lastItem?: Interfaces.ILastItemFeed;
  };
}

interface IState {
  query: Interfaces.IQueryFeedAcknowledgements;
  isLoading: IUseFeedAcknowledgments['isLoading'];
  listFeedAcknowledgments: IUseFeedAcknowledgments['dataAcknowledgments']['listParsed'];
  nextRequest: IUseFeedAcknowledgments['nextRequest'];
}

export const FEED_ACK_SUBSCRIPTION = gql`
  subscription publishNewFeed($groupId: String!) {
    onPublishNewFeed(id: $groupId) {
      createdAt
      name
      event
      description
    }
  }
`;

const LoggerInstance = ReporterLogger.getInstance();

export const useFeed = (
  groupId: Interfaces.IGroupComponentProps['groupId']
) => {
  return useSubscription<
    ISubscriptionFeedData,
    Interfaces.IGroupComponentProps
  >(FEED_ACK_SUBSCRIPTION, {
    variables: { groupId }
  });
};

export const useFeedAcknowledgments = (
  groupId: Interfaces.IGroupComponentProps['groupId'],
  data?: ISubscriptionFeedData,
  params: IParamsFeedAcknowledgments = {
    isThing: false,
    thingId: ''
  }
): IUseFeedAcknowledgments => {
  const { t } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();
  const { adminLevel } = useAuthState();

  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });

  const DEFAULT_QUERY: IState['query'] = { limit: 25 };

  const DEFAULT_VALUE_QUERY: IState['query'] = params?.isThing
    ? { thingId: params?.thingId, ...DEFAULT_QUERY }
    : DEFAULT_QUERY;

  const DEFAULT_VALUE_NEXT_REQUEST: IState['nextRequest'] = {
    isLoadMore: false
  };

  const [query, setQuery] = useState<IState['query']>(DEFAULT_VALUE_QUERY);
  const [listFeedAcknowledgments, setListFeedAcknowledgments] = useState<
    IState['listFeedAcknowledgments']
  >([]);
  const [isLoading, setIsLoading] = useState<IState['isLoading']>(true);
  const [nextRequest, setNextRequest] = useState<IState['nextRequest']>(
    DEFAULT_VALUE_NEXT_REQUEST
  );

  const parseDataItemFeed = (rawData: Interfaces.IFeedItems) =>
    new ModelFeed().init(rawData).parseToKeysSubscription();

  const fetchFeed = async (isRefetch = false): Promise<void> => {
    if (!isLoading) {
      setIsLoading(true);
    }
    try {
      const response = await BackendIoT.getLastAcknowledgmentsFeed(
        groupId,
        query
      );
      let newListFeedAcknowledgments: Interfaces.ISubscriptionFeed[] = [];
      let newNextRequest = DEFAULT_VALUE_NEXT_REQUEST;
      const { feed, lastItem } = response;

      if (feed.length || listFeedAcknowledgments.length) {
        newListFeedAcknowledgments = feed.map(
          (feedItem: Interfaces.IFeedItems) => parseDataItemFeed(feedItem)
        );

        if (nextRequest.isLoadMore && !isRefetch) {
          newListFeedAcknowledgments = [
            ...listFeedAcknowledgments,
            ...newListFeedAcknowledgments
          ];
        }

        if (lastItem) {
          newNextRequest = {
            isLoadMore: !!newListFeedAcknowledgments.length,
            lastItem
          };
        }
      }

      const filteredScheduleLocalDrill = newListFeedAcknowledgments.filter(
        (elem) => elem.typeEvent !== 'schedule-local-drill'
      );

      const validateData =
        adminLevel !== 'SkyAlertLicensee'
          ? filteredScheduleLocalDrill
          : newListFeedAcknowledgments;

      const orderFeedData = orderBy(validateData, [`createdAt`], [`desc`]);

      setNextRequest(newNextRequest);
      setListFeedAcknowledgments(orderFeedData);
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        message: t('error.requests.feedAck.fetch'),
        delay: 6000
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed fetch feed acknowledgments - useFeedAcknowledgments - "fetchFeed"',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const refetchAcknowledgments = async (): Promise<void> => {
    if (query.lastItem) {
      setQuery(DEFAULT_VALUE_QUERY);
      setNextRequest(DEFAULT_VALUE_NEXT_REQUEST);
    } else {
      await fetchFeed(true);
    }
  };

  useEffect(() => {
    fetchFeed();
  }, [groupId, query]);

  useEffect(() => {
    if (data && data.onPublishNewFeed) {
      const parseNewItem = parseDataItemFeed(data.onPublishNewFeed);
      setListFeedAcknowledgments((prevState) => [parseNewItem, ...prevState]);
    }
  }, [data]);

  const getMoreFeed = async (): Promise<void> => {
    const paramsQuery = {
      ...query,
      lastItem: JSON.stringify(nextRequest.lastItem)
    };
    setQuery(paramsQuery);
  };

  return {
    isLoading,
    refetchAcknowledgments,
    getMoreFeed,
    nextRequest,
    dataAcknowledgments: {
      listParsed: listFeedAcknowledgments
    }
  };
};
