import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../contexts/auth';
import { useAuthClient } from '../useAuthClient';
import { useStatusRequest } from '../../contexts/request';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import { filterListGroups } from '../../helpers';
import { IRequestThingDetails, IGroupsIoT } from '../../interfaces';

interface IProps {
  id: string;
  groupId: string;
}

interface IUseThingDetails {
  isLoading: boolean;
  dataThing: {
    listInfoDetail: IRequestThingDetails | null;
    hasPermissions: boolean;
  };
}

interface IState {
  isLoading: IUseThingDetails['isLoading'];
  listInfoDetail: IUseThingDetails['dataThing']['listInfoDetail'];
  hasPermissions: IUseThingDetails['dataThing']['hasPermissions'];
}

const LoggerInstance = ReporterLogger.getInstance();

export const useThingDetails = (id: IProps['id']): IUseThingDetails => {
  const { t } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoading, setIsLoading] = useState<IState['isLoading']>(true);
  const [dataThingDetail, setDataThingDetail] =
    useState<IState['listInfoDetail']>(null);
  const [hasPermissions, setHasPermissions] =
    useState<IState['hasPermissions']>(false);

  const { groups } = useAuthState();
  const { customerId } = useAuthClient();

  const groupsUserInThing = (filteredGroups: any): void => {
    if (filteredGroups.length === 0) {
      setHasPermissions(false);
    } else {
      const namesGroups = groups ? Object.keys(groups) : [];
      const comparisonGroups = filteredGroups.map(({ group }: any) => {
        return namesGroups.includes(group);
      });
      setHasPermissions(comparisonGroups.includes(true));
    }
  };

  const fetchThingDetail = async (): Promise<void> => {
    try {
      const response = await BackendIoT.getThingDetails(id);
      if (response?.license) {
        const { licenses: licensesResponse, license } = response;
        const licenses = licensesResponse.length ? licensesResponse : [license];
        const listFilter: IGroupsIoT[] = response.groups;
        const filteredGroups = filterListGroups(listFilter, customerId);

        setDataThingDetail({ licenses, license, groups: filteredGroups });
        groupsUserInThing(filteredGroups);
      }
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.things.fetch'),
        delay: 6000
      });
      LoggerInstance.error(
        'Failed to thing detail - useThingDetails - "fetchThingDetail"',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchThingDetail();
  }, [id]);

  return {
    isLoading,
    dataThing: {
      listInfoDetail: dataThingDetail,
      hasPermissions
    }
  };
};

export default useThingDetails;
