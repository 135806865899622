/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  DateInput,
  TimeInput,
  DateInputProps,
  TimeInputProps
} from 'semantic-ui-calendar-react';
import {
  Checkbox,
  Grid,
  Form,
  Segment,
  Header,
  Input,
  Label
} from 'semantic-ui-react';
import * as Constants from '../../../../config/constants';
import * as Interfaces from '../../../../interfaces';

export interface PropsComponentModalCommandsScheduled {
  typeOption?: Interfaces.IListSchedule['value'];
  onSelectCalendar(value: Interfaces.IDateSchedule): void;
  initialDate: Interfaces.IDateSchedule;
  isErrorTime?: boolean;
  onSelectOption(value: string): void;
}

const DATE_FORMAT = 'DD-MM-YYYY';

const CommandsScheduled = ({
  typeOption = 'now',
  initialDate,
  onSelectCalendar,
  onSelectOption,
  isErrorTime
}: PropsComponentModalCommandsScheduled): JSX.Element => {
  const { t } = useTranslation();

  const [type, setType] =
    useState<Interfaces.IListSchedule['value']>(typeOption);
  const [fullDate, setFullDate] =
    useState<Interfaces.IDateSchedule>(initialDate);
  const [activeInput, setActiveInput] = useState<string>('');

  const resetStates = (): void => {
    setFullDate(Constants.DEFAULT_VALUES_MODAL_DATE);
    onSelectCalendar(Constants.DEFAULT_VALUES_MODAL_DATE);
    setActiveInput('');
  };

  const handlerSelectOption = (
    e: SyntheticEvent,
    value: Interfaces.IListSchedule['value']
  ) => {
    e.preventDefault();
    if (type !== value) {
      resetStates();
      setType(value);
      onSelectOption(value);
    }
  };

  const handlerChangeDateTime = (
    e: SyntheticEvent,
    { value, name }: DateInputProps | TimeInputProps
  ): void => {
    e.preventDefault();
    const updateValue = name !== 'time' ? { date: value } : { time: value };
    const mergeValue = { ...fullDate, ...updateValue };
    setFullDate(mergeValue);
    onSelectCalendar(mergeValue);
  };

  const renderForm = (): JSX.Element => (
    <Form className='form-scheduled'>
      <Header as='h3'>{t('modals.titles.control.form.title')}</Header>
      <Form.Field>
        <label>{t('modals.titles.control.form.date')}</label>
        <Input
          icon='calendar'
          iconPosition='left'
          placeholder={t('modals.titles.control.form.date')}
          value={fullDate.date}
          onClick={() => setActiveInput('date')}
          onChange={() => setFullDate(fullDate)}
        />
        {activeInput === 'date' && (
          <DateInput
            inline
            name='date'
            icon='calendar'
            placeholder={t('modals.titles.control.form.date')}
            value={fullDate.date}
            marked={[moment()]}
            minDate={moment()}
            dateFormat={DATE_FORMAT}
            markColor='orange'
            iconPosition='left'
            hideMobileKeyboard
            onChange={handlerChangeDateTime}
          />
        )}
      </Form.Field>
      <Form.Field error={isErrorTime}>
        <label>
          {t('modals.titles.control.form.time')}
          {isErrorTime && (
            <Label
              basic
              pointing='left'
              color='red'
              content={t('modals.titles.control.form.invalid')}
              style={{ margin: '5px 10px' }}
            />
          )}
        </label>
        <Input
          icon='time'
          iconPosition='left'
          placeholder={t('modals.titles.control.form.time')}
          value={fullDate.time}
          onClick={() => setActiveInput('time')}
          onChange={() => setFullDate(fullDate)}
        />

        {activeInput === 'time' && (
          <TimeInput
            inline
            name='time'
            placeholder={t('modals.titles.control.form.time')}
            value={fullDate.time}
            iconPosition='left'
            popupPosition='bottom left'
            hideMobileKeyboard
            onChange={handlerChangeDateTime}
          />
        )}
      </Form.Field>
    </Form>
  );

  return (
    <Segment basic data-testid='modalsCommandsScheduled-component'>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column mobile={16} computer={6}>
            <Form autoComplete='off'>
              {Object.values(Constants.OPTIONS_LIST_SCHEDULE_COMMAND).map(
                (item) => (
                  <Form.Field key={item.value}>
                    <Checkbox
                      value={item.value}
                      label={t(`${item.label}` as any)}
                      checked={type === item.value}
                      onChange={(e: SyntheticEvent) =>
                        handlerSelectOption(e, item.value)
                      }
                    />
                  </Form.Field>
                )
              )}
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} computer={10}>
            {type === 'scheduled' && renderForm()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

CommandsScheduled.defaultProps = {
  typeOption: 'now',
  isErrorTime: false
};

export default CommandsScheduled;
