import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useStatusRequest } from '../../contexts/request';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import * as Interfaces from '../../interfaces';

interface IProps {
  id: string;
}

interface IUseThing {
  dataThing: {
    listInfo: Interfaces.IRequestThingById['thing'] | undefined;
    licenseInfo: Interfaces.ILicensesTypeItem | undefined;
  };
  isLoadingRequest: {
    fetch: boolean;
    edit: boolean;
  };
  sendEditThingName: (body: Interfaces.IBodyThingNameEdit) => Promise<void>;
  toggleReloadComponent: () => void;
}

interface IState {
  isLoadingRequest: IUseThing['isLoadingRequest'];
  listInfoThing: IUseThing['dataThing']['listInfo'];
  listInfoLicense: IUseThing['dataThing']['licenseInfo'];
}

const LoggerInstance = ReporterLogger.getInstance();

export const useThing = (id: IProps['id']): IUseThing => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoadingRequest, setIsLoadingRequest] = useState<
    IUseThing['isLoadingRequest']
  >({
    fetch: false,
    edit: false
  });
  const [listThing, setListThing] =
    useState<IState['listInfoThing']>(undefined);
  const [licenseInfo, setLicenseInfo] =
    useState<IState['listInfoLicense']>(undefined);
  const [isReloadComponent, setIsReloadComponent] = useState<boolean>(false);

  const updateParamsInURL = (
    newNameThing: Interfaces.IBodyThingNameEdit['thingName']
  ) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('name', newNameThing);
    setSearchParams(updatedSearchParams.toString());
  };

  const fetchThing = async (): Promise<void> => {
    if (!isLoadingRequest.fetch) {
      setIsLoadingRequest((prev) => ({
        ...prev,
        fetch: true
      }));
    }
    try {
      const response = await BackendIoT.getThing(id);
      if (response?.thing) {
        setListThing(response.thing);
      }
      if (response?.license) {
        setLicenseInfo(response.license);
      }
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.things.fetch'),
        delay: 6000
      });
      LoggerInstance.error('Failed to thing - useThing - "fetchThing"', error);
    } finally {
      setIsLoadingRequest((prev) => ({
        ...prev,
        fetch: false
      }));
    }
  };

  const sendEditThingName = async (
    body: Interfaces.IBodyThingNameEdit
  ): Promise<void> => {
    if (!isLoadingRequest.edit) {
      setIsLoadingRequest((prev) => ({
        ...prev,
        edit: true
      }));
    }
    try {
      await BackendIoT.updateThingName(body);
      updateParamsInURL(body.thingName);

      UpdateMessageResponse({
        type: 'success',
        action: t('actions.requests.edit.thing'),
        message: t('success.requests.things.edit', {
          name: body.thingName
        })
      });
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        action: t('actions.requests.edit.thing'),
        message: t('error.requests.things.edit', {
          name: body.thingName
        })
      });
      LoggerInstance.error(
        'Failed edit thing name - useThingsByType - "sendEditThingName"',
        error
      );
    } finally {
      setIsLoadingRequest((prev) => ({
        ...prev,
        edit: false
      }));
    }

    fetchThing();
  };

  const toggleReloadComponent = (): void => {
    setIsReloadComponent((prev) => !prev);
  };

  useEffect(() => {
    fetchThing();
  }, [id]);

  useEffect(() => {
    if (isReloadComponent) {
      fetchThing();
      toggleReloadComponent();
    }
  }, [isReloadComponent]);

  return {
    isLoadingRequest,
    sendEditThingName,
    toggleReloadComponent,
    dataThing: {
      listInfo: listThing,
      licenseInfo
    }
  };
};

export default useThing;
