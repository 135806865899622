import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStatusRequest } from '../../contexts/request';
import BackendIoT from '../../controllers/Backend/IoT';
import ReporterLogger from '../../controllers/ReporterLogger';
import * as Interfaces from '../../interfaces';

interface IProps {
  id: string;
  isOpen: boolean;
}

interface IUseThingSettings {
  isLoadingRequest: {
    fetch: boolean;
    create: boolean;
  };
  dataThing: {
    listInfoSettings: Interfaces.IBodySettings;
  };
  sendConfigThing: () => void;
  updateListThingSettings(value: Interfaces.IBodySettings): void;
}

interface IState {
  isLoading: IUseThingSettings['isLoadingRequest'];
  listSettings: IUseThingSettings['dataThing']['listInfoSettings'];
}

const DEFAULT_VALUES_SETTINGS: IState['listSettings'] = {
  drillOptOut: false,
  intensityTrigger: 0,
  customAudio: { files: {} }
};

const LoggerInstance = ReporterLogger.getInstance();

export const useThingSettings = (
  id: IProps['id'],
  isOpen: IProps['isOpen']
): IUseThingSettings => {
  const { t } = useTranslation();

  const messageResponseRef = useRef<Interfaces.IMessageResponse>({
    type: 'success',
    message: ''
  });

  const { UpdateMessageResponse } = useStatusRequest();

  const [listSettings, setListSettings] = useState<IState['listSettings']>(
    DEFAULT_VALUES_SETTINGS
  );
  const [isLoading, setIsLoading] = useState<IState['isLoading']>({
    fetch: true,
    create: false
  });

  const getThingSettings = async (): Promise<void> => {
    if (!isLoading.fetch) {
      setIsLoading({ ...isLoading, fetch: true });
    }
    try {
      const response = await BackendIoT.getThingSettings(id);
      const {
        state: { desired, reported }
      } = response;
      const configValue = reported || desired || DEFAULT_VALUES_SETTINGS;
      setListSettings(configValue);
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        message: t('error.requests.things.settings'),
        delay: 6000
      };
      UpdateMessageResponse(messageResponseRef.current);
      LoggerInstance.error(
        'Failed get thing settings - useThingSettings - "getThingSettings"',
        error
      );
    } finally {
      setIsLoading({ ...isLoading, fetch: false });
    }
  };

  useEffect(() => {
    if (isOpen) {
      getThingSettings();
    }
  }, [isOpen]);

  const sendConfigThing = async (): Promise<void> => {
    // TODO: CHANGE THE BODY WHEN YOU CAN EDIT MORE THINGS
    const body = { drillOptOut: listSettings.drillOptOut };

    try {
      setIsLoading({ ...isLoading, create: true });
      await BackendIoT.setThingSettings(id, body);
      messageResponseRef.current = {
        type: 'success',
        action: t('actions.requests.update.settings'),
        message: t('success.requests.things.update')
      };
    } catch (error) {
      messageResponseRef.current = {
        type: 'error',
        message: t('error.requests.things.updateSettings'),
        delay: 6000
      };
      LoggerInstance.error(
        'Failed edit settings - useThingSettings - "sendConfigThing"',
        error
      );
    } finally {
      UpdateMessageResponse(messageResponseRef.current);
      setIsLoading({ ...isLoading, create: false });
    }
  };

  const updateListThingSettings = (setting: IState['listSettings']) => {
    setListSettings(setting);
  };

  return {
    dataThing: {
      listInfoSettings: listSettings
    },
    isLoadingRequest: isLoading,
    sendConfigThing,
    updateListThingSettings
  };
};

export default useThingSettings;
