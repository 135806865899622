import ReactGA from 'react-ga4';
import ReporterLogger from './ReporterLogger';
import { isModeDevelop, isModeStaging } from '../helpers';

interface ParamsEventCustom {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

const LoggerInstance = ReporterLogger.getInstance();

class Analytics {
  protected static instance: Analytics = new Analytics();

  private apiKey: string;

  private stage: 'staging' | 'production';

  private isDev: boolean;

  constructor() {
    if (Analytics.instance) {
      throw new Error(
        "Singleton Analytics can't be instantiated more than once."
      );
    }

    this.apiKey =
      process.env.REACT_APP_GOOGLE_ANALYTICS_API_KEY ?? 'G-B37ZX6XD8E';
    this.isDev = isModeDevelop();
    this.stage = isModeStaging() ? 'staging' : 'production';
  }

  static getInstance(): Analytics {
    return Analytics.instance;
  }

  init(): void {
    if (!this.apiKey) {
      LoggerInstance.error('Could not initialize Analytics (missing api key)');
      return;
    }

    if (this.isDev || this.stage !== 'production') {
      console.log('Analytics in mode DEV');
      return;
    }

    console.log('Initialize Analytics');
    ReactGA.initialize(this.apiKey);
  }

  static sendPageView(page: string, title: string): void {
    ReactGA.send({ hitType: 'pageview', page, title });
  }

  static sendEventCustom(params: ParamsEventCustom): void {
    ReactGA.event(params);
  }
}

export default Analytics;
