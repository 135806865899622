import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import Analytics from '../../controllers/Analytics';
import { useCommands } from '../../hooks/useCommands';
import ModalConfirm from '../Modals/Delete/Confirm';
import { groupNameThings } from '../../helpers';
import * as Interfaces from '../../interfaces';
import * as Constants from '../../config/constants';

import './CommandsThings.scss';

const { LIST_CONTROLS_BUTTONS } = Constants;

interface ICommandsThingsProps {
  customerId: string;
}

interface ISelectCommand {
  text: string;
  name: string;
}

const DEFAULT_SELECT_COMMAND: ISelectCommand = {
  text: '',
  name: 'drill'
};

const commandsGeneralThings: Interfaces.IControlsCommandsList['command'][] = [
  'drill-start',
  'drill-stop'
];

const commandsFilter = LIST_CONTROLS_BUTTONS.filter(({ command }) =>
  commandsGeneralThings.includes(command)
);

const CommandsThings = ({ customerId }: ICommandsThingsProps): JSX.Element => {
  const { t } = useTranslation();

  const [selectCommand, setSelectCommand] = useState<ISelectCommand>(
    DEFAULT_SELECT_COMMAND
  );

  const {
    isOpenModal,
    toggleOpenModal,
    isLoadingRequest,
    checkGroupId,
    managerCommandFinish
  } = useCommands({ groupId: groupNameThings(customerId) });

  const handleOnAccept = async (): Promise<void> => {
    const message: Interfaces.IModalControlDataFinish['message'] = {
      title: '',
      body: ''
    };
    await managerCommandFinish({ message });
    toggleOpenModal('commands');

    Analytics.sendEventCustom({
      category: 'Things All',
      action: 'Send Command',
      label: `${selectCommand.name}`
    });
  };

  const handleOnClickButton = (
    element: Interfaces.IControlsCommandsList
  ): void => {
    setSelectCommand({
      text: `${t(`${element.texts}.visible` as any)}`,
      name: element.name
    });

    checkGroupId({
      ...element,
      isValidId: true
    });
  };

  return (
    <Segment className='wrapper-commands-things'>
      <Header
        size='large'
        content={t('things.commandThings.header')}
        subheader={t('things.commandThings.subheader')}
      />
      <Segment className='content-buttons' basic>
        {commandsFilter.map((element) => (
          <Button
            key={`key-button-command-things-${element.id}`}
            animated='fade'
            color='orange'
            className='button-command'
            onClick={() => handleOnClickButton(element)}
          >
            <Button.Content visible>
              {t(`${element.texts}.visible` as any)}
            </Button.Content>
            <Button.Content hidden>
              {t(`${element.texts}.hidden` as any)}
              &nbsp; <Icon name='arrow right' />
            </Button.Content>
          </Button>
        ))}
      </Segment>
      <ModalConfirm
        header={{
          title: t('modals.paragraphs.confirm.commandsThings.title', {
            commandName: selectCommand.text
          })
        }}
        isOpen={isOpenModal.commands}
        isLoading={isLoadingRequest.create}
        onAccept={handleOnAccept}
        onCancel={() => toggleOpenModal('commands')}
      >
        <Markdown>
          {t(
            `modals.paragraphs.confirm.commandsThings.subtitle.${selectCommand.name}` as any
          )}
        </Markdown>
      </ModalConfirm>
    </Segment>
  );
};

export default CommandsThings;
