import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Message, Transition } from 'semantic-ui-react';

import './UpdateMessage.scss';

interface PropsUpdateMessage {
  onUpdate: () => void;
  isOpen: boolean;
}

export const UpdateMessage = ({ onUpdate, isOpen }: PropsUpdateMessage) => {
  const { t: translations } = useTranslation();

  return (
    <Transition animation='fade' duration={300} visible={isOpen}>
      <Message className='wrapper-update-message'>
        <Message.Content>
          <Message.Header>
            <Icon name='sync alternate' loading />
            {translations('newVersion.title')}
          </Message.Header>
          <p>{translations('newVersion.content')}</p>
          <Button
            fluid
            onClick={onUpdate}
            content={translations('actions.accept')}
          />
        </Message.Content>
      </Message>
    </Transition>
  );
};

export default UpdateMessage;
