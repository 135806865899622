import api from '../../config/api';
import ApiRequest from './ApiRequest';
import * as Interfaces from '../../interfaces';

class RedSkyAlert {
  public static async getCoverageByZipCode(
    zipCode: string
  ): Promise<Interfaces.IRequestCoverageZipCode> {
    const { name, method } = api.redSkyAlert.endpoint.getCoverageByZipCode;

    return ApiRequest.getInstance().buildAndFetchApiRedSkyAlert({
      name: name(zipCode),
      method
    });
  }
}

export default RedSkyAlert;
