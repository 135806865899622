import React, { FC } from 'react';
import { Icon, Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Empty from '../../../../components/Empty';
import TableList from '../../../../components/TableList';
import { SkeletonBlock } from '../../../../components/Skeletons';
import * as Helpers from '../../../../helpers';
import * as Constants from '../../../../config/constants';
import * as Interfaces from '../../../../interfaces';

const { parseKeysTypes } = Helpers;

const { HEADER_LIST_LICENSES_THINGS, LICENSES_TYPES_LIST } = Constants;

export interface PropsComponentInformationHistoryLicenses {
  isLoading: boolean;
  licenses: Interfaces.ILicensesTypeItem[];
  isDarkMode?: boolean;
}

const InformationHistoryLicenses: FC<
  PropsComponentInformationHistoryLicenses
> = ({
  isLoading,
  licenses,
  isDarkMode
}: PropsComponentInformationHistoryLicenses): JSX.Element => {
  const { t } = useTranslation();

  if (isLoading) {
    return <SkeletonBlock styled={{ maxWidth: '100%', height: 200 }} />;
  }

  if (!licenses.length) {
    return (
      <Empty
        iconName='key'
        texts={{ title: t('messages.things.types.title') }}
      />
    );
  }
  return (
    <TableList
      inverted={isDarkMode}
      stackable
      padded='very'
      title={t('things.information.history')}
    >
      <TableList.Header columns={HEADER_LIST_LICENSES_THINGS} list={licenses} />
      <TableList.Body>
        {Object.values(licenses).map(
          ({ id: licenseId, license, status }, index) => (
            <Table.Row key={`group-${index.toString()}`}>
              <Table.Cell>{licenseId || license}</Table.Cell>
              <Table.Cell>
                <Segment basic className='statusLicense'>
                  <Icon
                    color={LICENSES_TYPES_LIST[status].color}
                    name='circle'
                    size='small'
                  />
                  {parseKeysTypes('LICENSE', status)}
                </Segment>
              </Table.Cell>
            </Table.Row>
          )
        )}
      </TableList.Body>
    </TableList>
  );
};

InformationHistoryLicenses.defaultProps = {
  isDarkMode: false
};

export default InformationHistoryLicenses;
