import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthState } from '../../contexts/auth';
import names from '../names';

// TODO: ADD ROUTE 404 IN PUBLIC

export const AppRoutesProtect = (): JSX.Element => {
  const user = useAuthState();
  if (!user.id) {
    return <Navigate to={{ pathname: names.paths.login }} replace />;
  }

  return <Outlet />;
};

export const AppRoutesPublic = (): JSX.Element => {
  const user = useAuthState();
  if (user.id) {
    const routeToNavigate = !user.isLicensee
      ? names.paths.dashboard
      : names.paths.clients;
    return <Navigate to={{ pathname: routeToNavigate }} replace />;
  }

  return <Outlet />;
};
