import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Analytics from '../controllers/Analytics';
import BackendIoT from '../controllers/Backend/IoT';
import ReporterLogger from '../controllers/ReporterLogger';
import { useStatusRequest } from '../contexts/request';
import * as Constants from '../config/constants';
import * as Interfaces from '../interfaces';

interface IUseDashboard {
  isLoading: boolean;
  dataCustomer: {
    totalThings: Interfaces.IThingsTotal;
    listLicenses: Interfaces.ILicenseList;
    totalLicenses: number;
  };
}

interface IState {
  isLoading: IUseDashboard['isLoading'];
  totalThings: IUseDashboard['dataCustomer']['totalThings'];
  listLicenses: IUseDashboard['dataCustomer']['listLicenses'];
  totalLicenses: IUseDashboard['dataCustomer']['totalLicenses'];
}

const { DEFAULT_TOTAL_THINGS, LICENSES_TYPES_LIST } = Constants;

const LoggerInstance = ReporterLogger.getInstance();

export const useDashboard = ({
  analytics
}: Interfaces.IAnalytics = {}): IUseDashboard => {
  const { t } = useTranslation();
  const { UpdateMessageResponse } = useStatusRequest();

  const [isLoading, setIsLoading] = useState<IState['isLoading']>(true);
  const [totalThings, setTotalThings] =
    useState<IState['totalThings']>(DEFAULT_TOTAL_THINGS);
  const [listLicenses, setListLicenses] =
    useState<IState['listLicenses']>(LICENSES_TYPES_LIST);
  const [totalLicenses, setTotalLicenses] =
    useState<IState['totalLicenses']>(0);

  const Licenses = (licensesTypes: any): void => {
    if (licensesTypes) {
      let counter = 0;
      const updateValues = listLicenses;
      Object.keys(licensesTypes).forEach((key) => {
        if (updateValues[key]) {
          counter += licensesTypes[key];
          updateValues[key].value = licensesTypes[key];
        }
      });
      setTotalLicenses(counter);
      setListLicenses(updateValues);
    }
  };

  const fetchInfoCustomer = async (): Promise<void> => {
    if (!isLoading) {
      setIsLoading(true);
    }
    try {
      const response = await BackendIoT.getCustomer();
      let totalThingCount = DEFAULT_TOTAL_THINGS;
      if (response?.thingCount) {
        totalThingCount = response.thingCount;
      }
      if (response?.licenseCount) {
        const licensesTypes = response.licenseCount;
        Licenses(licensesTypes);
      }

      setTotalThings(totalThingCount);
    } catch (error) {
      UpdateMessageResponse({
        type: 'error',
        message: t('error.requests.things.total')
      });
      LoggerInstance.error(
        'Failed fetch info customer - useDashboard - "fetchInfoCustomer"',
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (analytics) {
      Analytics.sendPageView(analytics.page, analytics.title);
    }
    fetchInfoCustomer();
  }, []);

  return {
    isLoading,
    dataCustomer: {
      totalThings,
      listLicenses,
      totalLicenses
    }
  };
};

export default useDashboard;
